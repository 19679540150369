import React from "react";
import { Message, Icon } from "semantic-ui-react";

const FinanceTypeStatusMessage = ({ financeType }) => (
  <Message color="orange" icon compact className="sheet-status-message">
    <Icon name={"exclamation"} size="mini" />

    <Message.Content>
      <p>
        {financeType} rate type has changed since you last loaded the sheet
        please check calculation.
      </p>
    </Message.Content>
  </Message>
);

export default FinanceTypeStatusMessage;
