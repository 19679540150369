import React from "react";
import { Table } from "@redriver/cinnamon";
import { withRouter } from "react-router-dom";
import { withPermissions } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import DeleteClient from "./DeleteClient";
import { ClientRoutes } from "constants/routes";

const ClientActions = ({ client, onRefresh, hasPermission, history }) => {
  const canView = hasPermission(Targets.Client, Actions.View);
  const canDelete = hasPermission(Targets.Client, Actions.Delete);

  if (!canView && !canDelete) return null;

  return (
    <Table.Menu>
      {canView && (
        <Table.MenuItem
          onClick={() => history.push(ClientRoutes.Overview(client.id))}
        >
          View
        </Table.MenuItem>
      )}
      {canDelete && (
        <DeleteClient client={client} onDeleted={onRefresh} as={Table.MenuItem}>
          Delete
        </DeleteClient>
      )}
    </Table.Menu>
  );
};

export default withRouter(withPermissions(ClientActions));
