import {
  apiGet,
  apiPut,
  apiPost,
  apiDelete
} from "@redriver/cinnamon";

const NAMESPACE = "CLIENTS";

export const ActionTypes = {
  GetClients: `${NAMESPACE}/GET_CLIENTS`,
  GetClient: `${NAMESPACE}/GET_CLIENT`,
  GetClientByCode: `${NAMESPACE}/GET_CLIENT_BY_CODE`,
  CreateClient: `${NAMESPACE}/ADD_CLIENT`,
  CreateClientWithDetails: `${NAMESPACE}/ADD_CLIENT_WITH_DETAILS`,
  UpdateClient: `${NAMESPACE}/UPDATE_CLIENT`,
  DeleteClient: `${NAMESPACE}/DELETE_CLIENT`,
  SetSidePanelVisible: `${NAMESPACE}/SET_SIDE_PANEL_VISIBLE`,
  GetClientAddresses: `${NAMESPACE}/GET_CLIENT_ADDRESSES`,
  SetHeaderFields: `${NAMESPACE}/SET_HEADER_FIELDS`
};

export const getClients = ({ filters, pagination }) => {
  const { pageSize, pageNumber } = pagination;
  const { search, dealerId, businessType, sortName, sortDirection } = filters;
  return apiGet(ActionTypes.GetClients, "clients", {
    pageNumber,
    pageSize,
    search,
    dealerId,
    businessType,
    sortName,
    sortDirection
  });
};

export const getClient = id => apiGet(ActionTypes.GetClient, `clients/${id}`);

export const getClientByCode = code => apiGet(ActionTypes.GetClientByCode, `clients/code/${code}`, null, {
  preventErrorNotification: true
});

export const createClient = data =>
  apiPost(ActionTypes.CreateClient, "clients", data);

export const putClientWithDetails = data =>
  apiPut(ActionTypes.CreateClientWithDetails, `clients/with-details/${data.code}`, data);

export const updateClient = (data, clientId) =>
  apiPut(ActionTypes.UpdateClient, `clients/${clientId}`, data);

export const deleteClient = id =>
  apiDelete(ActionTypes.DeleteClient, `clients/${id}`);

export const setSidePanelVisible = visible => ({
  type: ActionTypes.SetSidePanelVisible,
  visible
});

export const setHeaderFields = (data, clientId) =>
  apiPut(ActionTypes.SetHeaderFields, `clients/${clientId}/header-fields`, data);
