import React from "react";
import { useTranslation } from "react-i18next";
import { Form, useFormField, Format } from "@redriver/cinnamon";
import { Button, Table } from "semantic-ui-react";
import { produce } from "immer";

const UnitTable = () => {
  const { t } = useTranslation("agreements", {
    keyPrefix: "ScheduleRow.UnitTable",
  });
  const [items, setItems] = useFormField("units");

  const keepAgreedTmvs = ({ arrayIndex, all }) => {
    if (!Array.isArray(items) || items.length === 0) return;

    const fieldName = "agreedTmvsaMin";
    const fieldNameToUpdate = "suggestTmvsaMin";
    const fieldNameToSet = "keepAgreedTmvsaMin";
    let index = 0;
    const updatedItems = produce(items, (draftItems) => {
      draftItems.forEach((i) => {
        if (all || arrayIndex == index) {
          i[fieldNameToUpdate] = i[fieldName];
          i[fieldNameToSet] = true;
        }
        index++;
      });
      return draftItems;
    });
    setItems(updatedItems);
  };

  return (
    <React.Fragment>
      <Table className="schedule-unit-table center-align">
        <Form.Array field="units" arrayKey="id" propagateUpdates="always">
          {({ fields, arrayIndex }) => (
            <React.Fragment>
              {arrayIndex == 0 && (
                <React.Fragment>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>{t("Unit")}</Table.HeaderCell>
                      <Table.HeaderCell className="description">
                        {t("Unit_Description")}
                      </Table.HeaderCell>
                      <Table.HeaderCell className="agreed">
                        {t("Agreed_TMVSA_Minimums")}
                      </Table.HeaderCell>
                      <Table.HeaderCell className="suggested">
                        {t("Suggested_TMVSA_Minimums")}
                      </Table.HeaderCell>
                      <Table.HeaderCell className="keep">
                        {t("Keep_Current_Agreed_TMVSA_Minimum")}
                      </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell colSpan={4}></Table.HeaderCell>
                      <Table.HeaderCell className="center">
                        <Button
                          primary
                          className="apply-to-all"
                          content={"Apply to All"}
                          onClick={() =>
                            keepAgreedTmvs({ arrayIndex, all: true })
                          }
                          icon="check"
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                </React.Fragment>
              )}

              <Table.Body>
                <Table.Row>
                  <Table.Cell>{fields.unit}</Table.Cell>
                  <Table.Cell className="description">
                    {fields.description}
                  </Table.Cell>
                  <Table.Cell className="agreed">
                    <Format.Number
                      decimalPlaces={0}
                      thousandSeparator
                      value={fields.agreedTmvsaMin}
                    />
                  </Table.Cell>
                  <Table.Cell className="suggested">
                    <Form.Numeric
                      field="suggestTmvsaMin"
                      disabled={fields.keepAgreedTmvsaMin}
                    ></Form.Numeric>
                  </Table.Cell>
                  <Table.Cell className="keep center">
                    <Form.Checkbox
                      field="keepAgreedTmvsaMin"
                      onClick={() => keepAgreedTmvs({ arrayIndex, all: false })}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </React.Fragment>
          )}
        </Form.Array>
      </Table>
    </React.Fragment>
  );
};

export default UnitTable;
