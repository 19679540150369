import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder } from "@redriver/cinnamon";
import { resendVerification } from "./actions";
import { TranslatedModal } from "features/../../../shared/components/modals";

const ResendVerification = ({ as: As = Button, user, onResend, ...props }) => (
  <ModalBuilder
    submitAction={resendVerification}
    submitParams={user.id}
    onSubmitted={onResend}
    renderTrigger={showModal => <As onClick={showModal} {...props} />}
    renderModal={modalProps => (
      <TranslatedModal.Confirmation {...modalProps} header="Resend Verification">
        <p>
          Are you sure you wish to resend a verification email to {user.name}?
        </p>
        <p>
          This will be sent to: <strong>{user.email}</strong>
        </p>
      </TranslatedModal.Confirmation>
    )}
  />
);

export default ResendVerification;
