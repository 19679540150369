import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM";

const ActionTypes = {
  GetIsInMaintenancePeriod: `${NAMESPACE}/GET_IN_MAINTENANCE_PERIOD`,  
};

export const getIsInMaintenancePeriod = () =>
    apiGet(
        ActionTypes.GetIsInMaintenancePeriod,
        'system/in-maintenance-period',
        null,
        { auth: false,
          preventErrorNotification: true,
         }
    );