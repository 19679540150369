import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";
import { Form } from "@redriver/cinnamon";
import { mapFunderOverridesFieldName } from "./OverrideSection";

const FunderRateTypeSection = ({
  header,
  className,
  funderType,
  UnlockFieldOptions,
}) => {
  const { t } = useTranslation("ipsRates");
  const field = mapFunderOverridesFieldName(funderType);

  return (
    <Form.Object field={field} propagateUpdates="always">
      <Grid.Column className="funder-type-rate-settings">
        <Grid.Row className={"funder-type-radio"}>
          <div className={"funder-type-rate-details " + className}>
            <h5>{header}</h5>
            <Grid.Column className="funder-type-radio-group">
              <Form.RadioGroup
                inline
                field="unlockRateType"
                name={(radioField) => `radio_urt_${field}_${radioField}`}
                label={
                  <div className="funder-type-radio-title">
                    <p>{t("Unlock_Rate_Type?", { title: header })}</p>
                  </div>
                }
                options={UnlockFieldOptions}
                className="required-field sheet-radio-group"
              />
            </Grid.Column>
            <Grid.Column className="funder-type-radio-group">
              <Form.RadioGroup
                inline
                field="unlockFMV"
                name={(radioField) => `radio_ufmv_${field}_${radioField}`}
                label={
                  <div className="funder-type-radio-title">
                    <p>{t("Unlock_FMV?", { title: header })}</p>
                  </div>
                }
                options={UnlockFieldOptions}
                className="required-field sheet-radio-group"
              />
            </Grid.Column>
          </div>
        </Grid.Row>
      </Grid.Column>
    </Form.Object>
  );
};

export default FunderRateTypeSection;
