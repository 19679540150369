import React, { useState } from "react";
import QRCode from "qrcode.react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Loader, Checkbox } from "semantic-ui-react";
import {
  FormBuilder,
  Form,
  ErrorMessage,
  authenticate,
} from "@redriver/cinnamon";
import { AppRoutes, SystemRoutes } from "constants/routes";
import { ExternalUrls } from "constants/urls";
import { requestRegistration, completeRegistration } from "./actions";
import RecoveryCodes from "./RecoveryCodes";

const DEFAULT_MFA_RECOVERY_TYPE = "RecoveryCodes";

const authenticatorErrors = {
  922001: "Incorrect authenticator code, please try again",
};

const AuthenticatorSetup = ({
  token,
  loginTokens,
  setupRecovery,
  onSkipped,
  email,
}) => {
  const [setupFailed, setSetupFailed] = useState(false);
  const [setupSuccess, setSetupSuccess] = useState(false);
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const [codesSaved, setCodesSaved] = useState(false);
  const dispatch = useDispatch();
  const label = `${process.env.AUTHENTICATOR_LABEL}: ${email}`;
  if (setupFailed) {
    return (
      <div>
        <p>Two-Factor Authentication setup failed.</p>
        <p>
          Return to <Link to={SystemRoutes.Login}>Log In</Link>
        </p>
      </div>
    );
  }

  if (setupSuccess) {
    const hasCodes = recoveryCodes?.length > 0;
    return (
      <div>
        <p>Your account has been successfully setup.</p>
        {hasCodes && <RecoveryCodes codes={recoveryCodes} />}
        <div style={{ marginTop: 20 }}>
          {hasCodes && (
            <Checkbox
              label="I have saved my recovery codes"
              checked={codesSaved}
              onChange={() => setCodesSaved(!codesSaved)}
            />
          )}
          <Button
            primary
            fluid
            as={Link}
            to={AppRoutes.Root}
            replace
            size="large"
            disabled={hasCodes && !codesSaved}
            className="authenticator-button"
          >
            Continue
          </Button>
        </div>
      </div>
    );
  }

  const skipSetup = () => {
    if (!loginTokens) return;
    const { access, refresh } = loginTokens;
    dispatch(authenticate(access, refresh));
    if (onSkipped) onSkipped();
  };

  return (
    <FormBuilder
      loadAction={requestRegistration}
      loadParams={token}
      onLoaded={(response) => {
        if (!response?.secret) setSetupFailed(true);
      }}
      submitAction={completeRegistration}
      submitParams={{
        token,
        recovery: setupRecovery ? DEFAULT_MFA_RECOVERY_TYPE : undefined,
      }}
      onSubmitted={({ codes }) => {
        if (codes) setRecoveryCodes(codes);
        setSetupSuccess(true);
      }}
      renderLoading={false}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { loading, slowLoading, submitting, slowSubmitting, error },
        events
      ) => {
        const { secret } = formProps.value;
        if (loading && slowLoading) {
          return <Loader inline active size="large" />;
        }
        if (!secret) return null;

        return (
          <Form {...formProps}>
            {loginTokens && (
              <p className="align-text-left">
                We recommend enabling two-factor authentication on your account
                to enhance security and protect your details.
              </p>
            )}
            <p className="align-text-left">
              To continue, please download a two-factor authenticator app, scan
              the QR code below and enter an authentication code:
            </p>
            <div style={{ textAlign: "center" }}>
              <QRCode
                className="align-text-left"
                value={ExternalUrls.AuthenticatorQrCode(secret, label)}
                size={256}
              />
            </div>
            <p className="align-text-left">
              Alternatively, manually enter the following key into your chosen
              authenticator app:
              <br />
              <span className="key">{secret}</span>
            </p>
            <Form.Input
              field="totp"
              label="Authentication Code"
              placeholder="Enter authenticator app code"
              disabled={submitting}
              autoFocus
              required
              fluid
            />
            <div>
              <Button
                primary
                onClick={events.onSubmit}
                disabled={submitting}
                loading={submitting && slowSubmitting}
                fluid
                size="large"
                className="authenticator-button"
              >
                Continue
              </Button>
              {loginTokens && (
                <a onClick={skipSetup}>Skip Two-Factor Authentication</a>
              )}
            </div>
            <ErrorMessage
              header="Authentication Error"
              error={error}
              overrideMessages={authenticatorErrors}
              omitCodes
            />
            <p className="align-text-left">
              Return to <Link to={SystemRoutes.Login}>Log In</Link>
            </p>
          </Form>
        );
      }}
    />
  );
};

export default AuthenticatorSetup;
