import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder } from "@redriver/cinnamon";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { useTranslation } from "react-i18next";

const CloseConfirmationModal = ({
  buttonContent,
  disabled,
  onSubmit,
  icon,
}) => {
  const { t } = useTranslation("sheetTranslation", {
    keyPrefix: "closeConfirmationModal",
  });
  return (
    <ModalBuilder
      onSubmit={onSubmit}
      renderTrigger={(showModal) => (
        <Button
          primary
          disabled={disabled}
          onClick={showModal}
          content={buttonContent}
          className="close-button"
          icon={icon}
        ></Button>
      )}
      renderModal={(modalProps) => (
        <TranslatedModal.Confirmation {...modalProps} header={t("header")}>
          {t("question")}
          <br />
          <br />
          <strong>{t("warning")}</strong>
        </TranslatedModal.Confirmation>
      )}
    />
  );
};

CloseConfirmationModal.propTypes = {
  disabled: PropTypes.bool,
  user: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonContent: PropTypes.string,
};

export default withRouter(CloseConfirmationModal);
