import React from "react";
import { useSelector } from "react-redux";
import { Table } from "semantic-ui-react";
import { Form } from "@redriver/cinnamon";
import {
  BlankCurrencyFormatter,
  BlankNumberFormatter,
} from "features/../../../shared/components/display";
import { useTranslation } from "react-i18next";
import { getSheetDataState } from "features/Sheets";
import classNames from "classnames";

const CrystallisedSchedule = () => {
  const { t } = useTranslation("systemScheduleStability", {
    keyPrefix: "schedule",
  });
  const data = useSelector(
    (state) =>
      state.sheets?.rawCalculation?.scheduleStability?.liveCrystallisedSchedule,
  );

  const { comparisonAdjustmentQtrFinance, comparisonAdjustmentQtrService } =
    useSelector((state) => state.sheets?.sheetData.scheduleStability);

  const { preventUpdates } = useSelector(getSheetDataState);

  return (
    <React.Fragment>
      {data && (
        <React.Fragment>
          <h5>{t("from_live_crystallised_schedule")} </h5>
          <Table className="table alt">
            <Table.Body>
              <TR
                className="border-bottom"
                cell1={t("volume_selection_remaining")}
                cell2={
                  <BlankNumberFormatter value={data.volSelectionRemaining} />
                }
              />
              <TR className="blank" />
              <TR
                cell1={t("quarterly_contracted_volumes")}
                className="highlight"
              />
              <TR
                className="light-grey"
                cell1={t("managed_meter")}
                cell2={
                  <BlankNumberFormatter
                    value={data.qtrContractedVolManagedMeter}
                  />
                }
              />
              <TR
                className="border-bottom"
                cell1={t("non_managed_meter")}
                cell2={
                  <BlankNumberFormatter
                    value={data.qtrContractedVolNonManagedMeter}
                  />
                }
              />
              <TR className="blank" />
              <TR
                className="border-bottom highlight-alt"
                cell1={t("current_schedule_total_managed_volume")}
                cell2={
                  <BlankNumberFormatter
                    value={data.currentScheduleTotalManagedVol}
                  />
                }
              />
              <TR className="blank" />
              <TR
                cell1={t("quarterly_minimum_payments")}
                className="highlight"
              />
              <TR
                className="light-grey"
                cell1={t("managed_meter")}
                cell2={
                  <BlankCurrencyFormatter
                    value={data.qtrMinPaymentsManagedMeter}
                  />
                }
              />
              <TR
                className="border-bottom"
                cell1={t("non_managed_meter")}
                cell2={
                  <BlankCurrencyFormatter
                    value={data.qtrMinPaymentsNonManagedMeter}
                  />
                }
              />
              <TR
                className="border-bottom  bold"
                cell1={t("total_quarterly_minimum_payment")}
                cell2={
                  <BlankCurrencyFormatter value={data.qtrMinPaymentsTotal} />
                }
              />
              <TR className="blank" />
              <TR
                className="border-bottom highlight-alt"
                cell1={t("current_schedule_value")}
                cell2={
                  <BlankCurrencyFormatter value={data.currentScheduleValue} />
                }
              />
              <TR className="blank" />
              <TR
                className="border-bottom highlight"
                cell1={t("comparison_adjustments")}
              />
              <Table.Row className="light-grey">
                <Table.Cell style={{ width: "70%" }}>
                  {t("quarterly_finance_value")}
                </Table.Cell>
                <Table.Cell>
                  <Form.Numeric
                    field="comparisonAdjustmentQtrFinance"
                    className={classNames(
                      "comparison-adjustment-fields-width-override",
                      comparisonAdjustmentQtrFinance < 0 && "negative",
                    )}
                    decimalPlaces={2}
                    disabled={preventUpdates}
                    subLabel={process.env.MAJOR_CURRENCY}
                    subLabelPosition="left"
                    formatValue
                    placeholder="0.00"
                    width={10}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell style={{ width: "70%" }}>
                  {t("quarterly_service_value")}
                </Table.Cell>
                <Table.Cell>
                  <Form.Numeric
                    field="comparisonAdjustmentQtrService"
                    className={classNames(
                      "comparison-adjustment-fields-width-override",
                      comparisonAdjustmentQtrService < 0 && "negative",
                    )}
                    decimalPlaces={2}
                    disabled={preventUpdates}
                    subLabel={process.env.MAJOR_CURRENCY}
                    subLabelPosition="left"
                    formatValue
                    placeholder="0.00"
                    width={10}
                  />
                </Table.Cell>
              </Table.Row>
              <TR
                className="border-bottom bold"
                cell1={t("adjusted_total_quarterly_minimum_payment")}
                cell2={
                  <BlankCurrencyFormatter
                    value={data.adjustedTotalQtrMinPayment}
                  />
                }
              />
              <TR className="blank" />
              <TR
                cell1={t("adjusted_current_schedule_value")}
                cell2={
                  <BlankCurrencyFormatter
                    value={data.adjustedCurrentScheduleValue}
                  />
                }
                className="highlight-alt"
              />
            </Table.Body>
          </Table>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const TR = ({ cell1 = "", cell2 = "", className = "" }) => {
  return (
    <Table.Row className={className}>
      <Table.Cell style={{ width: "70%" }}>{cell1}</Table.Cell>
      <Table.Cell>{cell2}</Table.Cell>
    </Table.Row>
  );
};

export default CrystallisedSchedule;
