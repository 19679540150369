import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { SideColumn, Content, ContentBuilder } from "@redriver/cinnamon";
import { AppRoutes, DealerRoutes } from "constants/routes";
import DealerMenu from "./DealerMenu";
import DealerPage from "./DealerPage";
/*
import DealerUsersListPage from "./DealerUsersListPage";
import DealerSheetsListPage from "./DealerSheetsListPage";
import DealerDefaultsPage from "./DealerDefaultsPage";
import DealerRatesPage from "./DealerRatesPage";
import DealerYieldsPage from "./DealerYieldsPage";
import DealerLargeFormatPage from "./DealerLargeFormatPage";
*/
import { BackToLink } from "components/navigation";
import { getDealerOffice } from "features/DealerOffices/ListDealerOffices";
import { useTranslation } from "react-i18next";

const DealersRouter = ({ match }) => {
  const { t } = useTranslation("supplierOffices", { keyPrefix: "links" });

  return (
    <Content>
      <SideColumn
        className="dealers-pages"
        renderColumn={() => (
          <div>
            <BackToLink
              text={t("backToOffices")}
              to={AppRoutes.DealerOffices}
            />
            <DealerMenu companyId={match.params.id} />
          </div>
        )}
      >
        <ContentBuilder
          loadAction={getDealerOffice}
          loadParams={match.params.id}
          renderContent={(data) => (
            <React.Fragment>
              <Switch>
                <Route
                  exact
                  path={DealerRoutes.Overview()}
                  render={(props) => (
                    <DealerPage {...props} dealerOfficeName={data.name} />
                  )}
                />
                {/*
              <Route
                exact
                path={DealerRoutes.Users()}
                render={props => (
                  <DealerUsersListPage {...props} dealerOfficeName={data.name} />
                )}
              />
              <Route
                exact
                path={DealerRoutes.Sheets()}
                render={props => (
                  <DealerSheetsListPage {...props} dealerOfficeName={data.name} />
                )}
              />
              <Route
                exact
                path={DealerRoutes.Defaults()}
                render={props => (
                  <DealerDefaultsPage {...props} dealerOfficeName={data.name} />
                )}
              />
              <Route
                exact
                path={DealerRoutes.Rates()}
                render={props => (
                  <DealerRatesPage {...props} dealerOfficeName={data.name} />
                )}
              />
              <Route
                exact
                path={DealerRoutes.Yields()}
                render={props => (
                  <DealerYieldsPage {...props} dealerOfficeName={data.name} />
                )}
              />

              <Route
                exact
                path={DealerRoutes.LargeFormat()}
                render={props => (
                  <DealerLargeFormatPage {...props} dealerOfficeName={data.name} />
                )}
                />
                */}

                <Route
                  render={() => <Redirect to={AppRoutes.DealerOffices} />}
                />
              </Switch>
            </React.Fragment>
          )}
        />
      </SideColumn>
    </Content>
  );
};

export default DealersRouter;
