import { SettlementFieldType } from "features/../../../shared/constants/enums";
import { getWizardSplitTotals,} from "../../helpers";
import { getFixedNumericalValue } from "../FixedFields";

export const calculateTotals = section => {
    let capitalCosts = getFixedNumericalValue(section.productCapitalCosts.value),
      otherCosts = getFixedNumericalValue(section.otherCosts.value),
      baselineCost = (capitalCosts || 0) + (otherCosts || 0),
      result = getWizardSplitTotals(baselineCost, baselineCost, baselineCost);
  
    let salespersonSettlements = []
        .concat(section.settlements)
        .filter(x => x && x.fieldType === SettlementFieldType.Standard),
      adminSettlements = []
        .concat(section.settlements)
        .concat(section.adminSettlements)
        .filter(x => x && x.fieldType === SettlementFieldType.Admin),
      ipsSettlements = []
        .concat(section.settlements)
        .concat(section.ipSettlements)
        .filter(x => x && x.fieldType === SettlementFieldType.IP);
  
    salespersonSettlements.forEach(x => {
      result.salesperson += parseFloat(x.value.value) || 0;
      result.dealerAdmin += parseFloat(x.value.value) || 0;
      result.ipsAdmin += parseFloat(x.value.value) || 0;
    });
    adminSettlements.forEach(x => {
      result.dealerAdmin += parseFloat(x.value.value) || 0;
      result.ipsAdmin += parseFloat(x.value.value) || 0;
    });
    ipsSettlements.forEach(
      x => (result.ipsAdmin += parseFloat(x.value.value) || 0)
    );
  
    return result;
  };
  
