import React from "react";
import { registerLookup, apiGet, Form } from "@redriver/cinnamon";
import { ActionTypes as ListClientActionTypes } from "./ListClients";

const NAMESPACE = "CLIENTS/LOOKUPS";

const ActionTypes = {
  GetBusinessTypes: `${NAMESPACE}/GET_BUSINESS_TYPES`,
};

const clientsLookup = "clientsLookup";

registerLookup(
  clientsLookup,
  ({ search, dealerOfficeId }) =>
    apiGet(ListClientActionTypes.GetClients, `lookups/clients`, {
      pageSize: 10,
      pageNumber: 1,
      dealerOfficeId,
      search,
    }),
  {
    transform: (data) =>
      data && Array.isArray(data)
        ? data.map((x) => {
            return {
              value: x.id,
              text: x.name,
            };
          })
        : [],
    cache: false,
  }
);

export const ClientsDropdownLookup = ({
  className,
  dealerOfficeId,
  ...props
}) => (
  <div className={className}>
    <Form.Dropdown
      search
      searchSuggestions
      alwaysShowSuggestionsOnBlur
      {...props}
      lookup={clientsLookup}
      lookupParams={{ dealerOfficeId }}
    />
  </div>
);

const businessTypesLookup = "businessTypesLookup";
registerLookup(
  businessTypesLookup,
  () => apiGet(ActionTypes.GetBusinessTypes, "lookups/business-types"),
  {
    transform: (data) =>
      data.map((x) => {
        return {
          value: x.key,
          text: x.value,
        };
      }),
  }
);
export const BusinessTypeDropdownLookup = ({ props }) => (
  <Form.Dropdown {...props} lookup={businessTypesLookup} />
);

export const clientAddressesLookup = "clientAddressesLookup";

registerLookup(
  clientAddressesLookup,
  ({ clientId }) =>
    apiGet(
      ListClientActionTypes.GetClientAddresses,
      `lookups/clients/addresses`,
      {
        clientId,
      }
    ),
  {
    cache: false,
    transform: (data) =>
      data.map((x) => {
        return {
          value: x.id,
          text: x.address.addressString,
          ...x,
        };
      }),
  }
);

export const ClientAddressDropdownLookup = ({ clientId, ...props }) => (
  <Form.Dropdown
    {...props}
    lookup={clientAddressesLookup}
    lookupParams={{ clientId }}
  />
);
