const NAMESPACE = "AGREEMENTS";

export const ActionTypes = {
  SetAgreementCompanyFilter: `${NAMESPACE}/SET_AGREEMENT_COMPANY_FILTER`,
  ClearAgreementCompanyFilter: `${NAMESPACE}/CLEAR_AGREEMENT_COMPANY_FILTER`,
  ClearAgreementState: `${NAMESPACE}/CLEAR_AGREEMENT_STATE`,
  ClearAgreementData: `${NAMESPACE}/CLEAR_AGREEMENT_DATA`,
  SetSelectedAgreementSchedule: `${NAMESPACE}/SET_SELECTED_AGREEMENT_FILTER`,
};

export const setAgreementCompanyFilter = (data) => (dispatch) => {
  return dispatch({ type: ActionTypes.SetAgreementCompanyFilter, data });
};

export const clearAgreementCompanyFilter = () => (dispatch) => {
  return dispatch({ type: ActionTypes.ClearAgreementCompanyFilter });
};

// Resets state back to initial state
export const clearAgreementState = () => (dispatch) => {
  return dispatch({ type: ActionTypes.ClearAgreementState });
};

// Retains company filter, but clears out all other state data such as filters, agreements, sheets, etc.
export const clearAgreementData = () => (dispatch) => {
  return dispatch({ type: ActionTypes.ClearAgreementData });
};

export const setSelectedAgreementSchedule = (data) => (dispatch) => {
  return dispatch({ type: ActionTypes.SetSelectedAgreementSchedule, data });
};
