import { mockSuccess, MockArrayData } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/LIST_USERS";

const ActionTypes = {
  GetUsers: `${NAMESPACE}/GET_USERS`,
  InviteUsers: `${NAMESPACE}/INVITE_USERS`,
  ResendVerification: `${NAMESPACE}/RESEND_VERIFICATION`,
  SendPasswordReset: `${NAMESPACE}/SEND_PASSWORD_RESET`,
  LockOutUser: `${NAMESPACE}/LOCK_OUT_USER`,
  ActivateUser: `${NAMESPACE}/ACTIVATE_USER`,
};

const mockUsers = new MockArrayData([]);

export const getUsers = ({ filters, pagination }) => {
  const { pageSize, pageNumber } = pagination;
  const { search } = filters;
  return mockSuccess(ActionTypes.GetUsers, {
    delay: 200,
    response: mockUsers.filterPaged(
      (u) =>
        !search ||
        u.name.toLowerCase().includes(search.toLowerCase()) ||
        u.email.toLowerCase().includes(search.toLowerCase()),
      pageSize,
      pageNumber
    ),
  });
};

export const inviteUsers = () =>
  mockSuccess(ActionTypes.InviteUsers, { delay: 500 });

export const resendVerification = () =>
  mockSuccess(ActionTypes.ResendVerification);

export const sendPasswordReset = () =>
  mockSuccess(ActionTypes.SendPasswordReset);

export const lockOutUser = () => mockSuccess(ActionTypes.LockOutUser);

export const activateUser = () => mockSuccess(ActionTypes.ActivateUser);
