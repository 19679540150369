import React from "react";
import { FixedInput, FixedString, newFixedInput } from "components/forms";
import { Button } from "semantic-ui-react";
import { Form, getAuthClaim } from "@redriver/cinnamon";
import { getSheetDataState, getSheetState } from "features/Sheets";
import { connect } from "react-redux";
import { SheetViewType } from "features/../../../shared/constants/enums";
import { useTranslation } from "react-i18next";
import { isFieldFixed } from "features/Sheets/ManageSheet";
import { FormArrayDeleteModal } from "features/../../../shared/components/forms";

const PaperStepAdditionalCosts = ({
  sheetViewType,
  impersonatedUser,
  preventUpdates,
}) => {
  function fieldIsNotFixed(field) {
    if (!field) {
      return true;
    }
    if (field.setValue || field.minimumValue || field.maximumValue) {
      return false;
    }
    return true;
  }

  const hideFixedIndicator = !impersonatedUser.canEditAdminFields;
  const { t } = useTranslation("paperInclusive");
  return (
    <div className="additional-costs">
      <div className="additional-costs-container">
        <div className="add-cost-headers">
          <div>{t("modal.additionalCostPerQuarter.minQtrVol")}</div>
          <div>{t("modal.additionalCostPerQuarter.description")}</div>
          <div>{t("modal.additionalCostPerQuarter.sku")}</div>
          <div>{t("modal.additionalCostPerQuarter.customerReference")}</div>
          <div className="additional-terms">
            {t("modal.additionalCostPerQuarter.additionalTerms")}
          </div>
        </div>
        <div className="add-cost-all-fields">
          <div className="array">
            <Form.Array
              arrayKey="id"
              field="additionalCosts"
              minLength={2}
              disabled={preventUpdates}
            >
              {({ fields }) => (
                <div className="add-cost-fields">
                  <div className="remove-container">
                    {(fieldIsNotFixed(fields.value) ||
                      sheetViewType === SheetViewType.IpsAdmin ||
                      sheetViewType === SheetViewType.SupplierAdmin) && (
                      <FormArrayDeleteModal
                        nameOfRemoval={t(
                          "modal.additionalCostPerQuarter.deleteItemName"
                        )}
                        disabled={isFieldFixed(fields.value) || preventUpdates}
                      />
                    )}
                  </div>
                  <FixedInput
                    fluid
                    formatNumber
                    currency
                    field="value"
                    minAllowValue={0}
                    hideIndicator={hideFixedIndicator}
                    displayPriority={4}
                  />
                  <Form.Input field="description" />
                  <Form.Input field="sku" />
                  <Form.Input field="customerReference" />
                </div>
              )}
            </Form.Array>
          </div>
          <FixedString
            textArea
            field="paperInclusiveAdditionalTerms"
            placeholder={t(
              "modal.additionalCostPerQuarter.additionalTermsPlaceholder"
            )}
            className="highlighted-input additional-terms"
            rows={2}
            hideIndicator={hideFixedIndicator}
            displayPriority={4}
          />
        </div>
      </div>
      <div className="additional-cost-adder">
        <Form.ArrayAdder
          field="additionalCosts"
          arrayKey="id"
          as={Button}
          content={t("modal.additionalCostPerQuarter.addAdditionalCostButton")}
          disabled={preventUpdates}
          populate={() => ({
            id: Date.now(),
            value: newFixedInput(),
            description: "",
            sku: "",
            customerReference: "",
          })}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { sheetViewType } = getSheetState(state);
  const { impersonatedUser, preventUpdates, paperInclusive } =
    getSheetDataState(state);
  return {
    sheetViewType,
    impersonatedUser,
    preventUpdates,
    additionalCosts: paperInclusive.additionalCosts,
    paperInclusiveAdditionalTerms: paperInclusive.paperInclusiveAdditionalTerms,
    userArea: getAuthClaim(state, "userArea"),
  };
};
export default connect(mapStateToProps, null)(PaperStepAdditionalCosts);
