import React from "react";
import { useTranslation } from "react-i18next";
import { unarchiveSheet } from "../actions";
import { ModalBuilder, Table } from "@redriver/cinnamon";
import { withRouter } from "react-router-dom";
import {
  withCurrentUser,
  PermissionCheck,
} from "features/../../../shared/components/auth";

import { Targets, Actions } from "constants/permissions";
import { TranslatedModal } from "features/../../../shared/components/modals";

const UnarchiveSheet = ({ sheet, onSubmitted }) => {
  const { t } = useTranslation("agreements");
  const { sheetId, sheetName } = sheet;

  return (
    <PermissionCheck
      target={Targets.Sheet}
      action={Actions.ArchiveAndUnarchiveSheets}
    >
      <ModalBuilder
        withForm
        submitAction={unarchiveSheet}
        submitParams={sheetId}
        onSubmitted={onSubmitted}
        renderTrigger={(trigger) => (
          <Table.MenuItem onClick={trigger}>
            {t("SheetActions.UnarchiveSheet.TriggerText")}
          </Table.MenuItem>
        )}
        renderModal={(modalProps) => (
          <TranslatedModal.Confirmation
            {...modalProps}
            header={t("SheetActions.UnarchiveSheet.HeaderText")}
            size="small"
          >
            {t("SheetActions.UnarchiveSheet.ConfirmMessage", { sheetName })}
          </TranslatedModal.Confirmation>
        )}
      />
    </PermissionCheck>
  );
};

export default withCurrentUser(withRouter(UnarchiveSheet));
