import React from "react";
import { Icon, Button } from "semantic-ui-react";
import classNames from "classnames";

/**
 * A button meant for opening an edit modal on a Sheet step, consisting
 * of an icon with a text prompt
 * @param {String} icon The icon to use - defaults to `Pencil`
 * @param {String} text The text to display on the button - defaults to 'Edit'
 * @param {Function} action A function to call when the button is clicked
 * @param {String} className Optional classes to apply to the element
 */
const SheetStepEditButton = ({ text, icon, action, disabled, className }) => (
  <Button className={classNames("step-edit-button", className)} disabled={disabled} onClick={action}>
    <Icon name={icon || "pencil"} />
    {text || "Edit"}
  </Button>
);

export default SheetStepEditButton;
