import React from "react";
import { isNil } from "lodash";

const FormatDisplay = (item) => {
  let value = item.value;

  switch (item.label) {
    case "businessType":
      return "Business Type";

    case "dealerId":
      return "Supplier";
  }

  return `${item.label}: ${value}`;
};

const ClientFilterDisplay = ({ filters, onChange }) => {
  const items = filters
    ? Object.keys(filters)
        .filter((f) => f !== "search" && f != undefined && f != null)
        .map((k) => {
          return {
            label: k,
            value: filters[k],
          };
        })
    : [];

  if (!items.length) return null;

  return (
    <div className="filter-display">
      {items
        .filter((i) => !isNil(i.value) && i.value != "")
        .map((item) => (
          <div className="filter-display-item" key={item.label}>
            {FormatDisplay(item)}
          </div>
        ))}

      <div
        onClick={() => {
          const nextFilters = {
            ...filters,
          };
          delete nextFilters.businessType;
          delete nextFilters.dealerId;
          onChange(nextFilters);
        }}
        className="clear-button"
      >
        (clear)
      </div>
    </div>
  );
};

export default ClientFilterDisplay;
