import React, { useEffect } from "react";
import { compose } from "redux";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter, Redirect, Route, Switch } from "react-router-dom";
import classNames from "classnames";
import { NotificationStack, AuthActions } from "@redriver/cinnamon";
import { getNotificationState } from "features/../../../shared/modules/notifications";
import {
  AppRoutes,
  SystemRoutes,
  //  SettingsRoutes,
  DealerRoutes,
  ClientRoutes,
} from "constants/routes";
import { MainNav } from "./nav";
import UserRouter from "./users/UserRouter";
import { ClientsListPage, ClientsRouter } from "./clients";
import { DealersListPage, DealersRouter } from "./dealers";
import AccountPage from "./system/AccountPage";
import { getResponsiveState } from "features/../../../shared/modules/responsive/selectors";
import MobileWrapper from "./nav/MobileWrapper";
import AgreementsRouter from "./agreements/AgreementsRouter";
import DataUploadsRouter from "./DataUploads/DataUploadsRouter";
import NotificationsRouter from "./notifications/NotificationsRouter";
import preventDragHandler from "features/../../../shared/modules/dragging/preventDragHandler";
import {
  withCurrentUser,
  withPermissions,
} from "features/../../../shared/components/auth";
import AccessDenied from "./system/AccessDenied";
import { useTranslation } from "react-i18next";
import { getAgreementsState } from "features/Agreements/selectors";
import { configureUnloadListener, removeUnloadListener } from "setup";
import { getPersistentQueryParamState } from "features/System";

const PrincipalApp = ({ isSmallScreen, showNotifications, currentUser }) => {
  const dispatch = useDispatch();

  const { isInternalUser, isSupplierAdminUser } = currentUser;
  const { filters: agreementsFilters } = useSelector(getAgreementsState);
  const { customer } = useSelector(getPersistentQueryParamState);
  const clientCode = customer ?? agreementsFilters?.client?.code;

  window.onpopstate = function () {
    history.go(1);
  };

  // auto logout on tab or window close, as well as page refresh
  useEffect(() => {
    if (!process.env.DISABLE_LOGOUT_ON_BROWSER_UNLOAD_EVENT) {
      const logOut = () => {
        dispatch(AuthActions.deauthenticate());
        window.removeEventListener("unload", logOut);
        removeUnloadListener();
      };

      configureUnloadListener();
      window.addEventListener("unload", logOut);
    }
  }, [dispatch]);

  const { t } = useTranslation("system");
  const blockAccess = !clientCode && !isInternalUser && !isSupplierAdminUser;
  const hideMenu = !!customer;

  return (
    <section
      style={{ display: "flex", height: "100%", flexDirection: "column" }}
      onDragStart={preventDragHandler}
    >
      {!isSmallScreen && <MainNav hideMenu={hideMenu} />}
      <MobileWrapper isSmallScreen={isSmallScreen}>
        <React.Fragment>
          {!blockAccess ? (
            <Switch>
              <Route path={AppRoutes.Agreements} component={AgreementsRouter} />
              <Route
                path={AppRoutes.DataUploads}
                component={DataUploadsRouter}
              />
              <Route path={ClientRoutes.Overview()} component={ClientsRouter} />
              <Route
                exact
                path={AppRoutes.Clients}
                component={ClientsListPage}
              />
              <Route path={DealerRoutes.Overview()} component={DealersRouter} />
              {isInternalUser && (
                <Route
                  exact
                  path={AppRoutes.DealerOffices}
                  component={DealersListPage}
                />
              )}
              {/* These two routes have been asked to be removed, we have commented them out 
              incase they change their minds in the future and ask for them to be
              put back in.

              <Route path={AppRoutes.Settings} component={SettingsRouter} />
              <Route path={SettingsRoutes.Services} component={ServicesPage} /> */}
              <Route path={SystemRoutes.Account} component={AccountPage} />
              <Route path={AppRoutes.Users} component={UserRouter} />
              <Route
                path={AppRoutes.Notification}
                component={NotificationsRouter}
              />
              <Route render={() => <Redirect to={AppRoutes.Agreements} />} />
            </Switch>
          ) : (
            <Switch>
              <Route path={SystemRoutes.Account} component={AccountPage} />
              <Route path={AppRoutes.Users} component={UserRouter} />
              <Route
                path={AppRoutes.Notification}
                component={NotificationsRouter}
              />
              <Route
                render={() => (
                  <AccessDenied
                    message={t("Please_log_in_via_your_crm_system")}
                  />
                )}
              />
            </Switch>
          )}
        </React.Fragment>
      </MobileWrapper>
      <NotificationStack
        position="bottomLeft"
        className={classNames(showNotifications && "not-empty")}
      />
    </section>
  );
};

const mapStateToProps = (state) => {
  const { screenSize } = getResponsiveState(state);
  const { events } = getNotificationState(state);
  return {
    isSmallScreen: screenSize === "small",
    showNotifications: Array.isArray(events) && events.length > 0,
  };
};

export default compose(
  withRouter,
  withCurrentUser,
  withPermissions,
  connect(mapStateToProps),
)(PrincipalApp);
