import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { FormBuilder, Form } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";
import { setRequiresRecalculation } from "features/Sheets/ManageSheet";
import { useDispatch } from "react-redux";
import { withCurrentUser } from "features/../../../shared/components/auth";

import FixedFieldType from "constants/forms/FixedFieldType";
import { FixedInputField } from "./FixedInput";

export const CheckboxFields = {
  MaxSelected: "maxSelected",
  MinSelected: "minSelected",
  FixedValueSelected: "fixedValueSelected",
  RangeSelected: "rangeSelected",
  NoFixing: "noFixing",
};

export const InputFields = {
  Max: "max",
  Min: "min",
  Fixed: "fixed",
  RangeFrom: "rangeFrom",
  RangeTo: "rangeTo",
};

export const checkboxFieldToFixType = (checkboxField) => {
  let mappings = {};
  mappings[CheckboxFields.MaxSelected] = FixedFieldType.Max;
  mappings[CheckboxFields.MinSelected] = FixedFieldType.Min;
  mappings[CheckboxFields.FixedValueSelected] = FixedFieldType.Set;
  mappings[CheckboxFields.RangeSelected] = FixedFieldType.Range;
  mappings[CheckboxFields.NoFixing] = FixedFieldType.None;

  return mappings[checkboxField];
};

export const inputFieldToCheckboxField = (inputField) => {
  let mappings = {};

  mappings[InputFields.Max] = CheckboxFields.MaxSelected;
  mappings[InputFields.Min] = CheckboxFields.MinSelected;
  mappings[InputFields.Fixed] = CheckboxFields.FixedValueSelected;
  mappings[InputFields.RangeFrom] = CheckboxFields.RangeSelected;
  mappings[InputFields.RangeTo] = CheckboxFields.RangeSelected;

  return mappings[inputField];
};

export const fixTypeToCheckboxField = (fixType) => {
  let mappings = {};
  mappings[FixedFieldType.Max] = CheckboxFields.MaxSelected;
  mappings[FixedFieldType.Min] = CheckboxFields.MinSelected;
  mappings[FixedFieldType.Set] = CheckboxFields.FixedValueSelected;
  mappings[FixedFieldType.Range] = CheckboxFields.RangeSelected;
  mappings[FixedFieldType.None] = CheckboxFields.NoFixing;

  return mappings[fixType];
};

export const fixTypeToInputFields = (fixType) => {
  let mappings = {};
  mappings[FixedFieldType.Max] = [InputFields.Max];
  mappings[FixedFieldType.Min] = [InputFields.Min];
  mappings[FixedFieldType.Set] = [InputFields.Fixed];
  mappings[FixedFieldType.Range] = [InputFields.RangeFrom, InputFields.RangeTo];
  mappings[FixedFieldType.None] = [];

  return mappings[fixType];
};

const clearAndSetCheckboxFields = (formData, fixedFieldTypeSelected) => {
  const fixType = checkboxFieldToFixType(fixedFieldTypeSelected);
  if (!fixType) return;

  Object.values(CheckboxFields).forEach((f) => {
    formData[f] = false;
  });
  formData[fixedFieldTypeSelected] = true;
  formData.fixType = fixType;
};

const onFormChange = (field, applyChanges, formProps) => {
  const { value, onChange } = formProps;

  let newFormData = applyChanges(value);

  // Update selected type if radio button selected
  if (Object.values(CheckboxFields).some((k) => k === field)) {
    clearAndSetCheckboxFields(newFormData, field);
  }

  // Update selected type if corresponding input amended
  if (Object.values(InputFields).some((k) => k === field)) {
    clearAndSetCheckboxFields(newFormData, inputFieldToCheckboxField(field));
  }

  onChange(field, newFormData, () => applyChanges(newFormData));

  return newFormData;
};

const getInitialData = (fixType, values) => {
  let formData = {};

  // Set the checkbox values
  Object.values(CheckboxFields).forEach((f) => {
    formData[f] = false;
  });
  formData[fixTypeToCheckboxField(fixType)] = true;

  // Set the input values
  Object.values(InputFields).forEach((f) => {
    formData[f] = null;
  });

  const inputFieldsToPopulate = fixTypeToInputFields(fixType);
  if (inputFieldsToPopulate.length >= 1) {
    formData[inputFieldsToPopulate[0]] = values[0];
  }
  if (inputFieldsToPopulate.length >= 2) {
    formData[inputFieldsToPopulate[1]] = values[1];
  }

  return formData;
};

const getSubmitResponse = (formData, currentUser) => {
  let response = {};

  if (formData[CheckboxFields.RangeSelected]) {
    response["isFixed"] = false;
    response["minimumValue"] = formData.rangeFrom;
    response["maximumValue"] = formData.rangeTo;
  } else if (formData[CheckboxFields.MinSelected]) {
    response["isFixed"] = false;
    response["minimumValue"] = formData.min;
    response["maximumValue"] = null;
  } else if (formData[CheckboxFields.MaxSelected]) {
    response["isFixed"] = false;
    response["maximumValue"] = formData.max;
    response["minimumValue"] = null;
  }

  if (formData[CheckboxFields.FixedValueSelected]) {
    response["value"] = formData.fixed;
    response["isFixed"] = true;
    response["minimumValue"] = null;
    response["maximumValue"] = null;
  }
  if (formData[CheckboxFields.NoFixing]) {
    response["isFixed"] = false;
    response["minimumValue"] = null;
    response["maximumValue"] = null;
  }
  return response;
};

/**
 * The modal-style popup for editing 'forced values' - fix type, fix value(s)
 */
const FixedInputEditor = ({
  values,
  fixedValueOnly,
  fixType,
  onSubmitted,
  toggleAction,
  minorCurrency,
  decimalPlaces,
  minAllowValue,
  maxAllowValue,
  disabled,
  currentUser,
}) => {
  const { t } = useTranslation("sheetTranslation");
  const dispatch = useDispatch();
  const [frmData, setFrmData] = useState({});
  return (
    <FormBuilder
      initialData={getInitialData(fixType, values)}
      onSubmitted={() => {
        dispatch(setRequiresRecalculation());
        onSubmitted(getSubmitResponse(frmData, currentUser));
      }}
      renderForm={(formProps, state, events) => (
        <div className="modal-styled-popup fixed-input-editor">
          <div className="header">{t("FixedInput.InputEditor.Header")}</div>
          <div className="content">
            <Form
              {...formProps}
              disabled={disabled}
              className="highlighted-inputs"
              onChange={(field, data, applyChanges) => {
                const newFormData = onFormChange(
                  field,
                  applyChanges,
                  formProps
                );
                if (newFormData) {
                  setFrmData(newFormData);
                }
              }}
            >
              <div className="fixed-fields-group">
                {!fixedValueOnly && (
                  <div className="fixed-fields-row">
                    <div className="fixed-field-col">
                      <Form.Checkbox
                        radio
                        label={t("FixedInput.InputEditor.ForcedMaximum")}
                        field={CheckboxFields.MaxSelected}
                      />
                    </div>
                    <div className="fixed-field-col">
                      <Form.Numeric
                        field={InputFields.Max}
                        width={15}
                        decimalPlaces={decimalPlaces}
                        minValue={minAllowValue}
                        maxValue={maxAllowValue}
                        className={"max-value"}
                        showErrors
                        required={formProps.value.maxSelected}
                      />
                    </div>
                  </div>
                )}

                {!fixedValueOnly && (
                  <div className="fixed-fields-row">
                    <div className="fixed-field-col">
                      <Form.Checkbox
                        radio
                        label={t("FixedInput.InputEditor.ForcedMinimum")}
                        field={CheckboxFields.MinSelected}
                      />
                    </div>
                    <div className="fixed-field-col">
                      <Form.Numeric
                        field={InputFields.Min}
                        width={15}
                        decimalPlaces={decimalPlaces}
                        minValue={minAllowValue}
                        maxValue={maxAllowValue}
                        className={"min-value"}
                        showErrors
                        required={formProps.value.minSelected}
                      />
                    </div>
                  </div>
                )}

                <div className="fixed-fields-row">
                  <div className="fixed-field-col">
                    <Form.Checkbox
                      radio
                      label={t("FixedInput.InputEditor.FixedValue")}
                      field={CheckboxFields.FixedValueSelected}
                    />
                  </div>
                  <div className="fixed-field-col">
                    <Form.Numeric
                      field={InputFields.Fixed}
                      width={15}
                      decimalPlaces={decimalPlaces}
                      minValue={minAllowValue}
                      maxValue={maxAllowValue}
                      showErrors
                      className={"fixed-value"}
                      required={formProps.value.fixedValueSelected}
                    />
                  </div>
                </div>

                {!fixedValueOnly && (
                  <div className="fixed-fields-row">
                    <div className="fixed-field-col">
                      <Form.Checkbox
                        radio
                        label={t("FixedInput.InputEditor.Range")}
                        field={CheckboxFields.RangeSelected}
                      />
                    </div>

                    <div className="fixed-field-col">
                      <Form.Numeric
                        field={InputFields.RangeFrom}
                        width={7}
                        decimalPlaces={decimalPlaces}
                        minValue={minAllowValue}
                        maxValue={maxAllowValue}
                        showErrors
                        className={"from-value"}
                        required={formProps.value.rangeSelected}
                      />
                      <Form.Numeric
                        field={InputFields.RangeTo}
                        width={7}
                        decimalPlaces={decimalPlaces}
                        minValue={minAllowValue}
                        maxValue={maxAllowValue}
                        className={"to-value"}
                        showErrors
                        required={formProps.value.rangeSelected}
                      />
                    </div>
                  </div>
                )}

                <div className="fixed-fields-row">
                  <div className="fixed-field-col">
                    <Form.Checkbox
                      radio
                      label={t("FixedInput.InputEditor.NoFixing")}
                      field={CheckboxFields.NoFixing}
                      className={"no-fixing"}
                    />
                  </div>
                  <div className="fixed-field-col"></div>
                </div>
              </div>
            </Form>
          </div>
          <div className="actions">
            <Button
              negative
              content={t("FixedInput.InputEditor.Cancel")}
              onClick={toggleAction}
            />
            <Button
              positive
              icon="checkmark"
              content={t("FixedInput.InputEditor.Done")}
              floated="right"
              onClick={events.onSubmit}
              disabled={!state.formValid || disabled}
            />
          </div>
        </div>
      )}
    />
  );
};

FixedInputEditor.propTypes = {
  fixType: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.number),
  onSubmit: PropTypes.func,
  toggleAction: PropTypes.func,
  fieldId: PropTypes.string,
};

export default withCurrentUser(FixedInputEditor);
