import React from "react";
import { Modal } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";
import TranslatedConfirmationModal from "./TranslatedConfirmationModal";
import TranslatedInformationModal from "./TranslatedInformationModal";
import TranslatedAddModal from './TranslatedAddModal';
import TranslatedEditModal  from './TranslatedEditModal';
import TranslatedDeleteModal  from './TranslatedDeleteModal';
import TranslatedUploadModal from './TranslatedUploadModal';
import { translatedModalProps } from "./helpers";


const TranslatedModal = (modalProps) => {
        
    const { t } = useTranslation("shared");    
    const props = translatedModalProps(modalProps, t);
    

    return (
        <Modal  {...props}
            submitLabel={props.submitLabel ? props.submitLabel : t("modals.modal.submitLabel")}
            cancelLabel={props.cancelLabel ? props.cancelLabel : t("modals.modal.cancelLabel")}>
            {props.children}
        </Modal>
    )
}

TranslatedModal.Add = TranslatedAddModal;
TranslatedModal.Delete = TranslatedDeleteModal;
TranslatedModal.Edit = TranslatedEditModal;
TranslatedModal.Confirmation = TranslatedConfirmationModal;
TranslatedModal.Information = TranslatedInformationModal;
TranslatedModal.Upload = TranslatedUploadModal

export default TranslatedModal;
