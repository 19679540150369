import React from "react";
import { Format, ListBuilder, Table } from "@redriver/cinnamon";
import { getNotificationsList } from "./actions";
import { TablePagination } from "components/pagination";
import { useTranslation } from "react-i18next";
import NotificationModal from "./NotificationModal";
import { Icon } from "semantic-ui-react";

const NotificationList = () => {
  const { t } = useTranslation("notifications");

  return (
    <React.Fragment>
      <h4>{t("header")}</h4>
      <ListBuilder
        withTable
        initialPagination={{ pageSize: 25, pageNumber: 1 }}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        loadAction={getNotificationsList}
        renderList={(tableProps, state, events) => (
          <div className="">
            <Table
              {...tableProps}
              dateKey="id"
              emptMessage={t("empty")}
              width="50%"
            >
              {(notification) =>
                notification && (
                  <React.Fragment>
                    <Table.Column
                      className={!notification?.isRead && "unread"}
                      width="5%"
                      field="level"
                      title={t("")}
                      render={() => {
                        let level =
                          notification.level == "Info"
                            ? { name: "warning circle", color: "green" }
                            : notification.level == "Warning"
                            ? { name: "warning sign", color: "yellow" }
                            : notification.level == "Critical"
                            ? { name: "warning", color: "red" }
                            : { name: "", color: "" };

                        return <Icon name={level.name} color={level.color} />;
                      }}
                    />
                    <Table.Column
                      className={!notification?.isRead && "unread"}
                      width="30%"
                      field="createdUtc"
                      title={t("time")}
                      render={(x) => (
                        <Format.DateTime
                          value={notification.createdUtc}
                          format="hh:mmA DD MMM YYYY"
                        />
                      )}
                    />
                    <Table.Column
                      className={!notification?.isRead && "unread"}
                      width="30%"
                      field="body"
                      title={t("message_title")}
                    />
                    <Table.Column
                      className={!notification?.isRead && "unread"}
                      width="1%"
                      align="right"
                      render={(value) => (
                        <NotificationModal
                          props={value}
                          onRefresh={events.onRefresh}
                        />
                      )}
                    />
                  </React.Fragment>
                )
              }
            </Table>
            <TablePagination
              pageNumber={state.pagination.pageNumber}
              onChange={events.onChangePagination}
              pageSize={state.pagination.pageSize}
              totalItems={state.total}
            />
          </div>
        )}
      />
    </React.Fragment>
  );
};

export default NotificationList;
