const FixedFieldTypes = {
  None: "None",
  Min: "Forced Min",
  Max: "Forced Max",
  Set: "Forced Value",
  Range: "Forced Value Between"
};

export const FixableTypes = {
  Numeric: "numeric",
  Boolean: "boolean",
  String: "string",
  Service: "service",
  Unit: "unit",
  Checkbox: "checkbox",
  Dropdown: "dropdown"
};

export const FixedFieldTypeOptions = [
  { text: FixedFieldTypes.None, value: FixedFieldTypes.None },
  { text: FixedFieldTypes.Min, value: FixedFieldTypes.Min },
  { text: FixedFieldTypes.Max, value: FixedFieldTypes.Max },
  { text: FixedFieldTypes.Set, value: FixedFieldTypes.Set },
  { text: FixedFieldTypes.Range, value: FixedFieldTypes.Range }
];

export default FixedFieldTypes;
