import React from "react";
import { useTranslation } from "react-i18next";
import { Format } from "@redriver/cinnamon";
import DataGrid from "./DataGrid";
import { FunderType } from "features/../../../shared/constants/enums";
import classNames from "classnames";

const CoTerminusGrid = ({ info, funderType }) => {
  const { t } = useTranslation("capitalCalc", {
    keyPrefix: "infoModal.funderTypes.coTerminus",
  });

  let titleKey = "";
  switch (funderType) {
    case FunderType.Funder:
      titleKey = "funderTitle";
      break;
    case FunderType.Dealer:
      titleKey = "supplierTitle";
      break;
    case FunderType.CoFunded:
      titleKey = "cofundedTitle";
      break;
  }

  const rows = [
    {
      className: "highlighted",
      rowClassNames: info.coTerminusRentals?.map((s) => [[s.fieldType]]),
      values: [t("value"), t("cfaRef"), t("minimumQuarters")],
    },
  ].concat(
    info.coTerminusRentals?.map((s, idx) => [
      <Format.Number
        key={idx}
        value={s.value}
        format={process.env.CURRENCY_CODE}
      />,
      s.cfaRef,
      s.minimumQuarters,
    ]),
  );

  return (
    <DataGrid
      className={classNames("coterminus", funderType?.toLowerCase())}
      columnCount={3}
      headerText={t(titleKey)}
      isFirstRowHeader={true}
      minimumRows={7}
      dataRows={rows}
      lastRow={[
        <Format.Number
          key={1}
          value={info.total}
          format={process.env.CURRENCY_CODE}
          decimalPlaces={2}
        />,
        "",
        "",
      ]}
    />
  );
};

export default CoTerminusGrid;
