import React from "react";
import { connect } from "react-redux";
import { produce } from "immer";
import { Button, Image, Icon, Popup } from "semantic-ui-react";
import { Form, Format, getAuthClaim, useFormField } from "@redriver/cinnamon";
import classNames from "classnames";
import { InlineLoader } from "features/../../../shared/components/forms/InlineLoader";
import {
  FixedInput,
  FixedCheckboxGroup,
  FixedLookupDropdown,
  SectionTotal,
} from "components/forms";
import {
  SheetViewType,
  PaperInclusiveChargeType,
  yesNoOpts,
  PaperSize,
  paperInclusiveChargeTypes,
  SheetType,
} from "features/../../../shared/constants/enums";

import { LookupTypes as SettingsLookupTypes } from "features/Settings";
import {
  getCalculationData,
  getSheetPaperInclusiveState,
  getSheetState,
  getSheetRatesState,
} from "../../selectors";
import { parseBool } from "features/utils";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import saveIcon from "features/../../../shared/assets/save.svg";
import { DownloadConfirmationModal } from "../Shared";
import {
  canCalculate,
  downloadPaperInclusiveSummary,
  revertPaperInclusive,
} from "../actions";
import {
  canSheetBeUpdated,
  isFieldFixed,
  hasComparisonSnapshot,
} from "../helpers";
import PaperStepAdditionalCosts from "./PaperStepAdditionCostsArray";
import PaperStepTermsAndTotaliser from "./PaperStepTermsAndTotaliser";
import PaperStepCloseModal from "./PaperStepCloseModal";
import { useTranslation } from "react-i18next";
import {
  PaperDescriptionDropdown,
  PaperSkuDropdown,
} from "features/../../../shared/components/forms";

const CurrentSpendColumn = ({
  col,
  field,
  hideNonA3,
  hideFixedIndicator,
  disabled,
}) => {
  let id = `${field}-pw`;

  return (
    <div className="current-spend-container">
      <div className="paper-size">{col}</div>
      <div className="current-spend-columns">
        <Form.Object
          field={field}
          propagateUpdates="always"
          disabled={disabled}
        >
          <div className="description value">
            <Form.Input field="description" />
          </div>
          <div className="value">
            <FixedLookupDropdown
              id={id}
              fluid
              field="paperWeight"
              lookup={SettingsLookupTypes.PaperWeights}
              customEditorData={{ urlRoute: "fixed-paper-weights" }}
              className="fix-paperweight-dropdown"
              hideIndicator={hideFixedIndicator}
              displayPriority={4}
            />
          </div>
          <div className="value">
            <FixedInput
              field="totalReamsBoughtPerQuarter"
              fluid
              formatNumber
              decimalPlaces={0}
              minAllowValue={0}
              hideIndicator={hideFixedIndicator}
              displayPriority={4}
            />
          </div>
          <div className="value">
            <FixedInput
              field="quantityPerReam"
              fluid
              formatNumber
              decimalPlaces={0}
              minAllowValue={0}
              hideIndicator={hideFixedIndicator}
              displayPriority={4}
            />
          </div>
          <div className="value">
            <FixedInput
              fluid
              formatNumber
              currency
              field="pricePerReam"
              minAllowValue={0}
              hideIndicator={hideFixedIndicator}
              displayPriority={4}
            />
          </div>
          <div className="value">
            <FixedInput
              field="reamsPerBox"
              fluid
              formatNumber
              decimalPlaces={0}
              minAllowValue={0}
              hideIndicator={hideFixedIndicator}
              displayPriority={4}
            />
          </div>
          {!hideNonA3 && (
            <React.Fragment>
              <div className="value charges">
                <FixedInput
                  field="deliveryChargesPerQuarter"
                  fluid
                  formatNumber
                  currency
                  minAllowValue={0}
                  hideIndicator={hideFixedIndicator}
                  displayPriority={4}
                />
              </div>
              <div className="value charges">
                <FixedInput
                  field="otherChargesPerQuarter"
                  fluid
                  formatNumber
                  currency
                  minAllowValue={0}
                  hideIndicator={hideFixedIndicator}
                  displayPriority={4}
                />
              </div>
            </React.Fragment>
          )}
        </Form.Object>
      </div>
    </div>
  );
};

const ManagedInclusiveSpendColumn = ({
  col,
  field,
  hideFixedIndicator,
  sheetId,
  papers,
  paperSize,
  quarterlyServiceAdjustment,
  quarterlyServiceDifference,
  preventUpdates,
  impersonatedUser,
}) => {
  const [row, setRow] = useFormField(field);

  const updateRow = (paper, pricePerReamValue) => {
    const updatedRow = produce(row, (draftRow) => {
      draftRow.pricePerReam.value = pricePerReamValue;
      draftRow.paper = paper;
    });
    setRow(updatedRow);
  };

  const clearRow = (leaveDescription) => {
    const updatedRow = produce(row, (draftRow) => {
      if (!leaveDescription) draftRow.description = null;
      else draftRow.paperId = null;

      draftRow.pricePerReam.value = ""; // Do not set to null, this prevents the UI updating
      draftRow.paper = {
        id: undefined,
        weight: "",
        note: "",
        colour: "",
        qtyPerReam: "",
        reamsPerBox: "",
        width: "",
        length: "",
      };
    });
    setRow(updatedRow);
  };

  return (
    <div className="current-spend-container">
      <div className="paper-size">{col}</div>
      <div className="current-spend-columns">
        <Form.Object field={field} propagateUpdates="always">
          <ManagedInclusiveSpendColumnForm
            hideFixedIndicator={hideFixedIndicator}
            sheetId={sheetId}
            papers={papers}
            paperSize={paperSize}
            updateRow={updateRow}
            clearRow={clearRow}
            row={row}
            quarterlyServiceAdjustment={quarterlyServiceAdjustment}
            quarterlyServiceDifference={quarterlyServiceDifference}
            preventUpdates={preventUpdates}
            impersonatedUser={impersonatedUser}
          />
        </Form.Object>
      </div>
    </div>
  );
};

const ManagedInclusiveSpendColumnForm = ({
  hideFixedIndicator,
  sheetId,
  papers,
  paperSize,
  updateRow,
  clearRow,
  row,
  preventUpdates,
  impersonatedUser,
}) => {
  const pricePerReamIsFixed =
    row?.pricePerReam && isFieldFixed(row.pricePerReam);

  const [_, setPaperId] = useFormField("paperId");

  const setPaperDefault = ({ description }) => {
    let selected;

    const filteredPapers = papers?.filter(
      (x) => x.paperSize == paperSize && x.description == description,
    );

    if (filteredPapers.length == 1) {
      selected = filteredPapers[0];
    }

    if (selected) {
      updateSelected(selected);
      setPaperId(selected.id);
    }
  };

  const updateFields = () => {
    const paperId = row?.paperId;
    if (paperId && paperId == row.paper.id) return;

    const selected = papers.find((obj) => {
      return obj.id === paperId;
    });

    if (selected) {
      updateSelected(selected);
    }
  };

  const updateSelected = (selected) => {
    let updatedPaper = {
      weight: selected.weight,
      id: selected.id,
      note: selected.note,
      colour: selected.colour,
      sheetsPerReam: selected.sheetsPerReam,
      reamsPerBox: selected.reamsPerBox,
      width: selected.width,
      length: selected.length,
    };

    updateRow(updatedPaper, selected.defaultPrice);
  };
  return (
    <React.Fragment>
      <div className="description value">
        <PaperDescriptionDropdown
          sheetId={sheetId}
          paperSize={paperSize}
          field="description"
          placeholder="Description..."
          disabled={pricePerReamIsFixed || preventUpdates}
        />
        <Icon
          name="close"
          disabled={pricePerReamIsFixed || preventUpdates}
          onClick={
            pricePerReamIsFixed || preventUpdates ? null : () => clearRow()
          }
        />
        <Form.Trigger
          field="description"
          event="onChange"
          action={() => (
            clearRow(true), setPaperDefault({ description: row?.description })
          )}
        />
      </div>
      <div className="sku">
        <PaperSkuDropdown
          paperSize={paperSize}
          sheetId={sheetId}
          field="paperId"
          description={row?.description}
          placeholder="Sku..."
          disabled={pricePerReamIsFixed || preventUpdates}
        />
        <Form.Trigger
          field="paperId"
          event="onChange"
          action={updateFields({ paperId: row?.paperId })}
        />
      </div>
      <Form.Object field="paper" propagateUpdates="always">
        <div className="note value">
          <Form.Input disabled field="note" className="full-opacity" />
          <Popup
            trigger={<Icon name="info circle" />}
            on="click"
            position="right center"
            style={{ zIndex: 10000 }}
          >
            <Popup.Content>
              <p>{row?.paper?.note}</p>
            </Popup.Content>
          </Popup>
        </div>
        <div className="small value">
          <Form.Input disabled field="width" />
        </div>
        <div className="small value">
          <Form.Input disabled field="length" />
        </div>
        <div className="small value">
          <Form.Input disabled field="weight" />
        </div>
        <div className="small value">
          <Form.Input disabled field="colour" />
        </div>
        <div className="small value">
          <Form.Input disabled field="sheetsPerReam" />
        </div>
      </Form.Object>
      <PermissionCheck target={Targets.PaperPrice} action={Actions.View}>
        {impersonatedUser.canViewPaperPrice && (
          <div className="small value">
            <FixedInput
              field="pricePerReam"
              fluid
              formatNumber
              currency
              minAllowValue={0}
              hideIndicator={hideFixedIndicator}
              displayPriority={4}
              fixedValueOnly
            />
          </div>
        )}
      </PermissionCheck>
      <Form.Object field="paper" propagateUpdates="always">
        <div className="small value">
          <Form.Input disabled field="reamsPerBox" />
        </div>
      </Form.Object>
    </React.Fragment>
  );
};

const PaperStepDetails = ({
  calculating,
  modalProps,
  sheetViewType,
  sheetData,
  sheetId,
  units,
  costPerUnit,
  totalIncMinDelivery,
  sheetCanBeUpdated,
  totalAdditionalCosts,
  contractedQtrDeliveryCharge,
  paperCurrentSpendMissingAtTimeOfSnapshot,
  paperInclusiveBackup,
  revertPaperInclusive,
  impersonatedUser,
  papers,
  systemScheduleItems,
  largeFormatGroups,
  minQuarterlyPayment,
  minQuarterlySnapshotPayment,
  paperIncomeAdjustment,
  quarterlyServiceAdjustment,
  quarterlyServiceDifference,
  isRewrite,
  preventUpdates,
  paperInclusive,
  userArea,
}) => {
  const hideFixedIndicator = !impersonatedUser.canEditAdminFields;
  const { t } = useTranslation();
  const hasSnapshot = hasComparisonSnapshot(
    systemScheduleItems,
    largeFormatGroups,
  );

  let currentSpendDisabled =
    hasSnapshot && !paperCurrentSpendMissingAtTimeOfSnapshot;

  const disablePaperAdjustmentFields =
    !minQuarterlyPayment || !hasSnapshot || !minQuarterlySnapshotPayment;

  return (
    <div>
      <div className="header">
        <h3>{t("paperInclusive:modal:title")}</h3>
        <PaperStepCloseModal
          isDirty={sheetData.isDirty}
          onSubmit={() => {
            revertPaperInclusive(paperInclusiveBackup);
            modalProps.onCancel();
          }}
        />
      </div>
      <div className="overflow">
        <div className="customer-current-spend">
          <div>
            <h5>{t("paperInclusive:modal:customerCurrentSpend:title")}</h5>
            <div className="paper-inclusive-subgrid">
              <div className="headers">
                <div className="description">
                  {t(
                    "paperInclusive:modal:customerCurrentSpend:productDescription",
                  )}
                </div>
                <div>
                  {t("paperInclusive:modal:customerCurrentSpend:weight")}
                </div>
                <div>
                  {t(
                    "paperInclusive:modal:customerCurrentSpend:qtrReamsBought",
                  )}
                </div>
                <div>
                  {t("paperInclusive:modal:customerCurrentSpend:qtyPerReam")}
                </div>
                <div>
                  {t("paperInclusive:modal:customerCurrentSpend:pricePerReam")}
                </div>
                <div>
                  {t("paperInclusive:modal:customerCurrentSpend:reamsPerBox")}
                </div>
                <div className="charges">
                  {t(
                    "paperInclusive:modal:customerCurrentSpend:deliveryChargePerQtr",
                  )}
                </div>
                <div className="charges">
                  {t(
                    "paperInclusive:modal:customerCurrentSpend:otherChargesPerQtr",
                  )}
                </div>
              </div>
              <CurrentSpendColumn
                col={t("paperInclusive:A4")}
                field="a4CustomerCurrentSpend"
                hideFixedIndicator={hideFixedIndicator}
                disabled={currentSpendDisabled || preventUpdates}
                paperInclusive={paperInclusive}
                userArea={userArea}
              />
              <Form.If
                condition={({ fields }) =>
                  fields.paperInclusiveChargeType.value ===
                  PaperInclusiveChargeType.A4A3
                }
              >
                <CurrentSpendColumn
                  hideNonA3
                  col={t("paperInclusive:A3")}
                  field="a3CustomerCurrentSpend"
                  hideFixedIndicator={hideFixedIndicator}
                  disabled={currentSpendDisabled || preventUpdates}
                  paperInclusive={paperInclusive}
                  userArea={userArea}
                />
              </Form.If>
            </div>
          </div>
        </div>
        <SectionTotal
          label={t("paperInclusive:modal:customerCurrentSpend:units")}
          className="paper-units-totaliser"
        >
          <InlineLoader size="tiny" isActive={calculating} />
          <Format.Number
            value={units}
            format={process.env.CURRENCY_CODE}
            prefix=""
            className={classNames(
              "calculable-field",
              "paper-units",
              calculating && "value-loading",
              units < 0 && "negative",
            )}
          />
          <Format.Number
            value={costPerUnit || 0}
            format={process.env.CURRENCY_CODE}
            prefix={
              t("paperInclusive:modal:customerCurrentSpend:costPerUnit") +
              " " +
              (process.env.USE_MINOR_CURRENCY ? "" : process.env.MAJOR_CURRENCY)
            }
            suffix={
              process.env.USE_MINOR_CURRENCY && process.env.MINOR_CURRENCY
            }
            decimalPlaces={{
              min: 0,
              max: process.env.USE_MINOR_CURRENCY ? 3 : 5,
            }}
            className={classNames(
              "calculable-field",
              calculating && "value-loading",
              costPerUnit < 0 && "negative",
            )}
          />
        </SectionTotal>
        <div className="pink-bar" />
      </div>
      <div className="radio-groups">
        <PermissionCheck
          target={Targets.Sheet}
          action={Actions.ViewPaperSizeOption}
        >
          <div className="left-side">
            <FixedCheckboxGroup
              inline
              radio
              field="paperInclusiveChargeType"
              label={
                <div className="sheet-radio-title">
                  <p>{t("paperInclusive:modal:chargeBasedOn")}</p>
                </div>
              }
              options={paperInclusiveChargeTypes(t)}
              customEditorData={{ urlRoute: "pi-charge-types" }}
              className="required-field sheet-radio-group"
              hideIndicator={hideFixedIndicator}
              displayPriority={4}
            />
          </div>
        </PermissionCheck>
        <div className="right-side">
          <PermissionCheck target={Targets.SheetAdmin} action={Actions.Edit}>
            {(sheetViewType === SheetViewType.SupplierAdmin ||
              sheetViewType === SheetViewType.IpsAdmin) && (
              <React.Fragment>
                <Form.RadioGroup
                  inline
                  radio
                  field="paperInclusiveDisableMinLogic"
                  label={
                    <div className="sheet-radio-title">
                      <p>{t("paperInclusive:modal:disableMinLogic")}</p>
                    </div>
                  }
                  options={yesNoOpts}
                  customEditorData={{ urlRoute: "pi-charge-types" }}
                  className="required-field sheet-radio-group min-logic"
                />

                <Form.If
                  condition={({ fields }) =>
                    parseBool(fields.isPaperInclusive.value) &&
                    fields.paperInclusiveChargeType.value ===
                      PaperInclusiveChargeType.A4A3 &&
                    (sheetViewType === SheetViewType.SupplierAdmin ||
                      sheetViewType === SheetViewType.IpsAdmin ||
                      !sheetData.restrictions
                        .paperInclusiveSingleClickA3AvailableToDealerOnly)
                  }
                >
                  <FixedCheckboxGroup
                    inline
                    radio
                    isBoolean
                    field="isCostEffectiveA3"
                    label={
                      <div className="sheet-radio-title">
                        <p>{t("paperInclusive:modal:singleClickA3")}</p>
                      </div>
                    }
                    options={yesNoOpts}
                    className="required-field sheet-radio-group"
                    hideIndicator={hideFixedIndicator}
                    displayPriority={4}
                  />
                </Form.If>
              </React.Fragment>
            )}
          </PermissionCheck>
        </div>
      </div>
      <div className="overflow">
        <div className="contracted-managed-pi">
          <h5>{t("paperInclusive:modal:costPerReam:title")}</h5>
          <div className="customer-current-spend">
            <div className="paper-inclusive-subgrid">
              <div className="headers no-justify">
                <div className="description">
                  {t("paperInclusive:modal:costPerReam:productDescription")}
                </div>
                <div className="sku">
                  {t("paperInclusive:modal:costPerReam:sku")}
                </div>
                <div className="note">
                  {t("paperInclusive:modal:costPerReam:note")}
                </div>
                <div className="small">
                  {t("paperInclusive:modal:costPerReam:width")}
                </div>
                <div className="small">
                  {t("paperInclusive:modal:costPerReam:length")}
                </div>
                <div className="small">
                  {t("paperInclusive:modal:costPerReam:weight")}
                </div>
                <div className="small">
                  {t("paperInclusive:modal:costPerReam:colour")}
                </div>
                <div className="small">
                  {t("paperInclusive:modal:costPerReam:qtyPerReam")}
                </div>
                <PermissionCheck
                  target={Targets.PaperPrice}
                  action={Actions.View}
                >
                  {impersonatedUser.canViewPaperPrice && (
                    <div className="small">
                      {t("paperInclusive:modal:costPerReam:pricePerReam")}
                    </div>
                  )}
                </PermissionCheck>
                <div className="small reams-per-box">
                  {t("paperInclusive:modal:costPerReam:reamsPerBox")}
                </div>
              </div>
              <ManagedInclusiveSpendColumn
                col={t("paperInclusive:A4")}
                field="a4ContractedPaperSpend"
                hideFixedIndicator={hideFixedIndicator}
                sheetId={sheetId}
                preventUpdates={preventUpdates}
                papers={papers}
                paperSize={PaperSize.A4}
                impersonatedUser={impersonatedUser}
              />
              <Form.If
                condition={({ fields }) =>
                  fields.paperInclusiveChargeType.value ===
                  PaperInclusiveChargeType.A4A3
                }
              >
                <ManagedInclusiveSpendColumn
                  col={t("paperInclusive:A3")}
                  field="a3ContractedPaperSpend"
                  hideFixedIndicator={hideFixedIndicator}
                  sheetId={sheetId}
                  preventUpdates={preventUpdates}
                  papers={papers}
                  paperSize={PaperSize.A3}
                  impersonatedUser={impersonatedUser}
                />
              </Form.If>
            </div>
          </div>
        </div>
        <div className="delivery-charge">
          <div>{t("paperInclusive:modal:deliveryChargePerQtr")}</div>
          <FixedInput
            field="contractedQtrDeliveryCharge"
            fluid
            formatNumber
            currency
            minAllowValue={0}
            hideIndicator={hideFixedIndicator}
            displayPriority={4}
          />
          <div>
            {t("paperInclusive:modal:noOfDeliveries", {
              price: `${process.env.MAJOR_CURRENCY}10`,
            })}
          </div>
        </div>
        <div>
          <h5> {t("paperInclusive:modal:additionalCostPerQuarter:title")}</h5>
          <PaperStepAdditionalCosts />
        </div>
        <div className="additional-totals">
          <p>
            <span className="bold">
              {t("paperInclusive:modal:additionalCostPerQuarter:total")}
            </span>
            <InlineLoader size="tiny" isActive={calculating} />
            <Format.Number
              value={totalAdditionalCosts}
              format={process.env.CURRENCY_CODE}
              prefix={process.env.MAJOR_CURRENCY}
              thousandSeparator={true}
              decimalPlaces={2}
              minimum={0}
              className={classNames(
                "totaliser-value",
                "calculable-field",
                calculating && "value-loading",
                totalIncMinDelivery < 0 && "negative",
              )}
            />
          </p>
          <p>
            <span className="bold">
              {t(
                "paperInclusive:modal:additionalCostPerQuarter:totalIncludingDelivery",
              )}
            </span>
            <InlineLoader size="tiny" isActive={calculating} />
            <Format.Number
              value={
                Number(totalAdditionalCosts) +
                (contractedQtrDeliveryCharge
                  ? Number(contractedQtrDeliveryCharge.value)
                  : 0)
              }
              format={process.env.CURRENCY_CODE}
              prefix={process.env.MAJOR_CURRENCY}
              decimalPlaces={2}
              thousandSeparator={true}
              className={classNames(
                "totaliser-value",
                "calculable-field",
                calculating && "value-loading",
                totalIncMinDelivery < 0 && "negative",
              )}
            />
          </p>
        </div>
      </div>
      <PermissionCheck target={Targets.SheetAdmin} action={Actions.Edit}>
        {(sheetViewType === SheetViewType.SupplierAdmin ||
          sheetViewType === SheetViewType.IpsAdmin) && (
          <div className="rewrite-adjustment">
            <div className="backdated-rewrites">
              {t("paperInclusive:modal:rewrites")}
            </div>
            <div className="paper-income">
              <Form.Checkbox
                disabled={!isRewrite || !hasSnapshot || preventUpdates}
                field="paperIncomeAdjustment"
                label={t("paperInclusive:modal:paperIncomeAdjustment")}
              />
            </div>
            <table
              className={classNames(
                disablePaperAdjustmentFields && "paper-step-detail-disabled",
                "paper-adjustments",
              )}
            >
              <tbody>
                <tr>
                  <td>
                    {t(
                      "paperInclusive:modal:paperInclusiveAmountAtComparisonSnapshot",
                    )}
                  </td>
                  <td>
                    <InlineLoader size="tiny" isActive={calculating} />
                    {!disablePaperAdjustmentFields ? (
                      <Format.Number
                        value={minQuarterlySnapshotPayment || 0.0}
                        prefix={process.env.MAJOR_CURRENCY}
                        decimalPlaces={2}
                        thousandSeparator={true}
                        className={classNames(
                          "snapshot-amount",
                          "totaliser-value",
                          "calculable-field",
                          calculating && "value-loading",
                          "bold",
                        )}
                      />
                    ) : (
                      <span className="cin bold totaliser-value">-</span>
                    )}
                  </td>
                  <td>{t("paperInclusive:modal:difference")}</td>
                </tr>
                <tr>
                  <td>{t("paperInclusive:modal:paperPriceAdjusted")}</td>
                  <td>
                    <InlineLoader size="tiny" isActive={calculating} />
                    {!disablePaperAdjustmentFields ? (
                      <Format.Number
                        value={minQuarterlyPayment}
                        prefix={process.env.MAJOR_CURRENCY}
                        decimalPlaces={2}
                        thousandSeparator={true}
                        className={classNames(
                          "min-quarterly-payment",
                          "totaliser-value",
                          "calculable-field",
                          calculating && "value-loading",
                          "bold",
                        )}
                      />
                    ) : (
                      <span className="cin bold totaliser-value">-</span>
                    )}
                  </td>
                  <td>
                    <InlineLoader size="tiny" isActive={calculating} />
                    {!disablePaperAdjustmentFields ? (
                      <Format.Number
                        value={quarterlyServiceDifference}
                        prefix={process.env.MAJOR_CURRENCY}
                        decimalPlaces={2}
                        thousandSeparator={true}
                        className={classNames(
                          "quarterly-difference",
                          "totaliser-value",
                          "calculable-field",
                          calculating && "value-loading",
                          quarterlyServiceDifference < 0 && "negative",
                          "bold",
                        )}
                      />
                    ) : (
                      <span className="cin bold totaliser-value">-</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      {t("paperInclusive:modal:quarterlyServiceAdjustment")}
                    </span>
                  </td>
                  <td>
                    <InlineLoader size="tiny" isActive={calculating} />
                    {paperIncomeAdjustment && !disablePaperAdjustmentFields ? (
                      quarterlyServiceAdjustment > 0 ? (
                        <Format.Number
                          value={quarterlyServiceAdjustment || 0.0}
                          prefix={process.env.MAJOR_CURRENCY}
                          decimalPlaces={2}
                          thousandSeparator={true}
                          className={classNames(
                            "quarterly-service-adjustment",
                            "totaliser-value",
                            "calculable-field",
                            calculating && "value-loading",
                            "bold",
                            quarterlyServiceAdjustment < 0 && "negative",
                          )}
                        />
                      ) : (
                        <span className="cin bold totaliser-value">N/A</span>
                      )
                    ) : (
                      <span className="cin bold totaliser-value">-</span>
                    )}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </PermissionCheck>
      <div>
        <PaperStepTermsAndTotaliser calculating={calculating} />
      </div>{" "}
      <div className="custom-action-container">
        <PermissionCheck target={Targets.SheetAdmin} action={Actions.Edit}>
          {(sheetViewType === SheetViewType.IpsAdmin ||
            sheetViewType === SheetViewType.SupplierAdmin) &&
            impersonatedUser.canDownloadSheet && (
              <DownloadConfirmationModal
                disabled={!canCalculate(sheetData)}
                sheetId={sheetId}
                sheetDownloadFunction={downloadPaperInclusiveSummary}
                sheetCanBeUpdated={sheetCanBeUpdated}
                buttonContent={t("paperInclusive:modal:buttons:print")}
              />
            )}
        </PermissionCheck>
        <Button
          primary
          onClick={modalProps.onCancel}
          className="cancel save-button custom-action"
          disabled={preventUpdates}
        >
          {t("paperInclusive:modal:buttons:save")}
          <Image src={saveIcon} />
        </Button>
      </div>
    </div>
  );
};

const actions = {
  revertPaperInclusive,
};

const mapStateToProps = (state) => {
  const {
    summary: {
      units,
      costPerUnit,
      totalIncMinDelivery,
      quarterlyContractVol,
      contractVolumeInBoxedReams,
      amethystAmount,
      minDiffToCreateInAmethyst,
      likelyMinGmBelowActioned,
      totalIncludedMinimumDeliveryCharge,
      totalAdditionalCosts,
    },
    minQuarterlySnapshotPayment,
    paperIncomeAdjustment,
    quarterlyServiceAdjustment,
    quarterlyServiceDifference,
  } = getSheetPaperInclusiveState(state);

  const rawCalculation = getCalculationData(state);
  const minQuarterlyPayment =
    rawCalculation?.breakdown?.paperInclusiveCosts?.minQuarterlyPayment;

  const { sheetData, sheetDetailsSaving, sheetViewType, calculating } =
      getSheetState(state),
    { sheetType } = getSheetRatesState(state);
  return {
    sheetDetailsSaving,
    sheetData,
    units,
    costPerUnit,
    totalIncMinDelivery,
    quarterlyContractVol,
    contractVolumeInBoxedReams,
    amethystAmount,
    minDiffToCreateInAmethyst,
    likelyMinGmBelowActioned,
    totalIncludedMinimumDeliveryCharge,
    sheetCanBeUpdated: canSheetBeUpdated(sheetData),
    sheetViewType,
    totalAdditionalCosts,
    contractedQtrDeliveryCharge:
      sheetData.paperInclusive.contractedQtrDeliveryCharge,
    paperCurrentSpendMissingAtTimeOfSnapshot:
      sheetData.paperInclusive.paperCurrentSpendMissingAtTimeOfSnapshot,
    calculating,
    impersonatedUser: sheetData.impersonatedUser,
    preventUpdates: sheetData.preventUpdates,
    papers: sheetData.defaults.papers,
    systemScheduleItems: sheetData.systemScheduleItems,
    largeFormatGroups: sheetData.largeFormatGroups,
    minQuarterlySnapshotPayment,
    minQuarterlyPayment,
    paperIncomeAdjustment,
    quarterlyServiceAdjustment,
    quarterlyServiceDifference,
    isRewrite:
      sheetType == SheetType.Rewrite ||
      sheetType == SheetType.RewriteNewMasterDocument,
    paperInclusive: sheetData.paperInclusive,
    userArea: getAuthClaim(state, "userArea"),
  };
};

export default connect(mapStateToProps, actions)(PaperStepDetails);
