import React from "react";
import { ModalBuilder } from "@redriver/cinnamon";
import { deleteRateGroup, canDeleteRateGroup } from "./actions";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { useTranslation } from "react-i18next";

const DeleteRateGroupModal = ({ open, setModalVisibility, onDeleted, id }) => {
  const  { t }  = useTranslation('supplierOffices', { keyPrefix:'rates.deleteRatesModal'});
  return(
  <ModalBuilder
    loadAction={canDeleteRateGroup}
    loadParams={{ rateGroupId: id }}
    submitAction={deleteRateGroup}
    submitParams={{ rateGroupId: id }}
    onSubmitted={res => {
      setModalVisibility(false);
      onDeleted(res);
    }}
    visible={open}
    onCancel={() => setModalVisibility(false)}
    renderModal={(modalProps, canDelete, { loading }) => (
      <TranslatedModal.Delete
        {...modalProps}
        header={t("header")}
        submitLabel={t("submitLabel")}
        submitDisabled={!(canDelete === true) && !loading}
      >
        {canDelete === true || loading
          ? t("deleteWarningA")
          : t("deleteWarningB")
        }
      </TranslatedModal.Delete>
    )}
  />
)};

export default DeleteRateGroupModal;
