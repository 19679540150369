import { isFieldFixed } from "features/Sheets/ManageSheet/helpers";

export function defaultServiceValuesIfEmpty(services, allServices) {
  if (!services || !services.filter((x) => x != null).length) return;

  services.forEach((s) => {
    const matchedService = allServices.find((x) => x.id == s.service.value);
    if (matchedService) {
      // Set value
      if (matchedService.defaultValue != null && !isFieldFixed(s.value)) {
        if (s.value.value == null || s.value.value == "") {
          s.value.value = matchedService.defaultValue;
        }
      }
    }
  });
}
