import { SettlementFieldType } from "features/../../../shared/constants/enums";


  export const updateServicesArrays = services => {
    services.mandatoryServices = services.services.filter(
      x => x.service.isMandatory == true
    );
  
    services.ipServices = services.services.filter(
      x => x.fieldType == SettlementFieldType.IP
    );
    services.adminServices = services.services.filter(
      x => x.fieldType == SettlementFieldType.Admin
    );
    services.services = services.services.filter(
      x =>
        x.fieldType == SettlementFieldType.Standard &&
        x.service.isMandatory == false
    );
  };

