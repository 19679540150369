import React from "react";
import { ModalBuilder, Form } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { inviteUsers } from "./actions";
import { TranslatedModal } from "features/../../../shared/components/modals";

const InviteUsers = ({ as: As = Button, onInvited, ...props }) => (
  <ModalBuilder
    withForm
    submitAction={inviteUsers}
    onSubmitted={onInvited}
    renderTrigger={showModal => <As onClick={showModal} {...props} />}
    renderModal={(modalProps, formProps) => (
      <TranslatedModal
        {...modalProps}
        header="Invite Users"
        submitLabel="Invite"
        submitIcon="mail"
      >
        <Form {...formProps}>
          <Form.Array
            field="users"
            autoAdd
            autoRemove
            propagateUpdates="always"
            minLength={1}
            minLengthError="Please invite at least one user"
          >
            {({ arrayIndex, parentFields }) => {
              const isFirstRow =
                arrayIndex === 0 ||
                !parentFields.users ||
                parentFields.users.length === 0;
              return (
                <Form.Group widths="equal">
                  <Form.Input
                    label={isFirstRow ? "First Name" : undefined}
                    field="firstName"
                    fluid
                    required
                  />
                  <Form.Input
                    label={isFirstRow ? "Last Name" : undefined}
                    field="lastName"
                    fluid
                    required
                  />
                  <Form.Email
                    label={isFirstRow ? "Email" : undefined}
                    field="email"
                    fluid
                    required
                  />
                </Form.Group>
              );
            }}
          </Form.Array>
        </Form>
      </TranslatedModal>
    )}
  />
);

export default InviteUsers;
