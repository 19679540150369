import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getNotificationState, removeNotification } from "@redriver/cinnamon";

const NotificationClearer = ({
  events,
  removeNotification,
  expirySeconds = 10,
  intervalSeconds = 5
}) => {
  useEffect(() => {
    setInterval(() => {
      removeExpiredNotifications();
    }, intervalSeconds * 1000);
  });

  const removeExpiredNotifications = () => {
    if (!events || !events.length) return;

    const now = Date.now();
    events
      .filter(e => e.creation < now - (expirySeconds || 10) * 1000)
      .forEach(event => {
        removeNotification(event.id);
      });
  };

  return null;
};

const mapStateToProps = state => {
  const { events } = getNotificationState(state);
  return {
    events
  };
};

const actions = {
  removeNotification
};

export default connect(
  mapStateToProps,
  actions
)(NotificationClearer);
