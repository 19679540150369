import React, { useRef, useState } from "react";
import { Loader, Dimmer, Icon, Button, Input } from "semantic-ui-react";

/*
 * https://github.com/wojtekmaj/react-pdf#configure-pdfjs-worker
 */
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { pdfjs, Document, Page } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url,
).toString();

const Spinner = () => (
  <Dimmer active>
    <Loader indeterminate />
  </Dimmer>
);

const ZoomControl = ({
  scaleDecimal,
  scaleLimit = 5.0,
  onChange: callback,
}) => {
  const scaleToPercentage = scale => scale * 100;
  const percentageToScale = percentage => percentage / 100;

  const debounce = useRef(undefined);
  const [errorState, setErrorState] = useState(false);
  const [displayValue, setDisplayValue] = useState(
    scaleToPercentage(scaleDecimal),
  );

  const onChange = (userInput, debounceMs = 500) => {
    clearTimeout(debounce.current);
    userInput = userInput?.toString() ?? "";
    setDisplayValue(userInput);
    if (!userInput.length) return;

    let match = /^\d*\.?\d{0,3}/.exec(userInput)?.at(0);
    if (match?.length !== userInput.length) {
      setErrorState(true);
      return;
    }

    let parsed = parseFloat(match);
    if (
      isNaN(parsed) ||
      parsed <= 0 ||
      parsed > scaleToPercentage(scaleLimit)
    ) {
      setErrorState(true);
      return;
    }

    errorState && setErrorState(false);
    debounce.current = setTimeout(
      () => callback && callback(percentageToScale(parsed)),
      debounceMs,
    );
  };

  return (
    <Input
      type="text"
      icon
      action
      actionPosition="left"
      value={displayValue}
      placeholder={100}
      error={errorState}
      onChange={event => onChange(event?.target?.value)}
    >
      <Button
        icon="zoom-out"
        disabled={errorState || scaleDecimal <= 0}
        onClick={() => {
          let floor = Math.max(scaleDecimal - 0.25, 0);
          onChange(Math.round(scaleToPercentage(floor)), 0);
        }}
      />
      <Button
        icon="zoom-in"
        disabled={errorState || scaleDecimal >= scaleLimit}
        onClick={() => {
          let ceiling = Math.min(scaleDecimal + 0.25, scaleLimit);
          onChange(Math.round(scaleToPercentage(ceiling)), 0);
        }}
      />
      <Icon name="percent" size="small" />
      <input />
    </Input>
  );
};

const PageControl = ({ currentPage, pageLimit = 1, onChange: callback }) => {
  const debounce = useRef(undefined);
  const [errorState, setErrorState] = useState(false);
  const [displayValue, setDisplayValue] = useState(currentPage);

  const onChange = (userInput, debounceMs = 500) => {
    clearTimeout(debounce.current);
    userInput = userInput?.toString() ?? "";
    setDisplayValue(userInput);
    if (!userInput.length) return;

    let match = /^\d*$/.exec(userInput)?.at(0);
    if (match?.length !== userInput.length) {
      setErrorState(true);
      return;
    }

    let parsed = parseFloat(match);
    if (isNaN(parsed) || parsed <= 0 || parsed > pageLimit) {
      setErrorState(true);
      return;
    }

    errorState && setErrorState(false);
    debounce.current = setTimeout(
      () => callback && callback(parsed),
      debounceMs,
    );
  };

  return (
    <React.Fragment>
      <Input
        type="text"
        icon
        action
        actionPosition="left"
        value={displayValue}
        placeholder={`1...${pageLimit}`}
        disabled={pageLimit <= 1}
        error={errorState}
        onChange={event => onChange(event?.target?.value)}
      >
        <Button
          icon="angle up"
          disabled={errorState || currentPage <= 1}
          onClick={() => onChange(currentPage - 1, 0)}
        />
        <Button
          icon="angle down"
          disabled={errorState || currentPage >= pageLimit}
          onClick={() => onChange(currentPage + 1, 0)}
        />
        <Icon name="book" size="small" />
        <input />
      </Input>
      <Input action>
        <Button
          icon="angle up double"
          disabled={currentPage <= 1}
          onClick={() => onChange(1, 0)}
        />
        <Button
          icon="angle down double"
          disabled={currentPage >= pageLimit}
          onClick={() => onChange(pageLimit, 0)}
        />
      </Input>
    </React.Fragment>
  );
};

const InlinePdf = ({
  src,
  initialPage = 1,
  initialScale = 1,
  alignControls = "right",
  documentProps = {},
  pageProps = {},
}) => {
  const [scale, setScale] = useState(initialScale);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(initialPage);

  return (
    <div className="pdfjs-inline">
      <div className="canvas-container">
        <div className="tray" data-align-controls={alignControls}>
          <div className="canvas-controls">
            <ZoomControl scaleDecimal={scale} onChange={setScale} />
            <PageControl
              onChange={setCurrentPage}
              currentPage={currentPage}
              pageLimit={totalPages}
            />
          </div>
        </div>
        <div className="zoom-boundary">
          <Document
            loading={Spinner}
            {...documentProps}
            file={src ?? documentProps?.file}
            renderMode="canvas" //always override this as SVG engine has been depreacted in upstream pdf.js
            className={`document-canvas-container ${
              documentProps?.className ?? ""
            }`}
            onLoadSuccess={result => {
              const { numPages } = result;
              if (!isNaN(numPages)) setTotalPages(numPages);
              documentProps?.onLoadSuccess &&
                documentProps.onLoadSuccess(result);
            }}
          >
            <Page
              loading={Spinner}
              {...pageProps}
              pageNumber={currentPage}
              scale={scale}
              className={`page-canvas-container ${pageProps?.className ?? ""}`}
            />
          </Document>
        </div>
      </div>
    </div>
  );
};

export default InlinePdf;
