import { SettlementFieldType } from "features/../../../shared/constants/enums";

  export const updateSettlementAndCoTerminusArrays = funderSection => {
    if (funderSection && funderSection.settlements) {
      funderSection.ipSettlements = funderSection.settlements.filter(
        x => x.fieldType == SettlementFieldType.IP
      );
      funderSection.adminSettlements = funderSection.settlements.filter(
        x => x.fieldType == SettlementFieldType.Admin
      );
      funderSection.settlements = funderSection.settlements.filter(
        x => x.fieldType == SettlementFieldType.Standard
      );
    }
    if (funderSection && funderSection.coTerminusRentals) {
      funderSection.ipCoTerminusRentals = funderSection.coTerminusRentals.filter(
        x => x.fieldType == SettlementFieldType.IP
      );
      funderSection.coTerminusRentals = funderSection.coTerminusRentals.filter(
        x => x.fieldType == SettlementFieldType.Admin
      );
    }
  };
