export default {
  IPSyndicateRates: "ipSyndicateRates",
  Rates: "rates",
  Capital: "capital",
  Services: "services",
  Schedule: "schedule",
  PaperInclusive: "paper",
  All: "all",
  ScheduleStability: "scheduleStability"
};
