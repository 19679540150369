import React from "react";
import { ModalBuilder } from "@redriver/cinnamon";
import { IconButton } from "features/../../../shared/components/buttons";
import { addClientAuthorisedSignatory } from "./actions";
import ClientAuthorisedSignatoryForm from "./ClientAuthorisedSignatoryForm";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";

const AddClientAuthorisedSignatory = ({
  as: As = IconButton,
  onAdded,
  clientId,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <ModalBuilder
      withForm
      submitAction={addClientAuthorisedSignatory}
      submitParams={{ clientId }}
      onSubmitted={onAdded}
      renderTrigger={showModal => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Add
          {...modalProps}
          header={t(
            "clients:tables:authorisedSignatories:newAuthorisedSignatory:heading"
          )}
        >
          <ClientAuthorisedSignatoryForm {...formProps} />
        </TranslatedModal.Add>
      )}
    />
  );
};

export default AddClientAuthorisedSignatory;
