import { ModalBuilder, Table, notifySuccess } from "@redriver/cinnamon";
import React from "react";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { submitSheet } from "./actions";
import { useDispatch } from "react-redux";

const SubmitSheet = ({ sheet, onSubmitted }) => {
  const { t } = useTranslation("agreements");
  const dispatch = useDispatch();
  const { sheetId, sheetName } = sheet;

  return (
    <ModalBuilder
      submitAction={submitSheet}
      submitParams={{ sheetId }}
      onSubmitted={() => {
        dispatch(notifySuccess("Sheet successfully submitted"));
        onSubmitted();
      }}
      renderTrigger={(onTrigger) => (
        <Table.MenuItem onClick={onTrigger}>
          {t("SheetActions.SubmitSheet.TriggerText")}
        </Table.MenuItem>
      )}
      renderModal={(modalProps) => (
        <TranslatedModal.Confirmation
          {...modalProps}
          header={t("SheetActions.SubmitSheet.ModalHeader")}
          content={t("SheetActions.SubmitSheet.ModalContent", { sheetName })}
        />
      )}
    />
  );
};

export default SubmitSheet;
