import React from "react";
import { connect } from "react-redux";
import { getRatesState } from "features/Settings";
import {
  EditableLiquidityChargesTable,
  getLiquidityChargesUsingRateGroupId,
  setLiquidityChargesForm,
  updateLiquidityCharges,
  getRates,
} from "features/../../../shared/features/Settings";

class AllEditableLiquidityChargeTables extends React.Component {
  componentDidMount() {
    const { getLiquidityChargesUsingRateGroupId, rateGroupId } = this.props;
    if (getLiquidityChargesUsingRateGroupId) {
      getLiquidityChargesUsingRateGroupId(rateGroupId);
    }
  }

  render() {
    const {
      rateGroupId,
      liquidityRecords,
      liquidityLoading,
      includeIpSyndicateCharge,
      getLiquidityChargesUsingRateGroupId,
      updateLiquidityCharges,
      getRates,
    } = this.props;

    return (
      <div className="dealer-liquidity-charges-container">
        {liquidityRecords &&
          liquidityRecords.map((funder, funderIndex) =>
            funder.liquidityRecords.map((financeType, financeTypeIndex) => (
              <div className="liquidity-table-group" key={financeTypeIndex}>
                {financeType.liquidityRecords.map(
                  (paymentMethod, paymentMethodIndex) => (
                    <EditableLiquidityChargesTable
                      key={`${funderIndex}_${financeTypeIndex}_${paymentMethodIndex}`}
                      liquidityRecords={liquidityRecords}
                      rateGroupId={rateGroupId}
                      title={paymentMethod.title}
                      fullTitle={`${funder.title} ${financeType.title} ${paymentMethod.title}`}
                      reloadRatesOnFormChange={false}
                      funderIndex={funderIndex}
                      financeTypeIndex={financeTypeIndex}
                      paymentMethodIndex={paymentMethodIndex}
                      includeIpSyndicateCharge={includeIpSyndicateCharge}
                      onCancel={() =>
                        getLiquidityChargesUsingRateGroupId(rateGroupId)
                      }
                      loading={liquidityLoading}
                      updateLiquidityCharges={updateLiquidityCharges}
                      setLiquidityChargesForm={setLiquidityChargesForm}
                      getRatesState={getRatesState}
                      getRates={getRates}
                    />
                  )
                )}
              </div>
            ))
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { liquidityCharges, liquidityLoading } = getRatesState(state);

  const { includeIpSyndicateCharge, liquidityRecords } = liquidityCharges;

  return {
    liquidityLoading,
    includeIpSyndicateCharge,
    liquidityRecords,
    getRates,
  };
};

const actions = {
  getLiquidityChargesUsingRateGroupId,
  setLiquidityChargesForm,
  updateLiquidityCharges,
};

export default connect(
  mapStateToProps,
  actions
)(AllEditableLiquidityChargeTables);
