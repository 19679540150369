import React from "react";
import { ModalBuilder, Form } from "@redriver/cinnamon";
import Remove from "features/../../../shared/assets/red-delete.svg";
import { IconButton } from "features/../../../shared/components/buttons";
import RedAdd from "features/../../../shared/assets/red-add.svg";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";

const ProductScheduleRemoveModal = ({
  nameOfRemoval,
  reinstate = false,
  isLocked,
}) => {
  const { t } = useTranslation("productSchedule");

  return (
    <ModalBuilder
      renderTrigger={(showModal) =>
        !isLocked && (
          <IconButton
            onClick={showModal}
            icon={reinstate ? RedAdd : Remove}
            className="icon-only-button ps-remove-icon"
          />
        )
      }
      renderModal={(modalProps) => (
        <TranslatedModal.Confirmation
          {...modalProps}
          cancelLabel={t("No_Cancel")}
          submitLabel={
            <Form.ArrayRemover
              className="form-array sheet-array-remove-button"
              /* onRemoved logic will need to be added for rewrite sheets, when this is added

            onRemoved={() => {
              if(!isRewrite) return;

              let candidate = {
                ...formData,
                isRemoved: toRemove
              };
              const order = arrayToAddTo.length + 1;
              arrayToRemoveFrom.forEach(x => {
                x.order > candidate.order ? (x.order = x.order - 1) : null;
              });
              candidate.order = order;
              arrayToAddTo.push(candidate);
            }}
            */
            >
              {t("Yes_action", {
                action: reinstate ? t("Reinstate") : t("Remove"),
              })}
            </Form.ArrayRemover>
          }
          submitIcon={null}
        >
          {t("Do_you_want_to_action", {
            action: reinstate ? t("reinstate") : t("remove"),
            name: t(nameOfRemoval),
          })}
          <br />
        </TranslatedModal.Confirmation>
      )}
    />
  );
};

export default ProductScheduleRemoveModal;
