import React from "react";
import { Table, ActionMenu } from "@redriver/cinnamon";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAgreementsState } from "../../selectors";
import {
  PermissionCheck,
  withCurrentUser,
  withPermissions,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import {
  SheetActionIps,
  SheetActionPrincipal,
  SheetStateIps,
  SheetStatePrincipal,
  SheetStatus,
} from "features/../../../shared/constants/enums";

import {
  getMasterAgreement,
  getMasterAgreements,
  addExpandedAgreementOrSchedule,
  getAgreementScheduleSheets,
} from "../actions";
import {
  SubmitSheet,
  SetPhysicalDocumentSheetState,
  RenameSheet,
  SheetStateProgressView,
  SendForApproval,
  PreApproveSheetPrincipal,
  PreApproveSheetIps,
  IpsReview,
  MakeSheetLive,
  ResetSheetState,
  AddAdminComment,
  AmendExpiryDate,
  DeclinePreApprovalPrincipal,
  DeclinePreApprovalIps,
} from "../SheetActions";
import {
  SetSheetOwner,
  CloneSheet,
  DeleteSheet,
  CrystalliseSheet,
  UncrystalliseSheet,
  ArchiveSheet,
  UnarchiveSheet,
  ToggleSheetReadOnly,
  DuplicateToNewMaster,
} from "features/Sheets/ManageSheet/SheetActions";
import { SetCurrentSchedule } from "../SetCurrentSchedule";
import { SetReplacingSchedule } from "../SetReplacingSchedule";
import { VoidEnvelope } from "../SheetActions/VoidEnvelope";
import {
  SheetStateIps as NumberedSheetStateIps,
  SheetStatePrincipal as NumberedSheetStatePrincipal,
} from "features/../constants/sheetState";

const SheetActions = ({
  agreement,
  schedule,
  sheet,
  currentUser,
  hasPermission,
}) => {
  const dispatch = useDispatch();
  const { filters, companyFilters, pagination } =
    useSelector(getAgreementsState);

  const dealerId = companyFilters?.dealer?.id;
  let { sheetId, sheetName, ownedBy, isReadOnly } = sheet,
    { agreementReference, boilerPlateTandCsId } = agreement,
    { scheduleReference } = schedule;
  const { isSupplierAdminUser, isInternalUser } = currentUser;
  const isAdminUser = isInternalUser || isSupplierAdminUser;

  const canPreApproveAsSupplier =
    hasPermission(Targets.Sheet, Actions.PreApproveSupplier) &&
    (process.env.TRADE_AS_IPS
      ? sheet.stateIps == SheetStateIps.PreLive_AwaitingSupplierPreApproval
      : sheet.statePrincipal ==
          SheetStatePrincipal.PreLive_AwaitingPrincipalPreApproval &&
        !sheet.submittedForPhysicalDocumentFlow);

  const canPreApproveAsIPS =
    process.env.TRADE_AS_IPS &&
    isInternalUser &&
    hasPermission(Targets.Sheet, Actions.PreApproveIPS) &&
    sheet.stateIps == SheetStateIps.PreLive_AwaitingIpsPreApproval;
  const sheetInPreApprovalState = process.env.TRADE_AS_IPS
    ? sheet.stateIps == SheetStateIps.PreLive_AwaitingIpsPreApproval ||
      sheet.stateIps == SheetStateIps.PreLive_AwaitingSupplierPreApproval
    : sheet.statePrincipal ==
      SheetStatePrincipal.PreLive_AwaitingPrincipalPreApproval;

  const reloadAgreement = () =>
    dispatch(getMasterAgreement(agreement.agreementId, filters));

  const reloadAgreements = () =>
    dispatch(getMasterAgreements({ filters, pagination }, companyFilters));

  const onDuplicateToNewMasterSubmitted = (response) => {
    reloadAgreements();
    dispatch(getAgreementScheduleSheets(response.scheduleId, filters));

    // Expand newly created agreement and schedule, so using the response IDs.
    dispatch(addExpandedAgreementOrSchedule(response.masterAgreementId));
    dispatch(addExpandedAgreementOrSchedule(response.scheduleId));
  };

  const reloadSheetsForSchedule = () =>
    dispatch(getAgreementScheduleSheets(schedule.scheduleId, filters));

  return (
    <Table.Menu upward>
      <CloneSheet sheetId={sheetId} name={sheetName} isLive={sheet.isLive} />
      {(!isReadOnly || isAdminUser) && (
        <RenameSheet sheet={sheet} onSubmitted={reloadSheetsForSchedule} />
      )}
      {(!isReadOnly || isAdminUser) && (
        <PermissionCheck action={Actions.Resurrect} target={Targets.Sheet}>
          <DeleteSheet
            sheetId={sheetId}
            sheetName={sheetName}
            onSubmitted={() => {
              reloadSheetsForSchedule();
              reloadAgreement();
            }}
            submitParams={{ sheetId }}
          />
        </PermissionCheck>
      )}
      <PermissionCheck
        action={Actions.DuplicateToNewMaster}
        target={Targets.Sheet}
      >
        <DuplicateToNewMaster
          sheetId={sheetId}
          name={sheetName}
          onSubmitted={onDuplicateToNewMasterSubmitted}
        />
      </PermissionCheck>
      {(!isReadOnly || isAdminUser) && <ActionMenu.Divider />}
      {(!isReadOnly || isAdminUser) && (
        <PermissionCheck target={Targets.Sheet} action={Actions.SetOwner}>
          <SetSheetOwner
            sheetId={sheetId}
            dealerId={dealerId}
            ownedBy={ownedBy}
            onSubmitted={reloadSheetsForSchedule}
          />
        </PermissionCheck>
      )}
      {isAdminUser && (
        <AddAdminComment sheet={sheet} onSubmitted={reloadSheetsForSchedule} />
      )}
      {isInternalUser && (
        <SetReplacingSchedule
          sheet={sheet}
          onSubmitted={reloadSheetsForSchedule}
        />
      )}
      {isAdminUser && (
        <AmendExpiryDate sheet={sheet} onSubmitted={reloadSheetsForSchedule} />
      )}
      {isAdminUser && <ActionMenu.Divider />}
      {!sheet.submittedForPhysicalDocumentFlow && (
        <SheetStateProgressView sheet={sheet} />
      )}
      {isAdminUser && (
        <PermissionCheck action={Actions.Reset} target={Targets.Sheet}>
          <ResetSheetState
            sheet={sheet}
            onSubmitted={reloadSheetsForSchedule}
            sheetActionRequired={
              process.env.TRADE_AS_IPS
                ? SheetActionIps.CanResetSheetState
                : SheetActionPrincipal.CanResetSheetState
            }
          />
        </PermissionCheck>
      )}
      {sheet.isInDocuSign && (
        <PermissionCheck action={Actions.Void} target={Targets.Sheet}>
          <VoidEnvelope
            sheet={sheet}
            onSubmitted={() => {
              reloadSheetsForSchedule();
              reloadAgreement();
            }}
          />
        </PermissionCheck>
      )}
      {process.env.TRADE_AS_IPS &&
        (canPreApproveAsSupplier || canPreApproveAsIPS) && (
          <PreApproveSheetIps
            sheet={sheet}
            onSubmitted={reloadSheetsForSchedule}
          />
        )}
      {!process.env.TRADE_AS_IPS && canPreApproveAsSupplier && (
        <PreApproveSheetPrincipal
          sheet={sheet}
          onSubmitted={() => {
            reloadSheetsForSchedule();
            reloadAgreement();
          }}
          scheduleReference={scheduleReference}
          agreementReference={agreementReference}
          boilerPlateTandCsId={boilerPlateTandCsId}
          sheetActionRequired={SheetActionPrincipal.CanBePreApprovedByPrincipal}
        />
      )}
      {process.env.TRADE_AS_IPS &&
        (canPreApproveAsSupplier || canPreApproveAsIPS) && (
          <DeclinePreApprovalIps
            sheet={sheet}
            onSubmitted={reloadSheetsForSchedule}
          />
        )}

      {!process.env.TRADE_AS_IPS && canPreApproveAsSupplier && (
        <DeclinePreApprovalPrincipal
          sheet={sheet}
          onSubmitted={reloadSheetsForSchedule}
        />
      )}
      {!sheetInPreApprovalState && !sheet.submittedForPhysicalDocumentFlow && (
        <PermissionCheck
          target={Targets.Sheet}
          action={Actions.SubmitForCustomerApproval}
        >
          <SendForApproval
            sheet={sheet}
            onSubmitted={() => {
              reloadSheetsForSchedule();
              reloadAgreement();
            }}
            scheduleReference={scheduleReference}
            agreementReference={agreementReference}
            boilerPlateTandCsId={boilerPlateTandCsId}
            sheetActionRequired={
              process.env.TRADE_AS_IPS
                ? SheetActionIps.CanSendForApproval
                : SheetActionPrincipal.CanSendForApproval
            }
          />
        </PermissionCheck>
      )}
      <MakeSheetLive
        sheet={sheet}
        sheetActionRequired={
          process.env.TRADE_AS_IPS
            ? SheetActionIps.CanMakeLive
            : SheetActionPrincipal.CanMakeLive
        }
        onSubmitted={() => {
          reloadSheetsForSchedule();
          reloadAgreement();
        }}
        scheduleReference={scheduleReference}
        agreementReference={agreementReference}
      />
      {!process.env.TRADE_AS_IPS && (
        <React.Fragment>
          {!sheet.submittedForPhysicalDocumentFlow &&
            !sheet.submittedForDocusignFlow &&
            (!isReadOnly || isAdminUser) && (
              <PermissionCheck target={Targets.Sheet} action={Actions.Submit}>
                <SubmitSheet
                  sheet={sheet}
                  onSubmitted={reloadSheetsForSchedule}
                />
              </PermissionCheck>
            )}
          {!sheet.submittedForDocusignFlow && (!isReadOnly || isAdminUser) && (
            <PermissionCheck
              target={Targets.Sheet}
              action={Actions.SetPhysicalDocumentSheetState}
            >
              <SetPhysicalDocumentSheetState
                sheet={sheet}
                onSubmitted={reloadSheetsForSchedule}
              />
            </PermissionCheck>
          )}
          {isAdminUser && (
            <PermissionCheck
              target={Targets.Sheet}
              action={Actions.UpdateSheetScheduleReference}
            >
              <SetCurrentSchedule
                sheet={sheet}
                onSubmitted={reloadSheetsForSchedule}
              />
            </PermissionCheck>
          )}
        </React.Fragment>
      )}
      {(!isReadOnly || isAdminUser) && (
        <PermissionCheck
          target={Targets.Sheet}
          action={Actions.CrystalliseAndUncrystalliseSheets}
        >
          <React.Fragment>
            <UncrystalliseSheet
              sheet={sheet}
              onSubmitted={reloadSheetsForSchedule}
              sheetActionRequired={
                process.env.TRADE_AS_IPS
                  ? SheetActionIps.CanUncrystallise
                  : SheetActionPrincipal.CanUncrystallise
              }
            />
            <CrystalliseSheet
              sheet={sheet}
              onSubmitted={reloadSheetsForSchedule}
              sheetActionRequired={
                process.env.TRADE_AS_IPS
                  ? SheetActionIps.CanCrystallise
                  : SheetActionPrincipal.CanCrystallise
              }
            />
          </React.Fragment>
        </PermissionCheck>
      )}
      {(!isReadOnly || isAdminUser) && sheet.isArchived && (
        <UnarchiveSheet sheet={sheet} onSubmitted={reloadSheetsForSchedule} />
      )}
      {(!isReadOnly || isAdminUser) && !sheet.isArchived && (
        <ArchiveSheet sheet={sheet} onSubmitted={reloadSheetsForSchedule} />
      )}
      {(!isReadOnly || isAdminUser) && (
        <PermissionCheck target={Targets.Sheet} action={Actions.SetReadOnly}>
          <React.Fragment>
            <ActionMenu.Divider />
            <ToggleSheetReadOnly
              sheet={sheet}
              onSubmitted={reloadSheetsForSchedule}
            />
          </React.Fragment>
        </PermissionCheck>
      )}
    </Table.Menu>
  );
};

export default withPermissions(withCurrentUser(withRouter(SheetActions)));
