import React from "react";
import { Form, useFormField } from "@redriver/cinnamon";
import { DealerOfficesTypeAheadLookup } from "features/../../../shared/features/Settings";
import classNames from "classnames";
import GreenAddSvg from "features/../../../shared/assets/green-add.svg";
import FormArrayDeleteModal from "features/../../../shared/components/forms/FormArrayDeleteModal";
import { IconButton } from "features/../../../shared/components/buttons";
import { useTranslation } from "react-i18next";

const InvoiceEmailsList = ({ field = "invoiceEmails", readOnly = false }) => {
  let [invoiceEmails] = useFormField(field),
    renderEmpty = readOnly && (!invoiceEmails || invoiceEmails.length < 1);
  const { t } = useTranslation();
  return (
    <div className={`invoice-emails-list ${readOnly ? "disabled" : ""}`}>
      <Form.Array
        fluid
        field={field}
        label={t("clients:headings:invoiceEmails")}
      >
        {({ fields }) => (
          <div className="list-item">
            {!readOnly && (
              <FormArrayDeleteModal
                nameOfRemoval={fields.value || t("clients:labels:emptyItem")}
              />
            )}
            <Form.Email
              required
              field="value"
              placeholder={t("clients:fields:invoiceEmail:placeholder")}
              emailError={t("clients:fields:invoiceEmail:emailError")}
              renderReadOnly={(field) => <p>{field.value}</p>}
            />
          </div>
        )}
      </Form.Array>
      {renderEmpty && "-"}
      {!readOnly && (
        <div className="list-item">
          <Form.ArrayAdder
            field={field}
            arrayKey="key"
            as={IconButton}
            icon={GreenAddSvg}
            content={t("clients:buttons:addInvoiceEmail")}
          />
        </div>
      )}
    </div>
  );
};

const ClientForm = ({ notCreate, isEdit, className, ...formProps }) => {
  const { t } = useTranslation();
  return (
    <div className={classNames("client-form", className)}>
      <Form {...formProps}>
        <Form.Group widths="equal">
          <Form.Input
            field="name"
            label={t("clients:fields:name:label")}
            fluid
            required
            placeholder={t("clients:fields:name:placeholder")}
          />
          <DealerOfficesTypeAheadLookup
            field="dealerOfficeId"
            label={t("clients:fields:dealerOfficeId:label")}
            fluid
            required
            placeholder={t("clients:fields:dealerOfficeId:placeholder")}
            clearable
            textField="dealerOffice"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            field="phone"
            label={t("clients:fields:phone:label")}
            fluid
            placeholder={t("clients:fields:phone:placeholder")}
            renderReadOnly={(props) => `${props.value || "-"}`}
          />
          <Form.Input
            field="businessType"
            label={t("clients:fields:businessType:label")}
            fluid
            required
            placeholder={t("clients:fields:businessType:placeholder")}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            field="code"
            label={t("clients:fields:code:label")}
            maxLength={20}
            fluid
            required
            placeholder={t("clients:fields:code:placeholder")}
            renderReadOnly={(props) => `${props.value || "-"}`}
          />
          <Form.Input
            fluid
            field="accountManager"
            label={t("clients:fields:accountManager:label")}
            placeholder={t("clients:fields:accountManager:placeholder")}
            renderReadOnly={(props) => props.value || "-"}
          />
        </Form.Group>

        {notCreate && (
          <React.Fragment>
            <Form.Group widths="equal">
              <Form.Input
                field="companyRegNo"
                label={t("clients:fields:companyRegNo:label")}
                fluid
                placeholder={t("clients:fields:companyRegNo:placeholder")}
                renderReadOnly={(props) => `${props.value || "-"}`}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                field="yearsInBusiness"
                label={t("clients:fields:yearsInBusiness:label")}
                fluid
                disabled
                placeholder={t("clients:fields:yearsInBusiness:placeholder")}
                renderReadOnly={(props) => `${props.value || "-"}`}
              />
              <Form.Input
                field="contactName"
                label={t("clients:fields:contactName:label")}
                fluid
                placeholder={t("clients:fields:contactName:placeholder")}
                renderReadOnly={(props) => `${props.value || "-"}`}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                field="invoiceContactName"
                label={t("clients:fields:invoiceContactName:label")}
                fluid
                placeholder={t("clients:fields:invoiceContactName:placeholder")}
                renderReadOnly={(props) => `${props.value || "-"}`}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                field="address"
                label={t("clients:fields:address:label")}
                fluid
                disabled
                placeholder={t("clients:fields:address:placeholder")}
                renderReadOnly={(props) => `${props.value || "-"}`}
              />
            </Form.Group>
            {isEdit && (
              <React.Fragment>
                <Form.Group widths="equal">
                  <Form.Input
                    field="addressLine1"
                    label={t("clients:fields:addressLine1:label")}
                    fluid
                    placeholder={t("clients:fields:addressLine1:placeholder")}
                    renderReadOnly={(props) => `${props.value || "-"}`}
                  />
                  <Form.Input
                    field="addressLine2"
                    label={t("clients:fields:addressLine2:label")}
                    fluid
                    placeholder={t("clients:fields:addressLine2:placeholder")}
                    renderReadOnly={(props) => `${props.value || "-"}`}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    field="city"
                    label={t("clients:fields:city:label")}
                    fluid
                    placeholder={t("clients:fields:city:placeholder")}
                    renderReadOnly={(props) => `${props.value || "-"}`}
                  />
                </Form.Group>
              </React.Fragment>
            )}
            <Form.Group widths="equal">
              <Form.Input
                field="county"
                label={t("clients:fields:county:label")}
                fluid
                placeholder={t("clients:fields:county:placeholder")}
                renderReadOnly={(props) => `${props.value || "-"}`}
              />
              <Form.Input
                field="postcode"
                label={t("clients:fields:postcode:label")}
                fluid
                placeholder="Post Code..."
                renderReadOnly={(props) => `${props.value || "-"}`}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Email
                field="email"
                label={t("clients:fields:email:label")}
                fluid
                placeholder={t("clients:fields:email:placeholder")}
                renderReadOnly={(props) => `${props.value || "-"}`}
              />
            </Form.Group>
            <InvoiceEmailsList readOnly={!isEdit} />
          </React.Fragment>
        )}
      </Form>
    </div>
  );
};

export default ClientForm;
