import React from "react";
import { ModalBuilder, Form, Table, ErrorMessage } from "@redriver/cinnamon";
import { withRouter } from "react-router-dom";
import { SheetRoutes } from "constants/routes";
import {
  PermissionCheck,
  usePermission,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { cloneSheet, getSheetDuplicateName } from "../actions";

const CloneSheet = ({
  as: As = Table.MenuItem,
  sheetId,
  name,
  onOpen,
  onClose,
  history,
  redirectToClonedSheet = true,
  isLive,
}) => {
  const { t } = useTranslation("sheetHeader");

  const authAction = isLive ? Actions.DuplicateLive : Actions.Create;
  const hasPermission = usePermission(Targets.Sheet, authAction);

  if (!hasPermission) return null;

  return (
    <ModalBuilder
      withForm
      loadAction={getSheetDuplicateName}
      loadParams={{
        sheetId: sheetId,
      }}
      submitAction={cloneSheet}
      submitParams={sheetId}
      initialData={{ name }}
      onSubmitted={(clonedId) => {
        if (onClose) onClose();
        if (redirectToClonedSheet) history.push(SheetRoutes.Sheet(clonedId));
      }}
      onCancelled={onClose}
      renderTrigger={(trigger) => (
        <As
          onClick={() => {
            if (onOpen) onOpen();
            trigger();
          }}
        >
          {isLive ? t("Create_Rewrite") : t("Duplicate")}
        </As>
      )}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal
          {...modalProps}
          header={isLive ? t("Create_Rewrite") : t("Duplicate")}
          size="small"
        >
          <Form {...formProps}>
            <Form.Input
              field="name"
              label={t("Name")}
              required
              requiredError={t("Name") + " " + t("is_required")}
            />
            <ErrorMessage
              header={t("Something_went_wrong")}
              error={modalProps.error}
              omitCodes
              overrideMessages={{
                4038: t("This_sheet_cannot_be_duplicated_because_it_is_seeded"),
                4039: t("A_sheet_with_this_name_already_exists"),
              }}
            />
          </Form>
        </TranslatedModal>
      )}
    />
  );
};

export default withRouter(CloneSheet);
