import React from "react";
import { useTranslation } from "react-i18next";
import { principalPreApprovalDeclined } from "./actions";
import { ModalBuilder, Table, notifySuccess } from "@redriver/cinnamon";
import { SheetActionPrincipal } from "features/../../../shared/constants/enums";
import { TranslatedModal } from "features/../../../shared/components/modals";
import DeclinePreApprovalForm from "../DeclinePreApprovalForm";
import { useDispatch } from "react-redux";

const DeclinePreApprovalPrincipal = ({ sheet, sheetData, onSubmitted }) => {
  const { t } = useTranslation("agreements");
  const dispatch = useDispatch();

  const { sheetId } = sheet;
  const availableActions = sheet
    ? sheet.availableActionsPrincipal
    : sheetData.availableActionsPrincipal;

  const canBePreApprovedByPrincipal =
    availableActions[SheetActionPrincipal.CanBePreApprovedByPrincipal];

  const approver = t("Principal");

  if (!canBePreApprovedByPrincipal) return null;

  return (
    <ModalBuilder
      submitAction={principalPreApprovalDeclined}
      submitParams={{ sheetId }}
      onSubmitted={() => {
        dispatch(
          notifySuccess(
            t("SheetActions.DeclinePreApproval.NotificationText", { approver }),
          ),
        );
        onSubmitted();
      }}
      withForm={true}
      renderTrigger={(onTrigger) => (
        <Table.MenuItem onClick={onTrigger}>
          {t("SheetActions.DeclinePreApproval.TriggerText", { approver })}
        </Table.MenuItem>
      )}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal
          {...modalProps}
          header={t("SheetActions.DeclinePreApproval.ModalHeader")}
          className="add-agreement-modal"
        >
          <DeclinePreApprovalForm formProps={formProps} />
        </TranslatedModal>
      )}
    />
  );
};

export default DeclinePreApprovalPrincipal;
