import React from "react";
import { ListBuilder, Table, ActionBar } from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import { useTranslation } from "react-i18next";
import { getClients } from "./actions";
import { ListSearch } from "components/lists";

const ClientsList = ({ dealerOfficeId, onSelect }) => {
  const { t } = useTranslation("agreements");
  return (
    <React.Fragment>
      <h4 className="title">{t("Clients.Title")}</h4>
      <ListBuilder
        withTable
        initialPagination={{ pageSize: 25, pageNumber: 1 }}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        loadAction={getClients}
        loadParams={{ dealerOfficeId }}
        renderList={(tableProps, state, events) => (
          <div className="content-container principal-list">
            <ActionBar>
              <ActionBar.Item fluid className="flex">
                <ListSearch
                  value={state.filters}
                  onChange={events.onChangeFilters}
                  placeholder={t("Clients.Search_Placeholder")}
                />
                <div className="search-filter-display"></div>
              </ActionBar.Item>
            </ActionBar>
            <Table
              {...tableProps}
              dataKey="id"
              emptyMessage={t("NoData")}
              selectable
              className="center-align"
            >
              <Table.Column
                field="name"
                title={t("Clients.Headings.Name")}
                render={(item) => (
                  <a onClick={() => onSelect(item)}>{item.name}</a>
                )}
              />
              <Table.Column
                field="code"
                title={t("Clients.Headings.CRM_ID")}
                width="15%"
              />
              <Table.Column
                title={t("Clients.Headings.Address")}
                render={(row) => {
                  if (!row.address) return;
                  const address = [
                    row.address.line1,
                    row.address.line2,
                    row.address.town,
                    row.address.postcode,
                  ];
                  return address.filter((x) => !!x).join(", ");
                }}
              />
              <Table.Column
                field="telephone"
                title={t("Clients.Headings.Telephone")}
                width="15%"
              />
              <Table.Column
                field="opalId"
                title={t("Clients.Headings.Opal_ID")}
              />
              <Table.Column
                field="totalLiveSchedules"
                title={t("Clients.Headings.Total_Live_Schedules")}
              />
              <Table.Column
                field="status"
                title={t("Clients.Headings.Status")}
              />
              <Table.Column
                field="accountManager"
                title={t("Clients.Headings.Account_Manager")}
              />
            </Table>
            <TablePagination
              pageNumber={state.pagination.pageNumber}
              onChange={events.onChangePagination}
              pageSize={state.pagination.pageSize}
              totalItems={state.total}
            />
          </div>
        )}
      />
    </React.Fragment>
  );
};

export default ClientsList;
