import React from "react";
import { Loader } from "semantic-ui-react";
import classNames from "classnames";

/**
 * A spinner conveniently packaged with default styles making
 * it especially suited to displaying within cells, fields etc.
 * @param {Boolean} isActive Toggle display of the spinner
 * @param {String} className Optional classes to apply to the element
 * @param {String} size Choose from `mini`, `tiny`, `small`, `medium`, `large`, `big`, `huge`, `massive` - defaults to `mini`
 */
const InlineLoader = ({ size, isActive, className }) => {
  return isActive ? (
    <Loader
      size={size || "mini"}
      active={isActive}
      className={classNames("inline-loader", className)}
    ></Loader>
  ) : null;
};

export default InlineLoader;
