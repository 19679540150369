import {
  apiDelete,
  deauthenticate,
  getAuthRefreshToken,
  removeAllNotifications,
  lookupReset,
} from "@redriver/cinnamon";
import { clearAgreementState } from "features/Agreements";
import { clearSheetDetails } from "features/Sheets/ManageSheet";
import { clearRates } from "features/Settings/ListRates";

const NAMESPACE = "SYSTEM/LOGOUT";
export const REQUEST_LOGOUT = `${NAMESPACE}/REQUEST`;
// export const DELETE_USER_PIN = `${NAMESPACE}/DELETE_USER_PIN`;

export const requestLogout = () => async (dispatch, getState) => {
  // make the logout api call
  const token = getAuthRefreshToken(getState());

  const logoutAction = apiDelete(
    REQUEST_LOGOUT,
    "auth/token/refresh",
    { token },
    { auth: false, preventErrorNotification: true },
  );

  /*
  const removeUserPin = apiDelete(
    DELETE_USER_PIN,
    "system/userpin"
  );
  */

  const { success } = await dispatch(logoutAction);

  if (success) {
    // await dispatch(removeUserPin);
    // deauthenticate the user session
    dispatch(deauthenticate());
  }

  return success;
};

export const onDeauthenticated = () => (dispatch) => {
  // clear toast notifications
  dispatch(removeAllNotifications());

  // reset all cached lookup data
  dispatch(lookupReset());

  // clear agreement data
  dispatch(clearAgreementState());

  // clear sheet data
  dispatch(clearSheetDetails());

  // clear yields, rates & liquidity charges
  dispatch(clearRates());
};
