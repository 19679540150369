import React from "react";
import { FormBuilder, Form } from "@redriver/cinnamon";
import {
  paymentMethods,
  funderTypes,
  financeTypes
} from "features/../../../shared/constants/enums";
import { useTranslation } from "react-i18next";

const RatesFilters = ({ filters, onChange }) => {
  const  { t }  = useTranslation('supplierOffices', { keyPrefix:'rates.filters'});
  return(
  <FormBuilder
    value={filters}
    onChange={onChange}
    renderForm={formProps => (
      <Form {...formProps}>
        <h4>{t("title")}</h4>
        <Form.Group>
          <Form.RadioGroup
            field="funderType"
            options={funderTypes}
            label={t("funderType")}
            width={5}
          />
          <Form.RadioGroup
            field="financeType"
            options={financeTypes}
            label={t("ratesType")}
            width={5}
          />

          <Form.RadioGroup
            field="paymentMethod"
            options={paymentMethods}
            label={t("paymentMethod")}
            width={6}
          />
        </Form.Group>
      </Form>
    )}
  />
)};

export default RatesFilters;
