import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "@redriver/cinnamon";
import ProductTable from "./ProductTable";

const RetainProductsAndTable = ({ formProps }) => {
  const { t } = useTranslation("agreements", {
    keyPrefix: "ScheduleRow.ProductTable",
  });
  return (
    <React.Fragment>
      <div className="retain-container">
        <Form.RadioGroup
          className="retain"
          field="retain"
          label={t("Retain_Products")}
          options={[
            { value: 1, text: t("Yes") },
            { value: 0, text: t("No") },
          ]}
        />
      </div>

      <Form.If condition={({ fields }) => !!fields.retain}>
        <ProductTable formProps={formProps} />
      </Form.If>
    </React.Fragment>
  );
};

export default RetainProductsAndTable;
