import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormBuilder, Form, Content } from "@redriver/cinnamon";
import { Button, Message, Grid } from "semantic-ui-react";
import { getPin, savePin } from "./actions";
import { connect } from "react-redux";

const PinChange = ({ userId }) => {
  const { t } = useTranslation("system");
  const [pinUpdated, setPinUpdated] = useState(false);
  const [customErrors, setCustomErrors] = useState([]);
  const [pinIsEditable, setPinIsEditable] = useState(false);
  return (
    <FormBuilder
      loadAction={getPin}
      loadParams={userId}
      submitAction={savePin}
      onSubmit={() => setPinUpdated(false)}
      onSubmitted={() => setPinUpdated(true)}
      renderSubmitting={false}
      renderForm={(formProps, { submitting, slowSubmitting }, events) => (
        <Content>
          <Form {...formProps}>
            <Grid>
              <Grid.Column width={5}>
                <Form.Input
                  field="pin"
                  label={t("pin")}
                  placeholder={t("pin")}
                  required
                  fluid
                  readOnly={!pinIsEditable}
                  showErrors={customErrors && customErrors.length}
                  customErrors={customErrors}
                  customValidator={(_, __, ___, { value }) => {
                    setCustomErrors([]);
                    if (!(value.pin || "").length) return;
                    //check pin is the correct length and a number
                    if (value.pin.length !== 4) {
                      setCustomErrors([t("Pin_Correct_Length")]);
                    } else {
                      if (isNaN(value.pin)) {
                        setCustomErrors([t("Pin_Must_Be_Numerical")]);
                      }
                    }
                    return;
                  }}
                />
              </Grid.Column>
            </Grid>
          </Form>
          <div>
            <br />

            {pinUpdated && (
              <React.Fragment>
                <br />
                <Message
                  success
                  header={t("Success")}
                  content={t("Your_pin_has_been_updated")}
                />
              </React.Fragment>
            )}
            {pinIsEditable ? (
              <div className="update-pin">
                <Button
                  primary
                  icon="check"
                  content={t("Update_Pin")}
                  onClick={() => {
                    events.onSubmit({ reset: ["data"] });
                    setPinIsEditable(false);
                    events.onRefresh();
                  }}
                  disabled={submitting || (customErrors && customErrors.length)}
                  loading={submitting && slowSubmitting}
                />
              </div>
            ) : (
              <div>
                <Button
                  primary
                  icon="edit"
                  content={t("Edit_Pin")}
                  onClick={() => setPinIsEditable(true)}
                />
              </div>
            )}
          </div>
        </Content>
      )}
    />
  );
};

export default connect()(PinChange);
