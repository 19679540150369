import React from "react";
import { Form, DataList, Format } from "@redriver/cinnamon";
import { connect } from "react-redux";
import { getSheetDataState } from "features/Sheets/selectors";
import { useTranslation } from "react-i18next";
import { LfpMeterType } from "features/../../../shared/constants/enums";

const InkUsageDataDisplay = ({
  inkUsedML,
  lfPrinterConfig,
  costPerUnit,
  costPerClick,
  editMode,
  printerChanged,
  priceType,
}) => {
  const selectedInkMeter = lfPrinterConfig.meteredServices?.find(
    (x) => x.meterType == LfpMeterType.Ink
  );
  const selectedInkMeterPrices =
    selectedInkMeter?.prices?.find((x) => x.priceType == priceType) ?? {};
  const costPerMl =
    selectedInkMeterPrices?.value != null ? selectedInkMeterPrices.value : null;
  const costPerMlToDisplay =
    printerChanged || !editMode ? costPerMl : costPerClick;

  const { t } = useTranslation();
  return (
    <div className="ink-inclusive-values">
      <DataList>
        <DataList.Item
          className="per-ml-ink"
          title={t(
            "systemSchedule:largeFormatModal:inkInclusive:fields:pencePerMlOfInk:label"
          )}
        >
          {selectedInkMeterPrices?.value != null ? (
            <React.Fragment>
              {!process.env.USE_MINOR_CURRENCY && process.env.MAJOR_CURRENCY}
              <Format.Number
                decimalPlaces={process.env.USE_MINOR_CURRENCY ? 2 : 4}
                value={costPerMlToDisplay}
              />
              {process.env.USE_MINOR_CURRENCY && process.env.MINOR_CURRENCY}
            </React.Fragment>
          ) : (
            "-"
          )}
        </DataList.Item>
        <Form.Object field="lfInkInclusive" />
        <DataList.Item
          className="ml-ink-used"
          title={t(
            "systemSchedule:largeFormatModal:inkInclusive:fields:a1MlOfInkUsed:label"
          )}
        >
          {inkUsedML != null ? (
            <React.Fragment>
              <Format.Number
                decimalPlaces={{ min: 0, max: 3 }}
                value={inkUsedML}
              />
            </React.Fragment>
          ) : (
            "-"
          )}
        </DataList.Item>
        <DataList.Item
          className="cost-per-unit"
          title={t(
            "systemSchedule:largeFormatModal:inkInclusive:fields:mlOfInkCpu:label"
          )}
        >
          {costPerUnit != null ? (
            <React.Fragment>
              {!process.env.USE_MINOR_CURRENCY && process.env.MAJOR_CURRENCY}
              <Format.Number
                decimalPlaces={{
                  min: 0,
                  max: process.env.USE_MINOR_CURRENCY ? 3 : 5,
                }}
                value={costPerUnit}
              />
              {process.env.USE_MINOR_CURRENCY && process.env.MINOR_CURRENCY}
            </React.Fragment>
          ) : (
            "-"
          )}
        </DataList.Item>
      </DataList>
    </div>
  );
};

const mapStateToProps = (state) => {
  let { largeFormatCreateModal } = getSheetDataState(state);

  return {
    lfPrinterConfig: largeFormatCreateModal.lfPrinterConfig,
  };
};

export default connect(mapStateToProps)(InkUsageDataDisplay);
