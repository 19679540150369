import { isNil } from "lodash";

export const getFixedNumericalValue = value => {
    return typeof value === "undefined" ||
      isNaN(value) ||
      isNil(value) ||
      value == null ||
      value == ""
      ? 0
      : typeof value === "string"
      ? parseFloat(value.replace(",", ""))
      : value;
  };