import React from "react";
import classNames from "classnames";

const DataGrid = ({
  headerText,
  dataRows,
  lastRow,
  lastRowColumnCount,
  className,
  minimumRows,
  columnCount = 2,
  isFirstRowHeader = false,
}) => {
  const rowClassNames = dataRows && dataRows[0]?.rowClassNames;
  const renderEmptyRows = () => {
    const emptyRowsRequired = minimumRows
      ? minimumRows - (dataRows.length + (lastRow ? 1 : 0))
      : 0;
    if (emptyRowsRequired <= 0) return null;

    const rows = Array.from(Array(emptyRowsRequired).keys()).map(() => {
      return Array.from(Array(columnCount).keys()).map(() => {
        return "";
      });
    });

    return rows.map((r, idx) => (
      <div
        key={`column_${idx}`}
        className={classNames("row", columnCount && `columns-${columnCount}`)}
      >
        {r.map((value, i) => (
          <div key={`row_${i}`} className="cell">
            {value}
          </div>
        ))}
      </div>
    ));
  };

  const renderLastRow = () => {
    if (!lastRow) return null;
    return (
      <div
        className={classNames(
          "row",
          columnCount &&
            `columns-${lastRowColumnCount ? lastRowColumnCount : columnCount}`,
        )}
      >
        {lastRow.map((value, i) => (
          <div key={`last_row_${i}`} className="cell">
            {value}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className={classNames(
        "data-grid",
        className,
        lastRow && "highlighted-last-row",
      )}
    >
      {headerText && <div className="header">{headerText}</div>}
      {dataRows.map((r, rowIndex) => {
        const isArray = Array.isArray(r);
        const values = isArray ? r : r.values;
        return (
          <div
            key={rowIndex}
            className={classNames(
              "row",
              rowIndex === 0 && isFirstRowHeader && "column-header",
              columnCount && `columns-${columnCount}`,
              !isArray && r.className,
              rowClassNames &&
                rowClassNames[rowIndex - 1] &&
                rowClassNames[rowIndex - 1][0].toString().toLowerCase(),
            )}
          >
            {/* Row can either be an array of values, or an object with a className and an array property 'values' */}
            {values.map((value, cellIndex) => (
              <div key={`{row${rowIndex}_cell${cellIndex}`} className="cell">
                {value}
              </div>
            ))}
          </div>
        );
      })}
      {renderEmptyRows()}
      {renderLastRow()}
    </div>
  );
};

export default DataGrid;
