import React from "react";
import { ListBuilder, Table, Format } from "@redriver/cinnamon";
import { getRateGroupAudit } from "./actions";

const RateGroupAudit = ({ rateGroup }) => (
  <ListBuilder
    withTable
    loadAction={getRateGroupAudit}
    loadParams={{ rateGroupId: rateGroup.id }}
    renderTrigger={(showModal) => (
      <Table.MenuItem onClick={showModal}>Audit</Table.MenuItem>
    )}
    renderList={(tableProps, state, events) => (
      <React.Fragment>
        <Table {...tableProps} dataKey="id" emptyMessage="No Audits to show">
          <Table.Column
            title="Modified By"
            render={(item) => (
              <React.Fragment>{item.modifiedBy}</React.Fragment>
            )}
          />
          <Table.Column
            title="Modified Date"
            render={(item) => (
              <React.Fragment>
                <Format.Date value={item.modifiedDate} format="DD MMM YYYY" />
              </React.Fragment>
            )}
          />
          <Table.Column title="Name" render={(item) => item.name ?? "-"} />
          <Table.Column
            title="Applicable From"
            render={(item) =>
              item.applicableFromUtc ? (
                <React.Fragment>
                  <Format.Date
                    value={item.applicableFromUtc}
                    format="DD MMM YYYY"
                  />
                </React.Fragment>
              ) : (
                "-"
              )
            }
          />
        </Table>
      </React.Fragment>
    )}
  />
);

export default RateGroupAudit;
