import {
  FunderType,
  LiquidityChargeFunderKeys,
} from "features/../../../shared/constants/enums";

/**
 * Get the Boolean representation of any value - the string "false" will return `false`
 * @param {any} input The value to parse as a Boolean
 * @returns {Boolean} The Boolean representation of `input`, or `false` for the string "false"
 */
export const parseBool = (input) => {
  return typeof input === "string" && input.toLowerCase() === "false"
    ? false
    : !!input;
};

export const mapFunderTypeToLiquidityChargeKey = (funderType) => {
  switch (funderType) {
    case FunderType.Funder:
      return LiquidityChargeFunderKeys.Funder;
    case FunderType.Dealer:
      return LiquidityChargeFunderKeys.Dealer;
    case FunderType.CoFunded:
      return LiquidityChargeFunderKeys.Cofunded;
    default:
      return null;
  }
};

export const hideModals = () => {
  let modal_ele = document.getElementsByClassName("modal-styled-popup");
  for (let i = 0; i < modal_ele.length; ++i) {
    let item = modal_ele[i];
    item.getElementsByClassName("negative")[0].click();
  }
};
