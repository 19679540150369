import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import {
  Field,
  connectForm,
  FormUtils,
  LookupDropdown,
} from "@redriver/cinnamon";
import classNames from "classnames";
import FixedInputIndicator from "./FixedInputIndicator";
import {
  withPermissions,
  withCurrentUser,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import FixedFieldType, { FixableTypes } from "constants/forms/FixedFieldType";
import { UserArea } from "features/../../../shared/constants/enums";

/**
 * Custom Cinnamon component that provides an interface for the FixedFieldView object
 */
class FixedLookupDropdown extends Component {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.node,
    actions: PropTypes.node,
    inline: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fluid: PropTypes.bool,
    subLabel: PropTypes.string,
    subLabelPosition: PropTypes.string,
    renderReadOnly: PropTypes.func,
    required: PropTypes.bool,
    requiredError: PropTypes.string,
    minLength: PropTypes.number,
    minLengthError: PropTypes.string,
    maxLength: PropTypes.number,
    maxLengthError: PropTypes.string,
    confirmField: PropTypes.string,
    confirmFieldError: PropTypes.string,
    field: PropTypes.string.isRequired,
    showErrors: PropTypes.bool,
    allErrors: PropTypes.bool,
    animateErrors: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    customErrors: PropTypes.arrayOf(PropTypes.string),
    customValidator: PropTypes.func,
    notifiedFields: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string]),
      isFixed: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
      ]),
    }),
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.object,
    ]),
    passThruProps: PropTypes.object,
    formState: PropTypes.object,

    /**
     * If enabled, when clicking into the field, the field's value is selected and typing will override the value. This allows the field to be quickly overridden when tabbing.
     * @default true
     */
    selectOnFocus: PropTypes.bool,

    /**
     * Function to run when dropdown is clicked on
     */
    onFocus: PropTypes.func,

    /**
     * Function to run when dropdown is clicked away from
     */
    onBlur: PropTypes.func,

    /**
     * Sheet ID
     */
    sheetId: PropTypes.string,

    /**
     * Custom Editor Data
     */
    customEditorData: PropTypes.object,

    /**
     * Render function to render the trigger (what is displayed when dropdown is not open)
     */
    renderTrigger: PropTypes.func,

    /**
     * Adds CSS class to change display priority, use 1-5 to change display priority with 5 being the highest.
     * @default 0
     */
    displayPriority: PropTypes.number,
  };

  state = {
    fixType: FixedFieldType.None,
  };

  static defaultProps = {
    value: { value: "" },
    onChange: () => {},
    label: "",
    inline: false,
    selectOnFocus: true,
    selectOnBlur: false,
    selection: true,
    lookupOptions: {
      cache: true,
    },
    displayPriority: 0,
  };

  determineFixType(value) {
    return value.isFixed ? FixedFieldType.Set : FixedFieldType.None;
  }

  componentDidMount() {
    const { value } = this.props;

    const fixType = this.determineFixType(value);

    this.setState({
      fixType,
    });
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== this.props.value) {
      const fixType = this.determineFixType(value);

      this.setState({
        fixType,
      });
    }
  }

  enforceValueConstraints = (value, fixType) => {
    const {
      value: { setValue },
    } = this.props;

    return fixType === FixedFieldType.Set ? setValue : value;
  };

  /**
   * The onChange method for the `<Dropdown />` element of the FixedLookupDropdown
   */
  onValueChange = (_, { value }) => {
    this.props.onChange({
      ...this.props.value,
      value: value,
    });
  };

  /**
   * The onChange method for the `<FixedLookupDropdownEditor />` element of the FixedLookupDropdown
   */
  onValuePropsChange = (res) => {
    this.props.onChange({
      ...this.props.value,
      ...res,
      userArea: this.props.currentUser.userArea,
    });
  };

  onFocus = ({ target }) => {
    this.setState(
      {
        showFormatted: false,
      },
      () => {
        if (this.props.selectOnFocus) {
          target.select();
        }
      }
    );
  };

  onBlur = (e) => {
    if (this.props.onBlur) this.props.onBlur(e);
  };

  defaultRenderReadOnly = (props) => <p>{props.value.value}</p>;

  renderReadOnly = () =>
    (this.props.renderReadOnly || this.defaultRenderReadOnly)(this.props);

  getInferredValueOptions = () => {
    // auto-infer unknown value options when textField specified

    const { textField, value } = this.props;

    let inferredValueOptions = undefined;

    if (textField && value && value[textField]) {
      const text = value[textField];
      const textArray = Array.isArray(text) ? text : [text];
      const valueArray = Array.isArray(value) ? value : [value];

      if (valueArray.length === textArray.length) {
        inferredValueOptions = valueArray.map((v, i) => ({
          value: v.id,
          text: textArray[i],
        }));
      }

      return inferredValueOptions;
    }
  };

  render() {
    const {
      id,
      value,
      errors,
      showErrors,
      allErrors,
      animateErrors,
      readOnly,
      label,
      actions,
      inline,
      width,
      fluid,
      required,
      className,
      hasPermission,
      currentUser,
      onBlur,
      onFocus,
      selection,
      lookupOptions,
      lookup,
      lookupParams,
      customEditorData,
      renderTrigger,
      hideIndicator = false,
      displayPriority,
      disableValueChange,
    } = this.props;

    const { fixType } = this.state;

    const isAdmin = hasPermission(Targets.SheetAdmin, Actions.Edit);

    const disabled = this.props.disabled || fixType == FixedFieldType.Set;
    const indicatorDisabled =
      value?.isFixed &&
      value?.userArea === UserArea.IpsAdmin &&
      value?.userArea != currentUser.userArea;

    const hiddenIndicator =
      hideIndicator || (!isAdmin && fixType === FixedFieldType.None);
    const hasRightLabel = !hiddenIndicator;

    const unknownValueOptions = this.getInferredValueOptions();
    const unknownValueText =
      unknownValueOptions && unknownValueOptions.length === 1
        ? unknownValueOptions[0].text
        : null;

    return (
      !!value && (
        <Field
          id={id}
          inline={inline}
          required={required}
          disabled={disabled}
          renderReadOnly={readOnly && this.renderReadOnly}
          width={width}
          fluid={fluid}
          label={label}
          actions={actions}
          errors={FormUtils.fieldErrors(errors, showErrors, allErrors)}
          animateErrors={animateErrors}
          className={classNames(
            "fix-lookup-dropdown-container",
            hasRightLabel && "with-right-label",
            className
          )}
          onChange={this.onValueChange}
        >
          <LookupDropdown
            trigger={renderTrigger ? renderTrigger() : null}
            value={value ? value.value : null}
            lookup={lookup}
            lookupParams={lookupParams}
            onChange={this.onValueChange}
            searchSuggestions
            alwaysShowSuggestionsOnBlur
            width={8}
            allowAdditions
            onFocus={onFocus}
            onBlur={onBlur}
            unknownValueOptions={unknownValueOptions}
            unknownValueText={unknownValueText}
            selection={selection}
            lookupOptions={lookupOptions}
            disabled={disabled}
          />
          {hasRightLabel && (
            <FixedInputIndicator
              isAdmin={isAdmin}
              fixType={fixType}
              lookup={lookup}
              lookupParams={lookupParams}
              fieldId={this.props.value.id || null}
              values={[this.props.value.value || ""]}
              onForcedValuesChanged={this.onValuePropsChange}
              minorCurrency={this.props.minorCurrency}
              fixableType={FixableTypes.Dropdown}
              customEditorData={{
                sheetId: this.props.sheetId,
                unknownValueOptions,
                unknownValueText,
                ...customEditorData,
              }}
              className={className}
              disabled={indicatorDisabled}
              disableValueChange={disableValueChange}
              displayPriority={displayPriority}
            />
          )}
        </Field>
      )
    );
  }
}

export default connectForm({
  displayName: (props) =>
    props.label && typeof props.label === "string" ? props.label : "Input",
  validators: [],
})(compose(withPermissions, withCurrentUser)(FixedLookupDropdown));
