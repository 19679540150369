import React from "react";
import { useTranslation } from "react-i18next";
import { Format } from "@redriver/cinnamon";
import DataGrid from "./DataGrid";
import { FunderType } from "features/../../../shared/constants/enums";

const CfaChargeGrid = ({ data, funderType }) => {
  const { t } = useTranslation("capitalCalc", {
    keyPrefix: "infoModal.funderTypes.cfaPayment",
  });

  const funderTypeCfaCharges = {
    [FunderType.Funder]: "funderCfaPayment",
    [FunderType.CoFunded]: "cofundedCfaPayment",
  };

  return (
    <DataGrid
      className="cfa-charge"
      headerText={t("title")}
      dataRows={[
        [
          t("cfaPayment"),
          <Format.Number
            key={1}
            value={data.cfaPayment}
            format={process.env.CURRENCY_CODE}
          />,
        ],
        [
          t("roundingDifferenceAllocated"),
          <Format.Number
            key={2}
            value={data.roundingDifferenceAllocated}
            format={process.env.CURRENCY_CODE}
          />,
        ],
        {
          className: "bold",
          values: [
            t(funderTypeCfaCharges[funderType]),
            <Format.Number
              key={3}
              value={data.funderCfaPayment}
              format={process.env.CURRENCY_CODE}
            />,
          ],
        },
      ]}
    />
  );
};

export default CfaChargeGrid;
