import React from "react";
import classNames from "classnames";
import { Icon } from "semantic-ui-react";

const ToggleSwitch = ({
  trueText,
  trueClassName,
  falseText,
  falseClassName,
  value,
  setValue,
}) => {
  return (
    <div className="view-toggle-container">
      <div
        className={classNames(trueClassName, "view-toggle", value && "active")}
        onClick={() => setValue(true)}
      >
        {trueText}
        {value && <Icon name="check" />}
      </div>
      <div
        className={classNames(
          falseClassName,
          "view-toggle",
          !value && "active"
        )}
        onClick={() => setValue(false)}
      >
        {falseText}
        {!value && <Icon name="check" />}
      </div>
    </div>
  );
};

export default ToggleSwitch;
