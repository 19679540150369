import { isFieldFixed } from "../../helpers";

export function bringFinanceSelectionInRange(newSheetData, funderType) {
  const funderSettings = newSheetData.defaults.funderTypeSettings.find(
    (obj) => obj.type == funderType.funderType
  );

  if (
    !funderType?.financeSelection?.value ||
    isFieldFixed(funderType?.financeSelection)
  ) {
    return;
  }

  funderType.financeSelection.value = Math.max(
    parseInt(funderType.financeSelection.value),
    funderSettings.vMinSelection
  );
  funderType.financeSelection.value = Math.min(
    parseInt(funderType.financeSelection.value),
    funderSettings.vMaxSelection
  );
}
