import React, { useState } from "react";
import { connect } from "react-redux";
import { Icon, Transition } from "semantic-ui-react";
import { PageLoader } from "@redriver/cinnamon";
import { getRatesState } from "../selectors";
import {
  EditableYieldRateTable,
  setYieldRatesForm,
  saveYieldRates,
  getYieldRates,
} from "features/../../../shared/features/Settings";
import { lockYields, unlockYields } from "./actions";

const YieldMatrix = ({
  rateGroupId,
  yieldRates,
  ratesLoading,
  setYieldRatesForm,
  saveYieldRates,
  getYieldRates,
  hasLockAccess,
  lockYields,
  unlockYields,
}) => {
  const [collapsedFunders, setCollapsedFunders] = useState([]);
  const [tablesInEditMode, setTablesInEditMode] = useState([]);

  if (ratesLoading) {
    return <PageLoader />;
  }
  if (!yieldRates || yieldRates.length == 0) return null;

  const toggleReadOnly = async (key) => {
    const index = tablesInEditMode.indexOf(key);
    if (index == -1) {
      await lockYields(rateGroupId);
      // If not already in array, add it
      setTablesInEditMode([...tablesInEditMode, key]);
    } else {
      await unlockYields(rateGroupId);
      // If it is in the array, remove it
      setTablesInEditMode(tablesInEditMode.filter((x) => x !== key));
    }
  };

  const onToggleCollapseFunder = (funderIndex) => {
    let index = collapsedFunders.indexOf(funderIndex);
    if (index == -1) {
      // If not already in array, add it
      setCollapsedFunders([...collapsedFunders, funderIndex]);
    } else {
      // If it is in the array, remove it
      setCollapsedFunders(collapsedFunders.filter((x) => x !== funderIndex));
    }
  };

  return (
    <div className="yields-matrix">
      {yieldRates.map((funder, funderIndex) => (
        <div className="funder-section" key={funderIndex}>
          <div
            className="funder-section-header"
            onClick={() => onToggleCollapseFunder(funderIndex)}
          >
            <h3>{funder.title}</h3>
            <Icon
              name="dropdown"
              rotated={
                collapsedFunders.some((x) => x == funderIndex)
                  ? "counterclockwise"
                  : null
              }
            />
          </div>
          <Transition
            animation="slide down"
            visible={!collapsedFunders.some((x) => x == funderIndex)}
            duration={100}
          >
            <div>
              {funder.yields.map((financeType, financeTypeIndex) => (
                <div className="finance-type-section" key={financeTypeIndex}>
                  <h4>
                    {funder.title} {financeType.title}
                  </h4>
                  <div className="payment-method-container">
                    {financeType.yields.map(
                      (paymentMethod, paymentMethodIndex) => (
                        <div
                          className="payment-method-section"
                          key={paymentMethodIndex}
                        >
                          <EditableYieldRateTable
                            key={`${funderIndex}_${financeTypeIndex}_${paymentMethodIndex}`}
                            title={paymentMethod.title}
                            fullTitle={`${funder.title} ${financeType.title} ${paymentMethod.title}`}
                            rates={paymentMethod}
                            onToggleReadOnly={() =>
                              toggleReadOnly(
                                `${funderIndex}_${financeTypeIndex}_${paymentMethodIndex}`
                              )
                            }
                            readOnly={
                              !tablesInEditMode.some(
                                (x) =>
                                  x ==
                                  `${funderIndex}_${financeTypeIndex}_${paymentMethodIndex}`
                              )
                            }
                            onFormChange={(field, data, applyChanges) =>
                              setYieldRatesForm(
                                getRatesState,
                                field,
                                data,
                                applyChanges,
                                funderIndex,
                                financeTypeIndex,
                                paymentMethodIndex
                              )
                            }
                            onSubmit={async () => {
                              const response = await saveYieldRates(
                                paymentMethod,
                                rateGroupId
                              );
                              if (response.success) {
                                toggleReadOnly(
                                  `${funderIndex}_${financeTypeIndex}_${paymentMethodIndex}`
                                );
                              }
                              return response;
                            }}
                            onCancel={() => {
                              unlockYields(rateGroupId);
                              getYieldRates(getRatesState);
                            }}
                            loading={ratesLoading}
                            hasLockAccess={hasLockAccess}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Transition>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { rates, filters, ratesLoading } = getRatesState(state);
  const { rateGroupId } = filters;
  const { yieldRates } = rates;

  return {
    rateGroupId,
    yieldRates,
    ratesLoading,
  };
};

const actions = {
  setYieldRatesForm,
  saveYieldRates,
  getYieldRates,
  lockYields,
  unlockYields,
};

export default connect(mapStateToProps, actions)(YieldMatrix);
