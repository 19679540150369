import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Form } from "@redriver/cinnamon";
import { LargeFormatPrinterDropdownLookup } from "features/../../../shared/features/Settings/LargeFormat/lookups";
import { largeFormatCreateModalFieldName } from "../constants";
import { clearLargeFormatCreateModal } from "features/../../../shared/features/Settings/LargeFormat/actions";
import LargeFormatMedia from "./LargeFormatMedia";
import AdminAdd from "features/../../../shared/assets/green-add.svg";
import { IconButton } from "features/../../../shared/components/buttons";
import { calculateSheet, newPaperMeter } from "features/Sheets/ManageSheet";
import { getSheetDataState } from "features/Sheets";
import { useTranslation } from "react-i18next";
import LargeFormatModalInkInclusive from "./LargeFormatModalInkInclusive";
import { LargeFormatPriceType } from "features/../../../shared/constants/enums";
import {
  PermissionCheck,
  withCurrentUser,
  withPermissions,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import LfpPriceType from "features/../../../shared/constants/enums/LfpPriceType";
const yesNoOpts = [
  { value: true, text: "Yes" },
  { value: false, text: "No" },
];

const LargeFormatModalDetails = ({
  largeFormatCreateModal,
  sheetId,
  printerId,
  lfPrinterConfig,
  lfEditModalLoaded,
  lfRollMedia,
  maximumPaperMeters,
  lfInkInclusive,
  compLfInkInclusive,
  editMode,
  calculateSheet,
  largeFormatGroup,
  preventUpdates,
  hasPermission,
  calculationPaused,
}) => {
  useEffect(() => {
    if (lfEditModalLoaded && !calculationPaused) {
      calculateSheet();
    }
  }, [lfEditModalLoaded, calculateSheet, calculationPaused]);

  const viewPriceA = useMemo(
    () => hasPermission(Targets.Sheet, Actions.ViewLargeFormatPriceA),
    [],
  );

  const viewPriceB = useMemo(
    () => hasPermission(Targets.Sheet, Actions.ViewLargeFormatPriceB),
    [],
  );

  const { t } = useTranslation();
  const printerChanged = useMemo(
    () => largeFormatCreateModal?.printerChanged,
    [largeFormatCreateModal],
  );
  const inkCostPerClick = useMemo(
    () => largeFormatGroup?.printerMeter?.costPerClick?.value,
    [largeFormatGroup],
  );
  const inkCostPerUnit = useMemo(
    () =>
      printerChanged || !editMode
        ? largeFormatCreateModal.costPerUnit
        : largeFormatGroup?.printerMeter?.costPerUnit,
    [printerChanged, editMode, largeFormatCreateModal, largeFormatGroup],
  );

  return (
    <Form.Object
      field={largeFormatCreateModalFieldName}
      propagateUpdates="always"
    >
      <div className="header-test">
        {t("systemSchedule:largeFormatModal:printerConfig:title")}
      </div>
      <div className="box">
        <div className="large-format-values printer-conf">
          <div className="printer-input-wrapper">
            <LargeFormatPrinterDropdownLookup
              field="printerId"
              sheetId={sheetId}
              className="printer-dd"
              placeholder={t(
                "systemSchedule:largeFormatModal:printerConfig:fields:printer:placeholder",
              )}
              required
              disabled={preventUpdates}
            />

            {(!editMode || lfEditModalLoaded) && (
              <Form.Trigger
                field="printerId"
                event="onChange"
                action="clear"
                target={"printerInkUsageId"}
              />
            )}

            {(!editMode || lfEditModalLoaded) && (
              <Form.Trigger
                field="printerId"
                event="onChange"
                action="change"
                target={"lfInkInclusive"}
                value={{}}
              />
            )}

            {/* When Printer is changed, clear Paper Widths in Roll Media*/}
            {(!editMode || lfEditModalLoaded) && (
              <Form.Trigger
                field="printerId"
                event="onChange"
                action="change"
                target={"lfRollMedia"}
                value={[]}
              />
            )}

            {/* When Printer is changed, reset Price Type to Price A */}
            {(!editMode || lfEditModalLoaded) && (
              <Form.Trigger
                field="printerId"
                event="onChange"
                action="change"
                target={"priceType"}
                value={LfpPriceType.PriceA}
              />
            )}
          </div>
          <Form.Object field="lfPrinterConfig">
            <div className="values-section">
              <div className="t-market">
                <Form.Input
                  field="targetMarket"
                  disabled
                  label={t(
                    "systemSchedule:largeFormatModal:printerConfig:fields:targetMarket:label",
                  )}
                  placeholder={t(
                    "systemSchedule:largeFormatModal:printerConfig:fields:targetMarket:placeholder",
                  )}
                />
              </div>

              <Form.Input
                className="paper-width"
                field="paperWidth"
                disabled
                label={t(
                  "systemSchedule:largeFormatModal:printerConfig:fields:paperWidth:label",
                )}
                placeholder={t(
                  "systemSchedule:largeFormatModal:printerConfig:fields:paperWidth:placeholder",
                )}
              />
              <Form.Input
                className="colours"
                field="colours"
                disabled
                label={t(
                  "systemSchedule:largeFormatModal:printerConfig:fields:colours:label",
                )}
                placeholder={t(
                  "systemSchedule:largeFormatModal:printerConfig:fields:colours:placeholder",
                )}
              />
              <Form.Input
                className="scanner"
                field="scanner"
                disabled
                label={t(
                  "systemSchedule:largeFormatModal:printerConfig:fields:scanner:label",
                )}
                placeholder={t(
                  "systemSchedule:largeFormatModal:printerConfig:fields:scanner:placeholder",
                )}
                readOnly
                renderReadOnly={(item) => {
                  const content =
                    item.value === true
                      ? "Yes"
                      : item.value === false
                      ? "No"
                      : "-";

                  return <span className="scanner">{content}</span>;
                }}
              />
            </div>
          </Form.Object>
        </div>
      </div>

      <div className="extras">
        <PermissionCheck
          target={Targets.Sheet}
          action={Actions.ViewEnableScanMeter}
        >
          <Form.Dropdown
            options={yesNoOpts}
            field="includeScan"
            label={t("systemSchedule:largeFormatModal:fields:scanMeter:label")}
            placeholder={t(
              "systemSchedule:largeFormatModal:fields:scanMeter:placeholder",
            )}
            disabled={
              preventUpdates ||
              !lfPrinterConfig.hasScannerMeter ||
              !lfPrinterConfig.scanner
            }
            inline
          />
        </PermissionCheck>
        <PermissionCheck
          target={Targets.Sheet}
          action={Actions.ViewEnableCleaningMeter}
        >
          <Form.Dropdown
            options={yesNoOpts}
            field="includeCleaning"
            label={t(
              "systemSchedule:largeFormatModal:fields:includeCleaning:label",
            )}
            placeholder={t(
              "systemSchedule:largeFormatModal:fields:includeCleaning:placeholder",
            )}
            inline
            disabled={
              preventUpdates ||
              !lfPrinterConfig.hasCleaningMeter ||
              !lfPrinterConfig.includeCleaning
            }
          />
        </PermissionCheck>
        <div data-spacer />
        {/* Cinnamon (at the time of writing) does not allow individual options to be disabled. So we render 2 RadioGroup components, one for the enabled options, and one for the disabled options */}
        <Form.RadioGroup
          required
          field="priceType"
          options={Object.values(LargeFormatPriceType)
            .filter(
              (x) =>
                (viewPriceA && x == LfpPriceType.PriceA) ||
                (viewPriceB &&
                  x == LfpPriceType.PriceB &&
                  lfPrinterConfig.priceBVisible),
            )
            .map((x) => ({
              value: x,
              text: t(`enums:largeFormatPriceType:${x}`),
            }))}
        />
        {!lfPrinterConfig.priceBVisible && viewPriceB && (
          <Form.RadioGroup
            disabled
            field="priceTypeDisabled"
            options={Object.values(LargeFormatPriceType)
              .filter((x) => viewPriceB && x == LfpPriceType.PriceB)
              .map((x) => ({
                value: x,
                text: t(`enums:largeFormatPriceType:${x}`),
              }))}
          />
        )}
      </div>
      <div className="header-test">
        {t("systemSchedule:largeFormatModal:inkInclusive:title")}
      </div>
      <div className="box">
        <LargeFormatModalInkInclusive
          field="printerInkUsageId"
          optionField="lfInkInclusive"
          printerId={printerId}
          sheetId={sheetId}
          editMode={editMode}
          lfEditModalLoaded={lfEditModalLoaded}
          lfInkInclusive={lfInkInclusive}
          comparison={false}
          inkUsageCalcIncludingCapital={
            largeFormatCreateModal.inkUsageCalcIncludingCapital
          }
          inkUsageCalcExcludingCapital={
            largeFormatCreateModal.inkUsageCalcExcludingCapital
          }
          costPerClick={inkCostPerClick}
          costPerUnit={inkCostPerUnit}
          printerChanged={printerChanged}
          priceType={largeFormatCreateModal.priceType}
          disabled={preventUpdates}
        />
        <LargeFormatModalInkInclusive
          field="compPrinterInkUsageId"
          optionField="compLfInkInclusive"
          printerId={printerId}
          sheetId={sheetId}
          editMode={editMode}
          lfEditModalLoaded={lfEditModalLoaded}
          lfInkInclusive={compLfInkInclusive}
          comparison={true}
          inkUsageCalcIncludingCapital={
            largeFormatCreateModal.compInkUsageCalcIncludingCapital
          }
          inkUsageCalcExcludingCapital={
            largeFormatCreateModal.compInkUsageCalcExcludingCapital
          }
          costPerUnit={inkCostPerUnit}
          costPerClick={inkCostPerClick}
          printerChanged={printerChanged}
          priceType={largeFormatCreateModal.priceType}
          disabled={preventUpdates}
        />
      </div>
      <div className="header-test">
        {t("systemSchedule:largeFormatModal:rollFedMediaInclusive:title")}
      </div>
      <div className="box">
        <Form.Array
          field="lfRollMedia"
          propagateUpdates="always"
          arrayKey="index"
          disabled={preventUpdates}
        >
          {({ fields }) => (
            <LargeFormatMedia
              sheetId={sheetId}
              lfPrinterConfig={lfPrinterConfig}
              fields={fields}
              lfEditModalLoaded={lfEditModalLoaded}
              disabled={preventUpdates}
            />
          )}
        </Form.Array>

        {lfRollMedia.length < maximumPaperMeters && (
          <Form.ArrayAdder
            className="add-media"
            field="lfRollMedia"
            key="id"
            disabled={preventUpdates}
            populate={
              lfRollMedia.length >= maximumPaperMeters
                ? null
                : (k) => newPaperMeter(k, lfRollMedia.length + 3)
            }
          >
            <IconButton
              className={`wizard-title-button admin-button`}
              icon={AdminAdd}
              content={t(
                "systemSchedule:largeFormatModal:rollFedMediaInclusive:add",
              )}
            />
          </Form.ArrayAdder>
        )}
      </div>
    </Form.Object>
  );
};

const mapStateToProps = (state) => {
  const {
    largeFormatCreateModal,
    defaults,
    largeFormatGroups,
    preventUpdates,
  } = getSheetDataState(state);
  return {
    largeFormatCreateModal,
    printerId: largeFormatCreateModal.printerId,
    lfPrinterConfig: largeFormatCreateModal.lfPrinterConfig,
    lfInkInclusive: largeFormatCreateModal.lfInkInclusive,
    compLfInkInclusive: largeFormatCreateModal.compLfInkInclusive,
    lfRollMedia: largeFormatCreateModal.lfRollMedia,
    maximumPaperMeters: defaults.largeFormatDefaults.maximumPaperMeters,
    reduxState: state,
    largeFormatGroup: largeFormatGroups[largeFormatCreateModal.groupIndex],
    preventUpdates,
  };
};

const actions = (dispatch) => ({
  clearLargeFormatCreateModal: () => dispatch(clearLargeFormatCreateModal()),
  calculateSheet: () => dispatch(calculateSheet(true)),
  calculateComparisonData: () => dispatch(calculateSheet(true)),
});

export default withPermissions(
  withCurrentUser(connect(mapStateToProps, actions)(LargeFormatModalDetails)),
);
