import React from "react";
import { ModalBuilder,} from "@redriver/cinnamon";
import { IconButton } from "features/../../../shared/components/buttons";
import { createClient } from "features/../../../shared/features/Clients/actions";
import ClientForm from "./ClientForm";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";

const AddClient = ({ as: As = IconButton, onAdded, ...props }) => {
  const { t } = useTranslation();
  return (
    <ModalBuilder
      withForm
      submitAction={createClient}
      onSubmitted={onAdded}
      renderTrigger={showModal => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Add
          {...modalProps}
          header={t("clients:tables:clients:newClient:heading")}
          submitLabel={t("clients:tables:clients:newClient:submitText")}
        >
          <ClientForm {...formProps} />
        </TranslatedModal.Add>
      )}
    />
  );
};

export default AddClient;
