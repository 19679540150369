import React from 'react';
import { ListClientAuthorisedSignatories } from "features/Clients";
import { PageContent } from "@redriver/cinnamon";

const ClientAuthorisedSignatoriesPage = ({ match, clientName }) => (
    <PageContent>
        <ListClientAuthorisedSignatories clientId={match.params.id} clientName={clientName} />
    </PageContent>
);

export default ClientAuthorisedSignatoriesPage;