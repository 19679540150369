import React from "react";
import { useTranslation } from "react-i18next";
import { Segment } from "semantic-ui-react";
import { PageContent } from "@redriver/cinnamon";
import { EditUser } from "features/Admin";
import { PasswordChange } from "features/System";
import PinChange from "features/Admin/PinChange";

const AccountPage = () => {
  const { t } = useTranslation("system");
  return (
    <PageContent>
      <h4>{t("Account_Details")}</h4>
      <div className="account-layout">
        <Segment>
          <EditUser />
        </Segment>
        <Segment>
          <PasswordChange />
        </Segment>
        <Segment>
          <PinChange />
        </Segment>
      </div>
    </PageContent>
  );
};

export default AccountPage;
