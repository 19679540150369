import {
  PaymentMethod,
  //PaperInclusiveChargeType,
  PaperInclusiveChargeTypeText,
  SheetTypeText,
  FinanceType,
} from ".";

const mapEnumToDropdown = (item) => ({ text: item[1], value: item[0] });

export const paymentMethods =
  Object.entries(PaymentMethod).map(mapEnumToDropdown);

export const funderTypes = Object.entries({
  Funder: "Funder",
  DealerFunded: "Supplier",
  CoFunded: "Co-Funded",
}).map(mapEnumToDropdown);

export const financeType = Object.entries(FinanceType).map(mapEnumToDropdown);

export const paperInclusiveChargeTypes = (t) =>
  Object.entries(PaperInclusiveChargeTypeText(t)).map(mapEnumToDropdown);

export const yesNoOpts = [
  { text: "Yes", value: "true" },
  { text: "No", value: "false" },
];

export const financeTypes = Object.entries({
  EFA: "EFA",
  FMV: "FMV",
}).map(mapEnumToDropdown);

export const serviceTypes = Object.entries({
  Mandatory: "Mandatory",
  Discretionary: "Discretionary",
}).map(mapEnumToDropdown);

export const contractType = Object.entries({
  PaperContract: "Amethyst",
  HostingContract: "Kyanite",
}).map(mapEnumToDropdown);

export const disableMinLogicAvailableOptions = Object.entries({
  QuarterlyServices: "Quarterly Services",
  SystemSchedule: "System Schedules",
}).map(mapEnumToDropdown);

export const agreementStabilityOptionsEmpty = Object.entries({
  Option1: "",
  Option2: "",
  Option3: "",
  Option4: "",
}).map(mapEnumToDropdown);
