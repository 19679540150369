import { UserType as UserTypes } from "features/../../../shared/constants/enums/UserType";
import { UserArea } from "features/../../../shared/constants/enums";

//Services
//function to apply check and apply min values to a service.
function getCorrectedServiceValue(service, serviceData, userType) {
  const isValueSetByIPS =
    service?.value.setValue && service?.value.userArea == UserArea.IpsAdmin;
  if (isValueSetByIPS) return service?.value.setValue;

  let minValue = 0;
  const serviceId = service?.service?.value;
  const serviceValue = service?.value?.value;

  if (serviceId !== undefined && serviceData) {
    const selectedService = serviceData.find((obj) => {
      return obj.id === serviceId;
    });

    minValue =
      userType === UserTypes.HQ
        ? selectedService?.vMinValue ?? 0
        : selectedService?.minValue ?? 0;

    return minValue !== 0 && serviceValue < minValue ? minValue : serviceValue;
  }
}

export function correctQuarterlyServiceValues(services, serviceData, userType) {
  if (services && services.length >= 1) {
    services.forEach((item) => {
      item?.value &&
        (item.value.value = getCorrectedServiceValue(
          item,
          serviceData,
          userType
        ));
    });
  }
}
