export const AgreementStabilityOptions = {
  Option1: "Option1",
  Option2: "Option2",
  Option3: "Option3",
  Option4: "Option4",
};

export const AgreementStabilityOptionsString = {
  Option1: "Option_1",
  Option2: "Option_2",
  Option3: "Option_3",
  Option4: "Option_4",
};
