import React from "react";
import { Route, Switch } from "react-router-dom";
import { AppRoutes } from "constants/routes";
import DataUploadsPage from "./DataUploadsPage";


const DataUploadsRouter = () => (
  <Switch>
    <Route exact path={AppRoutes.DataUploads} component={DataUploadsPage} />
  </Switch>
);

export default DataUploadsRouter;
