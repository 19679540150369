import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "AGREEMENTS";
const ActionTypes = {
  getDealers: `${NAMESPACE}/GET_DEALERS`,
};

export const getDealers = ({ filters, pagination }, params) => {
  return apiGet(ActionTypes.getDealers, "agreements/dealers", {
    ...filters,
    ...pagination,
    ...params,
  });
};
