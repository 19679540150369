import React from "react";
import { compose } from "redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Message, Icon } from "semantic-ui-react";
import {
  withCurrentUser,
  withPermissions,
} from "features/../../../shared/components/auth";
import {
  SheetState,
  UserType,
  SheetViewType,
} from "features/../../../shared/constants/enums";
import { Targets, Actions } from "constants/permissions";
import { getSheetDataState } from "../selectors";

const SheetStatusMessage = ({ currentUser, hasPermission, sheetViewType }) => {
  const {
    isDraft,
    stateIps,
    statePrincipal,
    physicalDocumentSheetStatePrincipal,
    submittedForPhysicalDocumentFlow,
  } = useSelector(getSheetDataState);

  const { t } = useTranslation("enums", {
    keyPrefix: process.env.TRADE_AS_IPS
      ? "SheetStateIps"
      : submittedForPhysicalDocumentFlow
      ? "PhysicalDocumentSheetStatePrincipal"
      : "SheetStatePrincipal",
  });

  if (isDraft || !currentUser) return null;

  const sheetState = process.env.TRADE_AS_IPS
    ? stateIps
    : submittedForPhysicalDocumentFlow
    ? physicalDocumentSheetStatePrincipal
    : statePrincipal;

  const isAdmin = hasPermission(Targets.SheetAdmin, Actions.Edit);
  const isInternalUser = currentUser.userType === UserType.HQ;

  return (
    <Message color="orange" icon compact className="sheet-status-message">
      <Icon name={"exclamation"} size="mini" />

      <Message.Content>
        <p>
          {"This sheet's status is "}
          <strong>{t(sheetState)}</strong>
        </p>
        {!isInternalUser &&
          !isAdmin &&
          !(
            sheetViewType === SheetViewType.IpsAdmin ||
            sheetViewType === SheetViewType.SupplierAdmin
          ) && <p>You will not be able to make any further changes.</p>}
      </Message.Content>
    </Message>
  );
};

export default compose(withPermissions, withCurrentUser)(SheetStatusMessage);
