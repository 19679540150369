import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { Table } from "@redriver/cinnamon";
import { UserRoutes } from "constants/routes";
import ActivateUser from "./ActivateUser";
import LockOutUser from "./LockOutUser";
import SendPasswordReset from "./SendPasswordReset";
import ResendVerification from "./ResendVerification";

const UserActions = ({ user, onRefresh }) => (
  <Table.Menu openOnHover>
    <Table.MenuItem as={Link} to={UserRoutes.User(user.id)}>
      Edit User
    </Table.MenuItem>
    <Dropdown.Divider />
    {user.isDisabled ? (
      <ActivateUser user={user} onActivated={onRefresh} as={Table.MenuItem}>
        Activate Account
      </ActivateUser>
    ) : (
      <React.Fragment>
        {user.isVerified ? (
          <SendPasswordReset user={user} as={Table.MenuItem}>
            Send Password Reset
          </SendPasswordReset>
        ) : (
          <ResendVerification user={user} as={Table.MenuItem}>
            Resend Verification
          </ResendVerification>
        )}
        <LockOutUser user={user} onLockOut={onRefresh} as={Table.MenuItem}>
          Lock Out
        </LockOutUser>
      </React.Fragment>
    )}
  </Table.Menu>
);

export default UserActions;
