import React from "react";
import { useTranslation } from "react-i18next";
import CalcBreakdown from "./CalcBreakdown";
import {
  RatesAndSettingsGrid,
  CapitalInvestmentGrid,
  SettlementsGrid,
  CfaChargeGrid,
  YieldInformationGrid,
  CapitalTotalGrid,
  InvestmentPayoutGrid,
  FunderPayoutGrid,
  CoFundedCfaChargeGrid,
  CoTerminusGrid,
} from "./Grids";
import { numericFormatter } from "components/displays/DisplayHelper";

const CoFundedCFA = ({ breakdown }) => {
  const { t } = useTranslation("capitalCalc", {
    keyPrefix: "infoModal.funderTypes",
  });

  if (!breakdown) return null;

  return (
    <div className="funder-type-section cofunded">
      <h4>{t("cofundedTitle")}</h4>

      <div className="summary">
        <RatesAndSettingsGrid info={breakdown.ratesAndSettings} />
        <CapitalInvestmentGrid
          data={breakdown.capitalInvestment}
          funderType={breakdown.funderType}
        />
        <SettlementsGrid
          data={breakdown.settlementsGrid}
          funderType={breakdown.funderType}
        />
      </div>
      <div className="summary-footer">
        <div></div>
        <CapitalTotalGrid data={breakdown} funderType={breakdown.funderType} />
      </div>

      <CalcBreakdown
        data={breakdown.capitalCalcCalcBreakdown}
        funderType={breakdown.funderType}
      />

      <div className="breakdown"></div>

      <div className="charges-wrapper">
        <div className="charges">
          <div>
            <FunderPayoutGrid
              data={breakdown.payoutGrid}
              funderType={breakdown.funderType}
            />
          </div>
          <div className="charges-right-container">
            <div>
              <CfaChargeGrid
                data={breakdown.cfaChargeGrid}
                funderType={breakdown.funderType}
              />
              <br />
              <YieldInformationGrid data={breakdown.yieldInformationGrid} />
              <br />
              <CoFundedCfaChargeGrid data={breakdown.coFundedCfaChargeGrid} />
              <br />
              <div>
                <div className="padded coFundPercentage">
                  {t("details.supplierCoFundPercentage")}
                  <b>
                    {numericFormatter(
                      breakdown.dealerCofundPercentage,
                      0,
                      2,
                      "",
                      "%"
                    )}
                  </b>
                </div>
                <InvestmentPayoutGrid data={breakdown.investmentPayoutGrid} />
              </div>
            </div>
            <div>
              <CoTerminusGrid
                info={breakdown.coTerminusGrid}
                funderType={breakdown.funderType}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoFundedCFA;
