import React from "react";
import { useTranslation } from "react-i18next";
import { Format } from "@redriver/cinnamon";
import DataGrid from "./DataGrid";
import { FunderType } from "features/../../../shared/constants/enums";
import classNames from "classnames";

const SettlementsGrid = ({ data, funderType = "" }) => {
  const { t } = useTranslation("capitalCalc", {
    keyPrefix: "infoModal.funderTypes.settlements",
  });

  let titleKey = "";
  switch (funderType) {
    case FunderType.Funder:
      titleKey = "funderTitle";
      break;
    case FunderType.Dealer:
      titleKey = "supplierTitle";
      break;
    case FunderType.CoFunded:
      titleKey = "cofundedTitle";
      break;
  }

  const rows = [
    {
      rowClassNames: data.settlements?.map((s) => [[s.fieldType]]),
      values: [t("reference"), t("upgrade"), t("amount")],
    },
  ].concat(
    data.settlements?.map((s, idx) => [
      s.reference,
      s.isUpgrade ? t("yes") : t("no"),
      <Format.Number
        key={idx}
        value={s.amount}
        format={process.env.CURRENCY_CODE}
      />,
    ]),
  );

  return (
    <DataGrid
      className={classNames("settlements", funderType?.toLowerCase())}
      columnCount={3}
      headerText={t(titleKey)}
      isFirstRowHeader={true}
      minimumRows={6}
      dataRows={rows}
      lastRow={[
        t(titleKey),
        <Format.Number
          key={1}
          value={data.settlementsTotal}
          format={process.env.CURRENCY_CODE}
          decimalPlaces={2}
        />,
      ]}
      lastRowColumnCount={2}
    />
  );
};

export default SettlementsGrid;
