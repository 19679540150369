import React from "react";
import { Format } from "@redriver/cinnamon";

const BlankCurrencyFormatter = ({
  value,
  format = process.env.CURRENCY_CODE,
  ...otherProps
}) =>
  value != null ? (
    <span className={value < 0 ? "red" : ""}>
      {value < 0 ? "(" : ""}
        <Format.Number value={Math.abs(value)} format={format}  {...otherProps} />
      {value < 0 ? ")" : ""}
    </span>
  ) : (
    "-"
  );

export default BlankCurrencyFormatter;
