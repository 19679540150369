import React from "react";
import { Table } from "@redriver/cinnamon";
import ProcessUpload from "./ProcessUpload";

const DataUploadActions = ({ item, onSubmitted, dealerOfficeId }) => {
  const canProcess =
    !item.processedUtc &&
    (!item.requiresApproval || (item.requiresApproval && item.isApproved));
  const showActions = canProcess;

  if (!showActions) return null;
  return (
    <Table.Menu>
      {canProcess && (
        <ProcessUpload
          item={item}
          onSubmitted={onSubmitted}
          dealerOfficeId={dealerOfficeId}
        />
      )}
    </Table.Menu>
  );
};

export default DataUploadActions;
