import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { Input as SInput, TextArea as STextArea } from "semantic-ui-react";
import { Field, connectForm, FormUtils } from "@redriver/cinnamon";
import classNames from "classnames";
import FixedInputIndicator from "./FixedInputIndicator";
import {
  withPermissions,
  withCurrentUser,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import FixedFieldType, { FixableTypes } from "constants/forms/FixedFieldType";
import { UserArea } from "features/../../../shared/constants/enums";

/**
 * Custom Cinnamon component that provides an interface for the FixedFieldView object
 */
class FixedString extends Component {
  static propTypes = {
    label: PropTypes.node,
    actions: PropTypes.node,
    inline: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fluid: PropTypes.bool,
    subLabel: PropTypes.string,
    subLabelPosition: PropTypes.string,
    renderReadOnly: PropTypes.func,
    required: PropTypes.bool,
    requiredError: PropTypes.string,
    minLength: PropTypes.number,
    minLengthError: PropTypes.string,
    maxLength: PropTypes.number,
    maxLengthError: PropTypes.string,
    confirmField: PropTypes.string,
    confirmFieldError: PropTypes.string,
    field: PropTypes.string.isRequired,
    showErrors: PropTypes.bool,
    allErrors: PropTypes.bool,
    animateErrors: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    customErrors: PropTypes.arrayOf(PropTypes.string),
    customValidator: PropTypes.func,
    notifiedFields: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string]),
      setValue: PropTypes.number,
    }),
    hideIndicator: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.object,
    ]),
    passThruProps: PropTypes.object,
    formState: PropTypes.object,

    /**
     * If enabled, when clicking into the field, the field's value is selected and typing will override the value. This allows the field to be quickly overridden when tabbing.
     * @default true
     */
    selectOnFocus: PropTypes.bool,

    /**
     * If enabled, renders a TextArea instead of an Input element
     */
    textArea: PropTypes.bool,
    /**
     * Adds CSS class to change display priority, use 1-5 to change display priority with 5 being the highest.
     * @default 0
     */
    displayPriority: PropTypes.number,
  };

  state = {
    fixType: FixedFieldType.None,
  };

  static defaultProps = {
    value: { value: "", isFixed: false },
    onChange: () => {},
    label: "",
    inline: false,
    selectOnFocus: true,
    displayPriority: 0,
  };

  determineFixType(value) {
    return value.isFixed ? FixedFieldType.Set : FixedFieldType.None;
  }

  componentDidMount() {
    const { value } = this.props;

    const fixType = this.determineFixType(value);

    this.setState({
      fixType,
    });
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      const fixType = this.determineFixType(value);

      this.setState({
        fixType,
      });
    }
  }

  enforceValueConstraints = (value, fixType) => {
    const {
      value: { setValue },
    } = this.props;

    return fixType === FixedFieldType.Set ? setValue : value;
  };

  /**
   * The onChange method for the `<SInput />` element of the FixedString
   */
  onValueChange = (e) => {
    this.props.onChange({
      ...this.props.value,
      value: e.target.value,
      userArea: this.props.currentUser.userArea,
    });
  };

  /**
   * The onChange method for the `<FixedStringEditor />` element of the FixedString
   */
  onValuePropsChange = (res) => {
    this.props.onChange({ ...this.props.value, ...res });
  };

  onFocus = ({ target }) => {
    this.setState(
      {
        showFormatted: false,
      },
      () => {
        if (this.props.selectOnFocus) {
          target.select();
        }
      }
    );
  };

  onBlur = (e) => {
    if (this.props.onBlur) this.props.onBlur(e);
  };

  defaultRenderReadOnly = (props) => <p>{props.value.value}</p>;

  renderReadOnly = () =>
    (this.props.renderReadOnly || this.defaultRenderReadOnly)(this.props);

  rightLabelContent = (isAdmin, fixType) => {
    const { textArea, value, displayPriority, disabled, currentUser } =
      this.props;

    const indicatorDisabled =
      disabled ||
      (value?.isFixed &&
        value?.userArea == UserArea.IpsAdmin &&
        value?.userArea != currentUser.userArea);

    return (
      <FixedInputIndicator
        isAdmin={isAdmin}
        fixType={fixType}
        fieldId={value.id || null}
        values={[value.value || ""]}
        onForcedValuesChanged={this.onValuePropsChange}
        fixableType={FixableTypes.String}
        customEditorData={{ textArea }}
        disabled={indicatorDisabled}
        displayPriority={displayPriority}
      />
    );
  };

  renderInput = () => {
    const { fixType } = this.state;
    const {
      textArea,
      hasPermission,
      passThruProps,
      value,
      fluid,
      hideIndicator,
    } = this.props;

    const isAdmin = hasPermission(Targets.SheetAdmin, Actions.Edit);
    const disabled = this.props.disabled || fixType == FixedFieldType.Set;
    const hiddenIndicator =
      hideIndicator || (!isAdmin && fixType === FixedFieldType.None);
    const hasRightLabel = !hiddenIndicator;
    const semanticProps = FormUtils.omitProps(
      passThruProps,
      Object.keys(FixedString.propTypes)
    );
    const As = textArea ? STextArea : SInput;

    return (
      <As
        {...semanticProps}
        labelPosition={hasRightLabel ? "right" : null}
        value={value !== null && value.value !== null ? value.value : ""}
        onChange={textArea ? this.onValueChange : null}
        disabled={disabled}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        className={classNames(
          "fix-input-wrapper fix-string",
          hiddenIndicator && "fix-input-hidden",
          hasRightLabel && "with-right-label",
          !hiddenIndicator && "right-label--indicator",
          fluid ? "fix-input-fluid" : "fix-input-not-fluid",
          isAdmin && "is-admin"
        )}
      >
        {!textArea && (
          <React.Fragment>
            {
              <input
                onChange={this.onValueChange}
                value={
                  value !== null && value.value !== null ? value.value : null
                }
              />
            }
            {hasRightLabel && this.rightLabelContent(isAdmin, fixType)}
          </React.Fragment>
        )}
      </As>
    );
  };

  render() {
    const {
      value,
      errors,
      showErrors,
      allErrors,
      animateErrors,
      readOnly,
      label,
      actions,
      inline,
      width,
      fluid,
      required,
      className,
      hasPermission,
      textArea,
      hideIndicator,
    } = this.props;

    const { fixType } = this.state;
    const disabled = this.props.disabled || fixType == FixedFieldType.Set;
    const isAdmin = hasPermission(Targets.SheetAdmin, Actions.Edit);

    const hiddenIndicator =
      hideIndicator || (!isAdmin && fixType === FixedFieldType.None);

    return (
      !!value && (
        <Field
          inline={inline}
          required={required}
          disabled={disabled}
          renderReadOnly={readOnly && this.renderReadOnly}
          width={width}
          fluid={fluid}
          label={label}
          actions={actions}
          errors={FormUtils.fieldErrors(errors, showErrors, allErrors)}
          animateErrors={animateErrors}
          className={classNames("fix-input-container", className)}
        >
          {textArea ? (
            <div className="text-area-wrapper">
              {this.renderInput()}
              {!hiddenIndicator && this.rightLabelContent(isAdmin, fixType)}
            </div>
          ) : (
            this.renderInput()
          )}
        </Field>
      )
    );
  }
}

export default connectForm({
  displayName: (props) =>
    props.label && typeof props.label === "string" ? props.label : "Input",
  validators: [],
})(compose(withPermissions, withCurrentUser)(FixedString));
