import { apiGet, apiRequest, apiPost } from "@redriver/cinnamon";
import FileSaver from "file-saver";

const NAMESPACE = "SETTINGS_UPLOAD";

const ActionTypes = {
  GetDataUploads: `${NAMESPACE}/GET_UPLOADS`,
  GetTemplate: `${NAMESPACE}/GET_TEMPLATE`,
  UploadData: `${NAMESPACE}/UPDATE_DATA`,
  GetSummary: `${NAMESPACE}/GET_SUMMARY`,
  ProcessData: `${NAMESPACE}/PROCESS_DATA`,
};

export const getDataUploads = ({ filters, pagination }, dealerOfficeId) =>
  apiGet(ActionTypes.GetDataUploads, "settings-upload", {
    ...pagination,
    ...filters,
    dealerOfficeId: dealerOfficeId,
  });

export const downloadTemplate = (formData, params) => async dispatch => {
  const result = await dispatch(
    apiGet(ActionTypes.GetTemplate, `settings-upload/download-template`, {
      ...formData,
      ...params,
    })
  );

  if (result.success) {
    FileSaver.saveAs(result.response, `${formData.type}.xlsx`);
  }
  return result;
};

export const uploadData = (formData, params) => {
  const data = new FormData();
  data.append("file", formData.file);
  data.append("type", params.type);
  data.append("dealerOfficeId", params.dealerOfficeId);

  return apiRequest({
    type: ActionTypes.UploadData,
    method: "POST",
    path: "settings-upload/upload-settings",
    contentType: null,
    body: data,
  });
};

export const processData = ({ id }) =>
  apiPost(ActionTypes.ProcessData, `settings-upload/${id}/process`);

export const getSummary = id =>
  apiGet(ActionTypes.GetSummary, `settings-upload/${id}/summary`);
