import { produce } from "immer";

export const fillLargeFormatCreate = (state, action) => {
  return produce(state, (draftState) => {
    const lf = draftState.sheetData.largeFormatCreateModal;
    if (action.lfModal.printerId) {
      (lf.groupIndex = action.largeFormatEditIndex),
        (lf.printerId = action.lfModal.printerId);
      lf.editMode = true;
      lf.lfPrinterConfig.targetMarket = action.printer.targetMarket;
      lf.lfPrinterConfig.paperWidth = action.printer.paperWidth;
      lf.lfPrinterConfig.paperWidthmm = action.printer.paperWidthmm;
      lf.lfPrinterConfig.paperWidthInches = action.printer.paperWidthInches;
      lf.lfPrinterConfig.colours = action.printer.colours;
      lf.lfPrinterConfig.scanner = action.printer.scanner;
      lf.lfPrinterConfig.costPerMl = action.printer.costPerMl;
      lf.lfPrinterConfig.costPerScan = action.printer.costPerScan;
      lf.lfPrinterConfig.costPerMlCleaning = action.printer.costPerMlCleaning;
      lf.lfPrinterConfig.text = action.printer.name;
      lf.lfPrinterConfig.includeCleaning = action.printer.cleaningMeter;
      lf.lfPrinterConfig.meteredServices = action.printer.meteredServices;
      lf.lfPrinterConfig.hasCleaningMeter = action.printer.hasCleaningMeter;
      lf.lfPrinterConfig.hasScannerMeter = action.printer.hasScannerMeter;
      lf.lfPrinterConfig.priceBVisible = action.printer.priceBVisible;
      lf.costPerUnit = action.lfModal.printerMeter.costPerUnit;
    }
    if (action.lfModal.inkInclusiveId) {
      lf.printerInkUsageId = action.lfModal.printerInkUsageId;
      lf.compPrinterInkUsageId = action.lfModal.compPrinterInkUsageId;
      lf.lfInkInclusive.inkUsedML = action.ink.inkUsedML;
      lf.lfInkInclusive.imageUrl = action.ink.imageUrl;
      lf.compLfInkInclusive.inkUsedML = action.compInk.inkUsedML;
      lf.compLfInkInclusive.imageUrl = action.compInk.imageUrl;
    }
    lf.includeScan = action.lfModal.scannerIncluded;
    lf.includeCleaning = action.lfModal.includeCleaning;
    lf.priceBVisible = action.lfModal.priceBVisible;
    lf.priceType = action.lfModal.priceType;

    const paperBaseId = Date.now();
    lf.lfRollMedia = (action.mediaItems || []).map((mi, mediaIndex) => {
      const media = mi.rollMedia;
      const width = mi.width;
      return {
        index: paperBaseId + 3 + mediaIndex,
        paperWidthId: width.id,
        _widthIdMetric: width.id,
        _widthIdImperial: width.id,
        paperInclusiveId: media.id,
        cost: media.cost,
        width: media.width,
        length: media.length,
        weight: media.weight,
        paperWidth: width.name,
        lfRollMedia: {
          value: media.id,
          text: media.name,
          length: media.length,
          weight: media.weight,
          cost: media.cost,
          width: media.width,
        },
      };
    });
  });
};
