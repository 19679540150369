import React from "react";
import { ModalBuilder } from "@redriver/cinnamon";
import { Targets, Actions } from "constants/permissions";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { UserArea } from "features/../../../shared/constants/enums";
import { createNote } from "../actions";
import Add from "features/../../../shared/assets/blue-add.svg";
import { IconButton } from "features/../../../shared/components/buttons";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";
import NoteForm from "./NoteForm";

const AddNote = ({ sheetId, onSubmitted, userArea, disabled }) => {
  const { t } = useTranslation("sheetHeader");

  return (
    <PermissionCheck target={Targets.Sheet} action={Actions.Edit}>
      <ModalBuilder
        withForm
        initialData={{
          userArea: userArea == UserArea.AccountManager ? userArea : null,
        }}
        submitAction={createNote}
        submitParams={sheetId}
        onSubmitted={onSubmitted}
        renderTrigger={(showModal) => (
          <IconButton
            icon={Add}
            content={t("Add_Note")}
            onClick={showModal}
            disabled={disabled}
          />
        )}
        renderModal={(modalProps, formProps) => (
          <TranslatedModal.Add
            {...modalProps}
            header={t("Add_Note")}
            size="tiny"
          >
            <NoteForm userArea={userArea} formProps={formProps} />
          </TranslatedModal.Add>
        )}
      />
    </PermissionCheck>
  );
};

export default AddNote;
