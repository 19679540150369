import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getAuthState } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { requestLogout } from "./actions";

const Logout = ({ loggedIn, requestLogout }) => {
  const { t } = useTranslation("system");
  const [logoutStatus, setLogoutStatus] = useState(null);

  useEffect(() => {
    const logout = async () => {
      const logoutResult = await requestLogout();
      setLogoutStatus(logoutResult);
    };
    logout();
  }, [logoutStatus, requestLogout]);

  if (!loggedIn) {
    return <p>{t("You_have_been_successfully_signed_out")}</p>;
  }

  switch (logoutStatus) {
    case false:
      return (
        <div>
          <p>{t("Unable_to_sign_out_at_this_time")}</p>
          <div style={{ marginTop: 20 }}>
            <Button primary onClick={() => setLogoutStatus(null)}>
              {t("Try_Again")}
            </Button>
          </div>
        </div>
      );

    default:
      return <p>{t("Signing_out")}...</p>;
  }
};

const mapStateToProps = (state) => ({
  loggedIn: getAuthState(state).loggedIn,
});

export default connect(mapStateToProps, { requestLogout })(Logout);
