import { Modal, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { Icon } from "semantic-ui-react";
import { markAsRead } from "./actions";
import { useTranslation } from "react-i18next";

const NotificationModal = ({ props, onRefresh }) => {
  const { t } = useTranslation("shared");

  return (
    <ModalBuilder
      submitAction={!props.isRead && markAsRead}
      submitParams={{ id: props.id }}
      onSubmitted={onRefresh}
      renderTrigger={(showModal) => (
        <Icon
          onClick={() => {
            showModal();
          }}
          name="eye"
        />
      )}
      renderModal={(modalProps) => (
        <Modal.Confirmation
          {...modalProps}
          header={props.level}
          cancelDisabled={true}
          cancelLabel=""
          submitLabel="Ok"
        >
          <div>{props.source}</div>
          <div>{t("notificationsReasons:" + props.body)}</div>
          <div>{props.link}</div>
        </Modal.Confirmation>
      )}
    />
  );
};

export default NotificationModal;
