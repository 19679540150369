import { isFieldFixed, isRealNumber } from "features/Sheets/ManageSheet";

export function correctDeviceOnScheduleValues(newSheetData) {
  const field = newSheetData.capital.totalDevicesOnSchedule;
  const defaultValue =
    newSheetData.defaults.defaultAndMinimumTotalDevicesOnScheduleValue;

  if (isFieldFixed(field)) return;
  if (
    !isRealNumber(field.value) ||
    parseInt(field.value) < parseInt(defaultValue)
  ) {
    field.value = defaultValue;
  }
}
