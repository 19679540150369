import React from "react";
import { Icon } from "semantic-ui-react";
import { ListBuilder, Table, ActionBar } from "@redriver/cinnamon";
import { LoadMoreButton } from "features/../../../shared/components/buttons";
import { getUsers } from "./actions";
import FilterUsers from "./FilterUsers";
import InviteUsers from "./InviteUsers";
import UserActions from "./UserActions";
import { useTranslation } from "react-i18next";

const ListUsers = () => {
  const { t } = useTranslation("sheetTranslation");

  return (
    <ListBuilder
      withTable
      loadAction={getUsers}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      renderList={(tableProps, state, events) => (
        <React.Fragment>
          <ActionBar>
            <ActionBar.Item>
              <FilterUsers
                value={state.filters}
                onChange={events.onChangeFilters}
              />
            </ActionBar.Item>
            <ActionBar.Item fluid align="right">
              <InviteUsers
                primary
                icon="plus"
                content={t("ListUsers.InviteUsers")}
                onInvited={events.onRefresh}
              />
            </ActionBar.Item>
          </ActionBar>
          <Table
            {...tableProps}
            dataKey="id"
            emptyMessage={t("ListUsers.NoUsersFound")}
            selectable
          >
            <Table.Column
              width="5%"
              render={(user) => (
                <Icon
                  circular
                  inverted
                  color="grey"
                  name="user"
                  size="small"
                  disabled={user.isDisabled || !user.isVerified}
                />
              )}
            />
            <Table.Column
              field="name"
              title={t("ListUsers.Name")}
              width="22%"
            />
            <Table.Column
              field="email"
              title={t("ListUsers.Email")}
              width="38%"
            />
            <Table.Column
              field="role"
              title={t("ListUsers.Role")}
              width="15%"
            />
            <Table.Column
              title={t("ListUsers.Status")}
              width="15%"
              render={(user) =>
                user.isDisabled
                  ? "Disabled"
                  : user.isVerified
                  ? "Active"
                  : "Unverified"
              }
            />
            <Table.Column
              hoverable
              width="5%"
              align="right"
              render={(user) => <UserActions user={user} />}
            />
          </Table>
          <LoadMoreButton
            hasMore={state.hasMore}
            loadingMore={state.loadingMore}
            onLoadMore={events.onLoadMore}
          />
        </React.Fragment>
      )}
    />
  );
};

export default ListUsers;
