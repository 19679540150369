import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { ListClients } from "features/Clients";

const ClientsListPage = () => (
  <PageContent>
    <ListClients />
  </PageContent>
);

export default ClientsListPage;
