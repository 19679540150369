import { getSheetState } from "features/Sheets/selectors";
import React from "react";
import { Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { Format } from "@redriver/cinnamon";
import { InlineLoader } from "features/../../../shared/components/forms/InlineLoader";
import { UserTypeCheck } from "features/../../../shared/components/auth";
import {
  UserType,
  SheetViewType,
} from "features/../../../shared/constants/enums";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const InkUsageCalcGrid = ({
  inkUsageCalcIncludingCapital,
  inkUsageCalcExcludingCapital,
  calculating,
  sheetViewType,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        "ink-calc-container",
        sheetViewType != SheetViewType.IpsAdmin && "extra-margin"
      )}
    >
      <div className="title-container">
        <label>
          {t(
            "systemSchedule:largeFormatModal:inkInclusive:fields:conversionForCustomer:label"
          )}
        </label>
      </div>

      <div className="grid-container">
        {GenericGrid(
          t(
            "systemSchedule:largeFormatModal:inkInclusive:fields:tmvsaCalculationIncl:label"
          ),
          inkUsageCalcIncludingCapital,
          calculating
        )}
        <UserTypeCheck restrictTo={UserType.HQ}>
          {sheetViewType == SheetViewType.IpsAdmin &&
            GenericGrid(
              t(
                "systemSchedule:largeFormatModal:inkInclusive:fields:excludingCapital:label"
              ),
              inkUsageCalcExcludingCapital,
              calculating
            )}
        </UserTypeCheck>
      </div>
    </div>
  );
};

const GenericGrid = (title, data, calculating) => {
  const { t } = useTranslation();
  return (
    <Grid className="ink-calc-grid">
      <label className="title">{title}</label>
      {GridRow(
        "",
        t("systemSchedule:largeFormatModal:inkInclusive:fields:A0:label"),
        t("systemSchedule:largeFormatModal:inkInclusive:fields:A1:label")
      )}

      {GridRow(
        t(
          "systemSchedule:largeFormatModal:inkInclusive:fields:inkAndMaintenance:label"
        ),
        calculating ? (
          <InlineLoader isActive />
        ) : (
          <Format.Number
            format={process.env.CURRENCY_CODE}
            value={data.inkAndMaintenance[0]}
          />
        ),
        calculating ? (
          <InlineLoader isActive />
        ) : (
          <Format.Number
            format={process.env.CURRENCY_CODE}
            value={data.inkAndMaintenance[1]}
          />
        )
      )}
      {GridRow(
        t(
          "systemSchedule:largeFormatModal:inkInclusive:fields:costPerUnit2:label"
        ),
        calculating ? (
          <InlineLoader isActive />
        ) : (
          <Format.Number
            format={process.env.CURRENCY_CODE}
            value={data.costPerUnit2[0]}
          />
        ),
        calculating ? (
          <InlineLoader isActive />
        ) : (
          <Format.Number
            format={process.env.CURRENCY_CODE}
            value={data.costPerUnit2[1]}
          />
        )
      )}
      {GridRow(
        t(
          "systemSchedule:largeFormatModal:inkInclusive:fields:totalCostPerSheetSize:label"
        ),
        calculating ? (
          <InlineLoader isActive />
        ) : (
          <Format.Number
            format={process.env.CURRENCY_CODE}
            value={data.total[0]}
          />
        ),
        calculating ? (
          <InlineLoader isActive />
        ) : (
          <Format.Number
            format={process.env.CURRENCY_CODE}
            value={data.total[1]}
          />
        )
      )}
    </Grid>
  );
};

const GridRow = (value1, value2, value3) => (
  <React.Fragment>
    <Grid.Column width={10}>{value1}</Grid.Column>
    <Grid.Column width={3}>{value2}</Grid.Column>
    <Grid.Column width={3}>{value3}</Grid.Column>
  </React.Fragment>
);

const mapStateToProps = (state) => {
  const { calculating, sheetViewType } = getSheetState(state);

  return {
    calculating,
    sheetViewType,
  };
};

export default connect(mapStateToProps)(InkUsageCalcGrid);
