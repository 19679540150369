import React from "react";
import { Form, ModalBuilder, Table } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "../../../../../../shared/components/modals";
import { setReplacingSchedule } from "./actions";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";

const SetReplacingSchedule = ({ sheet, onSubmitted }) => {
  const { t } = useTranslation("agreements");
  const { sheetId, replacingScheduleReference } = sheet;

  return (
    <PermissionCheck
      target={Targets.Sheet}
      action={Actions.UpdateSheetScheduleReference}
    >
      <ModalBuilder
        withForm
        initialData={{ replacingScheduleReference }}
        submitParams={{ sheetId }}
        submitAction={setReplacingSchedule}
        onSubmitted={onSubmitted}
        renderTrigger={(onTrigger) => (
          <Table.MenuItem onClick={onTrigger}>
            {t("SheetActions.SetReplacingSchedule.TriggerText")}
          </Table.MenuItem>
        )}
        renderModal={(modalProps, formProps) => (
          <TranslatedModal.Edit
            {...modalProps}
            header={t("SheetActions.SetReplacingSchedule.ModalHeader")}
            submitText={t("SheetActions.SetReplacingSchedule.ModalSubmit")}
          >
            <Form {...formProps}>
              <Form.Input field="replacingScheduleReference" maxLength={20} />
            </Form>
          </TranslatedModal.Edit>
        )}
      />
    </PermissionCheck>
  );
};

export default SetReplacingSchedule;
