import { LfpMeterType } from "features/../../../shared/constants/enums";

export const setLargeFormatCreateModalData = (oldSheetData, newSheetData) => {
  const newLfpModalData = newSheetData.largeFormatCreateModal;
  const lfPrinterConfig = newLfpModalData.lfPrinterConfig;
  const scanMeter = lfPrinterConfig?.meteredServices?.find(
    (x) => x.meterType == LfpMeterType.Scan
  );

  const cleaningMeter = lfPrinterConfig?.meteredServices?.find(
    (x) => x.meterType == LfpMeterType.Cleaning
  );

  if (
    oldSheetData.largeFormatCreateModal.printerId != newLfpModalData.printerId
  ) {
    newLfpModalData.printerChanged = true;
    newLfpModalData.costPerUnit = null;
    newLfpModalData.includeScan =
      scanMeter != undefined ? lfPrinterConfig.scanner : false;
    newLfpModalData.includeCleaning =
      cleaningMeter != undefined ? lfPrinterConfig.includeCleaning : false;
    newLfpModalData.priceBVisible = lfPrinterConfig.priceBVisible;
  }
};
