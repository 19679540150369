import { produce } from "immer";

export const updateProposedVolumes = (action, state) => {
  if (!action.formData) return state;

  const volume = (meter) => {
    if (!meter) return null;
    const { id, predictedVolume } = meter;
    return { id, predictedVolume };
  };

  const idsToPredictedVolume =
    action.formData.preSnapshotSystemScheduleItems.map(volume);

  const lfIdsToPredictedVolume =
    action.formData.preSnapshotLargeFormatGroups.map(
      ({ id, printerMeter, scanMeter, otherMeter, paperMeters }) => ({
        id,
        printerMeter: volume(printerMeter),
        scanMeter: volume(scanMeter),
        otherMeter: volume(otherMeter),
        paperMeters: paperMeters.map(volume),
      }),
    );

  // Update both old and new array items
  return produce(state, (draftState) => {
    const updateScheduleItem = (data, item) => {
      const match = data.find((x) => x.id === item.id);
      if (!match) return;

      item.predictedVolume = match.predictedVolume;
    };

    const updateLfGroup = (data, group) => {
      const match = data.find((x) => x.id === group.id);
      if (!match) return;

      if (group.printerMeter && match.printerMeter) {
        group.printerMeter.predictedVolume = match.printerMeter.predictedVolume;
      }
      if (group.scanMeter && match.scanMeter) {
        group.scanMeter.predictedVolume = match.scanMeter.predictedVolume;
      }
      if (group.otherMeter && match.otherMeter) {
        group.otherMeter.predictedVolume = match.otherMeter.predictedVolume;
      }
      group.paperMeters?.forEach((pm) => {
        const matchedPaper = match.paperMeters.find((x) => x.id == pm.id);
        if (matchedPaper) {
          pm.predictedVolume = matchedPaper.predictedVolume;
        }
      });
    };

    draftState.sheetData.preSnapshotSystemScheduleItems.forEach((item) =>
      updateScheduleItem(idsToPredictedVolume, item),
    );
    draftState.sheetData.systemScheduleItems.forEach((item) =>
      updateScheduleItem(idsToPredictedVolume, item),
    );
    draftState.sheetData.preSnapshotLargeFormatGroups.forEach((group) =>
      updateLfGroup(lfIdsToPredictedVolume, group),
    );
    draftState.sheetData.largeFormatGroups.forEach((group) =>
      updateLfGroup(lfIdsToPredictedVolume, group),
    );
  });
};
