import React from "react";
import { SidePanel, Form, FormBuilder } from "@redriver/cinnamon";
import { Grid } from "semantic-ui-react";
import Filter from "features/../../../shared/assets/white-filter.svg";
import { IconButton } from "features/../../../shared/components/buttons";
import { UserTypeCheck } from "features/../../../shared/components/auth";
import { UserType } from "features/../../../shared/constants/enums";
import { DealerOfficesTypeAheadLookup } from "features/../../../shared/features/Settings";
import { useTranslation } from "react-i18next";

const ClientFilters = ({ title, value, onChange }) => {
  const { t } = useTranslation("clients");
  return (
    <SidePanel
      closeIcon
      header={title}
      trigger={
        <IconButton
          icon={Filter}
          primary
          content={t("shared:buttons:Filters")}
        />
      }
    >
      <FormBuilder
        value={value}
        onChange={onChange}
        renderForm={formProps => (
          <div className="ui form highlighted-inputs">
            <Form {...formProps}>
              <Grid columns={2}>
                <Grid.Column>
                  <Form.Input
                    field="businessType"
                    label={t(
                      "clients:tables:clients:filters:headings:businessType"
                    )}
                    fluid
                    clearable
                    placeholder={t(
                      "clients:tables:clients:filters:placeholders:businessType"
                    )}
                  />
                </Grid.Column>
                <UserTypeCheck restrictTo={UserType.HQ}>
                  <Grid.Column>
                    <DealerOfficesTypeAheadLookup
                      field="dealerId"
                      label={t(
                        "clients:tables:clients:filters:headings:dealer"
                      )}
                      fluid
                      clearable
                      placeholder={t(
                        "clients:tables:clients:filters:placeholders:dealer"
                      )}
                    />
                  </Grid.Column>
                </UserTypeCheck>
              </Grid>
            </Form>
          </div>
        )}
      />
    </SidePanel>
  );
};

export default ClientFilters;
