import { registerLookup, apiGet } from "@redriver/cinnamon";

const NAMESPACE = "FORMS/LOOKUPS";

const ActionTypes = {
    GetMeteredServices: `${NAMESPACE}/GET_METERED_SERVICES`,
    GetClients: `${NAMESPACE}/GET_CLIENTS`,
    GetSettingsUploadEntityType: `${NAMESPACE}/GET_SETTINGS_UPLOAD_ENTITY_TYPE`,
    GetPapers: `${NAMESPACE}/GET_PAPERS`,
};

export const getMeteredServices = (params) =>
    apiGet(ActionTypes.GetMeteredServices, `lookups/metered-services`, params);

export const getClients = () =>
  apiGet(ActionTypes.GetClients, `lookups/clients`);

export const getSettingsUploadEntityType = (params) =>
  apiGet(ActionTypes.GetSettingsUploadEntityType, `lookups/settings-upload-entity-type`, params);

export const getPapers = (params) =>
  apiGet(ActionTypes.GetPapers, `lookups/paper`, params);