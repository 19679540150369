import { checkMaxMin, checkMax, isFieldFixed } from "../../helpers";
import { UserType as UserTypes } from "features/../../../shared/constants/enums";

export function correctVolumeSelectionRelatedValues(
  newSheetData,
  funderType,
  funderSelected,
  state,
  userType
) {
  //if funder type is hidden return
  if (!funderSelected.value) return;

  const sheetViewType = state.sheetViewType;

  let volSelection = newSheetData.capital.volumeSelection.value;
  let financeSelection = funderType.financeSelection.value;

  if (
    financeSelection > volSelection &&
    !isFieldFixed(newSheetData.capital.volumeSelection)
  ) {
    newSheetData.capital.volumeSelection.value = financeSelection;
    volSelection = financeSelection;
  }

  //get funder type settings
  const funderTypeSettings = state.sheetData.defaults.funderTypeSettings;
  const funderSettings = funderTypeSettings.find(
    (obj) => obj.type == funderType.funderType
  );
  const vMinSelection = funderSettings.vMinSelection;
  const vMaxSelection =
    sheetViewType == "IpsAdmin"
      ? funderSettings.vMaxSelection
      : funderSettings.maxSelection;

  // If "Finance Selection" value is greater than "Volume Selection", set to highest of "Volume Selection" and it's Maximum, unless it is fixed
  const maxFinanceSelection = parseFloat(
    newSheetData.defaults.maximumFinanceSelectionValue
  );
  const minFinanceSelection = parseFloat(
    newSheetData.defaults.minimumFinanceSelectionValue
  );
  const selectedVol = parseFloat(newSheetData.capital.volumeSelection.value);
  if (!isFieldFixed(funderType.financeSelection)) {
    if (financeSelection > selectedVol) financeSelection = selectedVol;
    financeSelection = checkMaxMin(
      financeSelection,
      maxFinanceSelection,
      minFinanceSelection
    );

    //check against funder settings.
    financeSelection = checkMaxMin(
      financeSelection,
      vMaxSelection,
      vMinSelection
    );

    if (financeSelection !== funderType.financeSelection.value) {
      funderType.financeSelection.value =
        Math.floor(financeSelection).toString();
    }

    if (financeSelection > selectedVol) {
      newSheetData.capital.volumeSelection.value = financeSelection;
    }
  }

  // Prevent Volume Selection being greater than CoTerminus Min Qtr
  reduceVolumeSelectionAndCheckDefaults(
    newSheetData,
    funderType,
    userType,
    vMaxSelection
  );
}

function reduceVolumeSelectionAndCheckDefaults(
  newSheetData,
  funderType,
  userType,
  vMaxSelection
) {
  const adminUserType = userType === UserTypes.HQ;
  //get defaults
  const maxVol = adminUserType
    ? parseFloat(newSheetData.defaults.volumeSelection_Maximum)
    : parseFloat(newSheetData.defaults.maximumVolumeSelectionValue);
  const minVol = adminUserType
    ? parseFloat(newSheetData.defaults.volumeSelection_Minimum)
    : parseFloat(newSheetData.defaults.minimumVolumeSelectionValue);

  const volumeSelection = newSheetData.capital.volumeSelection;

  //get volume value and setValue
  const selectedVol = parseFloat(volumeSelection.value);

  //get volume value and setValue
  const selectedFinance = parseFloat(funderType.financeSelection.value);
  const volSetValue = volumeSelection.setValue;
  //check volume max min against defaults

  if (!isFieldFixed(volumeSelection)) {
    volumeSelection.value = checkMaxMin(selectedVol, maxVol, minVol);
  }

  if (!isFieldFixed(funderType.financeSelection)) {
    funderType.financeSelection.value = checkMax(selectedFinance, maxVol);
  }

  //loop through co Terminus
  funderType.coTerminusRentals
    .concat(funderType.ipCoTerminusRentals)
    .forEach((rental) => {
      const minQuarters = rental.minimumQuarters;
      //value check to prevent NAN error in field
      if (minQuarters.value) {
        //check max min defaults against co Terminus min Qtrs
        const selectedQVal = checkMax(
          parseFloat(rental.minimumQuarters.value),
          vMaxSelection
        );
        if (!isFieldFixed(minQuarters)) {
          minQuarters.value = selectedQVal;
        }
        //check against volume selection and adjust volume if necessary (co Terminus min qtr should be the anchor to volume).
        if (
          selectedQVal > selectedVol &&
          volSetValue == null &&
          !isFieldFixed(volumeSelection)
        ) {
          volumeSelection.value = selectedQVal;
        }
      }
    });
}
