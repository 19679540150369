import React from "react";
import { Form } from "@redriver/cinnamon";
import { getSettingsUploadEntityType } from "./actions";

const UploadEntityTypeDropdown = ({ dealerOfficeId, isLargeFormat, ...props }) => {
    return (<Form.Dropdown
        className="data-upload-templates"
        {...props}
        lookupAction={getSettingsUploadEntityType}
        lookupOptions={{
            transform: data =>  data.map(i => ({ value: i.key, text: i.value }))
        }}
    />);
}

export default UploadEntityTypeDropdown;