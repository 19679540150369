import React from "react";
import { Form, ModalBuilder, Table, notifySuccess } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "../../../../../../../shared/components/modals";
import { setPhysicalDocumentSheetState } from "./actions";
import {
  LookupNames as SheetLookupNames,
  ActionTypes as SheetActionTypes,
} from "../../../../Sheets/lookups";
import { useDispatch } from "react-redux";

const SetPhysicalDocumentSheetState = ({ sheet, onSubmitted }) => {
  const { t } = useTranslation("agreements");
  const dispatch = useDispatch();
  const { sheetId, physicalDocumentSheetState } = sheet;

  return (
    <ModalBuilder
      withForm
      initialData={{ physicalDocumentSheetState }}
      submitParams={{ sheetId }}
      submitAction={setPhysicalDocumentSheetState}
      onSubmitted={() => {
        dispatch(notifySuccess("Sheet status changed successfully"));
        onSubmitted();
      }}
      renderTrigger={(onTrigger) => (
        <Table.MenuItem onClick={onTrigger}>
          {t("SheetActions.SetStatus.TriggerText")}
        </Table.MenuItem>
      )}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Edit
          {...modalProps}
          header={t("SheetActions.SetStatus.ModalHeader")}
          submitText={t("SheetActions.SetStatus.ModalSubmit")}
        >
          <Form {...formProps}>
            <Form.Dropdown
              field="state"
              lookup={
                SheetLookupNames[SheetActionTypes.PhysicalSheetStatePrincipal]
              }
            />
          </Form>
        </TranslatedModal.Edit>
      )}
    />
  );
};

export default SetPhysicalDocumentSheetState;
