import React from "react";
import { useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import classNames from "classnames";
import { Format, Loadable } from "@redriver/cinnamon";
import { getSheetState, getSheetDataState } from "../../selectors";
import {
  PaperInclusiveChargeType,
  PaperInclusiveChargeTypeText,
} from "../../../../../../shared/constants/enums/PaperInclusiveChargeType";
import { useTranslation } from "react-i18next";

const ProposedVolumes = () => {
  const { t } = useTranslation();
  const sheetDataState = useSelector(getSheetDataState);
  const sheetState = useSelector(getSheetState);
  const paperInclusiveChargeType = sheetDataState
    ? sheetDataState.paperInclusive
      ? sheetDataState.paperInclusive.paperInclusiveChargeType
        ? sheetDataState.paperInclusive.paperInclusiveChargeType.value
        : false
      : false
    : false;
  const calculating = sheetState.calculating;
  const comparisonData = sheetDataState.comparisonData;
  const paperInclusiveIncluded =
    sheetDataState.paperInclusive.isPaperInclusive.value;

  const { priceDifference, items, total } = comparisonData;

  return (
    <Loadable loading={calculating}>
      <div className="table-container proposed-volumes">
        <Grid className="table section-header">
          <Grid.Row className="table-title-row">
            <Grid.Column width={13} className="table-title">
              {t("comparisonSheet:Reviewed_Volumes")}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="table-header">
            {/* ITEM ID*/}
            <Grid.Column width={2}>{t("comparisonSheet:Item_ID")}</Grid.Column>
            {/* ITEM DESCRIPTION*/}
            <Grid.Column width={3}>
              {t("comparisonSheet:Item_Description")}
            </Grid.Column>
            {/* AGREED TMVSA MINIMUMS*/}
            <Grid.Column width={3}>
              {t("comparisonSheet:Suggested_Quarter_TMVSA_Minimum")}
            </Grid.Column>
            {/* ACTUAL / PREDICTED VOLUME*/}
            <Grid.Column width={3}>
              {t("comparisonSheet:Additional_Over_TMVSA_Minimum")}
            </Grid.Column>
            {/* ADJUSTED CPU*/}
            <Grid.Column width={3}>
              {t("comparisonSheet:Adjusted_CPU")}
            </Grid.Column>
            {/* TOTAL*/}
            <Grid.Column width={2}>{t("comparisonSheet:Total")}</Grid.Column>
          </Grid.Row>

          {items.map((i) => {
            const {
              id,
              minQuarterly,
              costPerUnit,
              additional,
              displayCode,
              itemDescription,
            } = i;

            return (
              <Grid.Row className="body-row" key={id}>
                {/* ITEM ID*/}
                <Grid.Column width={2} className="bold centered">
                  <div className="unit-label-container">{displayCode}</div>
                </Grid.Column>
                {/* ITEM DESCRIPTION*/}
                <Grid.Column width={3} className="centered">
                  <div className="unit-label-container">{itemDescription}</div>
                </Grid.Column>
                {/* SUGGESTED TMVSA MINIMUMS*/}
                <Grid.Column width={3} className="bold centered">
                  <Format.Number
                    value={minQuarterly}
                    thousandSeparator
                    className={classNames(minQuarterly < 0 && "negative")}
                  />
                </Grid.Column>

                {/* ADDITIONAL OVER TMVSA MINIMUM */}
                <Grid.Column width={3} className="numeric-right-aligned">
                  <Format.Number
                    value={additional > 0 ? additional : 0}
                    thousandSeparator
                  />
                </Grid.Column>

                {/* ADJUSTED CPU*/}
                <Grid.Column width={3} className="bold numeric-right-aligned">
                  <span>
                    <Format.Number
                      value={costPerUnit}
                      thousandSeparator
                      decimalPlaces={{
                        min: 0,
                        max: process.env.USE_MINOR_CURRENCY ? 3 : 5,
                      }}
                      className={classNames(costPerUnit < 0 && "negative")}
                      suffix={
                        process.env.USE_MINOR_CURRENCY &&
                        process.env.MINOR_CURRENCY
                      }
                      prefix={
                        !process.env.USE_MINOR_CURRENCY &&
                        process.env.MAJOR_CURRENCY
                      }
                    />
                  </span>
                </Grid.Column>

                {/* TOTAL*/}
                <Grid.Column className="comparison-total-field" width={2}>
                  <Format.Number
                    thousandSeparator
                    format={process.env.CURRENCY_CODE}
                    value={i.total}
                    className={classNames(
                      "calculable-field",
                      calculating && "value-loading",
                      i.total < 0 && "negative"
                    )}
                  />
                </Grid.Column>
              </Grid.Row>
            );
          })}
          <div className="comparison-totals">
            <div className="totaliser">
              <span>
                {t("comparisonSheet:Estimated_Total_Quarter_Payment")}
              </span>
              <div className="comparison-total-value">
                <span className="totaliser-value">
                  <Format.Number
                    format={process.env.CURRENCY_CODE}
                    value={total}
                    decimalPlaces={2}
                    className={classNames(total < 0 && "negative")}
                  />
                </span>
              </div>
            </div>

            {paperInclusiveIncluded && (
              <div className="totaliser">
                <span>{t("comparisonSheet:Paper_Included")}</span>
                <div className="comparison-total-value">
                  <span className="totaliser-value">
                    {paperInclusiveChargeType == PaperInclusiveChargeType.A4A3
                      ? PaperInclusiveChargeTypeText(t).A4A3
                      : PaperInclusiveChargeTypeText(t).A4}
                  </span>
                </div>
              </div>
            )}
            <div className="totaliser saving">
              <span className={classNames(priceDifference < 0 && "negative")}>
                {priceDifference < 0 ? t("Saving") : t("Investing")}
              </span>
              <div className="comparison-total-value">
                <span
                  className={classNames(
                    "totaliser-value",
                    priceDifference < 0 && "negative"
                  )}
                >
                  <Format.Number
                    format={process.env.CURRENCY_CODE}
                    value={priceDifference}
                    decimalPlaces={2}
                  />
                </span>
              </div>
            </div>
          </div>
        </Grid>
      </div>
    </Loadable>
  );
};

export default ProposedVolumes;
