import React from "react";
import { useTranslation } from "react-i18next";

export const SettingsUploadDataErrorMessages = ({ message, code, data }) => {
  const { t } = useTranslation("dataUploads", { keyPrefix: "errors" });

  return (
    <React.Fragment>
      {message}
      <ul>
        {data.map(item => (
          <li>
            <b>
              {t("line")} {item.line}:
            </b>{" "}
            {item.errorCode}
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
};

export default SettingsUploadDataErrorMessages;
