import { apiGet, apiPost, apiPut, apiDelete } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/ROLL_WIDTH";

const ActionTypes = {
  AddRollWidth: `${NAMESPACE}/ADD_ROLL_WIDTH`,
};

export const addRollWidth = (data, { dealerOfficeId }) => {
  return apiPost(ActionTypes.AddRollWidth, `roll-width`, {
    ...data,
    dealerOfficeId,
  });
};
