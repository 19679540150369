import React from "react";
import { useTranslation } from "react-i18next";
import { Table, Form, ModalBuilder } from "@redriver/cinnamon";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { amendExpiryDate } from "../actions";

const AmendExpiryDate = ({ sheet, onSubmitted }) => {
  const { t } = useTranslation("agreements");
  const { sheetId, expiryDate } = sheet;

  return (
    <ModalBuilder
      withForm
      initialData={{ expiryDate }}
      submitAction={amendExpiryDate}
      submitParams={{ sheetId }}
      onSubmitted={onSubmitted}
      renderError={false}
      renderSubmitting={false}
      renderTrigger={(trigger) => (
        <Table.MenuItem onClick={trigger}>
          {t("SheetActions.AmendExpiry.TriggerText")}
        </Table.MenuItem>
      )}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Edit
          {...modalProps}
          header={t("SheetActions.AmendExpiry.HeaderText")}
        >
          <Form {...formProps}>
            <div className="flex">
              <Form.Date
                local
                field="expiryDate"
                label={t("SheetActions.AmendExpiry.ExpiryDate_Label")}
                width={25}
                dateFormat="DD MMM yyyy"
              />
            </div>
          </Form>
        </TranslatedModal.Edit>
      )}
    />
  );
};

export default AmendExpiryDate;
