import React from "react";
import { Icon, Transition } from "semantic-ui-react";
import { connect, useSelector } from "react-redux";

import { Form } from "@redriver/cinnamon";
import { SheetStep, cannotCalcMessages } from "features/Sheets/ManageSheet";
import { getSheetState } from "features/Sheets";
import {
  withCurrentUser,
  withPermissions,
} from "features/../../../shared/components/auth";
import { useTranslation } from "react-i18next";
import CrystallisedSchedule from "./CrystallisedSchedule";
import CurrentSchedule from "./CurrentSchedule";
import AdjustmentSelection from "./AdjustmentSelection";
import { compose } from "redux";
import { Actions, Targets } from "constants/permissions";

const ScheduleStabilityStep = ({
  sheetId,
  onNextStep,
  onPreviousStep,
  calculating,
  displayPrevious,
  displayNext,
  displaySave,
  collapsed,
  toggleVisibility,
  reactRef,
  customerToggle,
  displayCustomerToggle = false,
  sheetViewType,
  currentUser,
  hasPermission,
}) => {
  const { t } = useTranslation("systemScheduleStability");

  // Do not display the section if we don't have Schedule Stability calculation results yet
  const data = useSelector(
    (state) => state.sheets?.rawCalculation?.scheduleStability,
  );
  if (!data) return null;

  if (!hasPermission(Targets.Sheet, Actions.ViewScheduleStability)) {
    return null;
  }

  const { isSupplierAdminUser, isInternalUser } = currentUser;

  const isAdminUser = isInternalUser || isSupplierAdminUser;

  return (
    <div ref={reactRef} className="step-container">
      <SheetStep
        onNextStep={onNextStep}
        onPreviousStep={onPreviousStep}
        displayPrevious={displayPrevious}
        displayNext={displayNext}
        displaySave={displaySave}
        calculating={calculating}
        displayCustomerToggle={displayCustomerToggle}
        customerToggle={customerToggle}
        sheetId={sheetId}
        className="schedule-stability-step"
        errorMessage={cannotCalcMessages.schedules}
      >
        <Form.Object field="scheduleStability" propagateUpdates="always">
          <div className="schedule-step">
            <div className="schedule-step-header">
              <div className="sheet-step-header" onClick={toggleVisibility}>
                <h5 className="title">{t("title")}</h5>
                {toggleVisibility && (
                  <Icon
                    name="dropdown"
                    rotated={collapsed ? "counterclockwise" : null}
                  />
                )}
              </div>
            </div>
            <Transition
              animation="fade down"
              visible={!collapsed}
              duration={500}
            >
              <div>
                <div className="grid">
                  <div className="green">
                    <AdjustmentSelection
                      isAdminUser={isAdminUser}
                      sheetViewType={sheetViewType}
                    />
                  </div>
                  <div className="pink">
                    <CrystallisedSchedule isAdminUser={isAdminUser} />
                  </div>
                  <div className="pink">
                    <CurrentSchedule isAdminUser={isAdminUser} />
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </Form.Object>
      </SheetStep>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { sheetViewType } = getSheetState(state);
  return {
    sheetViewType,
  };
};

export default compose(
  withCurrentUser,
  connect(mapStateToProps),
)(withPermissions(ScheduleStabilityStep));
