import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { debounce } from "@redriver/cinnamon";

function getHeaderHeight() {
  const headerEl = document.getElementsByClassName("main-menu");
  if (!headerEl.length) return 0;
  let headerHeight = headerEl[0].clientHeight;
  if (!headerHeight) {
    headerHeight = 0;
  }
  return headerHeight;
}

/*
  Component works by moving its child content down (via css transform/translation - see float-box.scss)
  On page scroll it will recalculate the offset.
*/
const FloatingScrollBox = ({ children, className, ...props }) => {
  const [verticalOffset, setVerticalOffset] = useState(0);
  const debouncedSetMargin = debounce(setVerticalOffset, 100);
  const [heightAdjust, setHeightAdjust] = useState(0);
  const debouncedSetHeightAdjust = debounce(setHeightAdjust, 100);

  useEffect(() => {
    function scrolling() {
      const headerHeight = getHeaderHeight();
      const scrollHeight = window.scrollY;
      const nextMargin =
        scrollHeight > headerHeight ? scrollHeight - headerHeight : 0;

      const adjust =
        scrollHeight >= headerHeight ? 0 : headerHeight - scrollHeight;
      debouncedSetHeightAdjust(adjust);
      debouncedSetMargin(nextMargin);
    }

    scrolling();
    window.addEventListener("scroll", scrolling);
    return () => window.removeEventListener("scroll", scrolling);
  }, [debouncedSetHeightAdjust, debouncedSetMargin]);

  return (
    <section
      className={classNames("floating-scroll-box", className)}
      {...props}
    >
      <div
        className="inner-scroll"
        style={{
          transform: `translateY(${verticalOffset}px)`,
          maxHeight: `calc(100vh - ${heightAdjust}px - 0.5em)`,
        }}
      >
        {children}
      </div>
    </section>
  );
};

export default FloatingScrollBox;
