import React from "react";
import { Grid } from "semantic-ui-react";
import classNames from "classnames";
import { Format, Form } from "@redriver/cinnamon";

const CurrentQuarterDetails = ({
  minQuarterly,
  costPerUnit,
  total,
  displayCode,
  unitDescription,
  arrayIndex,
  predictedVolumeEntryDisabled,
}) => {
  return (
    <Grid.Row key={arrayIndex} className="quarter-agreed-body-row">
      {/* ITEM ID*/}
      <Grid.Column width={2} className="bold centered">
        <div className="unit-label-container">{displayCode}</div>
      </Grid.Column>
      {/* ITEM DESCRIPTION*/}
      <Grid.Column className="centered" width={3}>
        {unitDescription}
      </Grid.Column>
      {/* AGREED TMVSA MINIMUMS*/}
      <Grid.Column width={3} className="bold centered">
        <Format.Number value={minQuarterly} thousandSeparator />
      </Grid.Column>
      {/* ACTUAL / PREDICTED VOLUME*/}
      <Grid.Column width={3} className="numeric-right-aligned">
        <Form.Numeric
          field="predictedVolume"
          inline
          width={7}
          decimalPlaces={0}
          onFocus={(e) => e.target.select()}
          readOnly={predictedVolumeEntryDisabled}
          renderReadOnly={(props, state) => (
            <Format.Number value={state.prev} thousandSeparator />
          )}
        />
      </Grid.Column>
      {/* CURRENT CPU*/}
      <Grid.Column width={3} className="bold numeric-right-aligned">
        <Format.Number
          value={costPerUnit}
          decimalPlaces={{
            min: 0,
            max: process.env.USE_MINOR_CURRENCY ? 3 : 5,
          }}
          className={classNames(costPerUnit < 0 && "negative")}
          suffix={process.env.USE_MINOR_CURRENCY && process.env.MINOR_CURRENCY}
          prefix={!process.env.USE_MINOR_CURRENCY && process.env.MAJOR_CURRENCY}
        />
      </Grid.Column>
      {/* TOTAL*/}
      <Grid.Column width={2}>
        <Format.Number
          thousandSeparator
          format={process.env.CURRENCY_CODE}
          value={total}
          className={classNames(total < 0 && "negative")}
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default CurrentQuarterDetails;
