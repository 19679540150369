import React from "react";
import PropTypes from "prop-types";
import { Dropdown as SDropdown } from "semantic-ui-react";
import {
  connectForm,
  FormUtils,
  FormValidators,
  LookupTypeAhead,
  Field,
} from "@redriver/cinnamon";

/**
 * Field for a type-ahead search field
 */
class SheetServiceTypeAhead extends React.Component {
  static propTypes = {
    // -------------------
    // field props
    // -------------------

    /**
     * Label text to display alongside the field
     */
    label: PropTypes.node,
    /**
     * Additional content to appear alongside the field for custom behaviour
     */
    actions: PropTypes.node,
    /**
     * Whether the label text should be displayed to the side of the field rather than above it
     */
    inline: PropTypes.bool,
    /**
     * Width of the field in approximate number of characters, or a valid CSS width
     */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * Whether the width of the field should automatically fill all available space
     */
    fluid: PropTypes.bool,
    /**
     * Custom render function to override the displayed content in read-only mode
     * @param {Object} `props` Current field props
     */
    renderReadOnly: PropTypes.func,
    /**
     * Overridden Semantic UI prop for auto selecting a value when focus leaves the dropdown, defaults to false
     */
    selectOnBlur: PropTypes.bool,
    /**
     * Overridden Semantic UI prop for indicating this is a form selection field, defaults to true
     */
    selection: PropTypes.bool,
    /**
     * Optional, name of a lookup that has already been registered to use for the typeAhead's search functionality
     */
    lookup: PropTypes.string,
    /**
     * Optional additional parameters to supply to the lookup's action
     */
    lookupParams: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    /**
     * Optional default options for the lookup request
     */
    lookupOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    /**
     * Callback when the typeAhead search is triggered
     * @param {string} `searchQuery` The value of the typeAhead input to search on
     */
    onSearch: PropTypes.func,
    /**
     * Optional additional options to use when the current value if not one of the options from the lookup.
     * These will enable unknown values to be displayed, but will remain hidden unless they are a valid lookup option.
     * (a typical case for this is when the initial value was not in the lookup response)
     * Generally it is easier and preferable to use the 'textField` prop instead as the unknown options will be automatically inferred.
     */
    unknownValueOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    /**
     * Optional text to display when the current value is not one of the options from the lookup.
     * (a typical case for this is when the initial value was not in the lookup response)
     * Generally 'textField' or 'unknownValueOptions' props should be preferred instead as they support 'multiple' typeAheads.
     */
    unknownValueText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /**
     * Field name to store the 'text' of the dropdown when a value is selected (in addition to the usual field 'value').
     * When specified and data exists in the form, will be used to automatically infer unknown options.
     */
    textField: PropTypes.string,
    /**
     * Whether the typeAhead should be pre-populated with suggested options when no search has been made
     * Can be a boolean value, or a string to search on
     */
    searchSuggestions: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

    // -------------------
    // validator props
    // -------------------

    /**
     * Whether this field should be mandatory to completing the form
     */
    required: PropTypes.bool,
    /**
     * Override the default error message for required fields
     */
    requiredError: PropTypes.string,

    // -------------------
    // connectForm props
    // -------------------

    /**
     * Name of this field, and the form data key against which the value will be stored
     */
    field: PropTypes.string.isRequired,
    /**
     * Whether any errors on the field should be displayed, if not specified then inherits from the parent form or fields
     */
    showErrors: PropTypes.bool,
    /**
     * Whether to display all errors for this field or just show one error at a time, if not specified then inherits from the parent form or fields
     */
    allErrors: PropTypes.bool,
    /**
     * Time in milliseconds for field error animation transitions or false to disable, if not specified then inherits from the parent form or fields
     */
    animateErrors: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    /**
     * Whether to disable the field, if not specified then inherits from the parent form or fields
     */
    disabled: PropTypes.bool,
    /**
     * Whether the field should be read-only, if not specified then inherits from the parent form or fields
     */
    readOnly: PropTypes.bool,
    /**
     * Additional error messages that should be displayed before validator error messages
     */
    customErrors: PropTypes.arrayOf(PropTypes.string),
    /**
     * Function that will be run every time the field changes to perform additional validation
     * Resulting errors can be passed to customErrors
     */
    customValidator: PropTypes.func,
    /**
     * List of other field names that should be re-validated when this field changes
     */
    notifiedFields: PropTypes.arrayOf(PropTypes.string),
    /**
     * Current value of the field (supplied by the form connection)
     * @ignore
     */
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
    ]),
    /**
     * Callback when the field has changed (supplied by the form connection)
     * @ignore
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Array of error messages to display on the field (supplied by the form connection)
     * @ignore
     */
    errors: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.object,
    ]),
    /**
     * Props excluding those from the form connection (supplied by the form connection)
     * @ignore
     */
    passThruProps: PropTypes.object,
    /**
     * The current state of the form relative to this field (supplied by the form connection)
     * @ignore
     */
    formState: PropTypes.object,
  };

  static defaultProps = {
    onChange: () => {},
    onSearch: () => {},
    label: "",
    inline: false,
    selectOnBlur: false,
    selection: true,
    lookupOptions: {
      cache: true,
    },
  };

  onChange = (e, d) => {
    const { textField, multiple } = this.props;
    const { value, options } = d;
    const additionalFormData = {};

    // if textField specified store the selected text
    if (textField && value && options) {
      const selectedValues = Array.isArray(value) ? value : [value];
      const selectedText = selectedValues.map((v) => {
        const option = options.find((o) => o.value === v);
        return option && option.text !== undefined ? option.text : v;
      });

      additionalFormData[textField] =
        !multiple && selectedText.length === 1 ? selectedText[0] : selectedText;
    }

    this.props.onChange(value, additionalFormData);
  };

  onSearchChange = (e, d) => this.props.onSearch(d.searchQuery);

  defaultRenderReadOnly = ({ value, textField, formState, options }) => {
    let valueArray = Array.isArray(value) ? value : [value];

    if (textField && formState.fields[textField]) {
      const text = formState.fields[textField];
      const textArray = Array.isArray(text) ? text : [text];

      if (valueArray.length === textArray.length) {
        valueArray = textArray;
      }
    } else if (Array.isArray(options)) {
      valueArray = valueArray.map((v) => {
        const o = options.find((x) => x.value === v);
        return o ? o.text || o.value : v;
      });
    }

    return (
      <p>
        {valueArray
          .filter((v) => v !== null && v !== undefined && v !== "")
          .join(", ")}
      </p>
    );
  };

  renderReadOnly = () =>
    (this.props.renderReadOnly || this.defaultRenderReadOnly)(this.props);

  render() {
    const { multiple } = this.props;

    const {
      value = multiple ? [] : "",
      errors,
      showErrors,
      allErrors,
      animateErrors,
      disabled,
      readOnly,
      label,
      actions,
      inline,
      width,
      fluid,
      required,
      passThruProps,
      formState,
      selectOnBlur,
      selection,
      lookup,
      lookupParams,
      lookupOptions,
      unknownValueOptions,
      unknownValueText,
      textField,
      searchSuggestions,
      alwaysShowSuggestionsOnBlur,
    } = this.props;

    const semanticProps = FormUtils.omitProps(
      passThruProps,
      Object.keys(SheetServiceTypeAhead.propTypes)
    );

    const safeValue = value !== null ? value : multiple ? [] : "";

    // auto-infer unknown value options when textField specified
    let inferredValueOptions = undefined;
    if (textField && value && formState.fields[textField]) {
      const text = formState.fields[textField];
      const textArray = Array.isArray(text) ? text : [text];
      const valueArray = Array.isArray(value) ? value : [value];

      if (valueArray.length === textArray.length) {
        inferredValueOptions = valueArray.map((v, i) => ({
          value: v,
          text: textArray[i],
        }));
      }
    }

    return (
      <Field
        inline={inline}
        required={required}
        disabled={disabled}
        renderReadOnly={readOnly && this.renderReadOnly}
        width={width}
        fluid={fluid}
        label={label}
        actions={actions}
        errors={FormUtils.fieldErrors(errors, showErrors, allErrors)}
        animateErrors={animateErrors}
      >
        {lookup ? (
          <LookupTypeAhead
            {...semanticProps}
            value={safeValue}
            onChange={this.onChange}
            selectOnBlur={selectOnBlur}
            selection={selection}
            lookup={lookup}
            lookupParams={
              typeof lookupParams === "function"
                ? lookupParams(formState)
                : lookupParams
            }
            lookupOptions={
              typeof lookupOptions === "function"
                ? lookupOptions(formState)
                : lookupOptions
            }
            unknownValueOptions={
              typeof unknownValueOptions === "function"
                ? unknownValueOptions(formState)
                : unknownValueOptions || inferredValueOptions
            }
            unknownValueText={
              typeof unknownValueText === "function"
                ? unknownValueText(formState)
                : unknownValueText
            }
            searchSuggestions={searchSuggestions}
            alwaysShowSuggestionsOnBlur={alwaysShowSuggestionsOnBlur}
            disabled={disabled}
          />
        ) : (
          <SDropdown
            {...semanticProps}
            value={safeValue}
            onChange={this.onChange}
            search
            onSearchChange={this.onSearchChange}
            selectOnBlur={selectOnBlur}
            selection={selection}
            disabled={disabled}
          />
        )}
      </Field>
    );
  }
}

export default connectForm({
  displayName: (props) =>
    props.label && typeof props.label === "string"
      ? props.label
      : FormUtils.prettifyField(props.field),
  validators: [FormValidators.requiredField(false)],
})(SheetServiceTypeAhead);
