import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "AGREEMENTS";
const ActionTypes = {
  getDealers: `${NAMESPACE}/GET_DEALER_OFFICES`,
};

export const getDealerOffices = ({ filters, pagination }, params) => {
  return apiGet(ActionTypes.getDealers, "agreements/dealer-offices", {
    ...filters,
    ...pagination,
    ...params,
  });
};
