import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "USER_PIN";

export const ActionType = {
  GetHasUserPin: `${NAMESPACE}/GET_USER_HAS_PIN`,
};

export const getHasUserPin = () => {
  return apiGet(ActionType.GetHasUserPin, `users/has-pin`);
};
