import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Grid, Button } from "semantic-ui-react";
import { Form, FormBuilder, Lookup } from "@redriver/cinnamon";
import { getResponsiveState } from "features/../../../shared/modules/responsive/selectors";
import { IconButton } from "features/../../../shared/components/buttons";
import { getPaperDefaults, setPaperDefaults } from "./actions";
import { LookupTypes } from "features/../../../shared/features/Settings";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { PaperWeightsDropdownLookup } from "features/../../../shared/features/Settings";
import { UserType, PaperSize } from "features/../../../shared/constants/enums";
import Pen from "features/../../../shared/assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import { useTranslation } from "react-i18next";

const FieldSizes = {
  CostPerReam: 6,
  ReamSizes: 9,
  Markups: 9,
  Delivery: 9,
};

const transformFormData = (data, paperWeights) => {
  if (!data) return data;

  let paperCosts = {};
  data.paperCosts.forEach(pc => {
    paperCosts[`${pc.paperSize}_${pc.paperWeightId}`] = pc.value;
  });

  return {
    ...data,
    paperCosts,
  };
};

const renderCostPerReam = (paperWeights, paperSize, isMobile) =>
  Array.isArray(paperWeights)
    ? paperWeights.map(pw => (
      <Grid.Column width={3} key={`${paperSize}_${pw.value}`}>
        <Form.Numeric
          field={`${paperSize}_${pw.value}`}
          decimalPlaces={2}
          key={`${paperSize}_${pw.value}`}
          width={FieldSizes.CostPerReam}
          formatValue
          renderReadOnly={(props, state) => <p>{props.value || "-"}</p>}
        />
      </Grid.Column>
    ))
    : null;

const renderPaperWeightReadOnly = props => (
  <Lookup
    lookup={LookupTypes.PaperWeights}
    render={({ response }, resetLookup) => {
      const weight = response.find(x => x.value == props.value);
      return <p>{weight ? weight.text : "-"}</p>;
    }}
  />
);

const PaperDefaults = ({ settingsId, isMobile, currentUser }) => {
  const { t } = useTranslation();
  return (
    <Lookup
      lookup={LookupTypes.PaperWeights}
      render={(paperWeightLookupData, p2) => {
        return (
          <FormBuilder
            initialReadOnly
            loadAction={getPaperDefaults}
            loadParams={{ settingsId }}
            loadTransform={transformFormData}
            submitAction={setPaperDefaults}
            submitParams={{ settingsId }}
            renderForm={(formProps, state, events) => {
              const paperWeights = paperWeightLookupData.response;

              if (!paperWeights || !formProps.value) return null;

              return (
                <div className="paper-defaults">
                  <Form {...formProps}>
                    <div className="defaults-page-title flex">
                      <div className="flex title-bar">
                        <h4 className="settings-title">
                          {t("supplierOffices:paperInclusiveSettings:title")}
                        </h4>
                      </div>
                      <PermissionCheck
                        target={Targets.Defaults}
                        action={Actions.Edit}
                      >
                        <div className="flex">
                          {!state.readOnly && (
                            <Button
                              primary
                              className="cancel-title-action"
                              floated="right"
                              content={t("shared:buttons:cancel")}
                              onClick={events.onReset}
                            />
                          )}
                          <IconButton
                            primary
                            icon={state.readOnly ? Pen : Save}
                            content={
                              state.readOnly
                                ? t("shared:buttons:edit")
                                : t("shared:buttons:save")
                            }
                            floated="right"
                            onClick={() =>
                              state.readOnly
                                ? events.onToggleReadOnly()
                                : events.onSubmit({ reset: ["readOnly"] })
                            }
                            className={
                              !state.readOnly ? "positive-title-action" : null
                            }
                          />
                        </div>
                      </PermissionCheck>
                    </div>

                    <div className="content-container">
                      <div className="layout-grid flex">
                        <div className="section-1">
                          <h5>
                            {t(
                              "supplierOffices:paperInclusiveSettings:defaultPaperWeights"
                            )}
                          </h5>
                          <div className="section flex paper-weights">
                            <PaperWeightsDropdownLookup
                              field="defaultA3PaperWeightId"
                              label={t(
                                "supplierOffices:paperInclusiveSettings:defaultA3PaperWeight"
                              )}
                              fieldName="defaultA3PaperWeight"
                              width={10}
                              renderReadOnly={props =>
                                renderPaperWeightReadOnly(props)
                              }
                            />
                            <PaperWeightsDropdownLookup
                              field="defaultA4PaperWeightId"
                              label={t(
                                "supplierOffices:paperInclusiveSettings:defaultA4PaperWeight"
                              )}
                              fieldName="defaultA4PaperWeight"
                              width={10}
                              renderReadOnly={props =>
                                renderPaperWeightReadOnly(props)
                              }
                            />
                          </div>

                          <h5>
                            {t(
                              "supplierOffices:paperInclusiveSettings:minimumPaperCosts"
                            )}
                          </h5>
                          <div className="min-paper-costs section">
                            <Form.Object field="paperCosts">
                              <Grid columns={4}>
                                <Grid.Row className="paper-cost-header">
                                  <Grid.Column width={6}></Grid.Column>
                                  {Array.isArray(paperWeights) &&
                                    paperWeights.map(pw => (
                                      <Grid.Column width={3} key={pw.value}>
                                        {pw.text}
                                      </Grid.Column>
                                    ))}
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column width={6}>
                                    {t(
                                      "supplierOffices:paperInclusiveSettings:a3CostPerReam", { currencySymbol: process.env.MAJOR_CURRENCY }
                                    )}
                                  </Grid.Column>
                                  {renderCostPerReam(
                                    paperWeights,
                                    PaperSize.A3,
                                    isMobile
                                  )}
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column width={6}>
                                    {t(
                                      "supplierOffices:paperInclusiveSettings:a4CostPerReam", { currencySymbol: process.env.MAJOR_CURRENCY }
                                    )}
                                  </Grid.Column>
                                  {renderCostPerReam(
                                    paperWeights,
                                    PaperSize.A4,
                                    isMobile
                                  )}
                                </Grid.Row>
                              </Grid>
                            </Form.Object>
                          </div>
                        </div>
                        <div className="section-2">
                          <h5>
                            {t(
                              "supplierOffices:paperInclusiveSettings:reamSizes"
                            )}
                          </h5>
                          <div className="section">
                            <Grid columns={2}>
                              <Grid.Row className="ream-override-margin">
                                <Grid.Column>
                                  <Form.Numeric
                                    field="a3SheetsPerReam"
                                    label={t(
                                      "supplierOffices:paperInclusiveSettings:a3SheetsPerReam"
                                    )}
                                    width={
                                      isMobile ? "75%" : FieldSizes.ReamSizes
                                    }
                                  />
                                </Grid.Column>
                                <Grid.Column>
                                  <Form.Numeric
                                    field="a4SheetsPerReam"
                                    label={t(
                                      "supplierOffices:paperInclusiveSettings:a4SheetsPerReam"
                                    )}
                                    width={
                                      isMobile ? "75%" : FieldSizes.ReamSizes
                                    }
                                  />
                                </Grid.Column>
                              </Grid.Row>
                              <Grid.Row>
                                <Grid.Column>
                                  <Form.Numeric
                                    field="a3ReamsPerBox"
                                    label={t(
                                      "supplierOffices:paperInclusiveSettings:a3ReamsPerBox"
                                    )}
                                    width={
                                      isMobile ? "75%" : FieldSizes.ReamSizes
                                    }
                                  />
                                </Grid.Column>
                                <Grid.Column>
                                  <Form.Numeric
                                    field="a4ReamsPerBox"
                                    label={t(
                                      "supplierOffices:paperInclusiveSettings:a4ReamsPerBox"
                                    )}
                                    width={
                                      isMobile ? "75%" : FieldSizes.ReamSizes
                                    }
                                  />
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </div>

                          <h5 className="markups-top-margin">
                            {t(
                              "supplierOffices:paperInclusiveSettings:markups"
                            )}
                          </h5>
                          <div className="section">
                            <Grid columns={2}>
                              <Grid.Row>
                                <Grid.Column>
                                  <Form.Numeric
                                    field="standardMarkup"
                                    label={t(
                                      "supplierOffices:paperInclusiveSettings:standardMarkup"
                                    )}
                                    width={
                                      isMobile ? "75%" : FieldSizes.Markups
                                    }
                                  />
                                </Grid.Column>
                                <Grid.Column>
                                  <Form.Numeric
                                    field="a3Markup"
                                    label={t(
                                      "supplierOffices:paperInclusiveSettings:a3dMarkup"
                                    )}
                                    width={
                                      isMobile ? "75%" : FieldSizes.Markups
                                    }
                                  />
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </div>
                        </div>
                        <div className="section-3">
                          <h5>
                            {t(
                              "supplierOffices:paperInclusiveSettings:delivery"
                            )}
                          </h5>
                          <div className="section">
                            <Form.Numeric
                              field="minMonthlyBoxesWithoutDeliveryCharge"
                              label={t(
                                "supplierOffices:paperInclusiveSettings:minMonthlyBoxesWithoutDeliveryCharge"
                              )}
                              width={isMobile ? "75%" : FieldSizes.Delivery}
                            />
                            <Form.Numeric
                              field="quarterlyDeliveryChargeForBelowMinBoxes"
                              label={t(
                                "supplierOffices:paperInclusiveSettings:quarterlyDeliveryChargeForBelowMinBoxes", { currencySymbol: process.env.MAJOR_CURRENCY }
                              )}
                              width={isMobile ? "75%" : FieldSizes.Delivery}
                              decimalPlaces={2}
                            />
                            <Form.Numeric
                              field="quarterlyMinDeliveryChargeOverMinBoxes"
                              label={t(
                                "supplierOffices:paperInclusiveSettings:quarterlyMinDeliveryChargeOverMinBoxes", { currencySymbol: process.env.MAJOR_CURRENCY }
                              )}
                              width={isMobile ? "75%" : FieldSizes.Delivery}
                              decimalPlaces={2}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              );
            }}
          />
        );
      }}
    />
  );
};

const mapStateToProps = state => {
  const { screenSize } = getResponsiveState(state);
  return {
    isMobile: screenSize === "small",
  };
};

export default compose(connect(mapStateToProps))(PaperDefaults);
