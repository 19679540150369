import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { ModalBuilder } from "@redriver/cinnamon";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { getSheetState, getSheetDataState } from "features/Sheets";
import { hideModals } from "features/utils";
import { saveDetails, isDataPresent } from "features/Sheets/ManageSheet";

const ProductScheduleTriggerButton = ({ setProductScheduleViewable }) => {
  const { t } = useTranslation("sheetTranslation");
  const dispatch = useDispatch();
  const { sheetDetailsSaving, calculating } = useSelector(getSheetState);
  const sheetData = useSelector(getSheetDataState);

  const asyncSaving = async (sheetId) => {
    if (dispatch(isDataPresent()) == true) {
      const apiResponse = await dispatch(
        saveDetails(sheetId, false, null, false)
      );
      return apiResponse;
    }
    return { success: false, response: null };
  };

  return (
    <ModalBuilder
      renderTrigger={(showModal) => (
        <div className="product-schedule-button-container">
          <Button
            primary
            icon="file outline"
            disabled={sheetDetailsSaving || calculating}
            content="Product Schedule"
            onClick={
              sheetData.isDirty
                ? showModal
                : () => {
                    hideModals();
                    setProductScheduleViewable();
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }
            }
          />
        </div>
      )}
      renderModal={(modalProps, events) => (
        <TranslatedModal.Confirmation
          {...modalProps}
          onSubmit={async () => {
            const response = await asyncSaving(sheetData.id);
            if (!response.success) {
              events.onCancel();
              return;
            }

            setProductScheduleViewable();
            window.scrollTo({ top: 0, behavior: "smooth" });
            modalProps.onSubmit();
          }}
          submitDisabled={sheetDetailsSaving}
          submitting={sheetDetailsSaving}
        >
          {t("productScheduleConfirmationModal.note")}
        </TranslatedModal.Confirmation>
      )}
    />
  );
};

export default ProductScheduleTriggerButton;
