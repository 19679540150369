import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { ModalBuilder, Form } from "@redriver/cinnamon";
import { generateSchedule } from "features/Agreements/AgreementsList/actions";
import { getAgreementsState } from "features/Agreements/selectors";
import { SheetRoutes } from "constants/routes";
import { TranslatedModal } from "features/../../../shared/components/modals";

const AddSchedule = () => {
  const { t } = useTranslation("agreements");
  const history = useHistory();

  const {
    companyFilters: { dealerOffice, client },
    agreements,
  } = useSelector(getAgreementsState);

  const masterAgreementDropdownOptions =
    agreements?.map((x) => ({
      value: x.agreementId,
      text: x.agreementReference
        ? x.agreementReference + " - " + x.projectName
        : x.projectName,
    })) ?? [];

  return (
    <ModalBuilder
      submitAction={generateSchedule}
      submitParams={{
        dealerOfficeId: dealerOffice ? dealerOffice.id : null,
        clientId: client ? client.id : null,
      }}
      onSubmitted={(submitResponse) =>
        history.push(SheetRoutes.Sheet(submitResponse), {
          isNewSheet: true,
        })
      }
      withForm={true}
      renderTrigger={(showModal) => (
        <Button primary onClick={showModal} className="add-new-schedule">
          <Icon name="sticky note" size="large" />
          {t("ScheduleRow.Add_Schedule_Modal.Button")}
        </Button>
      )}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Add
          {...modalProps}
          header={t("ScheduleRow.Add_Schedule_Modal.Title")}
          className="add-schedule-modal"
        >
          <Form {...formProps} className="new-schedule">
            <Form.Dropdown
              field="masterAgreementId"
              label={t("ScheduleRow.Add_Schedule_Modal.Master_Agreement")}
              placeholder="Master Agreement"
              required
              options={masterAgreementDropdownOptions}
              className="master-agreement-id"
            />

            <Form.Input
              field="projectName"
              label={t("ScheduleRow.Add_Schedule_Modal.Project_Name")}
              required
              className="new-project-name"
            />
          </Form>
        </TranslatedModal.Add>
      )}
    />
  );
};

export default AddSchedule;
