import React from "react";
import { ModalBuilder, Table } from "@redriver/cinnamon";
import { Targets, Actions } from "constants/permissions";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { deleteNote } from "../actions";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";

const DeleteNote = ({
  as: As = Table.MenuItem,
  sheetId,
  noteId,
  onSubmitted,
}) => {
  const { t } = useTranslation("sheetHeader");

  return (
    <PermissionCheck target={Targets.Sheet} action={Actions.Edit}>
      <ModalBuilder
        withForm
        submitAction={deleteNote}
        submitParams={{ sheetId, noteId }}
        onSubmitted={onSubmitted}
        renderTrigger={(showModal) => (
          <As onClick={showModal}>{t("Delete")}</As>
        )}
        renderModal={(modalProps) => (
          <TranslatedModal.Delete
            {...modalProps}
            header={t("Delete_Note")}
            size="tiny"
          >
            {t("Delete_note_confirmation")}
          </TranslatedModal.Delete>
        )}
      />
    </PermissionCheck>
  );
};

export default DeleteNote;
