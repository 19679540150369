import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PageHeader } from "@redriver/cinnamon";
import { SystemPageContent } from "components/pages";
import { requestLogout } from "features/System/Logout/actions";
import { setPersistentQueryParams } from "features/System/actions";

const AccountManagerLogoutPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestLogout());
    dispatch(setPersistentQueryParams({}));
  }, [dispatch]);

  const { t } = useTranslation("system");
  return (
    <SystemPageContent>
      <PageHeader>{t("Sign_Out")}</PageHeader>
      <p className="crm-login-message">
        {t("You_are_logged_out_please_log_in_via_your_CRM_system")}
      </p>
    </SystemPageContent>
  );
};

export default AccountManagerLogoutPage;
