import React from "react";
import { Segment } from "semantic-ui-react";
import { Format } from "@redriver/cinnamon";
import classNames from "classnames";
import { InlineLoader } from "features/../../../shared/components/forms/InlineLoader";
import { getSheetPaperInclusiveState } from "features/Sheets";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const PaperStepTermsAndTotaliser = ({
  totalIncMinDelivery,
  quarterlyContractVol,
  contractVolumeInBoxedReams,
  amethystAmount,
  minDiffToCreateInAmethyst,
  likelyMinGmBelowActioned,
  calculating,
}) => {
  const { t } = useTranslation("paperInclusive");
  return (
    <div className="terms-and-totaliser">
      <Segment clearing basic className="totaliser paper-totaliser">
        <p>
          <span>
            {t("modal.paperStepTermsAndTotaliser.totalIncludingAmount")}
          </span>
          <InlineLoader size="tiny" isActive={calculating} />
          <Format.Number
            value={totalIncMinDelivery}
            format={process.env.CURRENCY_CODE}
            prefix={process.env.MAJOR_CURRENCY}
            decimalPlaces={2}
            thousandSeparator={true}
            className={classNames(
              "totaliser-value",
              "calculable-field",
              calculating && "value-loading",
              totalIncMinDelivery < 0 && "negative"
            )}
          />
        </p>
        <p>
          <span>
            {t("modal.paperStepTermsAndTotaliser.quarterlyContractVolume")}
          </span>
          <InlineLoader size="tiny" isActive={calculating} />
          <Format.Number
            value={quarterlyContractVol}
            className={classNames(
              "totaliser-value",
              "calculable-field",
              calculating && "value-loading",
              quarterlyContractVol < 0 && "negative"
            )}
          />
        </p>
        <p>
          <span>{t("modal.paperStepTermsAndTotaliser.equates")}</span>
          <InlineLoader size="tiny" isActive={calculating} />
          <Format.Number
            value={contractVolumeInBoxedReams}
            className={classNames(
              "totaliser-value",
              "calculable-field",
              calculating && "value-loading",
              contractVolumeInBoxedReams < 0 && "negative"
            )}
          />
        </p>
        <p>
          <span>{t("modal.paperStepTermsAndTotaliser.tmvsa")}</span>
          <InlineLoader size="tiny" isActive={calculating} />
          <Format.Number
            value={amethystAmount}
            format={process.env.CURRENCY_CODE}
            className={classNames(
              "totaliser-value",
              "calculable-field",
              calculating && "value-loading",
              amethystAmount < 0 && "negative"
            )}
          />
        </p>
        <p>
          <span>{t("modal.paperStepTermsAndTotaliser.minimumDifference")}</span>
          <InlineLoader size="tiny" isActive={calculating} />
          <Format.Number
            value={minDiffToCreateInAmethyst}
            format={process.env.CURRENCY_CODE}
            className={classNames(
              "totaliser-value",
              "calculable-field",
              calculating && "value-loading",
              minDiffToCreateInAmethyst < 0 && "negative"
            )}
          />
        </p>
        <p>
          <span>{t("modal.paperStepTermsAndTotaliser.likelyMinimum")}</span>
          <InlineLoader size="tiny" isActive={calculating} />
          <Format.Number
            value={likelyMinGmBelowActioned}
            format={process.env.CURRENCY_CODE}
            className={classNames(
              "totaliser-value",
              "calculable-field",
              calculating && "value-loading",
              likelyMinGmBelowActioned < 0 && "negative"
            )}
          />
        </p>
      </Segment>
    </div>
  );
};

const mapStateToProps = state => {
  const {
    summary: {
      totalIncMinDelivery,
      quarterlyContractVol,
      contractVolumeInBoxedReams,
      amethystAmount,
      minDiffToCreateInAmethyst,
      likelyMinGmBelowActioned,
    },
  } = getSheetPaperInclusiveState(state);

  return {
    totalIncMinDelivery,
    quarterlyContractVol,
    contractVolumeInBoxedReams,
    amethystAmount,
    minDiffToCreateInAmethyst,
    likelyMinGmBelowActioned,
  };
};

export default connect(mapStateToProps, null)(PaperStepTermsAndTotaliser);
