import React, { useState } from "react";
import { Icon, Transition } from "semantic-ui-react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Form, getAuthClaim } from "@redriver/cinnamon";
import classNames from "classnames";
import { canSheetBeUpdated } from "../helpers";
import {
  ScheduleStepCreateLargeFormat,
  ScheduleLargeFormatGroupArray,
  ScheduleStepEditLargeFormat,
} from "./LargeFormat";
import ScheduleUnitArray from "./ScheduleUnitArray";
import {
  SheetStep,
  cannotCalcMessages,
  lfHasManagedVolInc,
} from "features/Sheets/ManageSheet";
import {
  getSheetLargeFormatScheduleState,
  getSheetState,
  getSheetIpsRatesState,
  getSheetRestrictionsState,
  getSheetRatesState,
} from "features/Sheets";
import { FixedInput, FixedInputResponsiveLabel } from "components/forms";
import {
  PermissionCheck,
  withCurrentUser,
  withPermissions,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { SheetViewType } from "features/../../../shared/constants/enums";
import { useTranslation } from "react-i18next";
import { ProductScheduleTriggerButton } from "features/Sheets/ManageSheet/Shared";
import AddUnit from "./AddUnit";
import ColumnsHeader from "./ColumnsHeader";

const scheduleMissingInclusive = (fields) =>
  fields &&
  Array.isArray(fields.systemScheduleItems) &&
  fields.systemScheduleItems.some((x) => !x.includeManagedVol.value) &&
  !fields.systemScheduleItems.some((x) => x.includeManagedVol.value) &&
  !lfHasManagedVolInc(fields.largeFormatGroups);

const ScheduleStep = ({
  sheetId,
  onNextStep,
  onPreviousStep,
  numberOfSchedules,
  utilityMeters,
  calculating,
  sheetDetailsSaving,
  displayPrevious,
  displayNext,
  displaySave,
  collapsed,
  toggleVisibility,
  reactRef,
  contractType,
  hasLfItems,
  customerToggle,
  displayCustomerToggle = false,
  unlockAllowedTieredToner,
  unlockDisableMinVolsLogic,
  sheetViewType,
  setProductScheduleViewable,
  impersonatedUser,
  tieredTonerAvailableToDealer,
  sheetCanBeUpdated,
  userType,
  allowedTieredToner,
  preventUpdates,
  hasPermission,
  userArea,
  currentUser,
}) => {
  const [editModalTrigger, setEditModalTrigger] = useState(false);
  const [largeFormatEditIndex, setLargeFormatEditIndex] = useState();
  const [lfEditModalLoaded, setLfEditModalLoaded] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const hideFixedIndicator = !impersonatedUser.canEditAdminFields;
  const { t } = useTranslation("systemSchedule");

  const majorCurrency = process.env.MAJOR_CURRENCY;
  const minorCurrency = process.env.MINOR_CURRENCY;
  const principalMode = !process.env.TRADE_AS_IPS;

  const showUnitsHeader = hasPermission(
    Targets.Sheet,
    Actions.ViewStandardScheduleItems,
  );
  const showLFPHeader = hasPermission(
    Targets.Sheet,
    Actions.ViewLargeFormatSection,
  );

  const scheduleItemsLimitExceeded = principalMode
    ? numberOfSchedules > 10
    : false;
  const utilityMetersExceeded = principalMode ? utilityMeters > 5 : false;
  const scheduleItemsReachedLimit = principalMode
    ? numberOfSchedules >= 10
    : false;
  const utilityMetersReachedLimit = principalMode ? utilityMeters >= 5 : false;
  return (
    <div ref={reactRef} className="step-container">
      <SheetStep
        onNextStep={onNextStep}
        onPreviousStep={onPreviousStep}
        displayPrevious={displayPrevious}
        displayNext={displayNext}
        displaySave={displaySave}
        calculating={calculating}
        displayCustomerToggle={displayCustomerToggle}
        customerToggle={customerToggle}
        sheetId={sheetId}
        className="schedule-step"
        saveNotice={(data) =>
          scheduleMissingInclusive(data) && (
            <p className="sheet-validation-message sheet-validation-error">
              You must have inclusive volumes
            </p>
          )
        }
        errorMessage={cannotCalcMessages.schedules}
      >
        <div className="schedule-step">
          <div className="schedule-step-header">
            <div className="sheet-step-header" onClick={toggleVisibility}>
              <h5 className="title">{t("title")}</h5>
              {toggleVisibility && (
                <Icon
                  name="dropdown"
                  rotated={collapsed ? "counterclockwise" : null}
                />
              )}
            </div>
          </div>
          <Transition animation="fade down" visible={!collapsed} duration={500}>
            <div>
              <div className="overall-values system-schedule-top-row">
                <div>
                  <Form.Object field="capital" propagateUpdates="always">
                    <FixedInput
                      label={
                        <FixedInputResponsiveLabel
                          label={t("form.deviceQuantityOnSchedule.label")}
                        />
                      }
                      field="totalDevicesOnSchedule"
                      fluid
                      decimalPlaces={0}
                      formatNumber
                      placeholder={t(
                        "form.deviceQuantityOnSchedule.placeholder",
                      )}
                      selectOnFocus
                      className="tiny device-qty"
                      hideIndicator={hideFixedIndicator}
                      minAllowValue={0}
                    />
                  </Form.Object>
                </div>

                <PermissionCheck
                  target={Targets.SheetAdmin}
                  action={Actions.Edit}
                >
                  <div className="admin-fields">
                    <Form.Object field="rates" propagateUpdates="always">
                      {(sheetViewType === SheetViewType.IpsAdmin ||
                        (sheetViewType === SheetViewType.SupplierAdmin &&
                          tieredTonerAvailableToDealer)) &&
                        impersonatedUser.canEditAdminFields && (
                          <React.Fragment>
                            <Form.RadioGroup
                              inline
                              field="allowedTieredToner"
                              disabled={
                                !unlockAllowedTieredToner ||
                                !tieredTonerAvailableToDealer ||
                                preventUpdates
                              }
                              label={
                                <div className="sheet-radio-title">
                                  <p>{t("form.allowedTierToner.label")}</p>
                                </div>
                              }
                              options={[
                                { value: 1, text: "Yes" },
                                { value: 0, text: "No" },
                              ]}
                              className="required-field sheet-radio-group"
                            />
                            <Form.RadioGroup
                              inline
                              field="overrideMaxTieredToner"
                              disabled={
                                !unlockDisableMinVolsLogic || preventUpdates
                              }
                              label={
                                <div className="sheet-radio-title">
                                  <p>
                                    {t("form.overrideMaximumTieredToner.label")}
                                  </p>
                                </div>
                              }
                              options={[
                                { value: 1, text: "Yes" },
                                { value: 0, text: "No" },
                              ]}
                              className="required-field sheet-radio-group"
                            />
                          </React.Fragment>
                        )}
                      {(sheetViewType === SheetViewType.IpsAdmin ||
                        sheetViewType === SheetViewType.SupplierAdmin) &&
                        impersonatedUser.canEditAdminFields && (
                          <Form.RadioGroup
                            inline
                            field="systemScheduleDisableMinVolsLogic"
                            disabled={
                              !unlockDisableMinVolsLogic || preventUpdates
                            }
                            label={
                              <div className="sheet-radio-title">
                                <p>{t("form.disableMinVolsLogic.label")}</p>
                              </div>
                            }
                            options={[
                              { value: 1, text: "Yes" },
                              { value: 0, text: "No" },
                            ]}
                            className="required-field sheet-radio-group"
                          />
                        )}
                    </Form.Object>
                  </div>
                </PermissionCheck>
              </div>
              <div className="sheet-step-header">
                {sheetCanBeUpdated && (
                  <React.Fragment>
                    <PermissionCheck
                      target={Targets.Sheet}
                      action={Actions.ViewStandardScheduleItems}
                    >
                      <AddUnit
                        sheetId={sheetId}
                        contractType={contractType}
                        hideFixedIndicator={hideFixedIndicator}
                        userType={userType}
                        disabled={
                          preventUpdates ||
                          scheduleItemsLimitExceeded ||
                          scheduleItemsReachedLimit
                        }
                        userArea={userArea}
                        currentUser={currentUser}
                        showTooltip={
                          scheduleItemsLimitExceeded ||
                          scheduleItemsReachedLimit
                        }
                      />
                    </PermissionCheck>
                    <PermissionCheck
                      target={Targets.Sheet}
                      action={Actions.ViewLargeFormatSection}
                    >
                      <ScheduleStepCreateLargeFormat
                        sheetId={sheetId}
                        sheetViewType={sheetViewType}
                        showTooltip={
                          scheduleItemsLimitExceeded ||
                          utilityMetersExceeded ||
                          scheduleItemsReachedLimit ||
                          utilityMetersReachedLimit
                        }
                        disabled={
                          preventUpdates ||
                          sheetDetailsSaving ||
                          scheduleItemsLimitExceeded ||
                          utilityMetersExceeded ||
                          scheduleItemsReachedLimit ||
                          utilityMetersReachedLimit
                        }
                      />
                    </PermissionCheck>
                  </React.Fragment>
                )}
              </div>
              <div className="large-format-create"></div>
              <div
                className={classNames(
                  "schedule-container",
                  popoverVisible && "disable-overflow",
                )}
              >
                <div
                  onKeyDown={(e) => {
                    //prevent enter key propagation
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                >
                  {(hasLfItems || numberOfSchedules > 0) && (
                    <ColumnsHeader
                      tieredTonerAvailableToDealer={
                        tieredTonerAvailableToDealer
                      }
                      allowedTieredToner={allowedTieredToner}
                      majorCurrency={majorCurrency}
                      minorCurrency={minorCurrency}
                      numberOfSchedules={numberOfSchedules}
                      showHeader={showUnitsHeader}
                      forLFP={false}
                    />
                  )}
                </div>
                <PermissionCheck
                  target={Targets.Sheet}
                  action={Actions.ViewStandardScheduleItems}
                >
                  <ScheduleUnitArray
                    calculating={calculating}
                    contractType={contractType}
                    sheetId={sheetId}
                    setPopoverVisible={setPopoverVisible}
                  ></ScheduleUnitArray>
                </PermissionCheck>
                <div
                  onKeyDown={(e) => {
                    //prevent enter key propagation
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                >
                  {hasLfItems && numberOfSchedules != 0 && (
                    <PermissionCheck
                      target={Targets.Sheet}
                      action={Actions.ViewLargeFormatSection}
                    >
                      <ColumnsHeader
                        tieredTonerAvailableToDealer={
                          tieredTonerAvailableToDealer
                        }
                        allowedTieredToner={allowedTieredToner}
                        majorCurrency={majorCurrency}
                        minorCurrency={minorCurrency}
                        numberOfSchedules={numberOfSchedules}
                        showHeader={!(showUnitsHeader && showLFPHeader)}
                        forLFP
                      />
                    </PermissionCheck>
                  )}
                </div>
                <PermissionCheck
                  target={Targets.Sheet}
                  action={Actions.ViewLargeFormatSection}
                >
                  <ScheduleLargeFormatGroupArray
                    contractType={contractType}
                    hasAnyItems={hasLfItems}
                    sheetId={sheetId}
                    sheetDetailsSaving={sheetDetailsSaving}
                    setEditModalTrigger={() =>
                      setEditModalTrigger(!editModalTrigger)
                    }
                    setLargeFormatEditIndex={setLargeFormatEditIndex}
                  ></ScheduleLargeFormatGroupArray>
                </PermissionCheck>
                <ScheduleStepEditLargeFormat
                  sheetId={sheetId}
                  editModalTrigger={editModalTrigger}
                  largeFormatEditIndex={largeFormatEditIndex}
                  setEditModalTrigger={setEditModalTrigger}
                  setLfEditModalLoaded={setLfEditModalLoaded}
                  lfEditModalLoaded={lfEditModalLoaded}
                  sheetViewType={sheetViewType}
                />
              </div>
              <div>
                {scheduleItemsLimitExceeded && (
                  <span className="calc-error">
                    {t("Maximum_Limit_Reached")}
                  </span>
                )}
                {scheduleItemsLimitExceeded && utilityMetersExceeded && (
                  <span className="calc-error"> / </span>
                )}
                {utilityMetersExceeded && (
                  <span className="calc-error">
                    {t("Maximum_Utility_Limit_Reached")}
                  </span>
                )}
              </div>

              <ProductScheduleTriggerButton
                setProductScheduleViewable={setProductScheduleViewable}
              />
            </div>
          </Transition>
        </div>
      </SheetStep>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { sheetData, sheetDetailsSaving, sheetViewType } = getSheetState(state);
  const { preventUpdates, impersonatedUser } = sheetData;
  const lfItems = getSheetLargeFormatScheduleState(state) || [];
  const { unlockAllowedTieredToner, unlockDisableMinVolsLogic } =
    getSheetIpsRatesState(state);
  const { tieredTonerAvailableToDealer } = getSheetRestrictionsState(state);
  const { allowedTieredToner } = getSheetRatesState(state);
  return {
    sheetData,
    sheetDetailsSaving,
    hasLfItems: lfItems.length > 0,
    unlockAllowedTieredToner,
    unlockDisableMinVolsLogic,
    sheetViewType,
    preventUpdates,
    impersonatedUser,
    tieredTonerAvailableToDealer,
    sheetCanBeUpdated: canSheetBeUpdated(sheetData),
    allowedTieredToner,
    userArea: getAuthClaim(state, "userArea"),
  };
};

export default compose(
  connect(mapStateToProps),
  withPermissions,
  withCurrentUser,
)(ScheduleStep);
