import { apiPut, notifySuccess } from "@redriver/cinnamon";

const ActionTypes = {
  SetCurrentSchedule: "SHEETS/SET_CURRENT_SCHEDULE",
};

export const setCurrentSchedule =
  (request, { sheetId }) =>
  async dispatch => {
    const response = await dispatch(
      apiPut(
        ActionTypes.SetCurrentSchedule,
        `sheets/${sheetId}/current-schedule`,
        request
      )
    );

    response.success &&
      dispatch(notifySuccess("Current schedule updated successfully"));
    return response;
  };
