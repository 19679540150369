import React from "react";
import { useTranslation } from "react-i18next";
import { Format } from "@redriver/cinnamon";
import DataGrid from "./DataGrid";

const YieldInformationGrid = ({ data }) => {
  const { t } = useTranslation("capitalCalc", {
    keyPrefix: "infoModal.funderTypes.yieldInfo",
  });

  return (
    <DataGrid
      className="yield-info"
      headerText={t("title")}
      dataRows={[
        [
          t("ipsYieldAtSupplierNetRate"),
          <Format.Number
            key={1}
            value={data.ipSyndicateYieldAtDealerNetRate}
            format="percentage"
            decimalPlaces={2}
          />,
        ],
        [
          t("yieldAtGrossSalesRate"),
          <Format.Number
            key={2}
            value={data.rate}
            format="percentage"
            decimalPlaces={2}
          />,
        ],
      ]}
    />
  );
};

export default YieldInformationGrid;
