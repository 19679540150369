import PropTypes from "prop-types";
import i18next from "i18next";

const minValue = () => {
  return {
    propTypes: {
      minValue: PropTypes.number,
    },
    shouldValidate: () => true,
    getErrors: (field, value) => {
      if (
        typeof value === "object" &&
        !(value?.value == null) &&
        !!value.value.length
      ) {
        const val = parseFloat(value.value);

        if (!(value.minimumValue == null) && val < value.minimumValue) {
          return [
            `${field} ${i18next.t("sheetTranslation:CanNotBeLessThan")} ${
              value.minimumValue
            }`,
          ];
        }
      }
      return [];
    },
  };
};

export default minValue;
