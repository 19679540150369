import React from "react";
import { Form } from "@redriver/cinnamon";
import { Image } from "semantic-ui-react";
import { UserTypeOptions } from "features/../../../shared/constants/enums";
import Search from "features/../../../shared/assets/purple-search.svg";
import { useTranslation } from "react-i18next";

const FilterUsers = ({ value, formProps, isInternalUser, companyId }) => {
  const  { t }  = useTranslation('supplierOffices', { keyPrefix:'users'});
  return(
  <Form {...formProps}>
    <div className="users-list-filter">
      <Form.Input
        field="search"
        placeholder={t("search")}
        icon={<Image src={Search} />}
        className="search-input highlighted-input"
        width={20}
      />

      {isInternalUser && !companyId && (
        <Form.RadioGroup field="userType" options={UserTypeOptions} inline />
      )}
    </div>
  </Form>
)};

export default FilterUsers;
