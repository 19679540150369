import React from "react";
import { ModalBuilder, Table } from "@redriver/cinnamon";
import { TranslatedModal } from "../../../components/modals";
import RateGroupAudit from "./RateGroupAudit";

const RateGroupAuditModal = ({ rateGroup }) => (
  <ModalBuilder
    withForm
    renderTrigger={(showModal) => (
      <Table.MenuItem onClick={showModal}>Audit</Table.MenuItem>
    )}
    renderModal={(modalProps, formProps) => (
      <TranslatedModal.Information
        {...modalProps}
        size="small"
        header="View Audit Trail"
        submitLabel="Close"
      >
        <RateGroupAudit rateGroup={rateGroup} />
      </TranslatedModal.Information>
    )}
  />
);

export default RateGroupAuditModal;
