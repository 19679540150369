import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder } from "@redriver/cinnamon";
import { lockOutUser } from "./actions";
import { TranslatedModal } from "features/../../../shared/components/modals";

const LockOutUser = ({ as: As = Button, user, onLockOut, ...props }) => (
  <ModalBuilder
    submitAction={lockOutUser}
    submitParams={user.id}
    onSubmitted={onLockOut}
    renderTrigger={showModal => <As onClick={showModal} {...props} />}
    renderModal={modalProps => (
      <TranslatedModal.Confirmation {...modalProps} header="Lock Out">
        Are you sure you wish to lock out {user.name}?
      </TranslatedModal.Confirmation>
    )}
  />
);

export default LockOutUser;
