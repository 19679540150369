import React from "react";
import { connect } from "react-redux";
import { Grid, Button } from "semantic-ui-react";
import { Form, FormBuilder, Loadable } from "@redriver/cinnamon";
import { IconButton } from "features/../../../shared/components/buttons";
import { getResponsiveState } from "../../../modules/responsive/selectors";
import { getDefaults, setDefaults } from "./actions";
import Pen from "../../../assets/pen.svg";
import Save from "../../../assets/save.svg";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";

const getLabel = name => {
  return (
    <div className="default-grid-field-label-container">
      <div className="defaults-grid-title">
        <p>{name}</p>
        <span className="required-star">*</span>
      </div>
    </div>
  );
};

const ScheduleSettingsGrid = ({ settingsId, isMobile }) => (
  <div className="defaults-grid">
    <FormBuilder
      initialReadOnly
      loadAction={getDefaults}
      loadParams={{ settingsId }}
      submitAction={setDefaults}
      submitParams={settingsId}
      renderLoading={false}
      renderSubmitting={false}
      renderForm={(formProps, state, events) => {
        return (
          <React.Fragment>
            <div className="defaults-page-title flex">
              <div className="flex title-bar">
                <h4 className="settings-title">Schedule</h4>
              </div>
              <PermissionCheck target={Targets.Defaults} action={Actions.Edit}>
                <div>
                  <IconButton
                    primary
                    icon={state.readOnly ? Pen : Save}
                    content={state.readOnly ? "Edit" : "Save"}
                    floated="right"
                    onClick={() =>
                      state.readOnly
                        ? events.onToggleReadOnly()
                        : events.onSubmit({ reset: ["readOnly"] })
                    }
                    className={!state.readOnly ? "positive-title-action" : null}
                    loading={state.loading || state.submitting}
                    disabled={state.loading || state.submitting}
                  />
                  {!state.readOnly && (
                    <Button
                      primary
                      className="cancel-title-action"
                      floated="right"
                      content="Cancel"
                      onClick={events.onReset}
                    />
                  )}
                </div>
              </PermissionCheck>
            </div>

            <div className="principal-list">
              <Loadable loading={state.loading || state.submitting}>
                <Form {...formProps} className="highlighted-inputs">
                  <Grid
                    stackable
                    columns={!isMobile ? 4 : 3}
                    className="defaults-grid"
                  >
                    <Grid.Row>
                      <Grid.Column>
                        <Form.Numeric
                          field="tier3MaximumPercent"
                          label={getLabel(
                            "Tier 3 schedule item maximum percentage",
                          )}
                          placeholder="Maximum Tier 3 item percent"
                          required
                          decimalPlaces={2}
                          width={isMobile ? "75%" : 16}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={8} className="defaults-clause">
                        <Form.Input
                          field="upgradeClause"
                          label="Upgrade Clause heading"
                          fluid
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={8} className="defaults-clause">
                        <Form.Input
                          fluid
                          field="applicableFromPrefix"
                          label="Applicable From Prefix"
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Form.Numeric
                          field="a0SquareMetersLfp"
                          label={getLabel("A0 Square Metres (LFP)")}
                          placeholder="A0 Square Metres..."
                          required
                          width={isMobile ? "75%" : 16}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </Loadable>
            </div>
          </React.Fragment>
        );
      }}
    />
  </div>
);

const mapStateToProps = state => {
  const { screenSize } = getResponsiveState(state);
  return {
    isMobile: screenSize === "small",
  };
};

export default connect(mapStateToProps)(ScheduleSettingsGrid);
