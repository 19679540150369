import React, { useRef, useState } from 'react';
import { TranslatedModal } from '../modals';
import { PwaConfigState, PwaSessionType } from '../../constants/enums';

const PwaHandler = ({appName}) => {   
  const manifestQueryParamKey = 'pwa';
  const configStorageKey = 'pwa-config-state';
  const sessionTypeStorageKey = 'pwa-session';

  const beforeInstallEvent = useRef(undefined);
  const [modalDisplayState, setModalDisplay] = useState(false);
  const [submitDisabledState, setSubmitDisabled] = useState(false);
  const header = 'Install '+ appName;

  /*
  * first perform a soft check to try and determine whether or not we
  * are running in an installed instance, based on the presence of a
  * query argument that the manifest specifies in its startup
  * 
  * This is not bulletproof, as anyone could pass this up from any
  * browser, so we can't rely on this for anything mission critical
  */
  if ((new URLSearchParams(window.location.search)).has(manifestQueryParamKey)) {
    console.info('App appears to be running in a locally-installed PWA instance');
    window.sessionStorage.setItem(sessionTypeStorageKey, PwaSessionType.LocallyInstalled);
    return null;
  } else {
    console.info('App appears to be running in a regular browser window');
    window.sessionStorage.setItem(sessionTypeStorageKey, PwaSessionType.RegularBrowser);
  }

  const getConfig = () =>
  parseInt(window.sessionStorage.getItem(configStorageKey)) || PwaConfigState.None;
  const pushConfigFlag = (state = PwaConfigState.None) =>  {
    const current = getConfig();
    !(current & state) && window.sessionStorage.setItem(configStorageKey, current | state);
  }

  /*
  * the prompt is configurable by environment, so first check that
  */
  if (!process.env.ENABLE_PWA_INSTALL_PROMPT) {
    console.info('PWA installation prompt disabled for this environment, skipping');
    pushConfigFlag(PwaConfigState.DisabledForEnv);
    return null;
  }

  /*
  * if we are missing required methods on global objs, then this
  * browser does not fully support PWAs, so skip
  */
  if (!window.navigator.getInstalledRelatedApps) {
    console.info('Browser does not support PWA installation, skipping');
    pushConfigFlag(PwaConfigState.Incompatible);
    return null;
  }

  /*
  * if the user has already dismissed the prompt modal, or installation
  * has been triggered, skip and do not prompt again
  */
  if (getConfig() & (PwaConfigState.ModalDismissed | PwaConfigState.InstallBegun)) {
    console.info('PWA installation has already begun, or user has dismissed the prompt, skipping');
    return null;
  }

  window.addEventListener('beforeinstallprompt', async event => {
    if (!(event instanceof BeforeInstallPromptEvent) || !event.prompt) {
      console.info('Browser cannot handle PWA install events, skipping');
      pushConfigFlag(PwaConfigState.Incompatible);
      return;
    }

    console.info('Browser supports PWA installation, prompting user');
    beforeInstallEvent.current = event;
    setModalDisplay(true);
    pushConfigFlag(PwaConfigState.UserPrompted);
  });

  window.addEventListener('appinstalled', () => {
    console.info('Browser has indicated that PWA installation is complete, dismissing prompt');
    pushConfigFlag(PwaConfigState.InstallComplete);
    setModalDisplay(false);
    beforeInstallEvent.current = undefined;
  });

  return (
    <TranslatedModal.Confirmation open={modalDisplayState}
      size='small' header={header} cancelLabel='Stay in browser'
      submitLabel='Install now' submitIcon='download'
      submitDisabled={submitDisabledState}
      onCancel={() => {
        console.info('User has dismissed PWA installation prompt');
        pushConfigFlag(PwaConfigState.ModalDismissed);
        setModalDisplay(false);
      }}
      onSubmit={async () => {
        console.info('User has requested PWA install, firing browser event');
        pushConfigFlag(PwaConfigState.BrowserEventFired);
        setSubmitDisabled(true);

        switch ((await beforeInstallEvent.current.prompt())?.outcome) {
          case 'accepted':
            console.info('User has accepted PWA installation, awaiting response from browser...');
            pushConfigFlag(PwaConfigState.InstallBegun);
            /*
            * we now wait for the browser to fire the `appinstalled` event
            */
            break;

          case 'dismissed':
          default:
            console.info('User has dismissed or blurred PWA installation');
            pushConfigFlag(PwaConfigState.BrowserEventDismissed);
            setSubmitDisabled(false);
            break;
        }
      }}
    >
      <p>{appName} is available for installation on this device</p>
    </TranslatedModal.Confirmation>
  );
};

export default PwaHandler;
