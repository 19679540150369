import { Format } from "@redriver/cinnamon";
import { getSheetDataState, getCalculationData } from "features/Sheets";
import React from "react";
import { connect } from "react-redux";
import { BlankNumberFormatter } from "features/../../../shared/components/display";
import { useTranslation } from "react-i18next";

const ComparisonSheetPaperSpend = ({
  paperInclusive,
  currentSpendTotalAmount,
}) => {
  const { t } = useTranslation("comparisonSheet");
  return (
    <React.Fragment>
      {paperInclusive && (
        <div className="comparison-paper-spend section-header table">
          <div className="comparison-paper-spend-title">
            {t("Current_Cut_Sheet_Paper_Spend")}
          </div>
          <div className="comparison-paper-spend-body">
            <div className="comparison-paper-spend-headings">
              <div className="col-1">{t("Size")}</div>
              <div className="col-2">{t("Weight")}</div>
              <div className="col-3">{t("QTY_Per_Ream")}</div>
              <div className="col-4">{t("Price_Per_Ream")}</div>
              <div className="col-5">{t("Qty_Reams_Bought_per_Qtr")}</div>
              <div className="col-6">{t("Delivery_Charges_per_Qtr")}</div>
              <div className="col-7">{t("Other_Charges_per_Qtr")}</div>
            </div>
            <div className="comparison-paper-spend-data-container">
              <div>
                <div className="comparison-paper-spend-data">
                  <div className="col-1">{t("A4")}</div>
                  <div className="col-2">
                    {paperInclusive.a4CustomerCurrentSpend?.paperWeight
                      ? paperInclusive.a4CustomerCurrentSpend.paperWeight
                          ?.paperWeightName
                      : "-"}
                  </div>
                  <div className="col-3">
                    {paperInclusive.a4CustomerCurrentSpend?.quantityPerReam
                      ?.value || "-"}
                  </div>
                  <div className="col-4">
                    <BlankNumberFormatter
                      decimalPlaces={2}
                      value={
                        paperInclusive.a4CustomerCurrentSpend?.pricePerReam
                          ?.value
                      }
                    />
                  </div>
                  <div className="col-5">
                    {paperInclusive.a4CustomerCurrentSpend
                      ?.totalReamsBoughtPerQuarter.value || "-"}
                  </div>
                </div>
                {paperInclusive.paperInclusiveChargeType.value == "A4A3" && (
                  <div className="comparison-paper-spend-data">
                    <div className="col-1">{t("A3")}</div>
                    <div className="col-2">
                      {paperInclusive.a3CustomerCurrentSpend?.paperWeight
                        ?.paperWeightName || "-"}
                    </div>
                    <div className="col-3">
                      {paperInclusive.a3CustomerCurrentSpend?.quantityPerReam
                        ?.value || "-"}
                    </div>
                    <div className="col-4">
                      <BlankNumberFormatter
                        decimalPlaces={2}
                        value={
                          paperInclusive.a3CustomerCurrentSpend?.pricePerReam
                            ?.value
                        }
                      />
                    </div>
                    <div className="col-5">
                      {paperInclusive.a3CustomerCurrentSpend
                        ?.totalReamsBoughtPerQuarter?.value || "-"}
                    </div>
                  </div>
                )}
              </div>
              <div className="comparison-paper-spend-delivery">
                <div className="col-6">
                  <Format.Number
                    format={process.env.CURRENCY_CODE}
                    value={
                      paperInclusive.a4CustomerCurrentSpend
                        ?.deliveryChargesPerQuarter?.value || 0
                    }
                    decimalPlaces={2}
                  />
                </div>
                <div className="col-7">
                  <Format.Number
                    format={process.env.CURRENCY_CODE}
                    value={
                      paperInclusive.a4CustomerCurrentSpend
                        ?.otherChargesPerQuarter?.value || 0
                    }
                    decimalPlaces={2}
                  />
                </div>
              </div>
            </div>
            <div className="comparison-paper-spend-totals">
              <div>{t("Current_Paper_Spend_Total")} </div>
              <strong>
                <Format.Number
                  format={process.env.CURRENCY_CODE}
                  value={currentSpendTotalAmount || 0}
                  decimalPlaces={2}
                />
              </strong>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const sheetData = getSheetDataState(state);
  const calcData = getCalculationData(state);
  return {
    paperInclusive: sheetData.paperInclusive,
    currentSpendTotalAmount:
      calcData.breakdown.paperInclusiveCosts.currentSpendTotalAmount,
  };
};

export default connect(mapStateToProps)(ComparisonSheetPaperSpend);
