import React, { useEffect } from "react";
import { connect } from "react-redux";
import { updateFilters, setActiveTab } from "./actions";
import { getRatesState } from "../selectors";
import {
  EditableLiquidityChargesTable,
  updateLiquidityCharges,
  getLiquidityChargesByRateGroupId,
  setLiquidityChargesForm,
  getRates,
  getLiquidityChargeGroupFromFilter,
  getDefaultRateGroup,
  RateMatrix,
} from "features/../../../shared/features/Settings";
import RatesFilters from "./RatesFilters";
import RatesActions from "./RatesActions";
import { RateTypeDiscriminator } from "features/../../../shared/constants/enums";
import TabHeaders from "./TabHeaders";
import { useTranslation } from "react-i18next";

const ListRates = ({
  getDefaultRateGroup,
  updateLiquidityCharges,
  getLiquidityChargesByRateGroupId,
  setLiquidityChargesForm,
  liquidityRecords,
  includeIpSyndicateCharge,
  getRates,
  rates,
  filters,
  updateFilters,
  activeTab,
  ratesLoading,
  liquidityLoading,
  setActiveTab,
  dealerOfficeId,
}) => {
  const { t } = useTranslation("supplierOffices", { keyPrefix: "rates" });

  // component did mount
  useEffect(() => {
    setActiveTab(RateTypeDiscriminator.GrossSalesRate);
    getDefaultRateGroup(false, dealerOfficeId, getRatesState);
  }, [setActiveTab, dealerOfficeId, getDefaultRateGroup]);

  const { funderIndex, financeTypeIndex, paymentMethodIndex, fullTitle } =
    getLiquidityChargeGroupFromFilter(liquidityRecords, filters);

  return (
    <React.Fragment>
      <RatesActions
        headerText={t("ratesFor")}
        filters={filters}
        onChange={(newFilters) => updateFilters(newFilters, true)}
        showAllTables={false}
        dealerOfficeId={dealerOfficeId}
      />

      <div className="rates-page">
        <div className="left-column">
          <div className="liquidity-charge-table-container">
            <EditableLiquidityChargesTable
              fullTitle={fullTitle}
              liquidityRecords={liquidityRecords}
              reloadRatesOnFormChange={true}
              includeIpSyndicateCharge={includeIpSyndicateCharge}
              funderIndex={funderIndex}
              financeTypeIndex={financeTypeIndex}
              paymentMethodIndex={paymentMethodIndex}
              onCancel={() => getLiquidityChargesByRateGroupId(getRatesState)}
              loading={liquidityLoading}
              updateLiquidityCharges={updateLiquidityCharges}
              getLiquidityChargesByRateGroupId={
                getLiquidityChargesByRateGroupId
              }
              setLiquidityChargesForm={setLiquidityChargesForm}
              getRatesState={getRatesState}
              getRates={getRates}
            />
          </div>
          <div className="rates-section rates-filters">
            <RatesFilters
              filters={filters}
              onChange={(newFilters) => updateFilters(newFilters, true)}
            />
          </div>
        </div>
        <div className="right-column rates-section">
          <RateMatrix
            activeTab={activeTab}
            rates={rates}
            loading={liquidityLoading || ratesLoading}
            filters={filters}
            headerTabs={<TabHeaders showAllTables={false} />}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const actions = {
  getDefaultRateGroup,
  updateLiquidityCharges,
  getLiquidityChargesByRateGroupId,
  setLiquidityChargesForm,
  updateFilters,
  setActiveTab,
  getRates,
};

const mapStateToProps = (state) => {
  const {
    liquidityCharges,
    rates,
    ratesLoading,
    liquidityLoading,
    filters,
    activeTab,
  } = getRatesState(state);

  const { includeIpSyndicateCharge, liquidityRecords } = liquidityCharges;

  return {
    includeIpSyndicateCharge,
    liquidityRecords,
    rates,
    ratesLoading,
    liquidityLoading,
    filters,
    activeTab,
  };
};

export default connect(mapStateToProps, actions)(ListRates);
