import { apiPut, notifySuccess } from "@redriver/cinnamon";

const ActionTypes = {
  PrincipalPreApproval: "SHEETS/PRINCIPAL_PRE_APPROVAL",
};

export const principalPreApproveSheet = (formData, { sheetId }) =>
  apiPut(
    ActionTypes.PrincipalPreApproval,
    `sheets/${sheetId}/submit/supplier-pre-approval`,
    formData,
  );
