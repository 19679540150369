import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { FormBuilder, Form, ErrorMessage } from "@redriver/cinnamon";
import { SystemRoutes } from "constants/routes";
import { validateSetupToken, requestUserSetup } from "./actions";

const UserSetup = ({ token }) => {
  const { t } = useTranslation("system");
  const [tokenValid, setTokenValid] = useState(null);
  const [setupSuccess, setSetupSuccess] = useState(false);

  if (!token) return <p>{t("No_setup_token_supplied")}</p>;

  if (tokenValid === false) {
    return <p>{t("Setup_token_invalid")}</p>;
  }

  if (setupSuccess) {
    return (
      <div>
        <p>{t("Account_Setup")}</p>
        <div style={{ marginTop: 20 }}>
          <Button
            as={Link}
            to={SystemRoutes.Login}
            className="action-button"
            fluid
          >
            {t("Sign_In")}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <FormBuilder
      loadAction={validateSetupToken}
      loadParams={token}
      onLoaded={(response) => setTokenValid(!!response && response.ok)}
      submitAction={requestUserSetup}
      submitParams={token}
      onSubmitted={() => setSetupSuccess(true)}
      ErrorMessage="error"
      renderLoading={false}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { loading, slowLoading, submitting, slowSubmitting, error },
        events
      ) => {
        if (loading && slowLoading) {
          return <p>{t("Validating_setup_token")}...</p>;
        }
        if (!tokenValid) return null;

        return (
          <Form {...formProps}>
            <Form.Password
              field="newPassword"
              label={t("New_Password")}
              requiredError={t("New_Password") + " " + t("requiredError")}
              placeholder={t("Enter_new_password")}
              disabled={submitting && slowSubmitting}
              autoFocus
              required
              minLength={8}
            >
              <input autoComplete="new-password" />
            </Form.Password>
            <Form.Password
              field="confirmPassword"
              label={t("Confirm_Password")}
              requiredError={t("Confirm_Password") + " " + t("requiredError")}
              placeholder={t("Confirm_new_password")}
              confirmFieldError={
                t("Confirm_Password") + " " + t("does_not_match")
              }
              disabled={submitting && slowSubmitting}
              required
              confirmField="newPassword"
            >
              <input autoComplete="new-password" />
            </Form.Password>
            <Button
              onClick={events.onSubmit}
              disabled={submitting}
              loading={submitting && slowSubmitting}
              className="action-button"
              fluid
            >
              {t("Submit")}
            </Button>
            <ErrorMessage
              header={t("Something_went_wrong")}
              error={error}
              omitCodes
              overrideMessages={{
                913005: t("Password_to_common"),
              }}
            />
          </Form>
        );
      }}
    />
  );
};

UserSetup.propTypes = {
  token: PropTypes.string.isRequired,
};

export default UserSetup;
