export const SheetStateIps = {
  Initialising: 0,
  PreLive: 5,
  PreLive_Draft: 10,
  PreLive_AwaitingSupplierPreApproval: 13,
  PreLive_AwaitingIpsPreApproval: 16,

  PreLive_WithCustomer_Docusign: 20,
  PreLive_WithCustomer_Docusign_Waiting: 21,
  PreLive_WithCustomer_Docusign_Checking: 22,
  PreLive_WithCustomer_Docusign_StartingTimeoutTimer: 23,

  PreLive_WithSupplierAdmin_Docusign: 24,
  PreLive_WithSupplierAdmin_Docusign_Waiting: 25,
  PreLive_WithSupplierAdmin_Docusign_Checking: 26,

  PreLive_WithSupplierSignatory_Docusign: 27,
  PreLive_WithSupplierSignatory_Docusign_Waiting: 28,
  PreLive_WithSupplierSignatory_Docusign_Checking: 29,

  PreLive_WithIPSAdmin_Docusign: 30,
  PreLive_WithIPSAdmin_Docusign_Waiting: 31,
  PreLive_WithIPSAdmin_Docusign_Checking: 32,

  PreLive_WithIPSSignatory_Docusign: 33,
  PreLive_WithIPSSignatory_Docusign_Waiting: 34,
  PreLive_WithIPSSignatory_Docusign_Checking: 35,

  PreLive_AwaitingDealerAdminApproval: 40,
  PreLive_AwaitingIpsApproval: 41,
  PreLive_AwaitingIpsApproval_ScheduleNotReady: 42,
  PreLive_AwaitingIpsApproval_ScheduleReady: 45,
  PreLive_AwaitingIpsApproval_ScheduleReady_Complete: 46,
  PreLive_AwaitingIpsApproval_ScheduleReady_Waived: 47,

  PreLive_IPS_Approved: 60,
  Live: 70,
  Retired: 80,
  Voided: 90,
};

export const SheetStatePrincipal = {
  Initialising: 0,
  PreLive: 5,
  PreLive_Draft: 10,
  PreLive_AwaitingSupplierPreApproval: 13,

  PreLive_WithCustomer_Docusign: 20,
  PreLive_WithCustomer_Docusign_Waiting: 21,
  PreLive_WithCustomer_Docusign_Checking: 22,
  PreLive_WithCustomer_Docusign_StartingTimeoutTimer: 23,

  PreLive_WithPrincipalAdmin_Docusign: 24,
  PreLive_WithPrincipalAdmin_Docusign_Waiting: 25,
  PreLive_WithPrincipalAdmin_Docusign_Checking: 26,

  PreLive_WithPrincipalSignatory_Docusign: 27,
  PreLive_WithPrincipalSignatory_Docusign_Waiting: 28,
  PreLive_WithPrincipalSignatory_Docusign_Checking: 29,

  PreLive_CollatingFunderPack_Docusign: 30,
  PreLive_CollatingFunderPack_Docusign_Waiting: 31,
  PreLive_CollatingFunderPack_Docusign_Checking: 32,

  PreLive_WithFunder_Docusign: 33,
  PreLive_WithFunder_Docusign_Waiting: 34,
  PreLive_WithFunder_Docusign_Checking: 35,

  PreLive_AwaitingDealerAdminApproval: 40,
  PreLive_AwaitingIpsApproval: 41,
  PreLive_AwaitingIpsApproval_ScheduleNotReady: 42,
  PreLive_AwaitingIpsApproval_ScheduleReady: 45,
  PreLive_AwaitingIpsApproval_ScheduleReady_Complete: 46,
  PreLive_AwaitingIpsApproval_ScheduleReady_Waived: 47,

  PreLive_FunderApproved: 60,
  Live: 70,
  Retired: 80,
  Voided: 90,
};

export const PhysicalDocumentSheetStatePrincipal = {
  Draft: 0,
  SubmittedToPrincipalAdmin: 1,
  SentToFunder: 2,
  DealRejected: 3,
  Live: 4,
};
