import React from "react";
import { NavMenu } from "@redriver/cinnamon";
import { withRouter } from "react-router-dom";
import { ClientRoutes } from "constants/routes";
import { withCurrentUser } from "features/../../../shared/components/auth";
import { useTranslation } from "react-i18next";

const ClientMenu = ({ clientId }) => {
  const { t } = useTranslation();
  return (
    <NavMenu secondary className="secondary">
      <NavMenu.Item exact link={ClientRoutes.Overview(clientId)}>
        {t("clients:sideMenu:overview")}
      </NavMenu.Item>
      {/*
      <NavMenu.Item link={ClientRoutes.Sheets(clientId)}>
        {t("clients:sideMenu:sheets")}
      </NavMenu.Item>
  */}
      <NavMenu.Item link={ClientRoutes.AuthorisedSignatories(clientId)}>
        {t("clients:sideMenu:authorisedSignatories")}
      </NavMenu.Item>
    </NavMenu>
  );
};

export default withRouter(withCurrentUser(ClientMenu));
