import React from "react";
import { Form, useFormField } from "@redriver/cinnamon";
import LargeFormatMeterItem from "./LargeFormatMeterItem";

const LargeFormatPaperMeterArray = ({
  setEditModalTrigger,
  setLargeFormatEditIndex,
  hideFixedIndicator,
  printerName,
  groupArrayIndex,
}) => {
  const [paperMeters, setPaperMeters] = useFormField("paperMeters");

  const setMeterField = (updatedMeter) => {
    const otherMeters = paperMeters.filter((pm) => pm.id != updatedMeter.id);
    const newValue = otherMeters.concat(updatedMeter);
    setPaperMeters(newValue);
  };

  return (
    <Form.Array
      field="paperMeters"
      arrayKey="id"
      propagateUpdates="always"
      fluid
    >
      {({ fields }) => {
        const unitInfo = fields.paperWidth
          ? fields.paperWidth + " " + fields.paperInclusiveName
          : fields.paperInclusiveName;

        return (
          <React.Fragment>
            <LargeFormatMeterItem
              meterField={fields}
              setEditModalTrigger={setEditModalTrigger}
              setLargeFormatEditIndex={setLargeFormatEditIndex}
              hideFixedIndicator={hideFixedIndicator}
              unitInfo={unitInfo}
              printerName={printerName}
              arrayIndex={groupArrayIndex}
              setMeterField={setMeterField}
              disableCpiWarningMessages={true}
            />
          </React.Fragment>
        );
      }}
    </Form.Array>
  );
};

export default LargeFormatPaperMeterArray;
