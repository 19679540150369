import React from "react";
import { Form } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";

const ClientAuthorisedSignatoryForm = props => {
  const { t } = useTranslation();
  return (
    <Form {...props}>
      <Form.Input
        field="name"
        label={t(
          "clients:tables:authorisedSignatories:newAuthorisedSignatory:name:label"
        )}
        fluid
        required
        placeholder={t(
          "clients:tables:authorisedSignatories:newAuthorisedSignatory:name:placeholder"
        )}
      />
      <Form.Email
        field="email"
        label={t(
          "clients:tables:authorisedSignatories:newAuthorisedSignatory:email:label"
        )}
        fluid
        required
        placeholder={t(
          "clients:tables:authorisedSignatories:newAuthorisedSignatory:email:placeholder"
        )}
      />
      <Form.Input
        field="position"
        label={t(
          "clients:tables:authorisedSignatories:newAuthorisedSignatory:position:label"
        )}
        fluid
        required
        placeholder={t("clients:tables:authorisedSignatories:noData")}
      />
      <Form.Input
        field="phoneNumber"
        label={t(
          "clients:tables:authorisedSignatories:newAuthorisedSignatory:phoneNumber:label"
        )}
        fluid
        required
        placeholder={t(
          "clients:tables:authorisedSignatories:newAuthorisedSignatory:phoneNumber:placeholder"
        )}
      />
      <Form.Input
        field="phoneCountryCode"
        label={t(
          "clients:tables:authorisedSignatories:newAuthorisedSignatory:phoneCountryCode:label"
        )}
        fluid
        required
        placeholder={t(
          "clients:tables:authorisedSignatories:newAuthorisedSignatory:phoneCountryCode:placeholder"
        )}
      />
    </Form>
  );
};

export default ClientAuthorisedSignatoryForm;
