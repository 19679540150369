import React from "react";
import { Table } from "@redriver/cinnamon";
import { withPermissions } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";

import RemoveServiceModal from "./RemoveServiceModal";
import EditServiceModal from "./EditServiceModal";

const ServiceActions = ({
  service,
  onRefresh,
  deleteAction,
  editAction,
  hasPermission
}) => {
  const canDelete = hasPermission(Targets.Service, Actions.Delete);
  const canEdit = hasPermission(Targets.Service, Actions.Edit);

  if (!canDelete) return null;

  return (
    <Table.Menu>
      {canEdit && (
        <EditServiceModal
          service={service}
          onSubmitted={onRefresh}
          submitAction={editAction}
        />
      )}
      {canDelete && (
        <RemoveServiceModal
          service={service}
          onSubmitted={onRefresh}
          submitAction={deleteAction}
        />
      )}
    </Table.Menu>
  );
};

export default withPermissions(ServiceActions);
