import { apiGet, apiPut } from "@redriver/cinnamon";
import { produce } from "immer";
import { debounce } from "lodash";

const NAMESPACE = "SETTINGS/FINANCIAL/RATES";

export const ActionTypes = {
  GetDefaultRateGroup: `${NAMESPACE}/GET_DEFAULT_RATE_GROUP`,
  GetYieldRates: `${NAMESPACE}/GET_YIELD_RATES`,
  SetYieldRatesForm: `${NAMESPACE}/SET_YIELD_RATES_FORM`,
  UpdateYieldRates: `${NAMESPACE}/UPDATE_YIELD_RATES`,
  GetLiquidityCharges: `${NAMESPACE}/GET_LIQUIDITY_CHARGES`,
  DraftUpdateLiquidityCharges: `${NAMESPACE}/DRAFT_UPDATE_LIQUIDITY_CHARGES`,
  UpdateLiquidityCharges: `${NAMESPACE}/UPDATE_LIQUIDITY_CHARGES`,
  GetRates: `${NAMESPACE}/GET_RATES`,
  GetRateGroupDetails: `${NAMESPACE}/GET_RATE_GROUP`,
  UpdateRateGroupDetails: `${NAMESPACE}/UPDATE_RATE_GROUP`,
  GetRateTable: `${NAMESPACE}/GET_RATE_TABLE`,
  UpdateRateTable: `${NAMESPACE}/UPDATE_RATE_TABLE`,
};

export const getRateGroupDetails = ({ rateGroupId, funderType, financeType, paymentMethod }) =>
{
  const request = {funderType, financeType, paymentMethod};
  return apiGet(
    ActionTypes.GetRateGroupDetails,
    `defaults/rates/${rateGroupId}/details`, request
  );
}

export const updateRateGroupDetails = (data, { rateGroupId, funderType, financeType, paymentMethod }) =>
{
  const request = {...data, funderType, financeType, paymentMethod};
  return apiPut(
    ActionTypes.UpdateRateGroupDetails,
    `defaults/rates/${rateGroupId}/details`,
    request
  );
} 

export const getRateTable = ({ rateGroupId, funderType, financeType, paymentMethod }) =>
{
  const request = {funderType, financeType, paymentMethod};
  return apiGet(
    ActionTypes.GetRateTable,
    `defaults/rates/${rateGroupId}/rate-table`, request
  );
}

export const updateRateTable = (data, { rateGroupId, funderType, financeType, paymentMethod }) =>
{
  const request = {...data, funderType, financeType, paymentMethod};
  return apiPut(
    ActionTypes.UpdateRateTable,
    `defaults/rates/${rateGroupId}/rate-table`, request
  );
} 


// Rate Groups
export const getDefaultRateGroup =
  (fetchYieldRates, dealerOfficeId, getRatesState) => async dispatch => {
    const res = await dispatch(
      apiGet(ActionTypes.GetDefaultRateGroup, `defaults/rate-group`, {
        dealerOfficeId,
      })
    );

    if (fetchYieldRates) {
      const yields = await dispatch(getYieldRates(getRatesState));
      return {
        rateGroupId: res.response.value,
        lockedById: yields.response.lockedById,
        lockedByName: yields.response.lockedByName,
      };
    } else {
      await dispatch(getLiquidityChargesByRateGroupId(getRatesState));
      return await dispatch(getRates(getRatesState));
    }
  };

// Yields

export const setYieldRatesForm =
  (
    getRatesState,
    field,
    data,
    applyChanges,
    funderIndex,
    financeTypeIndex,
    paymentMethodIndex
  ) =>
  (dispatch, getState) => {
    // data for example: { field: "quarter_4_value_0", data: "22"}
    var key = data.field;
    var value = parseFloat(data.data);
    var quarter = parseInt(
      key.replace(/quarter_/i, "").replace(/_column.*/, "")
    );
    var rowIndex = parseInt(key.replace(/.*_column_/i, ""));

    const { filters, rates } = getRatesState(getState());
    const yieldRates = produce(rates.yieldRates, draftState => {
      const yieldTableToUpdate =
        draftState[funderIndex].yields[financeTypeIndex].yields[
          paymentMethodIndex
        ];

      var matchedRow = yieldTableToUpdate.rows.find(
        x => x.values[1] == quarter
      );

      matchedRow.values[rowIndex + 2] = isNaN(value) ? null : value; // Index is 3 off due to columns "Months", "Quarters", "FV"
        
    });

    dispatch({ type: ActionTypes.SetYieldRatesForm, yieldRates });
  };

export const getYieldRates = getRatesState => (dispatch, getState) => {
  const { filters } = getRatesState(getState());
  return dispatch(
    apiGet(
      ActionTypes.GetYieldRates,
      `defaults/yield-rates/${filters.rateGroupId}`
    )
  );
};

export const saveYieldRatesAsync =
  (yields, rateGroupId, callback) => async (dispatch, getState) => {
    await dispatch(
      apiPut(
        ActionTypes.UpdateYieldRates,
        `defaults/yield-rates/${rateGroupId}`,
        yields
      )
    );
    callback();
  };

export const saveYieldRates = (yields, rateGroupId) => (dispatch, getState) => {
  return dispatch(
    apiPut(
      ActionTypes.UpdateYieldRates,
      `defaults/yield-rates/${rateGroupId}`,
      yields
    )
  );
};

// Liquidity Charges

export const getLiquidityChargesByRateGroupId =
  getRatesState => (dispatch, getState, rateGroupId) => {
    rateGroupId = rateGroupId || getRatesState(getState()).filters.rateGroupId;

    return dispatch(
      apiGet(
        ActionTypes.GetLiquidityCharges,
        `defaults/rate-group/liquidity-charges/${rateGroupId}`
      )
    );
  };

export const getLiquidityChargesUsingRateGroupId = rateGroupId => dispatch => {
  return dispatch(
    apiGet(
      ActionTypes.GetLiquidityCharges,
      `defaults/rate-group/liquidity-charges/${rateGroupId}`
    )
  );
};

export const updateLiquidityCharges =
  (settingsId, data, getRatesState, rateGroupId) =>
  async (dispatch, getState) => {
    rateGroupId = rateGroupId || getRatesState(getState()).filters.rateGroupId;
    var res = await dispatch(
      apiPut(
        ActionTypes.UpdateLiquidityCharges,
        settingsId
          ? `defaults/settings/liquidity-charges/${settingsId}`
          : `defaults/rate-group/liquidity-charges/${rateGroupId}`,
        data
      )
    );

    if (res.success) {
      if (settingsId) {
        return dispatch(getLiquidityChargesBySettingsId(settingsId));
      } else {
        await dispatch(getLiquidityChargesByRateGroupId(getRatesState));
        dispatch(getRates(getRatesState));
        return res;
      }
    } else {
      return res;
    }
  };

const debouncedSetLiquidityChargesForm = debounce(
  (
    dispatch,
    field,
    data,
    applyChanges,
    funderIndex,
    financeTypeIndex,
    paymentMethodIndex,
    updateRates
  ) =>
    dispatch(
      updateRates
        ? setLiquidityChargesFormAndUpdateRates(
            dispatch,
            field,
            data,
            applyChanges,
            funderIndex,
            financeTypeIndex,
            paymentMethodIndex
          )
        : setLiquidityChargesFormInternal(
            dispatch,
            field,
            data,
            applyChanges,
            funderIndex,
            financeTypeIndex,
            paymentMethodIndex
          )
    ),
  500
);

export const setLiquidityChargesForm =
  (
    field,
    data,
    applyChanges,
    funderIndex,
    financeTypeIndex,
    paymentMethodIndex,
    updateRates
  ) =>
  (dispatch, getState) => {
    return debouncedSetLiquidityChargesForm(
      dispatch,
      field,
      data,
      applyChanges,
      funderIndex,
      financeTypeIndex,
      paymentMethodIndex,
      updateRates
    );
  };

const setLiquidityChargesFormAndUpdateRates = async (
  dispatch,
  field,
  data,
  applyChanges,
  funderIndex,
  financeTypeIndex,
  paymentMethodIndex
) => {
  await dispatch({
    type: ActionTypes.DraftUpdateLiquidityCharges,
    field,
    data,
    applyChanges,
    funderIndex,
    financeTypeIndex,
    paymentMethodIndex,
  });
  return dispatch(getRates());
};

const setLiquidityChargesFormInternal = (
  dispatch,
  field,
  data,
  applyChanges,
  funderIndex,
  financeTypeIndex,
  paymentMethodIndex
) =>
  dispatch({
    type: ActionTypes.DraftUpdateLiquidityCharges,
    field,
    data,
    applyChanges,
    funderIndex,
    financeTypeIndex,
    paymentMethodIndex,
  });

export const getLiquidityChargeGroupFromFilter = (
  liquidityRecords,
  filters
) => {
  let funderIndex,
    funderGroup = null;
  let financeTypeIndex,
    financeGroup = null;
  let paymentMethodIndex,
    paymentMethodGroup = null;
  let fullTitle = "";

  const hasData =
    !!(Array.isArray(liquidityRecords) && liquidityRecords.length) &&
    filters != null;

  if (hasData) {
    funderGroup = liquidityRecords.find(x => x.key === filters.funderType);
    funderIndex = liquidityRecords.indexOf(funderGroup);
    financeGroup = funderGroup.liquidityRecords.find(
      x => x.key === filters.financeType
    );
    financeTypeIndex = funderGroup.liquidityRecords.indexOf(financeGroup);
    paymentMethodGroup = financeGroup.liquidityRecords.find(
      x => x.paymentMethod === filters.paymentMethod
    );
    paymentMethodIndex =
      financeGroup.liquidityRecords.indexOf(paymentMethodGroup);

    fullTitle = `${funderGroup.title} ${financeGroup.title} ${paymentMethodGroup.title}`;
  }

  return {
    funderGroup,
    funderIndex,
    financeGroup,
    financeTypeIndex,
    paymentMethodIndex,
    paymentMethodGroup,
    fullTitle,
  };
};

// Rates
export const getRates =
  (getRatesState, liquidityRecords) => async (dispatch, getState) => {
    const { filters } = getRatesState(getState(getRatesState));

    if (!filters.rateGroupId || !liquidityRecords || !liquidityRecords.values)
      return null;

      const funderType = filters.funderType ? filters.funderType : "Funder";
      const financeType = filters.financeType ? filters.financeType : "EFA";
      const paymentMethod = filters.paymentMethod ? filters.paymentMethod : "QuarterlyDirectDebit";

      const data = {funderType, financeType, paymentMethod, liquidityCharges: liquidityRecords && liquidityRecords.values};    
    return await dispatch(
      apiPut(ActionTypes.GetRates, `defaults/rates/${filters.rateGroupId}`, data)
    );
  };
