import {
    apiGet,
    apiPut,
    apiPost,
    apiDelete,
    mockSuccess,
    MockArrayData
} from "@redriver/cinnamon";

const NAMESPACE = "DEALERS";

export const ActionTypes = {
    GetDealerOfficeHeaderInfo: `${NAMESPACE}/GET_DEALER_OFFICE_HEADER_INFO`,
};

export const getDealerOfficeHeaderInfo = (params) =>
    apiGet(ActionTypes.GetDealerOfficeHeaderInfo, `dealer-offices/header-info`, params);
