
export const PaperInclusiveChargeTypeText = (t) =>({  
  A4: t("enums:PaperInclusiveChargeType:A4"),
  A4A3: t("enums:PaperInclusiveChargeType:A4A3")
});

export const PaperInclusiveChargeType = {
  A4: "A4",
  A4A3: "A4A3"
};

