import { round, isNil } from "lodash";

export const roundUpTo2DecimalPlaces = (input) => {
  if (isNil(input) || isNaN(input)) {
    return 0.0;
  }
  return round(input + 0.004999, 2);
};

const getNormalisedLfGroups = (lfGroups) => {
  let normalisedGroups = [];
  for (let i = 0; i < (lfGroups || []).length; i++) {
    let group = lfGroups[i];
    let items = [];

    if (group.printerMeter) {
      items.push(group.printerMeter);
    }

    if (group.otherMeter) {
      items.push(group.otherMeter);
    }

    if (group.scanMeter) {
      items.push(group.scanMeter);
    }

    if (Array.isArray(group.paperMeters)) {
      for (let x = 0; x < (group.paperMeters || []).length; x++) {
        items.push(group.paperMeters[x]);
      }
    }
    normalisedGroups.push({ ...group, items });
  }

  return normalisedGroups;
};

export const calculateComparisonSheetData = (sheetData, calcData) => {
  if (!sheetData) {
    return null;
  }

  const scheduleItems = sheetData.systemScheduleItems;
  const lfGroups = getNormalisedLfGroups(sheetData.largeFormatGroups);
  const preSnapshotScheduleItems = sheetData.preSnapshotSystemScheduleItems;
  const preSnapshotLfGroups = getNormalisedLfGroups(
    sheetData.preSnapshotLargeFormatGroups,
  );
  const currentPaperSpendTotal =
    calcData != null
      ? calcData.breakdown.paperInclusiveCosts.currentSpendTotalAmount
      : 0;

  if (!Array.isArray(scheduleItems)) return null;
  const isValidVolume = (input) =>
    !isNaN(input) && !isNil(input) && !isNaN(parseInt(input));

  const calculateComparisonValues = (
    scheduleItem,
    useOldCostPerUnit,
    isLargeFormat,
  ) => {
    const si = scheduleItem;

    const minQuarterly = useOldCostPerUnit
      ? isValidVolume(si.oldMinQuarterly)
        ? parseInt(si.oldMinQuarterly)
        : isLargeFormat
        ? parseInt(si.minQuarterlyVolume.value)
        : parseInt(si.minQuarterly.value)
      : isLargeFormat
      ? parseInt(si.minQuarterlyVolume.value)
      : parseInt(si.minQuarterly.value);

    const costPerUnit =
      useOldCostPerUnit && si.oldCostPerUnit
        ? parseFloat(si.oldCostPerUnit)
        : parseFloat(si.costPerUnit);
    const predictedVolume = parseInt(si.predictedVolume);
    const additional = isValidVolume(predictedVolume)
      ? predictedVolume - minQuarterly
      : 0;
    const total = roundUpTo2DecimalPlaces(
      (isValidVolume(si.predictedVolume)
        ? Math.max(parseInt(si.predictedVolume) || 0, minQuarterly)
        : minQuarterly) *
        (process.env.USE_MINOR_CURRENCY ? costPerUnit / 100 : costPerUnit),
    );

    const displayCode = si.displayCode;

    const changeToCostPerClick =
      (costPerUnit || 0) - (parseFloat(si.oldCostPerUnit) || 0);

    //if large format use largeFormatMeteredServiceName else use unitName (Standard Metered Service)
    const itemDescription = si.paperInclusiveName
      ? si.paperInclusiveName
      : si.largeFormatMeteredServiceName
      ? si.largeFormatMeteredServiceId
        ? si.largeFormatMeteredServiceName
        : `${si.paperWidth} ${si.paperInclusiveName}`
      : si.unit.unitName;

    return {
      id: si.id,
      minQuarterly,
      costPerUnit,
      predictedVolume,
      additional,
      changeToCostPerClick,
      total,
      displayCode,
      itemDescription,
    };
  };

  const scheduleItemTotal = (scheduleItems || []).reduce(
    (prev, curr) =>
      (prev || 0) + calculateComparisonValues(curr, false, false).total,
    0,
  );

  const oldScheduleItemTotal = (preSnapshotScheduleItems || []).reduce(
    (prev, curr) =>
      (prev || 0) + calculateComparisonValues(curr, true, false).total,
    0,
  );
  let lfTotal = 0;
  lfGroups.forEach((lfsi) => {
    lfsi.items &&
      lfsi.items.forEach((item) => {
        lfTotal += calculateComparisonValues(item, false, true).total;
      });
  });
  let oldLfTotal = 0;
  preSnapshotLfGroups.forEach((lfsi) => {
    lfsi.items &&
      lfsi.items.forEach((item) => {
        oldLfTotal += calculateComparisonValues(item, true, true).total;
      });
  });

  const comparisonItems = (scheduleItems || []).map((si) =>
    calculateComparisonValues(si, false, false),
  );
  const lfComparisonItems = (lfGroups || [])
    .map((lf) =>
      (lf.items || []).map((si) => calculateComparisonValues(si, false, true)),
    )
    .flat();

  const newTotal = scheduleItemTotal + lfTotal;
  const oldTotal = oldScheduleItemTotal + oldLfTotal + currentPaperSpendTotal;

  return {
    priceDifference: newTotal - oldTotal,
    items: comparisonItems.concat(lfComparisonItems),
    total: newTotal,
    oldTotal: oldTotal,
  };
};
