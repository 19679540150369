import React from "react";
import { useTranslation } from "react-i18next";
import CalcBreakdown from "./CalcBreakdown";
import {
  RatesAndSettingsGrid,
  CapitalInvestmentGrid,
  SettlementsGrid,
  YieldInformationGrid,
  CoTerminusGrid,
  CapitalTotalGrid,
  CfaSplitChargeGrid,
  TotalIPSIncomeGrid,
  FunderPayoutGrid,
} from "./Grids";
import { numericFormatter } from "components/displays/DisplayHelper";
const DealerCFA = ({ breakdown }) => {
  const { t } = useTranslation("capitalCalc", {
    keyPrefix: "infoModal.funderTypes",
  });

  return (
    <div className="funder-type-section supplier">
      <h4>{t("supplierTitle")}</h4>

      <div className="summary">
        <RatesAndSettingsGrid info={breakdown.ratesAndSettings} />
        <CapitalInvestmentGrid
          data={breakdown.capitalInvestment}
          funderType={breakdown.funderType}
        />
        <SettlementsGrid
          data={breakdown.settlementsGrid}
          funderType={breakdown.funderType}
        />
      </div>
      <div className="summary-footer">
        <div></div>
        <CapitalTotalGrid data={breakdown} funderType={breakdown.funderType} />
      </div>

      <CalcBreakdown
        data={breakdown.capitalCalcCalcBreakdown}
        funderType={breakdown.funderType}
      />

      <div className="yield-grid">
        <div></div>
        <div>
          {t("details.yieldUsed")}
          <b> {numericFormatter(breakdown.yieldUsed, 0, 3, "", "%")}</b>
        </div>
        <div>
          {t("details.ipsOfYield")}
          <b>
            {" "}
            {numericFormatter(
              breakdown.ipSyndicatePercentageYield,
              0,
              3,
              "",
              "%"
            )}
          </b>
        </div>
        <div>
          {t("details.ipsPercentage")}
          <b>
            {" "}
            {numericFormatter(breakdown.ipSyndicatePercentage, 0, 3, "", "%")}
          </b>
        </div>
        <div>
          {t("details.supplierPercentage")}
          <b> {numericFormatter(breakdown.dealerPercentage, 0, 3, "", "%")}</b>
        </div>
        <div></div>
      </div>
      <div className="charges-wrapper">
        <div className="charges">
          <div className="cfa-yield-container">
            <FunderPayoutGrid
              data={breakdown.payoutGrid}
              funderType={breakdown.funderType}
            />
            <div></div>
            <div></div>
            <br />
          </div>
          <div className="charges-right-container dealer">
            <div></div>
            <div>
              <TotalIPSIncomeGrid
                ipSyndicateRentalIncome={breakdown.ipSyndicateRentalIncome}
              />
            </div>
            <div>
              <CfaSplitChargeGrid data={breakdown.cfaChargeSplitGrid} />
              <br />
              <YieldInformationGrid data={breakdown.yieldInformationGrid} />
            </div>
            <div>
              <CoTerminusGrid
                info={breakdown.coTerminusGrid}
                funderType={breakdown.funderType}
              />{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealerCFA;
