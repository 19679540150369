import React, { useMemo } from "react";
import { connect } from "react-redux";
import { Form, Format, getAuthClaim } from "@redriver/cinnamon";
import classNames from "classnames";
import {
  FixedInput,
  FixedBoolean,
  SectionTotal,
  FixedInputResponsiveLabel,
} from "components/forms";
import {
  FunderType,
  UserType,
  SheetViewType,
} from "features/../../../shared/constants/enums";
import FundingSection from "./FundingSection";
import SheetStep from "../SheetStep";
import {
  getSheetCapitalState,
  getCalculationData,
  getSheetState,
} from "../../selectors";
import { canSheetBeUpdated } from "../helpers";
import { cannotCalcMessages, canCalculate } from "../actions";
import { FunderTypeAvailability } from "features/../../../shared/constants/enums/FunderType";
import { useTranslation } from "react-i18next";
import {
  UserTypeCheck,
  withPermissions,
} from "features/../../../shared/components/auth";
import { CapitalInfoModal } from "./CapitalInfoModal";
import { Actions, Targets } from "constants/permissions";

const CapitalStep = ({
  sheetId,
  sheetData,
  onNextStep,
  onPreviousStep,
  calculating,
  missingYieldBands,
  sheetDetailsSaving,
  preventUpdates,
  calcBreakdown,
  calcHeader,
  cofundedTotalCosts,
  funderTotalCosts,
  dealerTotalCosts,
  cofundedMaxProductCapitalAllowed,
  funderMaxProductCapitalAllowed,
  dealerMaxProductCapitalAllowed,
  rrpRequired,
  screenSize,
  displayPrevious,
  displayNext,
  displaySave,
  collapsed,
  toggleVisibility,
  reactRef,
  coFundedSettlementLength,
  funderSettlementLength,
  dealerSettlementLength,
  coFundedAdminSettlementLength,
  funderAdminSettlementLength,
  dealerAdminSettlementLength,
  coFundedIpSettlementLength,
  funderIpSettlementLength,
  dealerIpSettlementLength,
  fundersSelected,
  funderSelected,
  cofundedSelected,
  dealerSelected,
  volumeSelectionValue,
  userType,
  userArea,
  sheetCanBeUpdated,
  customerToggle,
  sheetViewType,
  calculationData,
  displayCustomerToggle = false,
  impersonatedUser,
  coFundedIpCoTerminusRentalsLength,
  funderIpCoTerminusRentalsLength,
  dealerIpCoTerminusRentalsLength,
  coFundedCoTerminusRentalsLength,
  funderCoTerminusRentalsLength,
  dealerCoTerminusRentalsLength,
  funderSettings,
  dealerFundedSettings,
  coFundedSettings,
  funderSettlementsTotal,
  funderAdminSettlementsTotal,
  funderIpSettlementsTotal,
  dealerSettlementsTotal,
  dealerAdminSettlementsTotal,
  dealerIpSettlementsTotal,
  cofundedSettlementsTotal,
  cofundedAdminSettlementsTotal,
  cofundedIpSettlementsTotal,
  volumeSelection_Minimum, // VMin
  volumeSelection_Maximum, // VMax
  defaultVolumeSelectionValue, // Default
  minimumVolumeSelectionValue, // Min
  maximumVolumeSelectionValue, // Max
  hasPermission,
}) => {
  const { t } = useTranslation("capitalCalc");

  const canCalculateSheet = useMemo(
    () => canCalculate(sheetData, calculationData),
    [sheetData, calculationData],
  );

  const hideFixedIndicator = !impersonatedUser.canEditAdminFields;

  //update calcBreakdown
  calcBreakdown = {
    ...calcBreakdown,
    totalDevices: sheetData.capital.totalDevicesOnSchedule.value,
    calcHeader: {
      ...calcBreakdown.calcHeader,
      sheetType: sheetData.rates.sheetType.name,
    },
  };

  //funderSettings
  const funderAvailable = useMemo(
    () =>
      funderSettings.available &&
      (sheetViewType === SheetViewType.IpsAdmin ||
        funderSettings.availability === FunderTypeAvailability.All ||
        (funderSettings.availability ===
          FunderTypeAvailability.IPSSupplierAdmin &&
          (sheetViewType === SheetViewType.IpsAdmin ||
            sheetViewType === SheetViewType.SupplierAdmin))),
    [funderSettings, sheetViewType],
  );

  const dealerFundedAvailable = useMemo(
    () =>
      dealerFundedSettings.available &&
      (sheetViewType === SheetViewType.IpsAdmin ||
        dealerFundedSettings.availability === FunderTypeAvailability.All ||
        (dealerFundedSettings.availability ===
          FunderTypeAvailability.IPSSupplierAdmin &&
          (sheetViewType === SheetViewType.IpsAdmin ||
            sheetViewType === SheetViewType.SupplierAdmin))),
    [dealerFundedSettings, sheetViewType],
  );

  const coFundedAvailable = useMemo(
    () =>
      coFundedSettings.available &&
      (sheetViewType === SheetViewType.IpsAdmin ||
        coFundedSettings.availability === FunderTypeAvailability.All ||
        (coFundedSettings.availability ===
          FunderTypeAvailability.IPSSupplierAdmin &&
          (sheetViewType === SheetViewType.IpsAdmin ||
            sheetViewType === SheetViewType.SupplierAdmin))),
    [coFundedSettings, sheetViewType],
  );

  //calculate funder totals
  let funderTotal = useMemo(
    () => (funderSelected ? funderSettlementsTotal + funderTotalCosts : 0),
    [funderSelected, funderSettlementsTotal, funderTotalCosts],
  );

  let dealerTotal = useMemo(
    () => (dealerSelected ? dealerSettlementsTotal + dealerTotalCosts : 0),
    [dealerSelected, dealerSettlementsTotal, dealerTotalCosts],
  );

  let cofundedTotal = useMemo(
    () =>
      cofundedSelected ? cofundedSettlementsTotal + cofundedTotalCosts : 0,
    [cofundedSelected, cofundedSettlementsTotal, cofundedTotalCosts],
  );

  if (!sheetId || !hasPermission(Targets.Sheet, Actions.ViewCapitalCalc)) {
    return null;
  }

  if (sheetViewType === SheetViewType.IpsAdmin) {
    funderTotal += funderSelected
      ? funderAdminSettlementsTotal + funderIpSettlementsTotal
      : 0;
    dealerTotal += dealerSelected
      ? dealerAdminSettlementsTotal + dealerIpSettlementsTotal
      : 0;
    cofundedTotal += cofundedSelected
      ? cofundedAdminSettlementsTotal + cofundedIpSettlementsTotal
      : 0;
  }

  if (sheetViewType === SheetViewType.SupplierAdmin) {
    funderTotal += funderSelected ? funderAdminSettlementsTotal : 0;
    dealerTotal += dealerSelected ? dealerAdminSettlementsTotal : 0;
    cofundedTotal += cofundedSelected ? cofundedAdminSettlementsTotal : 0;
  }

  const calcInfoModal = (
    <UserTypeCheck restrictTo={UserType.HQ}>
      {sheetViewType === SheetViewType.IpsAdmin && (
        <React.Fragment>
          <CapitalInfoModal
            sheetId={sheetId}
            calcBreakdown={calcBreakdown}
            data={calcBreakdown.adminInfoReport}
            calculating={calculating}
            canCalculateSheet={canCalculateSheet}
            calcHeader={calcHeader}
            funderFundingAmount={funderTotal}
            dealerFundingAmount={dealerTotal}
            cofundedFundingAmount={cofundedTotal}
            funderFundingLabel={t("fundingSection.funder")}
            dealerFundingLabel={t("fundingSection.supplier")}
            cofundedFundingLabel={t("fundingSection.coFunded")}
            sheetCanBeUpdated={sheetCanBeUpdated}
            sheetViewType={sheetViewType}
          />
        </React.Fragment>
      )}
    </UserTypeCheck>
  );
  return (
    <div ref={reactRef} className="step-container">
      <SheetStep
        title={t("title")}
        headerActionBar={calcInfoModal}
        onNextStep={onNextStep}
        onPreviousStep={onPreviousStep}
        displayPrevious={displayPrevious}
        displayNext={displayNext}
        displaySave={displaySave}
        className={classNames("capital-step", collapsed && "collapsed")}
        collapsed={collapsed}
        toggleVisibility={toggleVisibility}
        calculating={calculating}
        sheetId={sheetId}
        showCapitalError={true}
        displayCustomerToggle={displayCustomerToggle}
        customerToggle={customerToggle}
        errorMessage={cannotCalcMessages.capital}
        missingYieldBands={missingYieldBands}
      >
        <Form.Object field="capital" propagateUpdates="always">
          <div className="overall-values">
            <FixedInput
              label={
                <FixedInputResponsiveLabel
                  label={t("volumeSelection")}
                  required
                  className="lowered"
                />
              }
              field="volumeSelection"
              fluid
              formatNumber
              placeholder={t("volumeSelectionPlaceholder")}
              className="volume-selection small"
              disabled={
                sheetDetailsSaving ||
                preventUpdates ||
                (userType !== UserType.HQ &&
                  sheetData &&
                  sheetData.restrictions &&
                  !sheetData.restrictions.allowVolumeSelection)
              }
              required
              hideIndicator={hideFixedIndicator}
              minAllowValue={
                userType == UserType.HQ
                  ? volumeSelection_Minimum
                  : minimumVolumeSelectionValue
              }
              maxAllowValue={
                userType == UserType.HQ
                  ? volumeSelection_Maximum
                  : maximumVolumeSelectionValue
              }
              defaultValue={defaultVolumeSelectionValue}
            />
            <Form.Group className="funder-types">
              <div className="funder-type-label">
                <p className="field-text">{t("funderTypes")}</p>{" "}
                <span className="required-star">*</span>
              </div>
              <div className="funder-type-options">
                <div>
                  <Form.If condition={() => funderAvailable}>
                    <div className="funder-type-option">
                      <FixedBoolean
                        label={
                          <FixedInputResponsiveLabel
                            label={t("funder")}
                            labelClassName="not-bold"
                          />
                        }
                        field="funderSelected"
                        hideIndicator={hideFixedIndicator}
                      />
                      <Format.Number
                        value={funderTotal}
                        format={process.env.CURRENCY_CODE}
                        className={classNames(funderTotal < 0 && "negative")}
                      />
                    </div>
                  </Form.If>
                  <Form.If condition={() => dealerFundedAvailable}>
                    <div className="funder-type-option">
                      <FixedBoolean
                        label={
                          <FixedInputResponsiveLabel
                            label={t("supplierFunded")}
                            labelClassName="not-bold"
                          />
                        }
                        field="dealerFunderSelected"
                        hideIndicator={hideFixedIndicator}
                      />
                      <Format.Number
                        value={dealerTotal}
                        format={process.env.CURRENCY_CODE}
                        className={classNames(dealerTotal < 0 && "negative")}
                      />
                    </div>
                  </Form.If>

                  <Form.If condition={() => coFundedAvailable}>
                    <div className="funder-type-option">
                      <FixedBoolean
                        label={
                          <FixedInputResponsiveLabel
                            label={t("coFunded")}
                            labelClassName="not-bold"
                          />
                        }
                        field="cofundedSelected"
                        hideIndicator={hideFixedIndicator}
                      />
                      <Format.Number
                        value={cofundedTotal}
                        format={process.env.CURRENCY_CODE}
                        className={classNames(cofundedTotal < 0 && "negative")}
                      />
                    </div>
                  </Form.If>
                </div>
              </div>
            </Form.Group>
          </div>
          <div>
            <SectionTotal
              label={t("totalCapitalInvestment")}
              className={classNames(
                "capital-totaliser",
                fundersSelected == 2
                  ? "medium"
                  : fundersSelected == 3
                  ? "long"
                  : "",
              )}
            >
              <Format.Number
                value={cofundedTotal + funderTotal + dealerTotal}
                format={process.env.CURRENCY_CODE}
                className={classNames(
                  "totaliser-value bold",
                  cofundedTotal + funderTotal + dealerTotal < 0 && "negative",
                )}
              />
            </SectionTotal>
          </div>
          <div className="funding-sections">
            <Form.If
              condition={({ fields }) =>
                fields.funderSelected.value && funderAvailable
              }
            >
              <Form.Object field="funder" propagateUpdates="always">
                <FundingSection
                  title={t("funderTitle")}
                  total={funderTotal}
                  totalCosts={funderTotalCosts}
                  maxProductCapitalAllowed={funderMaxProductCapitalAllowed}
                  type={FunderType.Funder}
                  screenSize={screenSize}
                  settlementLength={funderSettlementLength}
                  adminSettlementLength={funderAdminSettlementLength}
                  ipSettlementLength={funderIpSettlementLength}
                  calculating={calculating}
                  rrpRequired={rrpRequired}
                  volumeSelectionValue={volumeSelectionValue}
                  sheetDetailsSaving={sheetDetailsSaving}
                  className="funder"
                  sheetViewType={sheetViewType}
                  ipCoTerminusRentalsLength={funderIpCoTerminusRentalsLength}
                  coTerminusRentalsLength={funderCoTerminusRentalsLength}
                  userArea={userArea}
                />
              </Form.Object>
            </Form.If>
            <Form.If
              condition={({ fields }) =>
                fields.dealerFunderSelected.value && dealerFundedAvailable
              }
            >
              <Form.Object field="dealer" propagateUpdates="always">
                <FundingSection
                  title={t("supplierTitle")}
                  total={dealerTotal}
                  totalCosts={dealerTotalCosts}
                  maxProductCapitalAllowed={dealerMaxProductCapitalAllowed}
                  type={FunderType.Dealer}
                  screenSize={screenSize}
                  settlementLength={dealerSettlementLength}
                  adminSettlementLength={dealerAdminSettlementLength}
                  ipSettlementLength={dealerIpSettlementLength}
                  calculating={calculating}
                  rrpRequired={rrpRequired}
                  volumeSelectionValue={volumeSelectionValue}
                  sheetDetailsSaving={sheetDetailsSaving}
                  className="supplier"
                  sheetViewType={sheetViewType}
                  ipCoTerminusRentalsLength={dealerIpCoTerminusRentalsLength}
                  coTerminusRentalsLength={dealerCoTerminusRentalsLength}
                  userArea={userArea}
                />
              </Form.Object>
            </Form.If>{" "}
            <Form.If
              condition={({ fields }) =>
                fields.cofundedSelected.value && coFundedAvailable
              }
            >
              <Form.Object field="cofunded" propagateUpdates="always">
                <FundingSection
                  title={t("coFundedTitle")}
                  total={cofundedTotal}
                  totalCosts={cofundedTotalCosts}
                  maxProductCapitalAllowed={cofundedMaxProductCapitalAllowed}
                  rrpRequired={rrpRequired}
                  type={FunderType.CoFunded}
                  screenSize={screenSize}
                  settlementLength={coFundedSettlementLength}
                  adminSettlementLength={coFundedAdminSettlementLength}
                  ipSettlementLength={coFundedIpSettlementLength}
                  volumeSelectionValue={volumeSelectionValue}
                  calculating={calculating}
                  sheetDetailsSaving={sheetDetailsSaving}
                  className="cofunded"
                  sheetViewType={sheetViewType}
                  ipCoTerminusRentalsLength={coFundedIpCoTerminusRentalsLength}
                  coTerminusRentalsLength={coFundedCoTerminusRentalsLength}
                  supplierCoFundAvailable={
                    coFundedSettings.supplierCoFundIpsOnly
                  }
                  userArea={userArea}
                />
              </Form.Object>
            </Form.If>
          </div>
        </Form.Object>
      </SheetStep>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    sheetData,
    calculating,
    missingYieldBands,
    sheetDetailsSaving,
    sheetViewType,
  } = getSheetState(state);
  const {
    cofundedTotal,
    dealerTotal,
    cofunded,
    funder,
    dealer,
    funderSelected,
    cofundedSelected,
    dealerFunderSelected,
    volumeSelection,
    cofundedTotalCosts,
    funderTotalCosts,
    dealerTotalCosts,
  } = getSheetCapitalState(state);
  const {
    volumeSelection_Maximum,
    volumeSelection_Minimum,
    defaultVolumeSelectionValue,
    maximumVolumeSelectionValue,
    minimumVolumeSelectionValue,
    funderTypeSettings,
  } = sheetData.defaults;

  const coFundedSettlementLength = cofunded.settlements.length;
  const funderSettlementLength = funder.settlements.length;
  const dealerSettlementLength = dealer.settlements.length;
  const coFundedAdminSettlementLength = cofunded.adminSettlements.length;
  const funderAdminSettlementLength = funder.adminSettlements.length;
  const dealerAdminSettlementLength = dealer.adminSettlements.length;
  const coFundedIpSettlementLength = cofunded.ipSettlements.length;
  const funderIpSettlementLength = funder.ipSettlements.length;
  const dealerIpSettlementLength = dealer.ipSettlements.length;
  const coFundedIpCoTerminusRentalsLength = cofunded.ipCoTerminusRentals.length;
  const funderIpCoTerminusRentalsLength = funder.ipCoTerminusRentals.length;
  const dealerIpCoTerminusRentalsLength = dealer.ipCoTerminusRentals.length;
  const coFundedCoTerminusRentalsLength = cofunded.coTerminusRentals.length;
  const funderCoTerminusRentalsLength = funder.coTerminusRentals.length;
  const dealerCoTerminusRentalsLength = dealer.coTerminusRentals.length;
  const funderSettlementsTotal = funder.settlementsTotal;
  const funderAdminSettlementsTotal = funder.adminSettlementsTotal;
  const funderIpSettlementsTotal = funder.ipSettlementsTotal;

  const dealerSettlementsTotal = dealer.settlementsTotal;
  const dealerAdminSettlementsTotal = dealer.adminSettlementsTotal;
  const dealerIpSettlementsTotal = dealer.ipSettlementsTotal;

  const cofundedSettlementsTotal = cofunded.settlementsTotal;
  const cofundedAdminSettlementsTotal = cofunded.adminSettlementsTotal;
  const cofundedIpSettlementsTotal = cofunded.ipSettlementsTotal;

  let funderSettings = true;
  let dealerFundedSettings = true;
  let coFundedSettings = true;
  if (funderTypeSettings) {
    funderSettings = funderTypeSettings.find(
      (obj) => obj.type == FunderType.Funder,
    );
    dealerFundedSettings = funderTypeSettings.find(
      (obj) => obj.type == FunderType.Dealer,
    );
    coFundedSettings = funderTypeSettings.find(
      (obj) => obj.type == FunderType.CoFunded,
    );
  }

  const fundersSelected = [
    funderSelected.value,
    cofundedSelected.value,
    dealerFunderSelected.value,
  ].filter((v) => v).length;
  const volumeSelectionValue = volumeSelection.value;

  const calculationData = getCalculationData(state);
  let funderMaxProductCapitalAllowed = null;
  let cofundedMaxProductCapitalAllowed = null;
  let dealerMaxProductCapitalAllowed = null;
  let rrpRequired = false;
  let calcBreakdown = {};
  let calcHeader = {};

  if (calculationData) {
    calcBreakdown = {
      ...calculationData.breakdown.capitalCalcCosts,
      yieldOverrides: {
        dealer: sheetData.ipsRates.dealerFundedOverrides.yieldOverride,
        cofund: sheetData.ipsRates.coFundedOverrides.yieldOverride,
      },
      funders: {
        dealer: dealerFunderSelected.value,
        cofund: cofundedSelected.value,
      },
      calcHeader: {
        ...calculationData.breakdown.totalCfaScheduleCharges,
      },
    };

    rrpRequired = calculationData.breakdown.capitalCalcCosts.rrpRequired;
    const calcItemCosts = calculationData.breakdown.capitalCalcCosts.itemCosts;

    const hasFunder = calcItemCosts.some(
      (x) => x.funderType == FunderType.Funder,
    );
    const hasCofunded =
      calcItemCosts &&
      calcItemCosts.some((x) => x.funderType == FunderType.CoFunded);
    const hasDealer =
      calcItemCosts &&
      calcItemCosts.some((x) => x.funderType == FunderType.Dealer);

    funderMaxProductCapitalAllowed =
      calculationData && hasFunder
        ? calcItemCosts.find((x) => x.funderType == FunderType.Funder)
            .maxProductCapitalAllowed
        : null;
    cofundedMaxProductCapitalAllowed =
      calculationData && hasCofunded
        ? calcItemCosts.find((x) => x.funderType == FunderType.CoFunded)
            .maxProductCapitalAllowed
        : null;

    dealerMaxProductCapitalAllowed =
      calculationData && hasDealer
        ? calcItemCosts.find((x) => x.funderType == FunderType.Dealer)
            .maxProductCapitalAllowed
        : null;
  }

  return {
    sheetData,
    calcBreakdown,
    calcHeader,
    cofundedTotal,
    dealerTotal,
    coFundedSettlementLength,
    funderSettlementLength,
    dealerSettlementLength,
    cofundedTotalCosts,
    funderTotalCosts,
    dealerTotalCosts,
    coFundedAdminSettlementLength,
    funderAdminSettlementLength,
    dealerAdminSettlementLength,
    coFundedIpSettlementLength,
    funderIpSettlementLength,
    dealerIpSettlementLength,
    fundersSelected,
    funderSelected: !!funderSelected.value,
    cofundedSelected: !!cofundedSelected.value,
    dealerSelected: !!dealerFunderSelected.value,
    cofundedMaxProductCapitalAllowed,
    funderMaxProductCapitalAllowed,
    dealerMaxProductCapitalAllowed,
    rrpRequired,
    volumeSelectionValue,
    userType: getAuthClaim(state, "userType"),
    userArea: getAuthClaim(state, "userArea"),
    sheetCanBeUpdated: canSheetBeUpdated(sheetData),
    calculating,
    missingYieldBands,
    sheetDetailsSaving,
    preventUpdates: sheetData.preventUpdates,
    calculationData,
    sheetViewType,
    impersonatedUser: sheetData.impersonatedUser,
    coFundedIpCoTerminusRentalsLength,
    funderIpCoTerminusRentalsLength,
    dealerIpCoTerminusRentalsLength,
    coFundedCoTerminusRentalsLength,
    funderCoTerminusRentalsLength,
    dealerCoTerminusRentalsLength,
    funderSettings,
    dealerFundedSettings,
    coFundedSettings,
    funderSettlementsTotal,
    funderAdminSettlementsTotal,
    funderIpSettlementsTotal,
    dealerSettlementsTotal,
    dealerAdminSettlementsTotal,
    dealerIpSettlementsTotal,
    cofundedSettlementsTotal,
    cofundedAdminSettlementsTotal,
    cofundedIpSettlementsTotal,
    volumeSelection_Minimum,
    volumeSelection_Maximum,
    defaultVolumeSelectionValue,
    maximumVolumeSelectionValue,
    minimumVolumeSelectionValue,
  };
};

export default connect(mapStateToProps, null)(withPermissions(CapitalStep));
