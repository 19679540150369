import React from "react";
import { useTranslation } from "react-i18next";
import { toggleSheetReadOnly } from "../actions";
import { ModalBuilder, Table } from "@redriver/cinnamon";
import { withRouter } from "react-router-dom";
import {
  withCurrentUser,
  PermissionCheck,
} from "features/../../../shared/components/auth";

import { Targets, Actions } from "constants/permissions";
import { TranslatedModal } from "features/../../../shared/components/modals";

const ToggleSheetReadOnly = ({ sheet, onSubmitted }) => {
  const { t } = useTranslation("agreements");
  const { sheetId, sheetName, isReadOnly } = sheet;
  return (
    <PermissionCheck target={Targets.Sheet} action={Actions.SetReadOnly}>
      <ModalBuilder
        withForm
        submitAction={toggleSheetReadOnly}
        submitParams={sheetId}
        onSubmitted={onSubmitted}
        renderTrigger={(trigger) => (
          <Table.MenuItem onClick={trigger}>
            {isReadOnly
              ? t("SheetActions.EditableSheet.TriggerText")
              : t("SheetActions.ReadOnlySheet.TriggerText")}
          </Table.MenuItem>
        )}
        renderModal={(modalProps) => (
          <TranslatedModal.Confirmation
            {...modalProps}
            header={
              isReadOnly
                ? t("SheetActions.EditableSheet.TriggerText")
                : t("SheetActions.ReadOnlySheet.TriggerText")
            }
            size="small"
          >
            {isReadOnly
              ? t("SheetActions.EditableSheet.ConfirmMessage", { sheetName })
              : t("SheetActions.ReadOnlySheet.ConfirmMessage", { sheetName })}
          </TranslatedModal.Confirmation>
        )}
      />
    </PermissionCheck>
  );
};

export default withCurrentUser(withRouter(ToggleSheetReadOnly));
