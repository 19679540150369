import React, { useState } from "react";
import { ModalBuilder, useAuthClaims } from "@redriver/cinnamon";
import { AddButton } from "features/../../../shared/components/buttons";
import { addUser } from "./actions";
import UserForm from "./UserForm";
import { UserType } from "features/../../../shared/constants/enums";
import { TranslatedModal } from "../../../components/modals";
import { useTranslation } from "react-i18next";

const AddUser = ({ companyId, onAdded }) => {
  const { t } = useTranslation("supplierOffices", { keyPrefix: "users" });
  const [showAccessTree, setShowAccessTree] = useState(false);  
  const { userArea } = useAuthClaims();    
  
  return (
    <ModalBuilder
      withForm
      initialData={{
        companyId: companyId,
        userType: companyId ? UserType.Dealer : null,
      }}
      submitAction={addUser}
      onSubmitted={onAdded}
      renderTrigger={showModal => (
        <AddButton onClick={showModal} content={t("addNewUser")} />
      )}
      renderModal={(modalProps, formProps, state, events) => (
        <TranslatedModal.Add
          {...modalProps}
          className="add-user-modal"
          header={t("addNewUser")}
        >
          <UserForm
            formProps={formProps}
            companyId={companyId}
            setShowAccessTree={setShowAccessTree}
            showAccessTree={showAccessTree}
            userArea={userArea}
          />
        </TranslatedModal.Add>
      )}
    />
  );
};

export default AddUser;

