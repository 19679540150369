export const UserArea = {
  All: "All",
  IpsAdmin: "IpsAdmin",
  SupplierAdmin: "SupplierAdmin",
  AccountManager: "AccountManager",
  Funder: "Funder",
  IpsSuperUser: "IpsSuperUser",
};

// Although the enum text and descriptions match, this could change so we need to ensure we only display the descriptions.
export const UserAreaDescriptions = {
  All: "All",
  IpsAdmin: "IPS Admin",
  SupplierAdmin: "Supplier Admin",
  AccountManager: "Account Manager",
  Funder: "Funder",
  IpsSuperUser:"IPS Super User"
};

const InternalUserAreas = [
  UserArea.IpsAdmin,
  UserArea.IpsSuperUser,
];

export const getUserAreaOptions = (includeInternal) => [
  { text: UserAreaDescriptions.IpsSuperUser, value: UserArea.IpsSuperUser },
  { text: UserAreaDescriptions.IpsAdmin, value: UserArea.IpsAdmin },
  { text: UserAreaDescriptions.SupplierAdmin, value: UserArea.SupplierAdmin },
  { text: UserAreaDescriptions.AccountManager, value: UserArea.AccountManager },
].filter(area => includeInternal || !InternalUserAreas.some(internal => internal == area.value));
