import React from "react";
import { Form } from "@redriver/cinnamon";
import {
  FixedInput,
  FixedString,
  FixedInputResponsiveLabel,
} from "components/forms";
import FormArrayDeleteModal from "features/../../../shared/components/forms/FormArrayDeleteModal";
import { getSheetDataState } from "features/Sheets";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { isFieldFixed } from "features/Sheets/ManageSheet";

const CoTerminusArray = ({
  field,
  sheetDetailsSaving,
  preventUpdates,
  className,
  impersonatedUser,
  backgroundClassName,
}) => {
  const hideFixedIndicator = !impersonatedUser.canEditAdminFields;
  const { t } = useTranslation("capitalCalc");
  return (
    <Form.Array field={field} arrayKey="id" propagateUpdates="always">
      {({ fields }) => {
        return (
          <div className="coterminus-rental">
            <div className={backgroundClassName}>
              <FixedInput
                label={
                  <FixedInputResponsiveLabel
                    labelClassName={"not-bold " + className}
                  />
                }
                field="value"
                fluid
                currency
                formatNumber
                className="coterminus-rental-details medium"
                minAllowValue={0}
                hideIndicator={hideFixedIndicator}
              />
              <FixedString
                label={
                  <FixedInputResponsiveLabel
                    labelClassName={"not-bold " + className}
                  />
                }
                field="agreementReference"
                fluid
                placeholder={t(
                  "fundingSection.deleteCoTerminusModel.refNoPlaceholder",
                )}
                className="agreement-number coterminus-rental-details medium"
                hideIndicator={hideFixedIndicator}
              />
              <FixedInput
                label={
                  <FixedInputResponsiveLabel
                    labelClassName={"not-bold " + className}
                  />
                }
                field="minimumQuarters"
                fluid
                decimalPlaces={1}
                minAllowValue={0}
                maxValue={99}
                className="coterminus-rental-details min-qtr smaller"
                hideIndicator={hideFixedIndicator}
              />
            </div>
            <FormArrayDeleteModal
              nameOfRemoval={t(
                "fundingSection.deleteCoTerminusModel.coTerminusRental",
              )}
              disabled={
                sheetDetailsSaving ||
                preventUpdates ||
                isFieldFixed(fields.value) ||
                fields.agreementReference?.isFixed ||
                isFieldFixed(fields.minimumQuarters)
              }
            />
          </div>
        );
      }}
    </Form.Array>
  );
};

const mapStateToProps = (state) => {
  const { impersonatedUser, preventUpdates } = getSheetDataState(state);
  return {
    impersonatedUser,
    preventUpdates,
  };
};

export default connect(mapStateToProps, null)(CoTerminusArray);
