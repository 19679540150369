import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder } from "@redriver/cinnamon";
import { sendPasswordReset } from "./actions";
import { TranslatedModal } from "features/../../../shared/components/modals";

const SendPasswordReset = ({ as: As = Button, user, onSend, ...props }) => (
  <ModalBuilder
    submitAction={sendPasswordReset}
    submitParams={user.id}
    onSubmitted={onSend}
    renderTrigger={showModal => <As onClick={showModal} {...props} />}
    renderModal={modalProps => (
      <TranslatedModal.Confirmation {...modalProps} header="Send Password Reset">
        <p>
          Are you sure you wish to send a password reset email to {user.name}?
        </p>
        <p>
          This will be sent to: <strong>{user.email}</strong>
        </p>
      </TranslatedModal.Confirmation>
    )}
  />
);

export default SendPasswordReset;
