import React from "react";
import { ModalBuilder, Table, Lookup } from "@redriver/cinnamon";
import { LookupTypes } from "../lookups";
import ServiceForm from "./ServiceForm";
import { TranslatedModal } from "../../../components/modals";

const EditServiceModal = ({ submitAction, onSubmitted, service }) => (
  <Lookup
    lookup={LookupTypes.ContractTypes}
    render={({ loading, response: contractTypeResponse }) => (
      <ModalBuilder
        withForm
        initialData={{
          ...service,
          contractType: service.contractType.split(", ").map((contractType) => {
            const items = (contractTypeResponse || []).filter(
              (r) => r.text === contractType
            );
            return items.length > 0 ? items[0].value : "";
          }),
        }}
        submitAction={submitAction}
        submitParams={service.id}
        onSubmitted={onSubmitted}
        renderTrigger={(showModal) => (
          <Table.MenuItem onClick={showModal}>Edit</Table.MenuItem>
        )}
        renderModal={(modalProps, formProps) => (
          <TranslatedModal.Edit
            {...modalProps}
            loading={loading}
            size="small"
            header="Edit Quarterly Service"
            submitLabel="Update Quarterly Service"
          >
            <ServiceForm
              formProps={formProps}
              contractTypeResponse={contractTypeResponse}
            />
          </TranslatedModal.Edit>
        )}
      />
    )}
  />
);

export default EditServiceModal;
