import React from "react";
import { Grid } from "semantic-ui-react";
import { Form, Format } from "@redriver/cinnamon";
import { UserTypeCheck } from "features/../../../shared/components/auth";
import { UserType } from "features/../../../shared/constants/enums";

const DealerChargeColumn = ({
  title,
  column,
  readOnly,
  includeIpSyndicateCharge,
}) => (
  <Grid.Column width={2}>
    <Grid.Row>
      <p>{title}</p>
    </Grid.Row>
    <Grid.Row>
      <Form.Numeric
        field="sales"
        subLabel="%"
        subLabelPosition="right"
        decimalPlaces={3}
        formatValue
        readOnly={readOnly}
        onFocus={e => e.target.select()}
        renderReadOnly={props => (
          <Format.Number
            format="percentage"
            value={column.sales}
            decimalPlaces={{ min: 0, max: 3 }}
          />
        )}
      />
    </Grid.Row>
    <Grid.Row>
      <Form.Numeric
        field="pound"
        subLabel="%"
        subLabelPosition="right"
        decimalPlaces={3}
        formatValue
        readOnly={readOnly}
        onFocus={e => e.target.select()}
        renderReadOnly={props => (
          <Format.Number
            format="percentage"
            value={column.pound}
            decimalPlaces={{ min: 0, max: 3 }}
          />
        )}
      />
    </Grid.Row>
    <Grid.Row>
      <Form.Numeric
        field="admin"
        subLabel="%"
        subLabelPosition="right"
        decimalPlaces={3}
        formatValue
        readOnly={readOnly}
        onFocus={e => e.target.select()}
        renderReadOnly={props => (
          <Format.Number
            format="percentage"
            value={column.admin}
            decimalPlaces={{ min: 0, max: 3 }}
          />
        )}
      />
    </Grid.Row>
    <Grid.Row>
      <Form.Numeric
        field="other"
        subLabel="%"
        subLabelPosition="right"
        decimalPlaces={3}
        formatValue
        readOnly={readOnly}
        onFocus={e => e.target.select()}
        renderReadOnly={props => (
          <Format.Number
            format="percentage"
            value={column.other}
            decimalPlaces={{ min: 0, max: 3 }}
          />
        )}
      />
    </Grid.Row>

    <Grid.Row>
      <Format.Number
        format="percentage"
        field="total"
        value={getTotal(column.sales, column.pound, column.admin, column.other)}
        decimalPlaces={{ min: 0, max: 3 }}
      />
    </Grid.Row>

    <UserTypeCheck restrictTo={UserType.HQ}>
      <Grid.Row className="ip-syndicate">
        <Form.Numeric
          field="ipSyndicate"
          subLabel="%"
          subLabelPosition="right"
          decimalPlaces={3}
          formatValue
          readOnly={readOnly}
          onFocus={e => e.target.select()}
          renderReadOnly={props => (
            <Format.Number
              format="percentage"
              value={column.ipSyndicate}
              decimalPlaces={{ min: 0, max: 3 }}
            />
          )}
        />
      </Grid.Row>{" "}
      <Grid.Row className="ips-v-minimum">
        <Form.Numeric
          field="ipSyndicateVMinimum"
          subLabel="%"
          subLabelPosition="right"
          decimalPlaces={3}
          formatValue
          readOnly={readOnly}
          onFocus={e => e.target.select()}
          renderReadOnly={props => (
            <Format.Number
              format="percentage"
              value={column.ipSyndicateVMinimum}
              decimalPlaces={{ min: 0, max: 3 }}
            />
          )}
        />
      </Grid.Row>
    </UserTypeCheck>
  </Grid.Column>
);

const getTotal = (w, x, y, z) =>
  parseFloat(w) + parseFloat(x) + parseFloat(y) + parseFloat(z);

export default DealerChargeColumn;
