import React from "react";
import { useTranslation } from "react-i18next";
import { Format } from "@redriver/cinnamon";
import DataGrid from "./DataGrid";

const CfaSplitChargeGrid = ({ data }) => {
  const { t } = useTranslation("capitalCalc", {
    keyPrefix: "infoModal.funderTypes.cfaSplitPayment",
  });

  if (!data) return;

  const subHeaderKeys = [null, t("cfaPaymentHeader"), t("splitHeader")];

  return (
    <DataGrid
      className="cfa-charge-split"
      columnCount={3}
      isFirstRowHeader
      dataRows={[
        { className: "highlighted", values: subHeaderKeys },
        [
          t("supplierCfaPayment"),
          <Format.Number
            key={1}
            value={data.cfaCharge}
            format={process.env.CURRENCY_CODE}
          />,
          <Format.Number
            key={2}
            value={data.cfaChargeSplit}
            decimalPlaces={2}
            format="percentage"
          />,
        ],
        [
          t("ipsCfaPayment"),
          <Format.Number
            key={3}
            value={data.ipsCfaCharge}
            format={process.env.CURRENCY_CODE}
          />,
          <Format.Number
            key={4}
            value={data.ipsCfaChargeSplit}
            decimalPlaces={2}
            format="percentage"
          />,
        ],
        {
          className: "bold",
          values: [
            t("totalCfaPayment"),
            <Format.Number
              key={5}
              value={data.total}
              format={process.env.CURRENCY_CODE}
            />,
            <Format.Number
              key={6}
              value={data.totalSplit}
              decimalPlaces={2}
              format="percentage"
            />,
          ],
        },
      ]}
    />
  );
};

export default CfaSplitChargeGrid;
