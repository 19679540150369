import React from "react";
import { ModalBuilder, Form } from "@redriver/cinnamon";
import { Icon } from "semantic-ui-react";
import { renameSheet } from "../actions";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { connect } from "react-redux";
import { getSheetDataState } from "features/Sheets";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";

const RenameSheet = ({
  sheetId,
  lookupResponse,
  resetLookup,
  impersonatedUser,
  showOnSheetFirstLoad,
  disableShowOnSheetFirstLoad,
  disabled,
}) => {
  const { t } = useTranslation("sheetHeader");
  return (
    <PermissionCheck target={Targets.Sheet} action={Actions.Edit}>
      {impersonatedUser.canEditSheet && (
        <ModalBuilder
          withForm
          initialData={{ newName: lookupResponse.name }}
          submitAction={renameSheet}
          submitParams={{ sheetId }}
          onSubmitted={() => {
            resetLookup();
            disableShowOnSheetFirstLoad();
          }}
          onCancelled={disableShowOnSheetFirstLoad}
          renderError={false}
          renderSubmitting={false}
          renderTrigger={
            showOnSheetFirstLoad
              ? undefined
              : (showModal) => (
                  <Icon
                    name="pencil"
                    className="rename-sheet"
                    onClick={disabled ? undefined : showModal}
                  />
                )
          }
          renderModal={(modalProps, formProps) => (
            <TranslatedModal.Edit
              {...modalProps}
              header={t("Rename_Sheet")}
              className="rename-sheet"
            >
              <Form {...formProps}>
                <div className="flex">
                  <Form.Input
                    field="newName"
                    label={t("New_name")}
                    width={25}
                    className="new-sheet-name"
                  />
                </div>
              </Form>
            </TranslatedModal.Edit>
          )}
        />
      )}
    </PermissionCheck>
  );
};

const mapStateToProps = (state) => {
  const { impersonatedUser } = getSheetDataState(state);
  return {
    impersonatedUser,
  };
};

export default connect(mapStateToProps, null)(RenameSheet);
