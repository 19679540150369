export const UserStatus = {
  NotVerified: "NotVerified",
  Disabled: "Disabled",
  Verified: "Verified"
};

export const UserStatusText = {
  NotVerified: "Not Verified",
  Disabled: "Disabled",
  Verified: "Verified"
};

export const UserStatusOptions = [
  { text: UserStatusText.Verified, value: UserStatus.Verified },
  { text: UserStatusText.NotVerified, value: UserStatus.NotVerified },
  { text: UserStatusText.Disabled, value: UserStatus.Disabled }
];
