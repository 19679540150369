import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { SideColumn, Content, ContentBuilder } from "@redriver/cinnamon";
import { AppRoutes, ClientRoutes } from "constants/routes";
import ClientMenu from "./ClientMenu";
import ClientPage from "./ClientPage";
// import ClientSheetsListPage from "./ClientSheetsListPage";
import ClientAuthorisedSignatoriesPage from "./ClientAuthorisedSignatoriesPage";
import { BackToLink } from "components/navigation";
import { getClient } from "features/Clients/ListClients";
import { useTranslation } from "react-i18next";

const ClientsRouter = ({ match }) => {
  const { t } = useTranslation();
  return (
    <Content>
      <SideColumn
        className="clients-pages"
        renderColumn={() => (
          <div>
            <BackToLink
              text={t("clients:links:backToClients")}
              to={AppRoutes.Clients}
            />
            <ClientMenu clientId={match.params.id} />
          </div>
        )}
      >
        <ContentBuilder
          loadAction={getClient}
          loadParams={match.params.id}
          renderContent={(data) => (
            <React.Fragment>
              <Switch>
                <Route
                  exact
                  path={ClientRoutes.Overview()}
                  render={(props) => (
                    <ClientPage {...props} clientName={data.name} />
                  )}
                />

                {/*
                <Route
                  exact
                  path={ClientRoutes.Sheets()}
                  render={props => (
                    <ClientSheetsListPage
                      {...props}
                      clientId={match.params.id}
                      clientName={data.name}
                      dealerOfficeId={data.dealerOfficeId}
                      dealerOfficeName={data.dealerOffice}
                    />
                  )}
                  />
                  */}
                <Route
                  exact
                  path={ClientRoutes.AuthorisedSignatories()}
                  render={(props) => (
                    <ClientAuthorisedSignatoriesPage
                      {...props}
                      clientName={data.name}
                    />
                  )}
                />
                <Route render={() => <Redirect to={AppRoutes.Clients} />} />
              </Switch>
            </React.Fragment>
          )}
        />
      </SideColumn>
    </Content>
  );
};

export default ClientsRouter;
