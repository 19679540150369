import PropTypes from "prop-types";
import i18next from "i18next";

const maxValue = () => {
  return {
    propTypes: {
      maxValue: PropTypes.number,
    },
    shouldValidate: () => true,
    getErrors: (field, value) => {
      if (
        typeof value === "object" &&
        !(value?.value == null) &&
        !!value.value.length
      ) {
        const val = parseFloat(value.value);

        if (!(value.maximumValue == null) && val > value.maximumValue) {
          return [
            `${field} ${i18next.t("sheetTranslation:CanNotBeMoreThan")} ${
              value.maximumValue
            }`,
          ];
        }
      }
      return [];
    },
  };
};

export default maxValue;
