import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder } from "@redriver/cinnamon";
import { getClientAuthorisedSignatory, updateClientAuthorisedSignatory } from "./actions";
import ClientAuthorisedSignatoryForm from "./ClientAuthorisedSignatoryForm";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";

const EditClientAuthorisedSignatory = ({ as: As = Button, clientId, item, onUpdated, ...props }) => {
    const { t } = useTranslation("clients");
    return(
    <ModalBuilder
        withForm
        loadAction={getClientAuthorisedSignatory}
        loadParams={{ clientId: item.clientId, itemId: item.id }}
        submitAction={updateClientAuthorisedSignatory}
        submitParams={{ clientId: item.clientId, itemId: item.id }}
        onSubmitted={onUpdated}
        renderTrigger={showModal => <As onClick={showModal} {...props} />}
        renderModal={(modalProps, formProps) => (
            <TranslatedModal.Edit {...modalProps} 
            header={t("editAuthorisedSignatoriesModal.header")} > 
                <ClientAuthorisedSignatoryForm {...formProps} />
            </TranslatedModal.Edit>
        )}
    />
)};

export default EditClientAuthorisedSignatory;
