import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Segment, Sidebar } from "semantic-ui-react";
import { NavMenu } from "@redriver/cinnamon";

import {
  PermissionCheck,
  UserTypeCheck,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { SystemRoutes, AppRoutes } from "../../constants/routes";

import { UserType } from "features/../../../shared/constants/enums";

/**
 * NOTE: Please keep options here in sync with ./MainNav.
 * This menu is rendered on menu viewports
 */
const MobileMainMenu = ({ animation, direction, visible, hideMenu }) => {
  const { t } = useTranslation("menu");
  return (
    <Sidebar
      as={Segment}
      animation={animation}
      direction={direction}
      visible={visible}
      className="mobile-main-menu-segment"
    >
      <NavMenu className="mobile-main-menu">
        {!hideMenu && (
          <React.Fragment>
            <PermissionCheck target={Targets.Sheet} action={Actions.View}>
              <NavMenu.Item link={AppRoutes.Agreements}>
                {t("Agreements")}
              </NavMenu.Item>
            </PermissionCheck>
            <PermissionCheck target={Targets.Client} action={Actions.View}>
              <NavMenu.Item link={AppRoutes.Clients}>
                {t("Clients")}
              </NavMenu.Item>
            </PermissionCheck>
            <UserTypeCheck restrictTo={UserType.HQ}>
              <PermissionCheck target={Targets.Dealer} action={Actions.View}>
                <NavMenu.Item link={AppRoutes.DealerOffices}>
                  {t("Supplier_Offices")}
                </NavMenu.Item>
              </PermissionCheck>
            </UserTypeCheck>
          </React.Fragment>
        )}
        <NavMenu.Item link={SystemRoutes.Account}>{t("Account")}</NavMenu.Item>
        <NavMenu.Item link={SystemRoutes.Logout}>{t("Log_out")}</NavMenu.Item>
        <NavMenu.Item link={AppRoutes.Notification}>
          {t("Notification_List")}
        </NavMenu.Item>
      </NavMenu>
    </Sidebar>
  );
};

export default withRouter(MobileMainMenu);
