import {
  concatenateServiceArrays,
  isFieldFixed,
} from "features/Sheets/ManageSheet/helpers";

export const updateQuarterlyServicesOnServiceChange = (
  oldSheetServicesSection,
  newSheetServicesSection,
  serviceData
) => {
  const oldSheetServices = concatenateServiceArrays(oldSheetServicesSection);
  const newSheetServices = concatenateServiceArrays(newSheetServicesSection);

  newSheetServices.forEach(newSheetService => {
    if (isFieldFixed(newSheetService.value)) return;

    const oldSheetService = oldSheetServices.find(
      x => x.id == newSheetService.id
    );
    if (!oldSheetService) return;

    const serviceIsChanging =
      newSheetService.service.value !== oldSheetService.service.value;
    if (!serviceIsChanging) return;

    const service = serviceData.find(
      x => x.id == newSheetService.service.value
    );
    newSheetService.value.value = service?.defaultValue;
  });
};
