import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Format, getAuthClaim } from "@redriver/cinnamon";
import { FixedInput, FixedBoolean } from "components/forms";
import { InlineLoader } from "features/../../../shared/components/forms/InlineLoader";
import ScheduleItemDeleteModal from "../ScheduleItemDeleteModal";
import {
  getSheetDataState,
  getSheetState,
  getSheetRatesState,
} from "features/Sheets";
import { isFieldFixed } from "../../helpers";
import { withCurrentUser } from "features/../../../shared/components/auth";
import { compose } from "redux";

const LargeFormatMeterItem = ({
  calculating,
  meterField,
  arrayIndex,
  unitInfo,
  sheetId,
  sheetDetailsSaving,
  printerName,
  allowedTieredToner,
  setEditModalTrigger,
  setLargeFormatEditIndex,
  hideFixedIndicator,
  setMeterField,
  currentUser,
  disableCpiWarningMessages,
  preventUpdates,
}) => {
  return (
    <div className="schedule-item-container large-format">
      <div className="schedule-item top large-format">
        <div className="large-format-list-unit-container">
          <div className="col col-1 readonly-fix-unit-dropdown">
            {meterField.displayCode}
          </div>
        </div>
        <div className="col-2 unit-description">{unitInfo}</div>
        <div className="col col-3">
          <FixedInput
            className="cost-per-click"
            field="costPerClick"
            width={9}
            decimalPlaces={process.env.USE_MINOR_CURRENCY ? 3 : 5}
            currency
            minorCurrency={process.env.USE_MINOR_CURRENCY}
            required
            formatNumber
            hideIndicator={hideFixedIndicator}
            minAllowValue={
              currentUser.isInternalUser
                ? meterField.vMinSalePrice
                : meterField.defaultSalePrice
            }
            disableWarning={disableCpiWarningMessages}
          />
        </div>
        <div className="col col-4">
          <FixedBoolean
            field="includeManagedVol"
            toggle
            className="toggle include-managed-volume"
            disabled={
              (meterField &&
                (meterField.alwaysUnmanaged || meterField.alwaysManaged)) ||
              sheetDetailsSaving ||
              preventUpdates
            }
            hideIndicator={hideFixedIndicator}
          />
        </div>

        <div
          className={classNames(
            "col-lf-name",
            allowedTieredToner == 1 ? "large" : "",
          )}
        >
          <label
            onClick={() => {
              setLargeFormatEditIndex(arrayIndex);
              setEditModalTrigger();
            }}
          >
            {printerName}
          </label>
        </div>

        <div className="col col-7">
          <FixedInput
            className="min-quarterly-volume"
            field="minQuarterlyVolume"
            decimalPlaces={0}
            inline
            width={9}
            required
            formatNumber
            hideIndicator={hideFixedIndicator}
            minAllowValue={
              currentUser.isInternalUser
                ? meterField.vMinVolume
                : meterField.defaultVolume
            }
          />
        </div>
        <div className="col col-8 cost-per-unit">
          <InlineLoader
            size="tiny"
            isActive={calculating}
            className="centralise"
          />
          <strong
            className={classNames(
              "cost-per-unit",
              "calculable-field",
              calculating && "value-loading",
            )}
          >
            {process.env.USE_MINOR_CURRENCY ? (
              <Format.Number
                value={meterField.costPerUnit || 0}
                decimalPlaces={{ min: 0, max: 3 }}
                suffix={process.env.MINOR_CURRENCY}
              />
            ) : (
              <Format.Number
                value={meterField.costPerUnit || 0}
                decimalPlaces={{ min: 0, max: 5 }}
                prefix={process.env.MAJOR_CURRENCY}
              />
            )}
          </strong>
        </div>

        <div className="col col-0">
          <ScheduleItemDeleteModal
            nameOfRemoval="Large Format item"
            sheetId={sheetId}
            scheduleItemId={meterField.id}
            isLargeFormat={true}
            disabled={
              sheetDetailsSaving ||
              isFieldFixed(meterField.costPerClick) ||
              meterField.includeManagedVol.isFixed ||
              isFieldFixed(meterField.minQuarterlyVolume)
            }
            onSubmit={() =>
              setMeterField(
                Object.assign(
                  {
                    pendingDelete: true,
                  },
                  meterField,
                ),
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    id: sheetId,
    impersonatedUser,
    preventUpdates,
  } = getSheetDataState(state);
  const { sheetDetailsSaving, calculating } = getSheetState(state);
  const { allowedTieredToner } = getSheetRatesState(state);

  return {
    sheetId,
    sheetDetailsSaving,
    hideFixedIndicator: !impersonatedUser.canEditAdminFields,
    allowedTieredToner,
    calculating,
    userArea: getAuthClaim(state, "userArea"),
    preventUpdates,
  };
};

export default compose(
  connect(mapStateToProps),
  withCurrentUser,
)(LargeFormatMeterItem);
