import { apiPut, notifySuccess } from "@redriver/cinnamon";

const ActionTypes = {
  SetPhysicalDocumentSheetState: "SHEETS/SET_PHYSICAL_DOC_STATE",
};

export const setPhysicalDocumentSheetState = (request, { sheetId }) =>
  apiPut(
    ActionTypes.SetPhysicalDocumentSheetState,
    `sheets/${sheetId}/workflow/physical-document-state`,
    request,
  );
