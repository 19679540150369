export const AppRoutes = {
  Root: "/",
  Users: "/admin/users",
  Clients: "/clients",
  DealerOffices: "/dealer-offices",
  Settings: "/settings",
  DataUploads: "/data-uploads",
  Agreements: "/agreements",
  Notification: "/notifications",
};

export const SystemRoutes = {
  Login: "/login",
  Logout: "/logout",
  AccountManagerLogout: "/amlogout",
  SetupUser: "/setup-user/:token?",
  VerifyEmail: "/verify-email/:token?",
  ForgottenPassword: "/forgotten-password",
  ResetPassword: "/reset-password/:token?",
  Account: "/account",
  Authenticate: "/authenticate",
};

export const SettingsRoutes = {
  Users: `/settings/users`,
  Defaults: `/settings/defaults`,
  Services: `/settings/services`,
  Rates: `/settings/rates`,
  Yields: `/settings/yields`,
  SheetType: `/settings/sheet-type`,
  LargeFormat: `/settings/large-format`,
};

export const UserMatch = (id = ":id") => `${AppRoutes.Users}/${id}`;
export const SheetMatch = (id = ":id") =>
  `${AppRoutes.Agreements}/sheets/${id}`;
export const AgreementMatch = (id = ":id") => `${AppRoutes.Agreements}/${id}`;
export const ClientMatch = (id = ":id") => `${AppRoutes.Clients}/${id}`;
export const DealerMatch = (id = ":id") => `${AppRoutes.DealerOffices}/${id}`;

export const UserRoutes = {
  User: (id) => `${UserMatch(id)}`,
};

export const SheetRoutes = {
  Sheet: (id) => `${SheetMatch(id)}`,
};

export const AgreementRoutes = {
  Sheet: (id) => `${AgreementMatch(id)}`,
};

export const ClientRoutes = {
  Overview: (id) => `${ClientMatch(id)}`,
  Sheets: (id) => `${ClientMatch(id)}/sheets`,
  AuthorisedSignatories: (id) => `${ClientMatch(id)}/authorised-signatories`,
};

export const DealerRoutes = {
  Overview: (id) => `${DealerMatch(id)}`,
  Users: (id) => `${DealerMatch(id)}/users`,
  Sheets: (id) => `${DealerMatch(id)}/sheets`,
  Defaults: (id) => `${DealerMatch(id)}/defaults`,
  Rates: (id) => `${DealerMatch(id)}/rates`,
  Yields: (id) => `${DealerMatch(id)}/yields`,
  LargeFormat: (id) => `${DealerMatch(id)}/large-format`,
};
