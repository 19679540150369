import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Image } from "semantic-ui-react";
import { ModalBuilder, Form } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";

import { getSheetDuplicateName, saveAsSheet } from "../actions";
import { AgreementRoutes, SheetRoutes } from "constants/routes";
import { setSelectedAgreementSchedule } from "features/Agreements";
import Save from "features/../../../shared/assets/save.svg";
import { TranslatedModal } from "features/../../../shared/components/modals";

const SaveAsModal = ({ disabled, sheetId, scheduleId, history }) => {
  const { t } = useTranslation("sheetTranslation");
  const [redirectToAgreements, setRedirectToAgreements] = useState(false);
  const dispatch = useDispatch();

  const submitted = (submitResponse) => {
    if (redirectToAgreements) {
      dispatch(
        setSelectedAgreementSchedule({
          agreementId: null,
          scheduleId: scheduleId,
          sheetId: sheetId,
        })
      );
      history.push(AgreementRoutes.Sheet(submitResponse));
    } else {
      history.push(SheetRoutes.Sheet(submitResponse));
    }
  };

  return (
    <ModalBuilder
      withForm
      loadAction={getSheetDuplicateName}
      loadParams={{
        sheetId: sheetId,
      }}
      submitAction={saveAsSheet}
      submitParams={{
        sheetId,
        successMessage: t("Save_As_SuccessMessage"),
      }}
      renderTrigger={(showModal) => (
        <Button
          primary
          className="save-button"
          disabled={disabled}
          onClick={showModal}
        >
          {t("Save_As")}
          <Image src={Save} />
        </Button>
      )}
      onSubmitted={(submitResponse) => submitted(submitResponse)}
      renderModal={(modalProps, formProps, state) => (
        <TranslatedModal.Add
          {...modalProps}
          header={t("Save_As")}
          className="save-as-modal"
        >
          <Form {...formProps}>
            <div className="flex">
              <Form.Input field="name" label={t("New_sheet_name")} width={25} />
            </div>
          </Form>
          <div className="actions">
            <Button
              disabled={state.submitting || state.loading}
              negative
              onClick={modalProps.onCancel}
            >
              {t("Cancel")}
            </Button>
            <Button
              disabled={state.submitting || state.loading}
              loading={state.submitting}
              positive
              onClick={() => {
                setRedirectToAgreements(false);
                modalProps.onSubmit();
              }}
              className="save-button"
            >
              <div>
                {t("Save")}
                <Image src={Save} />
              </div>
            </Button>
            <Button
              disabled={state.submitting || state.loading}
              loading={state.submitting}
              positive
              onClick={() => {
                setRedirectToAgreements(true);
                modalProps.onSubmit();
              }}
              className="save-button"
            >
              {t("Save_&_Finish")}
              <Image src={Save} />
            </Button>
          </div>
        </TranslatedModal.Add>
      )}
    />
  );
};

export default withRouter(SaveAsModal);
