import React from "react";
import { Form } from "@redriver/cinnamon";

const RateGroupForm = ({ formProps }) => (
  <Form {...formProps} className="service-form">
    <Form.Group widths="equal">
      <Form.Input
        fluid
        required
        field="name"
        label="Rate Group Name"
        className="highlighted-input"
        placeholder="Name..."
      />
      <Form.Date
        dateFormat="DD MMM YYYY"
        local
        label="Applicable From"
        field="applicableFromDate"
        placeholder="Applicable From"
        className="date-input"
      />
    </Form.Group>
  </Form>
);

export default RateGroupForm;
