export const InchDivision = {
  One: 1 << 0,
  Half: 1 << 1,
  Quarter: 1 << 2,
  Eigth: 1 << 3,
  Sixteenth: 1 << 4,
  ThirtySecondth: 1 << 5,
  SixtyFourth: 1 << 6,
};

export const InchDivisionNumeric = {
  [InchDivision.One]: 1 << 0,
  [InchDivision.Half]: 1 << 1,
  [InchDivision.Quarter]: 1 << 2,
  [InchDivision.Eigth]: 1 << 3,
  [InchDivision.Sixteenth]: 1 << 4,
  [InchDivision.ThirtySecondth]: 1 << 5,
  [InchDivision.SixtyFourth]: 1 << 6,
};

export const InchDivisionDescription = {
  [InchDivision.One]: "One",
  [InchDivision.Half]: "Half",
  [InchDivision.Quarter]: "Quarter",
  [InchDivision.Eigth]: "Eigth",
  [InchDivision.Sixteenth]: "Sixteenth",
  [InchDivision.ThirtySecondth]: "ThirtySecondth",
  [InchDivision.SixtyFourth]: "SixtyFourth",
};
