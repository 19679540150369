import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Button } from "semantic-ui-react";
import {
  getAuthState,
  getAuthClaims,
  getAuthOptions,
  FormBuilder,
  Form,
  ErrorMessage,
} from "@redriver/cinnamon";
import { mockUserRejection, requestLogin } from "./actions";
import { EmailVerificationResender } from "../EmailVerification";
import { SystemRoutes } from "constants/routes";
import { getPersistentQueryParamState } from "../selectors";
import { configureUnloadListener, removeUnloadListener } from "setup";

/**
 * @param {Array} acceptUsernames An array of usernames for restricting submission of
 * the login form - if provided, only usernames specified in this array will pass form
 * validation, using **case-insensitive** comparison. Any elements that are empty strings,
 * or anything that is **not** a string, will be ignored. Passing an empty array, or anything
 * that is **not** an array, will revert to default behaviour and allow anyone to submit the
 * form as usual
 */
const Login = ({
  loggedIn,
  emailVerified,
  useEmailVerification,
  useUnloadEvent = false,
  acceptUsernames = [],
}) => {
  const { t } = useTranslation("system");

  const history = useHistory();
  const location = useLocation();
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    removeUnloadListener();
    useUnloadEvent && configureUnloadListener();
  }, [useUnloadEvent]);

  if (loggedIn) {
    if (emailVerified || !useEmailVerification) {
      return <p>{t("Successful_Signin")}</p>;
    }

    return (
      <div>
        <p>{t("Email_Not_Verified")}</p>
        <EmailVerificationResender />
      </div>
    );
  }

  const canAcceptUsername = (test) => {
    if (typeof test !== "string") return false;

    if (!Array.isArray(acceptUsernames) || acceptUsernames.length < 1) {
      return true;
    }

    test = test.toLowerCase();
    return acceptUsernames
      .filter((x) => x && typeof x === "string")
      .map((x) => x.toLowerCase())
      .includes(test);
  };

  return (
    <div className="login-form">
      <FormBuilder
        submitAction={(formData) =>
          canAcceptUsername(formData.email)
            ? requestLogin(formData)
            : mockUserRejection()
        }
        onSubmitted={({ secondFactor, tokens }) => {
          if ((secondFactor?.availableTypes ?? []).length > 0) {
            const { registeredTypes, availableTypes, recoveryTypes, token } =
              secondFactor;
            const nextPath = location.state?.nextPath;
            history.push(SystemRoutes.Authenticate, {
              registeredTypes,
              availableTypes,
              recoveryTypes,
              token,
              loginTokens: tokens,
              nextPath,
              userEmail,
            });
          }
        }}
        onChange={(value) => setUserEmail(value.email)}
        renderSubmitting={false}
        renderError={false}
        renderForm={(
          formProps,
          { submitting, slowSubmitting, error },
          events,
        ) => (
          <React.Fragment>
            <h3>{t("Welcome_Back")}</h3>
            <h2>{t("Log_In")}</h2>
            <Form {...formProps} className="login-form">
              <Form.Email
                field="email"
                label={t("Email")}
                requiredError={t("Email") + " " + t("requiredError")}
                placeholder={t("Enter_email") + "..."}
                className="input-email"
                disabled={submitting && slowSubmitting}
                autoFocus
                required
                fluid
              />
              <Form.Password
                field="password"
                label={t("Password")}
                requiredError={t("Password") + " " + t("requiredError")}
                placeholder={t("Enter_password") + "..."}
                disabled={submitting && slowSubmitting}
                className="input-password"
                required
                fluid
              >
                <input autoComplete="current-password" />
              </Form.Password>
              <Button
                className="action-button btn-login"
                onClick={events.onSubmit}
                disabled={submitting}
                loading={submitting && slowSubmitting}
                fluid
              >
                {t("Sign_In")}
              </Button>
              <ErrorMessage
                header={t("Sign_In_Error")}
                error={error}
                overrideMessages={{
                  915004: t("Incorrect_email_address_or_password"),
                  915005: t("Account_is_inactive"),
                }}
                omitCodes
              />
              <div className="forgot-password">
                <span>{t("Forgotten_password")}?</span>
                <Link to={SystemRoutes.ForgottenPassword}>{t("Reset_it")}</Link>
              </div>
            </Form>
          </React.Fragment>
        )}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { loggedIn } = getAuthState(state);
  const { emailVerified } = getAuthClaims(state);
  const { useEmailVerification } = getAuthOptions();

  const acceptUsernames = [];
  const { "member-email": memberEmail } = getPersistentQueryParamState(state);
  memberEmail && acceptUsernames.push(memberEmail);

  /*
   * if we are relying on usernames stored in state, then use the
   * page unload event to catch accidental reloading and potential
   * loss of data in state
   */
  const useUnloadEvent =
    !process.env.DISABLE_CRM_LINK_UNLOAD_EVENT && acceptUsernames.length > 0;

  return {
    loggedIn,
    emailVerified,
    useEmailVerification,
    acceptUsernames,
    useUnloadEvent,
  };
};

export default connect(mapStateToProps)(Login);
