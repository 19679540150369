import React from "react";
import { useTranslation } from "react-i18next";
import { Format } from "@redriver/cinnamon";
import DataGrid from "./DataGrid";

const InvestmentPayoutGrid = ({ data }) => {
  const { t } = useTranslation("capitalCalc", {
    keyPrefix: "infoModal.funderTypes.investmentPayout",
  });
  const subHeaderKeys = [null, t("total"), t("funder"), t("supplier")];

  return (
    <DataGrid
      className="investment-payout"
      columnCount={4}
      isFirstRowHeader
      dataRows={[
        { className: "", values: subHeaderKeys },
        [
          t("capitalInvestment"),
          <Format.Number
            key={1}
            value={data.totalCapitalRow.total}
            format={process.env.CURRENCY_CODE}
          />,
          <Format.Number
            key={2}
            value={data.totalCapitalRow.funderAmount}
            format={process.env.CURRENCY_CODE}
          />,
          <Format.Number
            key={3}
            value={data.totalCapitalRow.balance}
            format={process.env.CURRENCY_CODE}
          />,
        ],
        [
          t("supplierLiquidity"),
          <Format.Number
            key={4}
            value={data.dealerLiquidityRow.total}
            format={process.env.CURRENCY_CODE}
          />,
          <Format.Number
            key={5}
            value={data.dealerLiquidityRow.funderAmount}
            format={process.env.CURRENCY_CODE}
          />,
          <Format.Number
            key={6}
            value={data.dealerLiquidityRow.balance}
            format={process.env.CURRENCY_CODE}
          />,
        ],
        [
          t("ipsLiquidity"),
          <Format.Number
            key={7}
            value={data.ipsLiquidityRow.total}
            format={process.env.CURRENCY_CODE}
          />,
          <Format.Number
            key={8}
            value={data.ipsLiquidityRow.funderAmount}
            format={process.env.CURRENCY_CODE}
          />,
          <Format.Number
            key={9}
            value={data.ipsLiquidityRow.balance}
            format={process.env.CURRENCY_CODE}
          />,
        ],

        {
          className: "bold",
          values: [
            t("payout"),
            <Format.Number
              key={10}
              value={data.payoutCapitalRow.total}
              format={process.env.CURRENCY_CODE}
            />,
            <Format.Number
              key={11}
              value={data.payoutCapitalRow.funderAmount}
              format={process.env.CURRENCY_CODE}
            />,
            <Format.Number
              key={12}
              value={data.payoutCapitalRow.balance}
              format={process.env.CURRENCY_CODE}
            />,
          ],
        },
      ]}
    />
  );
};

export default InvestmentPayoutGrid;
