export const updateLfItems = (newSheetData) => {
  for (let x = 0; x < (newSheetData.largeFormatGroups || []).length; ++x) {
    let group = newSheetData.largeFormatGroups[x];

    // Deleting Printer & Ink meter removes everything else.
    if ((group.printerMeter || {}).pendingDelete) {
      newSheetData.largeFormatGroups[x] = undefined;
      continue;
    }

    group.order = x;
    group.paperMeters = (group.paperMeters || []).filter(
      (x) => !x.pendingDelete
    );

    if ((group.otherMeter || {}).pendingDelete) {
      group.otherMeter = undefined;
      continue;
    }
    if ((group.scanMeter || {}).pendingDelete) {
      group.scanMeter = undefined;
      continue;
    }
  }

  newSheetData.largeFormatGroups = newSheetData.largeFormatGroups.filter(
    (x) => x
  );
};
