import React from "react";
import PropTypes from "prop-types";
import { RateType } from "features/../../../shared/constants/enums";
import GenericRateTable from "./GenericRateTable";

const RateMatrix = ({ rates, loading, activeTab, filters, headerTabs }) => {
  const title =
    (filters ? filters.financeType + " " : "") + RateType[activeTab];

  return (
    <GenericRateTable
      columns={rates[activeTab].columns}
      rows={rates[activeTab].rows}
      loading={loading}
      title={title}
      headerTabs={headerTabs}
    />
  );
};

RateMatrix.propTypes = {
  rates: PropTypes.shape({
    DealerNetRate: PropTypes.shape({
      rateType: PropTypes.string,
      columns: PropTypes.arrayOf(PropTypes.string),
      rows: PropTypes.arrayOf(
        PropTypes.shape({ values: PropTypes.arrayOf(PropTypes.number) })
      ),
    }),
    GrossSalesRate: PropTypes.shape({
      rateType: PropTypes.string,
      columns: PropTypes.arrayOf(PropTypes.string),
      rows: PropTypes.arrayOf(
        PropTypes.shape({ values: PropTypes.arrayOf(PropTypes.number) })
      ),
    }),
  }),
  loading: PropTypes.bool,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
};

export default RateMatrix;
