import React from "react";
import { ModalBuilder, Modal, Table } from "@redriver/cinnamon";
import { resendVerification } from "./actions";

const ResendEmailVerification = ({
  as: As = Table.MenuItem,
  user,
  onSubmitted,
  ...props
}) => (
  <ModalBuilder
    submitAction={resendVerification}
    submitParams={{ userId: user.id }}
    onSubmitted={onSubmitted}
    renderTrigger={(showModal) => (
      <As onClick={showModal} {...props}>
        Resend Email Verification
      </As>
    )}
    renderModal={(modalProps) => (
      <Modal.Confirmation {...modalProps} header="Resend Verification Email">
        <p>
          Are you sure you wish to resend the verification email for{" "}
          {user.firstName} {user.lastName}?
        </p>

        <p>This will be sent to {user.email}</p>
      </Modal.Confirmation>
    )}
  />
);

export default ResendEmailVerification;
