import React from "react";
import { Form } from "@redriver/cinnamon";
import {
  LargeFormatPaperInclusiveDropdownLookup,
  RollMediaNameDropdownLookup,
} from "features/../../../shared/features/Settings/LargeFormat/lookups";
import RollMediaCalculation from "./RollMediaCalculation";
import { useTranslation } from "react-i18next";
import { FormArrayDeleteModal } from "features/../../../shared/components/forms";

const LargeFormatMedia = ({ sheetId, lfPrinterConfig, fields, disabled }) => {
  const { t } = useTranslation();
  return (
    <div className="large-format-values roll-fed-media">
      <FormArrayDeleteModal
        disabled={disabled}
        triggerClassName="remove-media"
        nameOfRemoval={t(
          "systemSchedule:largeFormatModal:rollFedMediaInclusive:remove"
        )}
      />

      <div className="inputs-section">
        <LargeFormatPaperInclusiveDropdownLookup
          field="paperWidthId"
          labelMetric={t(
            "systemSchedule:largeFormatModal:rollFedMediaInclusive:fields:widthmm:label"
          )}
          labelImperial={t(
            "systemSchedule:largeFormatModal:rollFedMediaInclusive:fields:widthInches:label"
          )}
          paperWidthmm={lfPrinterConfig.paperWidthmm}
          paperWidthInches={lfPrinterConfig.paperWidthInches}
          sheetId={sheetId}
          placeholder={t(
            "systemSchedule:largeFormatModal:rollFedMediaInclusive:fields:widthmm:placeholder"
          )}
          className="media-width"
          disabled={disabled}
        />
        <RollMediaNameDropdownLookup
          field="paperInclusiveId"
          paperWidthId={fields.paperWidthId}
          sheetId={sheetId}
          placeholder={t(
            "systemSchedule:largeFormatModal:rollFedMediaInclusive:fields:mediaDescription:placeholder"
          )}
          className="media-selected"
          ddDisabled={disabled || !fields.paperWidthId}
        />
        {/* When Paper Width changes, clear Roll Media */}
        <Form.Trigger
          field="paperWidthId"
          event="onChange"
          action="clear"
          match={({ fields }) => fields.paperWidthId == null}
          target="paperInclusiveId"
        />
        {/* When Paper Width is cleared (it clears lfPaperInclusive somehow), then clear Roll Media */}
        <Form.Trigger
          field="lfPaperInclusive"
          event="onClear"
          action="clear"
          match={({ fields }) => fields.lfPaperInclusive == null}
          target="paperInclusiveId"
        />
      </div>
      <div className="values-section">
        <Form.Object field="lfRollMedia">
          <Form.Input
            field="weight"
            disabled
            label={t(
              "systemSchedule:largeFormatModal:rollFedMediaInclusive:fields:mediaWeight:label"
            )}
          />
          <Form.Input
            field="length"
            disabled
            label={t(
              "systemSchedule:largeFormatModal:rollFedMediaInclusive:fields:totalLength:label"
            )}
          />
          <Form.Numeric
            currency
            decimalPlaces={2}
            subLabel={process.env.MAJOR_CURRENCY}
            subLabelPosition="left"
            field="cost"
            disabled
            label={t(
              "systemSchedule:largeFormatModal:rollFedMediaInclusive:fields:calcCostPrice:label"
            )}
            formatValue={true}
          />
        </Form.Object>
      </div>
      <br />
      <div className="totals-section">
        <RollMediaCalculation lfRollMedia={fields.lfRollMedia} />
      </div>
    </div>
  );
};

export default LargeFormatMedia;
