import React from "react";
import { Route, Switch } from "react-router-dom";
import { AppRoutes } from "constants/routes";
import NotificationPage from "./NotificationPage";

const NotificationsRouter = () => (
  <Switch>
    <Route exact path={AppRoutes.Notification} component={NotificationPage} />
  </Switch>
);

export default NotificationsRouter;
