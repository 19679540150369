import React from "react";
import { useTranslation } from "react-i18next";
import { ModalBuilder, Form, Table } from "@redriver/cinnamon";
import { setSheetOwners } from "../actions";
import { withRouter } from "react-router-dom";
import { UserTypeAhead } from "features/Settings";
import { withCurrentUser } from "features/../../../shared/components/auth";
import { TranslatedModal } from "features/../../../shared/components/modals";

const SetSheetOwner = ({
  sheetId,
  ownedBy = {},
  dealerId,
  onSubmitted,
  currentUser,
}) => {
  const { t } = useTranslation("agreements");
  const { isInternalUser } = currentUser;
  return (
    <ModalBuilder
      withForm
      submitAction={setSheetOwners}
      submitParams={{ sheetId, dealerId }}
      initialData={{
        ownedById: ownedBy?.id,
        ownedByName: ownedBy?.name,
      }}
      onSubmitted={onSubmitted}
      renderTrigger={(trigger) => (
        <Table.MenuItem onClick={trigger}>
          {t("SheetActions.SetOwner.TriggerText")}
        </Table.MenuItem>
      )}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal
          {...modalProps}
          header={t("SheetActions.SetOwner.HeaderText")}
          size="small"
        >
          <Form {...formProps}>
            <UserTypeAhead
              clearable
              fluid
              field="ownedById"
              textField="ownedByName"
              label={t("SheetActions.SetOwner.Owner_Label")}
              placeholder={t("SheetActions.SetOwner.Owner_Placeholder")}
              lookupParams={{
                includeInternalUsers: isInternalUser,
                dealerId,
              }}
            />
          </Form>
        </TranslatedModal>
      )}
    />
  );
};

export default withCurrentUser(withRouter(SetSheetOwner));
