import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder } from "@redriver/cinnamon";
import {
  getClient,
  updateClient
} from "features/../../../shared/features/Clients/actions";
import ClientForm from "./ClientForm";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "../../../../../shared/components/modals";

const EditClient = ({ clientId, onSubmitted }) => {
  const  { t }  = useTranslation('clients', { keyPrefix:'editModal'});
  return (
    <ModalBuilder
      withForm
      loadAction={getClient}
      loadParams={clientId}
      submitAction={updateClient}
      submitParams={clientId}
      onSubmitted={onSubmitted}
      renderTrigger={showModal => (
        <Button icon="edit" onClick={showModal} className="edit-button" />
      )}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Edit
          {...modalProps}
          header={t("header")}
          submitLabel={t("submitLabel")}
        >
          <ClientForm {...formProps} notCreate={true} isEdit={true} />
        </TranslatedModal.Edit>
      )}
    />
  );
};

export default EditClient;
