import React from "react";
import { Grid } from "semantic-ui-react";
import classNames from "classnames";
import { Form } from "@redriver/cinnamon";

const MeterReadingItem = ({ isGreen }) => {
  return (
    <Form.Array arrayKey="id" field="meterData">
      {({ arrayIndex }) => (
        <Grid.Row
          className={classNames(
            "meter-reading-item",
            isGreen ^ (arrayIndex == 1) && "even"
          )}
        >
          <Grid.Column width={1}>
            <Form.Input readOnly field="applicableItem" />
          </Grid.Column>
          <Grid.Column width={3}>
            <Form.Input readOnly field="description" />
          </Grid.Column>
          <Grid.Column width={2}>
            <Form.Input readOnly field="model" />
          </Grid.Column>
          <Grid.Column width={3}>
            <Form.Input readOnly field="statusText" />
          </Grid.Column>
          <Grid.Column width={2}>
            <Form.Input readOnly field="idNumber" />
          </Grid.Column>
          <Grid.Column width={1}>
            <Form.Input readOnly width="2" field="tier" />
          </Grid.Column>
          <Grid.Column width={1}>
            <Form.Input readOnly width="2" field="meterCode" />
          </Grid.Column>
          <Grid.Column width={2}>
            <Form.Numeric
              width="2"
              decimalPlaces={0}
              minValue={0}
              field="startMeterReading"
            />
          </Grid.Column>
        </Grid.Row>
      )}
    </Form.Array>
  );
};

export default MeterReadingItem;
