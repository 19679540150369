import React from "react";
import { Grid } from "semantic-ui-react";
import { Format } from "@redriver/cinnamon";

const RateTable = ({ columns, rows }) => (
  <React.Fragment>
    <div className="rates-table-container">
      <Grid rows={rows.length} className="rates-table-header">
        <Grid.Row columns={columns.length || 8}>
          {columns.map((c, i) => (
            <Grid.Column key={c}>
              <p>{c}</p>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
      <Grid rows={rows.length} className="rates-table">
        {rows.map((r, ri) => (
          <Grid.Row key={ri} columns={r.values.length}>
            {r.values.map((c, i) => (
              <Grid.Column key={i}>
                {i > 1 ? (
                  <Format.Number decimalPlaces={{ min: 0, max: 3 }} value={c} />
                ) : (
                  <p>{c}</p>
                )}
              </Grid.Column>
            ))}
          </Grid.Row>
        ))}
      </Grid>
    </div>
  </React.Fragment>
);

export default RateTable;
