import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { getAuthState, getAuthClaims } from "@redriver/cinnamon";
import { validateEmailToken } from "./actions";
import { AppRoutes, SystemRoutes } from "constants/routes";
import EmailVerificationResender from "./EmailVerificationResender";

const EmailVerification = ({
  token,
  loggedIn,
  emailVerified,
  validateEmailToken,
}) => {
  const { t } = useTranslation("system");
  const [tokenValid, setTokenValid] = useState(null);

  useEffect(() => {
    const validate = async () => {
      const validationResult = await validateEmailToken(token);
      setTokenValid(validationResult);
    };
    validate();
  }, [token, validateEmailToken]);

  if (emailVerified) {
    return <p>{t("Email_Verified")}</p>;
  }

  if (!token) {
    return <p>{t("No_email_token_supplied")}</p>;
  }

  switch (tokenValid) {
    case true:
      return (
        <div>
          <p>{t("Email_Verified")}</p>
          <div style={{ marginTop: 20 }}>
            <Button
              primary
              as={Link}
              to={loggedIn ? AppRoutes.Root : SystemRoutes.Login}
            >
              {t("Continue")}
            </Button>
          </div>
        </div>
      );

    case false:
      return (
        <div>
          <p>{t("Email_token_expired")}</p>
          <EmailVerificationResender />
        </div>
      );

    default:
      return <p>{t("Validating_email_token")}...</p>;
  }
};

EmailVerification.propTypes = {
  token: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { loggedIn } = getAuthState(state);
  const { emailVerified } = getAuthClaims(state);
  return {
    loggedIn,
    emailVerified,
  };
};

export default connect(mapStateToProps, { validateEmailToken })(
  EmailVerification,
);
