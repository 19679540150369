import React, { useState } from "react";
import classNames from "classnames";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Form } from "@redriver/cinnamon";
import { editDealerOfficeHeaderImage } from "./actions";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { useTranslation } from "react-i18next";

const EditDealerOfficeOfficeHeaderImage = ({ dealerOfficeId, onUpdate }) => {
  const [imageData, setImageData] = useState(null);
  const { t } = useTranslation("supplierOffices", {
    keyPrefix: "supplierHeaderImage",
  });

  const onChangeDealerOfficeHeaderImage = (file) => {
    let fileReader = new FileReader();
    fileReader.onload = () => {
      const res = fileReader.result;
      setImageData(res);
    };
    fileReader.readAsDataURL(file);
  };

  return (
    <ModalBuilder
      withForm
      submitAction={editDealerOfficeHeaderImage}
      submitParams={{ imageData, dealerOfficeId }}
      onSubmitted={onUpdate}
      renderTrigger={(showModal) => (
        <Button icon="edit" onClick={showModal} className="edit-button" />
      )}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Edit
          {...modalProps}
          header={t("header")}
          submitLabel={t("submitLabel")}
        >
          <Form {...formProps}>
            <Form.Group widths="equal">
              <Form.FileUpload
                field="image"
                label={t("officeHeaderImage")}
                fluid
                className={classNames(
                  "file-upload",
                  formProps &&
                    formProps.value &&
                    formProps.value.officeHeaderImage &&
                    "file-selected"
                )}
                uploadText={t("uploadText")}
                fileExtensionTypes={["png", "jpg", "jpeg", "svg"]}
                customValidator={(_, __, ___, { value }) => {
                  !!value?.image &&
                    onChangeDealerOfficeHeaderImage(value?.image);
                }}
              />
            </Form.Group>
          </Form>
        </TranslatedModal.Edit>
      )}
    />
  );
};

export default EditDealerOfficeOfficeHeaderImage;
