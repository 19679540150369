import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { FormBuilder, Form, ErrorMessage } from "@redriver/cinnamon";
import { SystemRoutes } from "constants/routes";
import { validateResetToken, requestPasswordReset } from "./actions";
import PasswordForgotten from "../PasswordForgotten";

const PasswordReset = ({ token }) => {
  const { t } = useTranslation("system");
  const [tokenValid, setTokenValid] = useState(null);
  const [resetSuccess, setResetSuccess] = useState(false);

  if (!token) return <p>{t("No_reset_token_supplied")}</p>;

  if (tokenValid === false) {
    return (
      <div>
        <p>{t("invalid_reset_token")}</p>
        <PasswordForgotten />
      </div>
    );
  }

  if (resetSuccess) {
    return (
      <div>
        <p>{t("Your_password_has_been_reset")}</p>
        <div style={{ marginTop: 20 }}>
          <Button
            as={Link}
            to={SystemRoutes.Login}
            fluid
            className="action-button"
          >
            {t("Continue")}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <FormBuilder
      loadAction={validateResetToken}
      loadParams={token}
      onLoaded={(response) => setTokenValid(!!response && response.ok)}
      submitAction={requestPasswordReset}
      submitParams={token}
      onSubmitted={() => setResetSuccess(true)}
      renderLoading={false}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { loading, slowLoading, submitting, slowSubmitting, error },
        events
      ) => {
        if (loading && slowLoading) {
          return <p>{t("Validating_reset_token")}...</p>;
        }
        if (!tokenValid) return null;

        return (
          <Form {...formProps}>
            <Form.Password
              field="newPassword"
              label={t("New_Password")}
              requiredError={t("New_Password") + " " + t("requiredError")}
              placeholder={t("Enter_new_password")}
              disabled={submitting && slowSubmitting}
              autoFocus
              required
              minLength={8}
            >
              <input autoComplete="new-password" />
            </Form.Password>
            <Form.Password
              field="confirmPassword"
              label={t("Confirm_Password")}
              requiredError={t("Confirm_Password") + " " + t("requiredError")}
              placeholder={t("Confirm_new_password")}
              disabled={submitting && slowSubmitting}
              required
              confirmField="newPassword"
            >
              <input autoComplete="new-password" />
            </Form.Password>
            <Button
              onClick={events.onSubmit}
              disabled={submitting}
              loading={submitting && slowSubmitting}
              fluid
              className="action-button"
            >
              {t("Reset")}
            </Button>
            <ErrorMessage
              header={t("Something_went_wrong")}
              error={error}
              omitCodes
              overrideMessages={{
                913005: t("Password_to_common"),
              }}
            />
          </Form>
        );
      }}
    />
  );
};

PasswordReset.propTypes = {
  token: PropTypes.string.isRequired,
};

export default PasswordReset;
