export const navigateForm = (target, increment, form, index) => {

  form.elements[index + increment] && form.elements[index + increment].type == "text" &&
  form.elements[index + increment].focus();

}

//horizontalOrientation - form.element index runs horizontally, if index runs vertically set horizontalOrientation to false.
export const navigateGridForm = (e, horizontalIncrement, verticalIncrement, horizontalOrientation = true) => {

  var form = e.target.form;
  var index = Array.prototype.indexOf.call(form, e.target);

  //tab to next form element on right arrow key
  if(e.keyCode === 39 && ((index + 1) % verticalIncrement != 0 || !horizontalOrientation)){
      navigateForm(e.target, horizontalIncrement, form, index);
  }
  //tab to item above on up key
  if(e.keyCode === 38 && ((index) % horizontalIncrement != 0 || horizontalOrientation)){
    navigateForm(e.target, -verticalIncrement, form, index);
  }
  //tab to previous form element on left arrow key
  if(e.keyCode === 37 && ((index) % verticalIncrement != 0 || !horizontalOrientation)){
    navigateForm(e.target, -horizontalIncrement, form, index);
  }
  //tab to item beneath on down key
  if(e.keyCode === 40 && ((index + 1) % horizontalIncrement != 0 || horizontalOrientation)){
    navigateForm(e.target, verticalIncrement, form, index);
  }
}