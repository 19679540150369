import {
  requestIsBusy,
  requestIsSuccess,
  requestResponse,
} from "@redriver/cinnamon";
import { produce } from "immer";
import { setDefaults } from "./SetDefaults";
import { calculateTotalCosts, calculateTotals } from "../Calculation";
import {
  calculateServicesTotals,
  concatenateServiceArrays,
  getWizardSplitTotals,
  sumValues,
  getLFPItems,
} from "../../helpers";
import { updateServicesArrays } from "./UpdateServicesArrays";
import { checkSectionsCompleted } from "./CheckSectionsCompleted";
import { updateSettlementAndCoTerminusArrays } from "./UpdateSettlementAndCoTerminusArrays";
import { updateLargeFormatScheduleItems } from "../Schedules";
import { mergeData } from "./MergeData";
import { stringifyNullableBool } from "components/forms";
import { calculateComparisonSheetData } from "../../ComparisonSheet/Utils";
import { emptyCreateModal } from "../../ScheduleStep/constants";
import { UtilityMeterDisplayCode } from "../../constants";

export const loadSheetDetails = (state, action, initialState) => {
  if (requestIsBusy(action) || !requestResponse(action)) {
    return {
      ...state,
      sheetDetailsLoading: requestIsBusy(action),
      loadFailed: !requestIsSuccess(action) && !requestIsBusy(action),
    };
  }

  const response = requestResponse(action);

  const data = setDefaults(
    { ...state.sheetData, ...response },
    response.defaults,
  );

  const cofundedTotalCosts = calculateTotalCosts(data.capital.cofunded);
  const dealerTotalCosts = calculateTotalCosts(data.capital.dealer);
  const funderTotalCosts = calculateTotalCosts(data.capital.funder);

  const cofundedTotals = data.capital.cofundedSelected.value
    ? calculateTotals(data.capital.cofunded)
    : getWizardSplitTotals();
  const dealerTotals = data.capital.dealerFunderSelected.value
    ? calculateTotals(data.capital.dealer)
    : getWizardSplitTotals();
  const funderTotals = data.capital.funderSelected.value
    ? calculateTotals(data.capital.funder)
    : getWizardSplitTotals();

  updateServicesArrays(data.services);
  const sectionsCompleted = checkSectionsCompleted(data, false);

  const servicesTotal = calculateServicesTotals(
    concatenateServiceArrays(data.services),
    action.customData.serviceData,
    parseFloat(data.capital.totalDevicesOnSchedule.value),
  );

  updateSettlementAndCoTerminusArrays(data.capital.funder);
  updateSettlementAndCoTerminusArrays(data.capital.cofunded);
  updateSettlementAndCoTerminusArrays(data.capital.dealer);

  updateLargeFormatScheduleItems(data.largeFormatGroups);

  let mergedCapital = mergeData(state.sheetData.capital, data.capital);

  const { userType, userArea } = action.customData;

  const newState = produce(state, (draftState) => {
    const d = draftState;

    d.sheetDetailsLoading = false;
    d.ratesCompleted = sectionsCompleted.rates;
    d.capitalCompleted = sectionsCompleted.capital;
    d.servicesCompleted = sectionsCompleted.services;
    d.scheduleCompleted = sectionsCompleted.schedule;
    d.scheduleStabilityCompleted = sectionsCompleted.scheduleStability;
    d.paperCompleted = sectionsCompleted.paper;
    d.paperHasContent = sectionsCompleted.paperHasContent;
    d.largeFormatCompleted = sectionsCompleted.largeFormat;
    d.sheetData = {
      ...data,
      impersonatedUser: { ...state.sheetData.impersonatedUser },
      isDirty: false,
      ipsRates: mergeData(state.sheetData.ipsRates, data.ipsRates),
      rates: mergeData(state.sheetData.rates, data.rates),
      capital: {
        ...mergedCapital,
        funder: {
          ...mergedCapital.funder,
          settlements: data.capital.funder.settlements,
          settlementsTotal: sumValues(data.capital.funder.settlements),
          adminSettlements: data.capital.funder.adminSettlements,
          adminSettlementsTotal: sumValues(
            data.capital.funder.adminSettlements,
          ),
          ipSettlements: data.capital.funder.ipSettlements,
          ipSettlementsTotal: sumValues(data.capital.funder.ipSettlements),
          coTerminusRentals: data.capital.funder.coTerminusRentals,
          ipCoTerminusRentals: data.capital.funder.ipCoTerminusRentals,
        },
        dealer: {
          ...mergedCapital.dealer,
          settlements: data.capital.dealer.settlements,
          settlementsTotal: sumValues(data.capital.dealer.settlements),
          adminSettlements: data.capital.dealer.adminSettlements,
          adminSettlementsTotal: sumValues(
            data.capital.dealer.adminSettlements,
          ),
          ipSettlements: data.capital.dealer.ipSettlements,
          ipSettlementsTotal: sumValues(data.capital.dealer.ipSettlements),
          coTerminusRentals: data.capital.dealer.coTerminusRentals,
          ipCoTerminusRentals: data.capital.dealer.ipCoTerminusRentals,
        },
        cofunded: {
          ...mergedCapital.cofunded,
          settlements: data.capital.cofunded.settlements,
          settlementsTotal: sumValues(data.capital.cofunded.adminSettlements),
          adminSettlements: data.capital.cofunded.adminSettlements,
          adminSettlementsTotal: sumValues(
            data.capital.cofunded.adminSettlements,
          ),
          ipSettlements: data.capital.cofunded.ipSettlements,
          ipSettlementsTotal: sumValues(data.capital.cofunded.ipSettlements),
          coTerminusRentals: data.capital.cofunded.coTerminusRentals,
          ipCoTerminusRentals: data.capital.cofunded.ipCoTerminusRentals,
        },
        cofundedTotal: cofundedTotals.getTotal(userType, userArea),
        funderTotal: funderTotals.getTotal(userType, userArea),
        dealerTotal: dealerTotals.getTotal(userType, userArea),
        cofundedTotalCosts: cofundedTotalCosts,
        funderTotalCosts: funderTotalCosts,
        dealerTotalCosts: dealerTotalCosts,
      },
      services: {
        servicesDisableMinVolsLogic: data.services.servicesDisableMinVolsLogic,
        services: data.services.services,
        adminServices: data.services.adminServices,
        ipServices: data.services.ipServices,
        mandatoryServices: data.services.mandatoryServices,
        servicesTotal: servicesTotal.getTotal(userType, userArea),
      },
      paperInclusive: {
        ...state.sheetData.paperInclusive,
        ...data.paperInclusive,
        isPaperInclusive: {
          ...data.paperInclusive.isPaperInclusive,
          value: !!data.paperInclusive.isPaperInclusive.value,
        },
        isCostEffectiveA3: {
          ...data.paperInclusive.isCostEffectiveA3,
          value: stringifyNullableBool(
            data.paperInclusive.isCostEffectiveA3.value,
          ),
        },
      },
      largeFormatCreateModal: emptyCreateModal,
      comparisonData:
        calculateComparisonSheetData(data, null) || initialState.comparisonData,
    };
    d.comparisonCopies = {
      paperInclusive: data.paperInclusive,
    };

    d.scheduleContent =
      d.sheetData.systemScheduleItems.length +
      getLFPItems(d.sheetData.largeFormatGroups).length;
    const systemScheduleItemsCount = d.sheetData.systemScheduleItems.filter(
      (x) => x.addedAsUtility,
    ).length;

    const largeFormatItems = getLFPItems(d.sheetData.largeFormatGroups);
    d.largeFormatGroupsItems = largeFormatItems;
    const lfpItemsCount = largeFormatItems.length;

    draftState.utilityMeters = systemScheduleItemsCount + lfpItemsCount;
    (d.servicesContent = servicesTotal),
      (d.capitalContent = getWizardSplitTotals(
        dealerTotals.salesperson +
          funderTotals.salesperson +
          cofundedTotals.salesperson,
        dealerTotals.dealerAdmin +
          funderTotals.dealerAdmin +
          cofundedTotals.dealerAdmin,
        dealerTotals.ipsAdmin + funderTotals.ipsAdmin + cofundedTotals.ipsAdmin,
      ));
  });
  return newState;
};
