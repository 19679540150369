import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { Form, FormBuilder, Format } from "@redriver/cinnamon";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { IconButton } from "features/../../../shared/components/buttons";
import { getYieldBands, setYieldBands } from "./actions";
import { withCurrentUser } from "features/../../../shared/components/auth";
import Pen from "features/../../../shared/assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import { useTranslation } from "react-i18next";
import { navigateGridForm } from "features/../../../shared/components/forms/formHelper";

const YieldBands = ({
  rateGroupId,
  paymentMethod,
  funderType,
  financeType,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [originalData, setOriginalData] = useState({ yieldBands: [] });
  const [formData, setFormData] = useState({ yieldBands: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getYieldBands({ rateGroupId })).then(result => {
      setOriginalData(result.response);
      setLoading(false);
    });
  }, [rateGroupId]);

  useEffect(() => {
    resetData();
  }, [paymentMethod, funderType, financeType, originalData]);

  const resetData = () => {
    let yieldBands = (originalData || {}).yieldBands || [];
    const td = yieldBands.filter(x => {
      return (
        x.financeType === financeType &&
        x.funderType === funderType &&
        x.paymentMethod === paymentMethod
      );
    });
    setFormData({ yieldBands: td });
  };

  const updateOriginalData = (response, formData) => {
    const newOrigData = { yieldBands: [] };
    originalData.yieldBands.forEach(x => {
      var f = formData.yieldBands.filter(yb => {
        return yb.id === x.id;
      });
      if (f && f.length === 1) {
        x.upperValue = f[0].upperValue;
        x.lowerValue = f[0].lowerValue;
      }
      newOrigData.yieldBands.push(x);
    });
    setOriginalData(newOrigData);
  };

  return (
    <div className="yield-bands-grid">
      <FormBuilder
        value={formData}
        onChange={fd => {
          setFormData(fd);
        }}
        initialReadOnly
        loading={loading}
        submitAction={setYieldBands}
        submitParams={{ rateGroupId, paymentMethod, funderType, financeType }}
        onSubmitted={x => updateOriginalData(x, formData)}
        renderForm={(formProps, state, events) => {
          if (!formProps.value) return null;
          const yieldBandsArray = formProps.value.yieldBands;
          if (!yieldBandsArray) return null;
          return (
            <Form
              {...formProps}
              onKeyDown={e => {
                navigateGridForm(e, 2, 1, false);
              }}
            >
              <div className="content-container">
                <div className="defaults-page-title flex">
                  <div className="flex title-bar">
                    <h4 className="title">
                      {t("supplierOffices:yieldBands:title")}
                    </h4>
                  </div>
                  <PermissionCheck
                    target={Targets.YieldBands}
                    action={Actions.Edit}
                  >
                    <div className="flex">
                      <IconButton
                        primary
                        icon={state.readOnly ? Pen : Save}
                        content={
                          state.readOnly
                            ? t("shared:buttons:edit")
                            : t("shared:buttons:save")
                        }
                        floated="right"
                        onClick={() =>
                          state.readOnly
                            ? events.onToggleReadOnly()
                            : events.onSubmit({ reset: ["readOnly"] })
                        }
                        className={
                          !state.readOnly ? "positive-title-action" : null
                        }
                      />
                      {!state.readOnly && (
                        <Button
                          className="cancel-title-action"
                          floated="right"
                          content={t("shared:buttons:cancel")}
                          onClick={() => {
                            resetData();
                            events.onReset();
                          }}
                        />
                      )}
                    </div>
                  </PermissionCheck>
                </div>

                <div className="yields-content horizontal-scrollbar">
                  <div className="table-title">
                    <div>
                      <strong></strong>
                    </div>
                    <div>
                      <strong>
                        {t("supplierOffices:yieldBands:lowerValue")}
                      </strong>
                    </div>
                    <div>
                      <strong>
                        {t("supplierOffices:yieldBands:upperValue")}
                      </strong>
                    </div>
                  </div>
                  <Form.Array field="yieldBands" arrayKey="id">
                    <div className="yield-band-column">
                      <Form.Input field="threshold" readOnly={true} />
                      <Form.Numeric
                        field="lowerValue"
                        required
                        decimalPlaces={3}
                        minValue={0}
                        renderReadOnly={(props, state) => (
                          <Format.Number
                            format={process.env.CURRENCY_CODE}
                            thousandSeparator
                            value={props.value}
                          />
                        )}
                      />
                      <Form.Numeric
                        field="upperValue"
                        required
                        decimalPlaces={3}
                        minValue={0}
                        renderReadOnly={(props, state) => (
                          <Format.Number
                            format={process.env.CURRENCY_CODE}
                            thousandSeparator
                            value={props.value}
                          />
                        )}
                      />
                    </div>
                  </Form.Array>
                </div>
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
};

export default withCurrentUser(YieldBands);
