import React from "react";
import { Lookup, registerLookup, apiGet, Form } from "@redriver/cinnamon";

const NAMESPACE = "DEALERS/LOOKUPS";

const ActionTypes = {
  GetDealers: `${NAMESPACE}/GET_DEALERS`,
};

const LookupTypes = {
  GetDealers: "dealersLookup",
};

// Dealers TypeAhead
registerLookup(
  LookupTypes.GetDealers,
  ({ search, showSPV , includeFunderSuppliersOnly, funderId }) => apiGet(ActionTypes.GetDealers, "lookups/dealers", { search, pageSize: 10, showSPV, includeFunderSuppliersOnly, funderId}),
    {
      cache: false,
      transform: (data) =>
        data && Array.isArray(data)
          ? data.map(r => {
              return r.spv ? { value: r.value, text: r.text + " - " + r.spv  } :  { value: r.value, text: r.text } ;
            })
          : [],
    },
);
export const DealersTypeAheadLookup = props => (
  <Form.TypeAhead
    {...props}
    searchSuggestions
    alwaysShowSuggestionsOnBlur
    lookup={LookupTypes.GetDealers}
  />
);
