import React from "react";
import { PageLoader } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";
import RateTable from "./RateTable";

const GenericRateTable = ({ columns, rows, loading, title, headerTabs }) => {
  const { t } = useTranslation("supplierOffices", { keyPrefix: "rates" });
  return (
    <React.Fragment>
      <div className="table-header">
        <h4>{title}</h4>
        {headerTabs && (
          <div className="mobile-tablet-tab-headers">{headerTabs}</div>
        )}
      </div>
      {loading ? <PageLoader /> : <RateTable columns={columns} rows={rows} />}
    </React.Fragment>
  );
};

export default GenericRateTable;
