import React from "react";
import { Form } from "@redriver/cinnamon";
import {
  FixedInput,
  FixedString,
  FixedInputResponsiveLabel,
} from "components/forms";
import FormArrayDeleteModal from "features/../../../shared/components/forms/FormArrayDeleteModal";
import { getSheetDataState } from "features/Sheets";
import { useSelector } from "react-redux";
import { isFieldFixed } from "features/Sheets/ManageSheet";

const SettlementArray = ({
  field,
  sheetDetailsSaving,
  preventUpdates,
  className,
}) => {
  const { impersonatedUser } = useSelector((state) => {
    return getSheetDataState(state);
  });

  const hideFixedIndicator = !impersonatedUser.canEditAdminFields;

  return (
    <Form.Array field={field} arrayKey="id" propagateUpdates="always">
      {({ fields }) => {
        return (
          <div className="settlement-array-section">
            <FixedInput
              label={
                <FixedInputResponsiveLabel
                  label="Value"
                  className={`not-bold ${className || ""}`}
                />
              }
              field="value"
              currency
              formatNumber
              className="settlement-detail"
              minAllowValue={0}
              hideIndicator={hideFixedIndicator}
            />

            <FixedString
              label={
                <FixedInputResponsiveLabel
                  label="Reference"
                  className={`not-bold ${className || ""}`}
                />
              }
              field="ref"
              placeholder="Ref No..."
              className="settlement-detail"
              hideIndicator={hideFixedIndicator}
            />
            <div className="inline-checkbox">
              {/*
               * we're not using the Checkbox's default label
               * because of fiddly styling
               */}
              <label>U/G?</label>
              <Form.Checkbox field="isUpgrade" />
            </div>
            <FormArrayDeleteModal
              nameOfRemoval="Settlement"
              disabled={
                sheetDetailsSaving ||
                preventUpdates ||
                isFieldFixed(fields.value) ||
                fields.ref.isFixed
              }
            />
          </div>
        );
      }}
    </Form.Array>
  );
};

export default SettlementArray;
