import React from "react";
import { withRouter } from "react-router-dom";
import { Table } from "@redriver/cinnamon";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { DealerRoutes } from "constants/routes";
import DeleteDealerOffice from "./DeleteDealerOffice";
import { useTranslation } from "react-i18next";

const DealerOfficeActions = ({ dealerOffice, onRefresh, history }) => {

  const  { t }  = useTranslation('supplierOffices', { keyPrefix:'listPage'});

  return(
  <Table.Menu>
    <PermissionCheck target={Targets.DealerOffice} action={Actions.View}>
      <Table.MenuItem
        onClick={() => history.push(DealerRoutes.Overview(dealerOffice.id))}
      >
        {t("view")}
      </Table.MenuItem>
    </PermissionCheck>
    <PermissionCheck target={Targets.Dealer} action={Actions.Delete}>
      <DeleteDealerOffice dealerOffice={dealerOffice} onDeleted={onRefresh} as={Table.MenuItem}>
        {t("delete")}
      </DeleteDealerOffice>
    </PermissionCheck>
  </Table.Menu>
)};

export default withRouter(DealerOfficeActions);
