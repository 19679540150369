import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { ClientOverview } from "features/Clients";

const ClientPage = ({ match, clientName }) => (
  <PageContent>
    <ClientOverview clientId={match.params.id} clientName={clientName} />
  </PageContent>
);

export default ClientPage;
