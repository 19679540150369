import React from "react";
import { DataList } from "@redriver/cinnamon";
import { useSelector } from "react-redux";
import { getSheetLargeFormatScheduleState } from "features/Sheets";

const LargeFormatSummary = () => {
  const groups = useSelector(getSheetLargeFormatScheduleState);

  return (
    <div className="large-format-summary">
      <h3>Large Format Summary</h3>
      {Array.isArray(groups) && groups.length ? (
        groups.map((g, idx) => (
          <React.Fragment key={idx}>
            <h4>Group {g.order + 1}</h4>
            {dli("Printer", g.printerName)}
            {dli("Ink Usage", g.inkInclusiveName || "-")}
            {g.paperMeters.length && (
              <React.Fragment>
                <h5>Paper</h5>
                <table className="paper-meters">
                  <thead>
                    <th>Code</th>
                    <th>Width</th>
                    <th>Media</th>
                  </thead>
                  <tbody>
                    {g.paperMeters.map((pm, idxx) => (
                      <tr key={idxx}>
                        <td>{pm.displayCode}</td>
                        <td>{pm.paperWidth}</td>
                        <td>{pm.paperInclusiveName}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </React.Fragment>
            )}
            <hr />
          </React.Fragment>
        ))
      ) : (
        <p>No Large Format Items</p>
      )}
    </div>
  );
};

export default LargeFormatSummary;

const dli = (name, value) => (
  <DataList.Item title={name}>{value}</DataList.Item>
);
