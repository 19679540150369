export const mergeData = (existingData, newData) => {
    return Object.keys(newData).reduce((acc, key) => {
      if (newData[key] === null) return acc;
      if (Array.isArray(newData[key])) {
        return {
          ...acc,
          [key]: mergeArrayItems(existingData[key], newData[key]),
        };
      }
      if (typeof newData[key] === "object") {
        return {
          ...acc,
          [key]: mergeData(existingData[key], newData[key]),
        };
      }
      return {
        ...acc,
        [key]: newData[key],
      };
    }, existingData);
  };
  
  const mergeArrayItems = (existingArray, newArray) => {
    const res = newArray.reduce(
      (acc, item) => {
        const { value, maximumValue, minimumValue, setValue } = item.value;
        if (
          (value !== null && typeof value !== "object") ||
          maximumValue !== null ||
          minimumValue !== null ||
          setValue !== null
        ) {
          const position = existingArray.findIndex(c => c.id === item.id);
          if (position === -1) {
            if (acc.length === 1 && acc[0].id == 0) {
              return [item];
            }
            return [...acc, item];
          }
  
          // use .slice to create a copy of the array to prevent issues updating immutable objects
          return [
            ...acc.slice().splice(0, position),
            item,
            ...acc.slice().splice(position + 1, acc.length),
          ];
        }
        return acc;
      },
      Array.isArray(existingArray) ? existingArray : []
    );
    return res;
  };