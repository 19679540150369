import React from "react";
import { ModalBuilder, Form } from "@redriver/cinnamon";
import Remove from "features/../../../shared/assets/red-delete.svg";
import { IconButton } from "features/../../../shared/components/buttons";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "../modals";
import classNames from "classnames";

const FormArrayDeleteModal = ({
  nameOfRemoval,
  disabled,
  triggerClassName,
  onUserRemoved,
}) => {
  const { t } = useTranslation("shared");
  return (
    <ModalBuilder
      renderTrigger={(showModal) => (
        <IconButton
          onClick={showModal}
          icon={Remove}
          disabled={disabled}
          className={classNames("icon-only-button", triggerClassName)}
        />
      )}
      renderModal={(modalProps) => (
        <TranslatedModal.Confirmation
          {...modalProps}
          cancelLabel={t("formArrayDeleteModal.Cancel")}
          submitLabel={
            <Form.ArrayRemover
              className="form-array sheet-array-remove-button"
              onRemoved={() => {
                onUserRemoved();
                return;
              }}
            >
              {t("formArrayDeleteModal.Remove")}
            </Form.ArrayRemover>
          }
          submitIcon={null}
        >
          {t("formArrayDeleteModal.Confirm", {
            nameOfRemoval: nameOfRemoval,
          })}
        </TranslatedModal.Confirmation>
      )}
    />
  );
};

export default FormArrayDeleteModal;
