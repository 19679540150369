import React from "react";
import { connect } from "react-redux";
import { getAuthClaims } from "@redriver/cinnamon";
import { UserArea, UserType } from "features/../../../shared/constants/enums";

const withCurrentUser = Component => {
  return connect(
    state => {
      const {
        userId,
        userName,
        userEmail,
        userType,
        userArea,
        companyId
      } = getAuthClaims(state);
      const isInternalUser = userType == UserType.HQ;

      return {
        currentUser: {
          id: userId,
          name: userName,
          email: userEmail,
          userType,
          userArea,
          companyId,
          isInternalUser,
          isSupplierAdminUser: userType === UserType.Dealer &&
            (userArea === UserArea.SupplierAdmin ||
              userArea === UserArea.IpsAdmin)
        }
      };
    },
    () => ({})
  )(Component);
};

export default withCurrentUser;
