import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  TableCell,
  TableRow,
  Form as SemanticForm,
} from "semantic-ui-react";
import { Form, useFormField } from "@redriver/cinnamon";
import { useTranslation, Trans } from "react-i18next";

import {
  AgreementStabilityOptions,
  SheetViewType,
} from "features/../../../shared/constants/enums";
import {
  BlankNumberFormatter,
  BlankCurrencyFormatter,
} from "features/../../../shared/components/display";
import { getSheetDataState } from "features/Sheets";

const AdjustmentSelection = ({ isAdminUser, sheetViewType }) => {
  const { t } = useTranslation("systemScheduleStability", {
    keyPrefix: "adjustment_selection",
  });

  const [option, setOption] = useFormField("agreementStabilityOption");

  const data = useSelector(
    (state) => state.sheets?.rawCalculation?.scheduleStability,
  );
  const defaults = useSelector(
    (state) => state.sheets?.sheetData?.defaults?.agreementStabilitySettings,
  );
  const { preventUpdates } = useSelector(getSheetDataState);

  const availableOptions = useMemo(
    () => defaults.availableOptions.replace(/ /g, "").split(","),
    [defaults],
  );
  const availableToDealer = useMemo(
    () => defaults.optionsVisibleToDealer.replace(/ /g, "").split(","),
    [defaults],
  );

  const selectableByDealer = useMemo(
    () => defaults.dealerSelectionOptions.replace(/ /g, "").split(","),
    [defaults],
  );

  const option1Available = useMemo(
    () =>
      availableOptions.includes(AgreementStabilityOptions.Option1) &&
      (sheetViewType == SheetViewType.IpsAdmin ||
        availableToDealer.includes(AgreementStabilityOptions.Option1)),
    [availableOptions, sheetViewType, availableToDealer],
  );

  const option1Selectable = useMemo(
    () =>
      sheetViewType == SheetViewType.IpsAdmin ||
      selectableByDealer.includes(AgreementStabilityOptions.Option1),
    [sheetViewType, selectableByDealer],
  );

  const option2Available = useMemo(
    () =>
      availableOptions.includes(AgreementStabilityOptions.Option2) &&
      (sheetViewType == SheetViewType.IpsAdmin ||
        availableToDealer.includes(AgreementStabilityOptions.Option2)),
    [sheetViewType, availableOptions, availableToDealer],
  );

  const option2Selectable = useMemo(
    () =>
      sheetViewType == SheetViewType.IpsAdmin ||
      selectableByDealer.includes(AgreementStabilityOptions.Option2),
    [sheetViewType, selectableByDealer],
  );

  const option3Available = useMemo(
    () =>
      availableOptions.includes(AgreementStabilityOptions.Option3) &&
      (sheetViewType == SheetViewType.IpsAdmin ||
        availableToDealer.includes(AgreementStabilityOptions.Option3)),
    [sheetViewType, availableOptions, availableToDealer],
  );

  const option3Selectable = useMemo(
    () =>
      sheetViewType == SheetViewType.IpsAdmin ||
      selectableByDealer.includes(AgreementStabilityOptions.Option3),
    [sheetViewType, selectableByDealer],
  );

  const option4Available = useMemo(
    () =>
      availableOptions.includes(AgreementStabilityOptions.Option4) &&
      (sheetViewType == SheetViewType.IpsAdmin ||
        availableToDealer.includes(AgreementStabilityOptions.Option4)),
    [sheetViewType, availableOptions, availableToDealer],
  );

  const option4Selectable = useMemo(
    () =>
      sheetViewType == SheetViewType.IpsAdmin ||
      selectableByDealer.includes(AgreementStabilityOptions.Option4),
    [sheetViewType, selectableByDealer],
  );

  const adjustmentData = data?.adjustmentSelection;

  return (
    <React.Fragment>
      {data && (
        <React.Fragment>
          <div className="vmax-grid">
            <div>
              <div className="vmax-detail-grid">
                <div>{t("volume_vmax")}</div>
                <div className="left-border">
                  <BlankNumberFormatter value={data.volumeVMax} />
                </div>
              </div>
            </div>
            <div>
              <div className="vmax-detail-grid">
                <div>{t("sheet_override_vmax")}</div>
                <div className="left-border">
                  <Form.Numeric
                    field="sheetOverrideVMax"
                    disabled={
                      preventUpdates ||
                      !(
                        (sheetViewType === SheetViewType.IpsAdmin ||
                          sheetViewType === SheetViewType.SupplierAdmin) &&
                        isAdminUser
                      )
                    }
                    decimalPlaces={2}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="vmax-detail-grid">
                <div>{t("vmax_used")}</div>
                <div className="left-border">{data.vMaxUsed}</div>
              </div>
            </div>
          </div>
          <h5>{t("adjustment_selection")} </h5>

          {option1Available && (
            <div className="adjustment-grid">
              <div>
                <SemanticForm.Radio
                  label={t("option", { No: 1 })}
                  value={AgreementStabilityOptions.Option1}
                  checked={option === AgreementStabilityOptions.Option1}
                  onChange={() => setOption(AgreementStabilityOptions.Option1)}
                  disabled={!option1Selectable || preventUpdates}
                />
              </div>
              <div className="white-container">
                {t("no_adjustment")}
                <Table className="table">
                  <Header />
                  <Table.Body>
                    <TR
                      className="light-grey bold"
                      cell1={t("tmv_option", { No: 1 })}
                      cell2={
                        <BlankNumberFormatter
                          value={adjustmentData.option1.tmv.quarters}
                          decimalPlaces={3}
                        />
                      }
                      cell3={
                        <BlankNumberFormatter
                          value={adjustmentData.option1.tmv.volume}
                        />
                      }
                      cell4={
                        <BlankCurrencyFormatter
                          value={adjustmentData.option1.tmv.value}
                        />
                      }
                    />
                    <TR
                      className="italic"
                      cell1={t("schedule_difference")}
                      cell4={
                        <BlankCurrencyFormatter
                          value={
                            adjustmentData.option1.scheduleDifference.value
                          }
                        />
                      }
                    />
                  </Table.Body>
                </Table>
              </div>
            </div>
          )}
          {option2Available && (
            <div className="adjustment-grid">
              <div>
                <SemanticForm.Radio
                  label={t("option", { No: 2 })}
                  value={AgreementStabilityOptions.Option2}
                  checked={option === AgreementStabilityOptions.Option2}
                  onChange={() => setOption(AgreementStabilityOptions.Option2)}
                  disabled={!option2Selectable || preventUpdates}
                />
              </div>
              <div className="white-container">
                <b>
                  <Trans t={t} components={{ u: <u></u> }}>
                    retains_existing_schedule_value_tmv
                  </Trans>
                </b>
                <Table className="table">
                  <Header />
                  <Table.Body>
                    <TR
                      className="light-grey"
                      cell1={t("full_additional")}
                      cell2={
                        <BlankNumberFormatter
                          value={adjustmentData.option2.fullAdditional.quarters}
                          decimalPlaces={3}
                        />
                      }
                      cell3={
                        <BlankNumberFormatter
                          value={adjustmentData.option2.fullAdditional.volume}
                        />
                      }
                      cell4={
                        <BlankCurrencyFormatter
                          value={adjustmentData.option2.fullAdditional.value}
                        />
                      }
                    />
                    <TR
                      cell1={t("option_unrestricted_total", { No: 2 })}
                      cell2={
                        <BlankNumberFormatter
                          value={
                            adjustmentData.option2.unrestrictedTotal.quarters
                          }
                          decimalPlaces={3}
                        />
                      }
                      cell3={
                        <BlankNumberFormatter
                          value={
                            adjustmentData.option2.unrestrictedTotal.volume
                          }
                        />
                      }
                      cell4={
                        <BlankCurrencyFormatter
                          value={adjustmentData.option2.unrestrictedTotal.value}
                        />
                      }
                    />
                    <TR
                      className="light-grey"
                      cell1={t("restriction")}
                      cell2={
                        <BlankNumberFormatter
                          value={adjustmentData.option2.restriction.quarters}
                          decimalPlaces={3}
                        />
                      }
                      cell3={
                        <BlankNumberFormatter
                          value={adjustmentData.option2.restriction.volume}
                        />
                      }
                      cell4={
                        <BlankCurrencyFormatter
                          value={adjustmentData.option2.restriction.value}
                        />
                      }
                    />
                    <TR
                      className="bold"
                      cell1={t("tmv_option", { No: 2 })}
                      cell2={
                        <BlankNumberFormatter
                          value={adjustmentData.option2.tmv.quarters}
                          decimalPlaces={3}
                        />
                      }
                      cell3={
                        <BlankNumberFormatter
                          value={adjustmentData.option2.tmv.volume}
                        />
                      }
                      cell4={
                        <BlankCurrencyFormatter
                          value={adjustmentData.option2.tmv.value}
                        />
                      }
                    />
                    <TR
                      className="italic"
                      cell1={t("option_addition_to_schedule", { No: 2 })}
                      cell2={
                        <BlankNumberFormatter
                          value={
                            adjustmentData.option2.additionalToSchedule.quarters
                          }
                          decimalPlaces={3}
                        />
                      }
                      cell3={
                        <BlankNumberFormatter
                          value={
                            adjustmentData.option2.additionalToSchedule.volume
                          }
                        />
                      }
                      cell4={
                        <BlankCurrencyFormatter
                          value={
                            adjustmentData.option2.additionalToSchedule.value
                          }
                        />
                      }
                    />

                    <TR
                      className="italic light-grey"
                      cell1={t("schedule_difference")}
                      cell4={
                        <BlankCurrencyFormatter
                          value={
                            adjustmentData.option2.scheduleDifference.value
                          }
                        />
                      }
                    />
                  </Table.Body>
                </Table>
              </div>
            </div>
          )}
          {option3Available && (
            <div className="adjustment-grid">
              <div>
                <SemanticForm.Radio
                  label={t("option", { No: 3 })}
                  value={AgreementStabilityOptions.Option3}
                  checked={option === AgreementStabilityOptions.Option3}
                  onChange={() => setOption(AgreementStabilityOptions.Option3)}
                  disabled={!option3Selectable || preventUpdates}
                />
              </div>
              <div className="white-container">
                <b>
                  <Trans t={t} components={{ u: <u></u> }}>
                    retains_existing_schedule_managed_vol
                  </Trans>
                </b>
                <Table className="table">
                  <Header />
                  <Table.Body>
                    <TR
                      className="light-grey"
                      cell1={t("full_additional")}
                      cell2={
                        <BlankNumberFormatter
                          value={adjustmentData.option3.fullAdditional.quarters}
                          decimalPlaces={3}
                        />
                      }
                      cell3={
                        <BlankNumberFormatter
                          value={adjustmentData.option3.fullAdditional.volume}
                        />
                      }
                      cell4={
                        <BlankCurrencyFormatter
                          value={adjustmentData.option3.fullAdditional.value}
                        />
                      }
                    />
                    <TR
                      cell1={t("option_unrestricted_total", { No: 3 })}
                      cell2={
                        <BlankNumberFormatter
                          value={
                            adjustmentData.option3.unrestrictedTotal.quarters
                          }
                          decimalPlaces={3}
                        />
                      }
                      cell3={
                        <BlankNumberFormatter
                          value={
                            adjustmentData.option3.unrestrictedTotal.volume
                          }
                        />
                      }
                      cell4={
                        <BlankCurrencyFormatter
                          value={adjustmentData.option3.unrestrictedTotal.value}
                        />
                      }
                    />
                    <TR
                      className="light-grey"
                      cell1={t("restriction")}
                      cell2={
                        <BlankNumberFormatter
                          value={adjustmentData.option3.restriction.quarters}
                          decimalPlaces={3}
                        />
                      }
                      cell3={
                        <BlankNumberFormatter
                          value={adjustmentData.option3.restriction.volume}
                        />
                      }
                      cell4={
                        <BlankCurrencyFormatter
                          value={adjustmentData.option3.restriction.value}
                        />
                      }
                    />
                    <TR
                      className="bold"
                      cell1={t("tmv_option", { No: 3 })}
                      cell2={
                        <BlankNumberFormatter
                          value={adjustmentData.option3.tmv.quarters}
                          decimalPlaces={3}
                        />
                      }
                      cell3={
                        <BlankNumberFormatter
                          value={adjustmentData.option3.tmv.volume}
                        />
                      }
                      cell4={
                        <BlankCurrencyFormatter
                          value={adjustmentData.option3.tmv.value}
                        />
                      }
                    />
                    <TR
                      className="italic"
                      cell1={t("option_addition_to_schedule", { No: 3 })}
                      cell2={
                        <BlankNumberFormatter
                          value={
                            adjustmentData.option3.additionalToSchedule.quarters
                          }
                          decimalPlaces={3}
                        />
                      }
                      cell3={
                        <BlankNumberFormatter
                          value={
                            adjustmentData.option3.additionalToSchedule.volume
                          }
                        />
                      }
                      cell4={
                        <BlankCurrencyFormatter
                          value={
                            adjustmentData.option3.additionalToSchedule.value
                          }
                        />
                      }
                    />

                    <TR
                      className="italic light-grey"
                      cell1={t("schedule_difference")}
                      cell4={
                        <BlankCurrencyFormatter
                          value={
                            adjustmentData.option3.scheduleDifference.value
                          }
                        />
                      }
                    />
                  </Table.Body>
                </Table>
              </div>
            </div>
          )}
          {option4Available && (
            <div className="adjustment-grid">
              <div>
                <SemanticForm.Radio
                  label={t("option", { No: 4 })}
                  value={AgreementStabilityOptions.Option4}
                  checked={option === AgreementStabilityOptions.Option4}
                  onChange={() => setOption(AgreementStabilityOptions.Option4)}
                  disabled={!option4Selectable || preventUpdates}
                />
              </div>
              <div className="white-container">
                <b>
                  <Trans t={t} components={{ u: <u></u> }}>
                    retains_existing_schedule_value_quarters
                  </Trans>
                </b>
                <Table className="table">
                  <Header />
                  <Table.Body>
                    <TR
                      className="light-grey"
                      cell1={t("full_additional")}
                      cell2={
                        <BlankNumberFormatter
                          value={adjustmentData.option4.fullAdditional.quarters}
                          decimalPlaces={3}
                        />
                      }
                      cell3={
                        <BlankNumberFormatter
                          value={adjustmentData.option4.fullAdditional.volume}
                        />
                      }
                      cell4={
                        <BlankCurrencyFormatter
                          value={adjustmentData.option4.fullAdditional.value}
                        />
                      }
                    />
                    <TR
                      cell1={t("option_unrestricted_total", { No: 4 })}
                      cell2={
                        <BlankNumberFormatter
                          value={
                            adjustmentData.option4.unrestrictedTotal.quarters
                          }
                          decimalPlaces={3}
                        />
                      }
                      cell3={
                        <BlankNumberFormatter
                          value={
                            adjustmentData.option4.unrestrictedTotal.volume
                          }
                        />
                      }
                      cell4={
                        <BlankCurrencyFormatter
                          value={adjustmentData.option4.unrestrictedTotal.value}
                        />
                      }
                    />
                    <TR
                      className="light-grey"
                      cell1={t("restriction")}
                      cell2={
                        <BlankNumberFormatter
                          value={adjustmentData.option4.restriction.quarters}
                          decimalPlaces={3}
                        />
                      }
                      cell3={
                        <BlankNumberFormatter
                          value={adjustmentData.option4.restriction.volume}
                        />
                      }
                      cell4={
                        <BlankCurrencyFormatter
                          value={adjustmentData.option4.restriction.value}
                        />
                      }
                    />
                    <TR
                      className="bold"
                      cell1={t("tmv_option", { No: 4 })}
                      cell2={
                        <BlankNumberFormatter
                          value={adjustmentData.option4.tmv.quarters}
                          decimalPlaces={3}
                        />
                      }
                      cell3={
                        <BlankNumberFormatter
                          value={adjustmentData.option4.tmv.volume}
                        />
                      }
                      cell4={
                        <BlankCurrencyFormatter
                          value={adjustmentData.option4.tmv.value}
                        />
                      }
                    />
                    <TR
                      className="italic"
                      cell1={t("option_addition_to_schedule", { No: 4 })}
                      cell2={
                        <BlankNumberFormatter
                          value={
                            adjustmentData.option4.additionalToSchedule.quarters
                          }
                          decimalPlaces={3}
                        />
                      }
                      cell3={
                        <BlankNumberFormatter
                          value={
                            adjustmentData.option4.additionalToSchedule.volume
                          }
                        />
                      }
                      cell4={
                        <BlankCurrencyFormatter
                          value={
                            adjustmentData.option4.additionalToSchedule.value
                          }
                        />
                      }
                    />
                    <TR
                      className="italic light-grey"
                      cell1={t("schedule_difference")}
                      cell4={
                        <BlankCurrencyFormatter
                          value={
                            adjustmentData.option4.scheduleDifference.value
                          }
                        />
                      }
                    />
                  </Table.Body>
                </Table>
              </div>
            </div>
          )}

          <div className="two-column">
            <div></div>
            <div className="bold">
              <Table>
                <TableRow>
                  <TableCell>{t("new_total_managed_volume")}</TableCell>
                  <TableCell>
                    <BlankNumberFormatter
                      value={adjustmentData.newTotalManagedVolume}
                    />{" "}
                  </TableCell>
                </TableRow>
                <TableRow className="light-grey">
                  <TableCell>{t("new_schedule_value")}</TableCell>
                  <TableCell>
                    <BlankCurrencyFormatter
                      value={adjustmentData.newScheduleValue}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("schedule_difference")}</TableCell>
                  <TableCell>
                    <BlankCurrencyFormatter
                      value={adjustmentData.scheduleDifference}
                    />{" "}
                  </TableCell>
                </TableRow>
              </Table>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const Header = () => {
  const { t } = useTranslation("systemScheduleStability", {
    keyPrefix: "adjustment_selection",
  });

  return (
    <Table.Header>
      <Table.HeaderCell></Table.HeaderCell>
      <Table.HeaderCell>{t("quarters")}</Table.HeaderCell>
      <Table.HeaderCell>{t("volume")}</Table.HeaderCell>
      <Table.HeaderCell>{t("value")}</Table.HeaderCell>
    </Table.Header>
  );
};

const TR = ({
  cell1 = "",
  cell2 = "",
  cell3 = "",
  cell4 = "",
  className = "",
}) => {
  return (
    <Table.Row className={className}>
      <Table.Cell style={{ width: "50%" }}>{cell1}</Table.Cell>
      <Table.Cell>{cell2}</Table.Cell>
      <Table.Cell>{cell3}</Table.Cell>
      <Table.Cell>{cell4}</Table.Cell>
    </Table.Row>
  );
};

export default AdjustmentSelection;
