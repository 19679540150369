import { union } from "lodash";
import { newFixedInput } from "components/forms";
import { newScheduleItem } from "../../helpers";

export const addDependencies = (newSheetData, unitData, oldSheetData = {}) => {
  //items that we had before the last change
  const oldItems = oldSheetData.systemScheduleItems || [],
    oldItemIds = oldItems.filter((x) => (x.unit || {}).value).map((x) => x.id);

  //items that are now being added
  const incomingItems = newSheetData.systemScheduleItems.filter(
    (x) => !oldItemIds.includes(x.id),
  );

  const incomingUnitIds = union(
      incomingItems.map((i) => (i.unit || {}).value).filter((x) => x != null),
    ),
    oldUnitIds = union(
      oldItems.map((x) => (x.unit || {}).value).filter((x) => x != null),
    );

  //dependencies from incoming Units, and that we don't already have
  const missingDependencies = union(
    unitData
      .filter(
        (
          x, //incoming Units
        ) => incomingUnitIds.includes(x.id) && !oldUnitIds.includes(x.id),
      )
      .map((x) => x.dependencies.map((y) => y.dependencyId))
      .filter((x) => x != null)
      .flat(),
  )
    .filter(
      (
        x, //dependencies we don't already have
      ) => !oldUnitIds.includes(x),
    )
    .map((x) => unitData.find((y) => y.id === x));

  missingDependencies.forEach((u) => {
    newSheetData.systemScheduleItems.push(
      newScheduleItem(
        "id",
        {
          isFixed: false,
          value: u.id,
          unitName: u.code,
          unitCode: u.name,
          overwritable: u.overwritable,
          alwaysUnmanaged: u.alwaysUnmanaged,
          alwaysManaged: u.alwaysManaged,
          isUtility: u.isUtility,
        },
        newFixedInput(
          u.defaultMinSalePrice.toFixed(process.env.USE_MINOR_CURRENCY ? 3 : 5),
        ),
        newFixedInput(u.defaultMinVolume),
        "dependant",
        u.defaultMinVolume,
        u.defaultMinSalePrice,
        u.vMinSalePrice,
        u.vMinVolume,
        u.tier1DefaultPercentage,
        u.tier2DefaultPercentage,
        u.tier2DefaultValue,
        u.tier3DefaultValue,
        u.vMinPercentageTier1,
        u.vMaxPercentageTier1,
        u.vMaxPercentageTier2,
        u.vMinValueTier2,
        u.vMaxPercentageTier3,
        u.vMinValueTier3,
      ),
    );
  });
};
