import { ModalBuilder, Table, notifySuccess } from "@redriver/cinnamon";
import React from "react";
import { voidEnvelope } from "./actions";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";

const VoidEnvelope = ({ sheet, onSubmitted }) => {
  const { t } = useTranslation("agreements");
  const { sheetId } = sheet;
  return (
    <ModalBuilder
      submitAction={voidEnvelope}
      submitParams={{ sheetId }}
      onSubmitted={(response) => {
        notifySuccess(t("Action.Void_Envelope_Success"));
        onSubmitted(response);
      }}
      renderTrigger={(trigger) => (
        <Table.MenuItem onClick={trigger}>
          {t("SheetActions.VoidEnvelope.VoidEnvelope")}
        </Table.MenuItem>
      )}
      renderModal={(modalProps) => (
        <TranslatedModal.Confirmation
          {...modalProps}
          header={t("SheetActions.VoidEnvelope.Confirmation")}
        >
          {t("SheetActions.VoidEnvelope.BodyText")}
        </TranslatedModal.Confirmation>
      )}
    />
  );
};

export default VoidEnvelope;
