import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { numericFormatter } from "components/displays/DisplayHelper";
import { Format } from "@redriver/cinnamon";
import { inchesToFeet } from "../../helpers";

const RollMediaCalculation = ({ lfRollMedia }) => {
  const { t } = useTranslation();
  const length = useMemo(
    () => (lfRollMedia ? lfRollMedia.length : 0),
    [lfRollMedia],
  );
  const width = useMemo(
    () => (lfRollMedia ? lfRollMedia.width : 0),
    [lfRollMedia],
  );
  const cost = useMemo(
    () => (lfRollMedia ? lfRollMedia.cost : 0),
    [lfRollMedia],
  );
  const unit2Total = useMemo(
    () =>
      process.env.USE_IMPERIAL_UNITS
        ? length * inchesToFeet(width)
        : length * (width / 1000),
    [length, width],
  );

  const costPerUnit2 = useMemo(
    () =>
      unit2Total != 0
        ? (cost / unit2Total) * (process.env.USE_MINOR_CURRENCY ? 100 : 1)
        : 0,
    [cost, unit2Total],
  );

  const unit2TotalFormatted = useMemo(
    () => numericFormatter(unit2Total, 0, 2),
    [unit2Total],
  );
  return (
    <React.Fragment>
      <div className="first">
        <div>
          {t(
            "systemSchedule:largeFormatModal:rollFedMediaInclusive:fields:unit2Total:label",
          )}
        </div>
        <div>{unit2TotalFormatted}</div>
      </div>
      <div className="second">{" @ "}</div>
      <div className="purple">
        {process.env.USE_MINOR_CURRENCY ? (
          <Format.Number
            value={costPerUnit2}
            suffix={process.env.MINOR_CURRENCY}
            decimalPlaces={3}
          />
        ) : (
          <Format.Number
            value={costPerUnit2}
            prefix={process.env.MAJOR_CURRENCY}
            decimalPlaces={5}
          />
        )}
      </div>
      <div>
        {t(
          "systemSchedule:largeFormatModal:rollFedMediaInclusive:fields:perSquared:label",
        )}
      </div>
    </React.Fragment>
  );
};

export default RollMediaCalculation;
