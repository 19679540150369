import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder } from "@redriver/cinnamon";
import { deleteClient } from "features/../../../shared/features/Clients/actions";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { useTranslation, Trans } from "react-i18next";

const DeleteClient = ({ as: As = Button, client, onDeleted, ...props }) => {
  const  { t }  = useTranslation('clients', { keyPrefix:'deleteModal'});
  return(

  <ModalBuilder
    submitAction={deleteClient}
    submitParams={client.id}
    onSubmitted={onDeleted}
    renderTrigger={showModal => <As onClick={showModal} {...props} />}
    renderModal={modalProps => (
      <TranslatedModal.Confirmation
        {...modalProps}
        header={t("header")}
        cancelLabel={t("cancelLabel")}
        submitLabel={t("submitLabel")}
      >
        <Trans t={t} values={{clientName: client.name}} >
          confirmationMessage
        </Trans>
      </TranslatedModal.Confirmation>
    )}
  />
)};

export default DeleteClient;
