import React from "react";
import { Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const DealerOfficeLogoViewForm = formProps => {
  const  { t }  = useTranslation('supplierOffices', { keyPrefix:'supplierHeaderImage'});
  
  return(
  <div className="no-disable-box">
    <b>{t("title")}</b>
    {formProps.value && formProps.value.imageUrl ? (
      <div>
        <Image src={formProps.value.imageUrl} />
      </div>
    ) : (
      <div>{t("noImageText")}</div>
    )}
  </div>
)};

export default DealerOfficeLogoViewForm;
