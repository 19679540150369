import React, { useState, useEffect } from "react";
import { IconButton } from "features/../../../shared/components/buttons";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { Form, ModalBuilder } from "@redriver/cinnamon";
import Add from "features/../../../shared/assets/blue-add.svg";
import { getSheetDataState } from "features/Sheets";
import { newLargeFormatScheduleGroup } from "features/Sheets/ManageSheet";
import { clearLargeFormatCreateModal } from "features/../../../shared/features/Settings/LargeFormat/actions";
import { calculateForm } from "features/Sheets/ManageSheet/actions";
import LargeFormatModalDetails from "./LargeFormatModalDetails";
import { useTranslation } from "react-i18next";

import { TranslatedModal } from "features/../../../shared/components/modals";

const ScheduleStepCreateLargeFormat = ({
  largeFormatGroups,
  sheetId,
  largeFormatCreateModal,
  largeFormatDefaults,
  clearLargeFormatCreateModal,
  sheetTypeView,
  calculateForm,
  disabled,
  sheet,
  showTooltip,
}) => {
  const { t } = useTranslation();

  return (
    <ModalBuilder
      onCancelled={() => {
        clearLargeFormatCreateModal();
        calculateForm(false);
      }}
      onSubmit={() => calculateForm(false)}
      renderTrigger={(showModal) => (
        <div className="tooltip">
          <IconButton
            onClick={() => (clearLargeFormatCreateModal(), showModal())}
            className="wizard-title-button add-new-lfp"
            icon={Add}
            disabled={disabled}
            content={t("systemSchedule:buttons:addNewLargeFormat")}
          />
          {showTooltip && (
            <span className="tooltiptext">
              {" "}
              <div className="red-text bold">
                {t("systemSchedule:Maximum_Utility_Limit_Reached")}
              </div>
            </span>
          )}
        </div>
      )}
      renderModal={(modalProps) => (
        <TranslatedModal.Add
          {...modalProps}
          className="large-format-create-modal"
          header={t("systemSchedule:largeFormatModal:title")}
          submitDisabled={
            largeFormatCreateModal.printerId == null ||
            (largeFormatCreateModal.lfRollMedia || []).some(
              (pm) => !pm.paperWidthId || !pm.paperInclusiveId,
            )
          }
          submitLabel={
            <Form.ArrayAdder
              field="largeFormatGroups"
              arrayKey="id"
              as={Button}
              className="wizard-title-button positive large-format-save"
              content={
                <div className="large-format-save-btn">
                  {t("systemSchedule:largeFormatModal:saveBtn")}
                  <Icon name="checkmark" />
                </div>
              }
              populate={(k) => {
                const newItem = newLargeFormatScheduleGroup(
                  k,
                  largeFormatCreateModal,
                  largeFormatDefaults,
                  largeFormatGroups,
                  false,
                  sheet,
                );
                clearLargeFormatCreateModal();
                return newItem;
              }}
            ></Form.ArrayAdder>
          }
        >
          <LargeFormatModalDetails
            sheetId={sheetId}
            createVersion={true}
            sheetTypeView={sheetTypeView}
          />
        </TranslatedModal.Add>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  const sheet = getSheetDataState(state);

  const { largeFormatCreateModal } = getSheetDataState(state),
    { defaults, largeFormatGroups } = getSheetDataState(state);

  return {
    sheet,
    largeFormatGroups,
    largeFormatCreateModal,
    largeFormatDefaults: defaults.largeFormatDefaults,
  };
};

const actions = {
  clearLargeFormatCreateModal,
  calculateForm,
};

export default connect(mapStateToProps, actions)(ScheduleStepCreateLargeFormat);
