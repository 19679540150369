import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { PageHeader, getAuthState } from "@redriver/cinnamon";
import { PasswordReset } from "features/System";
import { SystemRoutes } from "constants/routes";
import { SystemPageContent } from "components/pages";

const PasswordResetPage = ({ loggedIn, match }) => {
  const { t } = useTranslation('system');
  if (loggedIn) {
    return <Redirect to={SystemRoutes.ChangePassword} />;
  }

  return (
    <SystemPageContent>
      <div className="sys-form">
        <PageHeader>{t('Reset_Password')}</PageHeader>
        <PasswordReset token={match.params.token || ""} />
      </div>
    </SystemPageContent>
  );
};

const mapStateToProps = state => ({
  loggedIn: getAuthState(state).loggedIn
});

export default connect(mapStateToProps)(PasswordResetPage);
