import React from "react";
import { useTranslation } from "react-i18next";

const ColumnsHeader = ({
  tieredTonerAvailableToDealer,
  allowedTieredToner,
  majorCurrency,
  minorCurrency,
  numberOfSchedules,
  showHeader,
  forLFP,
}) => {
  const { t } = useTranslation("systemSchedule");
  return (
    <div className="schedule-header">
      <div className="col col-1">
        {(showHeader || forLFP) &&
          (forLFP
            ? t("form.largeFormat.label")
            : t("systemSchedule:form:unit:label"))}
      </div>
      {showHeader && <div className="col col-2"></div>}
      {showHeader && (
        <div className="col col-3">
          {process.env.USE_MINOR_CURRENCY
            ? t("systemSchedule:form:cpi:labelMinor", {
                minorCurrency,
              })
            : t("systemSchedule:form:cpi:labelMajor", {
                majorCurrency,
              })}
        </div>
      )}
      {showHeader && (
        <div className="col col-4">
          {t("systemSchedule:form:includeManaged:label")}
        </div>
      )}
      {showHeader && (
        <div className="col col-5">
          {numberOfSchedules > 0 &&
            t("systemSchedule:form:tonerExclusive:label")}
        </div>
      )}
      {showHeader && allowedTieredToner && tieredTonerAvailableToDealer ? (
        <div className="col col-6">
          {numberOfSchedules > 0 && t("systemSchedule:form:tieredToner:label")}
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {showHeader && (
        <div className="col col-7">
          {t("systemSchedule:form:minimumQtrVol:label")}
        </div>
      )}
      {showHeader && (
        <div className="col col-8">{t("systemSchedule:form:cpu:label")}</div>
      )}
    </div>
  );
};

export default ColumnsHeader;
