import { debounce } from "lodash";
import { apiGet, apiPut, apiPost, apiDelete } from "@redriver/cinnamon";
import { ContractTypeDescription } from "features/../../../shared/constants/enums/ContractType";
import { AgreementsSearchType } from "features/../../../shared/constants/enums";

const debounceTime = 500; // milliseconds

const NAMESPACE = "AGREEMENTS";

export const ActionTypes = {
  GetSchedule: `${NAMESPACE}/GET_SCHEDULE`,
  CreateSchedule: `${NAMESPACE}/CREATE_SCHEDULE`,
  UpdateSchedule: `${NAMESPACE}/UPDATE_SCHEDULE`,
  DeleteSchedule: `${NAMESPACE}/DELETE_SCHEDULE`,
  GetAgreements: `${NAMESPACE}/GET_MASTER_AGREEMENTS`,
  GetAgreement: `${NAMESPACE}/GET_MASTER_AGREEMENT`,
  CreateMasterAgreement: `${NAMESPACE}/CREATE_MASTER_AGREEMENT`,
  UpdateMasterAgreement: `${NAMESPACE}/UPDATE_MASTER_AGREEMENT`,
  DeleteMasterAgreement: `${NAMESPACE}/DELETE_MASTER_AGREEMENT`,
  CreateSheet: `${NAMESPACE}/CREATE_SHEET`,
  MakeSheetLive: `${NAMESPACE}/MAKE_SHEET_LIVE`,
  RenewSheet: `${NAMESPACE}/RENEW_SHEET`,
  VoidSheet: `${NAMESPACE}/VOID`,
  ResurrectSheet: `${NAMESPACE}/RESURRECT`,
  DealerReview: `${NAMESPACE}/DEALER_REVIEW`,
  IpsReview: `${NAMESPACE}/IPS_REVIEW`,
  OverrideClientSignature: `${NAMESPACE}/CLIENT_SIGN_OVR`,
  OverrideIpsSignature: `${NAMESPACE}/IPS_SIGN_OVR`,
  PreviewNewSheetContract: `${NAMESPACE}/PREVIEW_NEW_SHEET_CONTRACT`,
  AmendExpiryDate: `${NAMESPACE}/AMEND_SHEET_EXPIRY_DATE`,
  AdminComment: `${NAMESPACE}/ADMIN_COMMENT`,
  GetAgreementScheduleSheets: `${NAMESPACE}/GET_AGREEMENT_SCHEDULE_SHEET`,
  ResetSheetState: `${NAMESPACE}/RESET_SHEET_STATE`,
  SetPagination: `${NAMESPACE}/SET_PAGINATION`,
  SetFilters: `${NAMESPACE}/SET_FILTERS`,
  ClearFilters: `${NAMESPACE}/CLEAR_FILTERS`,
  AddExpandedAgreementOrSchedule: `${NAMESPACE}/ADD_EXPANDED_NODE`,
  RemoveExpandedAgreementOrSchedule: `${NAMESPACE}/REMOVE_EXPANDED_NODE`,
};

export const getAgreementScheduleSheetsDebounced = debounce(
  (dispatch, scheduleSheets, filters) => {
    Object.keys(scheduleSheets)?.forEach((scheduleId) =>
      dispatch(getAgreementScheduleSheets(scheduleId, filters)),
    );
  },
  debounceTime,
);

export const getAgreementScheduleSheets = (scheduleId, filters) => {
  const { sheetOwner, sheetOwnerName, searchType, search, ...otherFilters } =
    filters;

  return apiGet(
    ActionTypes.GetAgreementScheduleSheets,
    `schedules/${scheduleId}/sheets`,
    {
      ...otherFilters,
      sheetOwnerId: sheetOwner?.value,
      search:
        searchType === AgreementsSearchType.Sheets ? filters.search : null,
    },
    {
      actionCustomData: {
        scheduleId,
      },
    },
  );
};

export const createSchedule = (formData, submitParams) => {
  return apiPost(ActionTypes.CreateSchedule, `schedules`, {
    ...formData,
    ...submitParams,
  });
};

export const updateSchedule = (formData, { id }) => {
  return apiPut(ActionTypes.UpdateSchedule, `schedules/${id}`, {
    ...formData,
  });
};

export const deleteSchedule = ({ id }) => {
  return apiDelete(ActionTypes.DeleteSchedule, `schedules/${id}`);
};

export const getMasterAgreementsDebounced = debounce(
  (dispatch, { filters, pagination }, companyFilters) =>
    dispatch(getMasterAgreements({ filters, pagination }, companyFilters)),
  debounceTime,
);

export const getMasterAgreements = (
  { filters, pagination },
  companyFilters,
) => {
  const { client, dealerOffice } = companyFilters;
  const { sheetOwner, sheetOwnerName, ...otherFilters } = filters;

  let data = {
    ...pagination,
    ...otherFilters,
    clientId: client.id,
    dealerOfficeId: dealerOffice.id,
    sheetOwnerId: sheetOwner?.value,
  };

  // Force sheets to be filtered by dealerId if supplied in params
  return apiGet(ActionTypes.GetAgreements, `agreements`, data);
};

export const deleteMasterAgreement = ({ id }) => {
  return apiDelete(ActionTypes.DeleteMasterAgreement, `agreements/${id}`);
};

export const createMasterAgreement = (data) => {
  return apiPost(ActionTypes.CreateMasterAgreement, `agreements`, data);
};

export const generateMasterAgreement =
  (formData, { dealerOfficeId, clientId }) =>
  async (dispatch) => {
    const data = {};
    data.dealerOfficeId = dealerOfficeId;
    data.clientId = clientId;
    data.masterProjectName = formData.masterProjectName;
    data.scheduleProjectName = formData.scheduleProjectName;
    data.boilerPlateTandCsId = formData.boilerPlateTandCsId;
    //create agreement
    const apiResult = await dispatch(createMasterAgreement(data));
    if (!apiResult.success) {
      return apiResult;
    }
    const masterAgreementId = apiResult.response?.id;
    if (masterAgreementId) {
      //create Schedule
      const sheetId = await dispatch(
        generateSchedule(
          {
            projectName: formData.scheduleProjectName,
            masterAgreementId: masterAgreementId,
          },
          {
            dealerOfficeId: dealerOfficeId,
            clientId: clientId,
          },
        ),
      );
      if (sheetId) return sheetId;
    }
  };

export const generateSchedule =
  (formData, { masterAgreementId, dealerOfficeId, clientId }) =>
  async (dispatch) => {
    const scheduleData = {};
    scheduleData.MasterAgreementId = formData
      ? formData.masterAgreementId
      : masterAgreementId;
    scheduleData.ProjectName = formData ? formData.projectName : "";
    //create schedule
    const apiResult = await dispatch(createSchedule(scheduleData));
    if (!apiResult.success) {
      //as exception has thrown so delete just created master agreement
      await dispatch(
        deleteMasterAgreement({ id: scheduleData.MasterAgreementId }),
      );
      return apiResult;
    }
    const scheduleId = apiResult.response;
    if (scheduleId) {
      //create sheet
      const sheetId = await dispatch(
        generateSheet({
          clientId: clientId,
          dealerOfficeId: dealerOfficeId,
          scheduleId: scheduleId,
          ...formData,
        }),
      );
      return sheetId;
    }
  };

export const createSheet =
  ({ name, clientId, dealerOfficeId, scheduleId }) =>
  async (dispatch) => {
    const response = await dispatch(
      apiPost(ActionTypes.CreateSheet, `sheets`, {
        name,
        clientId,
        dealerOfficeId,
        scheduleId,
        contractType: ContractTypeDescription.PaperContract, // "Amethyst"
      }),
    );

    if (!response.success) return response;
    return response;
  };

export const generateSheet =
  ({ dealerOfficeId, clientId, scheduleId }) =>
  async (dispatch) => {
    //create sheet and return sheet id
    const sheetId = await dispatch(
      createSheet({
        name: "",
        clientId: clientId,
        dealerOfficeId: dealerOfficeId,
        scheduleId: scheduleId,
      }),
    );
    return sheetId;
  };

export const updateMasterAgreement = (data, { id }) => {
  return apiPut(ActionTypes.UpdateMasterAgreement, `agreements/${id}`, data);
};

export const getMasterAgreement = (id, filters) => {
  const { sheetOwner, sheetOwnerName, ...otherFilters } = filters;

  let data = {
    ...otherFilters,
    sheetOwnerId: sheetOwner?.value,
  };

  return apiGet(ActionTypes.GetAgreement, `agreements/${id}`, data, {
    actionCustomData: { agreementId: id },
  });
};

export const overrideClientSignature = ({ sheetId }) =>
  apiPost(
    ActionTypes.OverrideClientSignature,
    `sheets/${sheetId}/client-sign-override`,
  );

export const overrideIpsSignature = ({ sheetId }) =>
  apiPost(
    ActionTypes.OverrideClientSignature,
    `sheets/${sheetId}/ips-sign-override`,
  );

export const makeSheetLive = (formData, { sheetId }) =>
  apiPut(ActionTypes.MakeSheetLive, `sheets/${sheetId}/make-live`, formData);

export const renewSheet = (formData, { sheetId }) =>
  apiPost(ActionTypes.RenewSheet, `sheets/${sheetId}/renew`, formData);

export const voidSheet = ({ sheetId }) =>
  apiPut(ActionTypes.VoidSheet, `sheets/${sheetId}/void`);

export const resurrectSheet = ({ sheetId }) =>
  apiPut(ActionTypes.ResurrectSheet, `sheets/${sheetId}/resurrect`);

export const ipsReview = (formData, { sheetId }) =>
  apiPut(ActionTypes.IpsReview, `sheets/${sheetId}/ips-review`, formData);

export const getSheetContract = ({ sheetId }) =>
  apiGet(
    ActionTypes.PreviewNewSheetContract,
    `sheets/${sheetId}/download/sheet-contract`,
  );

export const amendExpiryDate = (formData, { sheetId }) =>
  apiPut(
    ActionTypes.AmendExpiryDate,
    `sheets/${sheetId}/amend-expiry-date`,
    formData,
  );

export const addAdminComment = (formData, { sheetId }) =>
  apiPut(ActionTypes.AdminComment, `sheets/${sheetId}/admin-comment`, formData);

export const resetSheetState = (formData, { sheetId }) =>
  apiPut(
    ActionTypes.ResetSheetState,
    `sheets/${sheetId}/reset-sheet-state`,
    formData,
  );

export const setPagination = (pagination) => ({
  type: ActionTypes.SetPagination,
  pagination,
});

export const setFilters = (filters) => ({
  type: ActionTypes.SetFilters,
  filters,
});

export const clearFilters = () => ({
  type: ActionTypes.ClearFilters,
});

export const addExpandedAgreementOrSchedule = (id) => ({
  type: ActionTypes.AddExpandedAgreementOrSchedule,
  id,
});

export const removeExpandedAgreementOrSchedule = (id) => ({
  type: ActionTypes.RemoveExpandedAgreementOrSchedule,
  id,
});
