  export const setLockedFields = (ipsRates, rates) => {
    if (
      ipsRates.lockAllowedTieredToner === 1 ||
      ipsRates.lockAllowedTieredToner === 0
    ) {
      rates.allowedTieredToner = ipsRates.lockAllowedTieredToner;
    }
    if (
      ipsRates.lockDisableMinVolsLogic === 1 ||
      ipsRates.lockDisableMinVolsLogic === 0
    ) {
      rates.systemScheduleDisableMinVolsLogic = ipsRates.lockDisableMinVolsLogic;
    }
    if (
      ipsRates.lockIncludeUpgradeClause === 1 ||
      ipsRates.lockIncludeUpgradeClause === 0
    ) {
      rates.includeUpgradeClause = ipsRates.lockIncludeUpgradeClause;
    }
  };

