import React from "react";
import { Form } from "@redriver/cinnamon";
import { getPapers } from "./actions";

const PaperDescriptionDropdown = ({ sheetId, paperSize, ...props }) => {
    return (<Form.Dropdown
        {...props}
        lookupAction={getPapers}
        lookupParams={{ sheetId, paperSize }}
        lookupOptions={{
            transform: data => data.map(i => ({ value: i.description, text: i.description }))
        }}
    />);
}

export const PaperSkuDropdown = ({ sheetId, paperSize, description, ...props }) => {
    return (<Form.Dropdown
        {...props}
        lookupAction={getPapers}
        lookupParams={{ sheetId, paperSize, description }}
        lookupOptions={{

            transform: description ? data => data.map(i => ({ value: i.id, text: i.productSku })) : []
        }}
    />);
}

export default PaperDescriptionDropdown;