import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder } from "@redriver/cinnamon";
import { deleteRole } from "./actions";
import { TranslatedModal } from "../../../components/modals";

const DeleteRole = ({ as: As = Button, role, onDeleted, ...props }) => (
  <ModalBuilder
    submitAction={deleteRole}
    submitParams={role.id}
    onSubmitted={onDeleted}
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps) => (
      <TranslatedModal.Confirmation
        {...modalProps}
        header="Delete Role"
        cancelLabel="No, Cancel"
        submitLabel="Yes, Delete Role"
      >
        Are you sure you wish to delete '{role.name}'?
      </TranslatedModal.Confirmation>
    )}
  />
);

export default DeleteRole;
