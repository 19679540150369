import { AgreementsSearchType } from "features/../../../shared/constants/enums";

export const initialState = {
  agreementsLoading: false,
  agreementsLoaded: false,
  totalAgreements: 0,
  agreements: [], // Contains an array of "schedules", but no sheets here.
  scheduleSheets: {
    // Contains a mapping of scheduleId to the load sheets API result, e.g [scheduleId]: {loading, result}
  },

  selected: {
    agreementId: null,
    scheduleId: null,
    sheetId: null,
  },

  expandedAgreementsAndSchedules: [], // includes mix of agreement and schedule IDs.

  pagination: {
    pageSize: 25,
    pageNumber: 1,
  },

  companyFilters: {
    dealer: {
      id: null,
      name: null,
    },
    dealerOffice: {
      id: null,
      name: null,
    },
    client: {
      id: null,
      name: null,
      code: null,
    },
  },

  filters: {
    search: "",
    searchType: AgreementsSearchType.All,
    sheetStatesIps: [],
    sheetStatesPrincipal: [],
    sheetOwner: {
      id: null,
      name: null,
    },
    isCrystallised: false,
    isUncrystallised: false,
    isArchived: false,
    notArchived: true,
  },
};
