import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "@redriver/cinnamon";

const DeclinePreApprovalForm = ({ formProps }) => {
  const { t } = useTranslation("agreements");
  return (
    <Form {...formProps} className="new-agreement">
      <div>
        <Form.TextArea
          fluid
          rows={2}
          field="declineReason"
          label={t("SheetActions.DeclinePreApproval.Label")}
        />
      </div>
    </Form>
  );
};

export default DeclinePreApprovalForm;
