import React from "react";
import { useTranslation } from "react-i18next";

export const SettingsUploadAdditionalColumnsErrorMessage = ({
  message,
  code,
  data,
}) => {
  const { t } = useTranslation("dataUploads", { keyPrefix: "errors" });

  return (
    <React.Fragment>
      {message}
      <ul>
        {data.map(item => (
          <li>
            <b>{item.headerName}</b>
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
};

export default SettingsUploadAdditionalColumnsErrorMessage;
