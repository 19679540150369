import React from "react";
import { useTranslation } from "react-i18next";

export const translatedModalProps = (props, t) => {    
    const { error } = props;  
    let modalProps = props;

    if (error && error?.response)
    {
        const {error, ...props} = modalProps;
        modalProps = props;     
    }  
    return modalProps;
}


export const isUnexpectedError = (error) => {
    if (error?.message) {
      return (
        error?.response[0]?.message?.toLowerCase().includes("please contact") ||
        error?.response[0]?.message?.toLowerCase().includes("unexpected")
      );
    }
  };
