import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder } from "@redriver/cinnamon";
import { activateUser } from "./actions";
import { TranslatedModal } from "features/../../../shared/components/modals";

const ActivateUser = ({ as: As = Button, user, onActivated, ...props }) => (
  <ModalBuilder
    submitAction={activateUser}
    submitParams={user.id}
    onSubmitted={onActivated}
    renderTrigger={showModal => <As onClick={showModal} {...props} />}
    renderModal={modalProps => (
      <TranslatedModal.Confirmation {...modalProps} header="Activate Account">
        Are you sure you wish to activate the account for {user.name}?
      </TranslatedModal.Confirmation>
    )}
  />
);

export default ActivateUser;
