import {
  isFieldFixed,
  shouldUseDefault,
} from "../../helpers";


export const setDefaults = (sheetData, defaults) => {
  // Capital
  if (
    !isFieldFixed(sheetData.capital.volumeSelection) &&
    (sheetData.capital.volumeSelection.value === 0 ||
      sheetData.capital.volumeSelection.value === null)
  ) {
    sheetData.capital.volumeSelection.value =
      defaults.defaultVolumeSelectionValue;
    sheetData.capital.volumeSelection.maximumValue =
      defaults.maximumVolumeSelectionValue;
    sheetData.capital.volumeSelection.minimumValue =
      defaults.minimumVolumeSelectionValue;
  }
  if (
    !isFieldFixed(sheetData.capital.totalDevicesOnSchedule) &&
    (sheetData.capital.totalDevicesOnSchedule.value === 0 ||
      sheetData.capital.totalDevicesOnSchedule.value === null)
  ) {
    sheetData.capital.totalDevicesOnSchedule.value =
      defaults.defaultAndMinimumTotalDevicesOnScheduleValue;
  }

  defaultFundingSection(sheetData.capital.funder, defaults, sheetData.rates);
  defaultFundingSection(sheetData.capital.cofunded, defaults, sheetData.rates);
  defaultFundingSection(sheetData.capital.dealer, defaults, sheetData.rates);

  return sheetData;
};



export const defaultFundingSection = (fundingSection, defaults, ratesSection) => {
  const funderDefaults =
    defaults.funderTypeSettings.find(
      x => x.type === fundingSection.funderType
    ) || {};
  if (!isFieldFixed(fundingSection.financeSelection)) {
    if (
      fundingSection.financeSelection.value === 0 ||
      fundingSection.financeSelection.value == null
    ) {
      fundingSection.financeSelection.value =
        funderDefaults.defaultQtrSelection;
    }
    if (
      fundingSection.financeSelection.minimumValue == null &&
      fundingSection.financeSelection.maximumValue == null
    ) {
      fundingSection.financeSelection.minimumValue =
        funderDefaults.vMinSelection;
      fundingSection.financeSelection.maximumValue =
        funderDefaults.maxSelection;
    }
  }

  if (!isFieldFixed(fundingSection.ipSyndicatePercentageYield)) {
    if (
      fundingSection.ipSyndicatePercentageYield.value === 0 ||
      fundingSection.ipSyndicatePercentageYield.value == null
    ) {
      const defaultYieldPercentage = defaults.defaultYieldPercentages.find(
        x =>
          x.quarter == fundingSection.financeSelection.value &&
          x.paymentMethod == ratesSection.paymentMethod &&
          x.financeType == ratesSection.dealerFmv.financeType
      );
      fundingSection.ipSyndicatePercentageYield.value =
        defaultYieldPercentage?.yieldPercentage || 0;
    }
  }

  shouldUseDefault(fundingSection.rrp) &&
    (fundingSection.rrp.value = funderDefaults.defaultRrp);
  shouldUseDefault(fundingSection.productCapitalCosts) &&
    (fundingSection.productCapitalCosts.value =
      funderDefaults.defaultProdCapCost);
  shouldUseDefault(fundingSection.otherCosts) &&
      (fundingSection.otherCosts.value = 0);
};


