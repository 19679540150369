import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Grid, Button } from "semantic-ui-react";
import classNames from "classnames";
import { FormBuilder, Form, Content, refreshJwt } from "@redriver/cinnamon";
import { getUser, saveUser } from "./actions";

/**
 * Allow the caller to edit their own profile
 * @param {*} props
 */
const EditUser = ({ userId, refreshJwt }) => {
  const { t } = useTranslation("system");
  const [avatarData, setAvatar] = useState(null);

  const onChangeAvatar = (file) => {
    let fileReader = new FileReader();
    fileReader.onload = () => {
      const res = fileReader.result;
      setAvatar(res);
    };
    fileReader.readAsDataURL(file);
  };

  return (
    <FormBuilder
      loadAction={getUser}
      loadParams={userId}
      submitAction={saveUser}
      submitParams={{ userId, avatarData }}
      onSubmitted={refreshJwt}
      renderSubmitting={false}
      renderForm={(formProps, state, events) => (
        <Content className="no-disable-box">
          <Form {...formProps}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={5}>
                  <Form.Email field="email" label={t("Email")} fluid disabled />
                </Grid.Column>
                <Grid.Column width={5}>
                  <Form.Input
                    field="firstName"
                    label={t("First_Name")}
                    requiredError={t("First_Name") + " " + t("requiredError")}
                    fluid
                    required
                  />
                </Grid.Column>
                <Grid.Column width={6}>
                  <Form.Input
                    field="lastName"
                    label={t("Last_Name")}
                    requiredError={t("Last_Name") + " " + t("requiredError")}
                    fluid
                    required
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="upload-row">
                <Grid.Column width={8} className="upload-col">
                  <Form.FileUpload
                    field="avatar"
                    label={t("Profile_Image")}
                    fluid
                    className={classNames(
                      "file-upload",
                      formProps &&
                        formProps.value &&
                        formProps.value.avatar &&
                        "file-selected"
                    )}
                    replaceText={t("Upload_Replace_Text")}
                    uploadText={t("Upload_Text")}
                    fileExtensionTypes={["png", "jpg", "jpeg", "webp"]}
                    customValidator={(_, __, ___, { value }) => {
                      !!value?.avatar && onChangeAvatar(value?.avatar);
                    }}
                    fileTypesError={
                      t("Profile_Image") + " " + t("Upload_Error")
                    }
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Button
                    primary
                    icon="check"
                    className="save"
                    content={t("Save_Changes")}
                    disabled={state.loading || state.submitting}
                    loading={state.submitting}
                    onClick={events.onSubmit}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Content>
      )}
    />
  );
};

export default connect(null, { refreshJwt })(EditUser);
