import React from "react";
import { connect } from "react-redux";
import ConfirmationModal from "./ConfirmationModal";
import { useTranslation } from "react-i18next";
import { saveAndDownloadSheet } from "features/Sheets/ManageSheet/actions";

const DownloadConfirmationModal = ({
  user,
  sheetDownloadFunction,
  sheetDownloadParams,
  onActivated,
  onSubmitted,
  buttonContent,
  buttonIcon,
  disabled,
  sheetId,
  isRewrite,
  isAdmin,
  sheetCanBeUpdated,
  onSaved,
  hideButton,
  onSubmitFunction,
  setCanClose = () => {},
}) => {
  const { t } = useTranslation("sheetTranslation");
  return (
    <ConfirmationModal
      disabled={disabled}
      sheetId={sheetId}
      sheetDownloadFunction={sheetDownloadFunction}
      isAdmin={isAdmin}
      isRewrite={isRewrite}
      sheetCanBeUpdated={sheetCanBeUpdated}
      buttonContent={buttonContent}
      setCanClose={setCanClose}
      onSubmitted={onSubmitted}
      hideButton={hideButton}
      onSaved={onSaved}
      onSubmitFunction={onSubmitFunction}
      buttonIcon={buttonIcon}
      sheetDownloadParams={sheetDownloadParams}
      onActivated={onActivated}
      user={user}
      headerText={t("downloadConfirmationModal.header")}
      bodyText={t(
        sheetCanBeUpdated
          ? "downloadConfirmationModal.bodyTextWithSaveWarning"
          : "downloadConfirmationModal.bodyTextWithoutSaveWarning"
      )}
      errorText={t("downloadConfirmationModal.unallocated_unit_error")}
      submitAction={saveAndDownloadSheet}
    ></ConfirmationModal>
  );
};

export default connect()(DownloadConfirmationModal);
