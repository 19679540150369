import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/EDIT_USER";

const ActionTypes = {
  GetUser: `${NAMESPACE}/GET_USER`,
  SaveUser: `${NAMESPACE}/SAVE_USER`,
};

export const getUser = () => apiGet(ActionTypes.GetUser, `users/me`);

export const saveUser = ({ avatar, avatarUrl, ...data }, { avatarData }) => {
  const avatarObject = !avatarData
    ? null
    : {
        name: avatar.name,
        content: avatarData.split(",")[1],
        size: avatar.size,
        contentType: avatar.type,
      };
  return apiPut(
    ActionTypes.SaveUser,
    `users/me`,
    {
      avatar: avatarObject,
      ...data,
    },
    { preventErrorNotification: true }
  );
};
