import {
  apiGet,
  apiPut,
  apiPost,
  getLookupData,
  mockSuccess,
} from "@redriver/cinnamon";
import { AddressType } from "./SelectLocationModal";
import { clientAddressesLookup } from "features/Clients";

const NAMESPACE = "PRODUCT_SCHEDULE";

export const ActionTypes = {
  UpdateProductSchedule: `${NAMESPACE}/UPDATE_PRODUCT_SCHEDULE`,
  GetProductSchedules: `${NAMESPACE}/GET_PRODUCT_SCHEDULES`,
  SaveMeterReadingData: `${NAMESPACE}/SAVE_METER_DATA`,
  GetMeterReadingData: `${NAMESPACE}/GET_METER_DATA`,
  SaveMeterCodeData: `${NAMESPACE}/SAVE_METER_CODE_DATA`,
  GetMeterCodeData: `${NAMESPACE}/GET_METER_CODE_DATA`,
  CreateClientAddress: `${NAMESPACE}/CREATE_CLIENT_ADDRESS`,
};

export const getProductSchedules = ({ sheetId }) => {
  return apiGet(
    ActionTypes.GetProductSchedules,
    `sheets/${sheetId}/product-schedule`,
  );
};

export const updateProductSchedule = (
  data,
  { sheetId, updateSerialNumbersOnly },
) => {
  return apiPut(
    ActionTypes.UpdateProductSchedule,
    `sheets/${sheetId}/product-schedule`,
    {
      ...data,
      updateSerialNumbersOnly,
    },
  );
};

export const saveMeterReadingData = (meterData, sheetId) => {
  return apiPut(
    ActionTypes.SaveMeterReadingData,
    `sheets/${sheetId}/product-schedule/meter-reading`,
    {
      ...meterData,
    },
  );
};

export const getMeterReadingData = (sheetId) => {
  return apiGet(
    ActionTypes.GetMeterReadingData,
    `sheets/${sheetId}/product-schedule/meter-reading`,
  );
};

export const getMeterCodeData = (sheetId) => {
  return apiGet(
    ActionTypes.GetMeterCodeData,
    `sheets/${sheetId}/product-schedule/meter-code`,
  );
};

export const saveMeterCodeData = (meterCodeData, sheetId) => {
  return apiPut(
    ActionTypes.SaveMeterCodeData,
    `sheets/${sheetId}/product-schedule/meter-code`,
    {
      ...meterCodeData,
    },
  );
};

export const createOrSetClientAddress = async (
  formData,
  { clientId, dispatch, reduxState },
  setClientAddress,
  setAllClientAddresses,
) => {
  if (formData.type === AddressType.New) {
    const createResponse = await dispatch(
      apiPost(
        ActionTypes.CreateClientAddress,
        `clients/${clientId}/addresses`,
        formData,
      ),
    );

    const newClientAddress = { id: createResponse.response, ...formData };

    if (formData.allocateToAll) {
      setAllClientAddresses(newClientAddress);
    } else {
      setClientAddress(newClientAddress);
    }
    return createResponse;
  } else if (formData.type === AddressType.Existing) {
    const existingData =
      (getLookupData(reduxState, clientAddressesLookup, { clientId }) || {})
        .response || [];
    const selectedExisting = existingData.find(
      (x) => x.id == formData.clientAddressId,
    );
    if (formData.allocateToAll) {
      setAllClientAddresses(selectedExisting);
    } else {
      setClientAddress(selectedExisting);
    }
    return mockSuccess(ActionTypes.CreateClientAddress, {
      response: selectedExisting,
    });
  }
};
