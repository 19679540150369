import React from "react";
import { useTranslation } from "react-i18next";
import { Format } from "@redriver/cinnamon";
import DataGrid from "./DataGrid";

const RatesAndSettingsGrid = ({ info }) => {
  const { t } = useTranslation("capitalCalc", {
    keyPrefix: "infoModal.funderTypes.ratesAndSettings",
  });

  return (
    <DataGrid
      headerText="Rates and Settings"
      dataRows={[
        [
          t("lookupRateColumn"),
          info.yieldBandLowerValue != null ||
          info.yieldBandUpperValue != null ? (
            <span key={1}>
              <span style={{ marginRight: "1em" }}>{info.threshold}</span>{" "}
              <Format.Number
                value={info.yieldBandLowerValue}
                format={process.env.CURRENCY_CODE}
                decimalPlaces={{ min: 0, max: 2 }}
              />{" "}
              -{" "}
              <Format.Number
                value={info.yieldBandUpperValue}
                format={process.env.CURRENCY_CODE}
                decimalPlaces={{ min: 0, max: 2 }}
              />
            </span>
          ) : null,
        ],
        [t("financeType"), info.financeType],
        [t("financeSelection"), info.financeSelection],
        [
          t("rrp"),
          <Format.Number
            key={4}
            value={info.rrp}
            format={process.env.CURRENCY_CODE}
            decimalPlaces={2}
          />,
        ],
        [
          t("rrpAllowance"),
          <Format.Number
            key={5}
            value={info.rateGroupRrpPercentage}
            format="percentage"
            decimalPlaces={2}
          />,
        ],
        [
          t("maximumRRPAllowance"),
          <Format.Number
            key={6}
            value={info.maxProductCapitalAllowed}
            format={process.env.CURRENCY_CODE}
            decimalPlaces={2}
          />,
        ],
      ]}
    />
  );
};

export default RatesAndSettingsGrid;
