import React from "react";
import { Table } from "@redriver/cinnamon";
import { withPermissions } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import EditClientAuthorisedSignatory from "./EditClientAuthorisedSignatory";
import DeleteClientAuthorisedSignatory from "./DeleteClientAuthorisedSignatory";

const ClientAuthorisedSignatoryActions = ({ item, onRefresh, hasPermission }) => {
    const canEdit = hasPermission(Targets.Client, Actions.Edit);
    const canDelete = hasPermission(Targets.Client, Actions.Delete);

    if (!canEdit && !canDelete) return null;

    return (
        <Table.Menu openOnHover>
            {canEdit && (
                <EditClientAuthorisedSignatory
                    item={item}
                    onUpdated={onRefresh}
                    as={Table.MenuItem}>
                    Edit
        </EditClientAuthorisedSignatory>
            )}
            {canDelete && (
                <DeleteClientAuthorisedSignatory
                    item={item}
                    onDeleted={onRefresh}
                    as={Table.MenuItem}>
                    Delete
        </DeleteClientAuthorisedSignatory>
            )}
        </Table.Menu>
    );
};

export default withPermissions(ClientAuthorisedSignatoryActions);
