import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  ActionBar,
  Form,
  ModalBuilder,
  Table,
  useFormField,
  Wizard,
  PageLoader,
  notifySuccess,
} from "@redriver/cinnamon";
import { FunderTypeAhead, FunderSignTypeAhead } from "./lookups/lookups";
import { DocusignAdminRecipientsLookup } from "features/Sheets/lookups";
import { principalPreApproveSheet } from "./actions";
import { Button, Icon } from "semantic-ui-react";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { IconButton } from "features/../../../shared/components/buttons";
import Add from "features/../../../shared/assets/black-add.svg";
import UserArray from "./UserArray";

const OptionsStep = ({ formData, t }) => {
  const ExistenceOptions = [
    { value: false, text: t("SheetActions.SendForApproval.Existing") },
    { value: true, text: t("SheetActions.SendForApproval.New") },
  ];

  return (
    <React.Fragment>
      <FunderTypeAhead />

      <Form.RadioGroup field="isNew" options={ExistenceOptions} />

      <Form.If condition={({ fields }) => fields.isNew == false}>
        <FunderSignTypeAhead funderId={formData?.funderId} />
      </Form.If>

      <Form.If condition={({ fields }) => fields.isNew == true}>
        <Form.Input
          field="fullName"
          label={t("SheetActions.SendForApproval.FullName")}
          inline
          fluid
          required
        />
        <Form.Input
          field="position"
          label={t("SheetActions.SendForApproval.Position")}
          inline
          fluid
          required
        />
        <Form.Email
          field="email"
          label={t("SheetActions.SendForApproval.Email")}
          inline
          fluid
          required
        />
        <Form.Input
          field="phoneNumber"
          label={t("SheetActions.SendForApproval.PhoneNumber")}
          inline
          fluid
          required
        />
        <Form.Input
          field="phoneCountryCode"
          label={t("SheetActions.SendForApproval.PhoneCountryCode")}
          inline
          fluid
          required
        />
      </Form.If>
      <br />

      <Form.ArrayAdder
        field="usersToViewBeforeDocumentSigned"
        arrayKey="id"
        className="wizard-title-button ip-button not-bold"
        as={IconButton}
        icon={Add}
        content={t("SheetActions.SendForApproval.ViewBeforeSigned")}
        populate={() => ({
          id: Date.now(),
          name: "",
          email: "",
        })}
      />

      <UserArray field="usersToViewBeforeDocumentSigned" />

      <Form.ArrayAdder
        field="usersToReceiveCopyOfSignedDocument"
        arrayKey="id"
        className="wizard-title-button ip-button not-bold"
        as={IconButton}
        icon={Add}
        content={t("SheetActions.SendForApproval.ReceiveCopyOfSignedDocument")}
        populate={() => ({
          id: Date.now(),
          name: "",
          email: "",
        })}
      />

      <UserArray field="usersToReceiveCopyOfSignedDocument" />
    </React.Fragment>
  );
};

const ConfirmationStep = ({
  formData,
  scheduleReference,
  agreementReference,
  t,
  sheetName,
  dealerOfficeId,
}) => {
  const formatPhoneNumber = (data) => {
    const countryCode = data?.phoneCountryCode
      ? `(${data?.phoneCountryCode})`
      : "";
    const phoneNumber = data?.phoneNumber ? `${data?.phoneNumber}` : "";

    return countryCode + phoneNumber;
  };

  return (
    <DocusignAdminRecipientsLookup
      dealerOfficeId={dealerOfficeId}
      render={({ loading, response }) => {
        if (loading) {
          return <PageLoader />;
        }

        return (
          <React.Fragment>
            <p>{t("SheetActions.SendForApproval.ConfirmMessage")}</p>
            <p>{t("SheetActions.SendForApproval.Confirm")}</p>
            <p>
              {t("SheetActions.SendForApproval.SheetLabel")}
              <strong>{sheetName}</strong>
            </p>
            <p>
              {t("SheetActions.SendForApproval.ScheduleLabel")}
              <strong>{scheduleReference ?? t("NotLive")}</strong>
            </p>
            <p>
              {t("SheetActions.SendForApproval.AgreementLabel")}
              <strong>{agreementReference ?? t("NotLive")}</strong>
            </p>
            <h3>{t("SheetActions.SendForApproval.RecipientsTable.Title")}</h3>
            <table className="recipients">
              <thead>
                <tr>
                  <th>
                    {t("SheetActions.SendForApproval.RecipientsTable.FullName")}
                  </th>
                  <th>
                    {t("SheetActions.SendForApproval.RecipientsTable.Position")}
                  </th>
                  <th>
                    {t("SheetActions.SendForApproval.RecipientsTable.Email")}
                  </th>
                  <th>
                    {t(
                      "SheetActions.SendForApproval.RecipientsTable.PhoneNumber",
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {formData.usersToViewBeforeDocumentSigned?.map((u, i) => (
                  <tr key={`tr_${i}`}>
                    <td>{u.name}</td>
                    <td>
                      {t(
                        "SheetActions.SendForApproval.RecipientsTable.ViewUserPosition",
                      )}
                    </td>
                    <td>{u.email}</td>
                    <td>{formatPhoneNumber(u)}</td>
                  </tr>
                ))}
                {response?.map((u, i) => (
                  <tr key={`tr_${i}`}>
                    <td>{u.name}</td>
                    <td>{u.position}</td>
                    <td>{u.email}</td>
                    <td>{formatPhoneNumber(u)}</td>
                  </tr>
                ))}
                <tr>
                  <td>
                    {formData.isNew
                      ? formData.fullName
                      : formData.funderSignatory?.name?.split(" - "[0])}
                  </td>
                  <td>
                    {formData.isNew
                      ? formData.position
                      : formData.funderSignatory?.position}
                  </td>
                  <td>
                    {formData.isNew
                      ? formData.email
                      : formData.funderSignatory?.email}
                  </td>
                  <td>
                    {formData.isNew
                      ? formatPhoneNumber(formData)
                      : formatPhoneNumber(formData.funderSignatory)}
                  </td>
                </tr>
                {formData.usersToReceiveCopyOfSignedDocument?.map((u, i) => (
                  <tr key={`tr_${i}`}>
                    <td>{u.name}</td>
                    <td>
                      {t(
                        "SheetActions.SendForApproval.RecipientsTable.CopyUserPosition",
                      )}
                    </td>
                    <td>{u.email}</td>
                    <td>{formatPhoneNumber(u)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </React.Fragment>
        );
      }}
    />
  );
};

const PreApproveSheetPrincipal = ({
  sheet,
  sheetData,
  onSubmitted,
  scheduleReference,
  agreementReference,
  sheetActionRequired,
  generateDocument,
  boilerPlateTandCsId,
}) => {
  const { t } = useTranslation("agreements");
  const dispatch = useDispatch();

  const sheetId = sheet ? sheet.sheetId : sheetData.id;
  const availableActions = sheet
    ? sheet.availableActionsPrincipal
    : sheetData.availableActionsPrincipal;

  if (!availableActions[sheetActionRequired]) return null;

  return (
    <ModalBuilder
      withForm
      initialData={{
        isNew: false,
      }}
      submitAction={principalPreApproveSheet}
      submitParams={{
        sheetId,
      }}
      onSubmitted={() => {
        dispatch(notifySuccess("Sheet successfully pre-approved"));
        onSubmitted();
      }}
      renderTrigger={(trigger) =>
        generateDocument ? (
          <Button
            className="generate-btn"
            primary
            onClick={trigger}
            disabled={!boilerPlateTandCsId}
          >
            <Icon name="check" />
            Generate Document
          </Button>
        ) : (
          <Table.MenuItem onClick={trigger} disabled={!boilerPlateTandCsId}>
            {t("SheetActions.PreApproveSheetPrincipal.TriggerText")}
          </Table.MenuItem>
        )
      }
      renderModal={(modalProps, formProps, state, events) => (
        <TranslatedModal.Edit
          {...modalProps}
          header={t(
            "SheetActions.PreApproveSheetPrincipal.SelectFunderSignatory",
          )}
          className="custom-actions customer-approval"
          size="large"
          onClick={(event) => event && event.stopPropagation()}
        >
          <Form
            {...formProps}
            onClick={(event) => event && event.stopPropagation()}
          >
            <Wizard
              steps={[
                { key: "optionsStep" },
                { key: "confirmationStep", canSubmit: true },
              ]}
            >
              <Wizard.Step
                step="optionsStep"
                render={() => <OptionsStep formData={formProps.value} t={t} />}
              />
              <Wizard.Step
                step="confirmationStep"
                render={() => (
                  <ConfirmationStep
                    formData={formProps.value}
                    agreementReference={agreementReference}
                    scheduleReference={scheduleReference}
                    sheetName={sheet ? sheet.sheetName : sheetData.name}
                    dealerOfficeId={
                      sheet ? sheet.dealerOfficeId : sheetData.dealerOfficeId
                    }
                    t={t}
                  />
                )}
              />
              <Wizard.Nav
                component={({
                  activeStep,
                  hasNextStep,
                  hasPreviousStep,
                  onGoToNextStep,
                  onGoToPreviousStep,
                }) => (
                  <div className="custom-action-footer">
                    <ActionBar>
                      <ActionBar.Item>
                        <Button
                          className="cancel"
                          content={t("Cancel")}
                          disabled={modalProps.submitting}
                          onClick={modalProps.onCancel}
                        />
                      </ActionBar.Item>
                      <ActionBar.Space />
                      {hasPreviousStep && (
                        <ActionBar.Item fluid align="right">
                          <Button
                            className="previous"
                            content={t("Back")}
                            disabled={modalProps.submitting}
                            onClick={onGoToPreviousStep}
                          />
                        </ActionBar.Item>
                      )}
                      {hasNextStep && (
                        <ActionBar.Item
                          fluid={!hasPreviousStep} //prevent random whitespace
                          align="right"
                        >
                          <Button
                            primary
                            className="next"
                            content={t("Next")}
                            loading={state.submitting}
                            disabled={state.submitting}
                            onClick={() => {
                              let { formValid } = state;
                              if (formValid) {
                                onGoToNextStep();
                                return;
                              }
                              events.onSubmit();
                            }}
                          />
                        </ActionBar.Item>
                      )}
                      {activeStep.canSubmit && (
                        <ActionBar.Item>
                          <Button
                            className="positive submit"
                            content={t("Confirm")}
                            loading={modalProps.submitting}
                            disabled={modalProps.submitting}
                            onClick={() => {
                              modalProps.submitting = true;
                              events.onSubmit();
                            }}
                          />
                        </ActionBar.Item>
                      )}
                    </ActionBar>
                  </div>
                )}
              />
            </Wizard>
          </Form>
        </TranslatedModal.Edit>
      )}
    />
  );
};

export default PreApproveSheetPrincipal;
