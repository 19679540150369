import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, useAuthClaims } from "@redriver/cinnamon";
import { getRole, updateRole } from "./actions";
import RoleForm from "./RoleForm";
import { TranslatedModal } from "../../../components/modals";

const EditRole = ({ as: As = Button, role, onUpdated, ...props }) => {
  const { userArea } = useAuthClaims();
  return (
    <ModalBuilder
      withForm
      loadAction={getRole}
      loadParams={role.id}
      submitAction={updateRole}
      submitParams={role.id}
      onSubmitted={onUpdated}
      renderTrigger={showModal => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Edit {...modalProps} header="Edit Template">
          <RoleForm formProps={formProps} userArea={userArea} editMode />
        </TranslatedModal.Edit>
      )}
    />
  );
};

export default EditRole;
