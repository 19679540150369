import React from "react";
import { Form } from "@redriver/cinnamon";
import { UserType } from "features/../../../shared/constants/enums";
import {
  RoleDropdown,
  UserTypeDropdown,
  UserAreaDropdown,
  DealerOfficesTypeAheadLookup,
  OfficeAccessTree,
} from "../lookups";
import { UserTypeCheck } from "features/../../../shared/components/auth";
import { Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { DealersTypeAheadLookup } from "../../Dealers/lookups";

// this file needs to change.

const UserForm = ({
  formProps,
  companyId = null,
  isEditMode = false,
  loading,
  showAccessTree,
  setShowAccessTree,
  userArea,
}) => {
  const { t } = useTranslation("supplierOffices", { keyPrefix: "users" });
  
  setShowAccessTree(formProps.value?.enableOfficeAccess)  

  return (
    <div className="no-disable-box">
      <Form {...formProps}>
        <div className="ui form highlighted-inputs">
          {/* Next Cinnamon release will contain bug fix to pass className to Form */}
          <Grid columns={3} stackable>
            <Grid.Row>
              <Grid.Column>
                <Form.Input
                  field="firstName"
                  label={t("firstName")}
                  placeholder={t("firstName")}
                  required
                  fluid
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Input
                  field="lastName"
                  label={t("lastName")}
                  placeholder={t("lastName")}
                  required
                  fluid
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Email
                  field="email"
                  label={t("email")}
                  placeholder={t("email")}
                  required
                  fluid
                  disabled={isEditMode}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <UserTypeCheck restrictTo={UserType.HQ}>
                <Grid.Column>
                  <UserTypeDropdown
                    fluid
                    required
                    disabled={!!companyId}
                    textField="userType"
                  />
                </Grid.Column>
              </UserTypeCheck>
              <Grid.Column>
                <UserAreaDropdown
                  fluid
                  required
                  lookupParams={{
                    userType: formProps.value.userType,
                    userArea: userArea,
                  }}
                  clearable
                />
              </Grid.Column>
              <UserTypeCheck restrictTo={UserType.HQ}>
                <Grid.Column>
                  <Form.If
                    condition={({ fields }) =>
                      fields.userType != UserType.HQ && !companyId
                    }
                  >
                    <DealersTypeAheadLookup
                      field="companyId"
                      textField="company"
                      label={t("supplier")}
                      placeholder={t("supplierPlaceholder")}
                      clearable
                      fluid
                      required
                    />
                  </Form.If>
                </Grid.Column>
              </UserTypeCheck>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {showAccessTree && (
                  <OfficeAccessTree
                    id={formProps.value.companyId}
                    field="officeAccessRefs"
                    label="Office"
                    required
                  />
                )}
              </Grid.Column>
              <UserTypeCheck restrictTo={UserType.HQ}>
                <Grid.Column style={{ width: "66.66%" }}>
                  {formProps.value.userArea && (
                    <RoleDropdown
                      label={t("template")}
                      field="originalTemplateId"
                      fluid
                      clearable
                      lookupParams={formProps.value.userArea}
                      placeholder={t("rolesPlaceholder")}
                    />
                  )}
                </Grid.Column>
              </UserTypeCheck>
            </Grid.Row>
          </Grid>
          {!loading && (
            <React.Fragment>
              <Form.Trigger field="userType" target="userArea" action="clear" />
              <Form.Trigger field="userArea" target="roleIds" action="clear" />
            </React.Fragment>
          )}
        </div>
      </Form>
    </div>
  );
};

export default UserForm;
