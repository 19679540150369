import React from "react";
import { useTranslation } from "react-i18next";
import { Format } from "@redriver/cinnamon";
import DataGrid from "./DataGrid";

const TotalIPSIncomeGrid = ({ ipSyndicateRentalIncome }) => {
  const { t } = useTranslation("capitalCalc", {
    keyPrefix: "infoModal.funderTypes.totalIpsIncome",
  });

  return (
    <DataGrid
      className="total-ips-income"
      columnCount={2}
      isFirstRowHeader
      dataRows={[
        [
          t("title"),
          <Format.Number
            key={1}
            value={ipSyndicateRentalIncome}
            format={process.env.CURRENCY_CODE}
          />,
        ],
      ]}
    />
  );
};

export default TotalIPSIncomeGrid;
