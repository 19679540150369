import React from "react";
import { Link } from "react-router-dom";

const BackToLink = ({ text, to }) => (
  <div className="navigate-back">
    <Link to={to}>{text}</Link>
  </div>
);

export default BackToLink;
