import { produce } from "immer";
import { ActionTypes } from "./actions";

const initialState = { persistentQueryParams: {} };

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SetPersistentQueryParams:
      return produce(state, (draft) => {
        draft.persistentQueryParams = action.data;
      });

    default:
      return state;
  }
};
