import { newFixedUnit, newFixedInput, newFixedBoolean } from "components/forms";
import {
  SheetState,
  UserType,
  UserArea,
  LfpPaperCodeSuffixes,
  LfpItemType,
  LfpMeterType,
  LargeFormatPriceType,
  SheetType,
  SheetActionIps,
  SheetActionPrincipal,
} from "features/../../../shared/constants/enums";
import { orderBy, isNil } from "lodash";
import {
  QrtServiceFieldType,
  UtilityMeterDisplayCode,
  ScanMeterDisplayCode,
} from "./constants";

/**
 * Determines whether or not `value` is a real number, or a string representation
 * of a real number, that can be successfully parsed as a float. Note that
 * `Infinity` is considered a real number, and will cause this function to return
 * `true`.
 * @param {Number} value The value to test
 * @returns {Boolean} A bool indicating whether or not `value` is a real number
 */
export const isRealNumber = (value) =>
  !Array.isArray(value) && !isNaN(parseFloat(value));

export const newScheduleItem = (
  key,
  unit,
  costPerClick, // this is a fixable field, not the value
  minQuarterly, // this is a fixable field, not the value
  dependant = "",
  defaultMinVolume = 0,
  defaultMinSalePrice = 0,
  vMinSalePrice = 0,
  vMinVolume = 0,
  tier1DefaultPercentage = 0,
  tier2DefaultPercentage = 0,
  tier2DefaultValue = 0,
  tier3DefaultValue = 0,
  vMinPercentageTier1 = 0,
  vMaxPercentageTier1 = null,
  vMaxPercentageTier2 = null,
  vMinValueTier2 = 0,
  vMaxPercentageTier3 = null,
  vMinValueTier3 = 0,
) => {
  return {
    [key]: `${Date.now()}_${unit ? unit.unitCode : ""}_${dependant}`,
    unit: unit || newFixedUnit(null),
    costPerClick,
    includeManagedVol: unit?.alwaysUnmanaged
      ? newFixedBoolean(false)
      : newFixedBoolean(true),
    minQuarterly,
    costPerUnit: 0,
    tonerExclusive: newFixedBoolean(false),
    consumerExclusive: newFixedBoolean(false),
    tieredToner: newFixedBoolean(false),
    tier1UpperBoundaryPercentage: newFixedInput(tier1DefaultPercentage),
    tier2UpperBoundaryPercentage: newFixedInput(tier2DefaultPercentage),
    tier2Adds: newFixedInput(tier2DefaultValue),
    tier3Adds: newFixedInput(tier3DefaultValue),
    displayCode: "",
    defaultMinVolume,
    defaultMinSalePrice,
    vMinSalePrice,
    vMinVolume,
    tier1DefaultPercentage: tier1DefaultPercentage,
    tier2DefaultPercentage: tier2DefaultPercentage,
    tier2DefaultValue: tier2DefaultValue,
    tier3DefaultValue: tier3DefaultValue,
    vMinPercentageTier1,
    vMaxPercentageTier1,
    vMaxPercentageTier2,
    vMinValueTier2,
    vMaxPercentageTier3,
    vMinValueTier3,
  };
};

export const shouldPreserveScheduleItemCodes = (sheet) => {
  /*
   * re. 96365
   * preserve Schedule Item codes if
   * - we are NOT a Draft
   * - OR we are a Rewrite
   */
  return (
    !process.env.TRADE_AS_IPS ||
    sheet?.rates?.sheetType == SheetType.Rewrite ||
    sheet?.rates?.sheetType == SheetType.RewriteNewMasterDocument ||
    !sheet.isDraft
  );
};

export const calcScheduleItemCode = (item, scheduleItems) => {
  if (!item || !item.unit || !scheduleItems) return "";

  let unitsOfSameType = scheduleItems
    .filter((x) => x.unit.value === item.unit.value)
    .map((x) => ({
      id: x.id,
      index: parseInt((x.displayCode || "").replace(/\D/g, "")),
    }));

  unitsOfSameType = orderBy(
    unitsOfSameType.filter((x) => x.index),
    ["index"],
    ["desc"],
  );
  const thisUnit = unitsOfSameType.find((x) => x.id == item.id);
  const lastUnit = unitsOfSameType.find((x) => x.id != item.id) || {};

  //workaround to prevent update of for example B1 -> B3 if B2 already exists
  if (thisUnit && thisUnit.index < lastUnit.index) return item.displayCode;

  return `${item.unit.unitCode}${(lastUnit.index || 0) + 1}`;
};

export const calcScheduleItemCode_PrincipalMode = (item, sheetData) => {
  if (!item?.unit || !sheetData.systemScheduleItems) return "";

  const isUtility = item.unit.isUtility || item.addedAsUtility;

  const scheduleItemUtilityMeters = sheetData.systemScheduleItems.filter(
    (x) => x.addedAsUtility,
  );

  const lfpItems = getLFPItems(sheetData.largeFormatGroups);

  const totalExistingUtilityMeters =
    scheduleItemUtilityMeters.length + lfpItems.length;
  const allUtilityMeters = [...scheduleItemUtilityMeters, ...lfpItems];

  let unitsOfSameType;
  if (isUtility) {
    const sysSchUnitsOfSameType = sheetData.systemScheduleItems
      .filter((x) => x.unit.isUtility || x.addedAsUtility)
      .map((x) => ({
        id: x.id,
        index: parseInt((x.displayCode || "").replace(/\D/g, "")),
        displayCode: x.displayCode,
      }));
    const lfpUnitsOfSameType = lfpItems.map((x) => ({
      id: x.id,
      index: parseInt((x.displayCode || "").replace(/\D/g, "")),
      displayCode: x.displayCode,
    }));
    unitsOfSameType = sysSchUnitsOfSameType.concat(lfpUnitsOfSameType);
  } else {
    unitsOfSameType = sheetData.systemScheduleItems
      .filter((x) => x.unit.value === item.unit.value && !x.addedAsUtility)
      .map((x) => ({
        id: x.id,
        index: parseInt((x.displayCode || "").replace(/\D/g, "")),
        displayCode: x.displayCode,
      }));
  }

  unitsOfSameType = orderBy(
    unitsOfSameType?.filter((x) => x.index),
    ["index"],
    ["desc"],
  );

  const thisUnit = unitsOfSameType.find((x) => x.id == item.id);
  const lastUnit = unitsOfSameType.find((x) => x.id != item.id) || {};

  // //workaround to prevent update of for example B1 -> B3 if B2 already exists
  if (thisUnit && thisUnit.index < lastUnit.index) {
    return {
      displayCode: item.displayCode,
      addedAsUtility: item.addedAsUtility,
    };
  }

  let displayCode = "";
  let addedAsUtility = false;
  let index = (lastUnit?.index || 0) + 1;

  const isScan = item?.unit?.unitCode === ScanMeterDisplayCode;
  const maximumIndex = isScan ? 1 : 2;

  if (isUtility || (!isUtility && index > maximumIndex)) {
    if (totalExistingUtilityMeters <= 5) {
      for (let i = 1; i <= 5; i++) {
        const potentialDisplayCode = `${UtilityMeterDisplayCode}${i}`;
        if (
          !allUtilityMeters.some((x) => x.displayCode === potentialDisplayCode)
        ) {
          displayCode = potentialDisplayCode;
          addedAsUtility = true;
          break;
        }
      }
    } else {
      displayCode = "-";
    }
  } else {
    if (index <= maximumIndex) {
      displayCode = `${item.unit.unitCode}${index}`;
    }
  }

  return { displayCode, addedAsUtility };
};
let lfSchItemCodeCalculated = 0;
export const calcLfSchItemCode = (
  sheet,
  fieldName,
  paperArrayIndex,
  largeFormatGroups,
  groupIndex,
  meteredServices,
  meterType,
  paperCode,
) => {
  const principalMode = !process.env.TRADE_AS_IPS;

  if (principalMode) {
    let utilityMeters = sheet.systemScheduleItems
      .filter((x) => x.addedAsUtility)
      .map((x) => ({
        id: x.id,
        index: parseInt((x.displayCode || "").replace(/\D/g, "")),
      }));

    const lfpItems = getLFPItems(sheet.largeFormatGroups);

    let lfpUtilityMeters = [];
    lfpItems.map((x) =>
      lfpUtilityMeters.push({
        id: x.id,
        index: parseInt((x.displayCode || "").replace(/\D/g, "")),
      }),
    );

    utilityMeters = utilityMeters.concat(lfpUtilityMeters);

    const lastUtilityIndex =
      utilityMeters.length > 0
        ? utilityMeters[utilityMeters.length - 1].index
        : 0;
    lfSchItemCodeCalculated++;
    const index = parseInt(lastUtilityIndex) + lfSchItemCodeCalculated;
    if (index > 5) {
      return "-";
    }

    let result = `${UtilityMeterDisplayCode}${index}`;
    return result;
  }

  let suffixCode =
    meterType == LfpMeterType.Paper
      ? paperCode
      : meteredServices.find((x) => x.meterType == meterType)?.code;

  let arrayIndex =
    largeFormatGroups.length > 0 ? largeFormatGroups.length - 1 : 0;

  fieldName = fieldName == "paperMeter" ? "paperMeters" : fieldName;

  //get last printer in array.
  //if there is a groupIndex supplied use this to get the correct item in array
  //as user maybe editing an item within the array as apposed to adding to the end of the array.
  const arrayPosition = groupIndex !== undefined ? groupIndex : arrayIndex;

  let printerItem =
    largeFormatGroups[arrayPosition] &&
    largeFormatGroups[arrayPosition]["printerMeter"]
      ? largeFormatGroups[arrayPosition]["printerMeter"]
      : null;

  //get suffix to remove from display code later.
  const printerItemSuffix = printerItem
    ? printerItem.largeFormatMeteredServiceCode
    : null;
  let highestNoInSequence = 0;
  let paperArraySuffix = "";

  if (printerItem) {
    highestNoInSequence = printerItem.displayCode;

    highestNoInSequence = highestNoInSequence.substring(
      printerItemSuffix.length,
    );
    //when editing remove 1 from from sequence
    if (groupIndex != undefined) {
      highestNoInSequence = highestNoInSequence - 1;
    }
  }

  //when adding additional meters we must append a suffix
  //if there are already existing paper meters in the group we must find the highest letter
  //and then get the next letter from the Suffix array to prevent duplicates when editing.
  if (fieldName == "paperMeters") {
    let position = 0;
    const pmArrayPosition =
      groupIndex !== undefined ? groupIndex : arrayIndex + 1;
    const currentItem =
      largeFormatGroups[pmArrayPosition] &&
      largeFormatGroups[pmArrayPosition][fieldName]
        ? largeFormatGroups[pmArrayPosition][fieldName]
        : null;

    if (currentItem && currentItem.length > 0) {
      //get suffix of last paperMeter
      let pmSuffix = currentItem[currentItem.length - 1].displayCode;

      if (pmSuffix) {
        pmSuffix = pmSuffix.slice(-1);
      }

      //get position of last suffix within the array.
      position =
        pmSuffix != null
          ? LfpPaperCodeSuffixes.findIndex((obj) => obj === pmSuffix)
          : 0;
    }

    paperArraySuffix =
      position >= paperArrayIndex && position > 0
        ? LfpPaperCodeSuffixes[position + 1]
        : paperArrayIndex != null
        ? LfpPaperCodeSuffixes[paperArrayIndex]
        : "";
  }

  return `${suffixCode ? suffixCode : "-"}${
    parseInt(highestNoInSequence) + 1
  }${paperArraySuffix}`;
};

export const newLargeFormatScheduleGroup = (
  key,
  largeFormatCreateModal,
  largeFormatDefaults,
  largeFormatGroups,
  edit = false,
  sheet,
) => {
  const {
      lfPrinterConfig,
      lfInkInclusive,
      paperInclusiveId,
      printerId,
      printerInkUsageId,
      lfRollMedia,
      includeScan,
      includeCleaning,
      priceType,
      editMode,
      compPrinterInkUsageId,
    } = largeFormatCreateModal,
    { paperMeter } = largeFormatDefaults || {};

  // get original large format group being edited
  const printerChanged = largeFormatCreateModal?.printerChanged ?? false;
  const arrayPosition = largeFormatCreateModal?.groupIndex;
  const originalLFG = largeFormatGroups[arrayPosition];
  const originalPrinterItem = originalLFG?.printerMeter;
  const originalScanItem = originalLFG?.scanMeter;
  const originalCleaningItem = originalLFG?.otherMeter;
  const originalPaperMeters = originalLFG?.paperMeters;

  //if user is editing a group and the printer hasn't been changed retain original values where necessary.
  const persistOriginalValues = edit && !printerChanged;
  const persistOriginalScanValues = persistOriginalValues && originalScanItem;
  const persistOriginalCleaningValues =
    persistOriginalValues && originalCleaningItem;

  const selectedInkMeter = lfPrinterConfig?.meteredServices.find(
    (x) => x.meterType == LfpMeterType.Ink,
  );
  const selectedScanMeter = lfPrinterConfig?.meteredServices.find(
    (x) => x.meterType == LfpMeterType.Scan,
  );
  const selectedCleaningMeter = lfPrinterConfig?.meteredServices.find(
    (x) => x.meterType == LfpMeterType.Cleaning,
  );

  const selectedInkMeterPrices =
    selectedInkMeter?.prices?.find((x) => x.priceType == priceType) ?? {};
  const selectedScanMeterPrices =
    selectedScanMeter?.prices?.find((x) => x.priceType == priceType) ?? {};
  const selectedCleaningMeterPrices =
    selectedCleaningMeter?.prices.find((x) => x.priceType == priceType) ?? {};

  const baseId = Date.now();
  lfSchItemCodeCalculated = 0;
  const result = {
    [key]: Date.now(),
    compPrinterInkUsageId: compPrinterInkUsageId || null,
    paperInclusiveId: paperInclusiveId || null,
    paperInclusiveName: lfRollMedia.text || null,
    printerId: printerId || null,
    printerName: lfPrinterConfig.text,
    printerInkUsageId: printerInkUsageId || null,
    inkInclusiveName: lfInkInclusive.text || null,
    inkInclusiveId: printerInkUsageId || null,
    scannerIncluded: includeScan || false,
    includeCleaning: includeCleaning || false,
    priceType: priceType || LargeFormatPriceType.PriceA,
    editMode: editMode || false,
    hasCleaningMeter: lfPrinterConfig.hasCleaningMeter,
    hasScannerMeter: lfPrinterConfig.hasScannerMeter,
    largeFormatCreateModal: {
      ...largeFormatCreateModal,
    },
    CostPerMl: 1.1,
    printerMeter: newMeter(
      key,
      baseId + 0,
      persistOriginalValues
        ? clearIdFromFixedField(originalPrinterItem.costPerClick)
        : newFixedInput(selectedInkMeterPrices.value),
      persistOriginalValues ||
        (edit &&
          originalPrinterItem?.minQuarterlyVolume.value >
            selectedInkMeterPrices.defaultMinVolume)
        ? clearIdFromFixedField(originalPrinterItem.minQuarterlyVolume)
        : newFixedInput(selectedInkMeterPrices.defaultMinVolume),
      selectedInkMeter.id,
      selectedInkMeter.code,
      selectedInkMeter.name,
      persistOriginalValues
        ? originalPrinterItem.displayCode
        : calcLfSchItemCode(
            sheet,
            "printerMeter",
            null,
            largeFormatGroups,
            arrayPosition,
            lfPrinterConfig.meteredServices,
            LfpMeterType.Ink,
            null,
          ),
      persistOriginalValues && originalPrinterItem.includeManagedVol
        ? clearIdFromFixedField(originalPrinterItem?.includeManagedVol)
        : newFixedBoolean(!selectedInkMeter.alwaysUnmanaged),
      selectedInkMeter.alwaysUnmanaged,
      selectedInkMeter.alwaysManaged,
      null,
      null,
      LfpItemType.Printer,
      null,
      null,
      selectedInkMeterPrices.vMinVolume,
      selectedInkMeterPrices.vMinSalePrice,
      selectedInkMeterPrices.value,
      selectedInkMeterPrices.defaultMinVolume,
      null,
    ),
    otherMeter:
      includeCleaning && selectedCleaningMeter
        ? newMeter(
            key,
            baseId + 1,
            persistOriginalCleaningValues && originalCleaningItem
              ? clearIdFromFixedField(originalCleaningItem.costPerClick)
              : newFixedInput(selectedCleaningMeterPrices.value),
            persistOriginalCleaningValues ||
              (edit &&
                originalCleaningItem?.minQuarterlyVolume.value >
                  selectedCleaningMeterPrices.defaultMinVolume)
              ? clearIdFromFixedField(originalCleaningItem.minQuarterlyVolume)
              : newFixedInput(selectedCleaningMeterPrices.defaultMinVolume),
            selectedCleaningMeter.id,
            selectedCleaningMeter.code,
            selectedCleaningMeter.name,
            persistOriginalCleaningValues
              ? originalCleaningItem.displayCode
              : calcLfSchItemCode(
                  sheet,
                  "otherMeter",
                  null,
                  largeFormatGroups,
                  arrayPosition,
                  lfPrinterConfig.meteredServices,
                  LfpMeterType.Cleaning,
                  null,
                ),
            persistOriginalCleaningValues && originalCleaningItem
              ? clearIdFromFixedField(originalCleaningItem.includeManagedVol)
              : newFixedBoolean(!selectedCleaningMeter.alwaysUnmanaged),
            selectedCleaningMeter.alwaysUnmanaged,
            selectedCleaningMeter.alwaysManaged,
            null,
            null,
            LfpItemType.Other,
            null,
            null,
            selectedCleaningMeterPrices.vMinVolume,
            selectedCleaningMeterPrices.vMinSalePrice,
            selectedCleaningMeterPrices.value,
            selectedCleaningMeterPrices.defaultMinVolume,
            null,
          )
        : undefined,
    scanMeter:
      includeScan && selectedScanMeter
        ? newMeter(
            key,
            baseId + 5,
            persistOriginalScanValues && originalScanItem
              ? clearIdFromFixedField(originalScanItem.costPerClick)
              : newFixedInput(selectedScanMeterPrices.value),
            persistOriginalCleaningValues ||
              (edit &&
                originalScanItem?.minQuarterlyVolume.value >
                  selectedScanMeterPrices.defaultMinVolume)
              ? clearIdFromFixedField(originalScanItem.minQuarterlyVolume)
              : newFixedInput(selectedScanMeterPrices.defaultMinVolume),
            selectedScanMeter.id,
            selectedScanMeter.code,
            selectedScanMeter.name,
            persistOriginalScanValues
              ? originalScanItem.displayCode
              : calcLfSchItemCode(
                  sheet,
                  "scanMeter",
                  null,
                  largeFormatGroups,
                  arrayPosition,
                  lfPrinterConfig.meteredServices,
                  LfpMeterType.Scan,
                  null,
                ),
            persistOriginalScanValues
              ? clearIdFromFixedField(originalScanItem.includeManagedVol)
              : newFixedBoolean(!selectedScanMeter.alwaysUnmanaged),
            selectedScanMeter.alwaysUnmanaged,
            selectedScanMeter.alwaysManaged,
            null,
            null,
            LfpItemType.Scanner,
            null,
            null,
            selectedScanMeterPrices.vMinVolume,
            selectedScanMeterPrices.vMinSalePrice,
            selectedScanMeterPrices.value,
            selectedScanMeterPrices.defaultMinVolume,
            null,
          )
        : undefined,
    paperMeters: (lfRollMedia || []).map((lfRollMediaItem, mediaIndex) => {
      const media = lfRollMediaItem.lfRollMedia || {};
      const { cost, length, width, text, minVolume, vMinVolume } = media;
      const unit2Total = process.env.USE_IMPERIAL_UNITS
        ? length * inchesToFeet(width)
        : length * (width / 1000);
      let costPerUnit2 =
        unit2Total != 0
          ? (cost / unit2Total) * (process.env.USE_MINOR_CURRENCY ? 100 : 1)
          : 0;
      costPerUnit2 = costPerUnit2.toFixed(
        process.env.USE_MINOR_CURRENCY ? 3 : 5,
      );
      const oldPaperMeter =
        (originalPaperMeters && originalPaperMeters[mediaIndex]) || null;

      return newMeter(
        key,
        baseId + 3 + mediaIndex,
        persistOriginalValues && oldPaperMeter && oldPaperMeter.costPerClick
          ? clearIdFromFixedField(oldPaperMeter.costPerClick)
          : newFixedInput(costPerUnit2),
        persistOriginalValues &&
          oldPaperMeter &&
          oldPaperMeter.minQuarterlyVolume
          ? clearIdFromFixedField(oldPaperMeter.minQuarterlyVolume)
          : newFixedInput(lfRollMediaItem?.lfRollMedia?.minVolume),
        //paper meters have no lfms ID value will always be null
        null,
        paperMeter?.code,
        paperMeter?.name,
        (oldPaperMeter || {}).displayCode ||
          calcLfSchItemCode(
            sheet,
            "paperMeter",
            mediaIndex,
            largeFormatGroups,
            arrayPosition,
            lfPrinterConfig.meteredServices,
            LfpMeterType.Paper,
            paperMeter?.code,
          ),
        persistOriginalValues &&
          oldPaperMeter &&
          oldPaperMeter.includeManagedVol
          ? clearIdFromFixedField(oldPaperMeter.includeManagedVol)
          : newFixedBoolean(!paperMeter?.alwaysUnmanaged),
        paperMeter?.alwaysUnmanaged,
        paperMeter?.alwaysManaged,
        oldPaperMeter?.paperInclusiveId || lfRollMediaItem.paperInclusiveId,
        oldPaperMeter?.paperWidthId || lfRollMediaItem.paperWidthId,
        LfpItemType.Paper,
        text,
        lfRollMediaItem.paperWidth,
        vMinVolume,
        null,
        cost,
        minVolume,
        persistOriginalValues && oldPaperMeter && oldPaperMeter.costPerClick
          ? oldPaperMeter.costPerClick
          : costPerUnit2,
      );
    }),
  };

  return result;
};

export const newPaperMeter = (key, index) => {
  return {
    [key]: Date.now(),
    index,
    cost: 0,
    width: 0,
    length: 0,
    weight: 0,
    paperWidthId: "",
    _widthIdMetric: "", //used internally
    _widthIdImperial: "", //used internally
    paperInclusiveId: "",
  };
};

const newMeter = (
  key,
  id,
  costPerClick,
  minQuarterly,
  unitValue,
  unitCode,
  unitName,
  displayCode,
  includeManagedVol,
  alwaysUnmanaged,
  alwaysManaged,
  paperInclusiveId,
  paperWidthId,
  type,
  paperName,
  paperWidth,
  vMinVolume,
  vMinSalePrice,
  defaultSalePrice,
  defaultVolume,
  defaultCostPerClick,
  isUtility,
) => {
  const meter = {
    [key]: id,
    displayCode: displayCode,
    costPerClick: costPerClick,
    includeManagedVol: includeManagedVol,
    minQuarterlyVolume: minQuarterly,
    costPerUnit: 0,
    paperInclusiveId,
    paperWidthId,
    paperInclusiveName: paperName,
    paperWidth,
    type,
    largeFormatMeteredServiceId: unitValue,
    largeFormatMeteredServiceCode: unitCode,
    largeFormatMeteredServiceName: unitName,
    vMinVolume: vMinVolume,
    vMinSalePrice: vMinSalePrice,
    alwaysManaged: alwaysManaged,
    alwaysUnmanaged: alwaysUnmanaged,
    defaultSalePrice: defaultSalePrice,
    defaultVolume: defaultVolume,
    defaultCostPerClick: defaultCostPerClick,
    isUtility: isUtility,
  };
  return meter;
};

export const calculateServicesTotals = (
  sheetServices,
  serviceData,
  totalDevices,
) => {
  let serviceAmount,
    result = getWizardSplitTotals();
  Array.isArray(sheetServices) &&
    sheetServices.forEach((s) => {
      const matchedService = serviceData.find((x) => x.id == s.service.value),
        value = parseFloat(s.value.value || 0);
      serviceAmount =
        matchedService && matchedService.multiplyByDeviceNumber
          ? value * totalDevices
          : value;

      switch (s.fieldType.toLowerCase()) {
        case QrtServiceFieldType.Standard.value.toLowerCase():
          result.salesperson += serviceAmount;
          result.dealerAdmin += serviceAmount;
          result.ipsAdmin += serviceAmount;
          break;
        case QrtServiceFieldType.Admin.value.toLowerCase():
          result.dealerAdmin += serviceAmount;
          result.ipsAdmin += serviceAmount;
          break;
        case QrtServiceFieldType.Ip.value.toLowerCase():
          result.ipsAdmin += serviceAmount;
          break;
      }
    });

  return result;
};

export const getWizardSplitTotals = (salesperson, dealerAdmin, ipsAdmin) => {
  return {
    salesperson: salesperson || 0,
    dealerAdmin: dealerAdmin || 0,
    ipsAdmin: ipsAdmin || 0,
    getTotal: function (userType, userArea) {
      return userType === UserType.HQ
        ? this.ipsAdmin || 0
        : userArea === UserArea.SupplierAdmin
        ? this.dealerAdmin || 0
        : this.salesperson || 0;
    },
  };
};

export const canPerformAction = (sheetAction, sheetData) =>
  process.env.TRADE_AS_IPS
    ? sheetData.availableActionsIps?.[sheetAction]
    : sheetData.availableActionsPrincipal?.[sheetAction];

export const canSheetBeUpdated = (sheetData) =>
  !sheetData.preventUpdates &&
  canPerformAction(
    process.env.TRADE_AS_IPS
      ? SheetActionIps.CanUpdate
      : SheetActionPrincipal.CanUpdate,
    sheetData,
  );

export const invalidSystemScheduleItemsExist = (sheetData) => {
  let invalidSchedule = false;

  if (typeof sheetData.systemScheduleItems != "undefined") {
    sheetData.systemScheduleItems.forEach((x) => {
      if (
        x.unit.value == "" ||
        isZeroOrEmpty(x.costPerClick.value) ||
        isZeroOrEmpty(x.minQuarterly.value)
      ) {
        invalidSchedule = true;
      }
    });

    sheetData.largeFormatGroups.forEach((x) => {
      if (
        (x.otherMeter != null &&
          (isZeroOrEmpty(x.otherMeter.costPerClick.value) ||
            isZeroOrEmpty(x.otherMeter.minQuarterlyVolume.value))) ||
        (x.paperMeter != null &&
          (isZeroOrEmpty(x.paperMeter.costPerClick.value) ||
            isZeroOrEmpty(x.paperMeter.minQuarterlyVolume.value))) ||
        (x.printerMeter != null &&
          (isZeroOrEmpty(x.printerMeter.costPerClick.value) ||
            isZeroOrEmpty(x.printerMeter.minQuarterlyVolume.value))) ||
        (x.includeScanMeter != null &&
          (isZeroOrEmpty(x.includeScanMeter.costPerClick.value) ||
            isZeroOrEmpty(x.includeScanMeter.minQuarterlyVolume.value)))
      ) {
        invalidSchedule = true;
      }
    });

    return invalidSchedule;
  }
  return false;
};
export const isEmpty = (value) =>
  value == null ||
  (typeof value === "string" && value.trim().length === 0) ||
  Object.keys(value).length === 0;

export const isZeroOrEmpty = (value) =>
  value === "" || value == 0 || value == null;

export const checkMaxMin = (value, max, min) => {
  if (value > max) return max;
  if (value < min) return min;
  return value;
};

export const checkMax = (value, max) => {
  if (value > max) return max;
  return value;
};

export const isFieldFixed = (field) => {
  return (
    !isNil(field.maximumValue) || !isNil(field.minimumValue) || field.isFixed
  );
};

export const inchesToFeet = (inches) => {
  if (inches == null || inches == "") return null;

  return inches / 64 / 12;
};
export const hasComparisonSnapshot = (
  systemScheduleItems,
  largeFormatGroups,
) => {
  //check if oldValuesPresent, as this determines if a sheet has a comparison snapshot
  let oldValuesPresent = false;
  systemScheduleItems &&
    systemScheduleItems.forEach((item) => {
      if (item.oldMinQuarterly != null || item.oldCostPerUnit != null) {
        oldValuesPresent = true;
      }
    });

  !oldValuesPresent &&
    largeFormatGroups &&
    largeFormatGroups.forEach((item) => {
      item.items &&
        item.items.forEach((meter) => {
          if (meter.oldMinQuarterly != null || meter.oldCostPerUnit != null) {
            oldValuesPresent = true;
          }
        });
    });

  return oldValuesPresent;
};

//function to override default value when field is fixed.
export const shouldUseDefault = (fixedField) => {
  return !isFieldFixed(fixedField) && !isRealNumber(fixedField.value);
};

//function to reduce array and sum value.value items
export const sumValues = (array) => {
  return array.reduce(function (prev, current) {
    return (
      prev +
      parseFloat((current.value.value == "" ? 0 : current.value.value) ?? 0)
    );
  }, 0);
};

//function to remove id from fixed field, used when updating Large Format group to ensure we don't loose
//the fixed/min/max properties of an existing fixed field when editing a group.
const clearIdFromFixedField = (fixedField) => {
  return { ...fixedField, id: null };
};

export const concatenateServiceArrays = (servicesSection) =>
  servicesSection?.mandatoryServices
    .concat(servicesSection?.services)
    .concat(servicesSection?.adminServices)
    .concat(servicesSection?.ipServices)
    .filter((x) => x != null);

export const isUnexpectedError = (error) => {
  if (error?.message) {
    return (
      error?.response[0]?.message?.toLowerCase().includes("please contact") ||
      error?.response[0]?.message?.toLowerCase().includes("unexpected")
    );
  }
};
export const isUnexpectedErrorMessage = (message) => {
  return message?.toLowerCase().includes("unexpected");
};

export const getLFPItems = (largeFormatGroups) => {
  return largeFormatGroups
    .map((x) =>
      [x.printerMeter, x.scanMeter, x.otherMeter]
        .concat(x.paperMeters)
        .filter((x) => x),
    )
    .flat();
};
