import React from "react";
import { Form } from "@redriver/cinnamon";
import { ProductMeteredServiceDropdown } from "./lookup";

const ApplicableItemArray = ({
  itemUnitList,
  currentlySelected,
  sheetId,
  applicableItemField,
  onDropdownOpen,
  onDropdownClose,
  unitOptions,
}) => {
  return (
    <div>
      <div className="product-schedule-app-item">
        <ProductMeteredServiceDropdown
          options={unitOptions}
          field="scheduleItemId"
          sheetId={sheetId}
          currentlySelected={currentlySelected}
          scheduleItemId={applicableItemField.scheduleItemId}
          itemUnitList={itemUnitList}
          onFocus={onDropdownOpen}
          onBlur={onDropdownClose}
        />
        <Form.Trigger
          field="scheduleItemId"
          event="onMatch"
          match={(formState) => {
            return formState.fields.otherInfo.value == "removeMe";
          }}
          action={(formState) => {
            formState.parentFields.applicableItems.splice(
              formState.arrayIndex,
              1
            );
          }}
        />
      </div>
    </div>
  );
};

export default ApplicableItemArray;
