import {
  isRealNumber,
  isFieldFixed,
} from "features/Sheets/ManageSheet/helpers";

export function updateNewPaperItem(paperSpendItem, sheetData) {
  if (!paperSpendItem) return;

  const paper = sheetData.defaults.papers.find(
    (x) => x.id == paperSpendItem.paperId
  );

  // Only update if we have paper selected, a matching paper record, the Price per Ream is blank and it's not fixed.
  if (
    !paperSpendItem.paperId ||
    !paper ||
    isRealNumber(paperSpendItem.pricePerReam.value) ||
    isFieldFixed(paperSpendItem.pricePerReam)
  ) {
    return;
  }

  paperSpendItem.pricePerReam.value = paper.defaultPrice;
}

export function bringPaperCostsInRange(sheetDataToUpdate, sheetData) {
  updateNewPaperItem(
    sheetDataToUpdate.paperInclusive.a3ContractedPaperSpend,
    sheetData
  );
  updateNewPaperItem(
    sheetDataToUpdate.paperInclusive.a4ContractedPaperSpend,
    sheetData
  );
}
