import React from "react";
import { useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import { SidePanel } from "@redriver/cinnamon";
import { usePermission } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import CalculationBreakdown from "./CalculationBreakdown";
import { getSheetDataState } from "features/Sheets";
import { LargeFormatSummary } from "../ScheduleStep";
import { useTranslation } from "react-i18next";
import SheetWarnings from "../Errors/SheetWarnings";

const CalculationBreakdownSidePanel = () => {
  const { t } = useTranslation("sheetHeader");
  const { id: sheetId } = useSelector(getSheetDataState);
  const hasPermission = usePermission(Targets.SheetAdmin, Actions.Edit);
  if (!sheetId || !hasPermission) return null;

  return (
    <SidePanel
      closeIcon
      header={t("Calculation_Breakdown")}
      trigger={
        <Button primary style={{ height: "32px" }} icon="calculator"></Button>
      }
    >
      <SheetWarnings calcOnly={true} />
      <CalculationBreakdown />
      <LargeFormatSummary />
    </SidePanel>
  );
};

export default CalculationBreakdownSidePanel;
