import React from "react";
import { useTranslation } from "react-i18next";
import { Format } from "@redriver/cinnamon";
import DataGrid from "./DataGrid";

const CoFundedCfaChargeGrid = ({ data }) => {
  const { t } = useTranslation("capitalCalc", {
    keyPrefix: "infoModal.funderTypes.coFundedCfaPayment",
  });

  const subHeaderKeys = [null, t("cfaPaymentHeader"), t("riskHeader")];

  return (
    <DataGrid
      className="cfa-charge-split"
      columnCount={3}
      minimumRows={4}
      isFirstRowHeader
      dataRows={[
        { className: "highlighted", values: subHeaderKeys },
        [
          t("supplierCfaPayment"),
          <Format.Number
            key={1}
            value={data.supplierCfaCharge}
            format={process.env.CURRENCY_CODE}
          />,
          <Format.Number
            key={2}
            value={data.supplierRisk}
            decimalPlaces={2}
            suffix="%"
          />,
        ],
        [
          t("funderCfaPayment"),
          <Format.Number
            key={3}
            value={data.funderCfaCharge}
            format={process.env.CURRENCY_CODE}
          />,
          <Format.Number
            key={4}
            value={data.funderRisk}
            decimalPlaces={2}
            suffix="%"
          />,
        ],
        {
          className: "bold",
          values: [
            t("totalCfaPayment"),
            <Format.Number
              key={5}
              value={data.totalCfaCharge}
              format={process.env.CURRENCY_CODE}
            />,
            <Format.Number
              key={6}
              value="{data.totalRisk}"
              decimalPlaces={2}
              suffix="%"
            />,
          ],
        },
      ]}
    />
  );
};

export default CoFundedCfaChargeGrid;
