import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { DealerOfficeOverview } from "features/DealerOffices";

const DealerPage = ({ match, dealerOfficeName }) => (
  <PageContent>
    <DealerOfficeOverview
      dealerOfficeId={match.params.id}
      dealerOfficeName={dealerOfficeName}
    />
  </PageContent>
);

export default DealerPage;
