import React from "react";
import moment from "moment-timezone";
import { ModalBuilder, Table } from "@redriver/cinnamon";
import RateGroupForm from "./RateGroupForm";
import { TranslatedModal } from "../../../components/modals";

const EditRateGroupModal = ({ submitAction, onSubmitted, rateGroup }) => (
  <ModalBuilder
    withForm
    initialData={{
      ...rateGroup,
      applicableFromDate: rateGroup.applicableFromUtc == null 
        ? null
        : moment
          .utc(rateGroup.applicableFromUtc)
          .tz(rateGroup.timezone)
          .format("YYYY-MM-DD")
    }}
    submitAction={submitAction}
    submitParams={{ id: rateGroup.id, timezone: rateGroup.timezone }}
    onSubmitted={onSubmitted}
    renderTrigger={(showModal) => (
      <Table.MenuItem onClick={showModal}>Edit</Table.MenuItem>
    )}
    renderModal={(modalProps, formProps) => (
      <TranslatedModal.Edit
        {...modalProps}
        size="small"
        header="Edit Rate Group"
        submitLabel="Update Rate Group"
      >
        <RateGroupForm formProps={formProps} />
      </TranslatedModal.Edit>
    )}
  />
);

export default EditRateGroupModal;
