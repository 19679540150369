import { ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import CurrentQuarterAgreed from "./CurrentQuarterAgreed";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { getSheetDataState } from "features/Sheets";
import { calculateForm } from "../actions";

const PredictedVolumeEntryModal = ({ disabled }) => {
  const { t } = useTranslation("sheetSummary");
  const dispatch = useDispatch();
  const { preventUpdates } = useSelector(getSheetDataState);

  return (
    <ModalBuilder
      renderTrigger={(showModal) => (
        <Button
          primary
          icon="clipboard list"
          content={t("Predicted_Volume_Entry")}
          onClick={showModal}
          disabled={disabled}
        />
      )}
      renderModal={(modalProps) => {
        return (
          <React.Fragment>
            <TranslatedModal
              {...modalProps}
              submitLabel={t("Save")}
              submitIcon="save"
              onUnmount={() => dispatch(calculateForm(false))}
              cancelDisabled={true}
              cancelLabel=""
              className="predicted-volume-entry-modal"
            >
              <div className="comparison-sheet-modal">
                <CurrentQuarterAgreed
                  predictedVolumeEntryDisabled={preventUpdates}
                />
              </div>
            </TranslatedModal>
          </React.Fragment>
        );
      }}
    />
  );
};

export default PredictedVolumeEntryModal;
