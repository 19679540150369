import React from "react";
import { useTranslation } from "react-i18next";
import { ActionButton, ModalBuilder } from "@redriver/cinnamon";
import { UserType } from "features/../../../shared/constants/enums";
import { deleteSchedule } from "../actions";
import { Targets, Actions } from "constants/permissions";
import {
  PermissionCheck,
  UserTypeCheck,
} from "features/../../../shared/components/auth";
import { TranslatedModal } from "features/../../../shared/components/modals";

const DeleteSchedule = ({ scheduleId, scheduleReference, onSubmitted }) => {
  const { t } = useTranslation("agreements");
  return (
    <div className="float-left spaced">
      <UserTypeCheck restrictTo={UserType.HQ}>
        <PermissionCheck target={Targets.Schedules} action={Actions.Delete}>
          <ModalBuilder
            submitAction={deleteSchedule}
            submitParams={{ id: scheduleId }}
            onSubmitted={onSubmitted}
            renderTrigger={(showModal) => (
              <ActionButton
                type="delete"
                onClick={showModal}
                className="action-button"
              />
            )}
            renderModal={(modalProps) => (
              <TranslatedModal.Delete
                {...modalProps}
                header={t("ScheduleRow.Actions.Delete_Header")}
                size="tiny"
              >
                {t("ScheduleRow.Actions.Delete_Confirm", {
                  ref: scheduleReference,
                })}
              </TranslatedModal.Delete>
            )}
          ></ModalBuilder>
        </PermissionCheck>
      </UserTypeCheck>
    </div>
  );
};

export default DeleteSchedule;
