import React from "react";
import { ModalBuilder, Form } from "@redriver/cinnamon";
import Remove from "features/../../../shared/assets/red-delete.svg";
import { IconButton } from "features/../../../shared/components/buttons";
import { canDeleteScheduleItem } from "features/Sheets/ManageSheet";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { withCurrentUser } from "features/../../../shared/components/auth";

const ScheduleItemDeleteModal = ({
  sheetId,
  scheduleItemId,
  isLargeFormat,
  nameOfRemoval,
  disabled,
  onSubmit,
  currentUser,
}) => {
  const { t } = useTranslation();
  return (
    <ModalBuilder
      loadAction={canDeleteScheduleItem}
      loadParams={{ sheetId, scheduleItemId, isLargeFormat }}
      onSubmit={onSubmit}
      renderTrigger={(showModal) => (
        <IconButton
          onClick={showModal}
          icon={Remove}
          disabled={disabled}
          className="icon-only-button"
        />
      )}
      renderModal={(modalProps, data) => {
        let canRemove = !!data.canDelete;

        return canRemove ? (
          <TranslatedModal.Confirmation
            {...modalProps}
            cancelLabel={t("systemSchedule:deleteModal:cancelButton")}
            submitDisabled={!canRemove}
            submitLabel={
              isLargeFormat ? (
                t("systemSchedule:deleteModal:confirmButton")
              ) : (
                <Form.ArrayRemover className="form-array schedule-item-delete-confirm-button">
                  {t("systemSchedule:deleteModal:confirmButton")}
                </Form.ArrayRemover>
              )
            }
            submitIcon={null}
          >
            {t("systemSchedule:deleteModal:label", { nameOfRemoval })}
            {currentUser.isInternalUser && (
              <p>
                <br />
                {t("systemSchedule:deleteModal:ipsAdminConfirmMsg")}
              </p>
            )}
          </TranslatedModal.Confirmation>
        ) : (
          <TranslatedModal.Information
            {...modalProps}
            onSubmit={modalProps.onCancel}
          >
            <React.Fragment>
              <p>
                {t("systemSchedule:deleteModal:itemCannotBeRemovedParagraph1")}
              </p>
              <p>
                {t("systemSchedule:deleteModal:itemCannotBeRemovedParagraph")}
              </p>
            </React.Fragment>
          </TranslatedModal.Information>
        );
      }}
    />
  );
};

export default withCurrentUser(ScheduleItemDeleteModal);
