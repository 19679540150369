import React from "react";
import { Table, useAuthClaims } from "@redriver/cinnamon";
import { withPermissions } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { UserArea } from "../../../constants/enums";

import EditRole from "./EditRole";
import DeleteRole from "./DeleteRole";

const RoleActions = ({ role, onRefresh, hasPermission }) => {
  const { userArea } = useAuthClaims();

  let canEdit, canDelete;

  if (role.type == UserArea.IpsAdmin || role.type == UserArea.IpsSuperUser) {
    canEdit =
      userArea == UserArea.IpsSuperUser &&
      hasPermission(Targets.RoleTemplate, Actions.UpdateIPSPermissions);
    canDelete =
      userArea == UserArea.IpsSuperUser &&
      hasPermission(Targets.RoleTemplate, Actions.UpdateIPSPermissions);
  } else {
    canEdit = hasPermission(Targets.RoleTemplate, Actions.Edit);
    canDelete = hasPermission(Targets.RoleTemplate, Actions.Delete);
  }

  if (!canEdit && !canDelete) return null;

  return (
    <Table.Menu openOnHover>
      {canEdit && (
        <EditRole role={role} onUpdated={onRefresh} as={Table.MenuItem}>
          Edit
        </EditRole>
      )}
      {canDelete && (
        <DeleteRole role={role} onDeleted={onRefresh} as={Table.MenuItem}>
          Delete
        </DeleteRole>
      )}
    </Table.Menu>
  );
};

export default withPermissions(RoleActions);
