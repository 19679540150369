import React from "react";
import { FormBuilder } from "@redriver/cinnamon";
import { getClient, ClientForm } from "./ListClients";
import EditClient from "./ListClients/EditClient";
import { withPermissions } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { useTranslation } from "react-i18next";

const ClientOverview = ({ clientId, clientName, hasPermission }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <h4>
        {clientName
          ? t("clients:headings:overviewForClientFormatted", {
              clientName: clientName,
            })
          : t("clients:headings:overviewForClientUnformatted")}
      </h4>
      <div className="content-container not-list client-overview">
        <FormBuilder
          loadAction={getClient}
          loadParams={clientId}
          renderForm={(formProps, state, events) => (
            <React.Fragment>
              <div className="client-form-container">
                <ClientForm
                  {...formProps}
                  disabled
                  readOnly
                  className="no-disable-box"
                  notCreate={true}
                />
              </div>
              {hasPermission(Targets.Client, Actions.Edit) && (
                <div className="edit-button-container">
                  <EditClient
                    clientId={clientId}
                    onSubmitted={events.onRefresh}
                  />
                </div>
              )}
            </React.Fragment>
          )}
        />
      </div>
    </React.Fragment>
  );
};

export default withPermissions(ClientOverview);
