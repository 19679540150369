import { produce } from "immer";
import { bringFinanceSelectionInRange } from "./BringFinanceSelectionInRange";
import { correctVolumeSelectionRelatedValues } from "./CorrectVolumeSelectionRelatedValues";
import { correctCapitalCalcValues } from "./CorrectCapitalCalcValues";
import { calculateTotals } from "../Calculation";
import { getWizardSplitTotals, isRealNumber, sumValues } from "../../helpers";
import {
  applyMinimumsToPriceAndVolume,
  safelySetFixedFieldMinimum,
} from "../FixedFields";
import { safelySetIncManagedVolume } from "./SafelySetIncManagedVolume";
import { UserType } from "../../../../../../../shared/constants/enums";
import { defaultServiceValuesIfEmpty } from "./DefaultServiceValuesIfEmpty";
import { correctQuarterlyServiceValues } from "../Services";
import { bringPaperCostsInRange } from "./BringPaperCostsInRange";
import { correctDeviceOnScheduleValues } from "./CorrectDeviceOnScheduleValues";

export const ensureSheetValuesAreValid = (state, action) => {
  const newSheetData2 = produce(state.sheetData, (draftSheetData) => {
    [
      draftSheetData.capital.funder,
      draftSheetData.capital.dealer,
      draftSheetData.capital.cofunded,
    ].forEach((x) => bringFinanceSelectionInRange(draftSheetData, x));

    // Capital calc
    correctVolumeSelectionRelatedValues(
      draftSheetData,
      draftSheetData.capital.funder,
      draftSheetData.capital.funderSelected,
      state,
      action.userType,
    );
    correctVolumeSelectionRelatedValues(
      draftSheetData,
      draftSheetData.capital.dealer,
      draftSheetData.capital.dealerFunderSelected,
      state,
      action.userType,
    );
    correctVolumeSelectionRelatedValues(
      draftSheetData,
      draftSheetData.capital.cofunded,
      draftSheetData.capital.cofundedSelected,
      state,
      action.userType,
    );
    correctDeviceOnScheduleValues(draftSheetData);

    correctCapitalCalcValues(
      draftSheetData,
      draftSheetData.capital.funder,
      state.sheetData.defaults.funderTypeSettings,
      {
        rrp: draftSheetData.defaults.funderDefaultRRPValue,
        productCapital: draftSheetData.defaults.funderDefaultProductCapital,
        otherCost: 0,
      },
    );
    correctCapitalCalcValues(
      draftSheetData,
      draftSheetData.capital.dealer,
      state.sheetData.defaults.funderTypeSettings,
      {
        rrp: draftSheetData.defaults.supplierDefaultRRPValue,
        productCapital: draftSheetData.defaults.supplierDefaultProductCapital,
      },
    );
    correctCapitalCalcValues(
      draftSheetData,
      draftSheetData.capital.cofunded,
      state.sheetData.defaults.funderTypeSettings,
      {
        rrp: draftSheetData.defaults.cofundedDefaultRRPValue,
        productCapital: draftSheetData.defaults.cofundedDefaultProductCapital,
      },
    );

    // Update Capital Totals after correcting values
    const { userType, userArea } = action;
    const cofundedTotals = draftSheetData.capital.cofundedSelected.value
      ? calculateTotals(draftSheetData.capital.cofunded)
      : getWizardSplitTotals();
    const dealerTotals = draftSheetData.capital.dealerFunderSelected.value
      ? calculateTotals(draftSheetData.capital.dealer)
      : getWizardSplitTotals();
    const funderTotals = draftSheetData.capital.funderSelected.value
      ? calculateTotals(draftSheetData.capital.funder)
      : getWizardSplitTotals();
    draftSheetData.capital.funderTotal = funderTotals.getTotal(
      userType,
      userArea,
    );
    draftSheetData.capital.cofundedTotal = cofundedTotals.getTotal(
      userType,
      userArea,
    );
    draftSheetData.capital.dealerTotal = dealerTotals.getTotal(
      userType,
      userArea,
    );

    //calculate capital settlement totals
    const capital = state.sheetData.capital;

    draftSheetData.capital.funder.settlementsTotal = sumValues(
      capital.funder.settlements,
    );
    draftSheetData.capital.funder.adminSettlementsTotal = sumValues(
      capital.funder.adminSettlements,
    );
    draftSheetData.capital.funder.ipSettlementsTotal = sumValues(
      capital.funder.ipSettlements,
    );

    draftSheetData.capital.dealer.settlementsTotal = sumValues(
      capital.dealer.settlements,
    );
    draftSheetData.capital.dealer.adminSettlementsTotal = sumValues(
      capital.dealer.adminSettlements,
    );
    draftSheetData.capital.dealer.ipSettlementsTotal = sumValues(
      capital.dealer.ipSettlements,
    );

    draftSheetData.capital.cofunded.settlementsTotal = sumValues(
      capital.cofunded.settlements,
    );
    draftSheetData.capital.cofunded.adminSettlementsTotal = sumValues(
      capital.cofunded.adminSettlements,
    );
    draftSheetData.capital.cofunded.ipSettlementsTotal = sumValues(
      capital.cofunded.ipSettlements,
    );

    const servicesDisableMinVolsLogic =
      draftSheetData.services.servicesDisableMinVolsLogic === 1;

    // Schedule items
    let scheduleItems = draftSheetData.systemScheduleItems;
    if (scheduleItems && scheduleItems.length >= 1) {
      scheduleItems.forEach((item) => {
        // Upper boundaries
        if (
          item.tier2UpperBoundaryPercentage.value &&
          item.tier1UpperBoundaryPercentage.value >
            item.tier2UpperBoundaryPercentage.value &&
          !item.tier1UpperBoundaryPercentage.isFixed
        ) {
          item.tier1UpperBoundaryPercentage.value =
            item.tier2UpperBoundaryPercentage.value;
        }

        // Tiered toner / Toner exclusive
        if (
          item.tonerExclusive.value &&
          item.tieredToner.value &&
          !item.tieredToner.isFixed
        ) {
          item.tieredToner.value = false;
        }

        // Cost per Click and Min Qtr minimums
        const unitData = action.unitData || [];
        let unitId = item.unit.value;
        if (unitId) {
          let matchedUnit = unitData.find((x) => x.id === unitId);
          if (matchedUnit) {
            if (action.userType == UserType.HQ) {
              applyMinimumsToPriceAndVolume(
                item.costPerClick,
                matchedUnit.vMinSalePrice,
              );
              applyMinimumsToPriceAndVolume(
                item.minQuarterly,
                matchedUnit.vMinVolume,
              );
            } else {
              applyMinimumsToPriceAndVolume(
                item.costPerClick,
                matchedUnit.minSalePrice,
              );
              applyMinimumsToPriceAndVolume(
                item.minQuarterly,
                matchedUnit.defaultMinVolume,
              );
            }

            safelySetFixedFieldMinimum(
              item.costPerClick,
              matchedUnit.defaultMinSalePrice,
            );
            safelySetFixedFieldMinimum(
              item.minQuarterly,
              matchedUnit.defaultMinVolume,
            );

            safelySetIncManagedVolume(
              item.includeManagedVol,
              matchedUnit.alwaysUnmanaged,
            );
          }
        }
      });
    }

    // large format vmin  checks
    let largeFormatItemsVMinChecking = draftSheetData.largeFormatGroups
      .map((i) =>
        [i.printerMeter, i.scanMeter, i.otherMeter].filter((x) => x != null),
      )
      .flat();

    largeFormatItemsVMinChecking.forEach((item) => {
      if (item != null) {
        const defaultSalePrice = item.defaultSalePrice;

        if (!isRealNumber(item.costPerClick?.value)) {
          safelySetFixedFieldMinimum(
            item.costPerClick,
            defaultSalePrice.toFixed(process.env.USE_MINOR_CURRENCY ? 3 : 5),
          );
        } else {
          if (userType == UserType.Dealer) {
            if (defaultSalePrice > item.costPerClick.value) {
              safelySetFixedFieldMinimum(
                item.costPerClick,
                defaultSalePrice.toFixed(
                  process.env.USE_MINOR_CURRENCY ? 3 : 5,
                ),
              );
            }
          } else if (item.vMinSalePrice > item.costPerClick.value) {
            safelySetFixedFieldMinimum(
              item.costPerClick,
              item.vMinSalePrice.toFixed(
                process.env.USE_MINOR_CURRENCY ? 3 : 5,
              ),
            );
          }
        }

        if (!isRealNumber(item.minQuarterlyVolume?.value)) {
          safelySetFixedFieldMinimum(
            item.minQuarterlyVolume,
            item.defaultVolume,
          );
        } else {
          const minVol =
            userType == UserType.Dealer ? item.defaultVolume : item.vMinVolume;
          if (minVol > item.minQuarterlyVolume.value) {
            safelySetFixedFieldMinimum(item.minQuarterlyVolume, minVol);
          }
        }
      }
    });

    //large format paper defaults.
    let largeFormatPaperItemsDefault = draftSheetData.largeFormatGroups
      .map((i) => [i.paperMeters].filter((x) => x != null))
      .flat();

    largeFormatPaperItemsDefault.forEach((papers) => {
      papers.forEach((item) => {
        if (item != null) {
          const defaultCostPerClick = item.defaultCostPerClick;
          if (!isRealNumber(item.costPerClick?.value)) {
            safelySetFixedFieldMinimum(item.costPerClick, defaultCostPerClick);
          } else {
            if (parseFloat(item.costPerClick.value) < defaultCostPerClick) {
              item.costPerClick.value = defaultCostPerClick;
            }
          }

          if (!isRealNumber(item.minQuarterlyVolume?.value)) {
            safelySetFixedFieldMinimum(
              item.minQuarterlyVolume,
              item.defaultVolume,
            );
          } else {
            const minVol =
              userType == UserType.Dealer
                ? item.defaultVolume
                : item.vMinVolume;
            if (minVol > item.minQuarterlyVolume.value) {
              safelySetFixedFieldMinimum(item.minQuarterlyVolume, minVol);
            }
          }
        }
      });
    });

    // Large format items
    let largeFormatItems = draftSheetData.largeFormatGroups
      .map((i) =>
        [i.paperMeter, i.printerMeter, i.scanMeter, i.otherMeter].filter(
          (x) => x != null,
        ),
      )
      .flat();

    if (largeFormatItems && largeFormatItems.length >= 1) {
      largeFormatItems.forEach((item) => {
        // Cost per Click and Min Qtr minimums
        const unitData = action.largeFormatUnitData || [];
        let unitId = item.largeFormatMeteredServiceId;
        if (unitId) {
          let matchedUnit = unitData.find((x) => x.id === unitId);
          if (matchedUnit) {
            safelySetIncManagedVolume(
              item.includeManagedVol,
              matchedUnit.alwaysUnmanaged,
            );
          }
        }
      });
    }

    //apply vmin and min values when adding/editing quarterly services on the sheet.
    const serviceGroup = draftSheetData.services;
    const serviceData = action.serviceData || [];
    const draftServiceGroup = draftSheetData.services;
    const draftIncomingService = draftServiceGroup.incomingService;

    [
      draftServiceGroup.services,
      draftServiceGroup.ipServices,
      draftServiceGroup.mandatoryServices,
      draftServiceGroup.adminServices,
    ].forEach((draftServicesArray) => {
      defaultServiceValuesIfEmpty(
        draftServicesArray,
        serviceData,
        servicesDisableMinVolsLogic,
      );
    });

    defaultServiceValuesIfEmpty(
      [draftIncomingService],
      serviceData,
      servicesDisableMinVolsLogic,
    );

    [
      serviceGroup.services,
      serviceGroup.ipServices,
      serviceGroup.mandatoryServices,
      serviceGroup.adminServices,
    ].forEach((servicesArray) => {
      correctQuarterlyServiceValues(servicesArray, serviceData, userType);
    });

    correctQuarterlyServiceValues(
      [serviceGroup.incomingService],
      serviceData,
      userType,
    );

    //Paper Inclusive
    bringPaperCostsInRange(draftSheetData, state.sheetData);
  });

  return {
    ...state,
    sheetData: {
      ...newSheetData2,
    },
  };
};
