import { Format } from "@redriver/cinnamon";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getCalculationData } from "features/Sheets";

const TotalCfaChargesInSchedule = ({ breakdown }) => {
  const { t } = useTranslation("capitalCalc", {
    keyPrefix: "infoModal.totalCfa",
  });
  const rawCalculation = useSelector(getCalculationData);

  if (!breakdown || !rawCalculation) return null;

  return (
    <div className="total-cfa-charges">
      <h4>{t("title")}</h4>

      <div className="charges-list">
        <ChargeRow
          label={t("newCfaCharge")}
          value={
            <Format.Number
              format={process.env.CURRENCY_CODE}
              value={breakdown.newFinanceCharge}
            />
          }
        />
        <ChargeRow
          label={t("existingCfaCharges")}
          value={
            <Format.Number
              format={process.env.CURRENCY_CODE}
              value={breakdown.coTerminusTotal}
            />
          }
        />
        <ChargeRow
          className="purple-highlight highlighted"
          label={t("totalCfaCharges")}
          value={
            <Format.Number
              format={process.env.CURRENCY_CODE}
              value={breakdown.totalCfaPayments}
            />
          }
        />
        <ChargeRow
          className="purple-highlight highlighted"
          label={t("totalFinanceCpc")}
          value={
            process.env.USE_MINOR_CURRENCY ? (
              <Format.Number
                value={breakdown.capitalCostPerClick}
                decimalPlaces={3}
                suffix={process.env.MINOR_CURRENCY}
              />
            ) : (
              <Format.Number
                value={breakdown.capitalCostPerClick}
                decimalPlaces={{ min: 0, max: 5 }}
                prefix={process.env.MAJOR_CURRENCY}
              />
            )
          }
        />
        <ChargeRow
          label={t("managedVolume")}
          value={
            <Format.Number
              decimalPlaces={0}
              value={breakdown.quarterlyVolumeReductionMinimum}
            />
          }
        />
        <ChargeRow
          label={t("totalScheduleFinanceCharge")}
          value={
            <Format.Number
              format={process.env.CURRENCY_CODE}
              value={breakdown.totalScheduleFinanceCharge}
            />
          }
        />
        <ChargeRow
          label={t("roundingPerQuarter")}
          value={
            <Format.Number
              format={process.env.CURRENCY_CODE}
              value={breakdown.quarterlyRounding}
            />
          }
        />
      </div>
    </div>
  );
};

const ChargeRow = ({ label, value, className }) => (
  <div className={classNames("charge-row", className)}>
    <div>{label}</div>
    <div>{value}</div>
  </div>
);

export default TotalCfaChargesInSchedule;
