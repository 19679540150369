import { FunderType } from "features/../../../shared/constants/enums";
import { shouldUseDefault } from "../../helpers";

export function correctCapitalCalcValues(
  newSheetData,
  funderType,
  funderTypeSettings,
) {
  const funderSettings = funderTypeSettings.find(
    (obj) => obj.type == funderType.funderType,
  );

  if (
    funderType.funderType == FunderType.CoFunded &&
    shouldUseDefault(funderType.coFundedDealerPercentage)
  ) {
    funderType.coFundedDealerPercentage.value = funderSettings.supplierCoFund;
  }
  if (
    funderType.funderType == FunderType.Dealer &&
    shouldUseDefault(funderType.ipSyndicatePercentageYield)
  ) {
    const defaultYieldPercentages =
      newSheetData.defaults.defaultYieldPercentages.find(
        (x) =>
          x.quarter == newSheetData.capital.dealer.financeSelection.value &&
          x.paymentMethod == newSheetData.rates.paymentMethod &&
          x.financeType == newSheetData.rates.dealerFmv.financeType,
      );
    funderType.ipSyndicatePercentageYield.value = defaultYieldPercentages
      ? defaultYieldPercentages.yieldPercentage
      : 0;
  }
}
