import React, { useState, useEffect } from "react";
import { Form, PageLoader } from "@redriver/cinnamon";
import { PermissionCheck } from "../../../components/auth";
import { Targets, Actions } from "constants/permissions";
import { IconButton } from "../../../components/buttons";
import { Grid, Button } from "semantic-ui-react";
import classNames from "classnames";
import Pen from "../../../assets/pen.svg";
import Save from "../../../assets/save.svg";
import DealerChargeColumn from "./DealerChargeColumn";
import { useTranslation } from "react-i18next";
import { navigateGridForm } from "features/../../../shared/components/forms/formHelper";

const Rows = {
  None: "",
  Sales: "Sales",
  Incentive: "Incentive",
  Admin: "Admin",
  Other: "Other",
  Total: "Total",
  IpSyndicate: "IpSyndicate",
  IpSyndicateVMinimum: "IpSyndicateVMinimum",
};

const FunderTypeMap = {
  funder: "Funder",
  dealer: "Supplier",
  cofunded: "Co-Funded",
};

const getRows = includeIpSyndicateCharge => {
  const rows = Object.values(Rows).filter(
    x => x !== Rows.IpSyndicate && x !== Rows.IpSyndicateVMinimum
  );
  if (includeIpSyndicateCharge) {
    rows.push(Rows.IpSyndicate);
    rows.push(Rows.IpSyndicateVMinimum);
  }
  return rows;
};

const areFormDataEqual = (oldFormData, newFormData) => {
  try {
    let result = true;
    Object.keys(oldFormData).forEach(key => {
      const oldValues = oldFormData[key];
      const newValues = newFormData[key];

      [
        "sales",
        "pound",
        "admin",
        "other",
        "ipSyndicate",
        "ipSyndicateVMinimum",
      ].forEach(field => {
        if (parseFloat(oldValues[field]) !== parseFloat(newValues[field])) {
          result = false;
        }
      });
    });

    return result;
  } catch {
    return false;
  }
};

const EditableLiquidityChargesTable = ({
  className,
  settingsId, // null if from ListRates page, has value if from Defaults page
  rateGroupId,
  title,
  reloadRatesOnFormChange = false,
  fullTitle,
  funderType,
  financeType,
  paymentMethod,
  loading,
  liquidityRecords,
  includeIpSyndicateCharge,
  setLiquidityChargesForm,
  updateLiquidityCharges,
  getRates,
  onCancel, // Loads by RateGroupId from ListRates Page, Loads by Settings Id for Defaults page
  getRatesState,
}) => {
  const { t } = useTranslation();
  const [readOnly, setReadOnly] = useState(true);
  const [formData, setFormData] = useState({});

  const onFormChange = (field, data, applyChanges) => {
    const newFormData = applyChanges(formData);
    setFormData(newFormData);
  };

  const calculateFormData = () => {
    getRates(getRatesState, mapFormDataToPostModel(formData));
  };

  const mapFormDataToPostModel = data => {
    let values = [];
    Object.entries(data).map(([key, value]) => {
      values.push({ id: key, ...value });
    });

    return { funderType, financeType, paymentMethod, values };
  };

  useEffect(() => {
    if (!liquidityRecords || !liquidityRecords.length) return;

    let initialFormData =
      Array.isArray(liquidityRecords) && liquidityRecords.length
        ? mapLiquidityChargesToForm(
            liquidityRecords.find(
              x =>
                x.funderType == funderType &&
                x.financeType == financeType &&
                x.paymentMethod == paymentMethod
            )
          )
        : {};

    setFormData(initialFormData);
    getRates(getRatesState, mapFormDataToPostModel(initialFormData));
  }, [liquidityRecords, funderType, financeType, paymentMethod]);

  return (
    <div
      className={classNames(
        "dealer-liquidity-section rates-section",
        className
      )}
    >
      <Header
        fullTitle={fullTitle}
        readOnly={readOnly}
        onToggleReadOnly={() => setReadOnly(!readOnly)}
        onSubmit={() =>
          updateLiquidityCharges(
            settingsId,
            mapFormDataToPostModel(formData),
            getRatesState,
            rateGroupId
          )
        }
        onCancel={onCancel}
        loading={loading}
        t={t}
      />
      <ChargeMatrix
        readOnly={readOnly}
        formData={formData}
        includeIpSyndicateCharge={includeIpSyndicateCharge}
        onFormChange={onFormChange}
        calculateFormData={calculateFormData}
        loading={loading}
        t={t}
      />
    </div>
  );
};

const Header = ({
  fullTitle,
  readOnly,
  onToggleReadOnly,
  onSubmit,
  onCancel,
  loading = false,
  t,
}) => (
  <div
    className={classNames(
      readOnly ? "title-container" : "title-container edit-mode"
    )}
  >
    <h4 className="title">
      {t("supplierOffices:liquidityChargesTable:title")}
    </h4>
    <p className="subtitle">{fullTitle}</p>
    <PermissionCheck target={Targets.Rates} action={Actions.Edit}>
      <div style={{ display: "flex" }}>
        {readOnly ? (
          <IconButton
            primary
            floated="right"
            content={t("shared:buttons:edit")}
            icon={Pen}
            onClick={onToggleReadOnly}
            disabled={loading}
          />
        ) : (
          <IconButton
            className="save-button"
            primary
            positive
            content={t("shared:buttons:save")}
            floated="right"
            icon={Save}
            onClick={async () => {
              const { success } = await onSubmit();
              if (success) {
                onToggleReadOnly();
              }
            }}
            disabled={loading}
          />
        )}
        {!readOnly && (
          <Button
            compact
            content={t("shared:buttons:cancel")}
            className="cancel"
            onClick={() => {
              onCancel();
              onToggleReadOnly();
            }}
          />
        )}
      </div>
    </PermissionCheck>
  </div>
);

const ChargeMatrix = ({
  readOnly,
  formData,
  liquidityCharge,
  onFormChange,
  includeIpSyndicateCharge,
  loading = false,
  calculateFormData,
}) => {
  const editableColumns = Object.entries(formData).length;

  const { t } = useTranslation("supplierOffices", {
    keyPrefix: "liquidityChargesTable",
  });
  return loading ? (
    <PageLoader />
  ) : (
    <Form
      value={formData}
      onChange={onFormChange}
      onKeyDown={e => {
        //enter key
        if (e.keyCode === 13) {
          calculateFormData();
        }
        else{
          navigateGridForm(e, editableColumns, 1);
        }
      }}
    >
      <Grid
        columns={7}
        className="dealer-liquidity-charge-table"
        onBlur={calculateFormData}
      >
        <Grid.Column width={4}>
          {getRows(includeIpSyndicateCharge).map((r, i) => (
            <Grid.Row
              key={r}
              className={classNames(
                r === Rows.IpSyndicate && "ip-syndicate",
                r === Rows.Total && "total",
                r === Rows.IpSyndicateVMinimum && "ips-v-minimum"
              )}
            >
              <p>{r !== "" ? t(r) : ""}</p>
            </Grid.Row>
          ))}
        </Grid.Column>

        {Object.entries(formData).map(data => {
          const [id, values] = data;
          return (
            <Form.Object field={id} key={id} propagateUpdates="always">
              <DealerChargeColumn
                column={values}
                title={values.thresholdName}
                readOnly={readOnly}
                includeIpSyndicateCharge={includeIpSyndicateCharge}
                calculateFormData={calculateFormData}
              />
            </Form.Object>
          );
        })}
      </Grid>
    </Form>
  );
};

export default EditableLiquidityChargesTable;

const mapLiquidityChargesToForm = liquidityRecords => {
  let formData = {};

  liquidityRecords?.values.forEach((lc, lcIndex) => {
    var item = {
      thresholdId: lc.thresholdId,
      thresholdName: lc.thresholdName,
      sales: lc.sales,
      pound: lc.pound,
      admin: lc.admin,
      other: lc.other,
      ipSyndicate: lc.ipSyndicate,
      ipSyndicateVMinimum: lc.ipSyndicateVMinimum,
    };
    formData[`${lc.id}`] = item;
  });

  return formData;
};
