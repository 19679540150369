import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { clearFailedLoginLockout } from "./actions";

const ClearFailedLoginLockout = ({
  as: As = Button,
  user,
  onReset,
  ...props
}) => {
  return (
    <ModalBuilder
      submitAction={clearFailedLoginLockout}
      submitParams={user.id}
      onSubmitted={onReset}
      renderTrigger={showModal => <As onClick={showModal} {...props} />}
      renderModal={modalProps => (
        <Modal.Confirmation {...modalProps} header="Clear Locked Out User">
          Are you sure you wish to clear {user.firstName} {user.lastName} login
          lockout status?
        </Modal.Confirmation>
      )}
    />
  );
};

export default ClearFailedLoginLockout;
