import { produce } from "immer";

export const updateComparisonData = (state) => {
  return produce(state, (draftState) => {
    draftState.sheetData.capital.oldVolumeSelection =
      draftState.sheetData.capital.volumeSelection.value;
    draftState.sheetData.createSnapshot = true;
    let scheduleItems = draftState.sheetData.systemScheduleItems;
    let largeFormatGroups = draftState.sheetData.largeFormatGroups;
    let paperInclusive = draftState.sheetData.paperInclusive;

    largeFormatGroups.forEach((lfItem) => {
      lfItem.printerMeter.oldMinQuarterly =
        lfItem.printerMeter.minQuarterlyVolume.value;
      lfItem.printerMeter.oldCostPerUnit = lfItem.printerMeter.costPerUnit;

      if (lfItem.scanMeter) {
        lfItem.scanMeter.oldMinQuarterly =
          lfItem.scanMeter.minQuarterlyVolume.value;
        lfItem.scanMeter.oldCostPerUnit = lfItem.scanMeter.costPerUnit;
      }

      if (lfItem.otherMeter) {
        lfItem.otherMeter.oldMinQuarterly =
          lfItem.otherMeter.minQuarterlyVolume.value;
        lfItem.otherMeter.oldCostPerUnit = lfItem.otherMeter.costPerUnit;
      }

      lfItem.paperMeters.forEach((paperItem) => {
        paperItem.oldMinQuarterly = paperItem.minQuarterlyVolume.value;
        paperItem.oldCostPerUnit = paperItem.costPerUnit;
      });
    });

    scheduleItems.forEach((item) => {
      item.oldMinQuarterly = item.minQuarterly.value;
      item.oldCostPerUnit = item.costPerUnit;
    });

    paperInclusive.minQuarterlySnapshotPayment =
      draftState.rawCalculation.breakdown.paperInclusiveCosts.minQuarterlyPayment;

    // set up the paper stuff here.
  });
};
