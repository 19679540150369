import { requestIsBusy } from "@redriver/cinnamon";
import { ActionTypes as ListLargeFormatActionTypes } from "features/../../../shared/features/Settings/LargeFormat/actions";
import { produce } from "immer";
import { isNil } from "lodash";
import { calculateComparisonSheetData } from "./ComparisonSheet/Utils";
import {
  initialSheetState as initialState,
  setCalculationPaused,
  updateProposedVolumes,
  updateSheetData,
} from "./ReducerHelpers";
import { calculateSheet } from "./ReducerHelpers/Calculation";
import { updateComparisonData } from "./ReducerHelpers/Data";
import { ensureSheetValuesAreValid } from "./ReducerHelpers/Data/EnsureSheetValuesAreValid";
import { fillLargeFormatCreate } from "./ReducerHelpers/Data/FillLargeFormatCreate";
import { loadSheetDetails } from "./ReducerHelpers/Data/LoadSheetDetails";
import { emptyCreateModal } from "./ScheduleStep/constants";
import { ActionTypes } from "./actions";
import { calculateServicesTotals } from "./helpers";

import * as mortar from "mortar/endpoints/sheets";

const getCalculationData = (sheetsState) => sheetsState.rawCalculation;

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UpdateSheetData:
      return updateSheetData(state, action);

    // If Finance Selection and Min Qtr are greater than volume selection then make them the same
    // Value, also addresses the values being too small and changing them accordingly
    // Creates new objects for fixed fields so that values update in FixedInput component. (do not just change value property)
    // Do not change min or max constraints on FixedInputs, or allow values to be outside of these.
    case ActionTypes.EnsureSheetValuesAreValid: {
      return ensureSheetValuesAreValid(state, action);
    }

    case ActionTypes.UpdateSheetSummary:
      return {
        ...state,
        summary: action.summary,
      };

    case ActionTypes.LoadSheetDetails: {
      return loadSheetDetails(state, action, initialState);
    }

    case ActionTypes.ClearSheetDetails:
      return initialState;

    case ActionTypes.SaveSheetData:
      if (requestIsBusy(action)) return { ...state, sheetDetailsSaving: true };
      return {
        ...state,
        sheetDetailsSaving: false,
        sheetData: {
          ...state.sheetData,
          isDirty: false,
        },
      };

    case ActionTypes.CalculateServicesTotal: {
      const servicesTotals = calculateServicesTotals(
        action.services,
        action.serviceData,
        parseFloat(action.totalDevicesOnSchedule),
      );

      return produce(state, (draftState) => {
        draftState.servicesContent = servicesTotals;
        draftState.sheetData.services.servicesTotal = servicesTotals.getTotal(
          action.userType,
          action.userArea,
        );
      });
    }

    case ActionTypes.UpdateProposedVolumes: {
      return updateProposedVolumes(action, state);
    }

    case ActionTypes.SetImpersonateUser: {
      if (!action.data) return state;

      return {
        ...state,
        sheetData: {
          ...state.sheetData,
          impersonatedUser: {
            ...action.data,
          },
        },
      };
    }

    case ActionTypes.DefaultImpersonatedUser: {
      return produce(state, (draftState) => {
        draftState.sheetData.impersonatedUser =
          initialState.sheetData.impersonatedUser;
      });
    }

    case ActionTypes.PauseCalculation: {
      return setCalculationPaused(state, true);
    }

    case ActionTypes.ResumeCalculation: {
      return setCalculationPaused(state, false);
    }

    case mortar.calculateSheet.toString(): {
      return calculateSheet(state, action, initialState);
    }

    case ActionTypes.CalculateComparisonData: {
      return produce(state, (draftState) => {
        const sd = draftState.sheetData;

        sd.comparisonData = calculateComparisonSheetData(
          sd,
          getCalculationData(draftState),
        );
      });
    }

    case ActionTypes.SidePanelVisibility: {
      return {
        ...state,
        actionsPanelOpen: !isNil(action.actions)
          ? action.actions
          : state.actionsPanelOpen,
        comparisonPanelOpen: !isNil(action.comparison)
          ? action.comparison
          : state.comparisonPanelOpen,
      };
    }

    case ActionTypes.SetSheetViewType: {
      return {
        ...state,
        sheetViewType: action.sheetViewType,
      };
    }

    case ListLargeFormatActionTypes.ClearLargeFormatCreateModal: {
      return {
        ...state,
        sheetData: {
          ...state.sheetData,
          largeFormatCreateModal: { ...emptyCreateModal },
        },
      };
    }

    case ActionTypes.UpdateComparisonData: {
      return updateComparisonData(state);
    }

    case ActionTypes.EndSnapshot: {
      const newState = produce(state, (draftState) => {
        draftState.sheetData.createSnapshot = false;
      });
      return newState;
    }

    case ActionTypes.RevertPaperInclusive: {
      const newState = produce(state, (draftState) => {
        draftState.sheetData.paperInclusive = action.paperInclusive;
      });
      return newState;
    }

    case ActionTypes.FillLargeFormatCreateModal: {
      return fillLargeFormatCreate(state, action);
    }

    case ActionTypes.SetRequiresRecalculation: {
      return produce(state, (draftState) => {
        draftState.requiresRecalculation = true;
      });
    }

    case ActionTypes.ResetRequiresRecalculation: {
      return produce(state, (draftState) => {
        draftState.requiresRecalculation = false;
      });
    }

    case ActionTypes.SetCrystallised: {
      return produce(state, (draft) => {
        draft.sheetData.isCrystallised = action.isCrystallised;
      });
    }

    default:
      return state;
  }
};
