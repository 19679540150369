import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "SETTINGS/YIELD_BANDS";

export const ActionTypes = {
  GetYieldBands: `${NAMESPACE}/GET`,
  SetYieldBands: `${NAMESPACE}/SET`,
};

export const getYieldBands = ({ rateGroupId }) => {
  if (!rateGroupId) return {};
  return apiGet(ActionTypes.GetYieldBands, `defaults/yield-bands`, {
    rateGroupId,
  });
};

export const setYieldBands = (
  bands,
  { rateGroupId, paymentMethod, financeType, funderType }
) => {
  return apiPut(ActionTypes.SetYieldBands, `defaults/yield-bands/`, {
    rateGroupId,
    paymentMethod,
    financeType,
    funderType,
    ...bands,
  });
};
