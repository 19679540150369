import { apiGet, apiPost, apiPut, apiDelete } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/LIST_ROLES";

const ActionTypes = {
  GetRoles: `${NAMESPACE}/GET_ROLES`,
  GetRole: `${NAMESPACE}/GET_ROLE`,
  AddRole: `${NAMESPACE}/ADD_ROLE`,
  UpdateRole: `${NAMESPACE}/UPDATE_ROLE`,
  DeleteRole: `${NAMESPACE}/DELETE_ROLE`,
};

export const getRoles = ({ filters, pagination }) =>
  apiGet(ActionTypes.GetRoles, `roles/template`, {
    ...filters,
    ...pagination,
  });

export const getRole = roleId =>
  apiGet(ActionTypes.GetRole, `roles/template/${roleId}`);

export const addRole = data =>
  apiPost(ActionTypes.AddRole, `roles/template`, data);

export const updateRole = (data, roleId) =>
  apiPut(ActionTypes.UpdateRole, `roles/template/${roleId}`, data); //update to templates

export const deleteRole = roleId =>
  apiDelete(ActionTypes.DeleteRole, `roles/template/${roleId}`);
