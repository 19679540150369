import React from "react";
import { Form } from "@redriver/cinnamon";
import ScheduleLargeFormatMeterArray from "./ScheduleLargeFormatMeterArray";
import { getSheetDataState } from "features/Sheets";
import { connect } from "react-redux";
import LargeFormatPaperMeterArray from "./LargeFormatPaperMeterArray";

const ScheduleLargeFormatGroupArray = ({
  hasAnyItems,
  setEditModalTrigger,
  setLargeFormatEditIndex,
}) => {
  return (
    <div
      className={`large-format-groups-container ${hasAnyItems ? "" : "empty"}`}
    >
      <Form.Array
        field="largeFormatGroups"
        arrayKey="id"
        propagateUpdates="always"
        fluid
      >
        {({ fields, arrayIndex }) => {
          return (
            <div className="large-format-group-container">
              {fields.printerMeter && (
                <ScheduleLargeFormatMeterArray
                  fieldName="printerMeter"
                  printerName={fields.printerName}
                  meterField={fields.printerMeter}
                  fields={fields}
                  arrayIndex={arrayIndex}
                  setEditModalTrigger={setEditModalTrigger}
                  setLargeFormatEditIndex={setLargeFormatEditIndex}
                />
              )}
              {fields.otherMeter && (
                <ScheduleLargeFormatMeterArray
                  fieldName="otherMeter"
                  printerName={fields.printerName}
                  meterField={fields.otherMeter}
                  fields={fields}
                  arrayIndex={arrayIndex}
                  setEditModalTrigger={setEditModalTrigger}
                  setLargeFormatEditIndex={setLargeFormatEditIndex}
                />
              )}
              {fields.scanMeter && (
                <ScheduleLargeFormatMeterArray
                  fieldName="scanMeter"
                  printerName={fields.printerName}
                  meterField={fields.scanMeter}
                  arrayIndex={arrayIndex}
                  setEditModalTrigger={setEditModalTrigger}
                  setLargeFormatEditIndex={setLargeFormatEditIndex}
                />
              )}
              {fields.paperMeters && (
                <LargeFormatPaperMeterArray
                  setEditModalTrigger={setEditModalTrigger}
                  setLargeFormatEditIndex={setLargeFormatEditIndex}
                  printerName={fields.printerName}
                  groupArrayIndex={arrayIndex}
                />
              )}
            </div>
          );
        }}
      </Form.Array>
    </div>
  );
};

const mapStateToProp = (state) => {
  const { impersonatedUser } = getSheetDataState(state);
  return {
    impersonatedUser,
  };
};

export default connect(mapStateToProp)(ScheduleLargeFormatGroupArray);
