import React from "react";
import { Form, useFormField } from "@redriver/cinnamon";
import { connect } from "react-redux";
import { getSheetRatesState, getSheetDataState } from "features/Sheets";
import LargeFormatMeterItem from "./LargeFormatMeterItem";

const ScheduleLargeFormatMeterArray = ({
  fieldName,
  contractType,
  meterField,
  arrayIndex,
  printerName,
  setEditModalTrigger,
  setLargeFormatEditIndex,
}) => {
  const [__, setMeterField] = useFormField(fieldName);
  return (
    <Form.Object field={fieldName} propagateUpdates="always">
      <LargeFormatMeterItem
        meterField={meterField}
        contractType={contractType}
        arrayIndex={arrayIndex}
        unitInfo={meterField.largeFormatMeteredServiceName}
        printerName={printerName}
        setEditModalTrigger={setEditModalTrigger}
        setLargeFormatEditIndex={setLargeFormatEditIndex}
        setMeterField={setMeterField}
        disableCpiWarningMessages={false}
      />
    </Form.Object>
  );
};

const mapStateToProps = (state) => {
  const { allowedTieredToner } = getSheetRatesState(state);
  const { impersonatedUser } = getSheetDataState(state);
  return { allowedTieredToner, impersonatedUser };
};

export default connect(mapStateToProps)(ScheduleLargeFormatMeterArray);
