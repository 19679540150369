import React from "react";
import { useTranslation } from "react-i18next";
import { Format } from "@redriver/cinnamon";
import DataGrid from "./DataGrid";
import { FunderType } from "features/../../../shared/constants/enums";
import classNames from "classnames";

const CapitalInvestmentGrid = ({ data, funderType }) => {
  const { t } = useTranslation("capitalCalc", {
    keyPrefix: "infoModal.funderTypes.capitalInvestment",
  });

  let titleKey = "";
  switch (funderType) {
    case FunderType.Funder:
      titleKey = "funderTitle";
      break;
    case FunderType.Dealer:
      titleKey = "supplierTitle";
      break;
    case FunderType.CoFunded:
      titleKey = "cofundedTitle";
      break;
  }

  return (
    <DataGrid
      className={classNames("capital-investment", funderType?.toLowerCase())}
      headerText={t(titleKey)}
      minimumRows={6}
      dataRows={[
        [
          t("productCapitalCosts"),
          <Format.Number
            key={1}
            value={data.productCapitalCosts}
            format={process.env.CURRENCY_CODE}
            decimalPlaces={2}
          />,
        ],
        [
          t("otherCosts"),
          <Format.Number
            key={2}
            value={data.otherCosts}
            format={process.env.CURRENCY_CODE}
            decimalPlaces={2}
          />,
        ],
      ]}
      lastRow={[
        t(titleKey),
        <Format.Number
          key={3}
          value={data.totalEquipmentValue}
          format={process.env.CURRENCY_CODE}
          decimalPlaces={2}
        />,
      ]}
    />
  );
};

export default CapitalInvestmentGrid;
