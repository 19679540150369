const NAMESPACE = "LARGE_FORMAT";

export const ActionTypes = {
  GetLargeFormatPrinterConfig: `${NAMESPACE}/GET_PRINTER_CONFIG`,
  GetLargeFormatInkInclusive: `${NAMESPACE}/GET_INK_INCLUSIVE`,
  GetLargeFormatPrinterInclusive: `${NAMESPACE}/GET_PAPER_INCLUSIVE`,
  GetRollMediaName: `${NAMESPACE}/GET_ROLL_MEDIA_NAME`,
  ClearLargeFormatCreateModal: `${NAMESPACE}/CLEAR_LF_CREATE_MODAL`,
};

export const clearLargeFormatCreateModal = () => ({
  type: ActionTypes.ClearLargeFormatCreateModal,
});
