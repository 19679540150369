export const SheetViewType = {
  System: 'System',
  IpsAdmin: "IpsAdmin",
  SupplierAdmin: "SupplierAdmin",
  AccountManager: "AccountManager",
  Customer: "Customer",
  IpsSuperUser: "IpsSuperUser"
};

export const SheetViewTypeDescription = {
  [SheetViewType.System]: 'System',
  [SheetViewType.IpsAdmin]: 'IPS Admin',
  [SheetViewType.SupplierAdmin]: 'Supplier Administrator',
  [SheetViewType.AccountManager]: 'Supplier Account Manager',
  [SheetViewType.Customer]: 'Customer',
  [SheetViewType.IpsSuperUser]: 'IPS Super User'
};
