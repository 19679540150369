import React from "react";
import { Format } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";

const getPageMin = (pageSize, pageNumber) =>
  pageSize * pageNumber - (pageSize - 1);

const getPageMax = (pageSize, pageNumber, total) => {
  const pageTotal = pageSize * pageNumber;
  return total < pageTotal ? total : pageTotal;
};

const ResultsCounter = ({ pageSize, pageNumber, totalItems }) => {
  const { t } = useTranslation("system");
  return totalItems ? (
    <label className="results-counter">
      <React.Fragment>
        {t("Showing")}{" "}
        <Format.Number value={getPageMin(pageSize, pageNumber)} /> -{" "}
        <Format.Number value={getPageMax(pageSize, pageNumber, totalItems)} />{" "}
        {t("of")} <Format.Number value={totalItems} />{" "}
        {t("results", { count: totalItems })}
      </React.Fragment>
    </label>
  ) : (
    ""
  );
};

export default ResultsCounter;
