import React from "react";
import { ListBuilder, Table, ActionBar } from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import { withRouter } from "react-router-dom";
import DealerOfficeFilterDisplay from "./DealerOfficeFilterDisplay";
import { getDealerOffices } from "./actions";
import SearchDealerOffices from "./SearchDealerOffices";
import DealerOfficeFilters from "./DealerOfficeFilters";
import DealerOfficeActions from "./DealerOfficeActions";
import { useTranslation } from "react-i18next";

const ListDealerOffices = () => {
  const { t } = useTranslation("supplierOffices", { keyPrefix: "listPage" });
  return (
    <React.Fragment>
      <h4>{t("supplierOffices")}</h4>
      <ListBuilder
        withTable
        initialPagination={{ pageSize: 25, pageNumber: 1 }}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        loadAction={getDealerOffices}
        renderList={(tableProps, state, events) => (
          <div className="content-container principal-list">
            <ActionBar>
              <ActionBar.Item fluid className="flex">
                <SearchDealerOffices
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
                <div className="search-filter-display">
                  <DealerOfficeFilterDisplay
                    filters={state.filters}
                    onChange={events.onChangeFilters}
                  />
                </div>
              </ActionBar.Item>
              <ActionBar.Item align="right">
                <DealerOfficeFilters
                  title={t("filters")}
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
              </ActionBar.Item>
            </ActionBar>
            <Table
              {...tableProps}
              dataKey="id"
              emptyMessage="No supplier offices found"
              selectable
            >
              <Table.Column field="name" title={t("office")} width="17%" />
              <Table.Column field="dealer" title={t("supplier")} width="10%" />
              <Table.Column
                title={t("address")}
                width="24%"
                render={(row) => {
                  if (!row.address) return;
                  const address = [
                    row.address.line1,
                    row.address.line2,
                    row.address.town,
                    row.address.postcode,
                  ];
                  return address.filter((x) => !!x).join(", ");
                }}
              />
              <Table.Column
                field="phone"
                title={t("contactInfo")}
                width="10%"
              />
              <Table.Column field="email" width="14%" />
              <Table.Column
                hoverable
                width="15%"
                align="right"
                render={(dealerOffice) => (
                  <DealerOfficeActions
                    dealerOffice={dealerOffice}
                    onRefresh={events.onRefresh}
                  />
                )}
              />
            </Table>
            <TablePagination
              pageNumber={state.pagination.pageNumber}
              onChange={events.onChangePagination}
              pageSize={state.pagination.pageSize}
              totalItems={state.total}
            />
          </div>
        )}
      />
    </React.Fragment>
  );
};

export default withRouter(ListDealerOffices);
