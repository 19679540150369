import React from "react";
import { Table, Form, ModalBuilder } from "@redriver/cinnamon";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { renameSheet } from "features/Sheets/ManageSheet";
import { useTranslation } from "react-i18next";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";

const RenameSheet = ({ sheet, onSubmitted }) => {
  const { t } = useTranslation("agreements");
  let { sheetId, sheetName } = sheet;

  return (
    <PermissionCheck action={Actions.Edit} target={Targets.Sheet}>
      <ModalBuilder
        withForm
        initialData={{ newName: sheetName }}
        submitAction={renameSheet}
        submitParams={{ sheetId }}
        onSubmitted={onSubmitted}
        renderError={false}
        renderSubmitting={false}
        renderTrigger={(trigger) => (
          <Table.MenuItem onClick={trigger}>
            {t("SheetActions.Rename.TriggerText")}
          </Table.MenuItem>
        )}
        renderModal={(modalProps, formProps) => (
          <TranslatedModal.Edit
            {...modalProps}
            header={t("SheetActions.Rename.HeaderText")}
          >
            <Form {...formProps}>
              <div className="flex">
                <Form.Input
                  field="newName"
                  label={t("SheetActions.Rename.New_name")}
                  width={25}
                />
              </div>
            </Form>
          </TranslatedModal.Edit>
        )}
      />
    </PermissionCheck>
  );
};

export default RenameSheet;
