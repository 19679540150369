import React, { useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import {
  SidePanel,
  Format,
  ListBuilder,
  Table,
  getAuthClaim,
} from "@redriver/cinnamon";
import { Button, Icon } from "semantic-ui-react";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { getSheetState, getSheetDataState } from "../../selectors";
import { UserArea, UserType } from "features/../../../shared/constants/enums";
import AddNote from "./AddNote";
import DeleteNote from "./DeleteNote";
import { getNotes } from "../actions";
import EditNote from "./EditNote";
import { useTranslation } from "react-i18next";

const NoteThumbnail = ({ avatarId }) =>
  avatarId ? (
    <div
      className="note-profile"
      style={{
        backgroundImage: `url('${
          process.env.STORAGE_CONTAINER_URL + avatarId
        }')`,
        marginTop: "15px",
        marginRight: "20px",
      }}
    ></div>
  ) : (
    <Icon
      className="account-icon"
      name="user circle"
      style={{
        fontSize: "2.5rem",
        marginTop: "15px",
        marginRight: "20px",
      }}
    />
  );

const NotesSidePanel = ({
  title,
  sheetId,
  userType,
  userArea,
  preventUpdates,
}) => {
  const { t } = useTranslation("sheetHeader");
  const [areaFilter, setAreaFilter] = useState(UserArea.All);

  const renderNotes = (notes, state, events) => {
    const filteredNotes =
      areaFilter !== UserArea.All
        ? notes.filter((n) => n.userArea === areaFilter)
        : notes;
    const userIsHQorAdmin =
      userType == UserType.HQ || userArea == UserArea.IpsAdmin;

    const renderTabItem = (userArea) => {
      const count = notes.filter((n) => n.userArea === userArea).length;
      const allSelected = userArea === UserArea.All;
      const disabled = !allSelected && !count;
      const isDealerItem = userArea == UserArea.SupplierAdmin;

      return (
        <div
          key={userArea}
          className={classNames(
            "tab-menu-item",
            areaFilter == userArea && "active",
            disabled && "disabled"
          )}
          onClick={disabled ? null : () => setAreaFilter(userArea)}
        >
          {isDealerItem && !userIsHQorAdmin
            ? t(`enums:UserAreaDescriptions:${UserArea.SupplierAdmin}`)
            : t(`enums:UserAreaDescriptions:${userArea}`)}
          {count && !allSelected ? ` (${count})` : ""}
        </div>
      );
    };

    const renderNoteItem = (n) => (
      <div className={classNames("note-item", userAreaClassName(n.userArea))}>
        <div className="body">
          <p className="text">{n.noteText}</p>
          <PermissionCheck target={Targets.Sheet} action={Actions.Edit}>
            {n.canDelete && (
              <Table.Menu>
                <EditNote
                  sheetId={sheetId}
                  noteId={n.id}
                  onSubmitted={events.onRefresh}
                  noteText={n.noteText}
                  noteUserArea={n.userArea}
                  userArea={userArea}
                />
                <DeleteNote
                  sheetId={sheetId}
                  noteId={n.id}
                  onSubmitted={events.onRefresh}
                />
              </Table.Menu>
            )}
          </PermissionCheck>
        </div>

        <div className="footer">
          <span>
            <Format.DateTime value={n.createdUtc} format="hh:mmA DD MMM YYYY" />
          </span>
          <div className="inline-divider"></div>
          <span>{n.createdBy}</span>
          {n.userArea && (
            <React.Fragment>
              <div className="inline-divider"></div>
              <span>{t(`enums:UserAreaDescriptions:${n.userArea}`)}</span>
            </React.Fragment>
          )}
        </div>
      </div>
    );

    return (
      <div className="sheet-notes">
        <div className="add-note-container">
          <AddNote
            sheetId={sheetId}
            onSubmitted={events.onRefresh}
            userArea={userArea}
            disabled={preventUpdates}
          />
        </div>
        {userArea != UserArea.Funder && userArea != UserArea.AccountManager && (
          <div className="tab-menu">
            {Object.keys(UserArea)
              .filter((x) =>
                x == UserArea.IpsAdmin
                  ? userIsHQorAdmin
                  : true && x == UserArea.Funder
                  ? userIsHQorAdmin
                  : true
              )
              .map((k) => renderTabItem(k))}
          </div>
        )}
        {filteredNotes.map((n, i) => (
          <div className="note-container" key={i}>
            <NoteThumbnail avatarId={n.createdByAvatarId} />
            {renderNoteItem(n)}
          </div>
        ))}
      </div>
    );
  };

  return (
    <ListBuilder
      loadAction={getNotes}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      initialPagination={{ pageSize: 10000, pageNumber: 1 }}
      loadParams={sheetId}
      renderList={(notes, state, events) => {
        const notesCount = notes.length;

        return (
          <SidePanel
            className="notes-side-panel"
            closeIcon
            header={title}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            trigger={
              <Button
                primary
                style={{ height: "32px" }}
                className={classNames(notesCount && "notes-to-view")}
              >
                <span className="notes-trigger-word">
                  {t("Notes")}
                  {notesCount ? ` (${notesCount})` : ""}
                </span>
              </Button>
            }
          >
            {renderNotes(notes, state, events)}
          </SidePanel>
        );
      }}
    />
  );
};

const userAreaClassName = (userArea) => {
  return userArea == UserArea.Funder
    ? "funder"
    : userArea == UserArea.SupplierAdmin
    ? "supplier"
    : userArea == UserArea.AccountManager
    ? "accountManager"
    : userArea == UserArea.IpsAdmin
    ? "ipsAdmin"
    : "";
};

const mapStateToProps = (state) => {
  const sheetState = getSheetState(state);
  const sheetData = getSheetDataState(state);
  return {
    notes: sheetData.notes || [],
    open: !!sheetState.notesPanelOpen,
    userType: getAuthClaim(state, "userType"),
    userArea: getAuthClaim(state, "userArea"),
    preventUpdates: sheetData.preventUpdates,
  };
};

export default connect(mapStateToProps)(NotesSidePanel);
