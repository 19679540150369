import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder } from "@redriver/cinnamon";
import { getDealerOffice, editDealerOffice } from "./actions";
import DealerOfficeForm from "./DealerOfficeForm";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { useTranslation } from "react-i18next";

const EditDealerOffice = ({ dealerOfficeId, onUpdate }) => {
  const { t } = useTranslation("supplierOffices", { keyPrefix: "editModal" });
  return (
    <ModalBuilder
      withForm
      loadAction={getDealerOffice}
      loadParams={dealerOfficeId}
      submitAction={editDealerOffice}
      submitParams={dealerOfficeId}
      onSubmitted={onUpdate}
      renderTrigger={(showModal) => (
        <Button icon="edit" onClick={showModal} className="edit-button" />
      )}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Edit
          {...modalProps}
          header={t("header")}
          submitLabel={t("submitLabel")}
        >
          <DealerOfficeForm {...formProps} />
        </TranslatedModal.Edit>
      )}
    />
  );
};

export default EditDealerOffice;
