import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getIsInMaintenancePeriod } from "../../features/System/actions";
import { apiGet } from "@redriver/cinnamon";

const MaintenanceBanner = ({ message, pollSeconds }) => {

    const [inMaintenancePeriod, setInMaintenancePeriod] = useState(false);
    const [intervalId, setIntervalId] = useState(null);
    const dispatch = useDispatch();

    const updateMaintenancePeriod = async () => {
        const { success, response } = await dispatch(getIsInMaintenancePeriod());        
        if(!success) return;

        setInMaintenancePeriod(response.isInMaintenancePeriod);                 
      };

    useEffect(() => {
        updateMaintenancePeriod();

        setIntervalId(
            setInterval(
                updateMaintenancePeriod,
                pollSeconds * 1_000
        ));

        return () => clearInterval(intervalId);
    }, []);

    if(!inMaintenancePeriod) return null;

    return (
    <div className="maintenance-banner">
        {message}
    </div>);    
}

export default MaintenanceBanner;