import React, { useState, useEffect, useRef, useMemo } from "react";
import { Input as SInput, Label, Icon } from "semantic-ui-react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { produce } from "immer";
import { Field, connectForm, FormUtils } from "@redriver/cinnamon";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { determineFixType } from "./utils";
import maxValueValidator from "./maxValueValidator";
import minValueValidator from "./minValueValidator";
import rangeLengthValidator from "./rangeValidator";
import FixedInputIndicator from "./FixedInputIndicator";
import {
  withPermissions,
  withCurrentUser,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import FixedFieldType from "constants/forms/FixedFieldType";
import { isRealNumber } from "features/Sheets/ManageSheet";
import { UserArea } from "features/../../../shared/constants/enums";

export const FixedInputField = {
  Id: "id",
  Value: "value",
  MinimumValue: "minimumValue",
  MaximumValue: "maximumValue",
  SetValue: "setValue",
  InitialLoadValue: "initialLoadValue",
  UserArea: "userArea",
};

/**
 * Custom Cinnamon component that provides an interface for the FixedFieldView object
 */
const FixedInput = ({
  label = "",
  actions,
  inline = false,
  width,
  fluid,
  renderReadOnly,
  required,
  showErrors,
  allErrors,
  animateErrors,
  disabled,
  readOnly,
  value = {
    id: "",
    value: "",
    minimumValue: null,
    maximumValue: null,
    isFixed: false,
    initialLoadValue: null,
  },
  errors,
  passThruProps,
  maxAllowValue,
  minAllowValue,
  fixedValueOnly = false,
  formatNumber = false,
  validateValue = true,
  currency = false,
  decimalPlaces = 2,
  minorCurrency = false,
  selectOnFocus = true,
  editorPopupClass = "",
  displayPriority = 0,
  hideIndicator = false,
  percentage = false,
  hasPermission,
  currentUser,
  onChange,
  id,
  disableWarning = false,
  defaultValue = null,
}) => {
  const warningTimeoutMs = 10_000;
  const { t } = useTranslation("sheetTranslation", {
    keyPrefix: "FixedInput.InputEditor",
  });
  const [fixType, setFixType] = useState("None");
  const [formatted, setFormatted] = useState("");
  const [showFormatted, setShowFormatted] = useState(true);
  const [displayWarning, setDisplayWarning] = useState(false);
  const [applyFormattingOnChange, setApplyFormattingOnChange] = useState(true);
  const [hideInitialLoadValueWarning, setHideInitialLoadValueWarning] =
    useState(false);

  const getUpperLimit = (maxAllowValue, value) => {
    const maxAllowValueIsNumber = isRealNumber(maxAllowValue);
    const maximumIsNumber = isRealNumber(value.maximumValue);

    if (maxAllowValueIsNumber && maximumIsNumber) {
      return Math.max(maxAllowValue, value.maximumValue);
    }

    if (!maxAllowValueIsNumber && !maximumIsNumber) {
      return null;
    }

    return maxAllowValueIsNumber ? maxAllowValue : value.maximumValue;
  };
  const getLowerLimit = (minAllowValue, value) => {
    const minAllowValueIsNumber = isRealNumber(minAllowValue);
    const minimumIsNumber = isRealNumber(value.minimumValue);

    if (minAllowValueIsNumber && minimumIsNumber)
      return Math.min(minAllowValue, value.minimumValue);
    if (!minAllowValueIsNumber && !minimumIsNumber) return null;

    return minAllowValueIsNumber ? minAllowValue : value.minimumValue;
  };

  const lowerLimit = useMemo(
    () => getLowerLimit(minAllowValue, value),
    [minAllowValue, value],
  );
  const upperLimit = useMemo(
    () => getUpperLimit(maxAllowValue, value),
    [maxAllowValue, value],
  );

  //use ref to focus the field after fixing or unfixing
  const inputRef = useRef(null);

  const handleUserKeyPress = () => {
    setApplyFormattingOnChange(false);
  };

  const semanticProps = FormUtils.omitProps(
    passThruProps,
    Object.keys(FixedInput.propTypes),
  );

  useEffect(() => {
    setFixType(
      determineFixType(value.minimumValue, value.maximumValue, value.isFixed),
    );
    setFormatted(formatValue(value));

    let initialLoadTimer = setTimeout(
      () => setHideInitialLoadValueWarning(true),
      warningTimeoutMs,
    );

    return () => clearTimeout(initialLoadTimer);
  }, [formatValue, value]);

  useEffect(() => {
    if (applyFormattingOnChange) {
      setFormatted(formatValue(value, false));
    }

    setApplyFormattingOnChange(true);
    setFixType(
      determineFixType(value.minimumValue, value.maximumValue, value.isFixed),
    );
    if (applyFormattingOnChange) {
      setFormatted(formatValue(value, false));
    }
  }, [value, applyFormattingOnChange, formatValue]);

  useEffect(() => {
    setFixType(
      determineFixType(value.minimumValue, value.maximumValue, value.isFixed),
    );
    setFormatted(formatValue(value));
  }, [onChange, formatValue, value]);

  const showWarning = () => {
    setDisplayWarning(true);
    setTimeout(function () {
      setDisplayWarning(false);
    }, warningTimeoutMs);
  };
  const hideWarning = () => setDisplayWarning(false);

  const enforceValueConstraints = (newValue, values, isUserInitiated) => {
    const { minimumValue, maximumValue, isFixed, value } = values;
    const fixTypeToUse = determineFixType(minimumValue, maximumValue, isFixed);
    if (fixTypeToUse === FixedFieldType.Range) {
      if (minimumValue <= newValue && newValue <= maximumValue) {
        if (isUserInitiated) {
          hideWarning();
        }
        return newValue;
      }
      if (newValue < minimumValue) {
        showWarning();
        return limitValue(minimumValue);
      }
      if (newValue > maximumValue) {
        showWarning();
        return limitValue(maximumValue);
      }
    }

    if (fixTypeToUse === FixedFieldType.Max) {
      if (!isRealNumber(newValue)) return null;
      if (newValue <= maximumValue) {
        hideWarning();
        return newValue;
      }
      showWarning();
      return limitValue(maximumValue);
    }

    if (fixTypeToUse === FixedFieldType.Min) {
      if (newValue >= minimumValue) {
        hideWarning();
        return newValue;
      }
      showWarning();
      return limitValue(minimumValue);
    }

    if (fixTypeToUse === FixedFieldType.Set) {
      hideWarning();
      return value;
    }

    if (!isRealNumber(newValue)) return null;
    return limitValue(newValue);
  };

  /**
   * Create the value to be stored in local state - thousands-sep'd
   * @param value - the value to format
   * @param fixType - the type of constraint the field uses; for `validateValue`
   * @returns the formatted value to store in local state
   */
  const formatValue = (val) => {
    if (Number.isNaN(parseFloat(val.value)) || value === "") {
      return "";
    }
    let num = Number(val.value);

    if (validateValue) {
      num = enforceValueConstraints(num, val, false);
    }
    if (formatNumber) {
      // src: https://gist.github.com/fjaguero/6932045
      // src: https://stackoverflow.com/a/29773435
      // restrict maximum
      if (decimalPlaces >= 0) {
        if (currency) {
          const currencyValue = num.toLocaleString(undefined, {
            useGrouping: true,
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
          });
          return minorCurrency
            ? currencyValue + process.env.MINOR_CURRENCY
            : currencyValue;
        }
        const value = num.toLocaleString(undefined, {
          useGrouping: true,
          maximumFractionDigits: decimalPlaces,
        });
        return percentage ? value + "%" : value;
      }

      const value = num.toLocaleString();
      return percentage ? value + "%" : value;
    }

    return percentage ? num + "%" : num;
  };

  /**
   * Validate numeric input to strip decimal places if required; remove non-numeric symbols
   *
   * @param incoming - the value to format
   * @returns the formatted value
   * @see https://github.com/RedRiverSoftware/Cinnamon/blob/master/packages/cinnamon/src/components/forms/fields/Numeric.js
   */
  const strip = (incoming) => {
    if (incoming == null || incoming == "") return "";
    //eslint-disable-next-line
    let value = incoming.replace(/[^-\.\d]/g, "");

    // only allow single decimal point
    //eslint-disable-next-line
    value = value.replace(/\./g, "|").replace("|", ".").replace(/\|/g, "");

    // only allow single minus
    value = value.substring(0, 1) + value.substring(1).replace(/-/g, "");

    // limit decimal places
    if (decimalPlaces === 0) {
      return value.replace(".", "");
    }
    if (decimalPlaces > 0) {
      const pattern = `(\\.\\d{${decimalPlaces}})\\d*`;
      const regex = new RegExp(pattern);
      return value.replace(regex, "$1");
    }
    return value;
  };

  const limitValue = (incoming) => {
    if (typeof maxAllowValue === "number") {
      if (incoming > upperLimit) {
        return upperLimit;
      }
    }
    if (typeof minAllowValue === "number") {
      if (incoming < lowerLimit) {
        return minAllowValue;
      }
    }
    return incoming;
  };

  /**
   * The onChange method for the `<SInput />` element of the FixedInput
   */
  const onValueChange = (e) => {
    onChange({
      ...value,
      value: strip(e.target.value ?? 0),
    });
  };

  /**
   * The onChange method for the `<FixedInputEditor />` element of the FixedInput
   */
  const onValuePropsChange = (res, minorCurrency) => {
    setApplyFormattingOnChange(true);

    const newValue = {
      ...value,
      ...res,
    };

    if (minorCurrency) {
      if (newValue.maximumValue) newValue.maximumValue *= 100;
      if (newValue.minimumValue) newValue.minimumValue *= 100;
      if (newValue.value) newValue.value *= 100;
    }

    const valueToSet = {
      ...newValue,
      value: enforceValueConstraints(
        parseFloat(newValue.value),
        newValue,
        false,
      ),
    };

    onChange(valueToSet);

    // Focus after fixing or unfixing, we need to wait until the field becomes editable again.
    setTimeout(() => inputRef.current.focus(), 100);
  };

  const onFocus = ({ target }) => {
    setShowFormatted(false);
    () => {
      if (selectOnFocus) {
        target.select();
      }
    };
  };

  const onBlur = (e) => {
    applyFormattingAndConstraints(e, true);
  };

  const applyFormattingAndConstraints = (e, showFormatted) => {
    if (!validateValue && !formatNumber) return;

    let newValue =
      !isRealNumber(e.target.value) && defaultValue != null
        ? parseFloat(defaultValue)
        : parseFloat(e.target.value);

    if (required && !newValue) {
      newValue = 0;
    }

    newValue = enforceValueConstraints(
      parseFloat(newValue),
      value,
      true,
    )?.toString();

    const formattedValue = formatValue(
      produce(value, (draft) => {
        draft.value = newValue;
      }),
    );

    setFormatted(formattedValue);
    setShowFormatted(showFormatted);

    const toChange = {
      ...e,
      target: {
        ...e.target,
        value: newValue,
      },
    };
    onValueChange(toChange);
  };

  const defaultRenderReadOnly = () => <p>{value.value}</p>;

  renderReadOnly = () => renderReadOnly || defaultRenderReadOnly;

  const rightLabelContent = (isAdmin, fixType) => (
    <FixedInputIndicator
      isAdmin={isAdmin}
      fixType={fixType}
      fieldId={value.id || null}
      values={
        fixType == FixedFieldType.Range
          ? [value.minimumValue, value.maximumValue]
          : [value.minimumValue || value.maximumValue || value.value || 0]
      }
      fixedValueOnly={fixedValueOnly}
      disabled={
        disabled ||
        (value?.isFixed &&
          (value?.userArea == UserArea.IpsAdmin ||
            value?.userArea == UserArea.IpsSuperUser) &&
          !currentUser.isInternalUser)
      }
      onForcedValuesChanged={onValuePropsChange}
      minorCurrency={minorCurrency}
      decimalPlaces={decimalPlaces}
      editorPopupClass={editorPopupClass}
      minAllowValue={lowerLimit}
      maxAllowValue={upperLimit}
      displayPriority={displayPriority}
      currentValue={value.value}
      disableEditor={
        (value?.maximumValue || value?.minimumValue) &&
        (value?.userArea === UserArea.IpsAdmin ||
          value?.userArea === UserArea.IpsSuperUser) &&
        !currentUser.isInternalUser
      }
    />
  );

  const InitialValueWarningLabel = () => {
    const outsideOfLimits =
      (isRealNumber(value.initialLoadValue) &&
        isRealNumber(minAllowValue) &&
        value.initialLoadValue < minAllowValue) ||
      (isRealNumber(value.initialLoadValue) &&
        isRealNumber(maxAllowValue) &&
        value.initialLoadValue > maxAllowValue);

    if (!outsideOfLimits || hideInitialLoadValueWarning) return null;

    return (
      <React.Fragment>
        {!disableWarning && (
          <div
            className="initial-fixed-warning"
            onClick={() => setHideInitialLoadValueWarning(true)}
          >
            <span className="limits">
              <Icon name="exclamation triangle" />
              {t("AllowedLimits")} <b> {minAllowValue}</b> -
              <b>{maxAllowValue}</b>
            </span>
          </div>
        )}
      </React.Fragment>
    );
  };

  const WarningLabel = () =>
    fixType !== FixedFieldType.None &&
    fixType !== FixedFieldType.Set && (
      <div className="fixed-warning" onClick={hideWarning}>
        <span>
          <Icon name="exclamation triangle" />
          {fixType === FixedFieldType.Max && (
            <React.Fragment>
              {t("ForcedMaximumOf")} <b> {value.maximumValue}</b>
            </React.Fragment>
          )}
          {fixType === FixedFieldType.Min && (
            <React.Fragment>
              {t("ForcedMinimumOf")} <b> {value.minimumValue}</b>
            </React.Fragment>
          )}
          {fixType === FixedFieldType.Range && (
            <React.Fragment>
              {t("RangeBetween")} <b> {value.minimumValue}</b> -
              <b>{value.maximumValue}</b>
            </React.Fragment>
          )}
        </span>
      </div>
    );

  const isAdmin = hasPermission(Targets.SheetAdmin, Actions.Edit);

  const fieldDisabled = disabled || fixType == FixedFieldType.Set;

  const hiddenIndicator =
    hideIndicator || (!isAdmin && fixType === FixedFieldType.None);
  const hasNonMinorCurrencyLabel = currency && !minorCurrency;
  const hasMinorCurrencyLabel = currency && minorCurrency;
  const hasLeftLabel = hasNonMinorCurrencyLabel;
  const hasRightLabel = !hiddenIndicator;

  return (
    !!value && (
      <React.Fragment>
        <Field
          id={id}
          inline={inline}
          required={required}
          disabled={fieldDisabled}
          renderReadOnly={readOnly && renderReadOnly}
          width={hasRightLabel ? width : width + 1}
          fluid={fluid}
          label={label}
          actions={actions}
          errors={FormUtils.fieldErrors(errors, showErrors, allErrors)}
          animateErrors={animateErrors}
          className={classNames(
            "fix-input-container",
            passThruProps.className,
            "fixed-field",
            displayWarning && "show",
            !hideInitialLoadValueWarning && "show-initial-warning",
          )}
        >
          <SInput
            {...semanticProps}
            ref={inputRef}
            labelPosition={
              hasRightLabel ? "right" : hasLeftLabel ? "left" : null
            }
            fluid={false}
            value={
              value != null && value.value != null && !showFormatted
                ? value.value
                : formatted
            }
            onChange={onValueChange}
            disabled={fieldDisabled}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={(e) => {
              //enter key
              if (e.keyCode === 13) {
                applyFormattingAndConstraints(e, false);
              } else {
                handleUserKeyPress();
              }
            }}
            className={classNames(
              "fix-input-wrapper",
              hiddenIndicator && "fix-input-hidden",

              hasLeftLabel && "with-left-label",
              hasNonMinorCurrencyLabel && "left-label--currency",

              hasRightLabel && "with-right-label",
              !hiddenIndicator && "right-label--indicator",
              hasMinorCurrencyLabel && "right-label--minorc",

              fluid ? "fix-input-fluid" : "fix-input-not-fluid",

              isAdmin && "is-admin",
            )}
          >
            {currency && !minorCurrency && (
              <Label basic className="currency-label">
                {process.env.MAJOR_CURRENCY}
              </Label>
            )}

            <input />
            {hasRightLabel && rightLabelContent(isAdmin, fixType)}
            <WarningLabel />
            <InitialValueWarningLabel />
          </SInput>
        </Field>
      </React.Fragment>
    )
  );
};

FixedInput.propTypes = {
  width: PropTypes.number,
  fluid: PropTypes.bool,
  renderReadOnly: PropTypes.bool,
  showErrors: PropTypes.bool,
  required: PropTypes.bool,
  currency: PropTypes.bool,
  animateErrors: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  value: PropTypes.object,
  maxAllowValue: PropTypes.number,
  minAllowValue: PropTypes.number,
  defaultValue: PropTypes.number,
  decimalPlaces: PropTypes.number,
  minorCurrency: PropTypes.bool,
  selectOnFocus: PropTypes.bool,
  editorPopupClass: PropTypes.string,
  displayPriority: PropTypes.number,
  hideIndicator: PropTypes.bool,
  percentage: PropTypes.bool,
  inline: PropTypes.bool,
  fixedValueOnly: PropTypes.bool,
  formatNumber: PropTypes.bool,
  validateValue: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hasPermission: PropTypes.func,
  onChange: PropTypes.func,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.object,
  ]),
};

export default connectForm({
  displayName: (props) =>
    props.label && typeof props.label === "string" ? props.label : "Input",
  validators: [
    maxValueValidator(),
    minValueValidator(),
    rangeLengthValidator(),
  ],
})(compose(withCurrentUser, withPermissions)(FixedInput));
