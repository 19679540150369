import React from "react";
import { Lookup, registerLookup, apiGet, Form } from "@redriver/cinnamon";
import { InlineLoader } from "../../../../../shared/components/forms/InlineLoader";

const NAMESPACE = "PRODUCT_SCHEDULE/LOOKUPS";

const productScheduleTypeLookup = "productScheduleTypeLookup";
export const productScheduleStatusLookup = "productScheduleStatusLookup";
export const productMeteredServiceLookup = "productMeteredServiceLookup";

registerLookup(
  productScheduleTypeLookup,
  () =>
    apiGet(
      `${NAMESPACE}/GET_PRODUCT_SCHEDULE_TYPE`,
      `lookups/product-schedule-type`
    ),
  {
    transform: (data) =>
      data && Array.isArray(data)
        ? data.map((x) => {
            return {
              value: x.key,
              text: x.value,
            };
          })
        : [],
    cache: false,
  }
);

export const ProductScheduleTypeDropdown = ({ className, ...props }) => (
  <Lookup
    lookup={productScheduleTypeLookup}
    render={({ loading, response }) => {
      let options = response
        ? response.map((m) => ({
            text: m.text,
            value: m.value,
          }))
        : [];
      return (
        <div className={className}>
          {loading ? (
            <InlineLoader isActive={true} size="small" />
          ) : (
            <Form.Dropdown
              {...props}
              className="ps-enum-dropdown"
              field="type"
              disabled={!options.length}
              options={options}
              placeholder="Type..."
              lookup={productScheduleTypeLookup}
              required
            />
          )}
        </div>
      );
    }}
  />
);

registerLookup(
  productScheduleStatusLookup,
  () =>
    apiGet(
      `${NAMESPACE}/GET_PRODUCT_SCHEDULE_STATUS`,
      `lookups/product-schedule-status`
    ),
  {
    transform: (data) =>
      data && Array.isArray(data)
        ? data.map((x) => {
            return {
              value: x.key,
              text: x.value,
            };
          })
        : [],
    cache: false,
  }
);

export const ProductScheduleStatusDropdown = ({ className, ...props }) => (
  <Lookup
    lookup={productScheduleStatusLookup}
    lookupParams={{}}
    render={({ loading, response }) => {
      let options = response
        ? response.map((m) => ({
            text: m.text,
            value: m.value,
          }))
        : [];
      return (
        <div className={className}>
          {loading ? (
            <InlineLoader isActive={true} size="small" />
          ) : (
            <Form.Dropdown
              {...props}
              field="status"
              className="ps-enum-dropdown"
              placeholder="Status..."
              disabled={!options.length}
              options={options}
              required
            />
          )}
        </div>
      );
    }}
  />
);

registerLookup(
  productMeteredServiceLookup,
  ({ sheetId }) =>
    apiGet(
      `${NAMESPACE}/GET_PRODUCT_SCHEDULE_ITEM_UNIT`,
      `lookups/product-schedule-item-unit`,
      { sheetId }
    ),
  {
    transform: (data) =>
      data && Array.isArray(data)
        ? data.map((x) => {
            return {
              value: x.id,
              text: x.displayCode,
              isLargeFormatItem: x.isLargeFormatItem,
            };
          })
        : [],
    cache: false,
  }
);

export const ProductMeteredServiceDropdown = ({
  sheetId,
  options,
  className,
  field,
  currentlySelected,
  scheduleItemId,
  ...props
}) => {
  let filteredOptions = (options || []).filter(
    (y) => !currentlySelected.includes(y.value) || y.value == scheduleItemId
  );
  filteredOptions.unshift({
    text: "",
    value: "removeMe",
    isLargeFormatItem: false,
  });

  return (
    <Form.Dropdown
      {...props}
      className="ps-item-dropdown test"
      field={field}
      placeholder="-"
      options={filteredOptions}
      optionField="otherInfo"
    />
  );
};
