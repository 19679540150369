import React from "react";
import { connect } from "react-redux";
import { Format, DataList } from "@redriver/cinnamon";
import { getSheetState } from "features/Sheets";
import { FunderTypeDescription } from "features/../../../shared/constants/enums";

const CalculationBreakdown = ({ rawCalculation }) => {
  rawCalculation = rawCalculation || {};
  const breakdown = rawCalculation.breakdown || {};

  const servicesCost = breakdown.servicesCost || {};
  const capitalCalcCosts = breakdown.capitalCalcCosts || {};
  const schedule = breakdown.systemSchedule || {};
  const paperInclusiveCosts = breakdown.paperInclusiveCosts || {};
  const fmvs = breakdown.fmvDetails || [];

  if (!servicesCost || !capitalCalcCosts || !schedule || !paperInclusiveCosts) {
    return null;
  }

  return (
    <div className="calculation-breakdown">
      <h3>Calculation breakdown</h3>

      <h4>Summary</h4>

      {/* TODO: Move these to use the dlPair render function below */}
      <small>Minimum Quarterly Payment Logic</small>
      <h5>{rawCalculation.quarterlyMinimumPaymentLogic}</h5>

      <small>Volume Minimum Qu Payment Logic</small>
      <h5>{rawCalculation.quarterlyVolumeMinimumPaymentLogic}</h5>

      <small>Non Volume Minimum Qu Payment Logic</small>
      <h5>{rawCalculation.quarterlyNonVolumeMinimumPaymentLogic}</h5>

      <small>Settlement Quarterly Amount</small>
      <h5>{rawCalculation.settlementQuarterlyAmount}</h5>

      <small>Settlement Quarterly Amount Logic</small>
      <h5>{rawCalculation.settlementQuarterlyAmountLogic}</h5>

      <small>Termination Discount Logic</small>
      <h5>{rawCalculation.terminationDiscountLogic}</h5>

      <h4>Capital Calc</h4>

      <small>Supplier</small>
      <h5>{capitalCalcCosts.dealer}</h5>

      <small>Rate Group</small>
      <h5>{capitalCalcCosts.rateGroup}</h5>

      <small>Volume Selection</small>
      <h5>
        <Format.Number
          value={capitalCalcCosts.volumeSelection}
          decimalPlaces={1}
          thousandSeparator
        />
      </h5>

      <small>Total Minimum Quarterly Rental</small>
      <h5>
        <Format.Number
          value={capitalCalcCosts.totalMinQuarterlyRental}
          format={process.env.CURRENCY_CODE}
          decimalPlaces={2}
          thousandSeparator
        />
      </h5>

      <small>Schedule Settlement</small>
      <h5>
        <Format.Number
          value={capitalCalcCosts.scheduleSettlementTotal}
          format={process.env.CURRENCY_CODE}
          decimalPlaces={2}
          thousandSeparator
        />
      </h5>

      <small>Total Capital Costs</small>
      <h5>
        <Format.Number
          value={capitalCalcCosts.totalCapitalCosts}
          format={process.env.CURRENCY_CODE}
          decimalPlaces={2}
          thousandSeparator
        />
      </h5>

      <small>Quarterly Device Extra Cost</small>
      <h5>
        <Format.Number
          value={capitalCalcCosts.quarterlyDeviceExtraCost}
          format={process.env.CURRENCY_CODE}
          decimalPlaces={2}
          thousandSeparator
        />
      </h5>

      <small>Quarterly Device Extra Cost Logic</small>
      <h5>{capitalCalcCosts.quarterlyDeviceExtraCostLogic}</h5>

      <small>Items</small>
      {capitalCalcCosts.itemCosts &&
        capitalCalcCosts.itemCosts.map((i, idx) => (
          <div className="capital-calc-item" key={idx}>
            <small>Funder Type</small>
            <h5>{FunderTypeDescription[i.funderType]}</h5>

            <small>Finance Type</small>
            <h5>{i.financeType}</h5>

            <small>Payment Method</small>
            <h5>{capitalCalcCosts.paymentMethod}</h5>

            <small>Finance Selection</small>
            <h5>{i.financeSelection}</h5>

            <small>IP Syndicate Liquidity Charge</small>
            <h5>
              <Format.Number
                value={i.ipSyndicateLiquidityCharge}
                decimalPlaces={2}
                thousandSeparator
              />
            </h5>

            <small>Yield Rate</small>
            <h5>
              <Format.Number
                value={i.yieldRate}
                decimalPlaces={{ min: 0, max: 3 }}
                thousandSeparator
              />
            </h5>

            <small>Liquidity Charge Total %</small>
            <h5>
              <Format.Number
                value={i.liquidityChargeTotalPercentage}
                decimalPlaces={2}
                thousandSeparator
              />
            </h5>

            <small>Rate Group RRP Percentage</small>
            <h5>
              <Format.Number
                value={i.rateGroupRrpPercentage}
                decimalPlaces={2}
                thousandSeparator
              />
            </h5>

            <small>Max Product Capital Allowed</small>
            <h5>
              <Format.Number
                value={i.maxProductCapitalAllowed}
                format={process.env.CURRENCY_CODE}
                decimalPlaces={2}
                thousandSeparator
              />
            </h5>

            <small>Gross Sales Rate</small>
            <h5>
              <Format.Number value={i.rate} />
            </h5>

            <small>Yield Band</small>
            <h5>{i.yieldBand}</h5>

            <small>Total Capital Costs</small>
            <h5>
              <Format.Number
                value={i.totalCapitalCosts}
                format={process.env.CURRENCY_CODE}
                decimalPlaces={2}
                thousandSeparator
              />
            </h5>

            <small>Minimum Quarterly Rental</small>
            <h5>
              <Format.Number
                value={i.minimumQuarterlyRental}
                format={process.env.CURRENCY_CODE}
                decimalPlaces={2}
                thousandSeparator
              />
            </h5>

            <small>Min Quarterly Rental Logic</small>
            <h5>{i.minimumQuarterlyRentalLogic}</h5>

            <small>Schedule Settlement</small>
            <h5>
              <Format.Number
                value={i.scheduleSettlement}
                format={process.env.CURRENCY_CODE}
                decimalPlaces={2}
                thousandSeparator
              />
            </h5>

            <small>Schedule Settlement Logic</small>
            <h5>{i.scheduleSettlementLogic}</h5>

            {renderDealerCalc(i.dealerCalcCostResult)}
            {renderCofundedCalc(i.cofundedCalcCostResult)}
          </div>
        ))}

      <h4>Services</h4>
      <small>Sum of selected services</small>
      <h5>
        <Format.Number
          value={servicesCost.servicesSum || 0}
          format={process.env.CURRENCY_CODE}
          decimalPlaces={2}
          thousandSeparator
        />
      </h5>

      <small>Services total</small>
      <h5>
        <Format.Number
          value={servicesCost.servicesTotal || 0}
          format={process.env.CURRENCY_CODE}
          decimalPlaces={2}
          thousandSeparator
        />
      </h5>

      <small>Services total logic</small>
      <h5>{servicesCost.servicesTotalLogic}</h5>

      <small>Cost per click</small>
      <h5>
        <Format.Number
          value={servicesCost.costPerClick || 0}
          decimalPlaces={{
            min: 0,
            max: process.env.USE_MINOR_CURRENCY ? 3 : 5,
          }}
          thousandSeparator
          suffix={process.env.USE_MINOR_CURRENCY && process.env.MINOR_CURRENCY}
          prefix={!process.env.USE_MINOR_CURRENCY && process.env.MAJOR_CURRENCY}
        />
      </h5>

      <small>Cost Per Click Logic</small>
      <h5>{servicesCost.costPerClickLogic}</h5>

      <small>Min Quarterly Rental</small>
      <h5>
        <Format.Number
          value={servicesCost.minQuarterlyRental || 0}
          format={process.env.CURRENCY_CODE}
          decimalPlaces={2}
          thousandSeparator
        />
      </h5>

      <small>Min Quarterly Rental Logic</small>
      <h5>{servicesCost.minQuarterlyRentalLogic}</h5>

      <small>Schedule Settlement</small>
      <h5>
        <Format.Number
          value={servicesCost.scheduleSettlementTotal || 0}
          format={process.env.CURRENCY_CODE}
          decimalPlaces={2}
          thousandSeparator
        />
      </h5>

      <small>Schedule Settlement Logic</small>
      <h5>{servicesCost.scheduleSettlementTotalLogic}</h5>

      <h4>Schedule</h4>
      <small>Min Quarterly Payment</small>
      <h5>
        <Format.Number
          value={schedule.minQuarterlyPayment}
          format={process.env.CURRENCY_CODE}
          decimalPlaces={2}
          thousandSeparator
        />
      </h5>

      <small>Schedule Settlement</small>
      <h5>
        <Format.Number
          value={schedule.scheduleSettlementTotal}
          format={process.env.CURRENCY_CODE}
          decimalPlaces={2}
          thousandSeparator
        />
      </h5>

      <h5>Items</h5>
      {renderScheduleItems(schedule.itemCosts)}

      <h5>Large Format Items</h5>
      {renderScheduleItems(schedule.largeFormatItemCosts)}

      <h4>Paper Inclusive</h4>

      <small>Amethyst Amount / Min Qu Payment</small>
      <h5>
        <Format.Number
          value={paperInclusiveCosts.minQuarterlyPayment}
          format={process.env.CURRENCY_CODE}
          decimalPlaces={2}
          thousandSeparator
        />
      </h5>

      <small>Min Quarterly Payment Logic</small>
      <h5>{paperInclusiveCosts.minQuarterlyPaymentLogic}</h5>

      <small>A4 Paper Cost</small>
      <h5>
        <Format.Number
          value={paperInclusiveCosts.a4PaperCost}
          format={process.env.CURRENCY_CODE}
          decimalPlaces={2}
          thousandSeparator
        />
      </h5>

      <small>A3 Paper Cost</small>
      <h5>
        <Format.Number
          value={paperInclusiveCosts.a3PaperCost}
          format={process.env.CURRENCY_CODE}
          decimalPlaces={2}
          thousandSeparator
        />
      </h5>

      <small>Paper Price</small>
      <h5>
        <Format.Number
          value={paperInclusiveCosts.paperPrice}
          format={process.env.CURRENCY_CODE}
          decimalPlaces={2}
          thousandSeparator
        />
      </h5>

      <small>Paper Price Logic</small>
      <h5>{paperInclusiveCosts.paperPriceLogic}</h5>

      <small>A4 Paper Price</small>
      <h5>
        <Format.Number
          value={paperInclusiveCosts.a4PaperPrice}
          format={process.env.CURRENCY_CODE}
          decimalPlaces={2}
          thousandSeparator
        />
      </h5>

      <small>A4 Paper Price Logic</small>
      <h5>{paperInclusiveCosts.a4PaperPriceLogic}</h5>

      <small>A3 Paper Price</small>
      <h5>
        <Format.Number
          value={paperInclusiveCosts.a3PaperPrice}
          format={process.env.CURRENCY_CODE}
          decimalPlaces={2}
          thousandSeparator
        />
      </h5>

      <small>A3 Paper Price Logic</small>
      <h5>{paperInclusiveCosts.a3PaperPriceLogic}</h5>

      <small>Units</small>
      <h5>{paperInclusiveCosts.units}</h5>

      <small>Units Logic</small>
      <h5>{paperInclusiveCosts.unitsLogic}</h5>

      <small>Cost per Unit</small>
      <h5>
        <Format.Number
          value={paperInclusiveCosts.costPerUnit || 0}
          decimalPlaces={{
            min: 0,
            max: process.env.USE_MINOR_CURRENCY ? 3 : 5,
          }}
          thousandSeparator
          suffix={process.env.USE_MINOR_CURRENCY && process.env.MINOR_CURRENCY}
          prefix={!process.env.USE_MINOR_CURRENCY && process.env.MAJOR_CURRENCY}
        />
      </h5>

      <small>Cost per Unit Logic</small>
      <h5>{paperInclusiveCosts.costPerUnitLogic}</h5>

      <small>Current Spend Total Amount</small>
      <h5>
        <Format.Number
          value={paperInclusiveCosts.currentSpendTotalAmount}
          decimalPlaces={2}
          format={process.env.CURRENCY_CODE}
          thousandSeparator
        />
      </h5>

      <small>Current Spend Total Amount Logic</small>
      <h5>{paperInclusiveCosts.currentSpendTotalAmountLogic}</h5>

      <small>Total Included Min Del Charge</small>
      <h5>
        <Format.Number
          value={paperInclusiveCosts.totalIncludedMinimumDeliveryCharge}
          decimalPlaces={2}
          format={process.env.CURRENCY_CODE}
          thousandSeparator
        />
      </h5>

      <small>Total Included Min Del Charge Logic</small>
      <h5>{paperInclusiveCosts.totalIncludedMinimumDeliveryChargeLogic}</h5>

      <small>Quarterly Contract Volume</small>
      <h5>
        <Format.Number
          value={paperInclusiveCosts.quarterlyContractVolume}
          decimalPlaces={0}
          thousandSeparator
        />
      </h5>

      <small>Quarterly Contract Volume Logic</small>
      <h5>{paperInclusiveCosts.quarterlyContractVolumeLogic}</h5>

      <small>Contract Volume In Boxed Reams</small>
      <h5>{paperInclusiveCosts.contractVolumeInBoxedReams}</h5>

      <small>Contract Volume In Boxed Reams Logic</small>
      <h5>{paperInclusiveCosts.contractVolumeInBoxedReamsLogic}</h5>

      <small>Min Diff To Create In Amethyst</small>
      <h5>
        <Format.Number
          value={paperInclusiveCosts.minimumDifferenceToCreateInAmethyst}
          decimalPlaces={2}
          format={process.env.CURRENCY_CODE}
          thousandSeparator
        />
      </h5>

      <small>Min Diff To Create In Amethyst Logic</small>
      <h5>{paperInclusiveCosts.minimumDifferenceToCreateInAmethystLogic}</h5>

      <small>Likely Min Gross Margin</small>
      <h5>
        <Format.Number
          value={paperInclusiveCosts.likelyMinimumGrossMargin}
          decimalPlaces={2}
          format={process.env.CURRENCY_CODE}
          thousandSeparator
        />
      </h5>

      <small>Likely Min Gross Margin Logic</small>
      <h5>{paperInclusiveCosts.likelyMinimumGrossMarginLogic}</h5>

      <small>Full Boxes</small>
      <h5>{paperInclusiveCosts.fullBoxes}</h5>

      <small>Full Boxes Logic</small>
      <h5>{paperInclusiveCosts.fullBoxesLogic}</h5>

      {renderFmvCalc(fmvs)}
    </div>
  );
};

const renderFmvCalc = (fmvs) =>
  fmvs == null ? null : (
    <React.Fragment>
      <h4>FMV</h4>
      {fmvs.map((fmv, idx) => (
        <div className="capital-calc-item" key={idx}>
          {dlPair("Funder Type", FunderTypeDescription[fmv.funderType])}
          {dlPair("Finance Type", fmv.financeType)}
          {dlPair("FMV Type", fmv.fmvType)}

          <h5>Adjusted Rates</h5>
          {renderFmvAdjusted("Overall", fmv.adjustedOverall)}
          {renderFmvAdjusted("IPS %", fmv.adjustedIpsPercentage)}
          {renderFmvAdjusted("IPS Protection", fmv.adjustedIpsProtection)}
          {renderFmvAdjusted("Supplier Net", fmv.adjustedDealerNet)}
          {dlPair(`Rate Used for GSR`, fmv.rateUsedForGSR)}
          {renderFmvAdjusted("GSR", fmv.adjustedGsr)}

          <h5>Future Values</h5>
          {renderFutureValue("Full FMV", fmv.fullFmvFutureValues)}
          {renderFutureValue("Of RRP", fmv.ofRrpFutureValues)}
          {renderFutureValue(
            "Of Total CAPITAL",
            fmv.ofTotalCapitalFutureValues,
          )}

          <h5>RRP Allowance</h5>
          {dlPair("RRP Allowance %", fmv.rrpAllowancePercentage)}
          {dlPair("of RRP %", fmv.ofRrpPercentage, fmv.ofRrpPercentageLogic)}
          {dlPair(
            "of Total Capital %",
            fmv.ofTotalCapitalPercentage,
            fmv.ofTotalCapitalPercentageLogic,
          )}

          <h5>Used figures</h5>
          {dlPair("Used %", fmv.fmvUsedPercentage, fmv.fmvUsedPercentageLogic)}
          {dlPair(
            "Used Amount",
            <Format.Number
              format={process.env.CURRENCY_CODE}
              value={fmv.fmvUsedAmount}
            />,
            fmv.fmvUsedAmountLogic,
          )}
        </div>
      ))}
    </React.Fragment>
  );

const renderFmvAdjusted = (name, adjusted) => (
  <React.Fragment>
    {dlPair(
      `${name} blended IP rate`,
      adjusted.blendedIpRate,
      adjusted.blendedIpRateLogic,
    )}
    {dlPair(
      `${name} nett CFA`,
      <Format.Number
        format={process.env.CURRENCY_CODE}
        value={adjusted.nettCfa}
      />,
      adjusted.nettCfaLogic,
    )}
  </React.Fragment>
);

const renderFutureValue = (name, fv) => (
  <React.Fragment>
    {dlPair(`${name} Percentage`, fv.percentage, fv.percentageLogic)}
    {dlPair(
      `${name} Amount`,
      <Format.Number format={process.env.CURRENCY_CODE} value={fv.amount} />,
      fv.amountLogic,
    )}
  </React.Fragment>
);

const renderDealerCalc = (r) =>
  r == null ? null : (
    <div className="funder-calc">
      <h4>Supplier Funded Calc</h4>

      {dlPair(
        "IPS % of Yield",
        <Format.Number
          value={r.ipSyndicatePercentageYield}
          decimalPlaces={{ min: 0, max: 3 }}
          suffix="%"
        />,
        r.ipSyndicatePercentageYieldLogic,
      )}
      {dlPair(
        "IPS %",
        <Format.Number
          value={r.ipSyndicatePercentage}
          decimalPlaces={2}
          suffix="%"
        />,
        r.ipSyndicatePercentageLogic,
      )}
      {dlPair(
        "Supplier %",
        <Format.Number
          value={r.dealerPercentage}
          decimalPlaces={2}
          suffix="%"
        />,
        r.dealerPercentageLogic,
      )}
      {dlPair(
        "IPS Liq Charge %",
        <Format.Number
          value={r.ipSyndicateLiquidityCharge}
          decimalPlaces={2}
          suffix="%"
        />,
        r.ipSyndicateLiquidityChargeLogic,
      )}
      {dlPair(
        "IPS Liq Charge amount",
        <Format.Number
          value={r.ipsLiquidityChargeAmount}
          format={process.env.CURRENCY_CODE}
        />,
        r.ipsLiquidityChargeAmountLogic,
      )}
      {dlPair(
        "IPS % of rental %",
        <Format.Number
          value={r.ipsPercentageOfRental}
          decimalPlaces={{ min: 0, max: 3 }}
          suffix="%"
        />,
        r.ipsPercentageOfRentalLogic,
      )}
      {dlPair(
        "Rental Charge",
        <Format.Number
          value={r.rentalCharge}
          format={process.env.CURRENCY_CODE}
        />,
      )}
      {dlPair(
        "Net Charge",
        <Format.Number
          value={r.netCharge}
          format={process.env.CURRENCY_CODE}
        />,
        r.netChargeLogic,
      )}
      {dlPair(
        "Supplier Net",
        <Format.Number
          value={r.dealerNet}
          format={process.env.CURRENCY_CODE}
        />,
        r.dealerNetLogic,
      )}
      {dlPair(
        "Additional charge",
        <Format.Number
          value={r.additionalCharge}
          format={process.env.CURRENCY_CODE}
        />,
        r.additionalChargeLogic,
      )}
      {dlPair(
        "IPS Share of Yield",
        <Format.Number
          value={r.ipSyndicateShareOfYield}
          format={process.env.CURRENCY_CODE}
        />,
        r.ipSyndicateShareOfYieldLogic,
      )}
    </div>
  );
const renderCofundedCalc = (r) =>
  r == null ? null : (
    <div className="funder-calc">
      <h4>Cofunded Calc</h4>

      {dlPair(
        "Supplier Co-fund %",
        <Format.Number
          value={r.dealerCofundPercentage}
          decimalPlaces={2}
          suffix="%"
        />,
      )}
      {dlPair(
        "Rental used",
        <Format.Number
          value={r.rentalUsed}
          format={process.env.CURRENCY_CODE}
        />,
      )}
      {dlPair("Number of payments", r.totalPayments)}
      {dlPair(
        "Total rentals",
        <Format.Number
          value={r.totalRentals}
          format={process.env.CURRENCY_CODE}
        />,
        r.totalRentalsLogic,
      )}
      {dlPair(
        "Gross Sales Rate",
        <Format.Number value={r.grossSalesRate} decimalPlaces={2} />,
      )}
      {dlPair(
        "Normal Supplier Net Rate",
        <Format.Number value={r.normalDealerNetRate} decimalPlaces={2} />,
      )}
      {dlPair(
        "IPS Net Rate",
        <Format.Number value={r.ipSyndicateNetRate} decimalPlaces={2} />,
      )}
      {dlPair(
        "IPS LC",
        <Format.Number
          value={r.ipSyndicateLiquidityCharge}
          decimalPlaces={2}
          suffix="%"
        />,
      )}
      {dlPair(
        "Supplier LC",
        <Format.Number
          value={r.dealerLiquidityCharge}
          decimalPlaces={2}
          suffix="%"
        />,
      )}
      {/**/}
      {cofundedCapitalRow("IPS capital", r.ipsCapitalRow)}

      {cofundedCapitalRow(
        "Total supplier cap & liq payout",
        r.dealerCapitalRow,
      )}
      {cofundedCapitalRow("Total capital entered", r.totalCapitalRow)}

      {dlPair(
        "Total payout",
        <Format.Number
          value={r.totalPayout}
          format={process.env.CURRENCY_CODE}
        />,
        r.totalPayoutLogic,
      )}
      {dlPair(
        "IPS diff at all %",
        <Format.Number
          value={r.ipSyndicateDifferenceAtAllPercentage}
          format={process.env.CURRENCY_CODE}
        />,
        r.ipSyndicateDifferenceAtAllPercentageLogic,
      )}
      {dlPair(
        "IPS diff amount",
        <Format.Number
          value={r.ipSyndicateDifferenceAmount}
          format={process.env.CURRENCY_CODE}
        />,
        r.ipSyndicateDifferenceAmountLogic,
      )}
      {dlPair(
        "Capital % at supplier payout",
        <Format.Number
          value={r.capitalPercentageAtDealerPayout}
          decimalPlaces={2}
          suffix="%"
        />,
        r.capitalPercentageAtDealerPayoutLogic,
      )}
      {dlPair(
        "Capital % at IPS payout",
        <Format.Number
          value={r.capitalPercentageAtIpsPayout}
          decimalPlaces={2}
          suffix="%"
        />,
        r.capitalPercentageAtIpsPayoutLogic,
      )}
      {dlPair(
        "Supplier 1/4 rental balance",
        <Format.Number
          value={r.dealerQuarterlyRentalBalance}
          format={process.env.CURRENCY_CODE}
        />,
        r.dealerQuarterlyRentalBalanceLogic,
      )}
    </div>
  );

const dlPair = (name, value, logic) => (
  <React.Fragment>
    <DataList.Item title={name}>{value}</DataList.Item>
    {logic && <DataList.Item title={`${name} logic`}>{logic}</DataList.Item>}
  </React.Fragment>
);

const cofundedCapitalRow = (name, row) => (
  <div className="cofunded-capital-row">
    <h4>{name}</h4>
    {dlPair(
      "Total",
      <Format.Number value={row.total} format={process.env.CURRENCY_CODE} />,
      row.totalLogic,
    )}
    {dlPair(
      "Funder amount",
      <Format.Number
        value={row.funderAmount}
        format={process.env.CURRENCY_CODE}
      />,
      row.funderAmountLogic,
    )}
    {dlPair(
      "Balance",
      <Format.Number value={row.balance} format={process.env.CURRENCY_CODE} />,
      row.balanceLogic,
    )}
  </div>
);

const renderScheduleItems = (items) =>
  items &&
  items.map((i, idx) => {
    return (
      <div className="capital-calc-item" key={idx}>
        <small>Unit</small>
        <h5>{i.unit}</h5>
        <small>Fixed Code</small>
        <h5>{i.displayCode || "-"}</h5>

        <small>Min Quarterly Volume</small>
        <h5>
          <Format.Number value={i.minQuarterlyVolume} />
        </h5>

        <small>Capital Cost Per Click</small>
        <h5>
          <Format.Number
            value={i.capitalCostPerClick || 0}
            decimalPlaces={{
              min: 0,
              max: process.env.USE_MINOR_CURRENCY ? 3 : 5,
            }}
            thousandSeparator
            suffix={
              process.env.USE_MINOR_CURRENCY && process.env.MINOR_CURRENCY
            }
            prefix={
              !process.env.USE_MINOR_CURRENCY && process.env.MAJOR_CURRENCY
            }
          />
        </h5>

        <small>Capital CPC Logic</small>
        <h5>{i.capitalCostPerClickLogic}</h5>

        <small>Cost per Unit</small>
        <h5>
          <Format.Number
            value={i.costPerUnit || 0}
            decimalPlaces={{
              min: 0,
              max: process.env.USE_MINOR_CURRENCY ? 3 : 5,
            }}
            thousandSeparator
            suffix={
              process.env.USE_MINOR_CURRENCY && process.env.MINOR_CURRENCY
            }
            prefix={
              !process.env.USE_MINOR_CURRENCY && process.env.MAJOR_CURRENCY
            }
          />
        </h5>

        <small>Cost Per Unit Logic</small>
        <h5>{i.costPerUnitLogic}</h5>

        <small>Finance Cost per Click</small>
        <h5>
          <Format.Number
            value={i.financeCostPerClick || 0}
            decimalPlaces={{
              min: 0,
              max: process.env.USE_MINOR_CURRENCY ? 3 : 5,
            }}
            thousandSeparator
            suffix={
              process.env.USE_MINOR_CURRENCY && process.env.MINOR_CURRENCY
            }
            prefix={
              !process.env.USE_MINOR_CURRENCY && process.env.MAJOR_CURRENCY
            }
          />
        </h5>

        <small>Finance Cost per Click Logic</small>
        <h5>{i.financeCostPerClickLogic}</h5>

        <small>Min Quarterly Payment</small>
        <h5>
          <Format.Number
            value={i.minQuarterlyPayment}
            format={process.env.CURRENCY_CODE}
            decimalPlaces={2}
            thousandSeparator
          />
        </h5>

        <small>Min Quarterly Payment Logic</small>
        <h5>{i.minQuarterlyPaymentLogic}</h5>

        <small>Schedule Settlement</small>
        <h5>
          <Format.Number
            value={i.scheduleSettlement}
            format={process.env.CURRENCY_CODE}
            decimalPlaces={2}
            thousandSeparator
          />
        </h5>

        <small>Schedule Settlement Logic</small>
        <h5>{i.scheduleSettlementLogic}</h5>
      </div>
    );
  });

const mapStateToProps = (state) => {
  const { rawCalculation } = getSheetState(state);
  return { rawCalculation };
};

export default connect(mapStateToProps, null)(CalculationBreakdown);
