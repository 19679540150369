import React from "react";
import { ModalBuilder, Table } from "@redriver/cinnamon";
import { TranslatedModal } from "../../../components/modals";


const RemoveServiceModal = ({ submitAction, onSubmitted, service }) => (
  <ModalBuilder
    submitAction={submitAction}
    submitParams={service.id}
    onSubmitted={onSubmitted}
    renderTrigger={showModal => (
      <Table.MenuItem onClick={showModal}>Delete</Table.MenuItem>
    )}
    renderModal={modalProps => (
      <TranslatedModal.Delete
        {...modalProps}
        header="Remove Service"
        submitLabel="Remove Service"
      >
        <p>Are you sure you wish to remove '{service.description}'?</p>
      </TranslatedModal.Delete>
    )}
  />
);

export default RemoveServiceModal;
