export const PwaSessionType = {
  RegularBrowser: 0,
  LocallyInstalled: 1
};

export const PwaConfigState = {
  None: 0,
  Incompatible: 1 << 0,
  DisabledForEnv: 1 << 1,
  AlreadyInstalled: 1 << 2,
  UserPrompted: 1 << 3,
  ModalDismissed: 1 << 4,
  BrowserEventFired: 1 << 5,
  BrowserEventDismissed: 1 << 6,
  InstallBegun: 1 << 7,
  InstallComplete: 1 << 8
};
