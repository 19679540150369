import React from "react";
import { FormBuilder, Form } from "@redriver/cinnamon";
import { Image } from "semantic-ui-react";
import Search from "features/../../../shared/assets/purple-search.svg";

const ListSearch = ({
  value,
  onChange,
  onFocus,
  placeholder,
  field = "search",
}) => {
  return (
    <FormBuilder
      value={value}
      onChange={onChange}
      renderForm={(formProps) => (
        <Form {...formProps}>
          <Form.Input
            field={field}
            placeholder={placeholder}
            icon={<Image src={Search} />}
            className="search-input highlighted-input"
            onFocus={onFocus}
          />
        </Form>
      )}
    />
  );
};

export default ListSearch;
