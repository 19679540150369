import { apiGet } from "@redriver/cinnamon";

const ActionTypes = {
  GetLogo: "DEALER_OFFICES/GET_LOGO",
  GetUnreadNotificationsCount: "NOTIFICATIONS/UNREAD_COUNT",
};

export const getCompanyLogo = (companyId) => async (dispatch) => {
  let result = await dispatch(
    apiGet(ActionTypes.GetLogo, `dealer-offices/${companyId}/logo`)
  );
  return result.response ? result.response.logoId : undefined;
};

export const getUnreadNotificationsCount = () =>
  apiGet(ActionTypes.GetUnreadNotificationsCount, `notifications/unread-count`);
