import React from "react";
import { ListBuilder, ActionBar, Table } from "@redriver/cinnamon";
import Add from "features/../../../shared/assets/white-add.svg";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { getClientAuthorisedSignatories } from "./actions";
import SearchClientAuthorisedSignatories from "./SearchClientAuthorisedSignatories";
import AddClientAuthorisedSignatory from "./AddClientAuthorisedSignatory";
import ClientAuthorisedSignatoryActions from "./ClientAuthorisedSignatoryActions";
import { Targets, Actions } from "constants/permissions";
import { TablePagination } from "components/pagination";
import { useTranslation } from "react-i18next";

const ListClientAuthorisedSignatories = ({ clientId }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <h4>{t("clients:headings:authorisedSignatories")}</h4>
      <ListBuilder
        withTable
        initialPagination={{ pageSize: 25, pageNumber: 1 }}
        loadAction={getClientAuthorisedSignatories}
        loadParams={{ clientId }}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        renderList={(tableProps, state, events) => (
          <div className="content-container principal-list client-auth-sig">
            <ActionBar>
              <ActionBar.Item fluid>
                <SearchClientAuthorisedSignatories
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
              </ActionBar.Item>
              <PermissionCheck target={Targets.Client} action={Actions.Edit}>
                <ActionBar.Item align="right">
                  <AddClientAuthorisedSignatory
                    primary
                    clientId={clientId}
                    icon={Add}
                    content={t(
                      "clients:tables:authorisedSignatories:newAuthorisedSignatory:buttonText"
                    )}
                    onAdded={events.onRefresh}
                  />
                </ActionBar.Item>
              </PermissionCheck>
            </ActionBar>
            <Table
              {...tableProps}
              compact
              dataKey="id"
              emptyMessage={t("clients:tables:authorisedSignatories:noData")}
              selectable
            >
              <Table.Column field="name" title="Name" width="30%" />
              <Table.Column field="email" title="Email" width="30%" />
              <Table.Column field="position" title="Position" width="40%" />
              <Table.Column
                hoverable
                width="4%"
                align="right"
                render={(item) => (
                  <ClientAuthorisedSignatoryActions
                    item={item}
                    onRefresh={events.onRefresh}
                  />
                )}
              />
            </Table>
            <TablePagination
              pageNumber={state.pagination.pageNumber}
              onChange={events.onChangePagination}
              pageSize={state.pagination.pageSize}
              totalItems={state.total}
            />
          </div>
        )}
      />
    </React.Fragment>
  );
};

export default ListClientAuthorisedSignatories;
