import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";
import { Image, Icon } from "semantic-ui-react";
import moment from "moment";
import {
  SheetRoutes,
  DealerRoutes,
  AppRoutes,
  ClientRoutes,
} from "constants/routes";
import { getDealerOfficeHeaderInfo } from "../../../../shared/features/DealerOffices";
import { getAgreementsState } from "features/Agreements/selectors";
import { Format } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";

const CompanyLogo = () => {
  const { t } = useTranslation("system");
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    companyFilters: { dealerOffice },
  } = useSelector(getAgreementsState);
  const [logoUri, setLogoUri] = useState(undefined);
  const [timezone, setTimezone] = useState(undefined);
  const [name, setName] = useState(undefined);
  const [time, setTime] = useState(moment());

  const sheetMatch = useRouteMatch(SheetRoutes.Sheet());
  const sheetId = sheetMatch ? sheetMatch.params.id : null;

  const clientMatch = useRouteMatch(ClientRoutes.Overview());
  const clientId = clientMatch ? clientMatch.params.id : null;

  const dealerOfficeMatch = useRouteMatch(DealerRoutes.Overview());
  const dealerOfficeId = dealerOfficeMatch
    ? dealerOfficeMatch.params.id
    : location.pathname === AppRoutes.Agreements
    ? dealerOffice.id
    : null;

  useEffect(() => {
    // Keep time updated
    const interval = setInterval(() => {
      setTime(moment());
    }, 10000);

    (async function fetchLogo() {
      const { success, response } = await dispatch(
        getDealerOfficeHeaderInfo({ dealerOfficeId, sheetId, clientId }),
      );
      setLogoUri(() => (success ? response?.imageUrl : null));
      setTimezone(success ? response?.timezone : null);
      setName(success ? response?.name : null);
    })();

    return () => interval && clearInterval(interval);
  }, [dealerOfficeId, sheetId, clientId, dispatch]);

  return (
    <div className="site-header-container">
      {logoUri && <Image src={logoUri} className="logo" />}
      <div className="info">
        <div className="office">
          <div className="icon-wrapper">
            <Icon name="compass outline" />
          </div>
          <label>{name || "(No Supplier Office selected)"}</label>
        </div>
        <div className="time">
          <label>{t("Local_Time")}:</label>
          <Format.Time timezone={timezone} value={time} format="HH:mm z" />
          {/* To be added at a later date, customer doesn't want this right now but partially implemented.
          <SwitchDealerOfficeModal
            onSubmit={(formData, _) => {
              history.push(
                `${AppRoutes.Agreements}?dealerOfficeId=${formData.dealerOfficeId}`
              );
            }}
            currentDealerOfficeId={dealerOfficeId}
          />
          */}
        </div>
      </div>
    </div>
  );
};

export default CompanyLogo;
