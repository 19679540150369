import React from "react";
import ResultsCounter from "./ResultsCounter";
import PageNumbers from "./PageNumbers";

const TablePagination = ({ pageNumber, onChange, pageSize, totalItems }) => {
  return (
    <footer className="table-pagination">
      <ResultsCounter
        pageNumber={pageNumber}
        pageSize={pageSize}
        totalItems={totalItems}
      />
      <PageNumbers
        activePage={pageNumber}
        onChange={onChange}
        pageSize={pageSize}
        totalItems={totalItems}
      />
    </footer>
  );
};

export default TablePagination;
