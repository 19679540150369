import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { UploadDownloadTemplate } from "features/DataUploads";
import { useTranslation } from "react-i18next";

const DataUploadsPage = () => {
  const { t } = useTranslation("dataUploads");

  return (
    <PageContent className="data-uploads">
      <h4 className="settings-header">{t("header")}</h4>

      <div className="content-container principal-list users-list">
        <UploadDownloadTemplate />
      </div>
    </PageContent>
  );
};

export default DataUploadsPage;
