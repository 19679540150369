import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { PageHeader, getAuthState } from "@redriver/cinnamon";
import { Logout } from "features/System";
import { AppRoutes } from "constants/routes";
import { SystemPageContent } from "components/pages";
import { setPersistentQueryParams } from "features/System/actions";

const LogoutPage = ({ loggedIn, location }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("system");

  useEffect(() => {
    dispatch(setPersistentQueryParams({}));
  }, [dispatch]);

  const nextRoute =
    location.state && location.state.nextPath
      ? location.state.nextPath
      : AppRoutes.Root;

  if (!loggedIn) {
    return <Redirect to={nextRoute} />;
  }

  return (
    <SystemPageContent>
      <PageHeader>{t("Sign_Out")}</PageHeader>
      <Logout />
      {!loggedIn && (
        <div style={{ marginTop: 20 }}>
          <Button primary as={Link} to={nextRoute}>
            {t("Continue")}
          </Button>
        </div>
      )}
    </SystemPageContent>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: getAuthState(state).loggedIn,
});

export default connect(mapStateToProps)(LogoutPage);
