import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "SETTINGS/PAPER_DEFAULTS";

export const ActionTypes = {
  GetPaperDefaults: `${NAMESPACE}/GET`,
  SetPaperDefaults: `${NAMESPACE}/SET`,
};

export const getPaperDefaults = ({ settingsId }) => {
  return settingsId
    ? apiGet(ActionTypes.GetPaperDefaults, `defaults/paper/${settingsId}`)
    : {};
};

export const setPaperDefaults = (defaults, { settingsId }) => {
  let paperCosts = [];
  const entries = Object.entries(defaults.paperCosts);
  entries.forEach(([key, value]) => {
    const parts = key.split("_");
    const paperSize = parts[0];
    const paperWeightId = parts[1];

    paperCosts.push({ paperWeightId, paperSize, value });
  });

  return apiPut(ActionTypes.SetPaperDefaults, `defaults/paper/${settingsId}`, {
    ...defaults,
    paperCosts,
  });
};
