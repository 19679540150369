import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  FormBuilder,
  Form,
  Content,
  ErrorMessage,
  getAuthClaims,
} from "@redriver/cinnamon";
import { Button, Message, Grid } from "semantic-ui-react";
import { requestPasswordChange } from "./actions";

const PasswordChange = ({ userEmail }) => {
  const { t } = useTranslation("system");
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  return (
    <FormBuilder
      submitAction={requestPasswordChange}
      submitParams={userEmail}
      onSubmit={() => setPasswordUpdated(false)}
      onSubmitted={() => setPasswordUpdated(true)}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { submitting, slowSubmitting, error },
        events
      ) => (
        <Content>
          <Form {...formProps}>
            <Grid>
              <Grid.Column width={5}>
                <Form.Password
                  field="oldPassword"
                  label={t("Old_Password")}
                  requiredError={t("Old_Password") + " " + t("requiredError")}
                  disabled={submitting && slowSubmitting}
                  fluid
                  required
                >
                  <input autoComplete="current-password" />
                </Form.Password>
              </Grid.Column>
              <Grid.Column width={5}>
                <Form.Password
                  field="newPassword"
                  label={t("New_Password")}
                  requiredError={t("New_Password") + " " + t("requiredError")}
                  disabled={submitting && slowSubmitting}
                  fluid
                  required
                  minLength={8}
                >
                  <input autoComplete="new-password" />
                </Form.Password>
              </Grid.Column>
              <Grid.Column width={6}>
                <Form.Password
                  field="confirmPassword"
                  label={t("Confirm_New_Password")}
                  requiredError={
                    t("Confirm_New_Password") + " " + t("requiredError")
                  }
                  disabled={submitting && slowSubmitting}
                  fluid
                  required
                  confirmField="newPassword"
                >
                  <input autoComplete="new-password" />
                </Form.Password>
              </Grid.Column>
            </Grid>
          </Form>
          <div>
            <br />
            <ErrorMessage
              header={t("Something_went_wrong")}
              error={error}
              overrideMessages={{
                911003: t("Incorrect_old_password"),
                911006: t("Invalid_new_credentials"),
              }}
              omitCodes
            />
            {passwordUpdated && (
              <React.Fragment>
                <br />
                <Message
                  success
                  header={t("Success")}
                  content={t("Your_password_has_been_updated")}
                />
              </React.Fragment>
            )}
            <div className="update-password">
              <Button
                primary
                icon="check"
                content={t("Update_Password")}
                onClick={() => events.onSubmit({ reset: ["data"] })}
                disabled={submitting}
                loading={submitting && slowSubmitting}
              />
            </div>
          </div>
        </Content>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  const { userEmail } = getAuthClaims(state);
  return { userEmail };
};

export default connect(mapStateToProps)(PasswordChange);
