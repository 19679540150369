import React from "react";
import classNames from "classnames";
import { Format } from "@redriver/cinnamon";
import { Image } from "semantic-ui-react";
import {
  PermissionCheck,
  UserTypeCheck,
  withCurrentUser,
} from "features/../../../shared/components/auth";
import EmptyCircle from "features/../../../shared/assets/icons/EmptyCircle.svg";
import CircleWithBorder from "features/../../../shared/assets/icons/CircleWithBorder.svg";
import Tick from "features/../../../shared/assets/icons/Tick.svg";
import Dash from "features/../../../shared/assets/icons/Dash.svg";
import {
  SheetSteps,
  UserType,
  SheetViewType,
} from "features/../../../shared/constants/enums";
import { Actions, Targets } from "constants/permissions";
import { connect } from "react-redux";
import { getSheetDataState } from "..";
import { AgreementStabilityOptionsString } from "features/../../../shared/constants/enums/AgreementStabilityOptions";
import { useTranslation } from "react-i18next";

const WizardNavigation = ({
  steps,
  activeStep,
  onGoToStep,
  pageView,
  currentUser,
  sheetViewType,
  impersonatedUser,
}) => {
  const { t } = useTranslation("sheetTranslation");

  return steps
    .filter((s) => s.key !== SheetSteps.All)
    .filter(
      (s) =>
        !s.restrictToInternalUsers ||
        (currentUser.userType === UserType.HQ &&
          sheetViewType === SheetViewType.IpsAdmin),
    )
    .filter(
      (s) =>
        !s.sheetViewTypes || s.sheetViewTypes.some((x) => x === sheetViewType),
    )
    .map((s) => (
      <div
        className={classNames(
          "wizard-tab",
          s.key,
          pageView && s === activeStep ? "active" : "",
        )}
        onClick={() => onGoToStep(s.key)}
        key={s.key}
      >
        <div className="wizard-tab-text">
          <strong>{s.title}</strong>
          <br />
          {s.contentType === "text" ? (
            <p>{s.content}</p>
          ) : s.contentType === "splitTotals" ? (
            <div className="split-totals">
              <Format.Number
                format={process.env.CURRENCY_CODE}
                value={s.content.salesperson || 0}
                className={`salesperson ${
                  s.content.salesperson < 0 ? "negative" : ""
                }`}
              />
              <PermissionCheck
                target={Targets.SheetAdmin}
                action={Actions.Edit}
              >
                {(sheetViewType === SheetViewType.IpsAdmin ||
                  sheetViewType === SheetViewType.SupplierAdmin) &&
                  impersonatedUser.canEditAdminFields && (
                    <Format.Number
                      format={process.env.CURRENCY_CODE}
                      value={s.content.dealerAdmin || 0}
                      className={`dealer-admin ${
                        s.content.dealerAdmin < 0 ? "negative" : ""
                      }`}
                    />
                  )}
              </PermissionCheck>
              <UserTypeCheck restrictTo={UserType.HQ}>
                {sheetViewType === SheetViewType.IpsAdmin && (
                  <Format.Number
                    format={process.env.CURRENCY_CODE}
                    value={s.content.ipsAdmin || 0}
                    className={`ips-admin ${
                      s.content.ipsAdmin < 0 ? "negative" : ""
                    }`}
                  />
                )}
              </UserTypeCheck>
            </div>
          ) : s.contentType === "num" ? (
            <p>{s.content} Items</p>
          ) : s.contentType == "none" ? (
            s.key == "scheduleStability" ? (
              <>
                {AgreementStabilityOptionsString.Option1 != s.content.option ? (
                  <>
                    {`${t(`Wizard_Navigation.${s.content.option}`)} - `}
                    <Format.Number
                      format={process.env.CURRENCY_CODE}
                      value={s.content.value}
                    />
                  </>
                ) : (
                  <div>{`${t(`Wizard_Navigation.${s.content.option}`)} - ${t(
                    "Wizard_Navigation.No_Adjustment",
                  )}`}</div>
                )}
              </>
            ) : null
          ) : (
            ""
          )}
        </div>

        {!s.isComplete && s === activeStep ? (
          <Image src={EmptyCircle} />
        ) : !s.isComplete && s !== activeStep ? (
          <Image src={CircleWithBorder} />
        ) : s.isComplete &&
          s.key === SheetSteps.PaperInclusive &&
          (s.hasContent === false || s.hasContent === null) &&
          s.content === 0 ? (
          <Image src={Dash} />
        ) : (
          <Image src={Tick} />
        )}
      </div>
    ));
};

const mapStateToProps = (state) => {
  const { impersonatedUser } = getSheetDataState(state);
  return { impersonatedUser };
};

export default connect(
  mapStateToProps,
  null,
)(withCurrentUser(WizardNavigation));
