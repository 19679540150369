import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";
import SheetStep from "../SheetStep";
import { Form } from "@redriver/cinnamon";
import {
  FunderType,
  UnlockFields,
} from "features/../../../shared/constants/enums";
import OverrideSection from "./OverrideSection";
import FunderRateTypeSection from "./FunderRateTypeSection";
import { Actions, Targets } from "constants/permissions";
import { withPermissions } from "features/../../../shared/components/auth";

const IPSyndicateRatesStep = ({
  sheetId,
  onNextStep,
  onPreviousStep,
  displayPrevious,
  displayNext,
  displaySave,
  collapsed,
  toggleVisibility,
  reactRef,
  calculating,
  customerToggle,
  displayCustomerToggle = false,
  hasPermission,
}) => {
  const { t } = useTranslation("ipsRates");

  const UnlockFieldOptions = [
    { value: UnlockFields.Yes, text: t("Yes") },
    { value: UnlockFields.No, text: t("No") },
  ];

  if (!hasPermission(Targets.Sheet, Actions.ViewIPSyndicateRates)) {
    return null;
  }

  return (
    <div ref={reactRef} className="step-container">
      <SheetStep
        title={t("IP_Syndicate_Rates_Settings")}
        onNextStep={onNextStep}
        onPreviousStep={onPreviousStep}
        displayPrevious={displayPrevious}
        displayNext={displayNext}
        displaySave={displaySave}
        collapsed={collapsed}
        toggleVisibility={toggleVisibility}
        calculating={calculating}
        sheetId={sheetId}
        displayCustomerToggle={displayCustomerToggle}
        customerToggle={customerToggle}
      >
        <Form.Object field="ipsRates">
          <div className="ips-rates-step">
            <Grid rows={3} stackable>
              <Grid.Row className="ips-rates-radio-group">
                <Grid.Column
                  mobile={8}
                  tablet={8}
                  computer={3}
                  className="override-radio-group"
                >
                  <Form.RadioGroup
                    inline
                    field="unlockDisableMinVolsLogic"
                    label={
                      <div className="override-radio-title">
                        <p>{t("Unlock_Disable_Min_Vols_Logic?")}</p>
                      </div>
                    }
                    options={UnlockFieldOptions}
                    className="required-field sheet-radio-group"
                  />
                </Grid.Column>{" "}
                <Grid.Column
                  mobile={8}
                  tablet={8}
                  computer={3}
                  className="override-radio-group"
                >
                  <Form.RadioGroup
                    inline
                    field="unlockAllowedTieredToner"
                    label={
                      <div className="override-radio-title">
                        <p>{t("Unlock_Allowed_Tiered_Toner?")}</p>
                      </div>
                    }
                    options={UnlockFieldOptions}
                    className="required-field sheet-radio-group"
                  />
                </Grid.Column>
                <Grid.Column
                  mobile={8}
                  tablet={8}
                  computer={3}
                  className="override-radio-group"
                >
                  <Form.RadioGroup
                    inline
                    field="unlockOverrideMaximumTieredToner"
                    label={
                      <div className="override-radio-title">
                        <p>{t("Unlock_Override_Maximum_Tiered_Toner?")}</p>
                      </div>
                    }
                    options={UnlockFieldOptions}
                    className="required-field sheet-radio-group"
                  />
                </Grid.Column>
                <div className="funder-type-rate-section">
                  <FunderRateTypeSection
                    funderType={FunderType.Funder}
                    header={t("Funder")}
                    className="funder"
                    UnlockFieldOptions={UnlockFieldOptions}
                  />

                  <FunderRateTypeSection
                    funderType={FunderType.Dealer}
                    header={t("Supplier")}
                    className="supplier"
                    UnlockFieldOptions={UnlockFieldOptions}
                  />

                  <FunderRateTypeSection
                    funderType={FunderType.CoFunded}
                    header={t("Co-Funded")}
                    className="co-funded"
                    UnlockFieldOptions={UnlockFieldOptions}
                  />
                </div>
              </Grid.Row>

              <Grid.Row columns={3}>
                <div className="override-section">
                  <OverrideSection
                    title={t("Funder_Overrides")}
                    className="funder"
                    funderType={FunderType.Funder}
                    header="Funder"
                  />

                  <OverrideSection
                    className="supplier"
                    title={t("Supplier_Overrides")}
                    funderType={FunderType.Dealer}
                    header="Supplier"
                  />

                  <OverrideSection
                    title={t("Co-Funded_Overrides")}
                    className="co-funded"
                    funderType={FunderType.CoFunded}
                    header="Co-Funded"
                  />
                </div>
              </Grid.Row>
            </Grid>
          </div>
        </Form.Object>
      </SheetStep>
    </div>
  );
};

export default withPermissions(IPSyndicateRatesStep);
