import React from "react";
import { Form } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";
import { withCurrentUser } from "features/../../../shared/components/auth";
import {
  UserArea,
  UserAreaDescriptions,
  UserType,
} from "features/../../../shared/constants/enums";
import { compose } from "redux";

const NoteForm = ({ userArea, formProps, currentUser }) => {
  const { t } = useTranslation("sheetHeader");

  let userAreaOptions = [];

  if (currentUser.userType === UserType.HQ) {
    userAreaOptions.push({
      value: UserArea.IpsAdmin,
      text: UserAreaDescriptions.IpsAdmin,
    });
  }
  if (
    userArea === UserArea.IpsAdmin ||
    userArea === UserArea.SupplierAdmin ||
    currentUser.userType === UserType.HQ
  ) {
    userAreaOptions.push({
      value: UserArea.SupplierAdmin,
      text: UserAreaDescriptions.SupplierAdmin,
    });
  }
  userAreaOptions.push(
    {
      value: UserArea.AccountManager,
      text: UserAreaDescriptions.AccountManager,
    },
  );

  if (currentUser.userType === UserType.HQ) {
    userAreaOptions.push(
      { value: UserArea.Funder, text: UserAreaDescriptions.Funder }
    );
  }

  return (
    <Form {...formProps} className="note-form highlighted-inputs">
      <Form.Dropdown
        field="userArea"
        options={userAreaOptions}
        required
        label={t("User_Applicable")}
        placeholder="select..."
        disabled={userArea == UserArea.AccountManager}
      />
      <Form.TextArea
        field="noteText"
        label={t("Note")}
        maxLength={1000}
        maxLengthError={t("Max_Note_Length_Error")}
        requiredError={t("Note") + " " + t("is_required")}
        fluid
        required
      />
    </Form>
  );
};

export default compose(withCurrentUser)(NoteForm);
