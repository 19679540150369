import React from "react";
import { ModalBuilder } from "@redriver/cinnamon";
import Remove from "features/../../../shared/assets/red-delete.svg";
import { IconButton } from "features/../../../shared/components/buttons";
import { TranslatedModal } from "features/../../../shared/components/modals";

const PaperStepCloseModal = ({ onSubmit, isDirty }) => {
    return (
        <ModalBuilder
            onSubmit={onSubmit}
            renderTrigger={(showModal) => <IconButton
                icon={Remove}
                className="icon-only-button"
                onClick={isDirty ? showModal : onSubmit}
                size="massive"
            />
            }
            renderModal={(modalProps) => (
                <TranslatedModal.Confirmation {...modalProps}>
                    Are you sure you want to close? Any changes will be lost.
                </TranslatedModal.Confirmation>
            )}
        />
    );
}

export default PaperStepCloseModal;
