import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { FormBuilder, Form } from "@redriver/cinnamon";
import FixedFieldType from "constants/forms/FixedFieldType";
import {
  ActionTypes as SheetActionTypes,
  LookupNames as SheetLookupNames,
} from "features/Sheets";
import { useTranslation } from "react-i18next";

export const CheckboxFields = {
  FixedValueSelected: "fixedValueSelected",
  NoFixing: "noFixing",
};

export const InputFields = {
  Fixed: "fixed",
};

export const checkboxFieldToFixType = (checkboxField) => {
  let mappings = {};
  mappings[CheckboxFields.FixedValueSelected] = FixedFieldType.Set;
  mappings[CheckboxFields.NoFixing] = FixedFieldType.None;

  return mappings[checkboxField];
};

export const inputFieldToCheckboxField = (inputField) => {
  let mappings = {};
  mappings[InputFields.Fixed] = CheckboxFields.FixedValueSelected;
  return mappings[inputField];
};

export const fixTypeToCheckboxField = (fixType) => {
  let mappings = {};
  mappings[FixedFieldType.Set] = CheckboxFields.FixedValueSelected;
  mappings[FixedFieldType.None] = CheckboxFields.NoFixing;

  return mappings[fixType];
};

export const fixTypeToInputFields = (fixType) => {
  let mappings = {};
  mappings[FixedFieldType.Set] = [InputFields.Fixed];
  mappings[FixedFieldType.None] = [];

  return mappings[fixType];
};

const clearAndSetCheckboxFields = (formData, fixedFieldTypeSelected) => {
  const fixType = checkboxFieldToFixType(fixedFieldTypeSelected);
  if (!fixType) return;

  Object.values(CheckboxFields).forEach((f) => {
    formData[f] = false;
  });
  formData[fixedFieldTypeSelected] = true;
  formData.fixType = fixType;
};

const onFormChange = (field, data, applyChanges, formProps) => {
  const { value, onChange } = formProps;

  let newFormData = applyChanges(value);

  // Update selected type if radio button selected
  if (Object.values(CheckboxFields).some((k) => k === field)) {
    clearAndSetCheckboxFields(newFormData, field);
  }

  // Update selected type if corresponding input amended
  if (Object.values(InputFields).some((k) => k === field)) {
    clearAndSetCheckboxFields(newFormData, inputFieldToCheckboxField(field));
  }

  onChange(field, newFormData, () => applyChanges(newFormData));
  return newFormData;
};

const getInitialData = (fixType, value) => {
  let formData = {};

  // Set the checkbox values
  Object.values(CheckboxFields).forEach((f) => {
    formData[f] = false;
  });
  formData[fixTypeToCheckboxField(fixType)] = true;

  // Set the input value
  formData[InputFields.Fixed] = value;

  return formData;
};

const getSubmitResponse = (formData) => {
  let response = {};
  response["isFixed"] = formData.fixedValueSelected;
  if (formData.fixedValueSelected) {
    response["value"] = formData.fixed;
  }
  return response;
};

/**
 * The modal-style popup for editing 'forced values' - fix type, fix value(s)
 */
const FixedServiceEditor = ({
  value,
  fixType,
  onSubmitted,
  toggleAction,
  customEditorData,
  disabled,
}) => {
  const { t } = useTranslation("sheetTranslation");
  const [frmData, setFrmData] = useState({});

  return (
    <FormBuilder
      disabled={disabled}
      initialData={getInitialData(fixType, value)}
      onSubmitted={() => onSubmitted(getSubmitResponse(frmData))}
      renderForm={(formProps, state, events) => (
        <div className="modal-styled-popup service">
          <div className="header">{t("FixedInput.ServiceEditor.Header")}</div>
          <div className="content">
            <Form
              {...formProps}
              className="highlighted-inputs"
              onChange={(field, data, applyChanges) => {
                const newFormData = onFormChange(
                  field,
                  data,
                  applyChanges,
                  formProps
                );
                setFrmData(newFormData);
              }}
            >
              <div className="fixed-fields-group">
                <div className="fixed-fields-row">
                  <div className="fixed-field-col">
                    <Form.Checkbox
                      radio
                      label={t("FixedInput.ServiceEditor.FixedValue")}
                      field={CheckboxFields.FixedValueSelected}
                    />
                  </div>
                  <div className="fixed-field-col">
                    <Form.Dropdown
                      field={InputFields.Fixed}
                      lookup={SheetLookupNames[SheetActionTypes.Services]}
                      lookupParams={{ sheetId: customEditorData.sheetId }}
                      search
                      searchSuggestions
                      alwaysShowSuggestionsOnBlur
                      fluid
                      unknownValueOptions={customEditorData.unknownValueOptions}
                      unknownValueText={customEditorData.unknownValueText}
                      selection
                    />
                  </div>
                </div>

                <div className="fixed-fields-row">
                  <div className="fixed-field-col">
                    <Form.Checkbox
                      radio
                      label={t("FixedInput.ServiceEditor.NoFixing")}
                      field={CheckboxFields.NoFixing}
                    />
                  </div>
                  <div className="fixed-field-col"></div>
                </div>
              </div>
            </Form>
          </div>
          <div className="actions">
            <Button
              negative
              content={t("FixedInput.ServiceEditor.Cancel")}
              onClick={toggleAction}
            />
            <Button
              positive
              icon="checkmark"
              content={t("FixedInput.ServiceEditor.Done")}
              floated="right"
              onClick={events.onSubmit}
              disabled={!state.formValid || disabled}
            />
          </div>
        </div>
      )}
    />
  );
};

FixedServiceEditor.propTypes = {
  fixType: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.number),
  onSubmit: PropTypes.func,
  toggleAction: PropTypes.func,
  fieldId: PropTypes.string,
};

export default FixedServiceEditor;
