import React from "react";
import { ModalBuilder, Table } from "@redriver/cinnamon";
import { Targets, Actions } from "constants/permissions";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { editNote } from "../actions";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";
import NoteForm from "./NoteForm";

const EditNote = ({
  as: As = Table.MenuItem,
  sheetId,
  onSubmitted,
  noteText,
  noteId,
  userArea,
  noteUserArea,
}) => {
  const { t } = useTranslation("sheetHeader");
  return (
    <PermissionCheck target={Targets.Sheet} action={Actions.Edit}>
      <ModalBuilder
        withForm
        initialData={{ noteText, userArea: noteUserArea }}
        submitAction={editNote}
        submitParams={{ sheetId, noteId }}
        onSubmitted={onSubmitted}
        renderTrigger={(showModal) => <As onClick={showModal}>{t("Edit")}</As>}
        renderModal={(modalProps, formProps) => (
          <TranslatedModal.Edit {...modalProps} header={t("Edit_Note")}>
            <NoteForm userArea={userArea} formProps={formProps} />
          </TranslatedModal.Edit>
        )}
      />
    </PermissionCheck>
  );
};

export default EditNote;
