export const claimExists = (claimData, target, action, any) => {
  if (!claimData.hasOwnProperty(target)) return false;
  const claimValue = claimData[target];
  const claimArray = Array.isArray(claimValue) ? claimValue : [claimValue];
  if (Array.isArray(action)) {
    const hasClaim = claim => action.includes(claim);
    return any ? claimArray.some(hasClaim) : claimArray.every(hasClaim);
  } else {
    return claimArray.includes(action);
  }
};
