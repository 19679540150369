import React from "react";
import Add from "features/../../../shared/assets/white-add.svg";
import IconButton from "./IconButton";

const AddButton = ({ content, onClick, ...props }) => (
  <IconButton
    {...props}
    icon={props.icon || Add}
    primary
    content={content}
    onClick={onClick}
  />
);

export default AddButton;
