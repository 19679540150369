import React from "react";
import { Grid } from "semantic-ui-react";
import { Form } from "@redriver/cinnamon";
import { PermissionsTree, UserAreaDropdown } from "../lookups";
import { UserArea } from "features/../../../shared/constants/enums";

const RoleForm = ({ formProps, editMode, userArea, userMode }) => (
  <Form {...formProps}>
    <Grid>
      <Grid.Column width={8}>
        <Form.Input
          field="name"
          label="Name"
          fluid
          required
          readOnly={userMode}
          placeholder="Template Name..."
        />
        <Form.Input
          field="description"
          label="Description"
          fluid
          readOnly={userMode}
          placeholder="Template Description..."
        />
        <UserAreaDropdown
          label="Type"
          field="userArea"
          lookupParams={{
            useRawNames: true,
            userType: null,
            userArea: userArea,
          }}
          required
          fluid
          placeholder="Select a type"
          disabled={editMode}
        />
        <Form.If condition={({ fields}) => fields.userArea === UserArea.SupplierAdmin}>
        <Form.Checkbox
          field="enableOfficeAccess"
          label="Enable Office Access"
        />       
        </Form.If> 
      </Grid.Column>
      <Grid.Column width={8}>
        <PermissionsTree
          field="permissionRefs"
          label="Permissions"
          required
          userArea={formProps.value.userArea} 
        />
      </Grid.Column>
    </Grid>
  </Form>
);

export default RoleForm;
