import React from "react";
import { Format } from "@redriver/cinnamon";

//Standard date format for Opal is (DD MMM YYYY)
export const dateFormatter = (value) => (
  <Format.Date value={value} format="DD MMM YYYY" />
);

export const DateTimeFormatter = (value) => (
  <Format.Date value={value} format="hh:mmA DD MMM YYYY" />
);

// TODO: Translate
export const yesNoFormatter = (value, singleDigit = false) => {
  return value === null
    ? "N/A"
    : value
    ? singleDigit
      ? "Y"
      : "Yes"
    : singleDigit
    ? "N"
    : "No";
};

export const blankDataFormatter = (value, nullValue) => {
  return value === null || value === "" ? nullValue : value;
};

export const numericFormatter = (
  value,
  zeroOverride = 0,
  decimalPlaces = 2,
  nullOverride = "",
  suffix = ""
) => {
  return value == 0 ? (
    zeroOverride == 0 ? (
      (0).toFixed(decimalPlaces)
    ) : (
      zeroOverride
    )
  ) : value == null ? (
    nullOverride
  ) : (
    <span className={value < 0 ? "red" : ""}>
      {value < 0 ? "(" : ""}
      <Format.Number
        value={Math.abs(value)}
        decimalPlaces={decimalPlaces}
        thousandSeparator
        suffix={suffix}
      />
      {value < 0 ? ")" : ""}
    </span>
  );
};
