import { apiGet, apiPut, mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULE_REQUESTS";

const ActionTypes = {
  GetCreditClearance: `${NAMESPACE}/GET_CREDIT_CLEARANCE`,
  RequestCreditClearance: `${NAMESPACE}/REQUEST_CREDIT_CLEARANCE`,
  GetVolumeRewrite: `${NAMESPACE}/GET_VOLUME_REWRITE`,
  RequestVolumeRewrite: `${NAMESPACE}/REQUEST_VOLUME_REWRITE`,
  GetReWriteAdditionalCapital: `${NAMESPACE}/GET_REWRITE_ADDITIONAL_CAPITAL`,
  RequestReWriteAdditionalCapital: `${NAMESPACE}/REQUEST_REWRITE_ADDITIONAL_CAPITAL`,
  OutstandingAgreementValue: `${NAMESPACE}/OUTSTANDING_AGREEMENT_VALUE`,
  RequestPartSettlement: `${NAMESPACE}/REQUEST_PART_SETTLEMENT`,
  GetPartSettlement: `${NAMESPACE}/GET_PART_SETTLEMENT`,
  RequestPartUpgrade: `${NAMESPACE}/REQUEST_PART_UPGRADE`,
  GetPartUpgrade: `${NAMESPACE}/GET_PART_UPGRADE`,
  RequestFullUpgrade: `${NAMESPACE}/REQUEST_FULL_UPGRADE`,
  GetFullUpgrade: `${NAMESPACE}/GET_FULL_UPGRADE`,
};

export const getCreditClearance = (scheduleId) =>
  apiGet(
    ActionTypes.GetCreditClearance,
    `schedules/${scheduleId}/credit-clearance`,
  );

export const requestCreditClearance = (formData, scheduleId) =>
  apiPut(
    ActionTypes.RequestCreditClearance,
    `schedules/${scheduleId}/credit-clearance`,
    {
      rrp: formData.schedule.rrp,
      grossSale: formData.schedule.grossSale,
      settlement: formData.schedule.settlement,
      comments: formData.schedule.comments,
      includeUpgrade: formData.schedule.includeUpgrade,
    },
  );

export const requestVolumeRewrite = (formData, scheduleId) =>
  apiPut(
    ActionTypes.RequestVolumeRewrite,
    `schedules/${scheduleId}/volume-rewrite`,
    formData,
  );

export const getVolumeRewrite = (scheduleId) =>
  process.env.TRADE_AS_IPS
    ? mockSuccess(ActionTypes.GetVolumeRewrite, {
        delay: 200,
        response: schedule,
      })
    : apiGet(
        ActionTypes.GetVolumeRewrite,
        `schedules/${scheduleId}/volume-rewrite`,
      );

export const getReWriteAdditionalCapital = (scheduleId) =>
  process.env.TRADE_AS_IPS
    ? mockSuccess(ActionTypes.GetReWriteAdditionalCapital, {
        delay: 200,
        response: schedule,
      })
    : apiGet(
        ActionTypes.GetReWriteAdditionalCapital,
        `schedules/${scheduleId}/rewrite-additional-capital`,
      );

export const requestReWriteAdditionalCapital = (formData, scheduleId) => {
  let toSend = {
    applicableFromPrevious: formData.applicableFromPrevious,
    applicableFromNext: formData.applicableFromNext,
    comments: formData.comments,
    units: formData.units,
  };
  return apiPut(
    ActionTypes.RequestReWriteAdditionalCapital,
    `schedules/${scheduleId}/rewrite-additional-capital`,
    toSend,
  );
};

export const requestOutstandingAgreementValue = (scheduleId) =>
  apiPut(
    ActionTypes.OutstandingAgreementValue,
    `schedules/${scheduleId}/outstanding-agreement-value`,
  );

export const getPartSettlement = (scheduleId) =>
  process.env.TRADE_AS_IPS
    ? mockSuccess(ActionTypes.GetPartSettlement, {
        delay: 200,
        response: schedule,
      })
    : apiGet(
        ActionTypes.GetPartSettlement,
        `schedules/${scheduleId}/part-settlement`,
      );

export const requestPartSettlement = (formData, scheduleId) => {
  return apiPut(
    ActionTypes.RequestPartSettlement,
    `schedules/${scheduleId}/part-settlement`,
    formData,
  );
};

export const getPartUpgrade = (scheduleId) =>
  process.env.TRADE_AS_IPS
    ? mockSuccess(ActionTypes.GetPartUpgrade, {
        delay: 200,
        response: schedule,
      })
    : apiGet(
        ActionTypes.GetPartUpgrade,
        `schedules/${scheduleId}/part-upgrade`,
      );

export const requestPartUpgrade = (formData, scheduleId) => {
  return apiPut(
    ActionTypes.RequestPartUpgrade,
    `schedules/${scheduleId}/part-upgrade`,
    formData,
  );
};

export const getFullUpgrade = (scheduleId) =>
  process.env.TRADE_AS_IPS
    ? mockSuccess(ActionTypes.GetFullUpgrade, {
        delay: 200,
        response: schedule,
      })
    : apiGet(
        ActionTypes.GetFullUpgrade,
        `schedules/${scheduleId}/full-upgrade`,
      );

export const requestFullUpgrade = (formData, scheduleId) =>
  apiPut(
    ActionTypes.GetCreditClearance,
    `schedules/${scheduleId}/full-upgrade`,
    formData,
  );

const schedule = {
  id: 1,
  startDate: "2020-10-17",
  backToStart: false,
  applicableFromPrevious: false,
  applicableFromNext: false,
  comments: "",
  creditAvailable: 10000,
  units: [
    {
      id: "a98d8317-5752-46a7-b43e-01b620c1514c",
      unit: "B1",
      description: "Black Unit",
      agreedTmvsaMin: 120000,
      suggestTmvsaMin: 150000,
      keepAgreedTmvsaMin: false,
    },
    {
      id: "29b4c402-75c3-4fcc-9495-907bb30e0026",
      unit: "C1",
      description: "Coloured Unit",
      agreedTmvsaMin: 230000,
      suggestTmvsaMin: 360000,
      keepAgreedTmvsaMin: false,
    },
    {
      id: "9d32049c-a57f-4ae7-b940-2933222a4942",
      unit: "S1",
      description: "Scan Unit",
      agreedTmvsaMin: 200,
      suggestTmvsaMin: 1500,
      keepAgreedTmvsaMin: false,
    },
    {
      id: "7a12b414-760c-41c2-9d18-37f12c0a09df",
      unit: "L1",
      description: "ml of ink used to print",
      agreedTmvsaMin: 100,
      suggestTmvsaMin: 150,
      keepAgreedTmvsaMin: false,
    },
    {
      id: "93e17ca9-1bb7-4a5f-98ef-b26edf1494bf",
      unit: "M1",
      description: "ml of ink used in service cycle",
      agreedTmvsaMin: 100,
      suggestTmvsaMin: 150,
      keepAgreedTmvsaMin: false,
    },
    {
      id: "95548ad3-d580-4414-a94b-bb8f331b7bf0",
      unit: "P1",
      description: "1542mm wide paper per M2",
      agreedTmvsaMin: 100,
      suggestTmvsaMin: 150,
      keepAgreedTmvsaMin: false,
    },
    {
      id: "c705c887-646c-42a6-8ceb-855bc71d6278",
      unit: "M1",
      description: "ml of ink used in service cycle",
      agreedTmvsaMin: 100,
      suggestTmvsaMin: 150,
      keepAgreedTmvsaMin: false,
    },
    {
      id: "6f01ffe3-4fd1-4411-a58f-c984816a5379",
      unit: "P1",
      description: "1542mm wide paper per M2",
      agreedTmvsaMin: 100,
      suggestTmvsaMin: 150,
      keepAgreedTmvsaMin: false,
    },
  ],
  products: [
    {
      id: "70c621ad-699f-4ea8-8d9a-fe3a5e3859ce",
      model: "KONICA MINOLTA C3001",
      identifierNumber: "AA22M569845625",
      postcode: "K119 1LS",
      retain: true,
    },
    {
      id: "06ad5090-09fe-4bf9-8265-0db045896f4d",
      model: "KYCERA TA C456",
      identifierNumber: "APOKDF029FNMFLK",
      postcode: "K119 1LS",
      retain: false,
    },
    {
      id: "90402cc6-24cd-4c6f-b1b6-96bef1591fe8",
      model: "KYCERA TA C5801",
      identifierNumber: "39034FKSKIIMFSOM",
      postcode: "K119 1LS",
      retain: false,
    },
    {
      id: "035f1d0b-8c9f-40f6-82b8-da72f7ba57df",
      model: "CANON TX-3000",
      identifierNumber: "A3838329FJND",
      postcode: "K119 1LS",
      retain: false,
    },
    {
      id: "12c7e9fa-241c-4407-b212-5784c06e27e8",
      model: "CANON TX-3000",
      identifierNumber: "47645674GDGGRER",
      postcode: "K119 1LS",
      retain: false,
    },
    {
      id: "e8b9fa60-96bc-485c-8d28-9c6e5a28a19e",
      model: "PAPERCUT",
      identifierNumber: null,
      postcode: "K119 1LS",
      retain: false,
    },
  ],
};
