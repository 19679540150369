import React from "react";
import MobileTopBar from "./MobileTopBar";

const MobileWrapper = ({ isSmallScreen, children }) => (
  <div className="mobile-wrapper">
    <MobileTopBar active={isSmallScreen}>{children}</MobileTopBar>
  </div>
);

export default MobileWrapper;
