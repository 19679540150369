import React from "react";
import { Form } from "@redriver/cinnamon";
import { DealersTypeAheadLookup } from "features/../../../shared/features/Dealers/lookups";
import { TimezonesDropdownLookup } from "features/../../../shared/features/System/lookups";
import { useTranslation } from "react-i18next";

const DealerOfficeForm = ({
  dealerReadOnly = true,
  className,
  ...formProps
}) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <Form {...formProps}>
        <Form.Group widths={3}>
          <Form.Input
            field="name"
            label={t("supplierOffices:dealerOfficeForm:name")}
            fluid
            required
            placeholder={t("supplierOffices:dealerOfficeForm:namePlaceholder")}
          />
          <Form.Input
            field="legalEntityName"
            label={t("supplierOffices:dealerOfficeForm:legalEntityName")}
            fluid
            placeholder={t(
              "supplierOffices:dealerOfficeForm:legalEntityNamePlaceholder"
            )}
          />
          <DealersTypeAheadLookup
            field="dealerId"
            label={t("supplierOffices:dealerOfficeForm:supplierHeadOffice")}
            fluid
            required
            placeholder={t(
              "supplierOffices:dealerOfficeForm:supplierHeadOfficePlaceholder"
            )}
            textField="dealerName"
            disabled={dealerReadOnly}
          />
        </Form.Group>
        <Form.Group widths={3}>
          <Form.Input
            field="contactPhone"
            label={t("supplierOffices:dealerOfficeForm:contactPhone")}
            fluid
            placeholder={t(
              "supplierOffices:dealerOfficeForm:contactPhonePlaceholder"
            )}
          />
          <Form.Email
            field="contactEmail"
            label={t("supplierOffices:dealerOfficeForm:contactEmail")}
            fluid
            placeholder={t(
              "supplierOffices:dealerOfficeForm:contactEmailPlaceholder"
            )}
          />
          <TimezonesDropdownLookup
            field="timezone"
            label={t("supplierOffices:dealerOfficeForm:timezone")}
            fluid
            placeholder={t(
              "supplierOffices:dealerOfficeForm:timezonePlaceholder"
            )}
            required
            //renderReadOnly={props => props.timezone || "-"}
          />
          <Form.Input
            field="code"
            label={t("supplierOffices:dealerOfficeForm:code")}
            maxLength={20}
            fluid
            required
            placeholder={t("supplierOffices:dealerOfficeForm:codePlaceholder")}
            renderReadOnly={(props) => `${props.code || "-"}`}
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export default DealerOfficeForm;
