import React from "react";
import { isNil } from "lodash";
import { Form } from "@redriver/cinnamon";
import { UserStatus, UserStatusText, UserStatusOptions } from "features/../../../shared/constants/enums";
import { FieldNames } from "./UsersFilterSidePanel";

const FieldMappings = {
  userStatuses: "Status",
  dealerId: "Supplier"
};

const FormatDisplay = item => {
  let value = item.value;

  switch (item.label) {
    case FieldNames.UserStatuses:
      value = Array.isArray(item.value)
        ? `${item.value.map(v => UserStatusText[v]).join(", ")}`
        : "";
      break;
    case FieldNames.CompanyId:
      return FieldMappings.dealerId;
  }

  return `${FieldMappings[item.label] || item.label}: ${value}`;
};

const UsersFilterDisplay = ({ filters, formProps, companyId }) => {
  const items = filters
    ? Object.keys(filters)
      .filter(
      f =>
        f !== "search" &&
        f !== "userType" &&
        f !== "dealer" &&
        f !== "sortDirection" && 
        f !== "sortName" &&
        (!companyId || f !== FieldNames.CompanyId) &&
        !isNil(filters[f]) &&
        filters[f].length
      )
      .map(k => {
        return {
          label: k,
          value: filters[k]
        };
      })
    : [];

  if (!items.length) return null;

  return (
    <div className="filter-display">
      {items
        .filter(i => !isNil(i.value) && i.value != "")
        .map(item => (
          <div className="filter-display-item" key={item.label}>
            {FormatDisplay(item)}
          </div>
        ))}
      <Form {...formProps}>
        <Form.Clearer
          field={Object.values(FieldNames)}
          as={props => (
            <div {...props} className="clear-button">
              (clear)
            </div>
          )}
        />
      </Form>
    </div>
  );
};

export default UsersFilterDisplay;
