import React from "react";
import { compose } from "redux";
import { Form, Format } from "@redriver/cinnamon";
import classNames from "classnames";
import { IconButton } from "features/../../../shared/components/buttons";
import { Targets, Actions } from "constants/permissions";
import {
  SettlementFieldType,
  FunderType,
  UserType,
  SheetViewType,
} from "features/../../../shared/constants/enums";
import {
  PermissionCheck,
  UserTypeCheck,
  withCurrentUser,
} from "features/../../../shared/components/auth";
import {
  newFixedInput,
  newFixedString,
  SectionTotal,
  FixedInputResponsiveLabel,
  FixedInput,
} from "components/forms";

import SettlementArray from "./SettlementArray";
import CoTerminusArray from "./CoTerminusArray";
import AdminAdd from "features/../../../shared/assets/green-add.svg";
import Add from "features/../../../shared/assets/black-add.svg";
import IPAdd from "features/../../../shared/assets/ips-add-cross.svg";
import { getSheetDataState, getSheetRatesState } from "features/Sheets";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const FundingSection = ({
  title,
  total,
  type,
  totalCosts,
  settlementLength,
  adminSettlementLength,
  ipSettlementLength,
  maxProductCapitalAllowed,
  calculating,
  sheetDetailsSaving,
  rrpRequired,
  className,
  sheetViewType,
  impersonatedUser,
  ipCoTerminusRentalsLength,
  coTerminusRentalsLength,
  defaultYieldPercentages,
  supplierFinanceType,
  paymentMethod,
  supplierCoFundAvailable,
  fundersSettings,
  preventUpdates,
  userArea,
  currentUser,
}) => {
  const hideFixedIndicator = !impersonatedUser.canEditAdminFields;
  const { t } = useTranslation("capitalCalc");

  const funderTypeDefaults = fundersSettings.find((x) => x.type == type);

  return (
    <div className={"funder-type-details " + className}>
      <h5>{title}</h5>
      <div className="funder-amounts">
        <FixedInput
          label={
            <FixedInputResponsiveLabel
              label={t("fundingSection.financeSelection")}
              required
            />
          }
          inline
          field="financeSelection"
          fluid
          placeholder={t("fundingSection.financeSelectionPlaceholder")}
          className="finance-selection"
          decimalPlaces={0}
          hideIndicator={hideFixedIndicator}
          minAllowValue={funderTypeDefaults?.vMinSelection ?? 0}
          maxAllowValue={
            currentUser.userType == UserType.HQ
              ? funderTypeDefaults?.vMaxSelection
              : funderTypeDefaults.maxSelection
          }
          defaultValue={funderTypeDefaults.defaultQtrSelection}
          required
        />
        {type == FunderType.Dealer && (
          <PermissionCheck target={Targets.SheetAdmin} action={Actions.Edit}>
            {(sheetViewType === SheetViewType.SupplierAdmin ||
              sheetViewType === SheetViewType.IpsAdmin) && (
              <React.Fragment>
                <FixedInput
                  label={
                    <FixedInputResponsiveLabel
                      label="% of Yield"
                      className="not-bold"
                    />
                  }
                  inline={true}
                  field="ipSyndicatePercentageYield"
                  fluid
                  formatNumber
                  minAllowValue={0}
                  hideIndicator={hideFixedIndicator}
                />
                <Form.Trigger
                  action="change"
                  event="onChange"
                  field="financeSelection"
                  target="ipSyndicatePercentageYield"
                  value={(formState) => {
                    const defaultYieldPercentage = defaultYieldPercentages.find(
                      (x) =>
                        x.quarter == formState.fields.financeSelection.value &&
                        x.paymentMethod == paymentMethod &&
                        x.financeType == supplierFinanceType,
                    );
                    return {
                      ...formState.fields.ipSyndicatePercentageYield,
                      value:
                        defaultYieldPercentage != null
                          ? defaultYieldPercentage.yieldPercentage
                          : formState.fields.ipSyndicatePercentageYield.value,
                    };
                  }}
                />
              </React.Fragment>
            )}
          </PermissionCheck>
        )}
        {type == FunderType.CoFunded && (
          <PermissionCheck target={Targets.SheetAdmin} action={Actions.Edit}>
            {(sheetViewType === SheetViewType.SupplierAdmin ||
              sheetViewType === SheetViewType.IpsAdmin) && (
              <FixedInput
                label={
                  <FixedInputResponsiveLabel
                    className="not-bold"
                    label="Supplier %"
                  />
                }
                inline={true}
                field="coFundedDealerPercentage"
                fluid
                formatNumber
                minAllowValue={0}
                defaultValue={funderTypeDefaults.supplierCoFund}
                hideIndicator={hideFixedIndicator}
                disabled={
                  !(
                    supplierCoFundAvailable &&
                    sheetViewType == SheetViewType.IpsAdmin
                  )
                }
              />
            )}
          </PermissionCheck>
        )}
      </div>
      <div className="funder-persistent">
        <FixedInput
          label={
            <FixedInputResponsiveLabel
              label={t("fundingSection.rrp")}
              required={rrpRequired}
              className="funder-persistent-field-details"
            />
          }
          field="rrp"
          fluid
          formatNumber
          currency
          className="funder-persistent-details large rrp"
          placeholder={t("fundingSection.rrpPlaceholder")}
          minAllowValue={0}
          defaultValue={funderTypeDefaults.defaultRrp}
          hideIndicator={hideFixedIndicator}
          required={rrpRequired}
        />
        <FixedInput
          label={
            <FixedInputResponsiveLabel
              label={t("fundingSection.productCapitalCosts")}
              className="funder-persistent-field-details"
              labelClassName="not-bold"
            />
          }
          field="productCapitalCosts"
          fluid
          currency
          formatNumber
          className="funder-persistent-details large"
          minAllowValue={0}
          defaultValue={funderTypeDefaults.defaultProdCapCost}
          hideIndicator={hideFixedIndicator}
        />
        <FixedInput
          label={
            <FixedInputResponsiveLabel
              label={t("fundingSection.otherCosts")}
              className="funder-persistent-field-details"
              labelClassName="not-bold"
            />
          }
          field="otherCosts"
          fluid
          formatNumber
          currency
          className="funder-persistent-details large"
          minAllowValue={0}
          defaultValue={0}
          hideIndicator={hideFixedIndicator}
        />
      </div>
      <div className="rrp-fields flex">
        <div className="max-allowed">
          <div className="max-allowed-label">
            {t("fundingSection.maximumRRPAllowance")}
          </div>
          <div
            className={classNames(
              "max-allowed-value calculable-field",
              calculating && "value-loading",
            )}
          >
            {maxProductCapitalAllowed == null ? (
              "-"
            ) : (
              <Format.Number
                value={maxProductCapitalAllowed}
                format={process.env.CURRENCY_CODE}
              />
            )}
          </div>
        </div>
        <div className="total-costs">
          <div className="total-costs-label">
            {t("fundingSection.totalCosts")}
          </div>
          <div className="total-costs-value">
            <Format.Number
              value={totalCosts || 0}
              format={process.env.CURRENCY_CODE}
            />
          </div>
        </div>
      </div>
      <div className="funder-amounts"></div>
      <section className="settlements">
        <p className="bold">{t("fundingSection.settlements")}</p>
        <div className="capital-section-title">
          <Form.ArrayAdder
            field="settlements"
            arrayKey="id"
            compact
            className="wizard-title-button not-bold supplier-button"
            as={IconButton}
            icon={Add}
            content={t("fundingSection.addSettlement")}
            populate={() => ({
              id: Date.now(),
              value: newFixedInput(),
              ref: newFixedString(),
              fieldType: SettlementFieldType.Standard,
              funderType: type,
            })}
            disabled={sheetDetailsSaving || preventUpdates}
          />
          <PermissionCheck target={Targets.SheetAdmin} action={Actions.Edit}>
            {(sheetViewType === SheetViewType.IpsAdmin ||
              sheetViewType === SheetViewType.SupplierAdmin) &&
              impersonatedUser.canEditAdminFields && (
                <Form.ArrayAdder
                  field="adminSettlements"
                  arrayKey="id"
                  compact
                  className="wizard-title-button admin-button not-bold new-settlement"
                  as={IconButton}
                  icon={AdminAdd}
                  content={t("fundingSection.supplierSettlement")}
                  populate={() => ({
                    id: Date.now(),
                    value: newFixedInput(),
                    ref: newFixedString(),
                    fieldType: SettlementFieldType.Admin,
                    funderType: type,
                  })}
                  disabled={sheetDetailsSaving || preventUpdates}
                />
              )}
          </PermissionCheck>
          <UserTypeCheck restrictTo={UserType.HQ}>
            {sheetViewType === SheetViewType.IpsAdmin && (
              <Form.ArrayAdder
                field="ipSettlements"
                arrayKey="id"
                compact
                className="wizard-title-button ip-button not-bold new-settlement"
                as={IconButton}
                icon={IPAdd}
                content={t("fundingSection.ipsSettlement")}
                populate={() => ({
                  id: Date.now(),
                  value: newFixedInput(),
                  ref: newFixedString(),
                  fieldType: SettlementFieldType.IP,
                  funderType: type,
                })}
                disabled={sheetDetailsSaving || preventUpdates}
              />
            )}
          </UserTypeCheck>
        </div>
        <div className="settlement-array">
          <SettlementArray
            field="settlements"
            settlementLength={settlementLength}
            sheetDetailsSaving={sheetDetailsSaving}
            preventUpdates={preventUpdates}
            userArea={userArea}
          />
          <PermissionCheck target={Targets.SheetAdmin} action={Actions.Edit}>
            {(sheetViewType === SheetViewType.IpsAdmin ||
              sheetViewType === SheetViewType.SupplierAdmin) &&
              impersonatedUser.canEditAdminFields && (
                <SettlementArray
                  field="adminSettlements"
                  settlementLength={adminSettlementLength}
                  sheetDetailsSaving={sheetDetailsSaving}
                  preventUpdates={preventUpdates}
                  className="green-text"
                  userArea={userArea}
                />
              )}
          </PermissionCheck>
          <UserTypeCheck restrictTo={UserType.HQ}>
            {sheetViewType === SheetViewType.IpsAdmin && (
              <SettlementArray
                field="ipSettlements"
                settlementLength={ipSettlementLength}
                sheetDetailsSaving={sheetDetailsSaving}
                preventUpdates={preventUpdates}
                className="ips-color-text"
                userArea={userArea}
              />
            )}
          </UserTypeCheck>
        </div>
      </section>
      <SectionTotal
        label={t("fundingSection.totalCapitalInvestment")}
        className="capital-totaliser"
      >
        <Format.Number
          format={process.env.CURRENCY_CODE}
          value={total}
          className={classNames("totaliser-value", total < 0 && "negative")}
        />
      </SectionTotal>
      <PermissionCheck target={Targets.SheetAdmin} action={Actions.Edit}>
        {impersonatedUser.canEditAdminFields && (
          <React.Fragment>
            <div className="capital-section-title">
              {(sheetViewType === SheetViewType.IpsAdmin ||
                sheetViewType === SheetViewType.SupplierAdmin) && (
                <Form.ArrayAdder
                  field="coTerminusRentals"
                  arrayKey="id"
                  className="wizard-title-button admin-button not-bold new-cfa"
                  as={IconButton}
                  icon={AdminAdd}
                  content={(function () {
                    switch (type) {
                      case FunderType.Dealer:
                        return t("fundingSection.supplierCFAPayment");
                      case FunderType.CoFunded:
                        return t("fundingSection.cofundedCFAPayment");
                      default:
                        return t("fundingSection.funderCFAPayment");
                    }
                  })()}
                  populate={(k) => ({
                    [k]: Date.now(),
                    value: newFixedInput(),
                    agreementReference: newFixedString(),
                    minimumQuarters: newFixedInput(),
                    fieldType: "Admin",
                    funderType: type,
                  })}
                  disabled={sheetDetailsSaving || preventUpdates}
                />
              )}
              <UserTypeCheck restrictTo={UserType.HQ}>
                {sheetViewType === SheetViewType.IpsAdmin && (
                  <Form.ArrayAdder
                    field="ipCoTerminusRentals"
                    arrayKey="id"
                    className="wizard-title-button ip-button not-bold new-cfa"
                    as={IconButton}
                    icon={IPAdd}
                    content={t("fundingSection.ipsCFAPayment")}
                    populate={(k) => ({
                      [k]: Date.now(),
                      value: newFixedInput(),
                      agreementReference: newFixedString(),
                      minimumQuarters: newFixedInput(),
                      fieldType: "IP",
                      funderType: type,
                    })}
                    disabled={sheetDetailsSaving || preventUpdates}
                  />
                )}
              </UserTypeCheck>
            </div>
            <section className="coterminus-rentals">
              <PermissionCheck
                target={Targets.SheetAdmin}
                action={Actions.Edit}
              >
                {(sheetViewType === SheetViewType.IpsAdmin ||
                  sheetViewType === SheetViewType.SupplierAdmin) &&
                  impersonatedUser.canEditAdminFields && (
                    <React.Fragment>
                      {ipCoTerminusRentalsLength + coTerminusRentalsLength !=
                        0 && (
                        <div className="CFA-headers">
                          <div className="payment">
                            {t("fundingSection.payment")}
                          </div>
                          <div className="cfa">
                            {t("fundingSection.cfaReference")}
                          </div>
                          <div className="min">
                            {t("fundingSection.minQtrs")}
                          </div>
                        </div>
                      )}
                      <CoTerminusArray
                        field="coTerminusRentals"
                        sheetDetailsSaving={sheetDetailsSaving}
                        className="green-text"
                        backgroundClassName="green"
                        userArea={userArea}
                      />
                      <UserTypeCheck restrictTo={UserType.HQ}>
                        {sheetViewType === SheetViewType.IpsAdmin && (
                          <CoTerminusArray
                            field="ipCoTerminusRentals"
                            className="ips-color-text"
                            sheetDetailsSaving={sheetDetailsSaving}
                            backgroundClassName="blue"
                            userArea={userArea}
                          />
                        )}
                      </UserTypeCheck>
                    </React.Fragment>
                  )}
              </PermissionCheck>
            </section>
          </React.Fragment>
        )}
      </PermissionCheck>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { impersonatedUser, defaults, preventUpdates } =
    getSheetDataState(state);
  const { paymentMethod, dealerFmv } = getSheetRatesState(state);

  return {
    impersonatedUser,
    preventUpdates,
    defaultYieldPercentages: defaults.defaultYieldPercentages,
    paymentMethod,
    supplierFinanceType: dealerFmv.financeType,
    fundersSettings: defaults.funderTypeSettings,
  };
};

export default compose(
  connect(mapStateToProps, null),
  withCurrentUser,
)(FundingSection);
