import React from "react";
import { useDispatch } from "react-redux";
import { ModalBuilder, Form, notifySuccess } from "@redriver/cinnamon";
import { getCreditClearance, requestCreditClearance } from "./actions";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { Actions, Targets } from "constants/permissions";
import { PermissionCheck } from "features/../../../shared/components/auth";

const CreditClearance = ({ as: As, scheduleId, disabled }) => {
  const { t } = useTranslation("agreements", { keyPrefix: "ScheduleRow" });
  const { t: tcc } = useTranslation("agreements", {
    keyPrefix: "ScheduleRow.CreditClearance",
  });
  const dispatch = useDispatch();

  return (
    <PermissionCheck action={Actions.SubmitRequest} target={Targets.Schedules}>
      <ModalBuilder
        withForm
        loadAction={getCreditClearance}
        loadParams={scheduleId}
        submitAction={requestCreditClearance}
        submitParams={scheduleId}
        onSubmitted={() =>
          dispatch(notifySuccess(t("Actions.Submitted_Successfully")))
        }
        renderTrigger={(showModal) => (
          <As onClick={showModal} disabled={disabled}>
            {t("Actions.Credit_Clearance")}
          </As>
        )}
        renderModal={(modalProps, formProps) => (
          <TranslatedModal
            {...modalProps}
            header={t("CreditClearance.Header")}
            submitLabel={t("CreditClearance.Submit_Label")}
            className="credit-clearance"
          >
            <Form {...formProps}>
              <Form.Object field="client">
                <Form.Group className="grid two-col top">
                  <Form.Input
                    field="name"
                    label={tcc("Form.CustomerName_Label")}
                    inline
                    readOnly
                  />
                  <Form.Input
                    field="companyRegNo"
                    label={tcc("Form.CoRegNo_Label")}
                    inline
                    readOnly
                  />
                </Form.Group>

                <Form.Group className="address">
                  <Form.Input
                    field="address"
                    label={tcc("Form.Address_Label")}
                    fluid
                    inline
                    readOnly
                  />
                </Form.Group>

                <Form.Group className="grid two-col contacts">
                  <Form.Input
                    field="contactName"
                    label={tcc("Form.Contact_Label")}
                    inline
                    readOnly
                  />
                  <Form.Input
                    field="email"
                    label={tcc("Form.Email_Label")}
                    inline
                    readOnly
                  />
                  <Form.Input
                    field="phone"
                    label={tcc("Form.Phone_Label")}
                    inline
                    readOnly
                  />
                  <Form.Input
                    field="website"
                    label={tcc("Form.Website_Label")}
                    inline
                    readOnly
                  />
                </Form.Group>
              </Form.Object>

              <hr />

              <Form.Object field="schedule">
                <Form.Group className="grid two-col schedule">
                  <Form.Input
                    field="projectName"
                    label={tcc("Form.ScheduleProjectName_Label")}
                    inline
                    readOnly
                  />
                  <Form.Input
                    field="scheduleReference"
                    label={tcc("Form.ScheduleAgreementRef_Label")}
                    inline
                    readOnly
                  />
                </Form.Group>

                <Form.Group className="input-values grid three-col">
                  <Form.Numeric
                    field="rrp"
                    label={tcc("Form.Rrp_Label")}
                    required
                    inline
                    formatValue={true}
                    decimalPlaces={2}
                    minValue={0}
                    subLabel={process.env.MAJOR_CURRENCY}
                    subLabelPosition="left"
                  />
                  <Form.Numeric
                    field="grossSale"
                    label={tcc("Form.GrossSale_Label")}
                    required
                    inline
                    formatValue={true}
                    decimalPlaces={2}
                    minValue={0}
                    subLabel={process.env.MAJOR_CURRENCY}
                    subLabelPosition="left"
                  />
                  <Form.Numeric
                    field="settlement"
                    label={tcc("Form.Settlement_Label")}
                    required
                    inline
                    formatValue={true}
                    decimalPlaces={2}
                    minValue={0}
                    subLabel={process.env.MAJOR_CURRENCY}
                    subLabelPosition="left"
                  />
                </Form.Group>
                <Form.Checkbox
                  field="includeUpgrade"
                  label={tcc("Form.Include_Upgrade_Label")}
                  fluid
                  className="include-upgrade"
                />
                <Form.TextArea
                  field="comments"
                  label={tcc("Form.Comments_Label")}
                  width="100%"
                />
              </Form.Object>
            </Form>
          </TranslatedModal>
        )}
      />
    </PermissionCheck>
  );
};

export default CreditClearance;
