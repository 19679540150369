import React from "react";
import { Step } from "semantic-ui-react";
import { Wizard } from "@redriver/cinnamon";
import { FunderType } from "features/../../../shared/constants/enums";
import { useTranslation } from "react-i18next";
import FunderCFA from "./FunderCFA";
import DealerCFA from "./DealerCFA";
import CoFundedCFA from "./CoFundedCFA";

const FunderTypeTabs = ({ data }) => {
  const { t } = useTranslation("capitalCalc", {
    keyPrefix: "infoModal.funderTypes",
  });

  let steps = [
    { key: FunderType.Funder, titleKey: "funder" },
    { key: FunderType.Dealer, titleKey: "supplier" },
    { key: FunderType.CoFunded, titleKey: "cofunded" },
  ];

  const funderData = data?.funderSummaries?.find(
    x => x.funderType == FunderType.Funder
  );
  const dealerData = data?.funderSummaries?.find(
    x => x.funderType == FunderType.Dealer
  );
  const coFundedData = data?.funderSummaries?.find(
    x => x.funderType == FunderType.CoFunded
  );

  //only show active tabs.
  if (!funderData) steps = steps.filter(x => x.key != FunderType.Funder);

  if (!dealerData) steps = steps.filter(x => x.key != FunderType.Dealer);

  if (!coFundedData) steps = steps.filter(x => x.key != FunderType.CoFunded);

  const NavigationControls = ({ steps, activeStep, onGoToStep }) => (
    <Step.Group ordered={false}>
      {steps.map(step => (
        <Step
          key={step.key}
          active={step === activeStep}
          content={t(step.titleKey)}
          onClick={() => onGoToStep(step.key)}
        />
      ))}
    </Step.Group>
  );

  return (
    <div className="funder-types">
      <Wizard steps={steps}>
        <Wizard.Nav component={NavigationControls} />
        <Wizard.Step
          step={FunderType.Funder}
          render={() => <FunderCFA breakdown={funderData} />}
        />
        <Wizard.Step
          step={FunderType.Dealer}
          render={() => <DealerCFA breakdown={dealerData} />}
        />
        <Wizard.Step
          step={FunderType.CoFunded}
          render={() => <CoFundedCFA breakdown={coFundedData} />}
        />
      </Wizard>
    </div>
  );
};

export default FunderTypeTabs;
