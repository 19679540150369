import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "SETTINGS/DEFAULTS";

export const ActionTypes = {
  GetDefaults: `${NAMESPACE}/GET`,
  SetDefaults: `${NAMESPACE}/SET`,
};

export const getDefaults = ({ settingsId }) =>
  apiGet(ActionTypes.GetDefaults, `defaults/${settingsId}`);

export const setDefaults = (defaults, settingsId) => {
  const settings = {
    capital: {
      funderType: defaults.funderType,
      paymentMethod: defaults.paymentMethod,
    },
    services: {
      maximumVolumeSelectionValue: defaults.maximumVolumeSelectionValue,
      minimumVolumeSelectionValue: defaults.minimumVolumeSelectionValue,
      defaultVolumeSelectionValue: defaults.defaultVolumeSelectionValue,
      costPerDevice: defaults.costPerDevice,
      a0SquareMetersLfp: defaults.a0SquareMetersLfp,
    },
    schedule: {
      upgradeClause: defaults.upgradeClause,
      applicableFromPrefix: defaults.applicableFromPrefix,
      defaultTotalDevicesOnScheduleValue:
        defaults.defaultTotalDevicesOnScheduleValue,
    },
  };

  return apiPut(ActionTypes.SetDefaults, `defaults/${settingsId}`, settings);
};
