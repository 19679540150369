import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "@redriver/cinnamon";
import { Image } from "semantic-ui-react";
import Search from "features/../../../shared/assets/purple-search.svg";
import { AgreementsSearchType } from "features/../../../shared/constants/enums";
import { FieldNames } from "./AgreementSheetFilters";

const SearchAgreements = ({ formProps }) => {
  const { t } = useTranslation("agreements", { keyPrefix: "AgreementFilters" });
  const { t: searchTypeTranslation } = useTranslation("enums", {
    keyPrefix: "agreementsSearchType",
  });

  const options = Object.keys(AgreementsSearchType).map((key) => ({
    value: key,
    text: searchTypeTranslation(key),
  }));

  return (
    <Form {...formProps} className="highlighted-inputs">
      <div className="flex">
        <Form.Input
          field={FieldNames.Search}
          placeholder={t("Search_Placeholder")}
          icon={<Image src={Search} />}
          className="search-input"
        />
        <Form.Dropdown
          field={FieldNames.SearchType}
          options={options}
          width="8em"
          className="agr-search-type"
        />
      </div>
    </Form>
  );
};

export default SearchAgreements;
