import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FormBuilder, Form, ErrorMessage } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { SystemRoutes } from "constants/routes";
import { requestPasswordForgotten } from "./actions";

const PasswordForgotten = () => {
  const { t } = useTranslation('system');
  const [requestSent, setRequestSent] = useState(false);
  const [requestEmail, setRequestEmail] = useState("");

  if (requestSent) {
    return (
      <div className="password-reset-complete">
        <p>
        {requestEmail ?  t('password_reset_sent_with_email', {email: requestEmail}) : t('password_reset_sent')}
        </p>
        <div style={{ marginTop: 20 }}>
          <Button
            as={Link}
            to={SystemRoutes.Login}
            fluid
            className="action-button"
          >
             {t('Continue')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <FormBuilder
      submitAction={requestPasswordForgotten}
      onSubmit={({ email }) => setRequestEmail(email)}
      onSubmitted={() => setRequestSent(true)}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { submitting, slowSubmitting, error },
        events
      ) => (

          <Form {...formProps}>
            <Form.Email
              field="email"
              label={t('Email')}
              requiredError={t('Email') + ' ' + t('requiredError')}
              placeholder={t('Enter_email_address')}
              disabled={submitting && slowSubmitting}
              autoFocus
              required
            />
            <Button
              onClick={events.onSubmit}
              disabled={submitting}
              loading={submitting && slowSubmitting}
              className="action-button"
              fluid
            >
              {t('Continue')}
            </Button>
            <ErrorMessage
               header={t('Something_went_wrong')}
               error={error} 
               omitCodes
               overrideMessages={
                {
                  913010: t('Invalid_email_address'),
                  913011: t('Email_address_not_verified')
                }
              }
               />
          </Form>
        
      )}
    />
  );
};

export default PasswordForgotten;
