import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { Checkbox as SCheckbox } from "semantic-ui-react";
import { Field, connectForm, FormUtils } from "@redriver/cinnamon";
import classNames from "classnames";
import FixedInputIndicator from "./FixedInputIndicator";
import {
  withPermissions,
  withCurrentUser,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import FixedFieldType, { FixableTypes } from "constants/forms/FixedFieldType";
import { parseBool } from "features/utils";
import { UserArea } from "features/../../../shared/constants/enums";

/**
 * Custom Cinnamon component that provides an interface for the FixedFieldView object
 */
class FixedBoolean extends Component {
  static propTypes = {
    label: PropTypes.node,
    actions: PropTypes.node,
    inline: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fluid: PropTypes.bool,
    renderReadOnly: PropTypes.func,
    required: PropTypes.bool,
    requiredError: PropTypes.string,
    confirmField: PropTypes.string,
    confirmFieldError: PropTypes.string,
    field: PropTypes.string.isRequired,
    showErrors: PropTypes.bool,
    allErrors: PropTypes.bool,
    animateErrors: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    customErrors: PropTypes.arrayOf(PropTypes.string),
    customValidator: PropTypes.func,
    notifiedFields: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
        PropTypes.string,
      ]),
      isFixed: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
        PropTypes.string,
      ]),
    }),
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.object,
    ]),
    passThruProps: PropTypes.object,
    formState: PropTypes.object,
    displayPriority: PropTypes.number,
    hideIndicator: PropTypes.bool,
  };

  state = {
    fixType: FixedFieldType.None,
  };

  static defaultProps = {
    value: { value: "" },
    onChange: () => {},
    label: "",
    inline: false,
    displayPriority: 0,
  };

  determineFixType(value) {
    return value.isFixed ? FixedFieldType.Set : FixedFieldType.None;
  }

  componentDidMount() {
    const { value } = this.props;

    const fixType = this.determineFixType(value);

    this.setState({
      fixType,
    });
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== this.props.value) {
      const fixType = this.determineFixType(value);

      this.setState({
        fixType,
      });
    }
  }

  enforceValueConstraints = (value, fixType) => {
    const {
      value: { setValue },
    } = this.props;

    return fixType === FixedFieldType.Set ? setValue : value;
  };

  /**
   * The onChange method for the `<SCheckbox />` element of the FixedBoolean
   */
  onValueChange = () => {
    const disabled =
      this.props.disabled || this.state.fixType == FixedFieldType.Set;
    if (disabled) return;

    this.props.onChange({
      ...this.props.value,
      value: !this.props.value.value,
    });
  };

  /**
   * The onChange method for the `<FixedInputEditor />` element of the FixedBoolean
   */
  onValuePropsChange = (res) => {
    this.props.onChange({
      ...this.props.value,
      ...res,
      userArea: this.props.currentUser.userArea,
    });
  };

  onBlur = (e) => {
    if (this.props.onBlur) this.props.onBlur(e);
  };

  defaultRenderReadOnly = (props) => <p>{props.value.value}</p>;

  renderReadOnly = () =>
    (this.props.renderReadOnly || this.defaultRenderReadOnly)(this.props);

  rightLabelContent = (isAdmin, fixType, semanticProps) => {
    const { disabled, value, displayPriority, currentUser } = this.props;

    const indicatorDisabled =
      disabled ||
      (value?.isFixed &&
        value?.userArea == UserArea.IpsAdmin &&
        value?.userArea != currentUser.userArea);

    return (
      <FixedInputIndicator
        isAdmin={isAdmin}
        fixType={fixType}
        fixableType={FixableTypes.Boolean}
        fieldId={value.id || null}
        values={[parseBool(value.value) || false]}
        onForcedValuesChanged={this.onValuePropsChange}
        semanticProps={semanticProps}
        disabled={indicatorDisabled}
        displayPriority={displayPriority}
      />
    );
  };

  render() {
    const {
      value,
      errors,
      showErrors,
      allErrors,
      animateErrors,
      readOnly,
      label,
      actions,
      inline,
      width,
      fluid,
      required,
      className,
      passThruProps,
      hasPermission,
      hideIndicator = false,
    } = this.props;

    const { fixType } = this.state;

    const isAdmin = hasPermission(Targets.SheetAdmin, Actions.Edit);

    const disabled = this.props.disabled || fixType == FixedFieldType.Set;

    const hiddenIndicator =
      hideIndicator || (!isAdmin && fixType === FixedFieldType.None);
    const hasRightLabel = !hiddenIndicator;

    const semanticProps = FormUtils.omitProps(
      passThruProps,
      Object.keys(FixedBoolean.propTypes)
    );

    return (
      <div className="fix-bool-container">
        <Field
          inline={inline}
          required={required}
          renderReadOnly={readOnly && this.renderReadOnly}
          width={width}
          fluid={fluid}
          label={label}
          actions={actions}
          errors={FormUtils.fieldErrors(errors, showErrors, allErrors)}
          animateErrors={animateErrors}
          className={classNames("", className)}
          onClick={this.onValueChange}
          disabled={disabled}
        >
          <SCheckbox
            {...semanticProps}
            checked={parseBool(value.value)}
            disabled={disabled}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            className={classNames(
              "fix-input-wrapper",
              hiddenIndicator && "fix-input-hidden",
              isAdmin && "is-admin"
            )}
          />
        </Field>
        {hasRightLabel &&
          this.rightLabelContent(isAdmin, fixType, semanticProps)}
      </div>
    );
  }
}

export default connectForm({
  displayName: (props) =>
    props.label && typeof props.label === "string" ? props.label : "Input",
  validators: [],
})(compose(withPermissions, withCurrentUser)(FixedBoolean));
