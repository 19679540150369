import { useLookup } from "@redriver/cinnamon";
import React from "react";
import { InlineLoader } from "../../../../../shared/components/forms/InlineLoader";
import { productScheduleStatusLookup } from "./lookup";
import { useTranslation } from "react-i18next";

const ProductScheduleStatusKey = ({ className }) => {
  const { t } = useTranslation("productSchedule");
  const [{ loading, response }] = useLookup(productScheduleStatusLookup, {});
  return (
    <div className={`sch-status-legend ${className || ""}`}>
      <InlineLoader isActive={loading} />
      {!loading && (
        <React.Fragment>
          <label>{t("Status")}</label>
          <dl>
            {[]
              .concat(response)
              .filter((x) => x)
              .map((x, index) => (
                <dt key={index}>{x.text}</dt>
              ))}
          </dl>
        </React.Fragment>
      )}
    </div>
  );
};

export default ProductScheduleStatusKey;
