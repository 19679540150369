import React from "react";
import { ModalBuilder, Table } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";
import { processData, getSummary } from "./actions";
import { SettingsUploadErrorMessages } from "features/../../../shared/components/errors";
import { isUnexpectedError } from "features/Sheets/ManageSheet/helpers";
import { TranslatedModal } from "features/../../../shared/components/modals";

const ProcessUpload = ({ item, onSubmitted }) => {
  const { t } = useTranslation("dataUploads", { keyPrefix: "processUpload" });
  return (
    <ModalBuilder
      loadAction={getSummary}
      loadParams={item.id}
      submitAction={processData}
      submitParams={{ id: item.id }}
      onSubmitted={onSubmitted}
      renderTrigger={(trigger) => (
        <Table.MenuItem onClick={trigger}>{t("actionLabel")}</Table.MenuItem>
      )}
      renderModal={(modalProps, data) => (
        <TranslatedModal.Confirmation {...modalProps}>
          <p>{t("confirmMessage")}</p>

          <label>Changes</label>
          <ul>
            <li>{data.totalAdditions} additions</li>
            <li>{data.totalRemovals} removals</li>
            <li>{data.totalUpdates} updates</li>
          </ul>
          {!isUnexpectedError(modalProps.error) && (
            <SettingsUploadErrorMessages error={modalProps.error} />
          )}
        </TranslatedModal.Confirmation>
      )}
    />
  );
};

export default ProcessUpload;
