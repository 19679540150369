const mapEnumToDropdown = item => ({ text: item[1], value: item[0] });

export const FunderType = {
  Funder: "Funder",
  CoFunded: "CoFunded",
  Dealer: "DealerFunded",
};

export const FunderTypeDescription = {
  Funder: "Funder",
  CoFunded: "CoFunded",
  DealerFunded: "Supplier Funded",
};

export const FmvType = {
  FullFmvValue: "FullFmvValue" ,
  OfRrpValue: "OfRrpValue" ,
  OfTotalCapitalOnly: "OfTotalCapitalOnlyy",
}

export const FmvTypeDescription = {
  FullFmvValue: "FULL FMV Value" ,
  OfRrpValue: "of RRP Value" ,
  OfTotalCapitalOnly: "of TOTAL Capital only",
}

export const FunderTypeCssClass = {
  Funder: "funder",
  CoFunded: "co-funded",
  Dealer: "dealer",
}
export const FunderTypeAvailability = {
  IPSOnly: "IPSOnly" ,
  IPSSupplierAdmin: "IPSSupplierAdmin" ,
  All: "All" 
}



export const FunderTypeAvailabilityLookup = Object.entries({
  IPSOnly: "Selectable by IPS Only" ,
  IPSSupplierAdmin: "Selectable by IPS & IPS Supplier Admin" ,
  All: "Selectable by All Views" 
}).map(mapEnumToDropdown);

export const FunderRateType = Object.entries({
  EFA: "EFA" ,
  FMV: "FMV" ,
}).map(mapEnumToDropdown);

export const FmvTypes = Object.entries(FmvTypeDescription).map(mapEnumToDropdown);

