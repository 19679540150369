import React from "react";
import { NavMenu } from "@redriver/cinnamon";
import { withRouter } from "react-router-dom";
import { DealerRoutes } from "constants/routes";
import { withCurrentUser } from "features/../../../shared/components/auth";
import { useTranslation } from "react-i18next";

const DealerMenu = ({ companyId }) => {
  const { t } = useTranslation("supplierOffices", { keyPrefix: "menu" });

  return (
    <NavMenu secondary className="secondary">
      <NavMenu.Item exact link={DealerRoutes.Overview(companyId)}>
        {t("overview")}
      </NavMenu.Item>
      {/*
    <NavMenu.Item link={DealerRoutes.Users(companyId)}>{t('users')}</NavMenu.Item>
    <NavMenu.Item link={DealerRoutes.Sheets(companyId)}>{t('sheets')}</NavMenu.Item>
    <NavMenu.Item link={DealerRoutes.Defaults(companyId)}>{t('defaults')}</NavMenu.Item>
    <NavMenu.Item link={DealerRoutes.Rates(companyId)}>{t('rates')}</NavMenu.Item>
    <NavMenu.Item link={DealerRoutes.Yields(companyId)}>{t('yields')}</NavMenu.Item>
    <NavMenu.Item link={DealerRoutes.LargeFormat(companyId)}>{t('largeFormat')}</NavMenu.Item>
  */}
    </NavMenu>
  );
};

export default withRouter(withCurrentUser(DealerMenu));
