import React from "react";
import { Table, useAuthClaims } from "@redriver/cinnamon";
import { withPermissions } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import EditUser from "./EditUser";
import DeleteUser from "./DeleteUser";
import ResetAuthentication from "./ResetAuthentication";
import { UserArea, UserStatus } from "../../../constants/enums";
import EditUserPermissions from "./EditUserPermissions";
import ClearFailedLoginLockout from "./ClearFailedLoginLockout";
import { ResendEmailVerification } from ".";

const UserActions = ({ user, companyId, onRefresh, hasPermission }) => {
  const { hasSetupMfa } = user;
  const { userArea } = useAuthClaims();

  let canEdit, canDelete, canResetMfa, canUpdateUserRoles;

  if (
    user.userArea == UserArea.IpsAdmin ||
    user.userArea == UserArea.IpsSuperUser
  ) {
    canEdit =
      userArea == UserArea.IpsSuperUser &&
      hasPermission(Targets.User, Actions.UpdateIPSUsers);
    canDelete =
      userArea == UserArea.IpsSuperUser &&
      hasPermission(Targets.User, Actions.UpdateIPSUsers);
    canResetMfa =
      userArea == UserArea.IpsSuperUser &&
      hasPermission(Targets.User, Actions.UpdateIPSUsers);
    canUpdateUserRoles =
      userArea == UserArea.IpsSuperUser &&
      hasPermission(Targets.UserRole, Actions.UpdateIPSPermissions);
  } else {
    canEdit = hasPermission(Targets.User, Actions.Edit);
    canDelete = hasPermission(Targets.User, Actions.Delete);
    canResetMfa = hasSetupMfa && hasPermission(Targets.UserMfa, Actions.Reset);
    canUpdateUserRoles = hasPermission(Targets.UserRole, Actions.Edit);
  }

  if (!canEdit && !canDelete && !canUpdateUserRoles && !canResetMfa)
    return null;

  return (
    <Table.Menu openOnHover>
      {canEdit && (
        <EditUser
          user={user}
          companyId={companyId}
          onSubmitted={onRefresh}
          as={Table.MenuItem}
        >
          Edit
        </EditUser>
      )}
      {canDelete && (
        <DeleteUser user={user} onSubmitted={onRefresh} as={Table.MenuItem}>
          Delete
        </DeleteUser>
      )}
      {canUpdateUserRoles && (
        <EditUserPermissions
          user={user}
          onSubmitted={onRefresh}
          as={Table.MenuItem}
        >
          Edit Permissions
        </EditUserPermissions>
      )}
      {canResetMfa && (
        <ResetAuthentication
          user={user}
          onReset={onRefresh}
          as={Table.MenuItem}
        >
          Reset 2FA
        </ResetAuthentication>
      )}
      {canEdit && user.userStatus != UserStatus.Verified && (
      <ResendEmailVerification        
        user={user}
        onRefresh={onRefresh}
        as={Table.MenuItem}
      />)}
      {canEdit && (
        <ClearFailedLoginLockout
          user={user}
          onReset={onRefresh}
          as={Table.MenuItem}
        >
          Clear Failed Login Lockout
        </ClearFailedLoginLockout>
      )}
    </Table.Menu>
  );
};

export default withPermissions(UserActions);
