import React from "react";
import { ListBuilder, ActionBar, Table } from "@redriver/cinnamon";
import { getDealerOffices } from "./actions";
import { ListSearch } from "components/lists";
import { TablePagination } from "components/pagination";
import { useTranslation } from "react-i18next";
import { UserType } from "features/../../../shared/constants/enums";
import { UserTypeCheck } from "features/../../../shared/components/auth";

const DealerOfficesList = ({ onSelect, dealerId }) => {
  const { t } = useTranslation("agreements");

  return (
    <React.Fragment>
      <UserTypeCheck restrictTo={UserType.HQ}></UserTypeCheck>
      <h4 className="title">{t("Offices.Title")}</h4>
      <ListBuilder
        withTable
        initialPagination={{ pageSize: 25, pageNumber: 1 }}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        loadAction={getDealerOffices}
        loadParams={{ dealerId }}
        renderList={(tableProps, state, events) => (
          <div className="content-container principal-list">
            <ActionBar>
              <ActionBar.Item fluid className="flex">
                <ListSearch
                  value={state.filters}
                  onChange={events.onChangeFilters}
                  placeholder={t("Offices.Search_Placeholder")}
                />
                <div className="search-filter-display"></div>
              </ActionBar.Item>
            </ActionBar>
            <Table
              {...tableProps}
              dataKey="id"
              emptyMessage={t("Offices.NoData")}
              selectable
              className="center-align"
            >
              <Table.Column
                field="name"
                title={t("Offices.Headings.Name")}
                width="15%"
                render={(item) => (
                  <a onClick={() => onSelect(item)}>{item.name}</a>
                )}
              />
              <Table.Column
                field="territory"
                title={t("Offices.Headings.Territory")}
                width="10%"
              />
              <Table.Column
                field="code"
                title={t("Offices.Headings.Office_ID")}
                width="10%"
              />
              <Table.Column
                title={t("Offices.Headings.Address")}
                width="25%"
                render={(row) => {
                  if (!row.address) return;
                  const address = [
                    row.address.line1,
                    row.address.line2,
                    row.address.town,
                    row.address.postcode,
                  ];
                  return address.filter((x) => !!x).join(", ");
                }}
              />
              <Table.Column
                title={t("Offices.Headings.Country")}
                render={(row) => row.address?.country}
                width="10%"
              />
              <Table.Column
                field="telephone"
                title={t("Offices.Headings.Telephone")}
                width="10%"
              />
              <Table.Column
                field="timezone"
                title={t("Offices.Headings.Time_Zone")}
                width="10%"
              />
              <Table.Column
                field="status"
                title={t("Offices.Headings.Status")}
                width="10%"
              />
            </Table>
            <TablePagination
              pageNumber={state.pagination.pageNumber}
              onChange={events.onChangePagination}
              pageSize={state.pagination.pageSize}
              totalItems={state.total}
            />
          </div>
        )}
      />
    </React.Fragment>
  );
};

export default DealerOfficesList;
