import { apiPatch, notifySuccess } from "@redriver/cinnamon";

const ActionTypes = {
  SetReplacingSchedule: "SHEETS/SET_REPLACING_SCHEDULE",
};

export const setReplacingSchedule =
  (request, { sheetId }) =>
  async dispatch => {
    const response = await dispatch(
      apiPatch(
        ActionTypes.SetReplacingSchedule,
        `sheets/${sheetId}/replacing-schedule`,
        request
      )
    );

    response.success &&
      dispatch(notifySuccess("Replacing schedule updated successfully"));
    return response;
  };
