import React from "react";
import { FormBuilder, Form } from "@redriver/cinnamon";
import { Image } from "semantic-ui-react";
import Search from "features/../../../shared/assets/purple-search.svg";
import { useTranslation } from "react-i18next";

const SearchClientAuthorisedSignatories = ({ value, onChange }) => {
  const { t } = useTranslation();
  return (
    <FormBuilder
      value={value}
      onChange={onChange}
      renderForm={formProps => (
        <Form {...formProps}>
          <Form.Input
            field="search"
            placeholder={t("clients:tables:authorisedSignatories:search")}
            icon={<Image src={Search} />}
            className="search-input highlighted-input"
          />
        </Form>
      )}
    />
  );
};

export default SearchClientAuthorisedSignatories;
