import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { NavMenu, MenuBar } from "@redriver/cinnamon";
import { Targets, Actions } from "constants/permissions";
import { AppRoutes } from "../../constants/routes";
import AccountItem from "./AccountItem";
import {
  PermissionCheck,
  UserTypeCheck,
  withCurrentUser,
  withPermissions,
} from "features/../../../shared/components/auth";
import { UserType } from "features/../../../shared/constants/enums";
import CompanyLogo from "./CompanyLogo";
import { getAgreementsDealerOfficeState } from "./selectors";
import { useDispatch, useSelector } from "react-redux";
import { getUnreadNotificationsCount } from "./actions";

/**
 * NOTE: Please keep options here in sync with ./MobileMainMenu.
 * This menu is rendered on non-mobile viewports
 */
const MainNav = ({ hasPermission, hideMenu }) => {
  const { t } = useTranslation("menu");
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);

  useEffect(() => {
    (async function fetchNotificationCount() {
      const { response } = await dispatch(getUnreadNotificationsCount());
      setCount(response);
    })();
  }, [dispatch]);

  const dealerOffice = useSelector(getAgreementsDealerOfficeState);

  const hasDataUploadPerms =
    dealerOffice.id &&
    (hasPermission(Targets.UploadSettings, Actions.View) ||
      hasPermission(Targets.UploadSettings, Actions.Upload) ||
      hasPermission(Targets.UploadSettingsTemplates, Actions.Download));

  return (
    <NavMenu vertical={false} className="main-menu">
      <NavMenu.Item
        exact
        link={!hideMenu ? AppRoutes.Root : null}
        className="logo"
      >
        <CompanyLogo />
      </NavMenu.Item>
      {!hideMenu && (
        <React.Fragment>
          <PermissionCheck target={Targets.Sheet} action={Actions.View}>
            <NavMenu.Item link={AppRoutes.Agreements}>
              {t("Agreements")}
            </NavMenu.Item>
          </PermissionCheck>
          <PermissionCheck target={Targets.Client} action={Actions.View}>
            <NavMenu.Item link={AppRoutes.Clients}>{t("Clients")}</NavMenu.Item>
          </PermissionCheck>
          <UserTypeCheck restrictTo={UserType.HQ}>
            <PermissionCheck target={Targets.Dealer} action={Actions.View}>
              <NavMenu.Item link={AppRoutes.DealerOffices}>
                {t("Supplier_Offices")}
              </NavMenu.Item>
            </PermissionCheck>
          </UserTypeCheck>
          {/*hasSettingsPerms && (
            <NavMenu.Item link={AppRoutes.Settings}>{t("Settings")}</NavMenu.Item>
          )*/}

          {hasDataUploadPerms && (
            <NavMenu.Item link={AppRoutes.DataUploads}>
              {t("Data_Uploads")}
            </NavMenu.Item>
          )}
        </React.Fragment>
      )}
      <MenuBar.Space />
      <NavMenu.Item
        link={AppRoutes.Notification}
        style={{ marginRight: "12px" }}
      >
        {count > 0 && <span className="notification-count">{count}</span>}
        {t("Notification_List")}
      </NavMenu.Item>
      <AccountItem />
    </NavMenu>
  );
};

export default compose(withRouter, withCurrentUser, withPermissions)(MainNav);
