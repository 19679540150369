import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { UserType } from "features/../../../shared/constants/enums";
import { resetAuthentication } from "./actions";

const ResetAuthentication = ({ as: As = Button, user, onReset, ...props }) => ( 
  <ModalBuilder
    submitAction={resetAuthentication}
    submitParams={user.id}
    onSubmitted={onReset}
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps) => (
      <Modal.Confirmation {...modalProps} header="Reset 2FA">
        <p>
          Are you sure you wish to reset Two-Factor Authentication for{" "}
          {user.firstName} {user.lastName}?
        </p>
        <p>
          This will remove any associations between this user's account and
          previous authenticator app registrations.
        </p>
        <p>
          {user.firstName} {user.lastName} will need to setup Two-Factor
          authentication again when they next log in.
        </p>
        {user.userType !== UserType.HQ && (
          <p className="reset-2fa-warning">
            <Icon name="warning sign" size="large" /> Please verify the user is
            who they say they are before continuing.
          </p>
        )}
      </Modal.Confirmation>
    )}
  />
);

export default ResetAuthentication;
