import React, { useState } from "react";
import { connect } from "react-redux";
import { Lookup, LookupTypeAhead } from "@redriver/cinnamon";
import { UserTypeCheck } from "features/../../../shared/components/auth";
import { UserType } from "features/../../../shared/constants/enums";
import { LookupTypes } from "features/Settings";
import { default as DefaultsGrid, getDefaults } from "./Defaults";
import {
  YieldBands,
  getYieldBands,
  getLiquidityChargesUsingRateGroupId,
  RateGroupLookupDropdown,
  RateTableLookupDropdown,
  PaperDefaults,
  getPaperDefaults,
} from "features/../../../shared/features/Settings";

import { AllEditableLiquidityChargeTables } from "features/Settings/ListRates";
import { Trans, useTranslation } from "react-i18next";

const DefaultsAndSettings = ({
  getLiquidityChargesUsingRateGroupId,
  dealerOfficeId,
  dealerOfficeName,
}) => {
  const { t } = useTranslation("supplierOffices", {
    keyPrefix: "defaultsAndSettings",
  });

  const [selectedSettings, setSelectedSettings] = useState(null);
  const [rateGroupId, setRateGroup] = useState(null);
  const [rateTableType, setRateTableType] = useState(null);
  const [filters, setFilters] = useState({
    paymentMethod: 0,
    financeType: 1,
    funderType: 4,
  });

  return (
    <Lookup
      lookup={LookupTypes.Settings}
      lookupParams={{ dealerOfficeId }}
      render={({ response }) => {
        if (!selectedSettings) {
          setSelectedSettings(response && response.length ? response[0] : null);
        }

        if (!selectedSettings) {
          return null;
        }
        const selectedSettingsId = selectedSettings.value;

        return (
          <div>
            <div className="header-dropdown">
              {dealerOfficeId ? (
                <h4>
                  <Trans t={t} values={{ dealerOfficeName: dealerOfficeName }}>
                    title
                  </Trans>
                </h4>
              ) : (
                <UserTypeCheck restrictTo={UserType.HQ}>
                  <LookupTypeAhead
                    readOnly={false}
                    lookup={LookupTypes.Settings}
                    searchSuggestions
                    alwaysShowSuggestionsOnBlur
                    onChange={(event, data) => {
                      setSelectedSettings(data);
                    }}
                    placeholder="Search by Supplier"
                    value={selectedSettingsId}
                    unknownValueOptions={[selectedSettings]}
                  />
                </UserTypeCheck>
              )}
            </div>
            <div className="defaults-settings-container">
              <UserTypeCheck restrictTo={UserType.HQ}>
                <div className="header-dropdown-rates">
                  <label>{t("rateGroup")}</label>
                  <RateGroupLookupDropdown
                    className="defaults-dropdown"
                    lookupParams={{
                      includeInactive: true,
                      search: null,
                      selectedSettingsId,
                    }}
                    value={rateGroupId}
                    onChange={async (event, data) => {
                      setRateGroup(data.value);
                      getLiquidityChargesUsingRateGroupId(data.value);
                    }}
                  />
                  <label>{t("rateTableType")}</label>
                  <RateTableLookupDropdown
                    className="defaults-dropdown"
                    value={rateTableType}
                    onChange={(event, data) => {
                      const matchedType =
                        data.options.find((x) => x.value === data.value) || {};
                      setRateTableType(data.value);
                      setFilters(matchedType);
                    }}
                  />
                </div>
                {rateGroupId && (
                  <div className="flex row">
                    <YieldBands
                      rateGroupId={rateGroupId}
                      paymentMethod={filters.paymentMethod}
                      financeType={filters.financeType}
                      funderType={filters.funderType}
                    />
                  </div>
                )}
              </UserTypeCheck>
              {rateGroupId && (
                <AllEditableLiquidityChargeTables rateGroupId={rateGroupId} />
              )}
              <div className="row">
                <DefaultsGrid settingsId={selectedSettingsId} />
              </div>
              <div className="row">
                <PaperDefaults settingsId={selectedSettingsId} />
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

const mapStateToProps = () => {
  return {};
};

const actions = {
  getDefaults,
  getYieldBands,
  getPaperDefaults,
  getLiquidityChargesUsingRateGroupId,
};

export default connect(mapStateToProps, actions)(DefaultsAndSettings);
