import { produce } from "immer";
import {
  requestIsBusy,
  requestResponse,
  requestIsSuccess,
  requestError,
  requestIsSlow,
} from "@redriver/cinnamon";
import { ActionTypes as AgreementItemActionTypes } from "./AgreementsList/actions";
import { ActionTypes } from "./actions";
import { initialState } from "./ReducerHelpers";

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SetAgreementCompanyFilter: {
      return produce(state, (draftState) => {
        draftState.companyFilters = action.data;
      });
    }

    case ActionTypes.SetSelectedAgreementSchedule: {
      return produce(state, (draftState) => {
        draftState.selected = action.data;
      });
    }

    case ActionTypes.ClearAgreementCompanyFilter: {
      return produce(state, (d) => {
        d.companyFilters = initialState.companyFilters;
      });
    }

    case ActionTypes.ClearAgreementState: {
      return produce(initialState, () => {});
    }

    case ActionTypes.ClearAgreementData: {
      return {
        ...initialState,
        companyFilters: state.companyFilters,
      };
    }

    case AgreementItemActionTypes.GetAgreements: {
      return produce(state, (d) => {
        d.agreementsLoading = !!(requestIsBusy(action) || requestError(action));
        if (requestIsSuccess(action)) {
          const { results, totalResults } = requestResponse(action);
          d.totalAgreements = totalResults;
          d.agreements = results;
          d.agreementsLoaded = true;
        }
      });
    }

    case AgreementItemActionTypes.GetAgreement: {
      return produce(state, (d) => {
        if (!requestIsSuccess(action)) return state;

        // Update the specific agreement only.
        d.agreements = d.agreements.map((agr) =>
          agr.agreementId == action.customData.agreementId
            ? requestResponse(action)
            : agr,
        );
      });
    }

    case AgreementItemActionTypes.SetPagination: {
      return produce(state, (d) => {
        d.pagination = action.pagination;
      });
    }

    case AgreementItemActionTypes.SetFilters: {
      return produce(state, (d) => {
        d.filters = action.filters;
        d.pagination = initialState.pagination;
      });
    }

    case AgreementItemActionTypes.ClearFilters: {
      return produce(state, (d) => {
        d.filters = initialState.filters;
      });
    }

    case AgreementItemActionTypes.AddExpandedAgreementOrSchedule: {
      return produce(state, (d) => {
        d.expandedAgreementsAndSchedules.push(action.id);
      });
    }

    case AgreementItemActionTypes.RemoveExpandedAgreementOrSchedule: {
      return produce(state, (d) => {
        d.expandedAgreementsAndSchedules =
          d.expandedAgreementsAndSchedules.filter((x) => x != action.id);

        delete d.scheduleSheets[action.id];
      });
    }

    case AgreementItemActionTypes.GetAgreementScheduleSheets: {
      return produce(state, (d) => {
        const loading = !!(requestIsBusy(action) || requestIsSlow(action));
        const scheduleId = action.customData.scheduleId;

        const response = requestIsSuccess(action)
          ? requestResponse(action)
          : d.scheduleSheets[scheduleId]?.response ?? [];

        d.scheduleSheets[scheduleId] = { loading, response };
      });
    }

    default:
      return state;
  }
};
