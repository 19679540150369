import React from "react";
import { Format } from "@redriver/cinnamon";

const BlankNumberFormatter = ({ value, format = "default", ...otherProps }) => {
    return (value != null ?
        <span className={value < 0 ? "red" : ""}>
            {value < 0 ? "(" : ""}
            <Format.Number
              value={Math.abs(value)}
              format={format}
              {...otherProps}
              thousandSeparator
            />
            {value < 0 ? ")" : ""}
          </span>
         : "-");
}

export default BlankNumberFormatter;