import React from "react";
import { Grid } from "semantic-ui-react";
import classNames from "classnames";
import { Form } from "@redriver/cinnamon";
import MeterReadingItem from "./MeterReadingItem";

const MeterReadingItemGroup = ({
  isFirstGroup,
  isLastGroup,
  startPosition,
  appSysLen,
  lfLen,
}) => {
  return (
    <Grid className="meter-item-group">
      <ApplicableItems
        fieldName="applicableSysSchItems"
        isFirstGroup={isFirstGroup}
        startPosition={startPosition}
      />
      <ApplicableItems
        fieldName="applicableLFItems"
        isFirstGroup={isFirstGroup}
        startPosition={startPosition + appSysLen}
      />
      {!isLastGroup && (
        <Grid.Row
          className={classNames(
            (startPosition + appSysLen + lfLen) % 2 === 0 && "even"
          )}
        ></Grid.Row>
      )}
    </Grid>
  );
};

const ApplicableItems = ({ fieldName, startPosition }) => (
  <Form.Array arrayKey="id" field={fieldName}>
    {({ arrayIndex }) => (
      <MeterReadingItem isGreen={(startPosition + arrayIndex) % 2 === 0} />
    )}
  </Form.Array>
);

export default MeterReadingItemGroup;
