import { PageContent } from "@redriver/cinnamon";
import NotificationList from "features/Notifications/NotificationList";
import React from "react";

const NotificationPage = ({ match }) => (
  <PageContent className="notification-page">
    <NotificationList key={match.params.id} />
  </PageContent>
);

export default NotificationPage;
