import React from "react";
import { useTranslation } from "react-i18next";
import { NavMenu, useAuthClaims } from "@redriver/cinnamon";
import { Popup, Icon } from "semantic-ui-react";
import { SystemRoutes } from "../../constants/routes";
import { UserType } from "features/../../../shared/constants/enums";
import { withCurrentUser } from "features/../../../shared/components/auth";

const AccountItem = ({ currentUser }) => {
  const { isInternalUser, isSupplierAdminUser } = currentUser;
  const { t } = useTranslation("system");

  const { userAvatar, userName, userType, userArea } = useAuthClaims();

  return (
    <div className="popup-menu account">
      <Popup
        flowing
        hoverable
        position="bottom right"
        content={
          <div className="popup-menu-content">
            <NavMenu.Item link={SystemRoutes.Account}>
              {t("Account")}
            </NavMenu.Item>
            {isInternalUser || isSupplierAdminUser ? (
              <NavMenu.Item link={SystemRoutes.Logout}>
                {t("Log_out")}
              </NavMenu.Item>
            ) : (
              <NavMenu.Item link={SystemRoutes.AccountManagerLogout}>
                {t("Log_out")}
              </NavMenu.Item>
            )}
          </div>
        }
        trigger={
          <div className="account-icon-trigger">
            <div className="user-info">
              <label>{userName}</label>
              <label>
                {userType === UserType.HQ
                  ? process.env.TRADE_AS_IPS
                    ? t("IP_Syndicate")
                    : t("Principal")
                  : t("enums:UserAreaDescriptions:" + userArea)}
              </label>
            </div>
            {userAvatar ? (
              <div
                className="account-icon"
                style={{
                  backgroundImage: `url('${
                    process.env.STORAGE_CONTAINER_URL + userAvatar
                  }')`,
                }}
              ></div>
            ) : (
              <Icon className="account-icon" name="user circle" />
            )}
          </div>
        }
      ></Popup>
    </div>
  );
};

export default withCurrentUser(AccountItem);
