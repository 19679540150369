import React from "react";
import { Modal, ErrorMessage } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";
import { translatedModalProps } from "./helpers";

const TranslatedConfirmationModal = (confirmationModalProps) => {    
    const { t } = useTranslation("shared");        
    const modalProps = translatedModalProps(confirmationModalProps, t);

    return (
        <Modal.Confirmation  {...modalProps}
            header={modalProps.header ? modalProps.header : t("modals.confirmation.header")}
            submitLabel={modalProps.submitLabel ? modalProps.submitLabel : t("modals.confirmation.submitLabel")}
            cancelLabel={modalProps.cancelLabel ? modalProps.cancelLabel : t("modals.confirmation.cancelLabel")}>
            {modalProps.children}
            <ErrorMessage
                header={confirmationModalProps.error?.status == 500 ?  t("modals.errorHeader"): t("modals.somethingWentWrong")}
                error={confirmationModalProps.error}
                omitCodes                    
            />
        </Modal.Confirmation>
    )
}

export default TranslatedConfirmationModal;
