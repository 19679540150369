import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "SETTINGS/DEFAULTS";

export const ActionTypes = {
  GetDefaults: `${NAMESPACE}/GET`,
  SetDefaults: `${NAMESPACE}/SET`,
};

export const getDefaults = ({ settingsId }) =>
    apiGet(ActionTypes.GetDefaults, `defaults/${settingsId}`);

export const setDefaults = (defaults, settingsId) => {
  const settings = {
    schedule: {
      upgradeClause: defaults.upgradeClause,
      applicableFromPrefix: defaults.applicableFromPrefix,
      tier3MaximumPercent: defaults.tier3MaximumPercent,
      defaultTotalDevicesOnScheduleValue:
      defaults.defaultTotalDevicesOnScheduleValue,
    },
    services: {
      A0SquareMetersLfp : defaults.a0SquareMetersLfp,
    }
  };

  return apiPut(ActionTypes.SetDefaults, `defaults/${settingsId}`, settings);
};
