import React from "react";
import { connect } from "react-redux";
import { ActionMenu } from "@redriver/cinnamon";
import classNames from "classnames";
import { setActiveTab } from "./actions";
import { getRatesState } from "../selectors";
import {
  RateTypeDiscriminator,
  UserType,
  RateType,
} from "features/../../../shared/constants/enums";
import { UserTypeCheck } from "features/../../../shared/components/auth";
import { useTranslation } from "react-i18next";

const TabHeaders = ({ activeTab, setActiveTab, showAllTables }) => {
  const { t } = useTranslation("supplierOffices", {
    keyPrefix: "rates.tabHeader",
  });
  return (
    <div className="tab-headers">
      {showAllTables ? (
        <UserTypeCheck restrictTo={UserType.HQ}>
          <div className="rates-actions">
            <ActionMenu
              compact
              className="ultra-compact-action-menu"
              label={`Displaying: ${RateType[activeTab]}`}
            >
              <ActionMenu.Item
                text={RateType.IpsNetRate}
                onClick={() => setActiveTab(RateTypeDiscriminator.IpsNetRate)}
              />
              <ActionMenu.Item
                text={RateType.Per1000DifferenceMinForIps}
                onClick={() =>
                  setActiveTab(RateTypeDiscriminator.Per1000DifferenceMinForIps)
                }
              />

              <ActionMenu.Item
                text={RateType.GrossSalesRate}
                onClick={() =>
                  setActiveTab(RateTypeDiscriminator.GrossSalesRate)
                }
              />
              <ActionMenu.Item
                text={RateType.DealerNetRate}
                onClick={() =>
                  setActiveTab(RateTypeDiscriminator.DealerNetRate)
                }
              />
              <ActionMenu.Item
                text={RateType.HqYieldAtDealerNetRate}
                onClick={() =>
                  setActiveTab(RateTypeDiscriminator.IpsYieldAtDealerNetRate)
                }
              />
            </ActionMenu>
          </div>
        </UserTypeCheck>
      ) : (
        <React.Fragment>
          <div
            className={classNames(
              "tab-container",
              activeTab === RateTypeDiscriminator.GrossSalesRate && "active"
            )}
          >
            <a
              onClick={() => setActiveTab(RateTypeDiscriminator.GrossSalesRate)}
            >
              {t("grossSalesRate")}
            </a>
          </div>
          <div
            className={classNames(
              "tab-container",
              activeTab === RateTypeDiscriminator.DealerNetRate && "active"
            )}
          >
            <a
              onClick={() => setActiveTab(RateTypeDiscriminator.DealerNetRate)}
            >
              {t("dealerNetRate")}
            </a>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const actions = {
  setActiveTab,
};

const mapStateToProps = (state) => {
  const { activeTab } = getRatesState(state);

  return {
    activeTab,
  };
};

export default connect(mapStateToProps, actions)(TabHeaders);
