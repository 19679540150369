import {
  setApiOptions,
  enableJwtAuth,
  setLoggingOptions,
  enableSentryLogger,
  enableSegmentLogger,
  enableHotjarLogger,
  setPreferenceDefaults,
  setResponsiveOptions,
  createDefaultReduxStore,
  notifyError,
  ApiError,
} from "@redriver/cinnamon";
import reducers from "./reducers";
import { onRefreshJwt, onDeauthenticated } from "features/System";
import "./translation/i18n";
import { setPersistentQueryParams } from "features/System/actions";
import { isUnexpectedErrorMessage } from "features/Sheets/ManageSheet";

export const setupApp = () => {
  configureApi();
  configureAuth();
  configureLogging();
  configurePreferences();
  configureResponsive();
  const store = configureStore();
  return { store };
};

const handleUnload = (event) => {
  event.preventDefault();
  return (event.returnValue = "Are you sure you want to exit?");
};
export const configureUnloadListener = () =>
  window.addEventListener("beforeunload", handleUnload);
export const removeUnloadListener = () =>
  window.removeEventListener("beforeunload", handleUnload);

// api / auth modules

const configureApi = () =>
  setApiOptions({
    apiUrl: process.env.API_URL,
    onErrorNotification: (message) => notifyError(message, { expiry: 10000 }),

    onErrorResponse: (response) => {
      return new Promise((resolve) => {
        const responseType = response.headers.get("content-type");
        const errorCode = response.headers.get("X-Saffron-Requestid");
        if (responseType && responseType.includes("application/json")) {
          response
            .json()
            .then((data) => {
              if (isUnexpectedErrorMessage(data[0].message)) {
                data[0].message =
                  "Oops, Something went wrong server side. \n Please contact our support team." +
                  "\n Error Code: " +
                  errorCode;
                data[0].code = undefined;
              }
              return resolve(new ApiError(data, response.status));
            })
            .catch((error) =>
              resolve(new ApiError(error.message, response.status))
            );
        } else if (responseType && responseType.includes("text/")) {
          response
            .text()
            .then((data) => resolve(new ApiError(data, response.status)))
            .catch((error) =>
              resolve(new ApiError(error.message, response.status))
            );
        } else {
          resolve(
            new ApiError(
              "Oops, Something went wrong server side. \n Please contact our support team.",
              response.status
            )
          );
        }
      });
    },
  });

const configureAuth = () =>
  enableJwtAuth({
    tokenKey: `${process.env.COOKIE_PREFIX}-token-key`,
    jwtClaims: {
      emailVerified: {
        type: "boolean",
        key: "ev",
      },
      userId: {
        type: "string",
        key: "sub",
      },
      userName: {
        type: "join",
        keys: ["fn", "ln"],
        separator: " ",
      },
      userEmail: {
        type: "string",
        key: "e",
        identity: "email",
      },
      permissions: {
        type: "object",
        keys: "^p_(.+)",
      },
      userType: {
        type: "string",
        key: "ut",
      },
      userArea: {
        type: "string",
        key: "ua",
      },
      companyId: {
        type: "string",
        key: "co",
      },
      userAvatar: {
        type: "string",
        key: "av",
      },
    },
    onRefreshJwt,
    onDeauthenticated,
  });

// logging module

const configureLogging = () => {
  setLoggingOptions({
    environment: process.env.LOGGING_ENVIRONMENT,
    release: process.env.LOGGING_RELEASE,
  });
  if (process.env.SENTRY_DSN) {
    enableSentryLogger(process.env.SENTRY_DSN);
  }
  if (process.env.SEGMENT_APIKEY) {
    enableSegmentLogger(process.env.SEGMENT_APIKEY);
  }
  if (process.env.HOTJAR_SITEID) {
    enableHotjarLogger(process.env.HOTJAR_SITEID);
  }
};

// preferences module

const configurePreferences = () =>
  setPreferenceDefaults({
    timezone: "Europe/London",
  });

// responsive module

const configureResponsive = () =>
  setResponsiveOptions({
    screenSizes: {
      small: { max: 799, className: "screen-sm" },
      medium: { min: 800, max: 1064, className: "screen-md" },
      large: { min: 1065, className: "screen-lg" },
    },
  });

// redux store module

const configureStore = () => {
  const store = createDefaultReduxStore({
    reducers,
    reduxDevTools: process.env.REDUX_DEVTOOLS,
  });

  setPersistentQueryParams(
    (() => {
      /*
       * take a copy of any query params present as they are right now
       */
      const params = new URLSearchParams(window.location.search),
        result = {};

      for (const [key, value] of params.entries()) {
        result[key] = decodeURIComponent(value ?? "");
      }

      return result;
    })()
  )(store.dispatch);

  return store;
};
