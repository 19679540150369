import React from "react";
import { useTranslation } from "react-i18next";
import { Format } from "@redriver/cinnamon";
import DataGrid from "./DataGrid";
import classNames from "classnames";

const CapitalTotalGrid = ({ data, funderType }) => {
  const { t } = useTranslation("capitalCalc", {
    keyPrefix: "infoModal.funderTypes.settlements",
  });

  return (
    <DataGrid
      className={classNames("total-investment", funderType?.toLowerCase())}
      columnCount={2}
      dataRows={[]}
      lastRow={[
        t("capitalInvestmentTotal", { funderType: funderType }),
        <Format.Number
          key={1}
          value={data.totalCapitalCosts}
          format={process.env.CURRENCY_CODE}
          decimalPlaces={2}
        />,
      ]}
    />
  );
};

export default CapitalTotalGrid;
