import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "@redriver/cinnamon";
import { Table } from "semantic-ui-react";

const ProductTable = ({
  showSettleProduct = false,
  showRetainProduct = true,
  showUpgrade = false,
}) => {
  const { t } = useTranslation("agreements", {
    keyPrefix: "ScheduleRow.ProductTable",
  });

  const headerKeys = [
    showSettleProduct && "Settle_Product",
    showUpgrade && "Upgrade",
    "Model",
    "Identifier_Number",
    "Postcode",
    showRetainProduct && "Retain_Product",
  ].filter((x) => x);

  return (
    <Table className="schedule-product-table center-align">
      <Table.Header>
        <Table.Row>
          {headerKeys.map((key) => (
            <Table.HeaderCell key={key}>{t(key)}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Form.Array field="products" arrayKey="id" propagateUpdates="always">
          {({ fields }) => (
            <Table.Row>
              {showSettleProduct && (
                <Table.Cell>
                  <Form.Checkbox field="settleProduct"></Form.Checkbox>
                </Table.Cell>
              )}
              {showUpgrade && (
                <Table.Cell className="center">
                  <Form.Checkbox field="upgrade"></Form.Checkbox>
                </Table.Cell>
              )}
              <Table.Cell>{fields.model}</Table.Cell>
              <Table.Cell>{fields.identifierNumber}</Table.Cell>
              <Table.Cell>{fields.postcode}</Table.Cell>
              {showRetainProduct && (
                <Table.Cell className="center">
                  <Form.Checkbox field="retainProduct"></Form.Checkbox>
                </Table.Cell>
              )}
            </Table.Row>
          )}
        </Form.Array>
      </Table.Body>
    </Table>
  );
};

export default ProductTable;
