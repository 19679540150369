import React from "react";
import { useTranslation } from "react-i18next";
import { compose } from "redux";
import { Form, getAuthClaim } from "@redriver/cinnamon";
import { Image } from "semantic-ui-react";
import { FixedInput, FixedService } from "components/forms";
import FormArrayDeleteModal from "features/../../../shared/components/forms/FormArrayDeleteModal";
import Lock from "features/../../../shared/assets/lock.svg";
import classNames from "classnames";
import { getSheetDataState } from "features/Sheets";
import { isFieldFixed } from "features/Sheets/ManageSheet";
import { connect } from "react-redux";
import { withCurrentUser } from "features/../../../shared/components/auth";

const ServicesArray = ({
  field,
  className,
  sheetId,
  popoverVisible,
  setPopoverVisible,
  sheetDetailsSaving,
  allServices,
  impersonatedUser,
  preventUpdates,
  currentUser,
}) => {
  const { t } = useTranslation("quarterlyServices");

  const MandatoryIndicator = (
    <div className="fixed-input-indicator fix-input-type-lock">
      <p title="This service is required">
        <Image src={Lock} />
      </p>
    </div>
  );

  const hideFixedIndicator = !impersonatedUser.canEditAdminFields;

  return (
    <div
      className={classNames(
        "services-body",
        field == "ipServices" ? "services-bottom" : "",
        popoverVisible && "disable-overflow no-margin",
      )}
    >
      <div
        className={classNames(
          "services-edit-list",
          popoverVisible && "disable-overflow no-margin overflow-disable",
        )}
      >
        <Form.Array field={field} arrayKey="id" propagateUpdates="always">
          {({ fields }) => {
            const service =
              allServices.find((x) => x.id === fields.service.value) || {};

            return (
              <div
                className={`service-field-group${
                  fields.service.isMandatory ? " mandatory-service" : ""
                }${fields.isAdmin ? " admin-fields" : ""}`}
              >
                {fields.service.isMandatory ? (
                  <div className="blue-text small-margin">
                    <Form.Object field="service" propagateUpdates="always">
                      <Form.Input
                        label={t("Quarterly_Service")}
                        field="serviceName"
                        className="service"
                        disabled
                        inline
                        fluid
                        subLabel={MandatoryIndicator}
                        subLabelPosition="right"
                      />
                    </Form.Object>
                  </div>
                ) : (
                  <React.Fragment>
                    <FixedService
                      textField="serviceName"
                      field="service"
                      className={className}
                      label={t("Quarterly_Service")}
                      sheetId={sheetId}
                      includeMandatory={false}
                      onFocus={() => setPopoverVisible(true)}
                      onBlur={() => setPopoverVisible(false)}
                      onClose={() => setPopoverVisible(false)}
                      hideIndicator={hideFixedIndicator}
                      disabled={isFieldFixed(fields.value) || preventUpdates}
                    />
                    <Form.Trigger
                      field="service"
                      event="onChange"
                      action="change"
                      target={"value"}
                      value={({ fields }) => {
                        return {
                          ...fields.value,
                          value: service
                            ? service.defaultValue
                            : fields.value?.value,
                        };
                      }}
                    />
                  </React.Fragment>
                )}
                <FixedInput
                  field="value"
                  label={t("Value")}
                  inline
                  fluid
                  currency
                  formatNumber
                  className={`service-value ${className}`}
                  maxAllowValue={999999.99}
                  editorPopupClass="services"
                  minAllowValue={
                    currentUser.isInternalUser
                      ? allServices?.find((x) => x.id == fields?.service?.value)
                          ?.vMinValue ?? 0
                      : allServices?.find((x) => x.id == fields?.service?.value)
                          ?.minValue ?? 0
                  }
                  hideIndicator={hideFixedIndicator}
                />
                <div className="service-sku">
                  <div className="label">{t("SKU")}</div>
                  <div className="value">{service.sku}</div>
                </div>
                <div className="service-billing-code">
                  <div className="label">{t("Billing_Code")}</div>
                  <div className="value">{service.billingCode}</div>
                </div>
                {!fields.service.isMandatory ? (
                  <FormArrayDeleteModal
                    disabled={
                      sheetDetailsSaving ||
                      preventUpdates ||
                      fields.service.isFixed ||
                      isFieldFixed(fields.value)
                    }
                    nameOfRemoval={t("Service")}
                  />
                ) : (
                  <div style={{ minWidth: "1.75em" }} />
                )}
              </div>
            );
          }}
        </Form.Array>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { impersonatedUser, preventUpdates } = getSheetDataState(state);
  return {
    impersonatedUser,
    preventUpdates,
    userArea: getAuthClaim(state, "userArea"),
  };
};

export default compose(
  withCurrentUser,
  connect(mapStateToProps, null),
)(ServicesArray);
