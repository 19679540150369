import React from "react";
import { useDispatch } from "react-redux";
import { Grid, GridColumn, GridRow } from "semantic-ui-react";
import {
  ModalBuilder,
  Form,
  notifySuccess,
  ActionMenu,
  Format,
} from "@redriver/cinnamon";
import { getFullUpgrade, requestFullUpgrade } from "./actions";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { Actions, Targets } from "constants/permissions";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { UnitTable, RetainProductsAndTable } from "./Components";

const FullUpgradeRequest = ({
  as: As = ActionMenu.Item,
  scheduleId,
  disabled,
  creditAvailable,
}) => {
  const { t } = useTranslation("agreements", { keyPrefix: "ScheduleRow" });
  const { t: tfu } = useTranslation("agreements", {
    keyPrefix: "ScheduleRow.FullUpgrade",
  });

  const dispatch = useDispatch();

  return (
    <PermissionCheck action={Actions.SubmitRequest} target={Targets.Schedules}>
      <PermissionCheck action={Actions.View} target={Targets.Schedules}>
        <ModalBuilder
          withForm
          loadAction={getFullUpgrade}
          loadParams={scheduleId}
          submitAction={requestFullUpgrade}
          submitParams={scheduleId}
          onSubmitted={() =>
            dispatch(notifySuccess(t("Actions.Submitted_Successfully")))
          }
          renderTrigger={(showModal) => (
            <As onClick={showModal} disabled={disabled}>
              {t("Actions.Full_Upgrade")}
            </As>
          )}
          renderModal={(modalProps, formProps) => {
            return (
              <TranslatedModal.Edit
                {...modalProps}
                header={tfu("Header")}
                submitLabel={tfu("Submit_Label")}
                className="full-upgrade-modal upgrade-request-modal"
                submitDisabled={
                  !formProps.value.applicableFromPrevious &&
                  !formProps.value.applicableFromNext
                }
              >
                <Form {...formProps}>
                  {() => (
                    <React.Fragment>
                      <Grid className="upgrade-types">
                        <GridRow>
                          <GridColumn width={1} className="right-align">
                            <Form.Checkbox
                              field="applicableFromPrevious"
                              checked={formProps.value.applicableFromPrevious}
                              fluid
                            />
                          </GridColumn>
                          <GridColumn width={15}>
                            <label>{tfu("applicable_from_previous")}</label>
                          </GridColumn>
                        </GridRow>
                        <GridRow>
                          <GridColumn width={1} className="right-align">
                            <Form.Checkbox
                              field="applicableFromNext"
                              checked={formProps.value.applicableFromNext}
                              fluid
                            />
                          </GridColumn>
                          <GridColumn width={10}>
                            <label>{tfu("applicable_from_next")}</label>
                          </GridColumn>
                          <GridColumn
                            width={5}
                            className="red-highlight credit-available"
                          >
                            <span className="credit-available-label">
                              {tfu("Credit_Available")}
                            </span>
                            <Format.Number
                              decimalPlaces={2}
                              thousandSeparator
                              format={process.env.CURRENCY_CODE}
                              value={creditAvailable || 0}
                            />
                          </GridColumn>
                        </GridRow>
                      </Grid>

                      <div className="unit-table-container">
                        <div className="vertical-scrollbar large">
                          <UnitTable formProps={formProps} />
                        </div>
                      </div>

                      <RetainProductsAndTable formProps={formProps} />

                      <Form.TextArea
                        field="comments"
                        label={tfu("Comments_Label")}
                        width="100%"
                      />
                    </React.Fragment>
                  )}
                </Form>
              </TranslatedModal.Edit>
            );
          }}
        />
      </PermissionCheck>
    </PermissionCheck>
  );
};

export default FullUpgradeRequest;
