import { LargeFormatPriceType } from "features/../../../shared/constants/enums";

export const largeFormatCreateModalFieldName = "largeFormatCreateModal";
export const newLargeFormatId = "new";

const inkUsageCalc = {
  inkAndMaintenance: [0, 0],
  costPerUnit2: [0, 0],
  total: [0, 0],
};

export const emptyCreateModal = {
  printerId: null,
  inkInclusiveId: null,
  compInkInclusiveId: null,
  includeScan: false,
  includeCleaning: false,
  priceType: LargeFormatPriceType.PriceA,
  lfRollMedia: [],
  costPerUnit: 0,
  lfInkInclusive: {
    inkUsedML: 0,
    imageUrl: null,
  },
  compLfInkInclusive: {
    inkUsedML: 0,
    imageUrl: null,
  },
  lfPrinterConfig: {
    paperWidthmm: 0,
    paperWidthInches: 0,
    costPerML: 0,
    priceBVisible: false,
  },
  inkUsageCalcIncludingCapital: inkUsageCalc,
  inkUsageCalcExcludingCapital: inkUsageCalc,
  compInkUsageCalcIncludingCapital: inkUsageCalc,
  compInkUsageCalcExcludingCapital: inkUsageCalc,
  editMode: false,
};
