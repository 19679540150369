import React from "react";
import { Link } from "react-router-dom";
import { Image, Button } from "semantic-ui-react";
import classNames from "classnames";
import { withCurrentUser } from "features/../../../shared/components/auth";

const NavGroup = ({
  title,
  description,
  icon,
  items = [],
  className,
  singleColumn = false,
  onNav,
  currentUser,
  ...otherProps
}) => {
  const css = classNames("nav-group", icon ? "has-icon" : "", className);
  return (
    <section className={css} {...otherProps}>
      {icon && <Image src={icon} />}
      <p className="nav-group-title">
        <strong>{title}</strong>
      </p>
      {description && <p className="nav-group-description">{description}</p>}
      <ul className={singleColumn ? "single-col" : ""}>
        {items.map((x) => {
          if (x.userType === currentUser.userType || !x.userType) {
            if (x.hasPermission || x.hasPermission == null) {
              return (
                <li
                  key={x.to}
                  className={
                    !!x.class && !!x.class.length ? x.class : undefined
                  }
                >
                  <Button
                    className="popup-nav-btn"
                    as={Link}
                    to={x.to}
                    content={x.name}
                    onClick={onNav ? onNav : () => {}}
                  />
                </li>
              );
            }
          } else {
            return null;
          }
        })}
      </ul>
    </section>
  );
};

export default withCurrentUser(NavGroup);
