import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { ManageSheet } from "features/Sheets";

const SheetPage = ({ match }) => (
  <PageContent className="sheet-structure">
    {/* using the 'key' param below forces unmounting to fix an issue with persisting states */}
    <ManageSheet sheetId={match.params.id} key={match.params.id} />
  </PageContent>
);

export default SheetPage;
