import React from 'react';
import { Button } from "semantic-ui-react";
import { ModalBuilder } from "@redriver/cinnamon";
import { deleteClientAuthorisedSignatory } from "./actions";
import { useTranslation, Trans } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";

const DeleteClientAuthorisedSignatory = ({ as: As = Button, item, onDeleted, ...props }) => {
    
    const  { t }  = useTranslation('clients', { keyPrefix:'deleteAuthorisedSignatoriesModal'});

    return (<ModalBuilder
        submitAction={deleteClientAuthorisedSignatory}
        submitParams={{ clientId: item.clientId, itemId: item.id }}
        onSubmitted={onDeleted}
        renderTrigger={showModal => <As onClick={showModal} {...props} />}
        renderModal={modalProps => (
            <TranslatedModal.Confirmation
                {...modalProps}
                header={t("header")} > 
                <Trans t={t} values={{itemName: item.name}} >
                    confirmationMessage
                </Trans>
          </TranslatedModal.Confirmation>
        )}
    />
    );
}

export default DeleteClientAuthorisedSignatory;