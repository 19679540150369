import React from "react";
import { ListBuilder, Table, ActionBar } from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import { getClients } from "features/../../../shared/features/Clients/actions";
import SearchClients from "./SearchClients";
import ClientFilters from "./ClientFilters";
import ClientActions from "./ClientActions";
import AddClient from "./AddClient";
import Add from "features/../../../shared/assets/white-add.svg";
import ClientFilterDisplay from "./ClientFilterDisplay";
import { useTranslation } from "react-i18next";

const ListClients = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <h4>{t("clients:headings:clients")}</h4>
      <ListBuilder
        withTable
        initialPagination={{ pageSize: 25, pageNumber: 1 }}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        loadAction={getClients}
        renderList={(tableProps, state, events) => (
          <div className="content-container principal-list">
            <ActionBar>
              <ActionBar.Item fluid className="flex">
                <SearchClients
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
                <div className="search-filter-display">
                  <ClientFilterDisplay
                    filters={state.filters}
                    onChange={events.onChangeFilters}
                  />
                </div>
              </ActionBar.Item>
              <ActionBar.Item align="right">
                <AddClient
                  primary
                  icon={Add}
                  content={t("clients:tables:clients:newClient:buttonText")}
                  onAdded={events.onRefresh}
                />
              </ActionBar.Item>
              <ActionBar.Item align="right">
                <ClientFilters
                  title={t("clients:tables:clients:filters:title")}
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
              </ActionBar.Item>
            </ActionBar>
            <Table
              {...tableProps}
              dataKey="id"
              emptyMessage={t("clients:tables:clients:noData")}
              selectable
            >
              <Table.Column
                field="name"
                title={t("clients:tables:clients:headings:client")}
              />
              <Table.Column field="dealer" title="Supplier" />
              <Table.Column
                field="phoneNumber"
                title={t("clients:tables:clients:headings:phoneNumber")}
                width="15%"
              />
              <Table.Column
                field="businessType"
                title={t("clients:tables:clients:headings:businessType")}
                width="15%"
              />
              <Table.Column
                field="numSheets"
                title={t("clients:tables:clients:headings:numberOfSheets")}
                width="15%"
              />
              <Table.Column
                field="code"
                title={t("clients:tables:clients:headings:code")}
                width="15%"
              />
              <Table.Column
                hoverable
                width="15%"
                align="right"
                render={(client) => (
                  <ClientActions client={client} onRefresh={events.onRefresh} />
                )}
              />
            </Table>
            <TablePagination
              pageNumber={state.pagination.pageNumber}
              onChange={events.onChangePagination}
              pageSize={state.pagination.pageSize}
              totalItems={state.total}
            />
          </div>
        )}
      />
    </React.Fragment>
  );
};

export default ListClients;
