import React from "react";
import { useTranslation } from "react-i18next";
import { ClientAuthSignTypeAhead } from "features/Clients/AuthorisedSignatories/lookups/lookups";
import { Button } from "semantic-ui-react";
import { Form } from "@redriver/cinnamon";

const ClientSignatory = ({ field, signatoryDropdownLabel, sheetId }) => {
  const { t } = useTranslation("agreements", {
    keyPrefix: "SheetActions.SendForApproval",
  });

  const existenceOptions = [
    { value: false, text: t("Existing") },
    { value: true, text: t("New") },
  ];

  const uniqueDropdownName = `isNew_${field}`;

  return (
    <Form.Object field={field}>
      <Form.RadioGroup field={uniqueDropdownName} options={existenceOptions} />

      <Form.If condition={({ fields }) => fields[uniqueDropdownName] == false}>
        <ClientAuthSignTypeAhead
          sheetId={sheetId}
          field="authorisedSignatoryId"
          label={signatoryDropdownLabel}
        />
      </Form.If>

      <Form.If condition={({ fields }) => fields[uniqueDropdownName] == true}>
        <Form.Input
          field="fullName"
          label={t("FullName")}
          inline
          fluid
          required
        />
        <Form.Input
          field="position"
          label={t("Position")}
          inline
          fluid
          required
        />
        <Form.Email field="email" label={t("Email")} inline fluid required />
        <Form.Input
          field="phoneNumber"
          label={t("PhoneNumber")}
          inline
          fluid
          required
        />
        <Form.Input
          field="phoneCountryCode"
          label={t("PhoneCountryCode")}
          inline
          fluid
          required
        />
      </Form.If>
    </Form.Object>
  );
};

export default ClientSignatory;
