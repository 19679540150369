import React, { useState } from "react";
import FixedFieldType, { FixableTypes } from "constants/forms/FixedFieldType";
import { Form } from "@redriver/cinnamon";
import { Image, Popup } from "semantic-ui-react";
import RangeIcon from "features/../../../shared/assets/plus-minus.svg";
import FixedInputEditor from "./FixedInputEditor";
import classNames from "classnames";
import FixedBooleanEditor from "./FixedBooleanEditor";
import FixedStringEditor from "./FixedStringEditor";
import FixedServiceEditor from "./FixedServiceEditor";
import FixedEnumEditor from "./FixedEnumEditor";
import FixedCheckboxEditor from "./FixedCheckboxEditor";
import FixedDropdownEditor from "./FixedDropdownEditor";

import { UnitOptions } from "features/../../../shared/constants/enums";
import { useTranslation } from "react-i18next";

const fixTypeClass = {
  [FixedFieldType.None]: "none",
  [FixedFieldType.Min]: "min",
  [FixedFieldType.Max]: "max",
  [FixedFieldType.Set]: "set",
  [FixedFieldType.Range]: "range",
};

const determineIndicatorContents = (fixType, t) => {
  switch (fixType) {
    case FixedFieldType.Min:
      return <p title={t("FixedInput.InputIndicator.ForcedMinimum")}>-</p>;
    case FixedFieldType.Max:
      return <p title={t("FixedInput.InputIndicator.ForcedMaximum")}>+</p>;
    case FixedFieldType.Range:
      return (
        <p title={t("FixedInput.InputIndicator.Range")}>
          <Image src={RangeIcon} aria-hidden="true" />
        </p>
      );
    default:
      return (
        <p
          title={
            fixType == FixedFieldType.Set
              ? `${t("FixedInput.InputIndicator.FixedValue")}`
              : `${t("FixedInput.InputIndicator.NoFixing")}`
          }
        >
          F
        </p>
      );
  }
};

const Trigger = ({ fixType, onClick, disabled, t }) => (
  <div
    className={classNames(
      `fixed-input-indicator fix-input-type-${fixTypeClass[fixType]}`,
      disabled && "disabled",
    )}
    onClick={disabled ? null : onClick}
  >
    <div className="trigger-content">
      {determineIndicatorContents(fixType, t)}
    </div>
  </div>
);

const FixedInputIndicator = ({
  isAdmin,
  fixType,
  fixableType,
  lookup,
  lookupParams,
  values,
  fixedValueOnly,
  fieldId,
  onForcedValuesChanged,
  minorCurrency,
  decimalPlaces,
  customEditorData,
  editorPopupClass,
  className,
  disabled,
  minAllowValue,
  maxAllowValue,
  semanticProps,
  displayPriority,
  disableValueChange,
  currentValue,
  disableEditor,
}) => {
  const [popupOpen, togglePopupState] = useState(false);
  const { t } = useTranslation("sheetTranslation");

  return !isAdmin ? (
    <Trigger isAdmin={isAdmin} fixType={fixType} t={t} />
  ) : (
    <Popup
      trigger={
        <Trigger
          isAdmin={isAdmin}
          fixType={fixType}
          onClick={() => togglePopupState(!popupOpen)}
          disabled={disabled}
          t={t}
        />
      }
      flowing
      basic
      open={popupOpen}
      className={classNames(
        "modal-styled-popup-container",
        editorPopupClass,
        displayPriority > 0 ? "displayPriority" + displayPriority : "",
      )}
    >
      {fixableType === FixableTypes.Boolean ? (
        <FixedBooleanEditor
          fixType={fixType}
          onSubmitted={(res) => {
            togglePopupState(false);
            onForcedValuesChanged(res);
          }}
          toggleAction={() => togglePopupState(false)}
          value={values[0]}
          fieldId={fieldId}
          semanticProps={semanticProps}
        />
      ) : fixableType === FixableTypes.String ? (
        <FixedStringEditor
          fixType={fixType}
          onSubmitted={(res) => {
            togglePopupState(false);
            onForcedValuesChanged(res);
          }}
          toggleAction={() => togglePopupState(false)}
          value={values[0]}
          fieldId={fieldId}
          customEditorData={customEditorData}
        />
      ) : fixableType === FixableTypes.Service ? (
        <FixedServiceEditor
          fixType={fixType}
          onSubmitted={(res) => {
            togglePopupState(false);
            onForcedValuesChanged(res);
          }}
          toggleAction={() => togglePopupState(false)}
          value={values[0]}
          fieldId={fieldId}
          customEditorData={customEditorData}
        />
      ) : fixableType === FixableTypes.Unit ? (
        <FixedEnumEditor
          fixType={fixType}
          onSubmitted={(res) => {
            togglePopupState(false);
            onForcedValuesChanged(res);
          }}
          toggleAction={() => togglePopupState(false)}
          value={values[0]}
          fieldId={fieldId}
          customEditorData={customEditorData}
          renderDropdown={() => (
            <Form.Dropdown
              field="fixed"
              options={UnitOptions}
              fluid
              className="unit-dropdown"
            />
          )}
        />
      ) : fixableType === FixableTypes.Checkbox ? (
        <FixedCheckboxEditor
          fixType={fixType}
          onSubmitted={(res) => {
            togglePopupState(false);
            onForcedValuesChanged(res);
          }}
          toggleAction={() => togglePopupState(false)}
          values={values}
          customEditorData={customEditorData}
        />
      ) : fixableType == FixableTypes.Dropdown ? (
        <FixedDropdownEditor
          fixType={fixType}
          onSubmitted={(res) => {
            togglePopupState(false);
            onForcedValuesChanged(res);
          }}
          toggleAction={() => togglePopupState(false)}
          values={values}
          fieldId={fieldId}
          lookup={lookup}
          lookupParams={lookupParams}
          customEditorData={customEditorData}
          disableValueChange={disableValueChange}
          className={className}
        />
      ) : (
        <FixedInputEditor
          fixType={fixType}
          onSubmitted={(res, minorCurrency) => {
            togglePopupState(false);
            onForcedValuesChanged(res, minorCurrency);
          }}
          toggleAction={() => togglePopupState(false)}
          values={values}
          fieldId={fieldId}
          minorCurrency={minorCurrency}
          decimalPlaces={decimalPlaces}
          minAllowValue={minAllowValue}
          maxAllowValue={maxAllowValue}
          fixedValueOnly={fixedValueOnly}
          currentValue={currentValue}
          disabled={disableEditor}
        />
      )}
    </Popup>
  );
};

export default FixedInputIndicator;
