import React from "react";
import { compose } from "redux";
import { ActionMenu } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { UserArea } from "features/../../../shared/constants/enums";
import {
  CreditClearance,
  OutstandingAgreementValue,
  PartSettlement,
  FullUpgradeRequest,
  VolumeRewriteModal,
  RewriteAdditionalCapital,
  PartUpgrade,
} from ".";
import {
  withCurrentUser,
  withPermissions,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";

const RequestPanel = ({ schedule, currentUser, hasPermission }) => {
  const canUseOtherScheduleRequest = hasPermission(
    Targets.Schedules,
    Actions.NonOwnedSubmitRequest,
  );

  const accountManagerDisable =
    currentUser.userArea == UserArea.AccountManager &&
    currentUser.id != schedule.ownedBy?.toLowerCase().replace(/-/g, "") &&
    !canUseOtherScheduleRequest;

  const disableSalesRequests =
    (!process.env.TRADE_AS_IPS && !schedule.isLive) || accountManagerDisable;
  return (
    <React.Fragment>
      <ActionMenu
        disabled={accountManagerDisable}
        trigger={
          <Button
            primary
            content="Make Request"
            style={{ marginBottom: "0px" }}
          />
        }
      >
        <CreditClearance
          as={ActionMenu.Item}
          scheduleId={schedule.scheduleId}
          disabled={accountManagerDisable}
        />
        <ActionMenu.Divider />
        <VolumeRewriteModal
          as={ActionMenu.Item}
          scheduleId={schedule.scheduleId}
          disabled={disableSalesRequests}
        />
        <RewriteAdditionalCapital
          as={ActionMenu.Item}
          scheduleId={schedule.scheduleId}
          disabled={disableSalesRequests}
          creditAvailable={schedule.creditAvailable}
        />
        <PartUpgrade
          as={ActionMenu.Item}
          scheduleId={schedule.scheduleId}
          disabled={disableSalesRequests}
          creditAvailable={schedule.creditAvailable}
        />
        <FullUpgradeRequest
          as={ActionMenu.Item}
          scheduleId={schedule.scheduleId}
          disabled={disableSalesRequests}
          creditAvailable={schedule.creditAvailable}
        />
        <PartSettlement
          as={ActionMenu.Item}
          scheduleId={schedule.scheduleId}
          disabled={disableSalesRequests}
        />
        <ActionMenu.Divider />
        <OutstandingAgreementValue
          as={ActionMenu.Item}
          scheduleId={schedule.scheduleId}
          disabled={disableSalesRequests}
        />
        <div className="clear"></div>
      </ActionMenu>
    </React.Fragment>
  );
};

export default compose(withCurrentUser, withPermissions)(RequestPanel);
