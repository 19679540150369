import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  Form,
  Format,
  getLookupData,
  ModalBuilder,
  useFormField,
} from "@redriver/cinnamon";
import classNames from "classnames";
import { Icon, Transition } from "semantic-ui-react";
import { IconButton } from "features/../../../shared/components/buttons";
import { InlineLoader } from "features/../../../shared/components/forms/InlineLoader";
import {
  newFixedInput,
  newFixedString,
  SectionTotal,
  FixedService,
  FixedInput,
} from "components/forms";
import {
  withPermissions,
  UserTypeCheck,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import {
  LookupNames as SheetsLookupNames,
  ActionTypes as SheetsActionTypes,
  getSheetState,
  getSheetIpsRatesState,
} from "features/Sheets";
import SheetStep from "../SheetStep";
import Add from "features/../../../shared/assets/black-add.svg";
import AdminAdd from "features/../../../shared/assets/green-add.svg";
import {
  UserType,
  SheetViewType,
} from "features/../../../shared/constants/enums";
import IPAdd from "features/../../../shared/assets/ips-add-cross.svg";
import ServicesArray from "./ServicesArray";
import { cannotCalcMessages } from "../actions";
import { QrtServiceFieldType } from "../constants";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { isFieldFixed } from "features/Sheets/ManageSheet";
import withCurrentUser from "features/../../../shared/components/auth/withCurrentUser";

const emptyIncomingService = {
  value: newFixedInput(),
  service: { value: null },
};

const AddQrtService = ({
  triggerClassName,
  field,
  fieldType,
  triggerIcon,
  triggerContent,
  modalHeader,
  sheetId,
  hideIndicator,
  services,
  currentUser,
  disabled,
  modalClassName,
}) => {
  const isIpsAdminUser = currentUser.userType === UserType.HQ;
  const { t } = useTranslation("quarterlyServices");
  const incomingServiceField = "incomingService",
    [incomingService, setIncomingService] = useFormField(incomingServiceField),
    [arrayField, setArrayField] = useFormField(field);

  return (
    <ModalBuilder
      onLoad={() => setIncomingService(emptyIncomingService)}
      onSubmitted={() => {
        setArrayField(
          [
            arrayField,
            {
              id: Date.now(),
              service: incomingService.service,
              value: incomingService.value,
              sku: newFixedString(),
              billingCode: newFixedString(),
              fieldType,
            },
          ].flat(),
        );
        setIncomingService(emptyIncomingService);
      }}
      renderTrigger={(trigger) => (
        <IconButton
          className={`wizard-title-button ${triggerClassName}`}
          icon={triggerIcon}
          content={triggerContent}
          onClick={trigger}
          disabled={disabled}
        />
      )}
      renderModal={(modalProps) => (
        <TranslatedModal.Add
          withForm
          {...modalProps}
          className="new-qrt-srv"
          header={modalHeader}
          submitDisabled={
            !incomingService ||
            !(incomingService?.service || {}).value ||
            !(incomingService?.value || {}).value ||
            !Array.isArray(services) ||
            (services.find((x) => x.id == incomingService?.service?.value)
              ?.minValue > incomingService?.value?.value &&
              !isIpsAdminUser) ||
            (services.find((x) => x.id == incomingService?.service?.value)
              ?.vMinValue > incomingService?.value?.value &&
              isIpsAdminUser)
          }
        >
          <div className="ui form highlighted-inputs">
            <Form.Object field={incomingServiceField}>
              <div className="services-container">
                <div className="services-body">
                  <div className="services-edit-list">
                    <div className="service-field-group add-quarterly-service">
                      <FixedService
                        textField="serviceName"
                        field="service"
                        label={t("Quarterly_Service")}
                        sheetId={sheetId}
                        includeMandatory={false}
                        hideIndicator={hideIndicator}
                        displayPriority={4}
                        className={`new-quarterly-service-dropdown ${modalClassName}`}
                        disabled={
                          incomingService?.value &&
                          isFieldFixed(incomingService.value)
                        }
                      />
                      <Form.Trigger
                        field="service"
                        event="onChange"
                        action="change"
                        target={"value"}
                        value={({ fields }) => {
                          const matchedService = services.find(
                            (x) => x.id == fields.service.value,
                          );

                          return {
                            ...fields.value,
                            value: matchedService?.defaultValue,
                          };
                        }}
                      />
                      <FixedInput
                        className="addQrtServiceValue"
                        inline
                        fluid
                        currency
                        formatNumber
                        field="value"
                        label={t("Value")}
                        maxAllowValue={999999.99}
                        editorPopupClass="services"
                        hideIndicator={hideIndicator}
                        displayPriority={4}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form.Object>
          </div>
        </TranslatedModal.Add>
      )}
    />
  );
};

const ServicesStep = ({
  sheetData,
  onNextStep,
  onPreviousStep,
  hasPermission,
  sheetId,
  displayPrevious,
  displayNext,
  displaySave,
  collapsed,
  toggleVisibility,
  reactRef,
  calculating,
  sheetDetailsSaving,
  unlockDisableMinVolsLogic,
  allServices,
  customerToggle,
  sheetViewType,
  displayCustomerToggle = false,
  impersonatedUser,
  servicesContent,
  currentUser,
  preventUpdates,
}) => {
  const { t } = useTranslation("quarterlyServices");
  const [popoverVisible, setPopoverVisible] = useState(false);

  if (!hasPermission(Targets.Sheet, Actions.ViewQuarterlyService)) {
    return null;
  }

  const hideFixedIndicator = !impersonatedUser.canEditAdminFields;

  //get correct services total based on sheet view
  let servicesTotal = servicesContent.salesperson;

  if (sheetViewType === SheetViewType.SupplierAdmin) {
    servicesTotal = servicesContent.dealerAdmin;
  }

  if (sheetViewType === SheetViewType.IpsAdmin) {
    servicesTotal = servicesContent.ipsAdmin;
  }

  return (
    <div ref={reactRef} className="step-container">
      <SheetStep
        onNextStep={onNextStep}
        onPreviousStep={onPreviousStep}
        displayPrevious={displayPrevious}
        displayNext={displayNext}
        displaySave={displaySave}
        calculating={calculating}
        sheetId={sheetId}
        displayCustomerToggle={displayCustomerToggle}
        customerToggle={customerToggle}
        className="services-step"
        errorMessage={cannotCalcMessages.services}
      >
        <Form.Object field="services" propagateUpdates="always">
          <div className="services-header">
            <div className="sheet-step-header" onClick={toggleVisibility}>
              <div className="title">
                <h5>{t("Quarterly_Services")}</h5>
                {toggleVisibility && (
                  <Icon
                    name="dropdown"
                    rotated={collapsed ? "counterclockwise" : null}
                  />
                )}
              </div>
            </div>
          </div>
          <Transition animation="fade down" visible={!collapsed} duration={500}>
            <div>
              <div className="services-top-section flex space-between">
                <div className="services-adders">
                  <AddQrtService
                    triggerClassName="supplier-button new-service-button"
                    fieldType={QrtServiceFieldType.Standard.value}
                    field="services"
                    triggerIcon={Add}
                    triggerContent={t("Add_New_Service")}
                    modalHeader={t("New_Quarterly_Service")}
                    sheetId={sheetData.id}
                    hideIndicator={hideFixedIndicator}
                    services={allServices}
                    currentUser={currentUser}
                    disabled={preventUpdates}
                    modalClassName="add-new-service-modal"
                  />
                  {hasPermission(Targets.SheetAdmin, Actions.Edit) &&
                    impersonatedUser.canEditAdminFields &&
                    (sheetViewType === SheetViewType.IpsAdmin ||
                      sheetViewType === SheetViewType.SupplierAdmin) && (
                      <AddQrtService
                        triggerClassName="admin-button"
                        fieldType={QrtServiceFieldType.Admin.value}
                        field="adminServices"
                        triggerIcon={AdminAdd}
                        triggerContent={t("Add_New_Supplier_Admin_Service")}
                        modalHeader={t("New_Supplier_Admin_Service")}
                        sheetId={sheetData.id}
                        hideIndicator={hideFixedIndicator}
                        services={allServices}
                        currentUser={currentUser}
                        disabled={preventUpdates}
                        modalClassName="add-new-supplier-admin-service-modal"
                      />
                    )}
                  <UserTypeCheck restrictTo={UserType.HQ}>
                    {sheetViewType === SheetViewType.IpsAdmin && (
                      <AddQrtService
                        triggerClassName="ip-button"
                        fieldType={QrtServiceFieldType.Ip.value}
                        field="ipServices"
                        triggerIcon={IPAdd}
                        triggerContent={t("Add_New_IPS_Service")}
                        modalHeader={t("New_IPS_Service")}
                        sheetId={sheetData.id}
                        hideIndicator={hideFixedIndicator}
                        services={allServices}
                        currentUser={currentUser}
                        disabled={preventUpdates}
                        modalClassName="add-new-ips-service-modal"
                      />
                    )}
                  </UserTypeCheck>
                </div>
                {hasPermission(Targets.SheetAdmin, Actions.Edit) &&
                  impersonatedUser.canEditAdminFields &&
                  (sheetViewType === SheetViewType.IpsAdmin ||
                    sheetViewType === SheetViewType.SupplierAdmin) && (
                    <div className="admin-fields">
                      <Form.RadioGroup
                        inline
                        field="servicesDisableMinVolsLogic"
                        disabled={
                          !unlockDisableMinVolsLogic ||
                          sheetDetailsSaving ||
                          preventUpdates
                        }
                        label={
                          <div className="sheet-radio-title">
                            <p>{t("Disable_Min_Logic?")}</p>
                          </div>
                        }
                        options={[
                          { value: 1, text: t("Yes") },
                          { value: 0, text: t("No") },
                        ]}
                        className="required-field sheet-radio-group"
                      />
                    </div>
                  )}
              </div>
              <div
                className={classNames(
                  "services-container",
                  popoverVisible && " overflow-disable",
                  !hasPermission(Targets.SheetAdmin, Actions.Edit) &&
                    impersonatedUser.canEditAdminFields &&
                    "short",
                )}
              >
                <ServicesArray
                  field="mandatoryServices"
                  sheetId={sheetId}
                  isAdmin={
                    hasPermission(Targets.SheetAdmin, Actions.Edit) &&
                    impersonatedUser.canEditAdminFields
                  }
                  setPopoverVisible={setPopoverVisible}
                  popoverVisible={popoverVisible}
                  sheetDetailsSaving={sheetDetailsSaving}
                  allServices={allServices}
                />

                <ServicesArray
                  field="services"
                  sheetId={sheetId}
                  isAdmin={
                    hasPermission(Targets.SheetAdmin, Actions.Edit) &&
                    impersonatedUser.canEditAdminFields
                  }
                  setPopoverVisible={setPopoverVisible}
                  popoverVisible={popoverVisible}
                  sheetDetailsSaving={sheetDetailsSaving}
                  allServices={allServices}
                />
                {hasPermission(Targets.SheetAdmin, Actions.Edit) &&
                  impersonatedUser.canEditAdminFields &&
                  (sheetViewType === SheetViewType.IpsAdmin ||
                    sheetViewType === SheetViewType.SupplierAdmin) && (
                    <ServicesArray
                      field="adminServices"
                      className="green-text"
                      sheetId={sheetId}
                      isAdmin={
                        hasPermission(Targets.SheetAdmin, Actions.Edit) &&
                        impersonatedUser.canEditAdminFields
                      }
                      setPopoverVisible={setPopoverVisible}
                      popoverVisible={popoverVisible}
                      sheetDetailsSaving={sheetDetailsSaving}
                      allServices={allServices}
                    />
                  )}
                <UserTypeCheck restrictTo={UserType.HQ}>
                  {sheetViewType === SheetViewType.IpsAdmin && (
                    <ServicesArray
                      field="ipServices"
                      className="ips-color-text"
                      sheetId={sheetId}
                      isAdmin={
                        hasPermission(Targets.SheetAdmin, Actions.Edit) &&
                        impersonatedUser.canEditAdminFields
                      }
                      setPopoverVisible={setPopoverVisible}
                      popoverVisible={popoverVisible}
                      sheetDetailsSaving={sheetDetailsSaving}
                      allServices={allServices}
                    />
                  )}
                </UserTypeCheck>
              </div>
              <SectionTotal
                clearing
                label={t("Quarterly_Services_Total")}
                className="services-totaliser"
              >
                {calculating ? (
                  <InlineLoader />
                ) : (
                  <Format.Number
                    value={servicesTotal || 0}
                    format={process.env.CURRENCY_CODE}
                    className={classNames(
                      "totaliser-value",
                      servicesTotal < 0 && "negative",
                    )}
                  />
                )}
              </SectionTotal>
            </div>
          </Transition>
        </Form.Object>
      </SheetStep>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { sheetData, sheetDetailsSaving, sheetViewType, servicesContent } =
    getSheetState(state);
  const { impersonatedUser, preventUpdates } = sheetData;
  const { unlockDisableMinVolsLogic } = getSheetIpsRatesState(state);
  const allServices =
    (
      getLookupData(state, SheetsLookupNames[SheetsActionTypes.Services], {
        sheetId: sheetData.id,
        search: "",
        includeMandatory: true,
      }) || {}
    ).response || [];

  return {
    sheetData,
    servicesContent,
    sheetDetailsSaving,
    unlockDisableMinVolsLogic,
    allServices,
    sheetViewType,
    impersonatedUser,
    preventUpdates,
  };
};

export default connect(
  mapStateToProps,
  {},
)(compose(withCurrentUser, withPermissions)(ServicesStep));
