import React from "react";
import { FormBuilder } from "@redriver/cinnamon";
import {
  getDealerOffice,
  DealerOfficeForm,
  getDealerOfficeLogo,
  getDealerOfficeHeaderImage,
} from "./ListDealerOffices";
import DealerOfficeLogoViewForm from "./ListDealerOffices/DealerOfficeLogoViewForm";
import EditDealerOffice from "./ListDealerOffices/EditDealerOffice";
import EditDealerOfficeLogo from "./ListDealerOffices/EditDealerOfficeLogo";
import DealerOfficeHeaderImageViewForm from "./ListDealerOffices/DealerOfficeHeaderImageViewForm";
import EditDealerOfficeHeaderImage from "./ListDealerOffices/EditDealerOfficeHeaderImage";
import { Targets, Actions } from "constants/permissions";
import { withPermissions } from "features/../../../shared/components/auth";
import { Trans, useTranslation } from "react-i18next";

const DealerOfficeOverview = ({
  dealerOfficeId,
  dealerOfficeName,
  hasPermission,
}) => {
  const { t } = useTranslation("supplierOffices", { keyPrefix: "overview" });

  return (
    <React.Fragment>
      <h4>
        {dealerOfficeName ? (
          <Trans t={t} values={{ name: dealerOfficeName }}>
            overviewSupplierOffices
          </Trans>
        ) : (
          t("overview")
        )}
      </h4>
      <div className="dealer-overview-container">
        <div className="content-container not-list dealer-overview details">
          <FormBuilder
            loadAction={getDealerOffice}
            loadParams={dealerOfficeId}
            renderForm={(formProps, state, events) => (
              <React.Fragment>
                <div className="dealer-form-container">
                  <DealerOfficeForm
                    disabled
                    {...formProps}
                    className="no-disable-box"
                  />
                </div>
                {hasPermission(Targets.Dealer, Actions.Edit) && (
                  <div className="edit-button-container">
                    <EditDealerOffice
                      dealerOfficeId={dealerOfficeId}
                      onUpdate={events.onRefresh}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <div className="content-container not-list dealer-overview logo">
          <FormBuilder
            loadAction={getDealerOfficeLogo}
            loadParams={dealerOfficeId}
            renderForm={(formProps, state, events) => (
              <React.Fragment>
                <div className="dealer-form-container">
                  <DealerOfficeLogoViewForm {...formProps} />
                </div>
                {hasPermission(Targets.Dealer, Actions.Edit) && (
                  <div className="edit-button-container">
                    <EditDealerOfficeLogo
                      dealerOfficeId={dealerOfficeId}
                      onUpdate={events.onRefresh}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
          />
        </div>

        <div className="content-container not-list dealer-overview header-image">
          <FormBuilder
            loadAction={getDealerOfficeHeaderImage}
            loadParams={{ dealerOfficeId }}
            renderForm={(formProps, state, events) => (
              <React.Fragment>
                <div className="dealer-form-container">
                  <DealerOfficeHeaderImageViewForm {...formProps} />
                </div>
                {hasPermission(Targets.Dealer, Actions.Edit) && (
                  <div className="edit-button-container">
                    <EditDealerOfficeHeaderImage
                      dealerOfficeId={dealerOfficeId}
                      onUpdate={events.onRefresh}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withPermissions(DealerOfficeOverview);
