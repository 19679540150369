import i18n from "i18next";
import { initReactI18next } from "react-i18next";

//en-US
import comparisonSheetTranslationsUS from "./en-US/ComparisonSheet.t.json";
import paperInclusiveTranslationsUS from "./en-US/PaperInclusive.t.json";
import systemScheduleTranslationsUS from "./en-US/SystemSchedule.t.json";
import enumsTranslationsUS from "features/../../../shared/translation/en-US/Enums.t.json";

//en-GB
import systemTranslationsEN from "./en-GB/System.t.json";
import mainMenuTranslationsEN from "./en-GB/MainMenu.t.json"; // (for top menu)
import agreementsPageTranslationsEN from "./en-GB/AgreementsPage.t.json";
import sheetHeaderTranslationsEN from "./en-GB/SheetHeader.t.json";
import sheetSummaryTranslationsEN from "./en-GB/SheetSummary.t.json";
import sheetTranslationsEN from "./en-GB/Sheet.t.json"; //(for any text outside of the areas covered by translation files and  shared text, like buttons, download confirmation modal text, etc)
import comparisonSheetTranslationsEN from "./en-GB/ComparisonSheet.t.json";
import customerViewTranslationsEN from "./en-GB/CustomerView.t.json";
import clientTranslationsEN from "./en-GB/Clients.t.json";
import ipsRatesTranslationsEN from "./en-GB/IPSRatesAndSettings.t.json";
import scheduleSetupTranslationsEN from "./en-GB/ScheduleSetup.t.json";
import capitalCalcTranslationsEN from "./en-GB/CapitalCalc.t.json";
import quarterlyServicesTranslationsEN from "./en-GB/QuarterlyServices.t.json";
import paperInclusiveTranslationsEN from "./en-GB/PaperInclusive.t.json";
import systemScheduleTranslationsEN from "./en-GB/SystemSchedule.t.json";
import systemScheduleStabilityTranslationsEN from "./en-GB/SystemScheduleStability.t.json";
import productScheduleTranslationsEN from "./en-GB/ProductSchedule.t.json";
import notificationsTranslationsEN from "./en-GB/Notifications.t.json";
import sharedTranslationsEN from "features/../../../shared/translation/en-GB/Shared.t.json";
import enumsTranslationsEN from "features/../../../shared/translation/en-GB/Enums.t.json";
import supplierOfficesTranslationsEN from "features/../../../shared/translation/en-GB/SupplierOffices.t.json";
import dataUploadsTranslationsEN from "features/../../../shared/translation/en-GB/DataUploads.t.json";

const resources = {
  "en-GB": {
    // Namespaces
    shared: sharedTranslationsEN,
    menu: mainMenuTranslationsEN,
    system: systemTranslationsEN,
    agreements: agreementsPageTranslationsEN,
    sheetHeader: sheetHeaderTranslationsEN,
    sheetSummary: sheetSummaryTranslationsEN,
    sheetTranslation: sheetTranslationsEN,
    comparisonSheet: comparisonSheetTranslationsEN,
    customerView: customerViewTranslationsEN,
    clients: clientTranslationsEN,
    ipsRates: ipsRatesTranslationsEN,
    scheduleSetup: scheduleSetupTranslationsEN,
    capitalCalc: capitalCalcTranslationsEN,
    quarterlyServices: quarterlyServicesTranslationsEN,
    paperInclusive: paperInclusiveTranslationsEN,
    systemSchedule: systemScheduleTranslationsEN,
    productSchedule: productScheduleTranslationsEN,
    enums: enumsTranslationsEN,
    supplierOffices: supplierOfficesTranslationsEN,
    dataUploads: dataUploadsTranslationsEN,
    systemScheduleStability: systemScheduleStabilityTranslationsEN,
    notifications: notificationsTranslationsEN,
  },
  "en-US": {
    // Namespaces
    shared: sharedTranslationsEN,
    menu: mainMenuTranslationsEN,
    system: systemTranslationsEN,
    agreements: agreementsPageTranslationsEN,
    sheetHeader: sheetHeaderTranslationsEN,
    sheetSummary: sheetSummaryTranslationsEN,
    sheetTranslation: sheetTranslationsEN,
    comparisonSheet: comparisonSheetTranslationsUS,
    customerView: customerViewTranslationsEN,
    clients: clientTranslationsEN,
    ipsRates: ipsRatesTranslationsEN,
    scheduleSetup: scheduleSetupTranslationsEN,
    capitalCalc: capitalCalcTranslationsEN,
    quarterlyServices: quarterlyServicesTranslationsEN,
    paperInclusive: paperInclusiveTranslationsUS,
    systemSchedule: systemScheduleTranslationsUS,
    productSchedule: productScheduleTranslationsEN,
    enums: enumsTranslationsUS,
    supplierOffices: supplierOfficesTranslationsEN,
    dataUploads: dataUploadsTranslationsEN,
    systemScheduleStability: systemScheduleStabilityTranslationsEN,
    notifications: notificationsTranslationsEN,
  },
};

i18n.use(initReactI18next).init({
  resources,
  defaultNS: "system",
  lng: process.env.CULTURE_CODE,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
