import React from "react";
import { SidePanel, Form } from "@redriver/cinnamon";
import { FilterButton } from "features/../../../shared/components/buttons";
import { DealersTypeAheadLookup } from "../../Dealers/lookups";
import {
  UserStatusOptions,
  getUserAreaOptions
} from "features/../../../shared/constants/enums";
import { withCurrentUser } from "features/../../../shared/components/auth";
import { useTranslation } from "react-i18next";
import { getUser } from "./actions";

export const FieldNames = {
  UserStatuses: "userStatuses",
  UserAreas: "userAreas",
  CompanyId: "companyId",
};

const UsersFilterSidePanel = ({
  isInternalUser,
  companyId,
  currentUser,
  formProps,
  onReset,
}) => {
  const { t } = useTranslation('supplierOffices', { keyPrefix: 'users' });
  return (
    <SidePanel
      closeIcon
      className="users-filter-side-panel"
      trigger={<FilterButton />}
    >
      <div>
        <div className="ui header filters-title">
          <div className="title-text">{t("userFilters")}</div>
          <div className="clear-button" onClick={onReset}>
            ({t("clear")})
          </div>
        </div>
        <Form {...formProps} className="highlighted-inputs">
          <div className="ui form highlighted-inputs">
            <Form.CheckboxGroup
              field={FieldNames.UserStatuses}
              options={UserStatusOptions}
            />

            <Form.CheckboxGroup
              label="Type"
              field={FieldNames.UserAreas}
              options={getUserAreaOptions(isInternalUser)}
            />

            {isInternalUser && !companyId && (
              <DealersTypeAheadLookup
                field={FieldNames.CompanyId}
                label={t("supplier")}
                textField="dealer"
                fluid
                clearable
              />
            )}
          </div>
        </Form>
      </div>
    </SidePanel>
  );
};
export default withCurrentUser(UsersFilterSidePanel);
