import React from "react";
import { useTranslation } from "react-i18next";
import { PageContent } from "@redriver/cinnamon";


const PageNotFound = () => {
  const { t } = useTranslation('system');
  return(
  <PageContent>
    <h4>{t('Page_Not_Found')}</h4>
    <div className="content-container not-list client-overview">
        <p>{t('Page_not_found_description')}</p>
    </div>
  </PageContent>
)};

export default PageNotFound;
