export const PaymentMethod = {
  QuarterlyDirectDebit: "Quarterly in Advance by Direct Debit",
  MonthlyInvoice:
    "Monthly by Invoice, late charges apply, but figures presented quarterly",
};

export const PaymentMethodEnum = {
  QuarterlyDirectDebit: "QuarterlyDirectDebit",
  MonthlyInvoice: "MonthlyInvoice",
};
