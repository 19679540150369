import React from "react";
import { Message, Button, Icon } from "semantic-ui-react";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";

const LockMessage = ({ lockedByName, adminUnlocked, onForceUnlock }) => {
  return (
    <Message color="orange" icon compact className="lock-message">
      <Icon name={adminUnlocked ? "lock open" : "lock"} size="mini" />

      <Message.Content>
        {adminUnlocked ? (
          <p>This sheet has now been unlocked.</p>
        ) : (
          <p>
            This sheet is currently being edited by {lockedByName}. Please
            contact the administrator if you require it to be unlocked.{" "}
            <PermissionCheck target={Targets.Sheet} action={Actions.ClearLock}>
              <Button color="orange" size="tiny" onClick={onForceUnlock}>
                Force unlock?
              </Button>
            </PermissionCheck>
          </p>
        )}
      </Message.Content>
    </Message>
  );
};

export default LockMessage;
