import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder } from "@redriver/cinnamon";
import { deleteDealerOffice } from "./actions";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { useTranslation, Trans } from "react-i18next";

const DeleteDealerOffice = ({ as: As = Button, dealerOffice, onDeleted, ...props }) => {
  
  const  { t }  = useTranslation('supplierOffices', { keyPrefix:'deleteModal'});

  return(
  <ModalBuilder
    submitAction={deleteDealerOffice}
    submitParams={dealerOffice.id}
    onSubmitted={onDeleted}
    renderTrigger={showModal => <As onClick={showModal} {...props} />}
    renderModal={modalProps => (
      <TranslatedModal.Confirmation
        {...modalProps}
        header={t("header")}
        cancelLabel={t("cancelLabel")}
        submitLabel={t("submitLabel")}
      >
        <Trans t={t} values={{name: dealerOffice.name}} >
          confirmationMessage
        </Trans>
      </TranslatedModal.Confirmation>
    )}
  />
)};

export default DeleteDealerOffice;
