import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { ModalBuilder, ErrorMessage } from "@redriver/cinnamon";
import { TranslatedModal } from "../../components/modals";
import { Button } from "semantic-ui-react";
import { SettingsUploadErrorMessages } from "features/../../../shared/components/errors";

const DownloadTemplate = ({
  template,
  templateName,
  title,
  submitAction,
  dealerOfficeId,
}) => {
  const { t } = useTranslation("dataUploads", {
    keyPrefix: "downloadUploadForm",
  });
  return (
    <ModalBuilder
      submitAction={submitAction}
      submitParams={{ type: template, dealerOfficeId: dealerOfficeId }}
      renderTrigger={showModal => (
        <Button
          title={t("download")}
          primary
          onClick={showModal}
          content={title}
          icon="download"
          className="download-btn"
        />
      )}
      renderModal={({ error, ...modalProps }, data) => (
        <TranslatedModal.Confirmation
          {...modalProps}
          header={t("downloadTemplate")}
        >
          <Trans t={t} values={{ template: templateName }}>
            downloadConfirmationMessage
          </Trans>
          <SettingsUploadErrorMessages error={error} />
        </TranslatedModal.Confirmation>
      )}
    />
  );
};

export default DownloadTemplate;
