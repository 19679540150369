import { apiGet, apiPut, apiPost, apiDelete } from "@redriver/cinnamon";
import moment from "moment-timezone";

const NAMESPACE = "SETTINGS/RATE_GROUPS";

export const ActionTypes = {
  GetRateGroups: `${NAMESPACE}/GET_RATE_GROUPS`,
  GetRateGroupAudit: `${NAMESPACE}/GET_RATE_GROUP_AUDIT`,
  AddRateGroup: `${NAMESPACE}/ADD_RATE_GROUP`,
  UpdateRateGroup: `${NAMESPACE}/UPDATE_RATE_GROUP`,
  RemoveRateGroupById: `${NAMESPACE}/REMOVE_RATE_GROUP_BY_ID`,
  ApproveRateGroup: `${NAMESPACE}/APPROVE_RATE_GROUP`,
};

export const getRateGroups = ({ filters, pagination }, { dealerOfficeId }) => {
  const { pageSize, pageNumber } = pagination;
  const { search, sortName, sortDirection } = filters;

  return apiGet(ActionTypes.GetRateGroups, `rate-groups`, {
    dealerOfficeId,
    pageSize,
    pageNumber,
    search,
    sortName,
    sortDirection,
  });
};

const convertApplicableFromToOfficeTimezoneUtc = (date, timezone) =>
  date == null
    ? null
    : moment.tz(date, timezone).utc().format();

export const addRateGroup = (rateGroup, { dealerOfficeId, timezone }) => { 
  return apiPost(ActionTypes.AddRateGroup, `rate-groups`, {
    ...rateGroup,
    applicableFromUtc: convertApplicableFromToOfficeTimezoneUtc(rateGroup.applicableFromDate, timezone),
    dealerOfficeId,    
  });
}

export const removeRateGroup = (id) =>
  apiDelete(ActionTypes.RemoveRateGroupById, `rate-groups/${id}`);

export const editRateGroup = (rateGroup, { id, timezone }) =>
  apiPut(ActionTypes.UpdateRateGroup, `rate-groups/${id}`, {
    ...rateGroup,
    applicableFromUtc: convertApplicableFromToOfficeTimezoneUtc(rateGroup.applicableFromDate, timezone),
  });

export const approveRateGroup = (id) =>
  apiPut(ActionTypes.ApproveRateGroup, `rate-groups/${id}/approve`);

export const getRateGroupAudit = ({ filters, pagination }, { rateGroupId }) => {
  return apiGet(
    ActionTypes.GetRateGroupAudit,
    `rate-groups/${rateGroupId}/audit`
  );
};
