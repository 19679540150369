import React from "react";
import classNames from "classnames";
import { Form, Format, getAuthClaim } from "@redriver/cinnamon";
import {
  FixedInput,
  FixedBoolean,
  FixedLookupDropdown,
} from "components/forms";
import { InlineLoader } from "features/../../../shared/components/forms/InlineLoader";
import { LookupTypes as SettingsLookupTypes } from "features/Settings";
import { connect } from "react-redux";
import {
  getSheetState,
  getSheetDataState,
  getSheetRatesState,
  getSheetRestrictionsState,
} from "features/Sheets";
import TieredTonerModal from "./TieredTonerModal";
import ScheduleItemDeleteModal from "./ScheduleItemDeleteModal";
import { isFieldFixed } from "../helpers";
import { withCurrentUser } from "features/../../../shared/components/auth";
import { compose } from "redux";

const ScheduleUnitArray = ({
  calculating,
  sheetDetailsSaving,
  allowedTieredToner,
  contractType,
  sheetId,
  impersonatedUser,
  preventUpdates,
  tieredTonerAvailableToDealer,
  setPopoverVisible,
  currentUser,
}) => {
  const hideFixedIndicator = !impersonatedUser.canEditAdminFields;
  const hideTiredToner = !(allowedTieredToner && tieredTonerAvailableToDealer);

  return (
    <Form.Array
      field="systemScheduleItems"
      arrayKey="id"
      propagateUpdates="always"
      fluid
      disabled={preventUpdates}
    >
      {({ fields, arrayIndex, parentFields }) => {
        return (
          <div
            className={classNames(
              "schedule-item-container",
              "system-schedule",
              fields.tieredToner.value && "expanded",
              arrayIndex === parentFields.systemScheduleItems.length - 1 &&
                "last",
            )}
          >
            <div
              className={`schedule-item top system-schedule ${
                fields.tieredToner.value ? "expanded" : ""
              }`}
            >
              <div className="col col-1 schedule-array">
                <FixedLookupDropdown
                  field="unit"
                  disabled={!!fields.unit.value || sheetDetailsSaving} // Unit cannot be updated once selected.
                  disableValueChange={true}
                  readOnly={true}
                  renderReadOnly={() => {
                    const scheduleItems = parentFields.systemScheduleItems;
                    const currentItem =
                      Array.isArray(scheduleItems) && scheduleItems[arrayIndex]
                        ? scheduleItems[arrayIndex]
                        : null;
                    if (!currentItem || !currentItem.unit) {
                      return <span>-</span>;
                    }

                    const unitId = currentItem.unit
                      ? currentItem.unit.value
                      : null;
                    if (!unitId) return <span>{currentItem.unit.code}</span>;

                    return <span>{currentItem.displayCode}</span>;
                  }}
                  lookup={SettingsLookupTypes.Units}
                  lookupParams={{
                    contractType,
                    isLargeFormat: false,
                    sheetId: sheetId,
                  }}
                  customEditorData={{ urlRoute: "fixed-units" }}
                  fluid
                  className="fix-unit-dropdown readonly-fix-unit-dropdown"
                  onFocus={() => setPopoverVisible(true)}
                  onBlur={() => setPopoverVisible(false)}
                  hideIndicator={true}
                />
              </div>
              <div className="col-2">
                <Form.Object field="unit">
                  <Form.Input field="unitName" readOnly />
                </Form.Object>
              </div>
              <div className="col col-3">
                <FixedInput
                  field="costPerClick"
                  className="cost-per-click"
                  width={9}
                  decimalPlaces={process.env.USE_MINOR_CURRENCY ? 3 : 5}
                  currency
                  minorCurrency={process.env.USE_MINOR_CURRENCY}
                  required
                  formatNumber
                  minAllowValue={
                    currentUser.isInternalUser
                      ? fields.vMinSalePrice
                      : fields.minSalePrice
                  }
                  disabled={
                    fields.unit.overwritable === false ||
                    sheetDetailsSaving ||
                    preventUpdates
                  }
                  hideIndicator={hideFixedIndicator}
                  key={"cpc_" + arrayIndex.toString()}
                  defaultValue={fields.defaultMinSalePrice}
                />
              </div>
              <div className="col col-4">
                <FixedBoolean
                  field="includeManagedVol"
                  toggle
                  className="toggle"
                  disabled={
                    (fields.unit &&
                      (fields.unit.alwaysUnmanaged ||
                        fields.unit.alwaysManaged)) ||
                    sheetDetailsSaving ||
                    preventUpdates
                  }
                  hideIndicator={hideFixedIndicator}
                />
              </div>
              <div className="col col-5">
                <FixedBoolean
                  field="tonerExclusive"
                  toggle
                  className="toggle"
                  hideIndicator={hideFixedIndicator}
                  disabled={sheetDetailsSaving || preventUpdates}
                />
              </div>
              {!hideTiredToner ? (
                <div className="col col-6">
                  <div className="flex">
                    <FixedBoolean
                      field="tieredToner"
                      toggle
                      className="toggle tiered-toner"
                      disabled={
                        fields.tonerExclusive.value ||
                        sheetDetailsSaving ||
                        !tieredTonerAvailableToDealer ||
                        preventUpdates
                      }
                      hideIndicator={hideFixedIndicator}
                    />
                    {fields.tieredToner.value &&
                      tieredTonerAvailableToDealer && (
                        <TieredTonerModal fields={fields} />
                      )}
                  </div>
                </div>
              ) : null}
              <div className="col col-7">
                <FixedInput
                  field="minQuarterly"
                  className="min-quarterly"
                  decimalPlaces={0}
                  inline
                  width={9}
                  required
                  formatNumber
                  minAllowValue={
                    currentUser.isInternalUser
                      ? fields.vMinVolume
                      : fields.defaultMinVolume
                  }
                  disabled={
                    fields.unit.overwritable === false ||
                    sheetDetailsSaving ||
                    preventUpdates
                  }
                  hideIndicator={hideFixedIndicator}
                  key={"minq_" + arrayIndex.toString()}
                  defaultValue={fields.defaultMinVolume}
                />
              </div>
              <div className="col col-8">
                <InlineLoader
                  size="tiny"
                  isActive={calculating}
                  className="centralise"
                />
                <strong
                  className={classNames(
                    "cost-per-unit",
                    "calculable-field",
                    calculating && "value-loading",
                    fields.costPerUnit < 0 && "negative",
                  )}
                >
                  {" "}
                  {process.env.USE_MINOR_CURRENCY ? (
                    <Format.Number
                      value={fields.costPerUnit || 0}
                      decimalPlaces={{ min: 0, max: 3 }}
                      suffix={process.env.MINOR_CURRENCY}
                    />
                  ) : (
                    <Format.Number
                      value={fields.costPerUnit || 0}
                      decimalPlaces={{ min: 0, max: 5 }}
                      prefix={process.env.MAJOR_CURRENCY}
                    />
                  )}
                </strong>
              </div>
              <div className="col col-0">
                <ScheduleItemDeleteModal
                  nameOfRemoval="Unit"
                  sheetId={sheetId}
                  scheduleItemId={fields.id}
                  isLargeFormat={false}
                  disabled={
                    sheetDetailsSaving ||
                    preventUpdates ||
                    fields.unit.isFixed ||
                    isFieldFixed(fields.costPerClick) ||
                    fields.includeManagedVol.isFixed ||
                    fields.tonerExclusive.isFixed ||
                    fields.consumerExclusive.isFixed ||
                    fields.tieredToner.isFixed ||
                    isFieldFixed(fields.minQuarterly) ||
                    isFieldFixed(fields.tier1UpperBoundaryPercentage) ||
                    isFieldFixed(fields.tier2UpperBoundaryPercentage) ||
                    isFieldFixed(fields.tier2Adds) ||
                    isFieldFixed(fields.tier3Adds)
                  }
                />
              </div>
            </div>
          </div>
        );
      }}
    </Form.Array>
  );
};

const mapStateToProps = (state) => {
  const { sheetDetailsSaving, sheetViewType } = getSheetState(state);
  const { lastUpdated, impersonatedUser, preventUpdates, systemScheduleItems } =
    getSheetDataState(state);
  const { allowedTieredToner } = getSheetRatesState(state);
  const { tieredTonerAvailableToDealer } = getSheetRestrictionsState(state);

  return {
    sheetDetailsSaving,
    allowedTieredToner,
    lastUpdated,
    impersonatedUser,
    preventUpdates,
    tieredTonerAvailableToDealer,
    sheetViewType,
    systemScheduleItems,
    userArea: getAuthClaim(state, "userArea"),
  };
};

export default compose(
  connect(mapStateToProps),
  withCurrentUser,
)(ScheduleUnitArray);
