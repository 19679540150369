import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { ListDealerOffices } from "features/DealerOffices";

const ListDealersPage = () => (
  <PageContent>
    <ListDealerOffices />
  </PageContent>
);

export default ListDealersPage;
