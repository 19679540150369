import React from "react";
import { useTranslation } from "react-i18next";
import { PageHeader } from "@redriver/cinnamon";
import { UserSetup } from "features/System";
import { SystemPageContent } from "components/pages";

const UserSetupPage = ({ match }) => {
  const { t } = useTranslation('system');

  return(
  <SystemPageContent>
     <div className="sys-form">
        <PageHeader>{t('User_Setup')}</PageHeader>
    <UserSetup token={match.params.token || ""} />
    </div>
  </SystemPageContent>
  )
};

export default UserSetupPage;
