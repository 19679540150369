import { apiGet, apiPut, apiPost, apiDelete } from "@redriver/cinnamon";

const NAMESPACE = "DEALERS";

export const ActionTypes = {
  GetDealerOffices: `${NAMESPACE}/GET_DEALER_OFFICES`,
  CreateDealerOffice: `${NAMESPACE}/ADD_DEALER_OFFICE`,
  DeleteDealerOffice: `${NAMESPACE}/DELETE_DEALER_OFFICE`,
  GetDealerOffice: `${NAMESPACE}/GET_DEALER_OFFICE`,
  EditDealerOffice: `${NAMESPACE}/EDIT_DEALER_OFFICE`,
  GetDealerOfficeLogo: `${NAMESPACE}/GET_DEALER_OFFICE_LOGO`,
  EditDealerOfficeLogo: `${NAMESPACE}/EDIT_DEALER_OFFICE_LOGO`,
  GetDealerOfficeHeaderImage: `${NAMESPACE}/GET_DEALER_OFFICE_HEADER_IMAGE`,
  EditDealerOfficeHeaderImage: `${NAMESPACE}/EDIT_DEALER_OFFICE_HEADER_IMAGE`,
  SetSidePanelVisible: `${NAMESPACE}/SET_SIDE_PANEL_VISIBLE`,
};

export const getDealerOfficeLogo = (id) =>
  apiGet(ActionTypes.GetDealerOfficeLogo, `dealer-offices/${id}/logo`);

export const editDealerOfficeLogo = (
  { logo },
  { logoData, dealerOfficeId }
) => {
  const logoObject = !logo
    ? null
    : {
        name: logo.name,
        content: logoData.split(",")[1],
        size: logo.size,
        contentType: logo.type,
      };
  return apiPut(
    ActionTypes.EditDealerOfficeLogo,
    `dealer-offices/${dealerOfficeId}/logo`,
    {
      logo: logoObject,
    }
  );
};

export const getDealerOfficeHeaderImage = ({ dealerOfficeId }) =>
  apiGet(
    ActionTypes.GetDealerOfficeHeaderImage,
    `dealer-offices/${dealerOfficeId}/header-image`
  );

export const editDealerOfficeHeaderImage = (
  { image },
  { imageData, dealerOfficeId }
) => {
  const imageObject = !image
    ? null
    : {
        name: image.name,
        content: imageData.split(",")[1],
        size: image.size,
        contentType: image.type,
      };

  return apiPut(
    ActionTypes.EditDealerOfficeHeaderImage,
    `dealer-offices/${dealerOfficeId}/header-image`,
    {
      image: imageObject,
    }
  );
};

export const getDealerOffices = ({ filters, pagination }) =>
  apiGet(ActionTypes.GetDealerOffices, `dealer-offices`, {
    ...filters,
    ...pagination,
  });

export const createDealerOffice = (data) =>
  apiPost(ActionTypes.CreateDealerOffice, `dealer-offices`, data);

export const deleteDealerOffice = (id) =>
  apiDelete(ActionTypes.DeleteDealerOffice, `dealer-offices/${id}`);

export const getDealerOffice = (id) =>
  apiGet(ActionTypes.GetDealerOffice, `dealer-offices/${id}`);

export const editDealerOffice = (data, id) =>
  apiPut(ActionTypes.EditDealerOffice, `dealer-offices/${id}`, data);

export const setSidePanelVisible = (visible) => ({
  type: ActionTypes.SetSidePanelVisible,
  visible,
});
