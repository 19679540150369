import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getSheetDataState } from "features/Sheets";
import { cannotCalcMessages } from "features/Sheets/ManageSheet";

const SheetWarnings = ({ calcOnly }) => {
  const { t } = useTranslation("system");
  const { preventUpdates } = useSelector(getSheetDataState);
  return (
    <React.Fragment>
      {cannotCalcMessages.full && (
        <span className="calc-error">
          {t("Cannot_calculate_save_because")}
          {cannotCalcMessages.errors.map((item, i, arr) => (
            <span key={`cannot_calc_msg_${i}`}>
              {t(item)}
              {i != arr.length - 1 ? t(", and ") : ""}
            </span>
          ))}
        </span>
      )}
      {!calcOnly && (
        <React.Fragment>
          {preventUpdates && (
            <span className="calc-error">
              {t("Cannot_modify_sheet_because_crystallised")}
            </span>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default SheetWarnings;
