import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder } from "@redriver/cinnamon";
import { getUserPermissions, updateUserPermissions } from "./actions";
import RoleForm from "../ListRoles/RoleForm";
import { TranslatedModal } from "../../../components/modals";

const EditUserPermissions = ({
  as: As = Button,
  user,
  onSubmitted,
  ...props
}) => {
  return (
    <ModalBuilder
      withForm
      loadAction={getUserPermissions}
      loadParams={user.id}
      submitAction={updateUserPermissions}
      submitParams={user.id}
      onSubmitted={onSubmitted}
      renderTrigger={showModal => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Edit {...modalProps} header="Edit User Permissions">
          <RoleForm
            formProps={formProps}
            editMode
            userMode={true}
            userArea={user.userArea}
          />
        </TranslatedModal.Edit>
      )}
    />
  );
};

export default EditUserPermissions;
