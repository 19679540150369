import React from "react";
import { SettingsUploadHeaderErrorMessages, SettingsUploadDataErrorMessages, SettingsUploadAdditionalColumnsErrorMessage } from "./";
import { ErrorMessage } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";

const SettingsUploadErrorMessages = ({error}) => {

    const { t } = useTranslation('dataUploads', { keyPrefix: 'errors' });

    return (
            <ErrorMessage
            header={t('uploadError')}
            error={error}
            omitCodes
            overrideMessages={
                {
                24016: ({ message, code, data }) => (
                    <SettingsUploadHeaderErrorMessages message={message} code={code} data={data} />
                ),
                24017: ({ message, code, data }) => (
                    <SettingsUploadDataErrorMessages message={message} code={code} data={data} />
                ),
                24022: ({ message, code, data }) => (
                    <SettingsUploadDataErrorMessages message={message} code={code} data={data} />
                ),
                24025: ({ message, code, data }) => (
                    <SettingsUploadDataErrorMessages message={message} code={code} data={data} />
                ),
                24027: ({ message, code, data }) => (
                    <SettingsUploadAdditionalColumnsErrorMessage message={message} code={code} data={data} />
                ),
                }
            }
            />        
    )
};

export default SettingsUploadErrorMessages;
