import { produce } from "immer";
import {
  requestIsBusy,
  requestResponse,
  requestError,
} from "@redriver/cinnamon";
import { ActionTypes } from "./actions";
import { RatesActionTypes as SharedActionTypes } from "features/../../../shared/features/Settings";
import { RateTypeDiscriminator } from "features/../../../shared/constants/enums";

const getInitialRates = () => {
  let rates = {};
  rates[RateTypeDiscriminator.DealerNetRate] = { columns: [], rows: [] };
  rates[RateTypeDiscriminator.GrossSalesRate] = { columns: [], rows: [] };
  rates[RateTypeDiscriminator.Yield] = { columns: [], rows: [] };
  rates[RateTypeDiscriminator.IpsNetRate] = { columns: [], rows: [] };
  rates[RateTypeDiscriminator.Per1000DifferenceMinForIps] = {
    columns: [],
    rows: [],
  };
  rates[RateTypeDiscriminator.IpsYieldAtDealerNetRate] = {
    columns: [],
    rows: [],
  };
  rates.lockedById = null;
  rates.lockedByName = null;
  return rates;
};

const initialState = {
  liquidityCharges: {
    liquidityRecords: [],
    includeIpSyndicateCharge: false,
  },
  rates: getInitialRates(),
  filters: {
    rateGroupId: null,
    rateGroupName: null,
    funderType: "Funder",
    paymentMethod: "QuarterlyDirectDebit",
    financeType: "EFA",
  },
  ratesLoading: true,
  liquidityLoading: true,
  activeTab: RateTypeDiscriminator.GrossSalesRate,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SharedActionTypes.GetRates: {
      if (requestIsBusy(action) || requestError(action)) {
        return {
          ...state,
          ratesLoading: true,
        };
      }

      const res = requestResponse(action);

      let rates = {};
      rates[RateTypeDiscriminator.DealerNetRate] = res.find(
        (x) => x.rateType === RateTypeDiscriminator.DealerNetRate
      );
      rates[RateTypeDiscriminator.GrossSalesRate] = res.find(
        (x) => x.rateType === RateTypeDiscriminator.GrossSalesRate
      );

      if (!action.customData || !action.customData.preventYieldUpdates) {
        // Only update yield rates if getting on all-rate-page and yield rates are not passed in
        rates[RateTypeDiscriminator.Yield] = res.find(
          (x) => x.rateType === RateTypeDiscriminator.Yield
        );
      } else {
        rates[RateTypeDiscriminator.Yield] =
          state.rates[RateTypeDiscriminator.Yield];
      }
      rates[RateTypeDiscriminator.IpsNetRate] = res.find(
        (x) => x.rateType === RateTypeDiscriminator.IpsNetRate
      );
      rates[RateTypeDiscriminator.Per1000DifferenceMinForIps] = res.find(
        (x) => x.rateType === RateTypeDiscriminator.Per1000DifferenceMinForIps
      );
      rates[RateTypeDiscriminator.IpsYieldAtDealerNetRate] = res.find(
        (x) => x.rateType === RateTypeDiscriminator.IpsYieldAtDealerNetRate
      );

      return {
        ...state,
        rates: rates,
        ratesLoading: false,
      };
    }
    case SharedActionTypes.GetDefaultRateGroup: {
      if (requestIsBusy(action) || requestError(action)) return state;

      const { text, value } = requestResponse(action);

      return {
        ...state,
        filters: {
          ...state.filters,
          rateGroupId: value,
          rateGroupName: text,
        },
      };
    }

    case SharedActionTypes.GetLiquidityCharges: {
      if (requestIsBusy(action) || requestError(action)) {
        return {
          ...state,
          liquidityLoading: true,
        };
      }

      const response = requestResponse(action);

      return {
        ...state,
        liquidityCharges: response,
        liquidityLoading: false,
      };
    }
    case SharedActionTypes.DraftUpdateLiquidityCharges: {
      const { liquidityLoading } = state;
      if (liquidityLoading) return state;

      const { field, data, funderType, financeType, paymentMethod } = action;

      const newLiquidityRecords = produce(
        state.liquidityCharges.liquidityRecords,
        (draftRecords) => {
          let valuesToUpdate = draftRecords.find(
            (x) =>
              x.funderType == funderType &&
              x.financeType == financeType &&
              x.paymentMethod == paymentMethod
          ).values;
          let itemToUpdate = valuesToUpdate.find((x) => x.id == field);
          Object.entries(data).forEach(([key, value]) => {
            if (!isNaN(parseFloat(value))) {
              itemToUpdate[key] = parseFloat(value);
            }
          });
        }
      );

      return {
        ...state,
        liquidityCharges: {
          ...state.liquidityCharges,
          liquidityRecords: newLiquidityRecords,
        },
      };
    }
    case SharedActionTypes.UpdateLiquidityCharges: {
      const loading = requestIsBusy(action);
      return produce(state, (draftState) => {
        draftState.liquidityLoading = loading;
      });
    }

    case SharedActionTypes.SetYieldRatesForm: {
      return {
        ...state,
        rates: {
          ...state.rates,
          yieldRates: action.yieldRates,
        },
      };
    }
    case SharedActionTypes.GetYieldRates: {
      if (requestIsBusy(action)) {
        return {
          ...state,
          ratesLoading: true,
        };
      }

      const response = requestResponse(action);

      if (!response) return state;

      return {
        ...state,
        ratesLoading: false,
        rates: {
          ...state.rates,
          yieldRates: response.yields,
          lockedById: response.lockedById,
          lockedByName: response.lockedByName,
          updated: Date.now(),
        },
      };
    }

    case ActionTypes.SetActiveTab: {
      return {
        ...state,
        activeTab: action.tab,
      };
    }
    case ActionTypes.UpdateFilters: {
      return {
        ...state,
        filters: action.filters,
      };
    }

    case ActionTypes.ClearRates: {
      return produce(initialState, () => {});
    }

    default: {
      return state;
    }
  }
};
