import React from "react";
import { Icon, Popup } from "semantic-ui-react";
import { ListBuilder, FormBuilder, Table, ActionBar } from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import { getUsers } from "./actions";
import UserActions from "./UserActions";
import UsersFilter from "./UsersFilter";
import UsersFilterSidePanel from "./UsersFilterSidePanel";
import {
  withCurrentUser,
  PermissionCheck,
} from "features/../../../shared/components/auth";
import { UserType, UserStatus } from "features/../../../shared/constants/enums";
import classNames from "classnames";
import AddUser from "./AddUser";
import UsersFilterDisplay from "./UsersFilterDisplay";
import { Targets, Actions } from "constants/permissions";
import { SortHeader } from "features/../../../shared/components/tables/sortableTable";
import { useTranslation } from "react-i18next";

const ListUsers = ({ currentUser, dealerName, companyId = null }) => {
  const { t } = useTranslation("supplierOffices", { keyPrefix: "users" });

  const isInternalUser = currentUser.userType === UserType.HQ;
  return (
    <React.Fragment>
      <h4 className="settings-header">
        {dealerName ? (
          <Trans t={t} values={{ name: dealerName }}>
            title
          </Trans>
        ) : (
          "Users"
        )}
      </h4>
      <ListBuilder
        withTable
        loadAction={getUsers}
        loadParams={{ companyId }}
        initialPagination={{ pageSize: 25, pageNumber: 1 }}
        initialFilters={{
          userType:
            isInternalUser && !companyId ? UserType.HQ : UserType.Dealer,
          search: "",
          status: [],
          companyId: companyId,
        }}
        dataTransform={data => data.results}
        totalTransform={data => data.totalResults}
        renderList={(tableProps, state, events) => (
          <div className="content-container principal-list users-list">
            <FormBuilder
              value={state.filters}
              onChange={events.onChangeFilters}
              renderForm={(formProps, formState, formEvents) => (
                <ActionBar>
                  <ActionBar.Item className="search-filter-display">
                    <UsersFilter
                      formProps={formProps}
                      isInternalUser={isInternalUser}
                      companyId={companyId}
                    />
                    <UsersFilterDisplay
                      filters={state.filters}
                      formProps={formProps}
                      companyId={companyId}
                    />
                  </ActionBar.Item>
                  <ActionBar.Item fluid></ActionBar.Item>
                  <ActionBar.Space />
                  <PermissionCheck
                    target={Targets.User}
                    action={Actions.Create}
                  >
                    <ActionBar.Item align="right">
                      <AddUser
                        companyId={companyId}
                        onAdded={events.onRefresh}
                      />
                    </ActionBar.Item>
                  </PermissionCheck>
                  <ActionBar.Item>
                    <UsersFilterSidePanel
                      formProps={formProps}
                      companyId={companyId}
                      isInternalUser={isInternalUser}
                      onReset={events.onReset}
                    />
                  </ActionBar.Item>
                </ActionBar>
              )}
            />

            <Table
              {...tableProps}
              compact
              dataKey="id"
              emptyMessage={t("emptyMessage")}
              selectable
            >
              <Table.Column
                title=""
                width={1}
                render={item => (
                  <div
                    className={classNames(
                      "user-status-icon",
                      item.userStatus === UserStatus.Verified && "verified",
                      item.userStatus === UserStatus.NotVerified &&
                        "not-verified",
                      item.userStatus === UserStatus.Disabled && "disabled",
                    )}
                  ></div>
                )}
              />
              <Table.Column
                field="name"
                render={item => `${item.firstName} ${item.lastName}`}
                header={
                  <SortHeader
                    field="name"
                    title={t("name")}
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
              />
              <Table.Column
                field="email"
                header={
                  <SortHeader
                    field="email"
                    title={t("email")}
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
              />
              <Table.Column
                field="userStatusText"
                header={
                  <SortHeader
                    field="userStatusText"
                    title={t("status")}
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
              />
              {currentUser.userType == UserType.HQ && (
                <React.Fragment>
                  <Table.Column field="userAreaText" title={t("type")} />
                  {!companyId && (
                    <Table.Column field="region" title={t("region")} />
                  )}
                  {!companyId && (
                    <Table.Column
                      field="company"
                      header={
                        <SortHeader
                          field="company"
                          title={t("supplier")}
                          value={state.filters}
                          onChange={events.onChangeFilters}
                        />
                      }
                    />
                  )}
                </React.Fragment>
              )}
              <Table.Column
                render={({ hasSetupMfa }) =>
                  hasSetupMfa ? (
                    <Popup
                      inverted
                      content="Two-Factor Authentication in use"
                      trigger={<Icon color="grey" name="shield" />}
                    />
                  ) : null
                }
                title="2FA"
                width="4%"
              />
              <Table.Column
                hoverable
                width="4%"
                align="right"
                render={user => (
                  <UserActions
                    user={user}
                    companyId={companyId}
                    onRefresh={events.onRefresh}
                  />
                )}
              />
            </Table>
            <TablePagination
              pageNumber={state.pagination.pageNumber}
              onChange={events.onChangePagination}
              pageSize={state.pagination.pageSize}
              totalItems={state.total}
            />
          </div>
        )}
      />
    </React.Fragment>
  );
};

export default withCurrentUser(ListUsers);
