import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * FixedInputResponsiveLabel renders a label for FixedInputs that handles text better when
 * "squished" on smaller viewports. This label should also work for non-FixedInputs inputs.
 */
const FixedInputResponsiveLabel = ({
  required,
  label,
  className,
  children,
  labelClassName,
  ...props
}) => (
  <div
    {...props}
    className={classNames("fixed-input-responsive-label", className)}
  >
    <div>
      {children ? children : <p className={labelClassName}>{label}</p>}
      {required && <span className="required-star">*</span>}
    </div>
  </div>
);

FixedInputResponsiveLabel.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string
};

export default FixedInputResponsiveLabel;
