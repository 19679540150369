import React from "react";
import Filter from "features/../../../shared/assets/white-filter.svg";
import { IconButton } from "features/../../../shared/components/buttons";
import { useTranslation } from "react-i18next";

const FilterButton = ({ content, onClick, ...props }) => {
  const  { t }  = useTranslation('supplierOffices', { keyPrefix:'users'});
  return (
    <IconButton
      primary
      {...props}
      icon={Filter}
      content={content || t("filterBtn")}
      onClick={onClick}
    />
  )
};

export default FilterButton;
