import React from "react";
import { SidePanel, FormBuilder, Form } from "@redriver/cinnamon";
import { Grid } from "semantic-ui-react";
import { IconButton } from "features/../../../shared/components/buttons";
import Filter from "features/../../../shared/assets/white-filter.svg";
import { useTranslation } from "react-i18next";

const DealerOfficeFilters = ({ title, value, onChange }) => {
  const { t } = useTranslation("supplierOffices", { keyPrefix: "listPage" });

  return (
    <SidePanel
      closeIcon
      header={title}
      trigger={<IconButton icon={Filter} primary content={t("filters")} />}
    >
      <FormBuilder
        value={value}
        onChange={onChange}
        renderForm={formProps => (
          <div className="ui form highlighted-inputs">
            <Form {...formProps}>
              <Grid columns={2}></Grid>
            </Form>
          </div>
        )}
      />
    </SidePanel>
  );
};

export default DealerOfficeFilters;
