import React from "react";
import {
  Form,
  Lookup,
  LookupDropdown,
  registerLookup,
  apiGet,
} from "@redriver/cinnamon";
import {
  paymentMethods,
  funderTypes,
  sheetTypes,
  financeTypes,
  serviceTypes,
  disableMinLogicAvailableOptions,
} from "../../constants/enums/helpers";
import {
  agreementStabilityOptionsEmpty,
  PaymentMethod,
  PaymentMethodEnum,
  UserArea,
} from "../../constants/enums";
import { mockSuccess } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";
import { UserType } from "features/../../../shared/constants/enums";
const NAMESPACE = "SETTINGS/LOOKUPS";

const ActionTypes = {
  Permissions: `${NAMESPACE}/PERMISSIONS`,
  Roles: `${NAMESPACE}/ROLES`,
  PaymentMethods: `${NAMESPACE}/PAYMENT_METHODS`,
  UserAreas: `${NAMESPACE}/USER_AREAS`,
  UserTypes: `${NAMESPACE}/USER_TYPES`,
  Users: `${NAMESPACE}/USERS`,
  RateGroups: `${NAMESPACE}/RATE_GROUPS`,
  Settings: `${NAMESPACE}/SETTINGS`,
  PaperWeights: `${NAMESPACE}/PAPER_WEIGHTS`,
  YieldBands: `${NAMESPACE}/YIELD_BANDS`,
  ContractTypes: `${NAMESPACE}/CONTRACT_TYPES`,
  DealerOfficeUsers: `${NAMESPACE}/DEALER_OFFICE_USERS`,
  Units: `${NAMESPACE}/UNITS`,
  RateTableTypes: `${NAMESPACE}/RATE_TABLE_TYPES`,
  RateTypes: `${NAMESPACE}/RATE_TYPES`,
  GetDealerOffices: `${NAMESPACE}/DEALER_OFFICES`,
  GetTerritories: `${NAMESPACE}/GET_TERRITORIES`,
  GetCFASchedules: `${NAMESPACE}/GET_CFA_SCHEDULES`,
  LargeFormatMeteredServices: `${NAMESPACE}/LF_METERED_SERVICES`,
  OfficeAccess: `${NAMESPACE}/GET_OFFICE_ACCESS`,
  BoilerPlateTandCs: `${NAMESPACE}/GET_BOILER_PLATE_TANDC`,
};

export const LookupTypes = {
  Permissions: "permissionsLookup",
  Roles: "rolesLookup",
  UserTypes: "userTypeLookup",
  UserAreas: "userAreaLookup",
  Users: "userLookup",
  RateGroups: "rateGroupLookup",
  Settings: "settingsLookup",
  PaperWeights: "paperWeightsLookup",
  YieldBands: "yieldsBandsLookup",
  ContractTypes: "contractTypesLookup",
  DealerOfficeUsers: "dealerOfficeUsersLookup",
  Units: "unitsLookup",
  LargeFormatMeteredServices: "largeFormatMeteredServicesLookup",
  RateTableTypes: "rateTableTypesLookup",
  RateTypes: "rateTypesLookup",
  GetDealerOffices: "dealerOfficesLookup",
  GetTerritories: "territoriesLookup",
  PaymentMethods: "paymentMethodsLookup",
  GetCFASchedules: "cfaSchedulesLookup",
  OfficeAccess: "officeAccessLookup",
  BoilerPlateTandCs: "boilerPlateTandCsLookup",
};

// Permissions tree

registerLookup(
  LookupTypes.Permissions,
  (params) =>
    apiGet(ActionTypes.Permissions, `roles/permissions`, {
      userArea: params.userArea,
    }),
  {
    transform: (data, params) => {
      return !!data
        ? data.categories.map((p) => {
            return {
              text: p.name,
              value: p.name,
              children: p.permissions.map((c) => {
                return {
                  text: c.description,
                  value: c.uniqueRef,
                };
              }),
            };
          })
        : [];
    },
  }
);

export const PermissionsTree = (props) => (
  <Lookup
    lookup={LookupTypes.Permissions}
    lookupParams={{ userArea: props.userArea }}
    render={({ loading, response }) =>
      !loading &&
      response && (
        <Form.CheckboxTree
          {...props}
          nodes={response}
          className="permissions-tree"
        />
      )
    }
  />
);

// enum dropdowns

export const DisableMinLogicOptionsRadioGroup = (props) => (
  <Form.CheckboxGroup options={disableMinLogicAvailableOptions} {...props} />
);

export const AgreementStabilityOptionsCheckboxGroup = (props) => (
  <Form.CheckboxGroup options={agreementStabilityOptionsEmpty} {...props} />
);

export const AgreementStabilityOptionsCheckboxGroupEmpty = (props) => (
  <Form.CheckboxGroup options={agreementStabilityOptionsEmpty} {...props} />
);

export const AgreementStabilityOptionsRadioGroup = (props) => (
  <Form.RadioGroup options={agreementStabilityOptionsEmpty} {...props} />
);

export const PaymentMethodsRadioGroup = (props) => (
  <Form.RadioGroup options={paymentMethods} {...props} />
);

export const PaymentMethodsCheckboxGroup = (props) => (
  <Form.CheckboxGroup options={paymentMethods} {...props} />
);

export const FunderTypesRadioGroup = (props) => (
  <Form.RadioGroup {...props} options={funderTypes} />
);

export const FinanceTypesRadioGroup = (props) => (
  <Form.RadioGroup {...props} options={financeTypes} />
);

export const ServiceTypesRadioGroup = (props) => (
  <Form.RadioGroup {...props} options={serviceTypes} />
);

// Roles dropdown

registerLookup(
  LookupTypes.Roles,
  (userArea) => {
    return apiGet(ActionTypes.Roles, "roles/template", {
      pageNumber: 1,
      pageSize: 100,
      userArea: userArea,
    });
  },
  {
    transform: (data, params) =>
      data && data.results
        ? data.results.map((d) => {
            return {
              text: d.name,
              value: d.id,
              enableOfficeAccess: d.enableOfficeAccess,
            };
          })
        : [],
  }
);

export const RoleDropdown = (props) => (
  <Form.Dropdown {...props} lookup={LookupTypes.Roles} optionField="fullRole" />
);

// User type dropdown

registerLookup(LookupTypes.UserTypes, (params) =>
  apiGet(ActionTypes.UserTypes, "lookups/user-types", params)
);

export const UserTypeDropdown = (props) => {
  const { t } = useTranslation("shared", { keyPrefix: "lookups" });
  return (
    <Form.Dropdown
      field="userType"
      label={t("userType")}
      placeholder={t("selectUserType")}
      {...props}
      lookup={LookupTypes.UserTypes}
    />
  );
};

// User area dropdown
registerLookup(LookupTypes.UserAreas, (params) =>
  apiGet(ActionTypes.UserAreas, "lookups/user-areas", {
    excludeNonUserAssignable: true,
    internalUserOnly:
      params.userType == null ? null : params.userType == UserType.HQ,
    isSuperUser: params.userArea == UserArea.IpsSuperUser,
  })
);

export const UserAreaDropdown = (props) => {
  const { t } = useTranslation("shared", { keyPrefix: "lookups" });
  return (
    <Form.Dropdown
      field="userArea"
      label={t("userArea")}
      placeholder={t("userAreaPlaceholder")}
      {...props}
      lookup={LookupTypes.UserAreas}
    />
  );
};

// User TypeAhead

registerLookup(
  LookupTypes.Users,
  ({ search, dealerId, dealerOfficeId, userArea, includeInternalUsers }) => {
    return apiGet(ActionTypes.Users, "lookups/users", {
      dealerId,
      dealerOfficeId,
      search,
      userArea,
      pageNumber: 1,
      pageSize: 20,
      includeInternalUsers,
    });
  },
  {
    cache: false,
    transform: (data, lookupParams) =>
      data && Array.isArray(data)
        ? data.map((r) => {
            return { value: r.id, text: r.fullName };
          })
        : [],
  }
);

export const UserTypeAhead = (props) => (
  <Form.TypeAhead {...props} lookup={LookupTypes.Users} />
);

// Dealer Office TypeAhead
registerLookup(
  LookupTypes.GetDealerOffices,
  ({ search, dealerOfficeId, pageSize = 10 }) =>
    apiGet(ActionTypes.GetDealerOffices, "lookups/dealer-offices", {
      search,
      pageSize,
      dealerOfficeId,
    }),
  {
    cache: false,
  }
);

export const DealerOfficesTypeAheadLookup = (props) => (
  <Form.TypeAhead
    searchSuggestions
    alwaysShowSuggestionsOnBlur
    {...props}
    lookup={LookupTypes.GetDealerOffices}
  />
);

// Rate Group TypeAhead

registerLookup(
  LookupTypes.RateGroups,
  ({ includeInactive, search, id, dealerOfficeId, selectedSettingsId }) =>
    apiGet(ActionTypes.RateGroups, "lookups/rate-groups", {
      search,
      includeInactive,
      id,
      dealerOfficeId,
      selectedSettingsId,
    }),
  { cache: false }
);

export const RateGroupDropdown = (props) => (
  <Form.Dropdown
    search
    searchSuggestions
    alwaysShowSuggestionsOnBlur
    {...props}
    lookup={LookupTypes.RateGroups}
  />
);

export const RateGroupLookupDropdown = (props) => (
  <LookupDropdown {...props} lookup={LookupTypes.RateGroups} />
);

// Settings lookup
registerLookup(
  LookupTypes.Settings,
  ({ search, dealerOfficeId }) =>
    apiGet(ActionTypes.Settings, "lookups/settings", {
      search,
      dealerOfficeId,
    }),
  {
    cache: false,
  }
);

export const SettingsTypeAhead = (props) => (
  <Form.TypeAhead
    searchSuggestions
    alwaysShowSuggestionsOnBlur
    {...props}
    lookup={LookupTypes.Settings}
  />
);

// Paper Weights
registerLookup(LookupTypes.PaperWeights, () =>
  apiGet(ActionTypes.PaperWeights, `lookups/paper-weights`)
);

export const PaperWeightsDropdownLookup = ({ className, ...props }) => (
  <div className={className}>
    <Form.Dropdown {...props} lookup={LookupTypes.PaperWeights} />
  </div>
);

// Payment Methods
registerLookup(LookupTypes.PaymentMethods, () =>
  apiGet(ActionTypes.PaymentMethods, `lookups/payment-methods`)
);

// Yield Bands
registerLookup(LookupTypes.YieldBands, () =>
  apiGet(ActionTypes.YieldBands, `lookups/yield-bands`)
);

export const YieldBandsDropdownLookup = ({ className, ...props }) => (
  <div className={className}>
    <Form.Dropdown {...props} lookup={LookupTypes.YieldBands} />
  </div>
);

// Contract Types
registerLookup(
  LookupTypes.ContractTypes,
  () => apiGet(ActionTypes.ContractTypes, `lookups/contract-types`),
  {
    transform: (items) =>
      items.map((item) => ({ text: item.value, value: item.key })),
  }
);

// Units
registerLookup(
  LookupTypes.Units,
  (params) => apiGet(ActionTypes.Units, `lookups/metered-services`, params),
  {
    cacheExpiry: 15000,
    transform: (items) =>
      items.map((item) => ({
        text: item.code,
        content: `${item.code} - ${item.name}`,
        value: item.id,
        ...item,
      })),
  }
);

export const UnitsDropdownLookup = ({ className, ...props }) => (
  <div className={className}>
    <Form.Dropdown {...props} lookup={LookupTypes.Units} />
  </div>
);

// Large Format Metered Services
registerLookup(
  LookupTypes.LargeFormatMeteredServices,
  (params) =>
    apiGet(
      ActionTypes.LargeFormatMeteredServices,
      `lookups/large-format-metered-services`,
      params
    ),
  {
    cacheExpiry: 1500,
    transform: (items) =>
      items.map((item) => ({
        text: item.code,
        content: `${item.code} - ${item.name}`,
        value: item.id,
        ...item,
      })),
  }
);

export const LFMeteredServicesDropdownLookup = ({ className, ...props }) => (
  <div className={className}>
    <Form.Dropdown {...props} lookup={LookupTypes.LargeFormatMeteredServices} />
  </div>
);

// Rate Table Types (Funder, Finance Type, Payment Method combinations)

registerLookup(LookupTypes.RateTableTypes, () =>
  apiGet(ActionTypes.RateTableTypes, `lookups/rate-table-types`)
);

export const RateTableLookupDropdown = (props) => {
  const { t } = useTranslation("shared", { keyPrefix: "lookups" });
  return (
    <LookupDropdown
      searchSuggestions
      alwaysShowSuggestionsOnBlur
      {...props}
      lookup={LookupTypes.RateTableTypes}
      placeholder={t("selectATable")}
    />
  );
};

registerLookup(
  LookupTypes.RateTypes,
  () => apiGet(ActionTypes.RateTypes, `lookups/rate-types`),
  {
    transform: (data, { t, paymentMethod }) =>
      data.map(({ value, text }) => {
        const key =
          value === "Yield"
            ? `${value}_${
                paymentMethod === PaymentMethodEnum.MonthlyInvoice
                  ? "monthly"
                  : "quarterly"
              }`
            : value;

        return { value, text: t(`rateTypes.${key}`) };
      }),
  }
);

export const RateTypeLookupDropdown = (props) => {
  const { t } = useTranslation("shared", { keyPrefix: "lookups" });
  return (
    <LookupDropdown
      searchSuggestions
      alwaysShowSuggestionsOnBlur
      {...props}
      lookup={LookupTypes.RateTypes}
      lookupParams={{ t, paymentMethod: props.paymentMethod }}
      placeholder={t("selectRateType")}
    />
  );
};

// CFA Schedules
const MockCFAScheduleLookup = [
  {
    value: "KM1420-MA2441-SC8564",
    text: "KM1420-MA2441-SC8564",
    status: "LIVE",
  },
  {
    value: "KM1420-MA2441-SC6120",
    text: "KM1420-MA2441-SC6120",
    status: "DEAD",
  },
];

registerLookup(LookupTypes.GetCFASchedules, (cfaId) => {
  //apiGet(ActionTypes.GetCFASchedules, `lookups/cfa-schedules`, cfaId)
  return mockSuccess(ActionTypes.GetCFASchedules, {
    delay: 200,
    response: MockCFAScheduleLookup,
  });
});

export const CFASchedulesLookupDropdown = ({ ...props }) => {
  const { t } = useTranslation("shared", { keyPrefix: "lookups" });
  return (
    <LookupDropdown
      {...props}
      lookup={LookupTypes.GetCFASchedules}
      lookupOptions={{
        transform: (data) =>
          data.map((s) => {
            return {
              value: s.value,
              text: s.text + (s.status == "DEAD" ? " - " + s.status : ""),
            };
          }),
      }}
      placeholder={t("selectASchedule")}
    />
  );
};

const officeTreeBuidler = (y) => {
  return {
    text: y.name,
    value: y.isOffice ? y.id : "",
    defaultExpanded: true,
    children:
      y.children != null ? y.children.map((c) => officeTreeBuidler(c)) : [],
  };
};

registerLookup(
  LookupTypes.OfficeAccess,
  ({ id }) =>
    apiGet(ActionTypes.Permissions, `roles/user/offices-access/${id}`),
  {
    transform: (data, params) => {
      return !!data
        ? [
            {
              text: data.name,
              value: data.isOffice ? data.id : "",
              defaultExpanded: true,
              children: data.children.map((p) => {
                return officeTreeBuidler(p);
              }),
            },
          ]
        : [];
    },
  }
);

export const BoilerPlateTandCsDropdownLookup = ({ dealerId, ...props }) => (
  <div>
    <Form.Dropdown
      {...props}
      lookup={LookupTypes.BoilerPlateTandCs}
      lookupParams={{ dealerId }}
    />
  </div>
);

registerLookup(
  LookupTypes.BoilerPlateTandCs,
  ({ search, dealerId, pageSize = 10 }) =>
    apiGet(ActionTypes.BoilerPlateTandCs, "lookups/boiler-plate-terms", {
      search,
      pageSize,
      dealerId,
    }),
  {
    cache: false,
  }
);
export const OfficeAccessTree = ({ id, props }) =>
  id ? (
    <React.Fragment>
      <Lookup
        lookupParams={{ id }}
        lookup={LookupTypes.OfficeAccess}
        render={({ loading, response }) =>
          !loading &&
          response && (
            <Form.CheckboxTree
              {...props}
              field="officeAccessRefs"
              nodes={response}
            />
          )
        }
      />
    </React.Fragment>
  ) : (
    <React.Fragment></React.Fragment>
  );
