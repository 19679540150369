import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AppRoutes, SheetRoutes, AgreementRoutes } from "constants/routes";
import AgreementsListPage from "./AgreementsListPage";
import SheetPage from "pages/sheets/SheetPage";

const AgreementsRouter = () => (
  <Switch>
    <Route exact path={AgreementRoutes.Sheet()} component={AgreementsListPage} />
    <Route exact path={AppRoutes.Agreements} component={AgreementsListPage} />
    <Route exact path={SheetRoutes.Sheet()} component={SheetPage} />
    <Route render={() => <Redirect to={AppRoutes.Agreements} />} />
  </Switch>
);

export default AgreementsRouter;
