import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "AGREEMENTS";
const ActionTypes = {
  GetClients: `${NAMESPACE}/GET_CLIENTS`,
};

export const getClients = ({ filters, pagination }, params) => {
  return apiGet(ActionTypes.GetClients, "agreements/clients", {
    ...filters,
    ...pagination,
    ...params,
  });
};
