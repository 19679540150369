import React from "react";
import { useTranslation } from "react-i18next";

const AdminInfoHeader = ({ breakdown }) => {
  const { t } = useTranslation("capitalCalc", {
    keyPrefix: "infoModal.header",
  });
  return (
    <div className="admin-info-header">
      <div>{t("volumeSelection", { value: breakdown.volumeSelection })}</div>
      <div>{t("totalDevices", { value: breakdown.totalDevices })}</div>
      <div>{t("paymentMethod", { value: breakdown.paymentMethod })}</div>
      <div>{t("annualPayments", { value: breakdown.annualPayments })}</div>
      <div>{t("sheetType", { value: breakdown.sheetType.name })}</div>
      <div>{t("rateGroup", { value: breakdown.rateGroup })}</div>
    </div>
  );
};

export default AdminInfoHeader;
