import React from "react";
import { useTranslation } from "react-i18next";
import { PageContent } from "@redriver/cinnamon";

const AccessDenied = ({ message }) => {
  const { t } = useTranslation("system");
  return (
    <PageContent>
      <h4>{t("Access_Denied")}</h4>
      <div className="content-container not-list client-overview">
        <p>{message}</p>
      </div>
    </PageContent>
  );
};

export default AccessDenied;
