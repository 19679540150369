import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import classNames from "classnames";
import { Format, Form, Loadable } from "@redriver/cinnamon";
import { isNil } from "lodash";
import {
  getSheetState,
  getSheetDataState,
  getCalculationData,
} from "../../selectors";
import { updateProposedVolumes } from "../actions";
import { roundUpTo2DecimalPlaces } from "./Utils";
import CurrentQuarterDetails from "./CurrentQuarterDetails";
import { useTranslation } from "react-i18next";

const isValidVolume = (input) =>
  !isNaN(input) && !isNil(input) && !isNaN(parseInt(input));

const calculateValues = (scheduleItem) => {
  const si = scheduleItem;

  const minQuarterly = isValidVolume(si.oldMinQuarterly)
    ? parseInt(si.oldMinQuarterly)
    : si.oldMinQuarterly
    ? parseInt(si.minQuarterly.value)
    : parseInt(si.minQuarterlyVolume.value);

  const costPerUnit = !isNil(si.oldCostPerUnit)
    ? si.oldCostPerUnit
    : si.costPerUnit;
  const total = roundUpTo2DecimalPlaces(
    (isValidVolume(si.predictedVolume)
      ? Math.max(parseInt(si.predictedVolume), minQuarterly)
      : minQuarterly) *
      (process.env.USE_MINOR_CURRENCY ? costPerUnit / 100 : costPerUnit)
  );

  const displayCode = si.displayCode;
  const unitDescription = si.largeFormatScheduleGroupId
    ? si.largeFormatMeteredServiceId
      ? si.largeFormatMeteredServiceName
      : `${si.paperWidth} ${si.paperInclusiveName}`
    : si.unit.unitName;

  return {
    minQuarterly,
    costPerUnit,
    total,
    displayCode,
    unitDescription,
  };
};

const CurrentQuarterAgreed = ({ predictedVolumeEntryDisabled }) => {
  const { t } = useTranslation("comparisonSheet");
  const dispatch = useDispatch();
  const sheetDataState = useSelector(getSheetDataState);
  const sheetState = useSelector(getSheetState);
  const calcData = useSelector(getCalculationData);
  const calculating = sheetState.calculating;
  const paperInclusiveIncluded =
    sheetDataState.paperInclusive.isPaperInclusive.value;

  const onFormChange = (field, data, applyChanges) => {
    const formData = applyChanges(sheetDataState);
    dispatch(updateProposedVolumes(formData));
  };

  const scheduleItems = sheetDataState.preSnapshotSystemScheduleItems || [];
  const largeFormatGroups = sheetDataState.preSnapshotLargeFormatGroups || [];
  const currentSpendTotal =
    calcData?.breakdown.paperInclusiveCosts.currentSpendTotalAmount;

  let total = (scheduleItems || []).reduce(
    (prev, curr) => (prev || 0) + calculateValues(curr).total,
    0
  );
  largeFormatGroups.forEach((lfsi) => {
    [lfsi.printerMeter, lfsi.scanMeter, lfsi.otherMeter, ...lfsi.paperMeters]
      .filter((x) => x != null)
      .forEach((item) => {
        total += calculateValues(item).total;
      });
  });

  return (
    <Loadable loading={calculating}>
      <div className="table-container current-quarter-agreed">
        <Form value={sheetDataState} onChange={onFormChange}>
          <Grid className="table section-header">
            <Grid.Row className="table-title-row">
              <Grid.Column width={8} className="table-title">
                {t("Current_Quarter")}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="table-header">
              {/* ITEM ID*/}
              <Grid.Column width={2}>{t("Item_ID")}</Grid.Column>
              {/* ITEM DESCRIPTION*/}
              <Grid.Column width={3}>{t("Item_Description")}</Grid.Column>
              {/* AGREED TMVSA MINIMUMS*/}
              <Grid.Column width={3}>{t("Agreed_TMVSA_Minimums")}</Grid.Column>
              {/* ACTUAL / PREDICTED VOLUME*/}
              <Grid.Column width={3}>
                {t("Actual_Predicted_Volume")}
              </Grid.Column>
              {/* CURRENT CPU*/}
              <Grid.Column width={3}>{t("Current_CPU")}</Grid.Column>
              {/* TOTAL*/}
              <Grid.Column width={2}>{t("Total")}</Grid.Column>
            </Grid.Row>
            <Form.Array
              field="preSnapshotSystemScheduleItems"
              arrayKey="id"
              propagateUpdates="always"
            >
              {({ parentFields, arrayIndex }) => {
                const si =
                  parentFields.preSnapshotSystemScheduleItems[arrayIndex];
                const {
                  minQuarterly,
                  costPerUnit,
                  total,
                  displayCode,
                  unitDescription,
                } = calculateValues(si);

                return (
                  <CurrentQuarterDetails
                    minQuarterly={minQuarterly}
                    costPerUnit={costPerUnit}
                    total={total}
                    displayCode={displayCode}
                    unitDescription={unitDescription}
                    predictedVolumeEntryDisabled={predictedVolumeEntryDisabled}
                  />
                );
              }}
            </Form.Array>
            <Form.Array
              field="preSnapshotLargeFormatGroups"
              arrayKey="id"
              propagateUpdates="always"
            >
              {({ fields }) => {
                const printerMeter = fields.printerMeter
                  ? calculateValues(fields.printerMeter)
                  : {};
                const otherMeter = fields.otherMeter
                  ? calculateValues(fields.otherMeter)
                  : {};
                const scanMeter = fields.scanMeter
                  ? calculateValues(fields.scanMeter)
                  : {};
                return (
                  <React.Fragment>
                    {fields.printerMeter && (
                      <Form.Object field="printerMeter">
                        <CurrentQuarterDetails
                          minQuarterly={printerMeter.minQuarterly}
                          costPerUnit={printerMeter.costPerUnit}
                          total={printerMeter.total}
                          displayCode={printerMeter.displayCode}
                          unitDescription={printerMeter.unitDescription}
                          predictedVolumeEntryDisabled={
                            predictedVolumeEntryDisabled
                          }
                        />
                      </Form.Object>
                    )}

                    {fields.otherMeter && (
                      <Form.Object field="otherMeter">
                        <CurrentQuarterDetails
                          minQuarterly={otherMeter.minQuarterly}
                          costPerUnit={otherMeter.costPerUnit}
                          total={otherMeter.total}
                          displayCode={otherMeter.displayCode}
                          unitDescription={otherMeter.unitDescription}
                          predictedVolumeEntryDisabled={
                            predictedVolumeEntryDisabled
                          }
                        />
                      </Form.Object>
                    )}

                    {fields.scanMeter && (
                      <Form.Object field="scanMeter">
                        <CurrentQuarterDetails
                          minQuarterly={scanMeter.minQuarterly}
                          costPerUnit={scanMeter.costPerUnit}
                          total={scanMeter.total}
                          displayCode={scanMeter.displayCode}
                          unitDescription={scanMeter.unitDescription}
                          predictedVolumeEntryDisabled={
                            predictedVolumeEntryDisabled
                          }
                        />
                      </Form.Object>
                    )}

                    <Form.Array
                      field="paperMeters"
                      arrayKey="id"
                      propagateUpdates="always"
                    >
                      {({ fields }) => {
                        const {
                          minQuarterly,
                          costPerUnit,
                          total,
                          displayCode,
                          unitDescription,
                        } = calculateValues(fields);
                        return (
                          <CurrentQuarterDetails
                            minQuarterly={minQuarterly}
                            costPerUnit={costPerUnit}
                            total={total}
                            displayCode={displayCode}
                            unitDescription={unitDescription}
                            predictedVolumeEntryDisabled={
                              predictedVolumeEntryDisabled
                            }
                          />
                        );
                      }}
                    </Form.Array>
                  </React.Fragment>
                );
              }}
            </Form.Array>

            <div className="totaliser-grid comparison-totals">
              <div className="totaliser">
                <span>{t("Actual_Predicted_Quarter_Spend")}</span>
                <div className="comparison-total-value">
                  <span className="totaliser-value">
                    <Format.Number
                      format={process.env.CURRENCY_CODE}
                      value={total}
                      decimalPlaces={2}
                      className={classNames(total < 0 && "negative")}
                    />
                  </span>
                </div>
              </div>
              {paperInclusiveIncluded && (
                <div className="totaliser">
                  <span>{t("Current_Paper_Spend")}</span>
                  <div className="comparison-total-value">
                    <span className="totaliser-value">
                      <Format.Number
                        format={process.env.CURRENCY_CODE}
                        value={currentSpendTotal}
                        decimalPlaces={2}
                        className={classNames(total < 0 && "negative")}
                      />
                    </span>
                  </div>
                </div>
              )}
              <div className="totaliser">
                <span>{t("Total")}</span>
                <div className="comparison-total-value">
                  <span className="totaliser-value">
                    <Format.Number
                      format={process.env.CURRENCY_CODE}
                      value={currentSpendTotal + total}
                      decimalPlaces={2}
                      className={classNames(total < 0 && "negative")}
                    />
                  </span>
                </div>
              </div>
            </div>
          </Grid>
        </Form>
      </div>
    </Loadable>
  );
};

export default CurrentQuarterAgreed;
