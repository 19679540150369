import React from "react";
import { Modal, ErrorMessage } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";
import { translatedModalProps } from "./helpers";

const TranslatedInformationModal = (infoModalProps) => {    
    const { t } = useTranslation("shared");    
    const modalProps = translatedModalProps(infoModalProps, t);  

    return (
        <Modal.Information  {...modalProps}
            header={modalProps.header ? modalProps.header : t("modals.info.header")}
            submitLabel={modalProps.submitLabel ? modalProps.submitLabel : t("modals.info.submitLabel")}
            >
            {modalProps.children}
            <ErrorMessage
                header={infoModalProps.error?.status == 500 ?  t("modals.errorHeader"): t("modals.somethingWentWrong")}
                error={infoModalProps.error}
                omitCodes                    
            />
        </Modal.Information>
    )
}

export default TranslatedInformationModal;
