// Auto Generated file, do not modify
import {
    apiDelete,
    apiGet,
    apiPost,
    apiPut,
    ApiRequestOptions,
} from "@redriver/cinnamon";
import {
    AdminCommentRequest,
    AmendExpiryDateRequest,
    CalculateSheetRequest,
    CloneSheetRequest,
    CreateSheetRequest,
    DuplicateSheetToNewMasterRequest,
    MakeSheetLiveRequest,
    RenameSheetRequest,
    RenewSheetRequest,
    SheetSaveAsRequest,
    UpdateSheetDetailsRequest,
    UpdateSheetOwnerRequest,
    VerifyUnlockViewRequest,
} from "mortar/Principal/Endpoints/Sheets";
import {
    SheetCustomerApprovalSubmitRequest,
    SubmitSupplierPreApprovalRequest,
} from "mortar/Principal/Endpoints/Sheets/Approval";
import {
    CreateNoteRequest,
    EditNoteRequest,
} from "mortar/Principal/Endpoints/Sheets/Notes";
import {
    SetPhysicalDocumentSheetStateRequest,
    SetSheetCurrentScheduleReferenceRequest,
} from "mortar/Principal/Endpoints/Sheets/Workflow";
import { LookupView } from "mortar/Principal/Logic/Helpers";
import { SheetCalculatorResult } from "mortar/Principal/Logic/Sheets/Calculator";
import {
    CanDeleteScheduleItemView,
    DuplicateSheetNameView,
    SheetHeaderDetailsView,
} from "mortar/Principal/Logic/Sheets/Views";
import { SheetWorkflowStatusView } from "mortar/Principal/Logic/Sheets/Workflow/Views";
import { Stream } from "mortar/System/IO";
import { makeAction, makeFormData } from "../lib";

export interface SheetStatesIpsLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "sheets/sheetStatesIpsLookup">
    >;
}

export const sheetStatesIpsLookup = makeAction((
    { options }: SheetStatesIpsLookupActionRequest = {},
) => apiGet<LookupView[], "sheets/sheetStatesIpsLookup">(
    "sheets/sheetStatesIpsLookup",
    `lookups/sheet-states-ips`,
    undefined,
    options,
), "sheets/sheetStatesIpsLookup");

export interface GetSheetsActionRequest {
    options?: Partial<ApiRequestOptions<void, "sheets/getSheets">>;
}

export const getSheets = makeAction((
    { options }: GetSheetsActionRequest = {},
) => apiGet<void, "sheets/getSheets">(
    "sheets/getSheets",
    `sheets`,
    undefined,
    options,
), "sheets/getSheets");

export interface CreateSheetActionRequest {
    request: CreateSheetRequest;
    options?: Partial<ApiRequestOptions<void, "sheets/createSheet">>;
}

export const createSheet = makeAction((
    { request, options }: CreateSheetActionRequest,
) => apiPost<void, "sheets/createSheet">(
    "sheets/createSheet",
    `sheets`,
    request,
    options,
), "sheets/createSheet");

export interface LoadSheetDetailsRouteParams {
    sheetId: string;
}

export interface LoadSheetDetailsActionRequest {
    routeParams: LoadSheetDetailsRouteParams;
    options?: Partial<ApiRequestOptions<void, "sheets/loadSheetDetails">>;
}

export const loadSheetDetails = makeAction((
    { routeParams, options }: LoadSheetDetailsActionRequest,
) => apiGet<void, "sheets/loadSheetDetails">(
    "sheets/loadSheetDetails",
    `sheets/${routeParams.sheetId}`,
    undefined,
    options,
), "sheets/loadSheetDetails");

export interface UpdateSheetDetailsRouteParams {
    sheetId: string;
}

export interface UpdateSheetDetailsActionRequest {
    routeParams: UpdateSheetDetailsRouteParams;
    request: UpdateSheetDetailsRequest;
    options?: Partial<ApiRequestOptions<void, "sheets/updateSheetDetails">>;
}

export const updateSheetDetails = makeAction((
    { routeParams, request, options }: UpdateSheetDetailsActionRequest,
) => apiPut<void, "sheets/updateSheetDetails">(
    "sheets/updateSheetDetails",
    `sheets/${routeParams.sheetId}`,
    request,
    options,
), "sheets/updateSheetDetails");

export interface UpdateAdminCommentRouteParams {
    sheetId: string;
}

export interface UpdateAdminCommentActionRequest {
    routeParams: UpdateAdminCommentRouteParams;
    request: AdminCommentRequest;
    options?: Partial<ApiRequestOptions<void, "sheets/updateAdminComment">>;
}

export const updateAdminComment = makeAction((
    { routeParams, request, options }: UpdateAdminCommentActionRequest,
) => apiPut<void, "sheets/updateAdminComment">(
    "sheets/updateAdminComment",
    `sheets/${routeParams.sheetId}/admin-comment`,
    request,
    options,
), "sheets/updateAdminComment");

export interface AmendExpiryDateRouteParams {
    sheetId: string;
}

export interface AmendExpiryDateActionRequest {
    routeParams: AmendExpiryDateRouteParams;
    request: AmendExpiryDateRequest;
    options?: Partial<ApiRequestOptions<void, "sheets/amendExpiryDate">>;
}

export const amendExpiryDate = makeAction((
    { routeParams, request, options }: AmendExpiryDateActionRequest,
) => apiPut<void, "sheets/amendExpiryDate">(
    "sheets/amendExpiryDate",
    `sheets/${routeParams.sheetId}/amend-expiry-date`,
    request,
    options,
), "sheets/amendExpiryDate");

export interface ArchiveSheetRouteParams {
    sheetId: string;
}

export interface ArchiveSheetActionRequest {
    routeParams: ArchiveSheetRouteParams;
    options?: Partial<ApiRequestOptions<void, "sheets/archiveSheet">>;
}

export const archiveSheet = makeAction((
    { routeParams, options }: ArchiveSheetActionRequest,
) => apiPut<void, "sheets/archiveSheet">(
    "sheets/archiveSheet",
    `sheets/${routeParams.sheetId}/archive`,
    undefined,
    options,
), "sheets/archiveSheet");

export interface GetSheetAuditsRouteParams {
    sheetId: string;
}

export interface GetSheetAuditsQueryParams {
    pageSize: number;
    pageNumber: number;
}

export interface GetSheetAuditsActionRequest {
    routeParams: GetSheetAuditsRouteParams;
    queryParams: GetSheetAuditsQueryParams;
    options?: Partial<ApiRequestOptions<void, "sheets/getSheetAudits">>;
}

export const getSheetAudits = makeAction((
    { routeParams, queryParams, options }: GetSheetAuditsActionRequest,
) => apiGet<void, "sheets/getSheetAudits">(
    "sheets/getSheetAudits",
    `sheets/${routeParams.sheetId}/audit`,
    queryParams,
    options,
), "sheets/getSheetAudits");

export interface CalculateSheetRouteParams {
    sheetId: string;
}

export interface CalculateSheetActionRequest {
    routeParams: CalculateSheetRouteParams;
    request: CalculateSheetRequest;
    options?: Partial<
        ApiRequestOptions<SheetCalculatorResult, "sheets/calculateSheet">
    >;
}

export const calculateSheet = makeAction((
    { routeParams, request, options }: CalculateSheetActionRequest,
) => apiPost<SheetCalculatorResult, "sheets/calculateSheet">(
    "sheets/calculateSheet",
    `sheets/${routeParams.sheetId}/calculate`,
    request,
    options,
), "sheets/calculateSheet");

export interface CanDeleteScheduleItemRouteParams {
    sheetId: string;
}

export interface CanDeleteScheduleItemQueryParams {
    isLargeFormat: boolean;
    scheduleItemId: string;
}

export interface CanDeleteScheduleItemActionRequest {
    routeParams: CanDeleteScheduleItemRouteParams;
    queryParams: CanDeleteScheduleItemQueryParams;
    options?: Partial<
        ApiRequestOptions<
            CanDeleteScheduleItemView,
            "sheets/canDeleteScheduleItem"
        >
    >;
}

export const canDeleteScheduleItem = makeAction((
    { routeParams, queryParams, options }: CanDeleteScheduleItemActionRequest,
) => apiGet<CanDeleteScheduleItemView, "sheets/canDeleteScheduleItem">(
    "sheets/canDeleteScheduleItem",
    `sheets/${routeParams.sheetId}/check-schedule-item-delete`,
    queryParams,
    options,
), "sheets/canDeleteScheduleItem");

export interface ClientSignatureOverrideRouteParams {
    sheetId: string;
}

export interface ClientSignatureOverrideActionRequest {
    routeParams: ClientSignatureOverrideRouteParams;
    options?: Partial<
        ApiRequestOptions<void, "sheets/clientSignatureOverride">
    >;
}

export const clientSignatureOverride = makeAction((
    { routeParams, options }: ClientSignatureOverrideActionRequest,
) => apiPost<void, "sheets/clientSignatureOverride">(
    "sheets/clientSignatureOverride",
    `sheets/${routeParams.sheetId}/client-sign-override`,
    undefined,
    options,
), "sheets/clientSignatureOverride");

export interface CloneSheetRouteParams {
    sheetId: string;
}

export interface CloneSheetActionRequest {
    routeParams: CloneSheetRouteParams;
    request: CloneSheetRequest;
    options?: Partial<ApiRequestOptions<void, "sheets/cloneSheet">>;
}

export const cloneSheet = makeAction((
    { routeParams, request, options }: CloneSheetActionRequest,
) => apiPost<void, "sheets/cloneSheet">(
    "sheets/cloneSheet",
    `sheets/${routeParams.sheetId}/clone`,
    request,
    options,
), "sheets/cloneSheet");

export interface CrystalliseSheetRouteParams {
    sheetId: string;
}

export interface CrystalliseSheetActionRequest {
    routeParams: CrystalliseSheetRouteParams;
    options?: Partial<ApiRequestOptions<void, "sheets/crystalliseSheet">>;
}

export const crystalliseSheet = makeAction((
    { routeParams, options }: CrystalliseSheetActionRequest,
) => apiPut<void, "sheets/crystalliseSheet">(
    "sheets/crystalliseSheet",
    `sheets/${routeParams.sheetId}/crystallise`,
    undefined,
    options,
), "sheets/crystalliseSheet");

export interface SetSheetCurrentScheduleReferenceRouteParams {
    sheetId: string;
}

export interface SetSheetCurrentScheduleReferenceActionRequest {
    routeParams: SetSheetCurrentScheduleReferenceRouteParams;
    request: SetSheetCurrentScheduleReferenceRequest;
    options?: Partial<
        ApiRequestOptions<void, "sheets/setSheetCurrentScheduleReference">
    >;
}

export const setSheetCurrentScheduleReference = makeAction((
    { routeParams, request, options }:
        SetSheetCurrentScheduleReferenceActionRequest,
) => apiPut<void, "sheets/setSheetCurrentScheduleReference">(
    "sheets/setSheetCurrentScheduleReference",
    `sheets/${routeParams.sheetId}/current-schedule`,
    request,
    options,
), "sheets/setSheetCurrentScheduleReference");

export interface DeleteSheetRouteParams {
    sheetId: string;
}

export interface DeleteSheetActionRequest {
    routeParams: DeleteSheetRouteParams;
    options?: Partial<ApiRequestOptions<void, "sheets/deleteSheet">>;
}

export const deleteSheet = makeAction((
    { routeParams, options }: DeleteSheetActionRequest,
) => apiDelete<void, "sheets/deleteSheet">(
    "sheets/deleteSheet",
    `sheets/${routeParams.sheetId}/delete`,
    undefined,
    options,
), "sheets/deleteSheet");

export interface DownloadSheetRouteParams {
    sheetId: string;
}

export interface DownloadSheetActionRequest {
    routeParams: DownloadSheetRouteParams;
    options?: Partial<ApiRequestOptions<Stream, "sheets/downloadSheet">>;
}

export const downloadSheet = makeAction((
    { routeParams, options }: DownloadSheetActionRequest,
) => apiGet<Stream, "sheets/downloadSheet">(
    "sheets/downloadSheet",
    `sheets/${routeParams.sheetId}/download`,
    undefined,
    options,
), "sheets/downloadSheet");

export interface DownloadCapitalCalcAdminInfoRouteParams {
    sheetId: string;
}

export interface DownloadCapitalCalcAdminInfoActionRequest {
    routeParams: DownloadCapitalCalcAdminInfoRouteParams;
    options?: Partial<
        ApiRequestOptions<void, "sheets/downloadCapitalCalcAdminInfo">
    >;
}

export const downloadCapitalCalcAdminInfo = makeAction((
    { routeParams, options }: DownloadCapitalCalcAdminInfoActionRequest,
) => apiGet<void, "sheets/downloadCapitalCalcAdminInfo">(
    "sheets/downloadCapitalCalcAdminInfo",
    `sheets/${routeParams.sheetId}/download/capital-calc-admin-info`,
    undefined,
    options,
), "sheets/downloadCapitalCalcAdminInfo");

export interface DownloadComparisonSnapshotRouteParams {
    sheetId: string;
}

export interface DownloadComparisonSnapshotActionRequest {
    routeParams: DownloadComparisonSnapshotRouteParams;
    options?: Partial<
        ApiRequestOptions<void, "sheets/downloadComparisonSnapshot">
    >;
}

export const downloadComparisonSnapshot = makeAction((
    { routeParams, options }: DownloadComparisonSnapshotActionRequest,
) => apiGet<void, "sheets/downloadComparisonSnapshot">(
    "sheets/downloadComparisonSnapshot",
    `sheets/${routeParams.sheetId}/download/comparison-snapshot`,
    undefined,
    options,
), "sheets/downloadComparisonSnapshot");

export interface DownloadDirectDebitAddendumRouteParams {
    sheetId: string;
}

export interface DownloadDirectDebitAddendumActionRequest {
    routeParams: DownloadDirectDebitAddendumRouteParams;
    options?: Partial<
        ApiRequestOptions<void, "sheets/downloadDirectDebitAddendum">
    >;
}

export const downloadDirectDebitAddendum = makeAction((
    { routeParams, options }: DownloadDirectDebitAddendumActionRequest,
) => apiGet<void, "sheets/downloadDirectDebitAddendum">(
    "sheets/downloadDirectDebitAddendum",
    `sheets/${routeParams.sheetId}/download/direct-debit-addendum`,
    undefined,
    options,
), "sheets/downloadDirectDebitAddendum");

export interface DownloadLfpRouteParams {
    sheetId: string;
}

export interface DownloadLfpQueryParams {
    sheetId: string;
    loadAdmin: boolean;
}

export interface DownloadLfpActionRequest {
    routeParams: DownloadLfpRouteParams;
    queryParams: DownloadLfpQueryParams;
    options?: Partial<ApiRequestOptions<void, "sheets/downloadLfp">>;
}

export const downloadLfp = makeAction((
    { routeParams, queryParams, options }: DownloadLfpActionRequest,
) => apiGet<void, "sheets/downloadLfp">(
    "sheets/downloadLfp",
    `sheets/${routeParams.sheetId}/download/lfp`,
    queryParams,
    options,
), "sheets/downloadLfp");

export interface DownloadPaperInclusiveRouteParams {
    sheetId: string;
}

export interface DownloadPaperInclusiveActionRequest {
    routeParams: DownloadPaperInclusiveRouteParams;
    options?: Partial<ApiRequestOptions<void, "sheets/downloadPaperInclusive">>;
}

export const downloadPaperInclusive = makeAction((
    { routeParams, options }: DownloadPaperInclusiveActionRequest,
) => apiGet<void, "sheets/downloadPaperInclusive">(
    "sheets/downloadPaperInclusive",
    `sheets/${routeParams.sheetId}/download/paper-inclusive`,
    undefined,
    options,
), "sheets/downloadPaperInclusive");

export interface DownloadPaperInclusiveAddendumRouteParams {
    sheetId: string;
}

export interface DownloadPaperInclusiveAddendumActionRequest {
    routeParams: DownloadPaperInclusiveAddendumRouteParams;
    options?: Partial<
        ApiRequestOptions<void, "sheets/downloadPaperInclusiveAddendum">
    >;
}

export const downloadPaperInclusiveAddendum = makeAction((
    { routeParams, options }: DownloadPaperInclusiveAddendumActionRequest,
) => apiGet<void, "sheets/downloadPaperInclusiveAddendum">(
    "sheets/downloadPaperInclusiveAddendum",
    `sheets/${routeParams.sheetId}/download/paper-inclusive-addendum`,
    undefined,
    options,
), "sheets/downloadPaperInclusiveAddendum");

export interface DownloadPrincipalLfpAddendumRouteParams {
    sheetId: string;
}

export interface DownloadPrincipalLfpAddendumActionRequest {
    routeParams: DownloadPrincipalLfpAddendumRouteParams;
    options?: Partial<
        ApiRequestOptions<void, "sheets/downloadPrincipalLfpAddendum">
    >;
}

export const downloadPrincipalLfpAddendum = makeAction((
    { routeParams, options }: DownloadPrincipalLfpAddendumActionRequest,
) => apiGet<void, "sheets/downloadPrincipalLfpAddendum">(
    "sheets/downloadPrincipalLfpAddendum",
    `sheets/${routeParams.sheetId}/download/principal-lfp-addendum`,
    undefined,
    options,
), "sheets/downloadPrincipalLfpAddendum");

export interface DownloadPrincipalSoftwareSupportAddendumRouteParams {
    sheetId: string;
}

export interface DownloadPrincipalSoftwareSupportAddendumActionRequest {
    routeParams: DownloadPrincipalSoftwareSupportAddendumRouteParams;
    options?: Partial<
        ApiRequestOptions<
            void,
            "sheets/downloadPrincipalSoftwareSupportAddendum"
        >
    >;
}

export const downloadPrincipalSoftwareSupportAddendum = makeAction((
    { routeParams, options }:
        DownloadPrincipalSoftwareSupportAddendumActionRequest,
) => apiGet<void, "sheets/downloadPrincipalSoftwareSupportAddendum">(
    "sheets/downloadPrincipalSoftwareSupportAddendum",
    `sheets/${routeParams.sheetId}/download/principal-software-support-addendum`,
    undefined,
    options,
), "sheets/downloadPrincipalSoftwareSupportAddendum");

export interface DownloadProductScheduleAddendumRouteParams {
    sheetId: string;
}

export interface DownloadProductScheduleAddendumActionRequest {
    routeParams: DownloadProductScheduleAddendumRouteParams;
    options?: Partial<
        ApiRequestOptions<void, "sheets/downloadProductScheduleAddendum">
    >;
}

export const downloadProductScheduleAddendum = makeAction((
    { routeParams, options }: DownloadProductScheduleAddendumActionRequest,
) => apiGet<void, "sheets/downloadProductScheduleAddendum">(
    "sheets/downloadProductScheduleAddendum",
    `sheets/${routeParams.sheetId}/download/product-schedule-addendum`,
    undefined,
    options,
), "sheets/downloadProductScheduleAddendum");

export interface DownloadSheetContractRouteParams {
    sheetId: string;
}

export interface DownloadSheetContractQueryParams {
    watermark: boolean;
    isPreview: boolean;
}

export interface DownloadSheetContractActionRequest {
    routeParams: DownloadSheetContractRouteParams;
    queryParams: DownloadSheetContractQueryParams;
    options?: Partial<ApiRequestOptions<void, "sheets/downloadSheetContract">>;
}

export const downloadSheetContract = makeAction((
    { routeParams, queryParams, options }: DownloadSheetContractActionRequest,
) => apiGet<void, "sheets/downloadSheetContract">(
    "sheets/downloadSheetContract",
    `sheets/${routeParams.sheetId}/download/sheet-contract`,
    queryParams,
    options,
), "sheets/downloadSheetContract");

export interface GetSheetDuplicateNameRouteParams {
    sheetId: string;
}

export interface GetSheetDuplicateNameQueryParams {
    duplicateToNewMaster: boolean;
}

export interface GetSheetDuplicateNameActionRequest {
    routeParams: GetSheetDuplicateNameRouteParams;
    queryParams: GetSheetDuplicateNameQueryParams;
    options?: Partial<
        ApiRequestOptions<
            DuplicateSheetNameView,
            "sheets/getSheetDuplicateName"
        >
    >;
}

export const getSheetDuplicateName = makeAction((
    { routeParams, queryParams, options }: GetSheetDuplicateNameActionRequest,
) => apiGet<DuplicateSheetNameView, "sheets/getSheetDuplicateName">(
    "sheets/getSheetDuplicateName",
    `sheets/${routeParams.sheetId}/duplicate-name`,
    queryParams,
    options,
), "sheets/getSheetDuplicateName");

export interface DuplicateSheetToNewMasterRouteParams {
    sheetId: string;
}

export interface DuplicateSheetToNewMasterActionRequest {
    routeParams: DuplicateSheetToNewMasterRouteParams;
    request: DuplicateSheetToNewMasterRequest;
    options?: Partial<
        ApiRequestOptions<void, "sheets/duplicateSheetToNewMaster">
    >;
}

export const duplicateSheetToNewMaster = makeAction((
    { routeParams, request, options }: DuplicateSheetToNewMasterActionRequest,
) => apiPost<void, "sheets/duplicateSheetToNewMaster">(
    "sheets/duplicateSheetToNewMaster",
    `sheets/${routeParams.sheetId}/duplicate-to-new-master`,
    request,
    options,
), "sheets/duplicateSheetToNewMaster");

export interface GetSheetHeaderDetailsRouteParams {
    sheetId: string;
}

export interface GetSheetHeaderDetailsActionRequest {
    routeParams: GetSheetHeaderDetailsRouteParams;
    options?: Partial<
        ApiRequestOptions<
            SheetHeaderDetailsView,
            "sheets/getSheetHeaderDetails"
        >
    >;
}

export const getSheetHeaderDetails = makeAction((
    { routeParams, options }: GetSheetHeaderDetailsActionRequest,
) => apiGet<SheetHeaderDetailsView, "sheets/getSheetHeaderDetails">(
    "sheets/getSheetHeaderDetails",
    `sheets/${routeParams.sheetId}/header-details`,
    undefined,
    options,
), "sheets/getSheetHeaderDetails");

export interface IpsOrFunderSignatureOverrideRouteParams {
    sheetId: string;
}

export interface IpsOrFunderSignatureOverrideActionRequest {
    routeParams: IpsOrFunderSignatureOverrideRouteParams;
    options?: Partial<
        ApiRequestOptions<void, "sheets/ipsOrFunderSignatureOverride">
    >;
}

export const ipsOrFunderSignatureOverride = makeAction((
    { routeParams, options }: IpsOrFunderSignatureOverrideActionRequest,
) => apiPut<void, "sheets/ipsOrFunderSignatureOverride">(
    "sheets/ipsOrFunderSignatureOverride",
    `sheets/${routeParams.sheetId}/ips-sign-override`,
    undefined,
    options,
), "sheets/ipsOrFunderSignatureOverride");

export interface MakeSheetLiveRouteParams {
    sheetId: string;
}

export interface MakeSheetLiveActionRequest {
    routeParams: MakeSheetLiveRouteParams;
    request: MakeSheetLiveRequest;
    options?: Partial<ApiRequestOptions<void, "sheets/makeSheetLive">>;
}

export const makeSheetLive = makeAction((
    { routeParams, request, options }: MakeSheetLiveActionRequest,
) => apiPut<void, "sheets/makeSheetLive">(
    "sheets/makeSheetLive",
    `sheets/${routeParams.sheetId}/make-live`,
    request,
    options,
), "sheets/makeSheetLive");

export interface GetNotesRouteParams {
    sheetId: string;
}

export interface GetNotesQueryParams {
    pageSize: number;
    pageNumber: number;
}

export interface GetNotesActionRequest {
    routeParams: GetNotesRouteParams;
    queryParams: GetNotesQueryParams;
    options?: Partial<ApiRequestOptions<void, "sheets/getNotes">>;
}

export const getNotes = makeAction((
    { routeParams, queryParams, options }: GetNotesActionRequest,
) => apiGet<void, "sheets/getNotes">(
    "sheets/getNotes",
    `sheets/${routeParams.sheetId}/notes`,
    queryParams,
    options,
), "sheets/getNotes");

export interface CreateNoteRouteParams {
    sheetId: string;
}

export interface CreateNoteActionRequest {
    routeParams: CreateNoteRouteParams;
    request: CreateNoteRequest;
    options?: Partial<ApiRequestOptions<void, "sheets/createNote">>;
}

export const createNote = makeAction((
    { routeParams, request, options }: CreateNoteActionRequest,
) => apiPost<void, "sheets/createNote">(
    "sheets/createNote",
    `sheets/${routeParams.sheetId}/notes`,
    request,
    options,
), "sheets/createNote");

export interface EditNoteRouteParams {
    noteId: string;
    sheetId: string;
}

export interface EditNoteActionRequest {
    routeParams: EditNoteRouteParams;
    request: EditNoteRequest;
    options?: Partial<ApiRequestOptions<void, "sheets/editNote">>;
}

export const editNote = makeAction((
    { routeParams, request, options }: EditNoteActionRequest,
) => apiPut<void, "sheets/editNote">(
    "sheets/editNote",
    `sheets/${routeParams.sheetId}/notes/${routeParams.noteId}`,
    request,
    options,
), "sheets/editNote");

export interface DeleteNoteRouteParams {
    sheetId: string;
    noteId: string;
}

export interface DeleteNoteActionRequest {
    routeParams: DeleteNoteRouteParams;
    options?: Partial<ApiRequestOptions<void, "sheets/deleteNote">>;
}

export const deleteNote = makeAction((
    { routeParams, options }: DeleteNoteActionRequest,
) => apiDelete<void, "sheets/deleteNote">(
    "sheets/deleteNote",
    `sheets/${routeParams.sheetId}/notes/${routeParams.noteId}`,
    undefined,
    options,
), "sheets/deleteNote");

export interface ToggleSheetReadOnlyRouteParams {
    sheetId: string;
}

export interface ToggleSheetReadOnlyActionRequest {
    routeParams: ToggleSheetReadOnlyRouteParams;
    options?: Partial<ApiRequestOptions<void, "sheets/toggleSheetReadOnly">>;
}

export const toggleSheetReadOnly = makeAction((
    { routeParams, options }: ToggleSheetReadOnlyActionRequest,
) => apiPut<void, "sheets/toggleSheetReadOnly">(
    "sheets/toggleSheetReadOnly",
    `sheets/${routeParams.sheetId}/read-only`,
    undefined,
    options,
), "sheets/toggleSheetReadOnly");

export interface RenameSheetRouteParams {
    sheetId: string;
}

export interface RenameSheetActionRequest {
    routeParams: RenameSheetRouteParams;
    request: RenameSheetRequest;
    options?: Partial<ApiRequestOptions<void, "sheets/renameSheet">>;
}

export const renameSheet = makeAction((
    { routeParams, request, options }: RenameSheetActionRequest,
) => apiPut<void, "sheets/renameSheet">(
    "sheets/renameSheet",
    `sheets/${routeParams.sheetId}/rename`,
    request,
    options,
), "sheets/renameSheet");

export interface RenewSheetRouteParams {
    sheetId: string;
}

export interface RenewSheetActionRequest {
    routeParams: RenewSheetRouteParams;
    request: RenewSheetRequest;
    options?: Partial<ApiRequestOptions<void, "sheets/renewSheet">>;
}

export const renewSheet = makeAction((
    { routeParams, request, options }: RenewSheetActionRequest,
) => apiPost<void, "sheets/renewSheet">(
    "sheets/renewSheet",
    `sheets/${routeParams.sheetId}/renew`,
    request,
    options,
), "sheets/renewSheet");

export interface ResetSheetStateRouteParams {
    sheetId: string;
}

export interface ResetSheetStateActionRequest {
    routeParams: ResetSheetStateRouteParams;
    options?: Partial<ApiRequestOptions<void, "sheets/resetSheetState">>;
}

export const resetSheetState = makeAction((
    { routeParams, options }: ResetSheetStateActionRequest,
) => apiPut<void, "sheets/resetSheetState">(
    "sheets/resetSheetState",
    `sheets/${routeParams.sheetId}/reset-sheet-state`,
    undefined,
    options,
), "sheets/resetSheetState");

export interface ResurrectSheetRouteParams {
    sheetId: string;
}

export interface ResurrectSheetActionRequest {
    routeParams: ResurrectSheetRouteParams;
    options?: Partial<ApiRequestOptions<void, "sheets/resurrectSheet">>;
}

export const resurrectSheet = makeAction((
    { routeParams, options }: ResurrectSheetActionRequest,
) => apiPut<void, "sheets/resurrectSheet">(
    "sheets/resurrectSheet",
    `sheets/${routeParams.sheetId}/resurrect`,
    undefined,
    options,
), "sheets/resurrectSheet");

export interface SheetSaveAsRouteParams {
    sheetId: string;
}

export interface SheetSaveAsActionRequest {
    routeParams: SheetSaveAsRouteParams;
    request: SheetSaveAsRequest;
    options?: Partial<ApiRequestOptions<void, "sheets/sheetSaveAs">>;
}

export const sheetSaveAs = makeAction((
    { routeParams, request, options }: SheetSaveAsActionRequest,
) => apiPost<void, "sheets/sheetSaveAs">(
    "sheets/sheetSaveAs",
    `sheets/${routeParams.sheetId}/save-as`,
    request,
    options,
), "sheets/sheetSaveAs");

export interface UpdateSheetOwnerRouteParams {
    sheetId: string;
}

export interface UpdateSheetOwnerActionRequest {
    routeParams: UpdateSheetOwnerRouteParams;
    request: UpdateSheetOwnerRequest;
    options?: Partial<ApiRequestOptions<void, "sheets/updateSheetOwner">>;
}

export const updateSheetOwner = makeAction((
    { routeParams, request, options }: UpdateSheetOwnerActionRequest,
) => apiPut<void, "sheets/updateSheetOwner">(
    "sheets/updateSheetOwner",
    `sheets/${routeParams.sheetId}/set-owner`,
    request,
    options,
), "sheets/updateSheetOwner");

export interface SubmitSheetRouteParams {
    sheetId: string;
}

export interface SubmitSheetActionRequest {
    routeParams: SubmitSheetRouteParams;
    options?: Partial<ApiRequestOptions<void, "sheets/submitSheet">>;
}

export const submitSheet = makeAction((
    { routeParams, options }: SubmitSheetActionRequest,
) => apiPost<void, "sheets/submitSheet">(
    "sheets/submitSheet",
    `sheets/${routeParams.sheetId}/submit`,
    undefined,
    options,
), "sheets/submitSheet");

export interface SubmitSheetForCustomerApprovalRouteParams {
    sheetId: string;
}

export interface SubmitSheetForCustomerApprovalActionRequest {
    routeParams: SubmitSheetForCustomerApprovalRouteParams;
    request: SheetCustomerApprovalSubmitRequest;
    options?: Partial<
        ApiRequestOptions<void, "sheets/submitSheetForCustomerApproval">
    >;
}

export const submitSheetForCustomerApproval = makeAction((
    { routeParams, request, options }:
        SubmitSheetForCustomerApprovalActionRequest,
) => apiPost<void, "sheets/submitSheetForCustomerApproval">(
    "sheets/submitSheetForCustomerApproval",
    `sheets/${routeParams.sheetId}/submit/customer-approval`,
    request,
    options,
), "sheets/submitSheetForCustomerApproval");

export interface SubmitIPSPreApprovalRouteParams {
    sheetId: string;
}

export interface SubmitIPSPreApprovalActionRequest {
    routeParams: SubmitIPSPreApprovalRouteParams;
    options?: Partial<ApiRequestOptions<void, "sheets/submitIPSPreApproval">>;
}

export const submitIPSPreApproval = makeAction((
    { routeParams, options }: SubmitIPSPreApprovalActionRequest,
) => apiPut<void, "sheets/submitIPSPreApproval">(
    "sheets/submitIPSPreApproval",
    `sheets/${routeParams.sheetId}/submit/ips-pre-approval`,
    undefined,
    options,
), "sheets/submitIPSPreApproval");

export interface SubmitSupplierPreApprovalRouteParams {
    sheetId: string;
}

export interface SubmitSupplierPreApprovalActionRequest {
    routeParams: SubmitSupplierPreApprovalRouteParams;
    request: SubmitSupplierPreApprovalRequest;
    options?: Partial<
        ApiRequestOptions<void, "sheets/submitSupplierPreApproval">
    >;
}

export const submitSupplierPreApproval = makeAction((
    { routeParams, request, options }: SubmitSupplierPreApprovalActionRequest,
) => apiPut<void, "sheets/submitSupplierPreApproval">(
    "sheets/submitSupplierPreApproval",
    `sheets/${routeParams.sheetId}/submit/supplier-pre-approval`,
    request,
    options,
), "sheets/submitSupplierPreApproval");

export interface VoidEnvelopeRouteParams {
    sheetId: string;
}

export interface VoidEnvelopeActionRequest {
    routeParams: VoidEnvelopeRouteParams;
    options?: Partial<ApiRequestOptions<void, "sheets/voidEnvelope">>;
}

export const voidEnvelope = makeAction((
    { routeParams, options }: VoidEnvelopeActionRequest,
) => apiPut<void, "sheets/voidEnvelope">(
    "sheets/voidEnvelope",
    `sheets/${routeParams.sheetId}/submit/void-envelope`,
    undefined,
    options,
), "sheets/voidEnvelope");

export interface UnarchiveSheetRouteParams {
    sheetId: string;
}

export interface UnarchiveSheetActionRequest {
    routeParams: UnarchiveSheetRouteParams;
    options?: Partial<ApiRequestOptions<void, "sheets/unarchiveSheet">>;
}

export const unarchiveSheet = makeAction((
    { routeParams, options }: UnarchiveSheetActionRequest,
) => apiPut<void, "sheets/unarchiveSheet">(
    "sheets/unarchiveSheet",
    `sheets/${routeParams.sheetId}/unarchive`,
    undefined,
    options,
), "sheets/unarchiveSheet");

export interface UncrystalliseSheetRouteParams {
    sheetId: string;
}

export interface UncrystalliseSheetActionRequest {
    routeParams: UncrystalliseSheetRouteParams;
    options?: Partial<ApiRequestOptions<void, "sheets/uncrystalliseSheet">>;
}

export const uncrystalliseSheet = makeAction((
    { routeParams, options }: UncrystalliseSheetActionRequest,
) => apiPut<void, "sheets/uncrystalliseSheet">(
    "sheets/uncrystalliseSheet",
    `sheets/${routeParams.sheetId}/uncrystallise`,
    undefined,
    options,
), "sheets/uncrystalliseSheet");

export interface VoidSheetRouteParams {
    sheetId: string;
}

export interface VoidSheetActionRequest {
    routeParams: VoidSheetRouteParams;
    options?: Partial<ApiRequestOptions<void, "sheets/voidSheet">>;
}

export const voidSheet = makeAction((
    { routeParams, options }: VoidSheetActionRequest,
) => apiPut<void, "sheets/voidSheet">(
    "sheets/voidSheet",
    `sheets/${routeParams.sheetId}/void`,
    undefined,
    options,
), "sheets/voidSheet");

export interface SheetWorkflowRouteParams {
    sheetId: string;
}

export interface SheetWorkflowQueryParams {
    includeDotGraph: boolean;
}

export interface SheetWorkflowActionRequest {
    routeParams: SheetWorkflowRouteParams;
    queryParams: SheetWorkflowQueryParams;
    options?: Partial<
        ApiRequestOptions<SheetWorkflowStatusView, "sheets/sheetWorkflow">
    >;
}

export const sheetWorkflow = makeAction((
    { routeParams, queryParams, options }: SheetWorkflowActionRequest,
) => apiGet<SheetWorkflowStatusView, "sheets/sheetWorkflow">(
    "sheets/sheetWorkflow",
    `sheets/${routeParams.sheetId}/workflow`,
    queryParams,
    options,
), "sheets/sheetWorkflow");

export interface SetPhysicalDocumentSheetStateRouteParams {
    sheetId: string;
}

export interface SetPhysicalDocumentSheetStateActionRequest {
    routeParams: SetPhysicalDocumentSheetStateRouteParams;
    request: SetPhysicalDocumentSheetStateRequest;
    options?: Partial<
        ApiRequestOptions<void, "sheets/setPhysicalDocumentSheetState">
    >;
}

export const setPhysicalDocumentSheetState = makeAction((
    { routeParams, request, options }:
        SetPhysicalDocumentSheetStateActionRequest,
) => apiPut<void, "sheets/setPhysicalDocumentSheetState">(
    "sheets/setPhysicalDocumentSheetState",
    `sheets/${routeParams.sheetId}/workflow/physical-document-state`,
    request,
    options,
), "sheets/setPhysicalDocumentSheetState");

export interface VerifyUnlockViewRouteParams {
    sheetId: string;
}

export interface VerifyUnlockViewActionRequest {
    routeParams: VerifyUnlockViewRouteParams;
    request: VerifyUnlockViewRequest;
    options?: Partial<ApiRequestOptions<void, "sheets/verifyUnlockView">>;
}

export const verifyUnlockView = makeAction((
    { routeParams, request, options }: VerifyUnlockViewActionRequest,
) => apiPost<void, "sheets/verifyUnlockView">(
    "sheets/verifyUnlockView",
    `${routeParams.sheetId}/verify-unlock-view`,
    request,
    options,
), "sheets/verifyUnlockView");
