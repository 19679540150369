import React, { useEffect, useState } from "react";
import { Grid, Button } from "semantic-ui-react";
import { Format, Form, ModalBuilder, PageLoader } from "@redriver/cinnamon";
import { parseFloatOrNull } from "../../../modules/parsing";
import { IconButton } from "../../../components/buttons";
import Pen from "../../../assets/pen.svg";
import Save from "../../../assets/save.svg";
import {
  PermissionCheck,
  withCurrentUser,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { TranslatedModal } from "../../../components/modals";
import { useTranslation, Trans } from "react-i18next";
import { navigateGridForm } from "features/../../../shared/components/forms/formHelper";

const EditableYieldRateTable = ({
  title,
  fullTitle,
  rates,
  onFormChange,
  onToggleReadOnly,
  readOnly,
  onSubmit,
  onCancel,
  loading,
  hasLockAccess,
}) => {
  if (loading) return <PageLoader />;
  if (!rates && !loading) return null;
  const { rows, columns } = rates;
  var formData = mapYieldRatesToForm(rates);

  const [saving, setSaving] = useState(false);

  return (
    <div className="rates-section">
      <YieldRateHeader
        title={title}
        fullTitle={fullTitle}
        readOnly={readOnly}
        onToggleReadOnly={onToggleReadOnly}
        loading={loading}
        onCancel={onCancel}
        onSubmit={onSubmit}
        saving={saving}
        setSaving={setSaving}
        hasLockAccess={hasLockAccess}
      />
      <div className="rates-table-container">
        <Grid rows={rows.length} className="rates-table-header">
          <Grid.Row columns={columns.length || 8}>
            {columns.map((c, i) => (
              <Grid.Column key={c}>
                <p>{c}</p>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
        {readOnly ? (
          <ReadOnlyRatesTable rows={rows} columns={columns} />
        ) : (
          <EditableRatesForm
            formData={formData}
            columns={columns}
            onFormChange={onFormChange}
          />
        )}
      </div>
    </div>
  );
};

const mapYieldRatesToForm = rates => {
  const { rows, columns } = rates;
  let formData = {};

  rows.forEach((row, rowIndex) => {
    var rowData = row.values;
    const quarter = rowData[1];
    var item = {};
    formData[`quarter_${quarter}`] = item;

    for (let columnIndex = 0; columnIndex < rowData.length; columnIndex++) {
      if (columnIndex <= 1) continue; // First 2 values are months and quarters

      const fieldKey = `quarter_${quarter}_column_${columnIndex - 2}`;
      item[fieldKey] = parseFloatOrNull(rowData[columnIndex]);
    }
  });

  return formData;
};

const EditableRatesForm = ({
  formData,
  onFormChange,
  loading = false,
  columns,
}) => {
  const noOfColumns = columns.length;
  const editableColumns = noOfColumns - 2;
  const [isBlur, setIsBlur] = useState(false);
  return loading ? (
    <PageLoader />
  ) : (
    <Form
      value={formData}
      //only trigger onChange onBlur
      onChange={(field, data, applyChanges) =>
        isBlur
          ? (onFormChange(field, data, applyChanges),
            setIsBlur(false))
          : setIsBlur(false)
      }
      onKeyDown={e => {
        navigateGridForm(e, 1, editableColumns);
      }}
    >
      <div className="yield-rate-form">
        {Object.entries(formData).map((row, rowIndex) => {
          const quarter = row[0].replace("quarter_", "");
          const month = (parseInt(quarter) * 3).toString();

          return (
            <div className="field-row" key={rowIndex + "_container"}>
              <Form.Object field={`${row[0]}`} key={rowIndex}>
                <p className="month">{month}</p>
                <p className="quarter">{quarter}</p>
                {Object.entries(row[1]).map(field => {
                  return (
                    <Form.Numeric
                      className="yield-rate"
                      formatValue
                      field={field[0]}
                      width={5}
                      key={field[0]}
                      minValue={0.0}
                      maxValue={
                        Object.keys(row[1]).length == 8 &&
                        field[0].slice(-1) == 1
                          ? 100
                          : null
                      }
                      debounce={500}
                      onBlur={() => (setIsBlur(true))}
                    />
                  );
                })}
              </Form.Object>
            </div>
          );
        })}
      </div>
    </Form>
  );
};

const ReadOnlyRatesTable = ({ rows, columns }) => (
  <Grid rows={rows.length} className="rates-table">
    {rows.map((r, ri) => (
      <Grid.Row key={ri} columns={r.values.length}>
        {r.values.map((c, i) => (
          <Grid.Column key={i}>
            {i > 1 ? (
              <Format.Number decimalPlaces={{ min: 0, max: 3 }} value={c} />
            ) : (
              <p>{c}</p>
            )}
          </Grid.Column>
        ))}
      </Grid.Row>
    ))}
  </Grid>
);

const YieldRateHeader = ({
  title,
  fullTitle,
  loading,
  saving,
  setSaving,
  readOnly,
  onToggleReadOnly,
  onCancel,
  onSubmit,
  hasLockAccess,
}) => {
  const { t } = useTranslation();
  return (
    <div className="defaults-page-title flex">
      <h4>{title}</h4>
      <div style={{ display: "flex" }}>
        <PermissionCheck target={Targets.Rates} action={Actions.Edit}>
          {readOnly ? (
            <IconButton
              primary
              compact
              content={t("shared:buttons:edit")}
              icon={Pen}
              onClick={onToggleReadOnly}
              disabled={loading || !hasLockAccess}
            />
          ) : (
            <SaveConfirmation
              fullTitle={fullTitle}
              onSubmit={async () => {
                setSaving(true);
                const { success } = await onSubmit();
                setSaving(false);
                if (success) {
                  onToggleReadOnly();
                }
              }}
              loading={saving}
              disabled={loading}
            />
          )}
        </PermissionCheck>
        {!readOnly && (
          <Button
            compact
            content={t("shared:buttons:cancel")}
            className="cancel-title-action"
            onClick={onCancel}
          />
        )}
      </div>
    </div>
  );
};

const SaveConfirmation = ({ fullTitle, onSubmit, loading, saving }) => {
  const { t } = useTranslation();
  return (
    <ModalBuilder
      renderTrigger={showModal => (
        <IconButton
          onClick={showModal}
          className="positive-title-action"
          primary
          content={t("shared:buttons:save")}
          icon={Save}
          loading={saving}
          disabled={loading}
        />
      )}
      onSubmit={onSubmit}
      renderModal={(modalProps, data, state, events) => (
        <TranslatedModal.Confirmation {...modalProps}>
          <p>
            <Trans
              t={t}
              values={{ title: fullTitle }}
              components={{ s: <strong /> }}
            >
              supplierOffices:yields.saveConfirmation
            </Trans>
          </p>
        </TranslatedModal.Confirmation>
      )}
    />
  );
};

export default withCurrentUser(EditableYieldRateTable);
