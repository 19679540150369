import React from "react";
import SheetStep from "../SheetStep";
import { Form } from "@redriver/cinnamon";
import { uniqBy } from "lodash";
import CustomerStepScheduleItem from "./CustomerStepScheduleItem";
import CustomerStepLFScheduleItem from "./CustomerStepLFScheduleItem";
import {
  getCalculationData,
  getSheetState,
  getSheetDataState,
} from "features/Sheets";
import { connect } from "react-redux";
import { InlineLoader } from "features/../../../shared/components/forms/InlineLoader";
import { useTranslation } from "react-i18next";
import {
  PaperInclusiveChargeType,
  PaperInclusiveChargeTypeText,
} from "features/../../../shared/constants/enums/PaperInclusiveChargeType";

const CustomerStep = ({
  sheetId,
  calculating,
  displaySave,
  customerToggle,
  schedule,
  sheetDetailsSaving,
  setImpersonatedUserFlicker,
  setProductScheduleViewable,
  hasLfItems,
  hasSystemScheduleItems,
  isPaperInclusive,
  paperInclusiveChargeType,
  preventUpdates,
}) => {
  const { t } = useTranslation();

  let schItems = uniqBy(
    []
      .concat(schedule.itemCosts)
      .concat(schedule.largeFormatItemCosts)
      .filter((x) => x),
    (x) => x.unitId,
  );

  return (
    <div className="sheet-customer-view">
      <SheetStep
        displaySave={displaySave}
        calculating={calculating}
        displayCustomerToggle={true}
        customerToggle={customerToggle}
        sheetId={sheetId}
        customerView={true}
        className="customer-view-step"
        setImpersonatedUserFlicker={setImpersonatedUserFlicker}
        setProductScheduleViewable={setProductScheduleViewable}
      >
        <h4>{t("customerView:System_Schedule_Title")}</h4>
        <div className="customer-schedule-items">
          {(hasLfItems || hasSystemScheduleItems) && (
            <div className="customer-headers">
              <div className="col col-1">
                <strong>{t("customerView:System_Schedule_Item_ID")}</strong>
              </div>
              <div className="col col-2">
                <strong>
                  {t("customerView:System_Schedule_Item_Description")}
                </strong>
              </div>
              <div className="col col-3">
                <strong>
                  {t("customerView:System_Schedule_Include_In_Managed_Volume")}
                </strong>
              </div>
              <div className="col col-4">
                <strong>
                  {hasSystemScheduleItems &&
                    t("customerView:System_Schedule_Toner_Exclusive")}
                </strong>
              </div>
              <div className="col col-5">
                <strong>
                  {hasSystemScheduleItems &&
                    t("customerView:System_Schedule_Tiered_Toner_Applies")}
                </strong>
              </div>
              <div className="col col-6">
                <strong>
                  {t(
                    "customerView:System_Schedule_Quarterly_Contracted_Volume",
                  )}
                </strong>
              </div>
              <div className="col col-7">
                <strong>{t("customerView:System_Schedule_CPU")}</strong>
              </div>
            </div>
          )}
          <Form.Array
            field="systemScheduleItems"
            arrayKey="id"
            propagateUpdates="always"
            fluid
          >
            {({ fields }) => {
              return (
                <CustomerStepScheduleItem
                  fields={fields}
                  sheetDetailsSaving={sheetDetailsSaving}
                  preventUpdates={preventUpdates}
                />
              );
            }}
          </Form.Array>
          <Form.Array
            field="largeFormatGroups"
            arrayKey="id"
            propagateUpdates="always"
            fluid
          >
            {({ fields, arrayIndex }) => {
              const printerName = fields.printerName;
              return (
                <React.Fragment key={`lf_item_${arrayIndex}`}>
                  <Form.Object field={"printerMeter"}>
                    <CustomerStepLFScheduleItem
                      sheetId={sheetId}
                      printerName={printerName}
                      meterField={fields.printerMeter}
                      unitInfo={
                        fields.printerMeter.largeFormatMeteredServiceName
                      }
                      sheetDetailsSaving={sheetDetailsSaving}
                      preventUpdates={preventUpdates}
                    />
                  </Form.Object>
                  {fields.otherMeter && (
                    <Form.Object field={"otherMeter"}>
                      <CustomerStepLFScheduleItem
                        sheetId={sheetId}
                        printerName={printerName}
                        meterField={fields.otherMeter}
                        unitInfo={
                          fields.otherMeter.largeFormatMeteredServiceName
                        }
                        sheetDetailsSaving={sheetDetailsSaving}
                        preventUpdates={preventUpdates}
                      />
                    </Form.Object>
                  )}
                  {fields.scanMeter && (
                    <Form.Object field={"scanMeter"}>
                      <CustomerStepLFScheduleItem
                        sheetId={sheetId}
                        printerName={printerName}
                        meterField={fields.scanMeter}
                        unitInfo={
                          fields.scanMeter.largeFormatMeteredServiceName
                        }
                        sheetDetailsSaving={sheetDetailsSaving}
                        preventUpdates={preventUpdates}
                      />
                    </Form.Object>
                  )}
                  {fields.paperMeters && (
                    <Form.Array field="paperMeters" arrayKey="id">
                      {({ fields }) => (
                        <CustomerStepLFScheduleItem
                          sheetId={sheetId}
                          printerName={printerName}
                          meterField={fields}
                          unitInfo={`${fields.paperWidth}  ${fields.paperInclusiveName}`}
                          sheetDetailsSaving={sheetDetailsSaving}
                          preventUpdates={preventUpdates}
                        />
                      )}
                    </Form.Array>
                  )}
                </React.Fragment>
              );
            }}
          </Form.Array>
        </div>
        <div className="customer-view-metered-service">
          <div className="metered-service-header">
            <strong className="underline">
              {t("customerView:System_Schedule_Metered_Services")}{" "}
            </strong>{" "}
            {calculating && <InlineLoader size="tiny" isActive={calculating} />}
          </div>
          {schItems.map((x) => {
            return (
              <div className="metered-service" key={x.id}>
                <strong>{x.unitName}</strong>
                <span>{x.unitDescription}</span>
              </div>
            );
          })}
        </div>
        {isPaperInclusive && (
          <div style={{ marginTop: "1rem" }}>
            <span>
              <b>
                {" "}
                {paperInclusiveChargeType == PaperInclusiveChargeType.A4A3
                  ? PaperInclusiveChargeTypeText(t).A4A3
                  : PaperInclusiveChargeTypeText(t).A4 + " only"}{" "}
                {t("customerView:Cut_Sheet_Paper_Included")}
              </b>
            </span>
          </div>
        )}
        <div className="customer-view-non-metered-services">
          <strong>
            {t("customerView:System_Schedule_Non_Metered_Services")}
          </strong>
          <Form.Object field="services">
            {({ formFields }) => {
              let displayServices = [
                "services",
                "mandatoryServices",
                "adminServices",
                "ipServices",
              ]
                .flatMap((x) => ((formFields || {}).services || {})[x])
                .filter((x) => !x.hideFromCustomerView && x.service);

              return uniqBy(
                displayServices,
                (x) => (x.service || {}).value,
              ).map((x) => (
                <div className="field" key={x.service.id}>
                  {x.service.serviceName}
                </div>
              ));
            }}
          </Form.Object>
        </div>
      </SheetStep>
    </div>
  );
};

const mapStateToProps = (state) => {
  const calculationData = getCalculationData(state);
  const { sheetDetailsSaving } = getSheetState(state);
  const schedule = calculationData
    ? calculationData.breakdown
      ? calculationData.breakdown.systemSchedule
      : []
    : [];
  const {
    systemScheduleItems,
    largeFormatGroups,
    paperInclusive,
    preventUpdates,
  } = getSheetDataState(state);
  return {
    schedule,
    sheetDetailsSaving,
    hasLfItems: largeFormatGroups.length > 0,
    hasSystemScheduleItems: systemScheduleItems.length > 0,
    isPaperInclusive: paperInclusive.isPaperInclusive.value,
    paperInclusiveChargeType: paperInclusive.paperInclusiveChargeType.value,
    preventUpdates,
  };
};

export default connect(mapStateToProps)(CustomerStep);
