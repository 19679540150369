import React from "react";
import { compose } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "semantic-ui-react";
import { setAgreementCompanyFilter, clearAgreementData } from "../actions";
import { getAgreementsState } from "../selectors";
import { useTranslation } from "react-i18next";
import {
  withCurrentUser,
  withPermissions,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { getPersistentQueryParamState } from "features/System";

const NavigationBar = ({ hasPermission, currentUser: { isInternalUser } }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("agreements");

  const { companyFilters } = useSelector(getAgreementsState);
  const { dealer, dealerOffice, client } = companyFilters;
  const { customer: clientCode } = useSelector(getPersistentQueryParamState);
  const isAdmin = hasPermission(Targets.SheetAdmin, Actions.Edit);

  let filterKey = "";
  let text = "";

  // On Agreements list
  if (!clientCode && isAdmin && client.id != null) {
    // Only admin users can go back to the Clients list
    filterKey = "client";
    text = t("BackToClientsText");
  }
  // On Clients list
  else if (isAdmin && dealerOffice.id != null) {
    // Only admin users can go back to the Office list
    filterKey = "dealerOffice";
    text = t("Clients.BackToOfficesText");
  }
  // On Offices list
  else if (isInternalUser && dealer.id != null) {
    filterKey = "dealer";
    text = t("Offices.BackToDealersText");
  }

  return (
    <div className="navigation-bar">
      {filterKey && !clientCode && (
        <div className="back">
          <Icon name="triangle left" />
          <a
            onClick={() => {
              dispatch(
                setAgreementCompanyFilter({
                  ...companyFilters,
                  [filterKey]: {
                    id: null,
                    name: null,
                  },
                }),
              );
              dispatch(clearAgreementData());
            }}
          >
            {text}
          </a>
        </div>
      )}
    </div>
  );
};

export default compose(withCurrentUser, withPermissions)(NavigationBar);
