import React from "react";
import { ListBuilder, ActionBar, Table } from "@redriver/cinnamon";
import { getDealers } from "./actions";
import { ListSearch } from "components/lists";
import { TablePagination } from "components/pagination";
import { useTranslation } from "react-i18next";

const DealersList = ({ onSelect }) => {
  const { t } = useTranslation("agreements");

  return (
    <React.Fragment>
      <h4 className="title">{t("Dealers.Title")}</h4>
      <ListBuilder
        withTable
        initialPagination={{ pageSize: 25, pageNumber: 1 }}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        loadAction={getDealers}
        renderList={(tableProps, state, events) => (
          <div className="content-container principal-list">
            <ActionBar>
              <ActionBar.Item fluid className="flex">
                <ListSearch
                  value={state.filters}
                  onChange={events.onChangeFilters}
                  placeholder={t("Dealers.Search_Placeholder")}
                />
                <div className="search-filter-display"></div>
              </ActionBar.Item>
            </ActionBar>
            <Table
              {...tableProps}
              dataKey="id"
              emptyMessage={t("NoData")}
              selectable
              className="center-align"
            >
              <Table.Column
                field="name"
                width="20%"
                title={t("Dealers.Headings.Name")}
                render={(item) => (
                  <a onClick={() => onSelect(item)}>{item.name}</a>
                )}
              />
              <Table.Column
                field="code"
                title={t("Dealers.Headings.Supplier_ID")}
                width="7%"
              />
              <Table.Column
                title={t("Dealers.Headings.Head_Office_Address")}
                width="29%"
                render={(row) => {
                  if (!row.address) return;
                  const address = [
                    row.address.line1,
                    row.address.line2,
                    row.address.town,
                    row.address.postcode,
                  ];
                  return address.filter((x) => !!x).join(", ");
                }}
              />
              <Table.Column
                field="jurisdiction"
                title={t("Dealers.Headings.Jurisdiction")}
                width="8%"
              />
              <Table.Column
                field="telephone"
                title={t("Dealers.Headings.Telephone")}
                width="9%"
              />
              <Table.Column
                field="totalOffices"
                title={t("Dealers.Headings.Supplier_Offices")}
                width="9%"
              />
              <Table.Column
                field="timezone"
                title={t("Dealers.Headings.Time_Zone")}
                width="9%"
              />
              <Table.Column
                field="status"
                title={t("Dealers.Headings.Status")}
                width="9%"
              />
            </Table>
            <TablePagination
              pageNumber={state.pagination.pageNumber}
              onChange={events.onChangePagination}
              pageSize={state.pagination.pageSize}
              totalItems={state.total}
            />
          </div>
        )}
      />
    </React.Fragment>
  );
};

export default DealersList;
