import { isNil } from "lodash";
import { AgreementStabilityOptions } from "features/../../../shared/constants/enums";

const fundingSectionComplete = (section) => {
  return (
    (!isNil(section.financeSelection.value) &&
      section.financeSelection.value > 0) ||
    (!isNil(section.productCapitalCosts.value) &&
      section.productCapitalCosts.value > 0) ||
    (!isNil(section.otherCosts.value) && section.otherCosts.value > 0)
  );
};

export const checkSectionsCompleted = (
  data,
  calculatePaperInclusive = true
) => {
  const {
    rates,
    capital,
    services,
    systemScheduleItems,
    paperInclusive,
    agreementStabilityOption,
  } = data;
  let result = {
    ipsRates: true,
    rates: false,
    capital: false,
    services: true,
    schedule: false,
    scheduleStability: false,
    paper: false,
    paperHasContent: false,
    largeFormat: false,
  };
  //rates
  if (rates.sheetType && rates.applicableFromUtc) {
    result.rates = true;
  }

  //capital
  if (
    !isNil(capital.volumeSelection.value) &&
    !isNil(capital.totalDevicesOnSchedule.value) &&
    !Number.isNaN(parseInt(capital.totalDevicesOnSchedule.value)) &&
    parseInt(capital.totalDevicesOnSchedule.value) > 0 &&
    (capital.cofundedSelected.value === true ||
      capital.funderSelected.value === true ||
      capital.dealerFunderSelected.value === true) &&
    (fundingSectionComplete(capital.dealer) ||
      fundingSectionComplete(capital.funder) ||
      fundingSectionComplete(capital.cofunded))
  ) {
    result.capital = true;
  }

  //services
  let allServices = [
    "services",
    "mandatoryServices",
    "adminServices",
    "ipServices",
  ].flatMap((x) => (services || {})[x]);
  if (
    !allServices.length ||
    allServices.some(
      (x) => !(x.service || {}).value || isNaN(parseInt((x.value || {}).value))
    )
  ) {
    result.services = false;
  }

  //system schedule
  if (
    systemScheduleItems.length > 0 &&
    systemScheduleItems.some((i) => i.includeManagedVol.value === true)
  ) {
    result.schedule = true;
  }
  //paper inclusive
  function isPaperNotIncluded() {
    return (
      paperInclusive.isPaperInclusive !== null &&
      paperInclusive.isPaperInclusive.value !== null &&
      (paperInclusive.isPaperInclusive.value === "false" ||
        paperInclusive.isPaperInclusive.value === false)
    );
  }
  if (calculatePaperInclusive) {
    if (isPaperNotIncluded(paperInclusive.isPaperInclusive)) {
      result.paper = true;
      result.paperHasContent = false;
    } else if (
      paperInclusive.isPaperInclusive !== null &&
      paperInclusive.isPaperInclusive.value !== null &&
      paperInclusive.paperInclusiveChargeType !== null &&
      paperInclusive.paperInclusiveChargeType.value !== null &&
      paperInclusive.summary &&
      paperInclusive.summary.amethystAmount > 0
    ) {
      result.paper = true;
      result.paperHasContent = true;
    }
  } else {
    if (isPaperNotIncluded(paperInclusive.isPaperInclusive)) {
      result.paper = true;
      result.paperHasContent = false;
    } else {
      result.paper = null;
      result.paperHasContent = null;
    }
  }
  // schedule stability
  if (
    Object.keys(AgreementStabilityOptions).some(
      (x) => x === agreementStabilityOption
    )
  ) {
    result.scheduleStability = true;
  }

  return result;
};
