import { apiPut, notifySuccess } from "@redriver/cinnamon";

const ActionTypes = {
  preApprovalDeclined: "SHEETS/PRE_APPROVAL_DECLINED",
};

export const principalPreApprovalDeclined = (request, { sheetId }) =>
  apiPut(
    ActionTypes.preApprovalDeclined,
    `sheets/${sheetId}/submit/principal-pre-approval-declined`,
    request,
  );
