import React from "react";
import { ModalBuilder, Table } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { SheetStateIps, SheetStatePrincipal } from "constants/sheetState";
import { Icon } from "semantic-ui-react";
import { TranslatedModal } from "features/../../../shared/components/modals";

const statesToRender = process.env.TRADE_AS_IPS
  ? [
      SheetStateIps.PreLive_Draft,
      SheetStateIps.PreLive_WithCustomer_Docusign,
      SheetStateIps.PreLive_WithSupplierAdmin_Docusign,
      SheetStateIps.PreLive_WithSupplierSignatory_Docusign,
      SheetStateIps.PreLive_WithIPSAdmin_Docusign,
      SheetStateIps.PreLive_WithIPSSignatory_Docusign,
      SheetStateIps.PreLive_IPS_Approved,
      SheetStateIps.Live,
    ]
  : [
      SheetStatePrincipal.PreLive_Draft,
      SheetStatePrincipal.PreLive_WithCustomer_Docusign,
      SheetStatePrincipal.PreLive_WithPrincipalAdmin_Docusign,
      SheetStatePrincipal.PreLive_WithPrincipalSignatory_Docusign,
      SheetStatePrincipal.PreLive_CollatingFunderPack_Docusign,
      SheetStatePrincipal.PreLive_WithFunder_Docusign,
      SheetStatePrincipal.PreLive_FunderApproved,
      SheetStatePrincipal.Live,
    ];

const CircleAndText = ({ text, isEnd = false, isCompleted = false }) => {
  return (
    <div className="sheet-state-progress-view">
      <div className="state-progress-shape">
        <div
          className={classNames(
            "progress-circle",
            isCompleted ? "completed" : "not-completed",
          )}
        >
          <p className="draft-text">
            {isCompleted ? (
              <Icon name="check" />
            ) : (
              <Icon name="circle outline" />
            )}
          </p>
        </div>
        <p className="draft">{text}</p>
      </div>
      {!isEnd && (
        <div
          className={classNames(
            "vertical-line",
            isCompleted ? "completed" : "not-completed",
          )}
        />
      )}
    </div>
  );
};

const SheetStateProgressView = ({ sheet }) => {
  const { t } = useTranslation("agreements");
  const { t: tState } = useTranslation("enums", {
    keyPrefix: process.env.TRADE_AS_IPS
      ? "SheetStateIps"
      : "SheetStatePrincipal",
  });

  return (
    <ModalBuilder
      withForm
      renderTrigger={(trigger) => (
        <Table.MenuItem onClick={trigger}>
          {t("SheetActions.ViewProgress.TriggerText")}
        </Table.MenuItem>
      )}
      renderModal={(modalProps) => (
        <TranslatedModal.Information
          {...modalProps}
          header={t("SheetActions.ViewProgress.HeaderText")}
          cancelDisabled={true}
          submitLabel="Close"
          cancelLabel=""
        >
          {statesToRender.map((sheetState, index) => (
            <CircleAndText
              text={tState(
                Object.entries(
                  process.env.TRADE_AS_IPS
                    ? SheetStateIps
                    : SheetStatePrincipal,
                ).find((x) => x[1] == sheetState)[0],
              )}
              isCompleted={sheetState <= sheet.stateDescriptionNumber}
              isEnd={
                index == statesToRender.length - 1 &&
                ((process.env.TRADE_AS_IPS &&
                  sheet.stateDescriptionNumberIps == SheetStateIps.Live) ||
                  (!process.env.TRADE_AS_IPS &&
                    sheet.stateDescriptionNumberPrincipal ==
                      SheetStatePrincipal.Live))
              }
              key={index}
            />
          ))}

          {((process.env.TRADE_AS_IPS &&
            sheet.stateDescriptionNumberIps >= SheetStateIps.Retired) ||
            (!process.env.TRADE_AS_IPS &&
              sheet.stateDescriptionNumberPrincipal >=
                SheetStatePrincipal)) && (
            <React.Fragment>
              <CircleAndText
                text={tState(
                  Object.entries(
                    process.env.TRADE_AS_IPS
                      ? SheetStateIps
                      : SheetStatePrincipal,
                  ).find(
                    (x) =>
                      x[1] ==
                      (process.env.TRADE_AS_IPS
                        ? SheetStateIps.Retired
                        : SheetStatePrincipal.Retired),
                  )[0],
                )}
                isCompleted={
                  process.env.TRADE_AS_IPS
                    ? SheetStateIps.Retired <= sheet.stateDescriptionNumberIps
                    : SheetStatePrincipal.Retired <=
                      sheet.stateDescriptionNumberPrincipal
                }
                isEnd={
                  process.env.TRADE_AS_IPS
                    ? sheet.stateDescriptionNumberIps < SheetStateIps.Voided
                    : sheet.stateDescriptionNumberPrincipal <
                      SheetStatePrincipal.Voided
                }
              />
              {((process.env.TRADE_AS_IPS &&
                sheet.stateDescriptionNumberIps >= SheetStateIps.Voided) ||
                (!process.env.TRADE_AS_IPS &&
                  sheet.stateDescriptionNumberPrincipal >=
                    SheetStatePrincipal.Voided)) && (
                <CircleAndText
                  text={tState(
                    Object.entries(
                      process.env.TRADE_AS_IPS
                        ? SheetStateIps
                        : SheetStatePrincipal,
                    ).find(
                      (x) =>
                        x[1] ==
                        (process.env.TRADE_AS_IPS
                          ? SheetStateIps.Voided
                          : SheetStatePrincipal.Voided),
                    )[0],
                  )}
                  isCompleted={
                    (process.env.TRADE_AS_IPS &&
                      SheetStateIps.Voided <=
                        sheet.stateDescriptionNumberIps) ||
                    (!process.env.TRADE_AS_IPS &&
                      SheetStatePrincipal.Voided <=
                        sheet.stateDescriptionNumberPrincipal)
                  }
                  isEnd={true}
                />
              )}
            </React.Fragment>
          )}
        </TranslatedModal.Information>
      )}
    />
  );
};

export default SheetStateProgressView;
