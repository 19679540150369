import React from "react";
import { connect } from "react-redux";
import ConfirmationModal from "./ConfirmationModal";
import { useTranslation } from "react-i18next";
import { saveDetails } from "features/Sheets/ManageSheet/actions";

const SaveConfirmationModal = ({
  onSubmitted,
  disabled,
  sheetId,
  sheetCanBeUpdated,
  buttonContent,
}) => {
  const { t } = useTranslation("sheetTranslation");
  return (
    <ConfirmationModal
      disabled={disabled}
      sheetId={sheetId}
      sheetCanBeUpdated={sheetCanBeUpdated}
      buttonContent={buttonContent}
      onSubmitted={onSubmitted}
      headerText={t("saveConfirmationModal.header")}
      bodyText={t(
        sheetCanBeUpdated
          ? "saveConfirmationModal.bodyTextWithSaveWarning"
          : "saveConfirmationModal.bodyTextWithoutSaveWarning"
      )}
      errorText={t("saveConfirmationModal.unallocated_unit_error")}
      submitAction={sheetCanBeUpdated ? saveDetails : null}
    ></ConfirmationModal>
  );
};

export default connect()(SaveConfirmationModal);
