import React, { useEffect } from "react";
import {
  Lookup,
  registerLookup,
  apiGet,
  Form,
  useFormField,
  TriggerEvent,
} from "@redriver/cinnamon";
import { ActionTypes as ListLargeFormatActionTypes } from "./actions";
import { InlineLoader } from "features/../../../shared/components/forms/InlineLoader";
import { useTranslation } from "react-i18next";
import { InchDivision } from "features/../../../shared/constants/enums";
const NAMESPACE = "LARGEFORMAT/LOOKUPS";

export const LookupTypes = {
  largeFormatPrinterLookup: "largeFormatPrinterConfigLookup",
  largeFormatInkInclusiveLookup: "largeFormatInkInclusiveLookup",
  largeFormatPaperInclusiveLookup: "largeFormatPaperInclusiveLookup",
  rollMediaNameLookup: "rollMediaNameLookup",
};

registerLookup(
  LookupTypes.largeFormatPrinterLookup,
  ({ sheetId }) =>
    apiGet(
      ListLargeFormatActionTypes.GetLargeFormatPrinterConfig,
      `lookups/large-format/printer-config`,
      { sheetId },
    ),
  {
    transform: (data, lookupParams) =>
      data && Array.isArray(data)
        ? data.map(x => {
            return {
              value: x.id,
              text: x.name,              
              targetMarket: x.targetMarket,
              paperWidth: x.paperWidth,
              paperWidthmm: x.paperWidthmm,
              paperWidthInches: x.paperWidthInches,
              colours: x.colours,
              scanner: x.scanner,
              costPerMl: x.costPerMl,
              costPerScan: x.costPerScan,
              costPerMlCleaning: x.costPerMlCleaning,
              includeCleaning: x.cleaningMeter,
              meteredServices: x.meteredServices,
              hasCleaningMeter: x.hasCleaningMeter,
              hasScannerMeter: x.hasScannerMeter,
              priceBVisible: x.priceBVisible,
            };
          })
        : [],
    cache: false,
  },
);

export const LargeFormatPrinterDropdownLookup = ({
  className,
  sheetId,
  disabled,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Lookup
      lookup={LookupTypes.largeFormatPrinterLookup}
      lookupParams={{ sheetId }}
      render={({ loading, response }, lookupData) => {
        let options = response ?? [];
        return (
          <div className={className}>
            {loading ? (
              <InlineLoader isActive={true} size="small" />
            ) : (
              <Form.Dropdown
                {...props}
                label={t(
                  "systemSchedule:largeFormatModal:printerConfig:fields:printer:label",
                )}
                field="printerId"
                disabled={!options.length || disabled}
                options={options}
                optionField="lfPrinterConfig"
                textField="printerName"
              />
            )}
          </div>
        );
      }}
    />
  );
};

registerLookup(
  LookupTypes.largeFormatInkInclusiveLookup,
  ({ printerId, sheetId }) =>
    apiGet(
      ListLargeFormatActionTypes.GetLargeFormatInkInclusive,
      `lookups/large-format/ink-inclusive`,
      { printerId, sheetId },
    ),
  {
    transform: (data, lookupParams) =>
      data && Array.isArray(data)
        ? data.map(x => {
            return {
              value: x.id,
              text: x.usage,
              inkUsedML: x.inkUsedML,
              imageUrl: x.imageUrl,
            };
          })
        : [],
    cache: false,
  },
);

export const LargeFormatInkInclusiveDropdownLookup = ({
  field,
  optionField,
  className,
  printerId,
  sheetId,
  disabled,
  ...props
}) => (
  <Lookup
    lookup={LookupTypes.largeFormatInkInclusiveLookup}
    lookupParams={{ sheetId, printerId }}
    render={({ loading, response }, lookupData) => {
      let options = response
        ? response.map(m => ({
            text: m.text,
            value: m.value,
            inkUsedML: m.inkUsedML,
            imageUrl: m.imageUrl,
          }))
        : [];
      return (
        <div className={className}>
          {loading ? (
            <InlineLoader isActive={true} size="small" />
          ) : (
            <Form.Dropdown
              {...props}
              clearable
              label="Usage"
              field={field}
              disabled={!options.length || disabled}
              options={options}
              optionField={optionField}
              textField="inkUsageName"
            />
          )}
        </div>
      );
    }}
  />
);

registerLookup(
  LookupTypes.largeFormatPaperInclusiveLookup,
  ({ paperWidth, sheetId, dealerOfficeId }) =>
    apiGet(
      ListLargeFormatActionTypes.GetLargeFormatPrinterInclusive,
      `lookups/large-format/paper-inclusive`,
      { paperWidth, sheetId, dealerOfficeId },
    ),
  {
    transform: data =>
      Array.isArray(data)
        ? data.map(x => {
            return {
              value: x.id,
              text: x.name,
              rollMedia: x.rollMedia,
              widthMm: x.widthMm,
              widthInches: x.widthInches,
            };
          })
        : [],
    cache: false,
  },
);

const MmInchDropdownPair = ({
  match,
  setMetric,
  setImperial,
  metricOptions,
  imperialOptions,
  labelMetric,
  fieldNameMetric,
  labelImperial,
  fieldNameImperial,
  toggle,
  disabled,
  ...props
}) => {
  useEffect(() => {
    if (!match) return;
    match.widthMm && setMetric(match.value);
    match.widthInches && setImperial(match.value);
  }, []);

  return (
    <div className="width-dropdown-pair">
      <Form.Dropdown
        {...props}
        clearable
        label={labelMetric}
        field={fieldNameMetric}
        disabled={disabled || !metricOptions.length}
        options={metricOptions}
        optionField="lfPaperInclusive"
        textField="paperWidth"
      />
      <Form.Dropdown
        {...props}
        clearable
        label={labelImperial}
        field={fieldNameImperial}
        disabled={disabled || !imperialOptions.length}
        options={imperialOptions}
        optionField="lfPaperInclusive"
        textField="paperWidth"
      />
      <Form.Trigger
        field={fieldNameMetric}
        event="onChange"
        action={({ fields }) => toggle(fields, fieldNameMetric)}
      />
      <Form.Trigger
        field={fieldNameImperial}
        event="onChange"
        action={({ fields }) => toggle(fields, fieldNameImperial)}
      />
    </div>
  );
};

export const LargeFormatPaperInclusiveDropdownLookup = ({
  paperWidthmm,
  paperWidthInches,
  field = "paperWidthId",
  labelMetric,
  labelImperial,
  className,
  sheetId,
  dealerOfficeId,
  disabled,
  ...props
}) => {
  const fieldNameMetric = "_widthIdMetric",
    fieldNameImperial = "_widthIdImperial";
  let [metric, setMetric] = useFormField(fieldNameMetric),
    [imperial, setImperial] = useFormField(fieldNameImperial),
    [paperWidthFormField, setPaperWidthFormField] = useFormField(field);

  function toggle(fields, changing) {
    let incoming = fields[changing];
    if (!incoming) return;

    setPaperWidthFormField(incoming);

    switch (changing) {
      case fieldNameMetric:
        setImperial("");
        break;
      case fieldNameImperial:
        setMetric("");
        break;
    }
  }

  const paperWidth = process.env.USE_IMPERIAL_UNITS
    ? paperWidthInches
    : paperWidthmm;
  return (
    <Lookup
      lookup={LookupTypes.largeFormatPaperInclusiveLookup}
      lookupParams={{ paperWidth, sheetId, dealerOfficeId }}
      render={({ loading, response }) => {
        const map = x => ({
          text: x.text,
          value: x.value,
        });

        const imperialMap = x => ({
          text:
            x.widthInches.fraction == null
              ? `${x.widthInches.quotient}"`
              : `${x.widthInches.quotient}" ${
                  x.widthInches.fraction.numerator
                }/${InchDivision[x.widthInches.fraction.denominator]}`,
          value: x.value,
        });
        response = (response || []).filter(
          x => x && (x.rollMedia || []).length,
        );
        let metricOptions = response
            .filter(x => x.widthMm != undefined)
            .map(map),
          imperialOptions = response
            .filter(x => x.widthInches != undefined)
            .map(imperialMap),
          match = response.find(x => x && x.value === paperWidthFormField);

        return (
          <div className={className}>
            {loading ? (
              <InlineLoader isActive={true} size="small" />
            ) : (
              <React.Fragment>
                <MmInchDropdownPair
                  {...props}
                  match={match}
                  setMetric={setMetric}
                  setImperial={setImperial}
                  labelMetric={labelMetric}
                  fieldNameMetric={fieldNameMetric}
                  labelImperial={labelImperial}
                  fieldNameImperial={fieldNameImperial}
                  metricOptions={metricOptions}
                  imperialOptions={imperialOptions}
                  toggle={toggle}
                  disabled={disabled}
                />
              </React.Fragment>
            )}
          </div>
        );
      }}
    />
  );
};

registerLookup(
  LookupTypes.rollMediaNameLookup,
  ({ paperWidthId, sheetId }) =>
    apiGet(
      ListLargeFormatActionTypes.GetRollMediaName,
      `lookups/large-format/roll-media-name`,
      { paperWidthId, sheetId },
    ),
  {
    transform: (data, lookupParams) =>
      data && Array.isArray(data)
        ? data.map(x => {
            return {
              value: x.id,
              text: x.name,
              length: x.length,
              weight: x.weight,
              cost: x.cost,
              width: x.width,
              minVolume: x.defaultMinVolume,
              vMinVolume: x.vMinVolume,
            };
          })
        : [],
    cacheExpiry: 15000,
  },
);

export const RollMediaNameDropdownLookup = ({
  className,
  paperWidthId,
  sheetId,
  ddDisabled,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Lookup
      lookup={LookupTypes.rollMediaNameLookup}
      lookupParams={{ paperWidthId, sheetId }}
      render={({ loading, response }, lookupData) => {
        let options = response
          ? response.map(m => ({
              text: m.text,
              value: m.value,
              length: m.length,
              weight: m.weight,
              cost: m.cost,
              width: m.width,
              minVolume: m.minVolume,
              vMinVolume: m.vMinVolume,
            }))
          : [];
        return (
          <div className={className}>
            {loading ? (
              <InlineLoader isActive={true} size="small" />
            ) : (
              <React.Fragment>
                <Form.Dropdown
                  {...props}
                  clearable
                  required={paperWidthId}
                  label={t(
                    "systemSchedule:largeFormatModal:rollFedMediaInclusive:fields:mediaDescription:label",
                  )}
                  field="paperInclusiveId"
                  disabled={!options.length || ddDisabled}
                  options={options}
                  optionField="lfRollMedia"
                  textField="rollMediaName"
                />
              </React.Fragment>
            )}
          </div>
        );
      }}
    />
  );
};
