import React from "react";
import { Modal, ErrorMessage } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";
import { translatedModalProps } from "./helpers";
import { SettingsUploadErrorMessages } from "features/../../../shared/components/errors";
import { isUnexpectedError } from "../../components/modals";

const TranslatedUploadModal = (uploadModalProps) => {    
    const { t } = useTranslation("shared"); 
    const modalProps = translatedModalProps(uploadModalProps, t);

    return (
        <Modal.Upload  {...modalProps}
            header={modalProps.header ? modalProps.header : t("modals.upload.header")}
            submitLabel={modalProps.submitLabel ? modalProps.submitLabel : t("modals.upload.submitLabel")}
            cancelLabel={modalProps.cancelLabel ? modalProps.cancelLabel : t("modals.upload.cancelLabel")}>
            {modalProps.children}

            {isUnexpectedError(modalProps.error) 
            ? <ErrorMessage
                header={t("modals.somethingWentWrong")}
                error={uploadModalProps.error}
                omitCodes                    
                />
            : <SettingsUploadErrorMessages error={uploadModalProps.error} /> }
            
        </Modal.Upload>
    )
}

export default TranslatedUploadModal;
