import React from "react";
import { Form } from "@redriver/cinnamon";
import { ServiceTypesRadioGroup } from "../lookups";

const ServiceForm = ({ formProps, contractTypeResponse }) => (
  <Form {...formProps} className="service-form">
    <Form.Input
      fluid
      required
      field="name"
      label="Name"
      className="highlighted-input"
      placeholder="Name..."
    />
    <Form.Group className="group">
      <ServiceTypesRadioGroup
        field="type"
        label="Service Type"
        fluid
        required
      />
      <Form.Numeric
        field="defaultValue"
        label="Default Value"
        decimalPlaces={2}
        width={10}
        formatValue
        subLabel={process.env.MAJOR_CURRENCY}
        subLabelPosition="left"
      />
    </Form.Group>
    <Form.Checkbox
      field="multiplyByDeviceNumber"
      label="Multiply by Device Number"
    />
    <Form.Input
      fluid
      field="description"
      label="Description"
      className="highlighted-input"
      placeholder="Description..."
    />
    <Form.Input
      fluid
      field="termsAndConditionsReference"
      label="Terms and Conditions Reference"
      className="highlighted-input"
      placeholder="Terms and Conditions Reference..."
    />
    <Form.Group widths="equal">
      <Form.Input
        fluid
        field="ipsBillingCode"
        label="Billing Code"
        className="highlighted-input"
        placeholder="Billing Code..."
      />
      <Form.Input
        fluid
        field="ipsSku"
        label="SKU"
        className="highlighted-input"
        placeholder="SKU..."
      />
    </Form.Group>
    <Form.Group widths="equal">
      <Form.Checkbox
        field="requiredOnEquipmentSchedule"
        label="Required on Product Schedule"
      />
      <Form.Checkbox
        field="hideFromCustomerView"
        label="Hide From Customer View"
      />
    </Form.Group>
    <Form.CheckboxGroup
      field="contractType"
      label="Contract Type(s)"
      options={contractTypeResponse != null ? contractTypeResponse : []}
    />
    <Form.Group widths="equal">
      <Form.Numeric
        fluid
        field="settlementDiscountPercentage"
        label="Settlement Discount %"
        className="highlighted-input"
        placeholder="Settlement Discount %..."
      />
      <Form.Input
        fluid
        field="vatCode"
        label="VAT Code"
        className="highlighted-input"
        placeholder="VAT Code..."
      />
    </Form.Group>
    <Form.Group widths="equal">
      <Form.Input
        fluid
        field="excessInvoiceNominalCode"
        label="Excess Invoice Nominal Code"
        className="highlighted-input"
        placeholder="Excess Invoice Nominal Code..."
      />
      <Form.Input
        fluid
        field="minimumInvoiceNominalCode"
        label="Minimum Invoice Nominal Code"
        className="highlighted-input"
        placeholder="Minimum Invoice Nominal Code..."
      />
      <Form.Input
        fluid
        field="code"
        label="Code"
        className="highlighted-input"
        placeholder="Code..."
      />
    </Form.Group>
  </Form>
);

export default ServiceForm;
