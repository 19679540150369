import React, { useEffect } from "react";
import RightArrow from "features/../../../shared/assets/white-right-arrow.svg";
import { Button, Image } from "semantic-ui-react";

const KeyPressTriggerButton = ({
  showModal,
  customerView,
  disabled,
  customerToggle,
  buttonText,
  className = "primary invisible switch-button",
  hideIcon = false,
}) => {
  const callback = () => (customerView ? showModal() : customerToggle());
  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      event.key === "F7" && callback();
    });
  });

  return (
    <Button className={className} disabled={disabled}>
      <div onClick={callback}>
        {buttonText}
        {!hideIcon && <Image src={RightArrow} />}
      </div>
    </Button>
  );
};

export default KeyPressTriggerButton;
