import { ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { uniqBy } from "lodash";
import { getSheetState, getCalculationData, getSheetDataState } from "../..";
import ComparisonSheetPaperSpend from "./ComparisonSheetPaperSpend";
import CurrentQuarterAgreed from "./CurrentQuarterAgreed";
import ProposedVolumes from "./ProposedVolumes";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { DownloadConfirmationModal, SaveConfirmationModal } from "../Shared";
import { canCalculate, downloadComparisonSnapshot } from "../actions";
import { canSheetBeUpdated, concatenateServiceArrays } from "../helpers";

import { useTranslation, Trans } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";
import PreviewGenerateDocument from "../PreviewGenerateDocument";

const ComparisonSnapshotModal = ({
  schedule,
  services,
  paperInclusiveSpend,
  scheduleAdditionalTerms,
  disabled,
  sheetData,
  calculationData,
  sheetId,
  sheetCanBeUpdated,
}) => {
  const { t } = useTranslation("comparisonSheet");
  let schItems = uniqBy(
    []
      .concat(schedule.itemCosts)
      .concat(schedule.largeFormatItemCosts)
      .filter((x) => x),
    (x) => x.unitId,
  );

  let serItems = uniqBy(
    concatenateServiceArrays(services).filter((x) => !x.hideFromCustomerView),
    (x) => x.service.id,
  );

  return (
    <ModalBuilder
      renderTrigger={(showModal) => (
        <Button
          primary
          icon="clipboard list"
          content={t("Comparison_Sheet")}
          onClick={showModal}
          disabled={disabled}
        />
      )}
      renderModal={(modalProps) => {
        return (
          <TranslatedModal.Edit
            {...modalProps}
            header={t("Comparison_Sheet")}
            submitLabel={t("Exit")}
            submitIcon="sign-out"
            cancelLabel={t("Print")}
            cancelIcon="print"
            className="comparison-snapshot-modal"
          >
            <div className="comparison-sheet-modal">
              {paperInclusiveSpend && (
                <React.Fragment>
                  <div className="comparison-sheet-modal">
                    <ComparisonSheetPaperSpend />
                  </div>
                </React.Fragment>
              )}
              <CurrentQuarterAgreed predictedVolumeEntryDisabled={true} />
              <ProposedVolumes />{" "}
              <div className="table section-header additional-info-section">
                <div className="customer-view-metered-service">
                  <div className="metered-service-header">
                    <strong className="underline">
                      {t("Included_Metered_Services")}:
                    </strong>
                  </div>
                  <div className="additional-item-container">
                    {schItems.map((x) => {
                      return (
                        <div className="metered-service" key={x.id}>
                          <strong>{x.unitName}</strong>
                          <span>{": " + x.unitDescription}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div>
                  <div className="metered-service-header">
                    <strong className="underline">
                      {t("Included_Non_Metered_Services")}:
                    </strong>
                  </div>
                  <div className="additional-item-container">
                    {serItems.map((x) => {
                      return (
                        <div
                          className="metered-service"
                          key={x.service.serviceName}
                        >
                          <span>{x.service.serviceName}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div>
                  <strong>{t("Schedule_Additional_Terms")}</strong>
                  <div className="comparison-modal-additional-terms-body">
                    {scheduleAdditionalTerms}
                  </div>
                </div>
                <div className="comparison-snapshot-tandc">
                  <div>
                    <Trans t={t} components={{ s: <strong /> }}>
                      Agreement
                    </Trans>
                  </div>
                  <div>{t("E&OE")}</div>
                </div>
              </div>
            </div>
            <div className="action-buttons">
              <PermissionCheck target={Targets.Sheet} action={Actions.Download}>
                <PreviewGenerateDocument
                  downloadOnly={true}
                  sheetId={sheetId}
                  renderTrigger={(onRender) => (
                    <SaveConfirmationModal
                      disabled={!canCalculate(sheetData, calculationData)}
                      sheetId={sheetId}
                      sheetCanBeUpdated={sheetCanBeUpdated}
                      buttonContent={t("Preview_Document")}
                      onSubmitted={onRender}
                    />
                  )}
                />
                <DownloadConfirmationModal
                  disabled={!canCalculate(sheetData, calculationData)}
                  sheetId={sheetId}
                  sheetDownloadFunction={downloadComparisonSnapshot}
                  sheetCanBeUpdated={sheetCanBeUpdated}
                  isAdmin={false}
                  buttonContent="Print"
                />
              </PermissionCheck>
              <Button negative onClick={modalProps.onCancel}>
                <Icon name="sign-out" />
                Exit
              </Button>
            </div>
          </TranslatedModal.Edit>
        );
      }}
    />
  );
};

const mapStateToProps = (state) => {
  const { sheetViewType } = getSheetState(state);
  const calculationData = getCalculationData(state);
  const sheetData = getSheetDataState(state);
  const schedule = calculationData
    ? calculationData.breakdown
      ? calculationData.breakdown.systemSchedule
      : []
    : [];
  const services = sheetData ? sheetData.services : [];
  const scheduleAdditionalTerms = sheetData
    ? sheetData.rates
      ? sheetData.rates.scheduleAdditionalTerms
      : ""
    : "";
  const paperInclusiveSpend = sheetData
    ? sheetData.paperInclusive
      ? sheetData.paperInclusive.isPaperInclusive
        ? sheetData.paperInclusive.isPaperInclusive.value
        : false
      : false
    : false;

  return {
    schedule,
    services,
    paperInclusiveSpend,
    scheduleAdditionalTerms,
    calculationData,
    sheetData,
    sheetViewType,
    sheetCanBeUpdated: canSheetBeUpdated(sheetData),
  };
};

export default connect(mapStateToProps)(ComparisonSnapshotModal);
