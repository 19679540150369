import React from "react";
import { Modal, ErrorMessage } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";
import { translatedModalProps } from "./helpers";

const TranslatedAddModal = (addModalProps) => {
    const { t } = useTranslation("shared");    
    const modalProps = translatedModalProps(addModalProps, t);    
    return (
        <Modal.Add  {...modalProps}
            header={modalProps.header ? modalProps.header : t("modals.add.header")}
            submitLabel={modalProps.submitLabel ? modalProps.submitLabel : t("modals.add.submitLabel")}
            cancelLabel={modalProps.cancelLabel ? modalProps.cancelLabel : t("modals.add.cancelLabel")}>
            {modalProps.children}
            <ErrorMessage
                header={addModalProps.error?.status == 500 ?  t("modals.errorHeader"): t("modals.somethingWentWrong")}
                error={addModalProps.error}
                omitCodes                    
            />
        </Modal.Add>
    )
}

export default TranslatedAddModal;
