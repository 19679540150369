import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ModalBuilder, Form, notifySuccess, Format } from "@redriver/cinnamon";
import { TranslatedModal } from "features/../../../shared/components/modals";
import {
  getReWriteAdditionalCapital,
  requestReWriteAdditionalCapital,
} from ".";
import { UnitTable } from "./Components";
import { Grid, GridColumn, GridRow } from "semantic-ui-react";

const RewriteAdditionalCapital = ({
  as: As,
  scheduleId,
  disabled,
  creditAvailable,
}) => {
  const { t } = useTranslation("agreements", { keyPrefix: "ScheduleRow" });
  const { t: trac } = useTranslation("agreements", {
    keyPrefix: "ScheduleRow.RewriteAdditionalCapital",
  });
  const dispatch = useDispatch();

  return (
    <ModalBuilder
      renderTrigger={(showModal) => (
        <As onClick={showModal} disabled={disabled}>
          {t("Actions.Rewrite_with_Additional_Capital")}
        </As>
      )}
      withForm
      loadAction={getReWriteAdditionalCapital}
      loadParams={scheduleId}
      submitAction={requestReWriteAdditionalCapital}
      submitParams={scheduleId}
      onSubmitted={() => {
        dispatch(notifySuccess(t("Actions.Submitted_Successfully")));
      }}
      renderModal={(modalProps, formProps) => {
        return (
          <TranslatedModal.Edit
            className="large-modal upgrade-request-modal"
            header={trac("Header")}
            submitLabel={trac("Submit_Request")}
            submitDisabled={
              !formProps.value.applicableFromPrevious &&
              !formProps.value.applicableFromNext
            }
            {...modalProps}
          >
            <Form {...formProps}>
              {() => (
                <React.Fragment>
                  <Grid className="upgrade-types">
                    <GridRow>
                      <GridColumn width={1} className="right-align">
                        <Form.Checkbox
                          field="applicableFromPrevious"
                          checked={formProps.value.applicableFromPrevious}
                          fluid
                        />
                      </GridColumn>
                      <GridColumn width={15}>
                        <label>{trac("applicable_from_previous")}</label>
                      </GridColumn>
                    </GridRow>
                    <GridRow>
                      <GridColumn width={1} className="right-align">
                        <Form.Checkbox
                          field="applicableFromNext"
                          checked={formProps.value.applicableFromNext}
                          fluid
                        />
                      </GridColumn>
                      <GridColumn width={10}>
                        <label>{trac("applicable_from_next")}</label>
                      </GridColumn>
                      <GridColumn
                        width={5}
                        className="red-highlight credit-available"
                      >
                        <span className="credit-available-label">
                          {trac("Credit_Available")}
                        </span>
                        <Format.Number
                          decimalPlaces={2}
                          thousandSeparator
                          format={process.env.CURRENCY_CODE}
                          value={creditAvailable || 0}
                        />
                      </GridColumn>
                    </GridRow>
                  </Grid>

                  <div className="unit-table-container">
                    <div className="vertical-scrollbar large">
                      <UnitTable formProps={formProps} />
                    </div>
                  </div>

                  <Form.TextArea
                    field="comments"
                    label={t("Additional_Comments")}
                    fluid
                    placeholder={t("Additional_Comments_Placeholder")}
                  />
                </React.Fragment>
              )}
            </Form>
          </TranslatedModal.Edit>
        );
      }}
    />
  );
};

export default RewriteAdditionalCapital;
