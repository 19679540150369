import React, { useState } from "react";
import { connect } from "react-redux";
import { Icon, Transition } from "semantic-ui-react";
import { Form, Format, ModalBuilder, getAuthClaim } from "@redriver/cinnamon";
import classNames from "classnames";
import { InlineLoader } from "features/../../../shared/components/forms/InlineLoader";
import { FixedBoolean, SectionTotal } from "components/forms";
import {
  SheetViewType,
  yesNoOpts,
} from "features/../../../shared/constants/enums";
import SheetStep from "../SheetStep";
import { getSheetPaperInclusiveState, getSheetState } from "features/Sheets";
import { parseBool } from "features/utils";
import { SheetStepEditButton } from "../../Components/SheetStepEditButton";
import PaperStepDetails from "./PaperStepDetails";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { Actions, Targets } from "constants/permissions";
import { withPermissions } from "features/../../../shared/components/auth";

const PaperStep = ({
  sheetId,
  sheetData,
  onNextStep,
  onPreviousStep,
  amethystAmount,
  calculating,
  sheetDetailsSaving,
  displayPrevious,
  displayNext,
  displaySave,
  collapsed,
  toggleVisibility,
  reactRef,
  customerToggle,
  sheetViewType,
  displayCustomerToggle = false,
  impersonatedUser,
  minQuarterlySnapshotPayment,
  quarterlyServiceAdjustment,
  paperInclusive,
  hasPermission,
}) => {
  const [paperInclusiveBackup, setPaperInclusiveBackup] = useState();
  const { t } = useTranslation("paperInclusive");
  if (!hasPermission(Targets.Sheet, Actions.ViewCutSheetPaper)) {
    return null;
  }

  const hideFixedIndicator = !impersonatedUser.canEditAdminFields;

  return (
    <div ref={reactRef} className="step-container">
      <SheetStep
        onNextStep={onNextStep}
        onPreviousStep={onPreviousStep}
        displayPrevious={displayPrevious}
        displayNext={displayNext}
        displaySave={displaySave}
        calculating={calculating}
        sheetId={sheetId}
        displayCustomerToggle={displayCustomerToggle}
        customerToggle={customerToggle}
        className="paper-step"
      >
        <div className="paper-inclusive-step">
          <Form.Object field="paperInclusive" propagateUpdates="always">
            <div className="paper-header">
              <div className="sheet-step-header" onClick={toggleVisibility}>
                <div className="title">
                  <h5>{t("title")}</h5>
                  {toggleVisibility && (
                    <Icon
                      name="dropdown"
                      rotated={collapsed ? "counterclockwise" : null}
                    />
                  )}
                  <Form.If
                    condition={({ fields }) =>
                      collapsed && parseBool(fields.isPaperInclusive.value)
                    }
                  >
                    <span className="included">
                      <Icon name="check" />
                      {t("included")}
                    </span>
                  </Form.If>
                </div>
              </div>
            </div>

            <Transition
              animation="fade down"
              visible={!collapsed}
              duration={500}
            >
              <div>
                <div className="top">
                  <FixedBoolean
                    inline
                    toggle
                    field="isPaperInclusive"
                    options={yesNoOpts}
                    label={
                      <div className="sheet-radio-title">
                        <p className="emphasis">{t("isPaperInclusive")}</p>
                        <span className="required-star">*</span>
                      </div>
                    }
                    className="required-field sheet-radio-group paper-inc-not-grey-out"
                    hideIndicator={hideFixedIndicator}
                  />{" "}
                  <span className="charge-type">
                    <b>
                      {paperInclusive.isPaperInclusive.value
                        ? t(paperInclusive.paperInclusiveChargeType.value)
                        : ""}
                    </b>
                  </span>
                  <ModalBuilder
                    renderTrigger={(show) => (
                      <Form.If
                        condition={({ fields }) =>
                          parseBool(fields.isPaperInclusive.value)
                        }
                      >
                        <SheetStepEditButton
                          action={() => {
                            setPaperInclusiveBackup(sheetData.paperInclusive);
                            show();
                          }}
                          disabled={sheetDetailsSaving}
                        />
                      </Form.If>
                    )}
                    renderModal={(modalProps) => (
                      <TranslatedModal.Edit
                        {...modalProps}
                        header=""
                        className="form paper-inclusive-step custom-actions"
                      >
                        <PaperStepDetails
                          modalProps={modalProps}
                          sheetId={sheetId}
                          paperInclusiveBackup={paperInclusiveBackup}
                        />
                      </TranslatedModal.Edit>
                    )}
                  />
                </div>

                <SectionTotal label={t("total")} className="paper-totaliser">
                  <InlineLoader size="tiny" isActive={calculating} />
                  <Format.Number
                    value={
                      sheetViewType !== SheetViewType.AccountManager &&
                      minQuarterlySnapshotPayment > 0 &&
                      quarterlyServiceAdjustment > 0
                        ? minQuarterlySnapshotPayment
                        : amethystAmount
                    }
                    format={process.env.CURRENCY_CODE}
                    className={classNames(
                      "calculable-field",
                      calculating && "value-loading",
                      amethystAmount < 0 && "negative"
                    )}
                  />
                </SectionTotal>
              </div>
            </Transition>
          </Form.Object>
        </div>
      </SheetStep>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    summary: { amethystAmount },
    minQuarterlySnapshotPayment,
    quarterlyServiceAdjustment,
  } = getSheetPaperInclusiveState(state);
  const { sheetData, sheetDetailsSaving, sheetViewType, calculating } =
    getSheetState(state);
  return {
    sheetDetailsSaving,
    sheetData,
    amethystAmount,
    sheetViewType,
    calculating,
    impersonatedUser: sheetData.impersonatedUser,
    minQuarterlySnapshotPayment,
    quarterlyServiceAdjustment,
    paperInclusive: sheetData.paperInclusive,
    userArea: getAuthClaim(state, "userArea"),
  };
};

export default connect(mapStateToProps, null)(withPermissions(PaperStep));
