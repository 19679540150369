import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

const Sort = {
  Asc: "Asc",
  Desc: "Desc",
};

const SortHeader = ({ title, field, value, onChange, disabled = false }) => {
  const sortName = !!value ? value.sortName : null;
  const sortDirection = !!value ? value.sortDirection : null;

  const isAsc = sortName == field && sortDirection == Sort.Asc;
  const isDesc = sortName == field && sortDirection == Sort.Desc;

  return (
    <div
      className={classNames("sort-header", disabled ? "disabled" : "")}
      onClick={() => {
        if (disabled) {
          return;
        }
        let next = null;
        if (sortName != field) {
          next = Sort.Asc;
        } else {
          switch (sortDirection) {
            case Sort.Asc:
              next = Sort.Desc;
              break;
            case null:
              next = Sort.Asc;
              break;
            default:
              next = null;
              break;
          }
        }
        onChange({
          ...value,
          sortName: next != null ? field : null, // remove sort name if no direction
          sortDirection: next,
        });
      }}
    >
      <div className="sort-content">{title}</div>
      <div
        className={classNames(
          "sort-arrows",
          isAsc ? "asc" : null,
          isDesc ? "desc" : null,
          disabled ? "hidden" : null
        )}
      >
          <Icon className="asc-arrow"
                        name="dropdown"
                        
                        />
          <Icon className="desc-arrow"
                        name="dropdown"
                        />
      </div>
    </div>
  );
};

SortHeader.propTypes = {
  title: PropTypes.any,
  field: PropTypes.string,
  value: PropTypes.shape({
    sortName: PropTypes.string,
    sortDirection: PropTypes.string,
  }),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SortHeader;