import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ModalBuilder } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { getSheetState } from "features/Sheets";
import { TranslatedModal } from "features/../../../shared/components/modals";
import AdminInfoHeader from "./AdminInfoHeader";
import TotalCfaChargesInSchedule from "./TotalCfaChargesInSchedule";
import { FunderTypeTabs } from "./FunderTypeTabs";
import {
  DownloadConfirmationModal,
  downloadCapitalCalcAdminInfo,
} from "features/Sheets/ManageSheet";

const CapitalInfoModal = ({
  sheetId,
  sheetCanBeUpdated,
  calcBreakdown,
  data,
  canCalculateSheet,
}) => {
  const { t } = useTranslation("capitalCalc", { keyPrefix: "infoModal" });

  const { calculating } = useSelector(getSheetState);

  return (
    <ModalBuilder
      renderTrigger={(render) => (
        <Button
          primary
          icon="info circle"
          content={t("title")}
          onClick={(e) => {
            render();
            e.stopPropagation();
          }}
          disabled={
            calculating ||
            !canCalculateSheet ||
            !calcBreakdown ||
            !calcBreakdown.itemCosts
          }
        />
      )}
      renderModal={(modalProps) => {
        return (
          <TranslatedModal
            {...modalProps}
            className="large capital-calc-info custom-actions"
          >
            <AdminInfoHeader breakdown={data.adminInfoHeader} />
            <TotalCfaChargesInSchedule breakdown={data} />

            <h4 className="funder-types-title">{t("funderTypes.title")}</h4>
            <FunderTypeTabs data={data} />

            <div className="custom-action-container">
              <Button
                primary
                content={t("close")}
                onClick={(e) => {
                  modalProps.onCancel();
                  e.stopPropagation();
                }}
              />
              <DownloadConfirmationModal
                sheetId={sheetId}
                sheetDownloadFunction={downloadCapitalCalcAdminInfo}
                sheetCanBeUpdated={sheetCanBeUpdated}
                buttonContent={t("download")}
              />
            </div>
          </TranslatedModal>
        );
      }}
    />
  );
};

export default CapitalInfoModal;
