import React from "react";
import { useSelector } from "react-redux";
import { Table } from "semantic-ui-react";
import {
  BlankCurrencyFormatter,
  BlankNumberFormatter,
} from "features/../../../shared/components/display";
import { useTranslation } from "react-i18next";

const CurrentSchedule = () => {
  const { t } = useTranslation("systemScheduleStability", {
    keyPrefix: "schedule",
  });
  const data = useSelector(
    (state) => state.sheets?.rawCalculation?.scheduleStability?.currentSchedule,
  );

  return (
    <React.Fragment>
      {data && (
        <React.Fragment>
          <h5>{t("from_current_schedule")}</h5>
          <Table className="table alt">
            <Table.Body>
              <TR
                className="border-bottom"
                cell1={t("volume_selection")}
                cell2={<BlankNumberFormatter value={data.volSelection} />}
              />
              <TR className="blank" />
              <TR
                cell1={t("quarterly_contracted_volumes")}
                className="highlight"
              />
              <TR
                className="light-grey"
                cell1={t("managed_meter")}
                cell2={
                  <BlankNumberFormatter
                    value={data.qtrContractedVolManagedMeter}
                  />
                }
              />
              <TR
                className="border-bottom"
                cell1={t("non_managed_meter")}
                cell2={
                  <BlankNumberFormatter
                    value={data.qtrContractedVolNonManagedMeter}
                  />
                }
              />
              <TR className="blank" />
              <TR
                cell1={t("new_unadjusted_total_managed_volume")}
                cell2={
                  <BlankNumberFormatter
                    value={data.newUnadjustedTotalManagedVol}
                  />
                }
                className="highlight-alt"
              />
              <TR className="blank" />
              <TR
                cell1={t("quarterly_minimum_payments")}
                className="highlight"
              />
              <TR
                className="light-grey"
                cell1={t("managed_meter")}
                cell2={
                  <BlankCurrencyFormatter
                    value={data.qtrMinPaymentsManagedMeter}
                  />
                }
              />
              <TR
                className="border-bottom"
                cell1={t("non_managed_meter")}
                cell2={
                  <BlankCurrencyFormatter
                    value={data.qtrMinPaymentsNonManagedMeter}
                  />
                }
              />
              <TR
                className="border-bottom bold"
                cell1={t("total_quarterly_minimum_payment")}
                cell2={
                  <BlankCurrencyFormatter value={data.qtrMinPaymentsTotal} />
                }
              />
              <TR className="blank" />
              <TR
                cell1={t("new_schedule_value")}
                cell2={<BlankCurrencyFormatter value={data.newScheduleValue} />}
                className="highlight-alt"
              />
              <TR className="blank border-bottom" />

              <TR
                cell1={t("full_schedule_value_difference")}
                cell2={
                  <BlankCurrencyFormatter
                    value={data.fullScheduleValueDifference}
                  />
                }
              />
              <TR
                className="light-grey"
                cell1={t("adjusted_schedule_value_difference")}
                cell2={
                  <BlankCurrencyFormatter
                    value={data.adjustedScheduleValueDifference}
                  />
                }
              />
            </Table.Body>
          </Table>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const TR = ({ cell1 = "", cell2 = "", className = "" }) => {
  return (
    <Table.Row className={className}>
      <Table.Cell style={{ width: "70%" }}>{cell1}</Table.Cell>
      <Table.Cell>{cell2}</Table.Cell>
    </Table.Row>
  );
};

export default CurrentSchedule;
