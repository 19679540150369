export const getSheetState = state => state.sheets;
export const getSheetDataState = state => state.sheets.sheetData;
export const getSheetRestrictionsState = state =>
  state.sheets.sheetData.restrictions;
export const getSheetSummaryState = state => state.sheets.summary;
export const getSheetIpsRatesState = state => state.sheets.sheetData.ipsRates;
export const getSheetRatesState = state => state.sheets.sheetData.rates;
export const getSheetCapitalState = state => state.sheets.sheetData.capital;
export const getSheetServicesState = state => state.sheets.sheetData.services;
export const getSheetScheduleState = state =>
  state.sheets.sheetData.systemScheduleItems;
export const getSheetLargeFormatScheduleState = state =>
  state.sheets.sheetData.largeFormatGroups;
export const getSheetPaperInclusiveState = state =>
  state.sheets.sheetData.paperInclusive;
export const getSheetDefaultsState = state => state.sheets.sheetData.defaults;
export const getCalculationData = state => state.sheets.rawCalculation;
export const getUnitTypesLookupState = state => state.lookups.unitsLookup;
export const getLargeFormatPrinterState = state =>
  state.sheets.sheetData.largeFormatCreateModal;
