import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { validate as isValidGuid } from "uuid";
import PropTypes from "prop-types";
import {
  Field,
  connectForm,
  FormUtils,
  LookupTypeAhead,
  getLookupData,
} from "@redriver/cinnamon";
import classNames from "classnames";
import FixedInputIndicator from "./FixedInputIndicator";
import {
  withPermissions,
  withCurrentUser,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import FixedFieldType, { FixableTypes } from "constants/forms/FixedFieldType";
import {
  ActionTypes as SheetActionTypes,
  LookupNames as SheetLookupNames,
  getSheetDataState,
} from "features/Sheets";
import { UserArea } from "features/../../../shared/constants/enums";

/**
 * Custom Cinnamon component that provides an interface for the FixedFieldView object
 */
class FixedService extends Component {
  static propTypes = {
    label: PropTypes.node,
    actions: PropTypes.node,
    inline: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fluid: PropTypes.bool,
    subLabel: PropTypes.string,
    subLabelPosition: PropTypes.string,
    renderReadOnly: PropTypes.func,
    required: PropTypes.bool,
    requiredError: PropTypes.string,
    minLength: PropTypes.number,
    minLengthError: PropTypes.string,
    maxLength: PropTypes.number,
    maxLengthError: PropTypes.string,
    confirmField: PropTypes.string,
    confirmFieldError: PropTypes.string,
    field: PropTypes.string.isRequired,
    showErrors: PropTypes.bool,
    allErrors: PropTypes.bool,
    animateErrors: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    customErrors: PropTypes.arrayOf(PropTypes.string),
    customValidator: PropTypes.func,
    notifiedFields: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string]),
      isFixed: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
      ]),
    }),
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.object,
    ]),
    passThruProps: PropTypes.object,
    formState: PropTypes.object,

    /**
     * If enabled, when clicking into the field, the field's value is selected and typing will override the value. This allows the field to be quickly overridden when tabbing.
     * @default true
     */
    selectOnFocus: PropTypes.bool,

    /**
     * Function to run when dropdown is clicked on
     */
    onFocus: PropTypes.func,

    /**
     * Function to run when dropdown is clicked away from
     */
    onBlur: PropTypes.func,

    /**
     * Function to run when dropdown is closed
     */
    onClose: PropTypes.func,

    /**
     * Sheet ID
     */
    sheetId: PropTypes.string,

    /**
     * Adds CSS class to change display priority, use 1-5 to change display priority with 5 being the highest.
     * @default 0
     */
    displayPriority: PropTypes.number,
  };

  state = {
    fixType: FixedFieldType.None,
  };

  static defaultProps = {
    value: { value: "" },
    onChange: () => {},
    label: "",
    inline: false,
    selectOnFocus: true,
    selectOnBlur: false,
    selection: true,
    lookupOptions: {
      cache: true,
    },
    displayPriority: 0,
  };

  determineFixType(value) {
    return value.isFixed ? FixedFieldType.Set : FixedFieldType.None;
  }

  componentDidMount() {
    const { value } = this.props;

    const fixType = this.determineFixType(value);

    this.setState({
      fixType,
    });
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      const fixType = this.determineFixType(value);

      this.setState({
        fixType,
      });
    }
  }

  enforceValueConstraints = (value, fixType) => {
    const {
      value: { setValue },
    } = this.props;

    return fixType === FixedFieldType.Set ? setValue : value;
  };

  /**
   * The onChange method for the `<ServicesTypeAheadLookup />` element of the FixedService
   */
  onValueChange = (_, { value }) => {
    const isNew = !isValidGuid(value);
    if (value === this.props.value.id) return; // Not changing so do nothing

    const { services } = this.props;
    const service = services.find((x) => x.value === value);

    this.props.onChange({
      ...this.props.value,
      value: isNew ? Date.now() : value,
      serviceName: isNew ? value : service ? service.text : "-",
    });
  };

  /**
   * The onChange method for the `<FixedServiceEditor />` element of the FixedService
   */
  onValuePropsChange = (res) => {
    this.props.onChange({
      ...this.props.value,
      ...res,
      userArea: this.props.currentUser.userArea,
    });
  };

  onFocus = ({ target }) => {
    this.setState(
      {
        showFormatted: false,
      },
      () => {
        if (this.props.selectOnFocus) {
          target.select();
        }
      }
    );
  };

  onBlur = (e) => {
    if (this.props.onBlur) this.props.onBlur(e);
  };

  defaultRenderReadOnly = (props) => <p>{props.value.value}</p>;

  renderReadOnly = () =>
    (this.props.renderReadOnly || this.defaultRenderReadOnly)(this.props);

  getInferredValueOptions = () => {
    // auto-infer unknown value options when textField specified

    const { textField, value } = this.props;

    let inferredValueOptions = undefined;

    if (textField && value && value[textField]) {
      const text = value[textField];
      const textArray = Array.isArray(text) ? text : [text];
      const valueArray = Array.isArray(value) ? value : [value];

      if (valueArray.length === textArray.length) {
        inferredValueOptions = valueArray.map((v, i) => ({
          value: v.id,
          text: textArray[i],
        }));
      }

      return inferredValueOptions;
    }
  };

  render() {
    const {
      value,
      errors,
      showErrors,
      allErrors,
      animateErrors,
      readOnly,
      label,
      actions,
      inline,
      width,
      fluid,
      required,
      className,
      hasPermission,
      currentUser,
      sheetId,
      onBlur,
      onFocus,
      onClose,
      selection,
      lookupOptions,
      includeMandatory,
      hideIndicator,
    } = this.props;

    const { fixType } = this.state;

    const isAdmin = hasPermission(Targets.SheetAdmin, Actions.Edit);

    const disabled = this.props.disabled || fixType == FixedFieldType.Set;

    const hiddenIndicator =
      hideIndicator || (!isAdmin && fixType === FixedFieldType.None);
    const hasRightLabel = !hiddenIndicator;

    const unknownValueOptions = this.getInferredValueOptions();
    const unknownValueText =
      unknownValueOptions && unknownValueOptions.length === 1
        ? unknownValueOptions[0].text
        : null;

    const indicatorDisabled =
      this.props.disabled ||
      !isValidGuid(value.value) || // Newly added services not fixable as they are not in the lookup
      (value?.isFixed &&
        value?.userArea === UserArea.IpsAdmin &&
        value?.userArea != currentUser.userArea);

    return (
      !!value && (
        <Field
          inline={inline}
          required={required}
          renderReadOnly={readOnly && this.renderReadOnly}
          width={width}
          fluid={fluid}
          label={label}
          actions={actions}
          errors={FormUtils.fieldErrors(errors, showErrors, allErrors)}
          animateErrors={animateErrors}
          className={classNames(
            "fix-service-container",
            className,
            hasRightLabel && "with-right-label"
          )}
          onChange={this.onValueChange}
          disabled={disabled}
        >
          <LookupTypeAhead
            value={value.value}
            lookup={SheetLookupNames[SheetActionTypes.Services]}
            lookupParams={{ sheetId, includeMandatory }}
            onChange={this.onValueChange}
            searchSuggestions
            alwaysShowSuggestionsOnBlur
            width={8}
            allowAdditions
            onFocus={onFocus}
            onBlur={onBlur}
            onClose={onClose}
            onClick={onFocus}
            unknownValueOptions={unknownValueOptions}
            unknownValueText={unknownValueText}
            selection={selection}
            lookupOptions={lookupOptions}
            disabled={disabled}
            className={classNames(hasRightLabel && "with-right-label")}
          />
          {hasRightLabel && (
            <FixedInputIndicator
              isAdmin={isAdmin}
              fixType={fixType}
              fieldId={value.id || null}
              values={[value.value || ""]}
              disabled={indicatorDisabled}
              onForcedValuesChanged={this.onValuePropsChange}
              fixableType={FixableTypes.Service}
              customEditorData={{
                sheetId: sheetId,
                unknownValueOptions,
                unknownValueText,
              }}
              displayPriority={this.props.displayPriority}
            />
          )}
        </Field>
      )
    );
  }
}

const mapStateToProps = (state) => {
  const sheet = getSheetDataState(state);
  const services = sheet
    ? getLookupData(state, SheetLookupNames[SheetActionTypes.Services], {
        sheetId: sheet.id,
        search: "",
        includeMandatory: false,
      })
    : null;
  return {
    services: services && services.response ? services.response : [],
  };
};

export default connectForm({
  displayName: (props) =>
    props.label && typeof props.label === "string" ? props.label : "Input",
  validators: [],
})(
  compose(
    withPermissions,
    withCurrentUser
  )(connect(mapStateToProps, null)(FixedService))
);
