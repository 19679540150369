import React from "react";
import { Lookup, registerLookup, apiGet, Form } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM/LOOKUPS";

const ActionTypes = {
    GetTimezones: `${NAMESPACE}/GET_TIMEZONES`,

};

const LookupTypes = {
    GetTimezones: "timezonesLookup",
};

// Timezone dropdown
registerLookup(
    LookupTypes.GetTimezones,
    () =>
        apiGet(ActionTypes.GetTimezones, "lookups/timezones"),
    {
        cache: false,
        transform: data => data.map(i => ({ text: i, value: i }))
    }
);
export const TimezonesDropdownLookup = props => {
    return (
    <Form.Dropdown {...props}
        search
        clearable
        lookup={LookupTypes.GetTimezones}       
    />
    )
};