import React from "react";
import PropTypes from "prop-types";
import { Segment } from "semantic-ui-react";
import classNames from "classnames";

/**
 * A `<Segment />` wrapper for displaying Principal subtotal calculations.
 * @param {Object} props See PropTypes
 */
const SectionTotal = ({ className, label, children, ...props }) => (
  <Segment basic {...props} className={classNames("totaliser", className)}>
    <span>{label}</span>
    {children}
  </Segment>
);

SectionTotal.propTypes = {
  /**
   * The className string to add to the root `<Segment />`
   */
  className: PropTypes.string,
  /**
   * The title to display on the left-hand side of the element
   */
  label: PropTypes.node
};

export default SectionTotal;
