import PropTypes from "prop-types";
import i18next from "i18next";

const rangeLength = () => {
  return {
    propTypes: {
      minLength: PropTypes.number,
      maxLength: PropTypes.number,
    },
    shouldValidate: () => true,
    getErrors: (field, value) => {
      if (
        typeof value === "object" &&
        !(value?.value == null) &&
        !!value.value.length
      ) {
        const val = parseFloat(value.value);
        if (!(value.minimumValue == null) && !(value.maximumValue == null)) {
          if (val < value.minimumValue) {
            return [
              `${field} ${i18next.t("sheetTranslation:CanNotBeLessThan")} ${
                value.minimumValue
              }`,
            ];
          }
          if (val > value.maximumValue) {
            return [
              `${field} ${i18next.t("sheetTranslation:CanNotBeMoreThan")} ${
                value.maximumValue
              }`,
            ];
          }
        }
      }
      return [];
    },
  };
};

export default rangeLength;
