import React from "react";
import { Table, ActionMenu } from "@redriver/cinnamon";
import { withPermissions } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";

import RemoveRateGroupModal from "./RemoveRateGroupModal";
import EditRateGroupModal from "./EditRateGroupModal";
import RateGroupAuditModal from "./RateGroupAuditModal";
import ApproveRateGroupModal from "./ApproveRateGroupModal";
import RateGroupStatus from "../../../constants/enums/RateGroupStatus";

const RateGroupActions = ({
  rateGroup,
  onRefresh,
  deleteAction,
  editAction,
  approveAction,
  hasPermission,
}) => {
  const canDelete = hasPermission(Targets.Defaults, Actions.Edit);
  const canEdit = hasPermission(Targets.Defaults, Actions.Edit);
  const canApprove = hasPermission(Targets.Defaults, Actions.Approve);

  const requiresApproval =
    rateGroup.status === RateGroupStatus.RequiresApproval;
  const isDraft = rateGroup.status === RateGroupStatus.Draft;
  const isPending = rateGroup.status === RateGroupStatus.Upcoming;
  const isInactive = rateGroup.status === RateGroupStatus.Inactive;

  const showEdit = canEdit && (requiresApproval || isDraft);
  const showDelete = canDelete &&
  (requiresApproval || isDraft || isPending || isInactive);
  const showApprove = canApprove && (requiresApproval || isDraft);


  return (
    <Table.Menu>
      {showEdit && (
        <EditRateGroupModal
          rateGroup={rateGroup}
          onSubmitted={onRefresh}
          submitAction={editAction}
        />
      )}
      {showDelete && (
          <RemoveRateGroupModal
            rateGroup={rateGroup}
            onSubmitted={onRefresh}
            submitAction={deleteAction}
          />
        )}
      {showApprove && (               
        <ApproveRateGroupModal
          rateGroup={rateGroup}
          onSubmitted={onRefresh}
          submitAction={approveAction}
        />
      )}
      
      {(showEdit || showDelete || showApprove) && (
        <ActionMenu.Divider />)
      }
      <RateGroupAuditModal rateGroup={rateGroup} />
    </Table.Menu>
  );
};

export default withPermissions(RateGroupActions);
