import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  withPermissions,
  PermissionCheck,
} from "features/../../../shared/components/auth";
import UploadEntityType from "features/../../../shared/constants/enums/UploadEntityType";
import {
  FormBuilder,
  Form,
  ModalBuilder,
  notifySuccess,
  ListBuilder,
} from "@redriver/cinnamon";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { Button } from "semantic-ui-react";
import { compose } from "redux";
import { Targets, Actions } from "constants/permissions";
import { useTranslation } from "react-i18next";
import { DownloadTemplate } from "features/../../../shared/features/DataUploads";
import { UploadEntityTypeDropdown } from "features/../../../shared/components/forms";
import { downloadTemplate, uploadData, getDataUploads } from "./actions";
import DataUploadsList from "./DataUploadsList";
import { getAgreementsDealerOfficeState } from "pages/nav/selectors";

const UploadDownloadTemplate = ({ hasPermission }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("dataUploads", {
    keyPrefix: "downloadUploadForm",
  });

  const [template, setTemplate] = useState();

  const templateChange = (field, data, applyChanges, formProps) => {
    const { value, onChange } = formProps;
    let newFormData = applyChanges(value);
    if (field == "template") {
      setTemplate(data);
    }
    onChange(field, newFormData, () => applyChanges(newFormData));
  };

  const dealerOfficeId = useSelector(getAgreementsDealerOfficeState);

  const hasDataUploadPerms =
    hasPermission(Targets.UploadSettings, Actions.Upload) ||
    hasPermission(Targets.UploadSettings, Actions.Download);

  return (
    <ListBuilder
      withTable
      initialPagination={{ pageSize: 25, pageNumber: 1 }}
      loadAction={getDataUploads}
      loadParams={dealerOfficeId.id}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      renderList={(tableProps, state, events) => (
        <div>
          {hasDataUploadPerms && (
            <React.Fragment>
              <div className="header-dropdown">
                <FormBuilder
                  renderForm={(formProps) => (
                    <Form
                      {...formProps}
                      onChange={(field, data, applyChanges) =>
                        templateChange(field, data, applyChanges, formProps)
                      }
                    >
                      <UploadEntityTypeDropdown
                        field="template"
                        placeholder={t("templateDrpPlaceholder")}
                        label={t("templateDrpLabel")}
                      />
                    </Form>
                  )}
                />
              </div>
              <br /> <br />
              {template && (
                <div className="flex">
                  <PermissionCheck
                    target={Targets.UploadSettingsTemplates}
                    action={Actions.Download}
                  >
                    <DownloadTemplate
                      templateName={UploadEntityType[template]}
                      template={template}
                      title={t("download")}
                      submitAction={downloadTemplate}
                      dealerOfficeId={dealerOfficeId.id}
                    />
                  </PermissionCheck>
                  <PermissionCheck
                    target={Targets.UploadSettings}
                    action={Actions.Upload}
                  >
                    <ModalBuilder
                      withForm
                      submitAction={uploadData}
                      submitParams={{
                        type: template,
                        dealerOfficeId: dealerOfficeId.id,
                      }}
                      onSubmitted={({ requiresApproval }) => {
                        dispatch(
                          notifySuccess(
                            requiresApproval
                              ? "Settings data was uploaded successfully, sent for approval."
                              : "Settings data was uploaded successfully, ready for you to process."
                          )
                        );
                        events.onRefresh();
                      }}
                      renderTrigger={(showModal) => (
                        <Button
                          primary
                          onClick={showModal}
                          content={t("upload")}
                          className="upload-btn"
                          icon="upload"
                        ></Button>
                      )}
                      renderModal={(modalProps, formProps) => (
                        <TranslatedModal.Upload
                          {...modalProps}
                          header={t("uploadTemplate")}
                          className="upload-settings-data"
                        >
                          <Form {...formProps}>
                            <Form.FileUpload
                              field="file"
                              fluid
                              required
                              fileExtensionTypes={["xlsx"]}
                            />
                          </Form>
                        </TranslatedModal.Upload>
                      )}
                    />
                  </PermissionCheck>
                </div>
              )}
            </React.Fragment>
          )}

          <DataUploadsList
            tableProps={tableProps}
            state={state}
            events={events}
            dealerOfficeId={dealerOfficeId.id}
          />
        </div>
      )}
    />
  );
};

export default compose(withPermissions)(UploadDownloadTemplate);
