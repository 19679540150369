import React from "react";
import { useSelector } from "react-redux";
import { ModalBuilder, Form, Table, ErrorMessage } from "@redriver/cinnamon";
import { withRouter } from "react-router-dom";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { duplicateSheetToNewMaster, getSheetDuplicateName } from "../actions";
import { getAgreementsState } from "features/Agreements/selectors";

const DuplicateToNewMaster = ({
  as: As = Table.MenuItem,
  sheetId,
  name,
  onOpen,
  onClose,
  onSubmitted,
}) => {
  const { t } = useTranslation("sheetHeader");
  const { companyFilters } = useSelector(getAgreementsState);
  const { client } = companyFilters;
  return (
    <PermissionCheck
      action={Actions.DuplicateToNewMaster}
      target={Targets.Sheet}
    >
      <ModalBuilder
        withForm
        loadAction={getSheetDuplicateName}
        loadParams={{
          sheetId: sheetId,
          duplicateToNewMaster: true,
        }}
        submitAction={duplicateSheetToNewMaster}
        submitParams={{ sheetId, clientId: client ? client.id : null }}
        initialData={{ name }}
        onSubmitted={(response) => onSubmitted(response)}
        onCancelled={onClose}
        renderTrigger={(trigger) => (
          <As
            onClick={() => {
              if (onOpen) onOpen();
              trigger();
            }}
          >
            {t("DuplicateToNewMaster")}
          </As>
        )}
        renderModal={(modalProps, formProps) => (
          <TranslatedModal
            {...modalProps}
            header={t("DuplicateToNewMaster")}
            size="small"
          >
            <Form {...formProps}>
              <Form.Input
                field="name"
                label={t("Name")}
                required
                requiredError={t("Name") + " " + t("is_required")}
              />
              <ErrorMessage
                header={t("Something_went_wrong")}
                error={modalProps.error}
                omitCodes
                overrideMessages={{
                  4040: t("A_sheet_with_this_name_already_exists"),
                  4039: t(
                    "This_sheet_cannot_be_duplicated_because_it_is_seeded",
                  ),
                }}
              />
            </Form>
          </TranslatedModal>
        )}
      />
    </PermissionCheck>
  );
};

export default withRouter(DuplicateToNewMaster);
