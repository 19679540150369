import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import { setupApp } from "./setup";
// import { InactivityMonitor } from "./pages/InactivityMonitor";
import { OnlineCheck } from "features/../../../shared/components/navigator";
import Shell from "pages/Shell";

const { store } = setupApp();

const App = () => (
  <Provider store={store}>
    <OnlineCheck />
    <BrowserRouter>
      <LastLocationProvider>
        {/* Disabled temporarily because it is suspected of causing some unusual front-end issues
        <InactivityMonitor />
        */}
        <Shell />
      </LastLocationProvider>
    </BrowserRouter>
  </Provider>
);

createRoot(document.getElementById("root")).render(<App />);
