import React from "react";
import { ClaimCheck } from "@redriver/cinnamon";
import PropTypes from "prop-types";
import { UserType } from "features/../../../shared/constants/enums";

const UserTypeCheck = ({ restrictTo, component, children }) => {
  const Component = component;
  return (
    <ClaimCheck claim="userType" value={restrictTo}>
      {Component ? <Component /> : children}
    </ClaimCheck>
  );
};

UserTypeCheck.propTypes = {
  restrictTo: PropTypes.oneOf(Object.values(UserType)),
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element])
};

UserTypeCheck.defaultProps = {
  restrictTo: UserType.Internal
};

export default UserTypeCheck;
