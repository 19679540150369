import React from "react";
import { Format, Form, ModalBuilder, useFormField } from "@redriver/cinnamon";
import classNames from "classnames";
import { FixedInput } from "components/forms";
import { getSheetDataState } from "features/Sheets";
import { connect } from "react-redux";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { isRealNumber } from "features/Sheets/ManageSheet";

const tierPercentageDecimalPlaces = 2;

const TieredTonerModal = ({ fields, impersonatedUser }) => {
  const hideFixedIndicator = !impersonatedUser.canEditAdminFields;
  const tieredTonerFields = "tieredTonerFields";
  const [tieredToner, setTieredToner] = useFormField(tieredTonerFields);
  const [tier1UpperBoundaryPercentage, setTier1UpperBoundaryPercentage] =
    useFormField("tier1UpperBoundaryPercentage");
  const [tier2UpperBoundaryPercentage, setTier2UpperBoundaryPercentage] =
    useFormField("tier2UpperBoundaryPercentage");
  const [tier2Adds, setTier2Adds] = useFormField("tier2Adds");
  const [tier3Adds, setTier3Adds] = useFormField("tier3Adds");

  return (
    <ModalBuilder
      onLoad={() => {
        const tieredTonerFields = {
          tier1UpperBoundaryPercentage: { ...tier1UpperBoundaryPercentage },
          tier2UpperBoundaryPercentage: { ...tier2UpperBoundaryPercentage },
          tier2Adds: { ...tier2Adds },
          tier3Adds: { ...tier3Adds },
        };
        setTieredToner(tieredTonerFields);
      }}
      onSubmitted={() => {
        setTier1UpperBoundaryPercentage(
          tieredToner.tier1UpperBoundaryPercentage,
        );
        setTier2UpperBoundaryPercentage(
          tieredToner.tier2UpperBoundaryPercentage,
        );
        setTier2Adds(tieredToner.tier2Adds);
        setTier3Adds(tieredToner.tier3Adds);
        setTieredToner(null);
      }}
      renderTrigger={(showModal) => {
        return (
          <div onClick={showModal} className="tier-toner-trigger">
            View
          </div>
        );
      }}
      renderModal={(modalProps) => (
        <TranslatedModal.Edit
          withForm
          {...modalProps}
          className="large modal tiered-toner-modal"
          header="Tiered Toner"
          cancelLabel="Cancel"
          submitLabel="Save"
        >
          <Form.Object field={tieredTonerFields}>
            <div className="container">
              <div className="headers">
                <div className="col col-0">Unit</div>
                <div className="col col-1">Unit Description</div>
                <div className="col col-2">
                  CPI (
                  {process.env.USE_MINOR_CURRENCY
                    ? process.env.MINOR_CURRENCY
                    : process.env.MAJOR_CURRENCY}
                  )
                </div>
                <div className="col col-3">Include Managed?</div>
                <div className="col col-4">Minimum Qtr. Vol.</div>
                <div className="col col-5">CPU</div>
                <div className="col col-6">Toner Exclusive</div>
                <div className="col col-7">Tiered Toner</div>
                <div className="col col-8">Tier 1</div>
                <div className="col col-9">Tier 2</div>
                <div className="col col-10">Tier 3</div>
              </div>
              <div className="content table-body">
                <div className="col col-0">{fields.displayCode}</div>
                <div className="col col-1">{fields.unit.unitName}</div>
                <div className="col col-2">
                  {!process.env.USE_MINOR_CURRENCY &&
                    process.env.MAJOR_CURRENCY}
                  {fields.costPerClick.value}
                  {process.env.USE_MINOR_CURRENCY && process.env.MINOR_CURRENCY}
                </div>
                <div className="col col-3">
                  {fields.includeManagedVol.value ? "Yes" : "No"}
                </div>
                <div className="col col-4">
                  {" "}
                  {process.env.USE_IMPERIAL_UNITS ? (
                    <Format.Number value={fields.minQuarterly.value} />
                  ) : (
                    fields.minQuarterly.value
                  )}
                </div>
                <div className="col col-5">
                  {!process.env.USE_MINOR_CURRENCY &&
                    process.env.MAJOR_CURRENCY}
                  {fields.costPerUnit}
                  {process.env.USE_MINOR_CURRENCY && process.env.MINOR_CURRENCY}
                </div>
                <div className="col col-6">
                  {fields.tonerExclusive.value ? "Yes" : "No"}
                </div>
                <div className="col col-7">
                  {fields.tieredToner.value ? "Yes" : "No"}
                </div>
                <div
                  className={classNames(
                    "col",
                    "tier",
                    "visible",
                    "col-8",
                    "left-border",
                  )}
                >
                  <div className="tier-labels col-8-a">0% to</div>
                  <div className="col-8-b">
                    <FixedInput
                      field="tier1UpperBoundaryPercentage"
                      decimalPlaces={tierPercentageDecimalPlaces}
                      percentage
                      className="tier percentage tier-1-upper-boundary-percentage"
                      minAllowValue={fields.vMinPercentageTier1 ?? 0}
                      maxAllowValue={fields.vMaxPercentageTier1}
                      formatNumber={true}
                      hideIndicator={hideFixedIndicator}
                      displayPriority={3}
                      defaultValue={fields.tier1DefaultPercentage}
                    />
                  </div>
                  <div className="tier-labels col-8-c">included </div>
                </div>

                <div
                  className={classNames(
                    "col",
                    "tier",
                    "visible",
                    "col-9",
                    "out-borders",
                  )}
                >
                  <div className="tier-labels col-9-a">
                    <div>
                      {">"}
                      <Format.Number
                        decimalPlaces={tierPercentageDecimalPlaces}
                        value={
                          tieredToner?.tier1UpperBoundaryPercentage
                            ? parseFloat(
                                tieredToner?.tier1UpperBoundaryPercentage
                                  ?.value,
                              )
                            : null
                        }
                      />
                      % to
                    </div>
                  </div>
                  <div className="col-9-b">
                    <FixedInput
                      field="tier2UpperBoundaryPercentage"
                      decimalPlaces={tierPercentageDecimalPlaces}
                      percentage
                      className="tier percentage tier-2-upper-boundary-percentage"
                      formatNumber
                      maxAllowValue={
                        fields.vMaxPercentageTier3 > fields.vMaxPercentageTier2
                          ? fields.vMaxPercentageTier2
                          : fields.vMaxPercentageTier3
                      }
                      minAllowValue={
                        +tieredToner?.tier1UpperBoundaryPercentage?.value ?? 0
                      }
                      hideIndicator={hideFixedIndicator}
                      displayPriority={3}
                      defaultValue={fields.tier2DefaultPercentage}
                    />
                    <Form.Trigger
                      action="change"
                      event="onChange"
                      field="tier1UpperBoundaryPercentage"
                      target="tier2UpperBoundaryPercentage"
                      value={({ fields }) => {
                        const tier1 =
                          fields.tier1UpperBoundaryPercentage?.value;
                        const tier2 =
                          fields.tier2UpperBoundaryPercentage?.value;

                        const newValue =
                          isRealNumber(tier1) && isRealNumber(tier2)
                            ? Math.max(tier1, tier2)
                            : tier2;

                        return {
                          ...fields.tier2UpperBoundaryPercentage,
                          value: newValue,
                        };
                      }}
                    />
                  </div>
                  <div className="tier-labels col-9-c">adds</div>
                  <div className="col-9-d">
                    <FixedInput
                      inline
                      field="tier2Adds"
                      formatNumber
                      currency
                      minorCurrency={process.env.USE_MINOR_CURRENCY}
                      decimalPlaces={process.env.USE_MINOR_CURRENCY ? 3 : 5}
                      className={`tier amount tier-2-adds ${
                        process.env.USE_MINOR_CURRENCY ? "" : "major-currency"
                      }`}
                      hideIndicator={hideFixedIndicator}
                      minAllowValue={fields.vMinValueTier2 ?? 0}
                      displayPriority={3}
                      defaultValue={fields.tier2DefaultValue}
                    />
                  </div>
                </div>

                <div className={classNames("col", "tier", "visible", "col-10")}>
                  <div className="tier-labels tier-3 col-10-a">
                    <div>
                      {">"}
                      <Format.Number
                        decimalPlaces={2}
                        value={
                          tieredToner?.tier2UpperBoundaryPercentage
                            ? parseFloat(
                                tieredToner?.tier2UpperBoundaryPercentage
                                  ?.value,
                              )
                            : null
                        }
                      />
                      % to {fields.vMaxPercentageTier3 ?? "100"}%
                    </div>
                  </div>
                  <div className="tier-labels col-10-b">
                    <div>adds</div>
                  </div>
                  <div className="col-10-c">
                    <FixedInput
                      field="tier3Adds"
                      formatNumber
                      currency
                      minorCurrency={process.env.USE_MINOR_CURRENCY}
                      decimalPlaces={process.env.USE_MINOR_CURRENCY ? 3 : 5}
                      className={`tier amount tier-3-adds ${
                        process.env.USE_MINOR_CURRENCY ? "" : "major-currency"
                      }`}
                      hideIndicator={hideFixedIndicator}
                      displayPriority={3}
                      minAllowValue={fields.vMinValueTier3 ?? 0}
                      defaultValue={fields.tier3DefaultValue}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form.Object>
        </TranslatedModal.Edit>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  const { impersonatedUser } = getSheetDataState(state);
  return {
    impersonatedUser,
  };
};

export default connect(mapStateToProps, null)(TieredTonerModal);
