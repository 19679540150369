import React, { useState } from "react";
import { ModalBuilder, Table, useAuthClaims } from "@redriver/cinnamon";
import { getUser, updateUser } from "./actions";
import UserForm from "./UserForm";
import { UserType } from "features/../../../shared/constants/enums";
import { TranslatedModal } from "features/../../../shared/components/modals";

const EditUser = ({
  as: As = Table.MenuItem,
  user,
  companyId,
  onSubmitted,  
  ...props
}) => {
  const [showAccessTree, setShowAccessTree] = useState(false);
  const { userArea } = useAuthClaims();    
  return (
    <ModalBuilder
      withForm
      initialData={{
        companyId: companyId,
        userType: companyId ? UserType.Dealer : null,
      }}
      loadAction={getUser}
      loadParams={user.id}
      submitAction={updateUser}
      submitParams={{ userId: user.id, showAccessTree: showAccessTree }}
      onSubmitted={onSubmitted}
      renderTrigger={showModal => <As {...props} onClick={showModal}></As>}
      renderModal={(modalProps, formProps, state, events) => (
        <TranslatedModal.Edit
          {...modalProps}
          className="edit-user-modal"
          header={`Update User: ${user.firstName} ${user.lastName}`}
        >
          <UserForm
            setShowAccessTree={setShowAccessTree}
            showAccessTree={showAccessTree}
            formProps={formProps}
            isEditMode={true}
            loading={state.loading}
            companyId={companyId}
            userArea={userArea}
          />
        </TranslatedModal.Edit>
      )}
    />
  );
};

export default EditUser;
