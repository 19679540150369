import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ModalBuilder, Form, notifySuccess } from "@redriver/cinnamon";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { getPartSettlement, requestPartSettlement } from ".";
import { UnitTable, ProductTable } from "./Components";
import { Grid, GridColumn, GridRow } from "semantic-ui-react";

import { Actions, Targets } from "constants/permissions";
import { PermissionCheck } from "features/../../../shared/components/auth";

const PartSettlement = ({ as: As, scheduleId, disabled }) => {
  const { t } = useTranslation("agreements", { keyPrefix: "ScheduleRow" });
  const { t: tps } = useTranslation("agreements", {
    keyPrefix: "ScheduleRow.PartSettlement",
  });

  const dispatch = useDispatch();
  return (
    <PermissionCheck action={Actions.SubmitRequest} target={Targets.Schedules}>
      <PermissionCheck action={Actions.View} target={Targets.Schedules}>
        <ModalBuilder
          renderTrigger={(showModal) => (
            <As onClick={showModal} disabled={disabled}>
              {t("Actions.Part_Settlement")}
            </As>
          )}
          withForm
          loadAction={getPartSettlement}
          loadParams={scheduleId}
          submitAction={requestPartSettlement}
          submitParams={scheduleId}
          onSubmitted={() => {
            dispatch(notifySuccess(t("Actions.Submitted_Successfully")));
          }}
          renderModal={(modalProps, formProps) => {
            return (
              <TranslatedModal.Edit
                className="large-modal upgrade-request-modal"
                header={tps("Header")}
                submitLabel={tps("Submit_Request")}
                submitDisabled={
                  !formProps.value.applicableFromPrevious &&
                  !formProps.value.applicableFromNext
                }
                {...modalProps}
              >
                <Form {...formProps}>
                  {() => (
                    <React.Fragment>
                      <Grid className="upgrade-types">
                        <GridRow>
                          <GridColumn width={1} className="right-align">
                            <Form.Checkbox
                              field="applicableFromPrevious"
                              checked={formProps.value.applicableFromPrevious}
                              fluid
                            />
                          </GridColumn>
                          <GridColumn width={15}>
                            <label>{tps("applicable_from_previous")}</label>
                          </GridColumn>
                        </GridRow>
                        <GridRow>
                          <GridColumn width={1} className="right-align">
                            <Form.Checkbox
                              field="applicableFromNext"
                              checked={formProps.value.applicableFromNext}
                              fluid
                            />
                          </GridColumn>
                          <GridColumn width={15}>
                            <label>{tps("applicable_from_next")}</label>
                          </GridColumn>
                        </GridRow>
                      </Grid>

                      <div className="unit-table">
                        <div className="vertical-scrollbar large">
                          <UnitTable formProps={formProps} />
                        </div>
                      </div>

                      <div className="vertical-scrollbar large">
                        <ProductTable
                          formProps={formProps}
                          showSettleProduct={true}
                          showRetainProduct={false}
                        />
                      </div>
                      <br />
                      <br />
                      <Form.TextArea
                        field="comments"
                        label={t("Additional_Comments")}
                        fluid
                        placeholder={t("Additional_Comments_Placeholder")}
                      />
                    </React.Fragment>
                  )}
                </Form>
              </TranslatedModal.Edit>
            );
          }}
        />
      </PermissionCheck>
    </PermissionCheck>
  );
};

export default PartSettlement;
