import React from "react";
import { FormBuilder, Form } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";

const FilterUsers = ({ value, onChange }) => {
  const { t } = useTranslation("sheetTranslation");

  return (
    <FormBuilder
      value={value}
      onChange={onChange}
      renderForm={formProps => (
        <Form {...formProps}>
          <Form.Input field="search" placeholder={t("Search")} icon="search" />
        </Form>
      )}
    />
  );
};

export default FilterUsers;
