import React from "react";
import { Image } from "semantic-ui-react";
import { Form } from "@redriver/cinnamon";
import { LargeFormatInkInclusiveDropdownLookup } from "features/../../../shared/features/Settings/LargeFormat/lookups";
import InkUsageCalcGrid from "./InkUsageCalcGrid";
import InkUsageDataDisplay from "./InkUsageDataDisplay";
import classNames from "classnames";

const LargeFormatModalInkInclusive = ({
  field,
  optionField,
  printerId,
  sheetId,
  editMode,
  lfEditModalLoaded,
  lfInkInclusive,
  costPerUnit,
  comparison,
  inkUsageCalcIncludingCapital,
  inkUsageCalcExcludingCapital,
  costPerClick,
  printerChanged,
  priceType,
  disabled,
}) => {
  return (
    <div
      className={classNames(
        "large-format-values",
        "ink-inclusive",
        comparison ? "cream" : "blue",
      )}
    >
      <div className="ink-inclusive-input-wrapper">
        <LargeFormatInkInclusiveDropdownLookup
          field={field}
          optionField={optionField}
          placeholder="Ink..."
          printerId={printerId}
          sheetId={sheetId}
          disabled={disabled}
        />

        {lfInkInclusive && lfInkInclusive.imageUrl && (
          <Image src={lfInkInclusive.imageUrl} className="ink-usage-image" />
        )}
      </div>
      {lfInkInclusive && (
        <React.Fragment>
          <InkUsageDataDisplay
            inkUsedML={lfInkInclusive.inkUsedML}
            costPerUnit={costPerUnit}
            costPerClick={costPerClick}
            editMode={editMode}
            printerChanged={printerChanged}
            priceType={priceType}
          />
          <InkUsageCalcGrid
            inkUsageCalcIncludingCapital={inkUsageCalcIncludingCapital}
            inkUsageCalcExcludingCapital={inkUsageCalcExcludingCapital}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default LargeFormatModalInkInclusive;
