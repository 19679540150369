import React from "react";
import { ModalBuilder, Table } from "@redriver/cinnamon";
import { deleteUser } from "./actions";
import { TranslatedModal } from "../../../components/modals";

const DeleteUser = ({
  as: As = Table.MenuItem,
  user,
  onSubmitted,
  ...props
}) => (
  <ModalBuilder
    submitAction={deleteUser}
    submitParams={user.id}
    onSubmitted={onSubmitted}
    renderTrigger={showModal => <As {...props} onClick={showModal}></As>}
    renderModal={(modalProps, formProps, state, events) => (
      <TranslatedModal.Delete
        {...modalProps}
        className="edit-user-modal"
        header="Delete User"
      >
        Are you sure you want to delete {user.firstName} {user.lastName}?
      </TranslatedModal.Delete>
    )}
  />
);

export default DeleteUser;
