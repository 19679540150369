import React from "react";
import { connect } from "react-redux";
import { Grid, Button } from "semantic-ui-react";
import { Form, FormBuilder, Loadable } from "@redriver/cinnamon";
import { IconButton } from "features/../../../shared/components/buttons";
import {
  paymentMethods,
  funderTypes,
  financeTypes,
} from "features/../../../shared/constants/enums";
import { getResponsiveState } from "features/../../../shared/modules/responsive/selectors";
import { getDefaults, setDefaults } from "./actions";
import {
  PaymentMethodsRadioGroup,
  FunderTypesRadioGroup,
  FinanceTypesRadioGroup,
} from "features/Settings";
import Pen from "features/../../../shared/assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";
import { useTranslation } from "react-i18next";

const getLabel = (name) => {
  return (
    <div className="default-grid-field-label-container">
      <div className="defaults-grid-title">
        <p>{name}</p>
        <span className="required-star">*</span>
      </div>
    </div>
  );
};

const DefaultsGrid = ({ settingsId, isMobile }) => {
  const { t } = useTranslation();

  return (
    <div className="defaults-grid">
      <FormBuilder
        initialReadOnly
        loadAction={getDefaults}
        loadParams={{ settingsId }}
        submitAction={setDefaults}
        submitParams={settingsId}
        renderLoading={false}
        renderSubmitting={false}
        renderForm={(formProps, state, events) => {
          const readOnlyPaymentMethod = paymentMethods.find(
            (x) => x.value == formProps.value.paymentMethod,
          );
          const readOnlyFunderType = funderTypes.find(
            (x) => x.value == formProps.value.funderType,
          );
          const readOnlyFinanceType = financeTypes.find(
            (x) => x.value == formProps.value.financeType,
          );

          return (
            <React.Fragment>
              <div className="defaults-page-title flex">
                <div className="flex title-bar">
                  <h4 className="title">
                    {t("supplierOffices:defaultsGrid:title")}
                  </h4>
                </div>
                <PermissionCheck
                  target={Targets.Defaults}
                  action={Actions.Edit}
                >
                  <div>
                    <IconButton
                      primary
                      icon={state.readOnly ? Pen : Save}
                      content={
                        state.readOnly
                          ? t("shared:buttons:edit")
                          : t("shared:buttons:save")
                      }
                      floated="right"
                      onClick={() =>
                        state.readOnly
                          ? events.onToggleReadOnly()
                          : events.onSubmit({ reset: ["readOnly"] })
                      }
                      className={
                        !state.readOnly ? "positive-title-action" : null
                      }
                      loading={state.loading || state.submitting}
                      disabled={state.loading || state.submitting}
                    />
                    {!state.readOnly && (
                      <Button
                        primary
                        className="cancel-title-action"
                        floated="right"
                        content={t("shared:buttons:cancel")}
                        onClick={events.onReset}
                      />
                    )}
                  </div>
                </PermissionCheck>
              </div>

              <div className="content-container principal-list">
                <Loadable loading={state.loading || state.submitting}>
                  <Form {...formProps} className="highlighted-inputs">
                    <Grid
                      stackable
                      columns={!isMobile ? 4 : 3}
                      className="defaults-grid"
                    >
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Numeric
                            field="costPerDevice"
                            label={getLabel(
                              t("supplierOffices:defaultsGrid:costPerDevice", {
                                currencySymbol: process.env.MAJOR_CURRENCY,
                              }),
                            )}
                            placeholder={t(
                              "supplierOffices:defaultsGrid:costPerDevicePlaceholder",
                            )}
                            required
                            width={isMobile ? "75%" : 11}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Numeric
                            field="a0SquareMetersLfp"
                            label={getLabel(
                              t("supplierOffices:defaultsGrid:aSquareMetres"),
                            )}
                            placeholder={t(
                              "supplierOffices:defaultsGrid:aSquareMetresPlaceholder",
                            )}
                            required
                            width={isMobile ? "75%" : 11}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Numeric
                            field="maximumVolumeSelectionValue"
                            label={getLabel(
                              t(
                                "supplierOffices:defaultsGrid:maxVolumeSelectionValue",
                              ),
                            )}
                            placeholder={t(
                              "supplierOffices:defaultsGrid:maxVolumeSelectionPlaceholder",
                            )}
                            required
                            width={isMobile ? "75%" : 11}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Numeric
                            field="minimumVolumeSelectionValue"
                            label={getLabel(
                              t(
                                "supplierOffices:defaultsGrid:minVolumeSelectionValue",
                              ),
                            )}
                            placeholder={t(
                              "supplierOffices:defaultsGrid:minVolumeSelectionPlaceholder",
                            )}
                            required
                            width={isMobile ? "75%" : 11}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Numeric
                            field="defaultVolumeSelectionValue"
                            label={getLabel(
                              t(
                                "supplierOffices:defaultsGrid:defaultVolumeSelectionValue",
                              ),
                            )}
                            placeholder={t(
                              "supplierOffices:defaultsGrid:defaultVolumeSelectionPlaceholder",
                            )}
                            required
                            width={isMobile ? "75%" : 11}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Numeric
                            field="defaultAndMinimumTotalDevicesOnScheduleValue"
                            label={getLabel(
                              t(
                                "supplierOffices:defaultsGrid:defaultNoOfDevicesOnScheduleValue",
                              ),
                            )}
                            placeholder={t(
                              "supplierOffices:defaultsGrid:defaultNoOfDevicesOnSchedulePlaceholder",
                            )}
                            required
                            decimalPlaces={0}
                            width={isMobile ? "75%" : 11}
                          />
                        </Grid.Column>
                        <Grid.Column></Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={state.readOnly ? 4 : 3}>
                        <Grid.Column width={8}>
                          {state.readOnly ? (
                            <div className="field">
                              <label>
                                {t(
                                  "supplierOffices:defaultsGrid:defaultPaymentMethod",
                                )}
                              </label>
                              <div className="read-only">
                                <p>
                                  {readOnlyPaymentMethod
                                    ? readOnlyPaymentMethod.text
                                    : ""}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <PaymentMethodsRadioGroup
                              field="paymentMethod"
                              label={t(
                                "supplierOffices:defaultsGrid:defaultPaymentMethod",
                              )}
                            />
                          )}
                        </Grid.Column>
                        <Grid.Column width={4}>
                          {state.readOnly ? (
                            <div className="field">
                              <label>
                                {t("supplierOffices:defaultsGrid:paymentBy")}
                              </label>
                              <div className="read-only">
                                <p>
                                  {readOnlyFunderType
                                    ? readOnlyFunderType.text
                                    : ""}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <FunderTypesRadioGroup
                              field="funderType"
                              label={t(
                                "supplierOffices:defaultsGrid:paymentBy",
                              )}
                            />
                          )}
                        </Grid.Column>
                        <Grid.Column width={4}>
                          {state.readOnly ? (
                            <div className="field">
                              <label>Rate Type</label>
                              <div className="read-only">
                                <p>
                                  {readOnlyFinanceType
                                    ? readOnlyFinanceType.text
                                    : ""}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <FinanceTypesRadioGroup
                              field="financeType"
                              label={t("supplierOffices:defaultsGrid:rateType")}
                            />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={16} className="defaults-clause">
                          <Form.Input
                            field="upgradeClause"
                            label={t(
                              "supplierOffices:defaultsGrid:upgradeClause",
                            )}
                            fluid
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16} className="defaults-clause">
                          <Form.Input
                            fluid
                            field="applicableFromPrefix"
                            label={t(
                              "supplierOffices:defaultsGrid:applicableFromPrefix",
                            )}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </Loadable>
              </div>
            </React.Fragment>
          );
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { screenSize } = getResponsiveState(state);
  return {
    isMobile: screenSize === "small",
  };
};

export default connect(mapStateToProps)(DefaultsGrid);
