import React from "react";
import { useTranslation } from "react-i18next";
import { deleteSheet } from "../actions";
import { ModalBuilder, Table } from "@redriver/cinnamon";
import { withRouter } from "react-router-dom";
import {
  withCurrentUser,
  PermissionCheck,
} from "features/../../../shared/components/auth";

import { Targets, Actions } from "constants/permissions";
import { TranslatedModal } from "features/../../../shared/components/modals";

const DeleteSheet = ({ sheetId, sheetName, onSubmitted }) => {
  const { t } = useTranslation("agreements");

  return (
    <PermissionCheck target={Targets.Sheet} action={Actions.Delete}>
      <ModalBuilder
        withForm
        submitAction={deleteSheet}
        submitParams={{ sheetId }}
        onSubmitted={onSubmitted}
        renderTrigger={(trigger) => (
          <Table.MenuItem onClick={trigger}>
            {t("SheetActions.DeleteSheet.TriggerText")}
          </Table.MenuItem>
        )}
        renderModal={(modalProps) => (
          <TranslatedModal
            {...modalProps}
            header={t("SheetActions.DeleteSheet.HeaderText")}
            size="small"
          >
            {t("SheetActions.DeleteSheet.ConfirmMessage", { sheetName })}
          </TranslatedModal>
        )}
      />
    </PermissionCheck>
  );
};

export default withCurrentUser(withRouter(DeleteSheet));
