import React from "react";
import {
  DateTimeFormatter,
  yesNoFormatter,
} from "components/displays/DisplayHelper";
import { Table } from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import DataUploadActions from "./DataUploadActions";

const DataUploadsList = ({ tableProps, state, events, dealerOfficeId }) => {
  return (
    <div>
      <div className="spaced">
        <Table {...tableProps}>
          <Table.Column field="type" title="Type" />
          <Table.Column
            field="createdUtc"
            title="Uploaded Time"
            render={(item) => DateTimeFormatter(item.createdUtc)}
          />
          <Table.Column
            field="requiresApproval"
            title="Requires IPS Approval"
            render={(item) => yesNoFormatter(item.requiresApproval)}
          />
          <Table.Column
            field="isApproved"
            title="Approved"
            render={(item) => yesNoFormatter(item.isApproved)}
          />
          <Table.Column
            field="processedUtc"
            title="Processed Time"
            render={(item) =>
              item.processedUtc ? DateTimeFormatter(item.processedUtc) : "-"
            }
          />
          <Table.Column
            field="status"
            title="Status"
            render={(item) =>
              item.processedUtc ? (
                <span className="complete">Complete</span>
              ) : item.requiresApproval && !item.isApproved ? (
                <span className="waiting">Awaiting approval</span>
              ) : item.isFailed ? (
                <span className="failed">Failed to process</span>
              ) : (
                <span className="ready">Ready to process</span>
              )
            }
          />
          <Table.Column
            hoverable
            align="right"
            render={(item) => (
              <DataUploadActions
                item={item}
                onSubmitted={events.onRefresh}
                dealerOfficeId={dealerOfficeId}
              />
            )}
          />
        </Table>
        <div className="padded">
          <TablePagination
            pageSize={state.pagination.pageSize}
            pageNumber={state.pagination.pageNumber}
            onChange={events.onChangePagination}
            totalItems={state.total}
          />
        </div>
      </div>
    </div>
  );
};

export default DataUploadsList;
