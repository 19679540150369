import React from "react";
import { ModalBuilder, Form, TriggerEvent } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { getSheetDataState } from "../selectors";
import { createOrSetClientAddress } from "./actions";
import { ClientAddressDropdownLookup } from "features/Clients";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";

const SelectLocationModal = ({ setClientAddress, setAllClientAddresses }) => {
  const { t } = useTranslation("productSchedule");
  const { clientId } = useSelector(getSheetDataState);
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state);

  const AddressTypeOptions = [
    { value: AddressType.Existing, text: t("Existing") },
    { value: AddressType.New, text: t("New") },
  ];

  return (
    <ModalBuilder
      withForm
      onSubmit={async (formData, submitParams) => {
        await createOrSetClientAddress(
          formData,
          submitParams,
          setClientAddress,
          setAllClientAddresses
        );
      }}
      submitParams={{ clientId, dispatch, reduxState }}
      initialData={{ type: AddressType.Existing }}
      renderTrigger={(showModal) => <Button icon="plus" onClick={showModal} />}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Edit
          {...modalProps}
          header={t("Set_Location")}
          className="select-location-modal"
        >
          <Form {...formProps}>
            <div className="location-modal-menu">
              <Form.RadioGroup
                field="type"
                label={t("Select_a_Client_Address")}
                options={AddressTypeOptions}
              />
              <Form.Checkbox
                field="allocateToAll"
                label={t("Allocate_to_All")}
                className="allocate-to-all"
              />
            </div>
            <Form.If
              condition={({ fields }) => fields.type == AddressType.Existing}
            >
              <h4>{t("Select_an_Existing_Address")}</h4>
              <ClientAddressDropdownLookup
                field="clientAddressId"
                clientId={clientId}
                fluid
                clearable
              />
            </Form.If>
            <Form.If condition={({ fields }) => fields.type == AddressType.New}>
              <h4>{t("New_Address_Lookup")}</h4>

              <Form.Input
                field="department"
                label={t("Department")}
                inline
                fluid
              />

              <Form.Object field="address">
                <Form.Input
                  field="line1"
                  label={t("Address_Line_1")}
                  inline
                  fluid
                />
                <Form.Input
                  field="line2"
                  label={t("Address_Line_2")}
                  inline
                  fluid
                />
                <Form.Input
                  field="line3"
                  label={t("Address_Line_3")}
                  inline
                  fluid
                />
                <Form.Input field="town" label={t("Town/City")} inline fluid />
                <Form.Input field="county" label={t("County")} inline fluid />
                <Form.Input
                  field="postcode"
                  label={t("Post_Code")}
                  required
                  inline
                  fluid
                />
                <Form.Trigger
                  field="postcode"
                  target="postcode"
                  event={TriggerEvent.OnChange}
                  action="change"
                  match={({ fields }) =>
                    ((fields || {}).postcode || "").length > 10
                  }
                  value={({ fields }) => (fields.postcode || "").slice(0, 10)}
                />
                <Form.Input field="country" label={t("Country")} inline fluid />
              </Form.Object>

              <Form.Input field="phone" label={t("Phone")} inline fluid />
              <Form.Email field="email" label={t("Email")} inline fluid />
            </Form.If>
          </Form>
        </TranslatedModal.Edit>
      )}
    />
  );
};

export default SelectLocationModal;

export const AddressType = {
  Existing: "Existing",
  New: "New",
};
