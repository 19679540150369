export default {
  User: "u",
  RoleTemplate: "ro",
  UserRole: "uro",
  Sheet: "sh",
  Dealer: "d",
  DealerOffice: "do",
  Client: "c",
  Service: "s",
  Defaults: "df",
  SheetType: "st",
  SheetAdmin: "sa",
  SheetFinance: "sf",
  ProductSchedule: "ps",
  PaperPrice: "pp",
  Territory: "t",
  LargeFormat: "lf",
  Agreements: "a",
  Schedules: "sc",
  YieldBands: "yb",
  Rates: "ra",
  UploadSettings: "ups",
  UploadSettingsTemplates: "ust",
};
