import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { Agreements, NavigationBar } from "features/Agreements";

const AgreementsListPage = ({ match }) => (
  <div className="agreements-page-container">
    <NavigationBar />
    <PageContent className="agreements">
      <Agreements sheetId={match.params.id} />
    </PageContent>
  </div>
);

export default AgreementsListPage;
