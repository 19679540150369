import React from "react";
import { useSelector, connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";
import { FixedInputResponsiveLabel, FixedInput } from "components/forms";
import {
  getCalculationData,
  getSheetState,
  getSheetDataState,
} from "features/Sheets";
import { Format, Form, getAuthClaim } from "@redriver/cinnamon";
import { InlineLoader } from "features/../../../shared/components/forms/InlineLoader";
import { FunderType } from "features/../../../shared/constants/enums";
import { compose } from "redux";

export const mapFunderOverridesFieldName = (funderType) => {
  switch (funderType) {
    case FunderType.Funder:
      return "funderOverrides";
    case FunderType.Dealer:
      return "dealerFundedOverrides";
    case FunderType.CoFunded:
      return "coFundedOverrides";
    default:
      return null;
  }
};

const OverrideSection = ({ title, className, funderType }) => {
  const { t } = useTranslation("ipsRates");

  const field = mapFunderOverridesFieldName(funderType);
  const { calculating } = useSelector(getSheetState);
  const itemCost = useSelector(
    getCalculationData,
  )?.breakdown.capitalCalcCosts.itemCosts.find(
    (x) => x.funderType === funderType,
  );

  return (
    <Form.Object field={field} propagateUpdates="always">
      <Grid.Column className="funder-specific-overrides">
        <Grid.Row>
          <Grid.Column>
            <div className={"override-details " + className}>
              <h5>{title}</h5>
              <FixedInput
                label={
                  <FixedInputResponsiveLabel
                    label={t("Yield_Override")}
                    className="override-field-details"
                  />
                }
                field="yieldOverride"
                fluid
                inline
                placeholder={t("Override...")}
                className="override-input"
                decimalPlaces={3}
                minAllowValue={0}
              />
              <FixedInput
                label={
                  <FixedInputResponsiveLabel
                    label={t("FV_Override_%")}
                    className="override-field-details"
                  />
                }
                field="fvOverride"
                fluid
                inline
                placeholder={t("Override...")}
                className="override-input"
                decimalPlaces={3}
                minAllowValue={0}
              />
              <div className="override-field-details output-group">
                <label>{t("IP_Net_Rate")}</label>
                {calculating ? (
                  <InlineLoader isActive={true} size="tiny" />
                ) : (
                  <Format.Number
                    value={(itemCost && itemCost.ipSyndicateNetRate) || 0}
                    decimalPlaces={{ min: 0, max: 3 }}
                  />
                )}
              </div>
              <div className="override-field-details output-group">
                <label>{t("Gross_Sale_Override")}</label>
                {calculating ? (
                  <InlineLoader isActive={true} size="tiny" />
                ) : (
                  <Format.Number
                    value={(itemCost && itemCost.rate) || 0}
                    decimalPlaces={{ min: 0, max: 3 }}
                  />
                )}
              </div>
              <FixedInput
                label={
                  <FixedInputResponsiveLabel
                    label={t("IP_Liquidity_Charge_Override")}
                    className="override-field-details"
                  />
                }
                field="ipLiquidityChargeOverride"
                fluid
                inline
                placeholder={t("Override...")}
                className="override-input"
                decimalPlaces={3}
                minAllowValue={0}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid.Column>
    </Form.Object>
  );
};

const mapStateToProps = (state) => {
  const { ipsRates } = getSheetDataState(state);

  return {
    ipsRates,
    userArea: getAuthClaim(state, "userArea"),
  };
};

export default compose(connect(mapStateToProps))(OverrideSection);
