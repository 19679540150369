import React from "react";
import { ListUsers } from "features/Admin";
import { PageContent, PageHeader } from "@redriver/cinnamon";

const UserListPage = () => (
  <PageContent>
    <PageHeader>Users</PageHeader>
    <ListUsers />
  </PageContent>
);

export default UserListPage;
