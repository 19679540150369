import React, {useEffect, useState} from 'react';
import { Icon } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";

const OnlineCheck = () => {

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        //hide if online on load
        if (!navigator.onLine) {
            setVisible(true);
          }

        //add event listeners
        window.addEventListener('offline', function(e) {setVisible(true)});
        window.addEventListener('online', function(e) {setVisible(false);
    });
    }, [])

    return(
        <ModalBuilder
            visible={visible}
            renderModal={(modalProps, formProps) => (
            <Modal
            className="no-internet-modal"
            {...modalProps}
            Icon="wifi"
            header="No Internet"
            cancelDisabled={true}
            submitDisabled={true}
            >
            <div>
                Try:
                <ul>
                    <li>Checking the network cables, modem and router</li>
                    <li>Reconnecting to Wi-Fi</li>
                    <li>Running Windows Network Diagnostics</li>
                </ul>
            </div>
            <Icon name='wifi'  size='huge' color='red' />
        </Modal>
      )}
    />
   
    );

}

export default OnlineCheck;