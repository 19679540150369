export const safelySetFixedFieldMinimum = (fixedField, minimum) => {
  if (isNaN(parseFloat(minimum))) return;
  if (
    isNaN(parseFloat(fixedField.value)) &&
    fixedField.setValue == null &&
    (isNaN(parseFloat(fixedField.minimumValue)) ||
      minimum >= fixedField.minimumValue) &&
    (isNaN(parseFloat(fixedField.maximumValue)) ||
      minimum <= fixedField.maximumValue)
  ) {
    fixedField.value = minimum;
  }
};
