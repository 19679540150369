import { apiPut } from "@redriver/cinnamon";

const ActionTypes = {
  VoidEnvelope: "SHEETS/VOID_ENVELOPE",
};

export const voidEnvelope =
  ({ sheetId }) =>
  async (dispatch) => {
    return await dispatch(
      apiPut(
        ActionTypes.VoidEnvelope,
        `sheets/${sheetId}/submit/void-envelope`,
      ),
    );
  };
