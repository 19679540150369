import React from "react";
import { DataGrid } from "./Grids";
import { useTranslation } from "react-i18next";
import { Format } from "@redriver/cinnamon";
import { FmvTypeDescription } from "features/../../../shared/constants/enums";
import classNames from "classnames";
import { numericFormatter } from "components/displays/DisplayHelper";

const Currency = (value) =>
  value == null ? (
    ""
  ) : (
    <Format.Number
      value={value}
      format={process.env.CURRENCY_CODE}
      decimalPlaces={2}
    />
  );
const Percentage = (value, decimalPlaces = 2) =>
  value == null ? (
    ""
  ) : (
    <Format.Number
      value={value}
      format="percentage"
      decimalPlaces={decimalPlaces}
    />
  );

const fmvFieldKeys = [
  "fmvOption",
  "fmvPercentage",
  "fmvPercentageApplied",
  "fmvPercentageUsed",
  "fmvValue",
];

const rateFieldKeys = [
  "lookupYield",
  "ipsNetRate",
  "ipsLiquidityCharge",
  "ipsProtectionPercentage",
  "ipsProtectionAmount",
  "supplierNetRate",
];

const lcFieldKeys = [
  "supplierSalesPercentage",
  "supplierIncentivePercentage",
  "supplierAdminPercentage",
  "supplierOtherPercentage",
  "supplierLiquidityChargeTotal",
];

const subHeaderKeys = ["used", null, "override", null, "fromAllTables"];
const subHeaderCfaKeys = ["used", null, "fromAllTables"];

const CalcBreakdown = ({ data, funderType }) => {
  const { t } = useTranslation("capitalCalc", {
    keyPrefix: "infoModal.funderTypes.breakdown.fields",
  });
  const { t: th } = useTranslation("capitalCalc", {
    keyPrefix: "infoModal.funderTypes.breakdown.headers",
  });

  const subHeaders = subHeaderKeys.map((key) => [key != null ? th(key) : ""]);
  const subHeadersCfa = subHeaderCfaKeys.map((key) => [
    key != null ? th(key) : "",
  ]);
  const subHeaderClass = `highlighted-first-row ${funderType.toLowerCase()}`;

  return (
    <div className="breakdown-wrapper">
      <div className="breakdown">
        <FmvRow
          data={data.fmvBreakdown}
          t={t}
          th={th}
          subHeaders={subHeaders}
          subHeadersCfa={subHeadersCfa}
          subHeaderClass={subHeaderClass}
          funderType={funderType}
        />
        <RatesRow data={data.ratesBreakdown} t={t} funderType={funderType} />
        <LiquidityChargeRow
          data={data.liquidityChargesBreakdown}
          t={t}
          funderType={funderType}
        />
        <GrossSalesRateRow
          data={data.grossSalesRateBreakdown}
          t={t}
          subHeaderClass={subHeaderClass}
        />
      </div>
    </div>
  );
};

const FmvRow = ({
  data,
  t,
  th,
  subHeaders,
  subHeadersCfa,
  subHeaderClass,
  funderType,
}) => {
  return (
    <React.Fragment>
      <DataGrid
        className="fmv-fields right-align"
        columnCount={1}
        dataRows={fmvFieldKeys.map((key) => {
          if (key) return [t(key)];
          return { className: "hidden", values: [""] };
        })}
      />
      <DataGrid
        className={classNames(
          "used-override-table",
          data.funderType?.toLowerCase(),
        )}
        minimumRows={6}
        columnCount={5}
        headerText={th("yieldsTitle")}
        isFirstRowHeader
        dataRows={[
          { className: subHeaderClass, values: subHeaders },
          [
            data.overrideFmvOption
              ? FmvTypeDescription[data.overrideFmvOption]
              : null,
            null,
            data.usedFmvOption ? FmvTypeDescription[data.usedFmvOption] : null,
            null,
            data.lookupFmvOption
              ? FmvTypeDescription[data.lookupFmvOption]
              : null,
          ],
          [
            Percentage(data.fmvPercentage?.usedCfa),
            null,
            Percentage(data.fmvPercentage?.overrideCfa),
            null,
            Percentage(data.fmvPercentage?.lookupCfa),
          ],
          [
            Percentage(data.fmvPercentageApplied?.usedCfa),
            null,
            null,
            null,
            Percentage(data.fmvPercentageApplied?.lookupCfa),
          ],
          [
            Percentage(data.fmvUsed?.usedCfa, 3),
            null,
            null,
            null,
            Percentage(data.fmvUsed?.lookupCfa, 3),
          ],
          [
            Currency(data.yieldAndRatesFmvValue?.usedCfa),
            null,
            null,
            null,
            Currency(data.yieldAndRatesFmvValue?.lookupCfa),
          ],
        ]}
      />
      <DataGrid
        className={classNames("used-override-table", funderType?.toLowerCase())}
        minimumRows={6}
        columnCount={3}
        headerText={th("cfaTitle")}
        isFirstRowHeader
        dataRows={[
          { className: subHeaderClass, values: subHeadersCfa },
          [null, null, null],
          [null, null, null],
          [null, null, null],
          [null, null, null],
          [
            Currency(data.fmvValue?.usedCfa),
            null,
            Currency(data.fmvValue?.lookupCfa),
          ],
        ]}
      />
    </React.Fragment>
  );
};

const RatesRow = ({ data, t, funderType }) => {
  return (
    <React.Fragment>
      <DataGrid
        columnCount={1}
        className="right-align rate-table"
        dataRows={rateFieldKeys.map((key) => [t(key)])}
      />
      <DataGrid
        className={classNames(
          "used-override-table cell-top-border rate-table",
          funderType?.toLowerCase(),
        )}
        minimumRows={6}
        columnCount={5}
        dataRows={[
          [
            Percentage(data.yieldRate?.usedCfa),
            null,
            Percentage(data.yieldRate?.overrideCfa),
            null,
            Percentage(data.yieldRate?.lookupCfa),
          ],
          [
            data.ipsNetRate?.usedCfa,
            null,
            data.ipsNetRate?.overrideCfa,
            null,
            data.ipsNetRate?.lookupCfa,
          ],
          [
            Percentage(data.ipsLiquidityCharge?.usedCfa),
            null,
            Percentage(data.ipsLiquidityCharge?.overrideCfa),
            null,
            Percentage(data.ipsLiquidityCharge?.lookupCfa),
          ],
          [
            Percentage(data.ipsProtectionPercentage?.usedCfa),
            null,
            null,
            null,
            Percentage(data.ipsProtectionPercentage?.lookupCfa),
          ],
          [
            <Format.Number
              key={1}
              value={data.ipsProtectionAmount?.usedCfa}
              decimalPlaces={3}
            />,
            null,
            null,
            null,
            <Format.Number
              key={2}
              value={data.ipsProtectionAmount?.lookupCfa}
              decimalPlaces={3}
            />,
          ],
          [
            data.dealerNetRate?.usedCfa,
            null,
            null,
            null,
            data.dealerNetRate?.lookupCfa,
          ],
        ]}
      />
      <DataGrid
        className={classNames(
          "used-override-table cell-top-border rate-table",
          funderType?.toLowerCase(),
        )}
        minimumRows={6}
        columnCount={3}
        dataRows={[
          [null, null, null],
          [
            Currency(data.ipsNetRateAmount?.usedCfa),
            null,
            Currency(data.ipsNetRateAmount?.lookupCfa),
          ],
          [
            Currency(data.ipsLiquidityChargeAmount?.usedCfa),
            null,
            Currency(data.ipsLiquidityChargeAmount?.lookupCfa),
          ],
          [null, null, null],
          [
            Currency(data.ipsProtectionBreakdownAmount?.usedCfa),
            null,
            Currency(data.ipsProtectionBreakdownAmount?.lookupCfa),
          ],
          [
            Currency(data.dealerNetRateAmount?.usedCfa),
            null,
            Currency(data.dealerNetRateAmount?.lookupCfa),
          ],
        ]}
      />
    </React.Fragment>
  );
};

const LiquidityChargeRow = ({ data, t, funderType }) => {
  return (
    <React.Fragment>
      <DataGrid
        className="liquidity-charges right-align"
        columnCount={1}
        dataRows={lcFieldKeys.map((key) => [t(key)])}
      />

      <DataGrid
        className={classNames(
          "used-override-table liquidity-charges cell-top-border",
          funderType.toLowerCase(),
        )}
        minimumRows={5}
        columnCount={5}
        dataRows={[
          [
            Percentage(data.salesPercentage?.usedCfa),
            null,
            Percentage(data.salesPercentage?.overrideCfa),
            null,
            Percentage(data.salesPercentage?.lookupCfa),
          ],
          [
            Percentage(data.incentivePercentage?.usedCfa),
            null,
            Percentage(data.incentivePercentage?.overrideCfa),
            null,
            Percentage(data.incentivePercentage?.lookupCfa),
          ],
          [
            Percentage(data.adminPercentage?.usedCfa),
            null,
            Percentage(data.adminPercentage?.overrideCfa),
            null,
            Percentage(data.adminPercentage?.lookupCfa),
          ],
          [
            Percentage(data.otherPercentage?.usedCfa),
            null,
            Percentage(data.otherPercentage?.overrideCfa),
            null,
            Percentage(data.otherPercentage?.lookupCfa),
          ],
          {
            className: "total",
            values: [
              Percentage(data.dealerLiquidityChargePercentage?.usedCfa),
              null,
              null,
              null,
              Percentage(data.dealerLiquidityChargePercentage?.lookupCfa),
            ],
          },
        ]}
      />
      <DataGrid
        className={classNames(
          "used-override-table liquidity-charges cell-top-border",
          data.funderType?.toLowerCase(),
        )}
        minimumRows={5}
        columnCount={3}
        dataRows={[
          [
            Currency(data.salesAmount?.usedCfa),
            null,
            Currency(data.salesAmount?.lookupCfa),
          ],
          [
            Currency(data.incentiveAmount?.usedCfa),
            null,
            Currency(data.incentiveAmount?.lookupCfa),
          ],
          [
            Currency(data.adminAmount?.usedCfa),
            null,
            Currency(data.adminAmount?.lookupCfa),
          ],
          [
            Currency(data.otherAmount?.usedCfa),
            null,
            Currency(data.otherAmount?.lookupCfa),
          ],
          {
            className: "total",
            values: [
              Currency(data.dealerLiquidityChargeAmount?.usedCfa),
              null,
              Currency(data.dealerLiquidityChargeAmount?.lookupCfa),
            ],
          },
        ]}
      />
    </React.Fragment>
  );
};

const GrossSalesRateRow = ({ data, t, subHeaderClass }) => {
  return (
    <React.Fragment>
      <DataGrid
        className="highlighted-last-row right-align"
        minimumRows={1}
        columnCount={1}
        dataRows={[{ className: subHeaderClass, values: [t("grossSaleRate")] }]}
      />
      <DataGrid
        className="highlighted-last-row"
        minimumRows={1}
        columnCount={5}
        dataRows={[
          {
            className: subHeaderClass,
            values: [
              numericFormatter(data.grossSalesRate?.usedCfa, 0, 3),
              null,
              null,
              null,
              numericFormatter(data.grossSalesRate?.lookupCfa, 0, 3),
            ],
          },
        ]}
      />
      <DataGrid
        className="highlighted-last-row"
        minimumRows={1}
        columnCount={3}
        dataRows={[
          {
            className: subHeaderClass,
            values: [
              Currency(data.grossSalesRateValue?.usedCfa),
              null,
              Currency(data.grossSalesRateValue?.lookupCfa),
            ],
          },
        ]}
      />
    </React.Fragment>
  );
};

export default CalcBreakdown;
