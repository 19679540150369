import { apiGet, apiPut, apiPost, apiDelete } from "@redriver/cinnamon";

const NAMESPACE = "SETTINGS/SERVICES";

export const ActionTypes = {
  GetServices: `${NAMESPACE}/GET_SERVICES`,
  AddService: `${NAMESPACE}/ADD_SERVICE`,
  UpdateService: `${NAMESPACE}/UPDATE_SERVICE`,
  RemoveServiceById: `${NAMESPACE}/REMOVE_SERVICE_BY_ID`
};

export const getServices = ({ filters, pagination }, { dealerOfficeId }) => {
  const { pageSize, pageNumber } = pagination;
  const { search, sortName, sortDirection } = filters;

  return apiGet(ActionTypes.GetServices, `services`, {
    dealerOfficeId,
    pageSize,
    pageNumber,
    search,
    includeMandatory: true,
    sortName,
    sortDirection,
  });
};

export const addService = (service, { dealerOfficeId }) =>
  apiPost(ActionTypes.AddService, `services`, { ...service, dealerOfficeId });

export const removeService = id =>
  apiDelete(ActionTypes.RemoveServiceById, `services/${id}`);

export const editService = (service, id) =>
  apiPut(ActionTypes.UpdateService, `services/${id}`, service);
