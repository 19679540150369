import React, { useState } from "react";
import { ModalBuilder, Form } from "@redriver/cinnamon";
import { Button, Grid } from "semantic-ui-react";
import { saveMeterReadingData, getMeterReadingData } from "./actions";
import MeterReadingItemGroup from "./MeterReadingItemGroup";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";

const transformMeterData = (data) => {
  // Add item number to each item to add alternating background despite the nested groupings

  let idx = 0;
  (data.groups || []).forEach((group) => {
    group.applicableLFItems
      .concat(group.applicableSysSchItems)
      .forEach((item) => {
        item.itemIndex = idx;
        idx++;
      });
    idx++; // Add 1 to end of the group because of the blank row after each group
  });
  return data;
};

function getSizeOfPreviousList(parentFields, arrayIndex) {
  let relevant = parentFields.groups.slice(0, arrayIndex);
  let position = 0;
  relevant.forEach(
    (x) =>
      (position += x.applicableLFItems.length + x.applicableSysSchItems.length)
  );
  position += arrayIndex;
  return position;
}

const TriggerButton = ({ onClick }) => {
  const { t } = useTranslation("productSchedule");
  return (
    <Button primary className="ps-save-button">
      <div onClick={onClick}>{t("Enter_Meter_Information")}</div>
    </Button>
  );
};

const MeterReadingModal = ({
  sheetId,
  onSubmitted,
  onSubmit,
  saveRequired,
  isProductScheduleValid,
  disabled,
}) => {
  const { t } = useTranslation("productSchedule");
  const [psSaving, setPsSaving] = useState(false);
  return (
    <ModalBuilder
      withForm
      loadAction={getMeterReadingData}
      loadParams={sheetId}
      loadTransform={transformMeterData}
      submitAction={saveMeterReadingData}
      submitParams={sheetId}
      onSubmitted={onSubmitted}
      renderTrigger={(showModal) =>
        saveRequired ? (
          <ModalBuilder
            submitting={psSaving}
            renderModal={(modalProps, formProps, state, events) =>
              isProductScheduleValid ? (
                <TranslatedModal.Confirmation
                  {...modalProps}
                  onSubmit={async () => {
                    setPsSaving(true);
                    const { success } = await onSubmit();
                    setPsSaving(false);
                    if (!success) {
                      events.onCancel();
                      return;
                    }
                    showModal();
                    modalProps.onSubmit();
                  }}
                >
                  {t("save_warning")}
                </TranslatedModal.Confirmation>
              ) : (
                <TranslatedModal.Information {...modalProps}>
                  {t("complete_warning")}
                </TranslatedModal.Information>
              )
            }
            renderTrigger={(showInnerModal) => (
              <TriggerButton onClick={showInnerModal} />
            )}
          />
        ) : (
          <TriggerButton onClick={showModal} />
        )
      }
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Edit
          {...modalProps}
          className="meter-reading-modal"
          submitLabel={t("Save")}
          submitDisabled={disabled}
          header={t("Meter_Reading")}
        >
          <div className="meter-reading-list-container">
            <div className="meter-reading-list">
              <Form {...formProps} readOnly={disabled || formProps.readOnly}>
                <Grid className="meter-item-group">
                  <Grid.Row className="meter-reading-item header">
                    <Grid.Column width={1}>{t("Item")}</Grid.Column>
                    <Grid.Column width={3}>{t("Item_Description")}</Grid.Column>
                    <Grid.Column width={2}>{t("Model")}</Grid.Column>
                    <Grid.Column width={3}>{t("Status")}</Grid.Column>
                    <Grid.Column width={2}>
                      {t("Identifier_Number")}
                    </Grid.Column>
                    <Grid.Column width={1}>{t("Tier")}</Grid.Column>
                    <Grid.Column width={1}>{t("Meter_Code")}</Grid.Column>
                    <Grid.Column width={2}>
                      {t("Start_Meter_Reading")}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <div className="meter-reading-groups">
                  <Form.Array arrayKey="id" field="groups">
                    {({ fields, arrayIndex, parentFields, formFields }) => (
                      <MeterReadingItemGroup
                        startPosition={getSizeOfPreviousList(
                          parentFields,
                          arrayIndex,
                          fields
                        )}
                        appSysLen={fields.applicableSysSchItems.length}
                        lfLen={fields.applicableLFItems.length}
                        isFirstGroup={arrayIndex === 0}
                        isLastGroup={
                          arrayIndex === formFields.groups.length - 1
                        }
                        separatorRowIsEven={true}
                      />
                    )}
                  </Form.Array>
                </div>
              </Form>
            </div>
          </div>
        </TranslatedModal.Edit>
      )}
    />
  );
};
export default MeterReadingModal;
