import { reducer as system } from 'features/System';
import { reducer as sheets } from "features/Sheets";
import { reducer as settings } from "features/Settings";
import { reducer as agreements } from "features/Agreements";

export default {
  system,
  sheets,
  settings,
  agreements
};
