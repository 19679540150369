import React, { Fragment } from "react";
import { Image } from "semantic-ui-react";
import Scanner from "features/../../../shared/assets/scanner.svg";

export const Units = {
  Black: "Black",
  Colour: "Colour",
  Scan: "Scan",
  Utility: "Utility"
};

export const UnitLabels = {
  Black: (
    <Fragment>
      <div className="unit-icon black"></div>
      <label className="unit-label">B</label>
    </Fragment>
  ),
  Colour: (
    <Fragment>
      <div className="unit-icon colour"></div>
      <label className="unit-label">C</label>
    </Fragment>
  ),
  Scan: (
    <div className="unit-label-container">
      <Image
        src={Scanner}
        style={{ width: "0.85em", marginTop: "0.0em", marginRight: "0.45em" }}
      />{" "}
      <label className="unit-label">S</label>
    </div>
  ),
  Utility: (
    <Fragment>
      <div className="unit-icon utility"></div>
      <label className="unit-label">U</label>
    </Fragment>
  )
};

export const UnitOptions = [
  {
    value: Units.Black,
    text: UnitLabels.Black
  },
  {
    value: Units.Colour,
    text: UnitLabels.Colour
  },
  {
    value: Units.Scan,
    text: UnitLabels.Scan
  },
  {
    value: Units.Utility,
    text: UnitLabels.Utility
  }
];
