export const calculateTotalCosts = section => {
    const productCapital = section.productCapitalCosts.value
      ? typeof section.productCapitalCosts.value === "string"
        ? parseFloat(section.productCapitalCosts.value.replace(",", ""))
        : section.productCapitalCosts.value
      : 0;
  
    const otherCosts = section.otherCosts.value
      ? typeof section.otherCosts.value === "string"
        ? parseFloat(section.otherCosts.value.replace(",", ""))
        : section.otherCosts.value
      : 0;
  
    return productCapital + otherCosts;
  };