import React, { useState } from "react";
import { connect } from "react-redux";
import {
  ActionMenu,
  ActionBar,
  FormBuilder,
  Form,
  lookupReset,
  lookupRequest,
} from "@redriver/cinnamon";
import { withCurrentUser } from "features/../../../shared/components/auth";
import { RateGroupDropdown, LookupTypes } from "features/Settings";
import EditRateGroupDetailsModal from "./EditRateGroupDetailsModal";
import DeleteRateGroupModal from "./DeleteRateGroupModal";
import CloneRateGroupModal from "./CloneRateGroupModal";
import TabHeaders from "./TabHeaders";
import { getDefaultRateGroup } from "features/../../../shared/features/Settings";
import { useTranslation } from "react-i18next";

const RatesActions = ({
  headerText,
  filters,
  onChange,
  hideTabs,
  dealerOfficeId,
}) => {
  const { t } = useTranslation("supplierOffices", { keyPrefix: "rates" });
  return (
    <FormBuilder
      value={filters}
      onChange={onChange}
      renderForm={(formProps, _, events) => (
        <Form {...formProps}>
          <div className="rates-action-bar">
            <ActionBar>
              <ActionBar.Item fluid>
                <HqHeader
                  headerText={headerText}
                  rateGroupId={formProps.value.rateGroupId}
                  onRefresh={events.onRefresh}
                  filters={formProps.value}
                  onChangeFilter={(newFilters) => onChange(newFilters)}
                  dealerOfficeId={dealerOfficeId}
                  t={t}
                />
              </ActionBar.Item>
              {!hideTabs && (
                <ActionBar.Item
                  float="right"
                  className="desktop-tab-headers-container"
                >
                  <div className="desktop-tab-headers">
                    <TabHeaders />
                  </div>
                </ActionBar.Item>
              )}
            </ActionBar>
          </div>
        </Form>
      )}
    />
  );
};

const HqHeader = connect(null, {
  getDefaultRateGroup,
  lookupReset,
  lookupRequest,
})(
  ({
    headerText,
    rateGroupId,
    onChangeFilter,
    filters,
    getDefaultRateGroup,
    lookupReset,
    lookupRequest,
    dealerOfficeId,
    t,
  }) => {
    const [inactiveIsChecked, toggleIncludeInactive] = useState(true);
    const [editModalIsVisible, setEditModalVisibility] = useState(false);
    const [cloneModalIsVisible, setCloneModalVisibility] = useState(false);
    const [deleteModalIsVisible, setDeleteModalVisibility] = useState(false);
    const [typeAheadKey, setTypeAheadKey] = useState(null);

    return (
      <h4 className="rates-header">
        {headerText}
        <div className="header-dropdown">
          <RateGroupDropdown
            key={typeAheadKey}
            field="rateGroupId"
            textField="rateGroupName"
            placeholder={t("searchPlaceholder")}
            lookupParams={{
              includeInactive: inactiveIsChecked,
              dealerOfficeId,
            }}
            lookupOptions={{ cache: false }}
            width={"100%"}
          />
        </div>
        <div className="rates-actions">
          <ActionMenu
            compact
            label={t("actions")}
            className="ultra-compact-action-menu"
          >
            <ActionMenu.Item
              text={t("includeInactive")}
              icon={inactiveIsChecked && "check"}
              onClick={() => toggleIncludeInactive(!inactiveIsChecked)}
            />
            <ActionMenu.Item
              text={t("editDetails")}
              onClick={() => setEditModalVisibility(true)}
            />
            <ActionMenu.Item
              text={t("duplicateRates")}
              onClick={() => setCloneModalVisibility(true)}
            />
            <ActionMenu.Item
              text={t("deleteRates")}
              onClick={() => setDeleteModalVisibility(true)}
            />
          </ActionMenu>
        </div>
        <EditRateGroupDetailsModal
          id={rateGroupId}
          open={editModalIsVisible}
          setModalVisibility={setEditModalVisibility}
          onEdited={async () => {
            // grab details for current group again
            await lookupReset(LookupTypes.RateGroups);
            const { error, response } = await lookupRequest(
              LookupTypes.RateGroups,
              {
                includeInactive: inactiveIsChecked,
                id: filters.rateGroupId,
                dealerOfficeId,
              }
            );
            if (!error) {
              onChangeFilter({
                ...filters,
                rateGroupName: response[0].text,
              });
              setTypeAheadKey(Date.now());
            } else {
              console.error(error);
            }
          }}
        />
        <CloneRateGroupModal
          id={rateGroupId}
          open={cloneModalIsVisible}
          setModalVisibility={setCloneModalVisibility}
          onCloned={async (newGroupId) => {
            // show the new group
            await lookupReset(LookupTypes.RateGroups);
            const { error, response } = await lookupRequest(
              LookupTypes.RateGroups,
              {
                includeInactive: inactiveIsChecked,
                id: newGroupId,
              }
            );
            if (!error) {
              onChangeFilter({
                ...filters,
                rateGroupName: response[0].text,
                rateGroupId: response[0].value,
              });
              setTypeAheadKey(Date.now());
            } else {
              console.error(error);
            }
          }}
        />
        <DeleteRateGroupModal
          id={rateGroupId}
          open={deleteModalIsVisible}
          setModalVisibility={setDeleteModalVisibility}
          onDeleted={async () => {
            // show default group again
            await getDefaultRateGroup();
            await lookupReset(LookupTypes.RateGroups);
            await lookupRequest(LookupTypes.RateGroups, {
              includeInactive: inactiveIsChecked,
            });
            setTypeAheadKey(Date.now());
          }}
        />
      </h4>
    );
  }
);

export default withCurrentUser(RatesActions);
