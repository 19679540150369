import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateFilters, lockYields, unlockYields } from "./actions";
import { getRatesState } from "../selectors";
import YieldMatrix from "./YieldMatrix";
import RatesActions from "./RatesActions";
import { RateTypeDiscriminator } from "features/../../../shared/constants/enums";
import { getDefaultRateGroup } from "features/../../../shared/features/Settings";
import LockMessage from "./LockMessage";
import { withCurrentUser } from "features/../../../shared/components/auth";
import { compose } from "redux";
import { useTranslation } from "react-i18next";

const Yields = ({
  getDefaultRateGroup,
  filters,
  updateFilters,
  dealerOfficeId,
  ratesLoading,
  lockedById,
  lockedByName,
  rateGroupId,
  unlockYields,
  currentUser,
  updated,
}) => {
  const { t } = useTranslation("supplierOffices", { keyPrefix: "yields" });

  const [hasLockAccess, setHasLockAccess] = useState(false);
  const [adminUnlocked, setAdminUnlocked] = useState(false);

  // component did mount
  useEffect(() => {
    getDefaultRateGroup(true, dealerOfficeId, getRatesState);
  }, [dealerOfficeId, getDefaultRateGroup]);

  useEffect(() => {
    if (rateGroupId) {
      if (!lockedById) {
        setHasLockAccess(true);
      } else if (lockedById) {
        if (lockedById.toLowerCase().replace(/-/g, "") === currentUser.id) {
          setHasLockAccess(true);
        } else {
          setHasLockAccess(false);
        }
      } else {
        setHasLockAccess(false);
      }
    }
  }, [updated, currentUser, rateGroupId, lockedById]);
  return (
    <React.Fragment>
      {!hasLockAccess && !ratesLoading && (
        <LockMessage
          lockedByName={lockedByName}
          adminUnlocked={adminUnlocked}
          onForceUnlock={() => {
            unlockYields(rateGroupId).then(() => {
              setAdminUnlocked(true);
              setHasLockAccess(true);
            });
          }}
        />
      )}
      <RatesActions
        headerText={t("yieldsFor")}
        filters={filters}
        onChange={(newFilters) => updateFilters(newFilters, false)}
        hideTabs
        dealerOfficeId={dealerOfficeId}
      />
      <div className="rates-page yields-page">
        <YieldMatrix hasLockAccess={hasLockAccess} />
      </div>
    </React.Fragment>
  );
};

const actions = {
  getDefaultRateGroup,
  updateFilters,
  lockYields,
  unlockYields,
};

const mapStateToProps = (state) => {
  const { rates, ratesLoading, filters } = getRatesState(state);

  const yieldRates = rates[RateTypeDiscriminator.Yield];

  return {
    rates,
    yieldRates,
    ratesYieldRates: rates.yieldsRates,
    ratesLoading,
    filters,
    lockedById: rates.lockedById,
    lockedByName: rates.lockedByName,
    rateGroupId: filters.rateGroupId,
    updated: rates.updated,
  };
};

export default compose(
  connect(mapStateToProps, actions),
  withCurrentUser,
)(Yields);
