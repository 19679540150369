import React from "react";
import { useTranslation } from "react-i18next";
import CalcBreakdown from "./CalcBreakdown";
import {
  RatesAndSettingsGrid,
  CapitalInvestmentGrid,
  SettlementsGrid,
  CfaChargeGrid,
  YieldInformationGrid,
  FunderPayoutGrid,
  CoTerminusGrid,
  CapitalTotalGrid,
} from "./Grids";

const FunderCFA = ({ breakdown }) => {
  const { t } = useTranslation("capitalCalc", {
    keyPrefix: "infoModal.funderTypes",
  });

  return (
    <div className="funder-type-section funder">
      <h4>{t("funderTitle")}</h4>

      <div className="summary">
        <RatesAndSettingsGrid info={breakdown.ratesAndSettings} />
        <CapitalInvestmentGrid
          data={breakdown.capitalInvestment}
          funderType={breakdown.funderType}
        />
        <SettlementsGrid
          data={breakdown.settlementsGrid}
          funderType={breakdown.funderType}
        />
      </div>
      <div className="summary-footer">
        <div></div>
        <CapitalTotalGrid data={breakdown} funderType={breakdown.funderType} />
      </div>

      <CalcBreakdown
        data={breakdown.capitalCalcCalcBreakdown}
        funderType={breakdown.funderType}
      />

      <div className="charges">
        <div className="charges-left-container">
          <FunderPayoutGrid
            data={breakdown.payoutGrid}
            funderType={breakdown.funderType}
          />{" "}
          <br />
        </div>
        <div className="charges-right-container">
          <div>
            <CfaChargeGrid
              data={breakdown.cfaChargeGrid}
              funderType={breakdown.funderType}
            />
            <br />
            <YieldInformationGrid data={breakdown.yieldInformationGrid} />
          </div>
          <div>
            <CoTerminusGrid
              info={breakdown.coTerminusGrid}
              funderType={breakdown.funderType}
            />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FunderCFA;
