import { apiGet, apiPost, apiPut, apiDelete } from "@redriver/cinnamon";
import { UserStatus, UserType } from "features/../../../shared/constants/enums";
import { ResendEmailVerification } from ".";

const NAMESPACE = "ADMIN/LIST_USERS";

const ActionTypes = {
  GetUsers: `${NAMESPACE}/GET_USERS`,
  GetUser: `${NAMESPACE}/GET_USER`,
  AddUser: `${NAMESPACE}/ADD_USER`,
  UpdateUser: `${NAMESPACE}/UPDATE_USER`,
  DeleteUser: `${NAMESPACE}/DELETE_USER`,
  ResetAuthentication: `${NAMESPACE}/RESET_AUTHENTICATION`,
  GetUserPermissions: `${NAMESPACE}/GET_USER_PERMISSIONS`,
  UpdateUserPermissions: `${NAMESPACE}/UPDATE_USER_PERMISSIONS`,
  ClearFailedLoginLockout: `${NAMESPACE}/CLEAR_FAILED_LOGIN_LOCKOUT`,
  ResendEmailVerification: `${NAMESPACE}/RESEND_EMAIL_VERIFICATION`,
};

export const getUsers = ({ filters, pagination }, { companyId }) => {
  let data = {
    ...filters,
    ...pagination,
  };

  // Force users to be filtered by company ID (dealer ID) if set
  if (companyId) {
    data.companyId = companyId;
  }

  return apiGet(ActionTypes.GetUsers, `users`, data);
};

export const getUser = (userId) =>
  apiGet(ActionTypes.GetUser, `users/${userId}`);

const getUserFormData = (data, showAccessTree) => {
  return {
    ...data,
    companyId: data.userType != UserType.HQ ? data.companyId : null,
    officeAccessRefs: showAccessTree
      ? data.officeAccessRefs.filter((n) => n != "")
      : [],
  };
};

export const addUser = (data) =>
  apiPost(ActionTypes.AddUser, `users`, getUserFormData(data));

export const updateUser = (data, { userId, showAccessTree }) => {
  return apiPut(
    ActionTypes.UpdateUser,
    `users/${userId}`,
    getUserFormData(data, showAccessTree)
  );
};

export const deleteUser = (userId) =>
  apiDelete(ActionTypes.DeleteUser, `users/${userId}`);

export const resetAuthentication = (userId) =>
  apiPut(ActionTypes.ResetAuthentication, `users/${userId}/reset-mfa`);

export const getUserPermissions = (userId) =>
  apiGet(ActionTypes.GetUserPermissions, `roles/user/${userId}`);

export const updateUserPermissions = (data, userId) =>
  apiPut(ActionTypes.UpdateUserPermissions, `roles/user/${userId}`, data);

export const clearFailedLoginLockout = (userId) =>
  apiPut(ActionTypes.ClearFailedLoginLockout, `users/${userId}/clear-lockout`);

export const resendVerification = ({ userId }) =>
  apiPost(
    ActionTypes.ResendEmailVerification,
    `users/${userId}/resend-verification`
  );
