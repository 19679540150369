import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Accordion } from "semantic-ui-react";
import {
  withCurrentUser,
  withPermissions,
} from "features/../../../shared/components/auth";
import { SheetsList } from "features/Agreements/AgreementsList/SheetComponents";
import { InlineLoader } from "features/../../../shared/components/forms/InlineLoader";
import { getAgreementsState } from "../../selectors";
import ScheduleRowForm from "../ScheduleComponents/ScheduleRowForm";
import AgreementRowForm from "./AgreementRowForm";

const AgreementListItems = ({ selectedSheetId, currentUser }) => {
  const ref = useRef(null);

  const { isSupplierAdminUser, isInternalUser } = currentUser;
  const isAdminUser = isInternalUser || isSupplierAdminUser;

  const {
    agreements,
    scheduleSheets,
    selected,
    expandedAgreementsAndSchedules,
  } = useSelector(getAgreementsState);

  //if user is being redirect from the sheet because they have created a copy we need the schedule and agreementId to automatically display the related schedule expanded.
  let agreementId,
    scheduleId = null;
  if (selectedSheetId !== undefined) {
    scheduleId = selected.scheduleId;
    if (scheduleId != null) {
      let selected = agreements.find((x) =>
        x.schedules.some((x) => x.scheduleId == scheduleId),
      );
      agreementId = selected?.agreementId;
    }
  }

  //if agreement ID is set on load set the associated accordions to be expanded.
  useEffect(() => {
    if (agreementId !== undefined && agreements) {
      setTimeout(() => handleScroll(), 500);
    }
  }, [agreementId, agreements, scheduleId]);

  const handleScroll = () => {
    let bodyRect = document.body.getBoundingClientRect(),
      elemRect = ref.current.getBoundingClientRect(),
      offset = elemRect.top - bodyRect.top;
    window.scrollTo({
      top: offset,
      left: 0,
      behavior: "smooth",
    });
  };

  const getExpandIconName = (agreementOrScheduleId) =>
    expandedAgreementsAndSchedules.includes(agreementOrScheduleId)
      ? "minus square"
      : "plus square";

  const panels = agreements.map((x) => {
    return {
      key: x.agreementId,
      title: (
        <Accordion.Title className="accordion-title-flex agreement-row">
          <React.Fragment>
            <div ref={agreementId === x.agreementId ? ref : null}>
              <AgreementRowForm
                agreement={x}
                getExpandIconName={getExpandIconName}
                isAdminUser={isAdminUser}
                isInternalUser={isInternalUser}
              />
            </div>
          </React.Fragment>
        </Accordion.Title>
      ),
      active: expandedAgreementsAndSchedules.includes(x.agreementId),
      content: {
        content: (
          <React.Fragment>
            <Accordion.Accordion
              exclusive={false}
              className="agreement-schedule"
              panels={x.schedules.map((s) => {
                return {
                  key: s.scheduleId,
                  title: (
                    <Accordion.Title className="schedule-row">
                      <ScheduleRowForm
                        agreement={x}
                        schedule={s}
                        isAdminUser={isAdminUser}
                        isInternalUser={isInternalUser}
                        getExpandIconName={getExpandIconName}
                      />
                    </Accordion.Title>
                  ),
                  active: expandedAgreementsAndSchedules.includes(s.scheduleId),
                  content: {
                    content: (
                      <div>
                        {scheduleSheets[s.scheduleId]?.loading ? (
                          <InlineLoader
                            isActive={true}
                            size="medium"
                          ></InlineLoader>
                        ) : (
                          <SheetsList agreement={x} schedule={s} />
                        )}
                      </div>
                    ),
                  },
                };
              })}
            />
          </React.Fragment>
        ),
      },
    };
  });

  return (
    <React.Fragment>
      <Accordion
        fluid
        styled
        exclusive={false}
        className="agreements-list"
        panels={panels}
      />
    </React.Fragment>
  );
};

export default withCurrentUser(AgreementListItems);
