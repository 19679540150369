import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "semantic-ui-react";
import classNames from "classnames";
import { Form, useFormField } from "@redriver/cinnamon";
import { produce } from "immer";

const MeterCodeGroup = ({
  startPosition,
  isFirstGroup,
  isLastGroup,
  length,
  disabled,
}) => {
  const [items, setItems] = useFormField("meterCodeItem");

  const onApplyToAll = () => {
    if (!Array.isArray(items) || items.length === 0) return;

    const fieldName = "meterCode";
    const value = items[0][fieldName];
    const updatedItems = produce(items, (draftItems) => {
      draftItems.forEach((i) => {
        i[fieldName] = value;
      });
      return draftItems;
    });
    setItems(updatedItems);
  };

  return (
    <Grid className="meter-item-group">
      <MeterCodeApplicableItems
        isFirstGroup={isFirstGroup}
        startPosition={startPosition}
        onApplyToAll={onApplyToAll}
        disabled={disabled}
      />
      {!isLastGroup && (
        <Grid.Row
          className={classNames((startPosition + length) % 2 === 0 && "even")}
        ></Grid.Row>
      )}
    </Grid>
  );
};

const MeterCodeApplicableItems = ({
  startPosition,
  onApplyToAll,
  disabled,
}) => {
  const { t } = useTranslation("productSchedule");
  return (
    <Form.Array arrayKey="id" field="meterCodeItem">
      {({ fields, arrayIndex }) => (
        <Grid.Row
          className={classNames(
            "meter-reading-item",
            (startPosition + arrayIndex) % 2 === 0 && "even"
          )}
        >
          <Grid.Column width={1}>
            <Form.Input readOnly field="applicableItem" />
          </Grid.Column>
          <Grid.Column width={4}>
            <Form.Input readOnly field="description" />
          </Grid.Column>
          <Grid.Column width={3}>
            <Form.Input readOnly field="model" />
          </Grid.Column>
          <Grid.Column width={3}>
            <Form.Input readOnly field="idNumber" />
          </Grid.Column>
          <Grid.Column width={1}>
            <Form.Input readOnly field="tier" />
          </Grid.Column>
          <Grid.Column width={3} className="meter-code-col">
            <Form.Input width={6} field="meterCode" />
            {arrayIndex == 0 && (
              <Button
                primary
                content={`${t("Apply to All")} ${fields.applicableItem}`}
                onClick={() => onApplyToAll({ fields, arrayIndex })}
                icon="check"
                disabled={disabled}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      )}
    </Form.Array>
  );
};

export default MeterCodeGroup;
