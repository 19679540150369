export const copyToClipboard = async value => {
  let tempElement = null;
  let success = false;

  try {
    if (navigator.clipboard) {
      // use async clipboard api
      await navigator.clipboard.writeText(value);
      success = true;
    } else {
      // fallback to legacy method using temp control
      const textArea = document.createElement("textarea");
      textArea.value = value;
      textArea.style.position = "fixed";
      textArea.style.top = 0;
      textArea.style.left = 0;
      document.body.appendChild(textArea);
      tempElement = textArea;

      textArea.focus();
      textArea.select();
      success = document.execCommand("copy");
      if (!success) throw new Error("Copying unsuccessful");
    }
  } catch (error) {
    console.error("Failed to copy to clipboard", value, error);
  }

  if (tempElement !== null) {
    document.body.removeChild(tempElement);
  }

  return success;
};
