import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link, withRouter, matchPath } from "react-router-dom";
import { Dropdown, Icon } from "semantic-ui-react";

class DropdownSubmenuItem extends React.Component {
  state = { mouseOver: false };

  onMouseEnter = (e) => {
    this.setState({ mouseOver: true });
    if (this.props.onMouseEnter) this.props.onMouseEnter(e);
  };

  onMouseLeave = (e) => {
    this.setState({ mouseOver: false });
    if (this.props.onMouseLeave) this.props.onMouseLeave(e);
  };

  render() {
    const {
      link,
      exact,
      icon,
      match,
      location,
      history,
      staticContext,
      children,
      content,
      className,
      ...otherProps
    } = this.props;

    const linkProps = link
      ? {
          as: Link,
          to: link,
        }
      : {};

    const active = link
      ? !!matchPath(location.pathname, {
          path: link.pathname || link,
          exact,
        })
      : this.props.active;

    const hover = this.state.mouseOver;

    const output =
      typeof children === "function"
        ? children({ active, hover })
        : children || content;

    return (
      <Dropdown.Item
        {...otherProps}
        {...linkProps}
        active={active}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        className={classNames(className, { hover })}
      >
        {icon ? (
          <React.Fragment>
            <div className="icon">
              {typeof icon === "string" ? <Icon name={icon} /> : icon}
            </div>
            <div className="content">{output}</div>
          </React.Fragment>
        ) : (
          output
        )}
      </Dropdown.Item>
    );
  }
}

DropdownSubmenuItem.propTypes = {
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
    }),
  ]),
  exact: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default withRouter(DropdownSubmenuItem);
