import React from "react";
import { Modal,ErrorMessage } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";
import { translatedModalProps } from "./helpers";

const TranslatedDeleteModal = (deleteModalProps) => {
    const { t } = useTranslation("shared");  
    const modalProps = translatedModalProps(deleteModalProps, t);
    
    return (
        <Modal.Delete  {...modalProps}
            header={modalProps.header ? modalProps.header : t("modals.delete.header")}
            submitLabel={modalProps.submitLabel ? modalProps.submitLabel : t("modals.delete.submitLabel")}
            cancelLabel={modalProps.cancelLabel ? modalProps.cancelLabel : t("modals.delete.cancelLabel")}>
            {modalProps.children}
            <ErrorMessage
                header={deleteModalProps.error?.status == 500 ?  t("modals.errorHeader"): t("modals.somethingWentWrong")}
                error={deleteModalProps.error}
                omitCodes                    
            />
        </Modal.Delete>
    )
}

export default TranslatedDeleteModal;
