import {
  requestError,
  requestIsBusy,
  requestResponse,
} from "@redriver/cinnamon";
import { getWizardSplitTotals } from "../../helpers";
import { calculateComparisonSheetData } from "../../ComparisonSheet/Utils";
import { checkSectionsCompleted } from "../Data";
import { produce } from "immer";
import { isNil } from "lodash";

const getCalculationData = (sheetsState) => sheetsState.rawCalculation;

export const calculateSheet = (state, action, initialState) => {
  if (requestIsBusy(action)) {
    return produce(state, (draftState) => {
      draftState.calculating = true;
    });
  }

  if (requestError(action)) {
    //get error code
    const code = action.error.response[0].code;
    //Capital Calc Cost is outside of Yield bands and therefore cannot calc sheet.
    if (code == 25024) {
      return produce(state, (draftState) => {
        (draftState.calculating = false),
          (draftState.missingYieldBands = true),
          (draftState.summary = initialState.summary);
      });
    }
    return produce(state, (draftState) => {
      draftState.summary = initialState.summary;
      draftState.calculating = false;
    });
  }

  const result = requestResponse(action);
  if (!result) return state;
  let paperInclusiveResult = result.breakdown.paperInclusiveCosts,
    minQrtPayment = result.breakdown.paperInclusiveCosts.minQuarterlyPayment;

  let paperInclusiveProgress = {};

  if (state.paperHasContent === null) {
    const isPi = state.sheetData.paperInclusive.isPaperInclusive;
    const piChargeType =
      state.sheetData.paperInclusive.paperInclusiveChargeType;

    if (!isNil(isPi)) {
      paperInclusiveProgress.paperHasContent = !(
        isPi === "false" || isPi === false
      );

      paperInclusiveProgress.paperCompleted =
        isPi === "false" ||
        isPi === false ||
        (piChargeType !== null && minQrtPayment > 0);
    }
  }

  if (
    action.customData?.includeNewLargeFormatItems &&
    result?.breakdown?.systemSchedule?.newLargeFormatCalc
  ) {
    // Update calcs in Create Large Format modal only, do not change other values
    const newState = produce(state, (draftState) => {
      const {
        inkUsageCalcIncludingCapital,
        inkUsageCalcExcludingCapital,
        compInkUsageCalcIncludingCapital,
        compInkUsageCalcExcludingCapital,
        costPerUnit,
      } = result.breakdown.systemSchedule.newLargeFormatCalc;
      const lf = draftState.sheetData.largeFormatCreateModal;
      lf.inkUsageCalcIncludingCapital = inkUsageCalcIncludingCapital;
      lf.inkUsageCalcExcludingCapital = inkUsageCalcExcludingCapital;
      lf.compInkUsageCalcIncludingCapital = compInkUsageCalcIncludingCapital;
      lf.compInkUsageCalcExcludingCapital = compInkUsageCalcExcludingCapital;
      if (lf.printerChanged) {
        lf.costPerUnit = costPerUnit.toFixed(
          process.env.USE_MINOR_CURRENCY ? 3 : 5,
        );
      }
      draftState.calculating = false;
      draftState.missingYieldBands = false;
    });
    return newState;
  }

  const newState = produce(state, (draftState) => {
    const d = draftState;
    const s = draftState.summary;
    const sd = draftState.sheetData;
    const ratesState = sd.rates;
    const pi = sd.paperInclusive;
    const pis = sd.paperInclusive.summary;
    d.calculating = false;
    d.missingYieldBands = false;
    d.rawCalculation = result;

    // Update summary
    s.countedVolume = result.quarterlyVolumeReductionMinimum;
    s.notCountedVolume = result.quarterlyNonVolumeReductionMinimum;
    s.settlements = !isNaN(parseFloat(ratesState.displaySettlement.value))
      ? parseFloat(ratesState.displaySettlement.value)
      : result.breakdown.capitalCalcCosts.settlementsLiabilitiesTotal;
    s.volumeReductionMinimum = result.quarterlyVolumeMinimumPayment;
    s.nonVolumeReductionMinimum = result.quarterlyNonVolumeMinimumPayment;
    s.totalScheduleManagedCountedVolume =
      result.totalScheduleManagedCountedVolume;
    s.totalMinimum = result.quarterlyMinimumPayment;
    s.terminationDiscount = result.terminationDiscount;

    // Update Wizard tab content

    pi.quarterlyServiceDifference =
      minQrtPayment - pi.minQuarterlySnapshotPayment;
    pi.quarterlyServiceAdjustment = pi.paperIncomeAdjustment
      ? pi.minQuarterlySnapshotPayment - minQrtPayment
      : 0;

    d.paperContent = getWizardSplitTotals(
      minQrtPayment,
      pi.minQuarterlySnapshotPayment > 0 && pi.quarterlyServiceAdjustment > 0
        ? pi.minQuarterlySnapshotPayment
        : minQrtPayment,
      pi.quarterlyServiceAdjustment > 0
        ? pi.minQuarterlySnapshotPayment
        : minQrtPayment,
    );

    // Update sheet data
    pis.units = paperInclusiveResult.units;
    pis.costPerUnit = paperInclusiveResult.costPerUnit;
    pis.totalIncMinDelivery =
      paperInclusiveResult.totalIncludedMinimumDeliveryCharge;
    pis.quarterlyContractVol = paperInclusiveResult.quarterlyContractVolume;
    pis.contractVolumeInBoxedReams =
      paperInclusiveResult.contractVolumeInBoxedReams;
    pis.amethystAmount = paperInclusiveResult.minQuarterlyPayment;
    pis.minDiffToCreateInAmethyst =
      paperInclusiveResult.minimumDifferenceToCreateInAmethyst;
    pis.likelyMinGmBelowActioned =
      paperInclusiveResult.likelyMinimumGrossMargin;
    pis.totalIncludedMinimumDeliveryCharge =
      paperInclusiveResult.totalIncludedMinimumDeliveryCharge;
    pis.totalAdditionalCosts = paperInclusiveResult.totalAdditionalCosts;

    let scheduleItems = draftState.sheetData.systemScheduleItems;
    if (scheduleItems && scheduleItems.length >= 1) {
      scheduleItems.forEach((item) => {
        const calcItem = result.breakdown.systemSchedule.itemCosts.find(
          (x) => x.id == item.id,
        );

        if (calcItem) {
          item.costPerUnit = calcItem.costPerUnit.toFixed(
            process.env.USE_MINOR_CURRENCY ? 3 : 5,
          );
          item.oldCostPerUnit =
            item.oldCostPerUnit != null
              ? calcItem.oldCostPerUnit.toFixed(
                  process.env.USE_MINOR_CURRENCY ? 3 : 5,
                )
              : null;
        }
      });
    }

    let largeFormatItems = draftState.sheetData.largeFormatGroups
      .map((i) =>
        [i.printerMeter, i.scanMeter, i.otherMeter]
          .filter((x) => x != null)
          .concat(i.paperMeters.filter((x) => x != null)),
      )
      .flat();
    if (largeFormatItems && largeFormatItems.length >= 1) {
      largeFormatItems.forEach((item) => {
        const calcItem =
          result.breakdown.systemSchedule.largeFormatItemCosts.find(
            (x) => x.id == item.id,
          );

        if (calcItem) {
          item.costPerUnit = calcItem.costPerUnit.toFixed(
            process.env.USE_MINOR_CURRENCY ? 3 : 5,
          );
          item.oldCostPerUnit =
            item.oldCostPerUnit != null
              ? calcItem.costPerUnit?.toFixed(
                  process.env.USE_MINOR_CURRENCY ? 3 : 5,
                )
              : null;
        }
      });
    }

    sd.comparisonData =
      calculateComparisonSheetData(sd, getCalculationData(d)) ||
      initialState.comparisonData;
    d.paperCompleted = checkSectionsCompleted(sd).paper;
  });
  return newState;
};
