import React from "react";
import { useDispatch } from "react-redux";
import { ModalBuilder, notifySuccess } from "@redriver/cinnamon";
import { requestOutstandingAgreementValue } from "./actions";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { Actions, Targets } from "constants/permissions";
import { PermissionCheck } from "features/../../../shared/components/auth";

const OutstandingAgreementValue = ({ as: As, scheduleId, disabled }) => {
  const { t } = useTranslation("agreements", { keyPrefix: "ScheduleRow" });
  const { t: tcc } = useTranslation("agreements", {
    keyPrefix: "ScheduleRow.OutstandingAgreementValue",
  });
  const dispatch = useDispatch();

  return (
    <PermissionCheck action={Actions.SubmitRequest} target={Targets.Schedules}>
      <ModalBuilder
        submitAction={requestOutstandingAgreementValue}
        submitParams={scheduleId}
        onSubmitted={() => {
          dispatch(notifySuccess(t("Actions.Submitted_Successfully")));
        }}
        renderTrigger={(showModal) => (
          <As onClick={showModal} disabled={disabled}>
            {t("Actions.Outstanding_Agreement_Value")}
          </As>
        )}
        renderModal={(modalProps) => (
          <TranslatedModal
            {...modalProps}
            header={tcc("Header")}
            submitLabel={tcc("Submit_Label")}
          >
            {tcc("Confirmation_Message")}
          </TranslatedModal>
        )}
      />
    </PermissionCheck>
  );
};

export default OutstandingAgreementValue;
